import "./SingleEmailEditorComponent.scss";
import ChipInputComponent from "../../../../../components/chip-input/ChipInputComponent";
import InputComponent from "../../../../ant-component/input/InputComponent";
import {CloseOutlined, DeleteOutlined, SendOutlined} from "@ant-design/icons";
import {Button} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {ApiService, CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import FilePickerComponent from "../../../../../components/file-picker/FilePickerComponent";
import JoditEditorComponent from "../../../../../components/text-editor-v2/JoditEditorComponent";


interface SingleEmailEditorComponentProps{
    recipientEmail?:string | null;
    setOpenEmailDrawer?:any;
    isEditable?:boolean;
    selectedJobForEmail?:any;
    emailMessage?:any;
    setEmailMessage?:any;
}

const SingleEmailEditorComponent=(props:SingleEmailEditorComponentProps)=>{
    const {recipientEmail,setOpenEmailDrawer,isEditable=false,selectedJobForEmail,emailMessage,setEmailMessage}=props;
    const [showCC,setShowCC]=useState<boolean>(false);
    const [showBCC,setShowBCC]=useState<boolean>(false);
    const [subject,setSubject]=useState<string>("");
    const [recipientEmails,setRecipientEmails]=useState<any>(recipientEmail? [recipientEmail] : [])
    const [ccEmails,setCCEmails]=useState<any>([]);
    const [bccEmails,setBCCEmails]=useState<any>([]);
    const [attachment,setAttachment]=useState<any>(null)
    const [isSubmitting,setIsSubmitting]=useState<boolean>(false);


    useEffect(() => {
        if (selectedJobForEmail) {
            setSubject(`New Job Shared ${selectedJobForEmail?._id?.substr(-7)} : ${selectedJobForEmail?.job_title} `)
            let startDate:any;
            let endDate:any;
            if(selectedJobForEmail?.job_type === 'permanent'){
                    startDate = CommonService.getUtcDate(selectedJobForEmail?.job_start_date);
                    endDate = CommonService.getUtcDate(selectedJobForEmail?.job_end_date);

            }
            if(selectedJobForEmail?.job_type === 'contract'){
                startDate = CommonService.getUtcDate(selectedJobForEmail?.contract_details?.start_date);
                endDate = CommonService.getUtcDate(selectedJobForEmail?.contract_details?.end_date);
            }
            if(selectedJobForEmail?.job_type === 'per_diem'){
                startDate = CommonService.getUtcDate(selectedJobForEmail?.per_diem_details?.start_date);
                endDate = CommonService.getUtcDate(selectedJobForEmail?.per_diem_details?.end_date);
            }
            const emailContent = `
            <table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse; width: 100%;">
                <tr><th>Job Code</th><td>${selectedJobForEmail?._id?.substr(-7) || "N/A"}</td></tr>
                <tr><th>Job Title</th><td>${selectedJobForEmail?.job_title || "N/A"}</td></tr>
                <tr><th>Job Type</th><td>${CommonService.formatTitleCase(selectedJobForEmail?.job_type) || "N/A"}</td></tr>
                <tr><th>Profession</th><td>${selectedJobForEmail?.staff_type || "N/A"}</td></tr>
                <tr><th>Speciality</th><td>${selectedJobForEmail?.speciality || "N/A"}</td></tr>
                <tr><th>Location</th><td>${[selectedJobForEmail?.address?.city, selectedJobForEmail?.address?.state].filter(Boolean).join(', ') || "N/A"}</td></tr>
                <tr><th>Start Date</th><td>${startDate || "N/A"}</td></tr>
                <tr><th>End Date</th><td>${endDate || "N/A"}</td></tr>
                <tr><th>Apply Link</th><td><a href="${ENV.API_JOB_BOARD_URL}job/${selectedJobForEmail?._id}" target="_blank" rel="noopener noreferrer">Apply Now</a></td></tr>
            </table>
        `;
            setEmailMessage(emailContent);
        }
    }, [selectedJobForEmail,setEmailMessage]);



    const handleCCOpenClose = () => {
        if (showCC) {
            setCCEmails([]);
        }
        setShowCC(!showCC);
    };

    const handleBCCOpenClose = () => {
        if (showBCC) {
            setBCCEmails([]);
        }
        setShowBCC(!showBCC);
    };

    const handleClear=useCallback(()=>{
        setAttachment(null)
        setEmailMessage("");
        setSubject("");
        setCCEmails([]);
        setBCCEmails([]);
    },[setEmailMessage])

    const handleSubmit=useCallback(()=>{
        setIsSubmitting(true)
        const combinedRecipientEmails = [recipientEmails] || '';
        const ccEmailsList = ccEmails || [];
        const bccEmailsList = bccEmails || [];
        const formData = new FormData();
        if (attachment) {
            formData.append('attachment', attachment);
        }
        combinedRecipientEmails?.forEach((email:any,index:number)=>{
            formData.append(`email[${index}]`,email || "");
        })
        ccEmailsList?.forEach((email:any,index:number)=>{
            formData.append(`cc[${index}]`,email || "");
        })
        bccEmailsList?.forEach((email:any,index:number)=>{
            formData.append(`bcc[${index}]`,email || "");
        })
        formData.append('subject',subject || "");
        formData.append('emailMessage',emailMessage || "");


        ApiService.upload(ENV.API_URL + "ats/bulkEmail",formData)
            .then((response)=>{
                CommonService.showToast(response.message,"success");
                handleClear();
                setOpenEmailDrawer(false);
            }).catch((error:any)=>{
            CommonService.showToast(error.error || error.message || "Error occurred while sending email",'error')
        }).finally(()=>{
            setIsSubmitting(false)
        })
    },[recipientEmails,ccEmails,bccEmails,subject,emailMessage,attachment,setOpenEmailDrawer,handleClear])



    return (
        <div className={'single-email-editor-component'}>
            <div className={'single-email-box'}>
                <div className={'email-editor-wrapper'}>
                <div className={'email-editor'}>
                    <ChipInputComponent
                        placeholder={'Recipients'}
                        title={'Recipients'}
                        showCC={showCC}
                        showBCC={showBCC}
                        isEditable={isEditable}
                        manualEmails={recipientEmails}
                        setManualEmails={setRecipientEmails}
                        handleCCToggle={handleCCOpenClose}
                        handleBCCToggle={handleBCCOpenClose}
                    />
                    {showCC &&
                        <ChipInputComponent
                            placeholder={'CC'}
                            manualEmails={ccEmails}
                            setManualEmails={setCCEmails}
                            title={"CC"}
                        />
                    }
                    {showBCC &&
                        <ChipInputComponent
                            placeholder={'BCC'}
                            manualEmails={bccEmails}
                            setManualEmails={setBCCEmails}
                            title={'BCC'}
                        />
                    }
                    <div>
                        <InputComponent
                            placeholder={'Subject'}
                            value={subject}
                            className={'email-subject-input'}
                            onChange={(value: string) => {
                                setSubject(value)
                            }}
                        />
                    </div>
                    <div className={'email-quill-wrapper'}>
                        <JoditEditorComponent
                            value={emailMessage}
                            onChange={(value: any) => {
                                setEmailMessage(value)
                            }}
                        />
                    </div>
                    <div className={'email-attachment-wrapper'}>
                        <div>
                            {!attachment &&
                                <FilePickerComponent
                                    variant={'outlined'}
                                    height={'470px'}
                                    id={" sc_upload_btn"}
                                    btnText={" Add Attachment"}
                                    multiple={false}
                                    showDropZone={false}
                                    onFilesDrop={(acceptedFiles: any, rejectedFiles: any) => {
                                        if (acceptedFiles && acceptedFiles.length > 0) {
                                            const file = acceptedFiles[0];
                                            //   setTimesheetFile(file);
                                            setAttachment(file);
                                        }
                                    }}
                                    acceptedFileTypes={["pdf", "jpeg", "jpg", "png", "doc", "docx"]}
                                    uploadDescription={"(upload only one file)"}
                                />}
                            {attachment &&
                                <div className={'file-preview-wrapper'}>
                                    {attachment?.name}
                                    <div className={'file-preview-close-icon'} onClick={() => setAttachment(null)}>
                                        <CloseOutlined/>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={'email-btn-wrapper'}>
                        <Button onClick={handleClear}
                                icon={<DeleteOutlined/>}
                                iconPosition={'start'}
                                className={'delete-btn'}
                        >
                        </Button>
                        <Button
                            type="primary"
                            className="email-save-button"
                            icon={<SendOutlined/>}
                            iconPosition={'end'}
                            loading={isSubmitting}
                            onClick={handleSubmit}
                            disabled={recipientEmails?.length  === 0 || !emailMessage || subject?.length === 0}
                        >
                            Send
                        </Button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="email-footer">

            </div>
        </div>
    )
}

export default SingleEmailEditorComponent