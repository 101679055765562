import {Box, Button, FormControlLabel, InputAdornment, MenuItem, Radio, Tooltip, Typography} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import {Field, Form, Formik} from "formik";
import {CheckboxWithLabel, RadioGroup, TextField} from "formik-material-ui";
import {DatePicker as FormikDatePicker} from "formik-material-ui-pickers";
import React, {PropsWithChildren, useEffect, useState} from "react";
import {ScrollToError} from "../../../../../components/ScrollToError";
import {
    boolAcknowledge,
    contactType,
    covidPreference,
    genderTypes,
    hcpPaymentModeOptions,
    moreImportant,
    salaryCredit,
    shiftTypePreference,
    travelDistancePreference,
    vaccine
} from "../../../../../constants/data";
import * as Yup from "yup";
import {PdfIcon} from "../../../../../constants/ImageConfig";
import FileUploadComponent from "../../../../../components/file-upload/FileUploadComponent";
import {CommonService} from "../../../../../helpers";
import HcpEditAttachmentComponentANTD from "../hcp-edit-attachment-component/HcpEditAttachmentComponentANTD";


export interface EditHcpBasicDetailsComponentProps {
    contractFile: any;
    fileUpload: any;
    onAdd: any;
    hcpTypes: any;
    regions: any;
    specialities: any;
    expInYears: any;
    required_attachments: any;
    OnContractFileUpload: any;
    deleteContractFile: any;
    isDeleted: any;
    OnFileSelected: any;
    attachmentsDetails: any;
    isContractDeleted: any;
    previewFile: any;
    contractDetails: any;
    handleExpiryDate: any;
    deleteLocalAttachment: any;
    openDeleteAttachment: any;
    hcpInitialState: any;
    openDeleteContract: any;
    setRequiredAttachments: any;
    setFileUpload: any;
    profileImageFile: any;
    onProfileImageUpload: any;
    previewProfileImageFile: any;
    hcpDetails: any;
    openDeleteProfileImage: any;
    isProfileImageDeleted: any;
    deleteProfileImage: any;
    setOtherDocuments: any;
    otherDocuments: any;
    state: any;
    id?: any
    getAttachmentsDetails?: any;
    setUploadedFiles?: any;
    uploadedFiles?: any;
    apiUploadedFiles?:any;
    setApiUploadedFiles?:any;
}


const validatorSchema = Yup.object({
    first_name: Yup.string()
        .typeError(" must be a text")
        .min(1, "min 1 letter")
        .trim("empty space not allowed")
        .required("Required")
        .max(50, "max limit 50")
        // eslint-disable-next-line
        .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
            , "alphanumeric & special characters only "),
    last_name: Yup.string()
        .typeError(" must be a text")
        .min(1, "min 1 letter")
        .trim("empty space not allowed")
        .required("Required")
        .max(50, "max limit 50")
        // eslint-disable-next-line
        .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
            , "alphanumeric & special characters only "),
    // hcp_uniqueId: Yup.string().required('Staff Id is required').min(2, "Minimum 2 characters are required"),
    email: Yup.string().min(3, "invalid").trim("empty space not allowed").typeError(" must be a text").email("invalid").required("Required").max(50, "max limit 50"),
});

const EditHcpBasicDetailsComponent = (props: PropsWithChildren<EditHcpBasicDetailsComponentProps>) => {
    const [vaccineStatus, setVaccineStatus] = useState<string>("");
    const [isFirstShotVisible, setIsFirstShotVisible] = useState<boolean>(false);
    const [isLastShotVisible, setIsLastShotVisible] = useState<boolean>(false);
    //  const [startDate, setStartDate] = useState<any>(null);
    const id = props?.id;
    const uploadedFiles = props?.uploadedFiles;
    const setUploadedFiles = props?.setUploadedFiles;
    const apiUploadedFiles=props?.apiUploadedFiles;
    const setApiUploadedFiles=props.setApiUploadedFiles;
    const hcpInitialState = props?.hcpInitialState;
    const contractFile = props?.contractFile;
    const onAdd = props?.onAdd;
    const hcpTypes = props?.hcpTypes;
    const regions = props?.regions;
    const getAttachmentsDetails = props?.getAttachmentsDetails
    const specialities = props?.specialities;
    const expInYears = props?.expInYears;
    const OnContractFileUpload = props?.OnContractFileUpload;
    const deleteContractFile = props?.deleteContractFile;
    const attachmentsDetails = props?.attachmentsDetails;
    const isContractDeleted = props?.isContractDeleted;
    const previewFile = props?.previewFile;
    // const contractDetails = props?.contractDetails;
    const openDeleteContract = props?.openDeleteContract;
    const profileImageFile = props?.profileImageFile;
    const onProfileImageUpload = props?.onProfileImageUpload;
    const previewProfileImageFile = props?.previewProfileImageFile;
    const hcpDetails = props?.hcpDetails;
    const isProfileImageDeleted = props?.isProfileImageDeleted;
    const openDeleteProfileImage = props?.openDeleteProfileImage;
    const deleteProfileImage = props?.deleteProfileImage;
    // const state = props?.state;

    console.log(hcpInitialState,"hcpInitialState")

    const showDropDownBelowField = {
        MenuProps: {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
            getContentAnchorEl: null,
        },
    };

    useEffect(() => {
        setVaccineStatus(hcpInitialState?.nc_details?.vaccine);
        //  setStartDate(hcpInitialState?.dob);
    }, [hcpInitialState?.nc_details?.vaccine, hcpInitialState?.dob]);


    useEffect(() => {
        if (vaccineStatus === "full") {
            setIsFirstShotVisible(true);
            setIsLastShotVisible(true);
        }
        if (vaccineStatus === "half") {
            setIsFirstShotVisible(true);
            setIsLastShotVisible(false);
        }
        if (vaccineStatus === "exempted") {
            setIsFirstShotVisible(false);
            setIsLastShotVisible(false);
        }
        if (vaccineStatus === "") {
            setIsFirstShotVisible(false);
            setIsLastShotVisible(false);
        }
    }, [vaccineStatus]);


    useEffect(() => {
        try {
            validatorSchema.validateSync(hcpInitialState);
        } catch (error) {
            console.error(error);
        }
    }, [hcpInitialState]);

    useEffect(() => {
        console.log(profileImageFile);
    }, [profileImageFile]);

    // const handleDateChange = (date: any) => {
    //     setStartDate(date);
    //     console.log(date);

    async function getLatLong(street: any, city: any, state: any, zipcode: any) {
        // const address = `${street}, ${city}, ${state}, ${zipcode}, USA`;
        // const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&addressdetails=1&limit=1`;
        const url = `https://nominatim.openstreetmap.org/search?postalcode=${zipcode}&countrycodes=us&format=json&limit=1`;
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.length > 0) {
                const location = data[0];
                return {
                    latitude: location.lat,
                    longitude: location.lon,
                };
            } else {
                throw new Error('Geocoding failed: No results found');
            }
        } catch (error) {
            console.error('Error fetching geolocation:', error);
            return null;
        }
    }

    // };
    return (
        <div>
            <Formik initialValues={hcpInitialState}
                    validateOnChange={true}
                    validateOnBlur={false}
                    validationSchema={validatorSchema}
                    onSubmit={onAdd}>
                {({isSubmitting, isValid,errors, resetForm, setFieldValue, values}) => (
                    <Form id="hcp-edit-form" className={"form-holder"}>
                        <ScrollToError/>
                        <div className="hcp-basic-details">
                            <div className="custom-border">
                                <p className="card-header">Basic Details</p>
                                <div className="input-container">
                                    <Field variant="outlined" name="first_name" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="First Name*" fullWidth id="input_hcp_edit_first_name"
                                           autoComplete="off"/>
                                    <Field variant="outlined" name="last_name" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="Last Name*" fullWidth id="input_hcp_edit_last_name"
                                           autoComplete="off"/>
                                </div>

                                <div className="input-container">
                                    <Field variant="outlined" component={TextField} type={"text"} fullWidth
                                           InputLabelProps={{shrink: true}}
                                           autoComplete="off" label="Email*" name="email" id="input_hcp_edit_email"
                                           className="flex-1"/>
                                    <div className="flex-1 d-flex">
                                        <Field
                                            inputProps={{ maxLength: 10,
                                                onInput: (e:any) => {
                                                    if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10);
                                                },
                                            }}
                                            className="phone_number"
                                            variant="outlined"
                                            name="contact_number"
                                            type={"text"}
                                            component={TextField}
                                            label="Phone Number"
                                            fullWidth
                                            autoComplete="off"
                                            id="input_facility_member_add_phone_number"
                                            InputLabelProps={{shrink: true}}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        +1
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="input-container">
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel className={"form-label"}>{"Gender"}</FormLabel>
                                        </div>

                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="gender" id="radio_doctor_add_gender">
                                                <div className="d-flex">
                                                    {genderTypes.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel key={"gender_type_" + index}
                                                                                  value={item.value}
                                                                                  control={<Radio
                                                                                      disabled={isSubmitting}/>}
                                                                                  disabled={isSubmitting}
                                                                                  label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <Field
                                            SelectProps={showDropDownBelowField}
                                            variant="outlined"
                                            onChange={(e: any) => {
                                                const hcpType = e.target.value;
                                                setFieldValue("hcp_type", hcpType);
                                            }}
                                            component={TextField}
                                            type={"text"}
                                            select
                                            label="Staff Type"
                                            name="hcp_type"
                                            id="menu_hcp_edit_hcp_type"
                                            fullWidth
                                            autoComplete="off"
                                            InputLabelProps={{shrink: true}}
                                        >
                                            {hcpTypes.map((item: any, index: number) => (
                                                <MenuItem value={item.code} key={index}
                                                          id={"menu_hcp_edit_hcp_type" + item.title}>
                                                    {item?.title}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </div>

                                    {/*<div className={"flex-1"}>*/}
                                    {/*    <Field variant="outlined" name="hcp_uniqueId" type={"text"}*/}
                                    {/*           component={TextField} InputLabelProps={{shrink: true}}*/}
                                    {/*           label="Staff ID*" fullWidth id="input_hcp_edit_uniqueId"*/}
                                    {/*           autoComplete="off"/>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="input-container">
                                    <Field variant="outlined" name="address.street" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="Street" id="input_hcp_edit_street" fullWidth autoComplete="off"/>
                                    <Field variant="outlined" name="address.city" type={"text"} id="input_hcp_edit_city"
                                           component={TextField} label="City" fullWidth autoComplete="off"/>
                                </div>
                                <div className="input-container">
                                    <Field SelectProps={showDropDownBelowField} variant="outlined" component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           type={"text"} select label="State" name="address.state"
                                           id="menu_hcp_edit_state" fullWidth autoComplete="off">
                                        {regions &&
                                            regions.map((item: any, index: any) => (
                                                <MenuItem value={item.title} key={index}
                                                          id={"menu_hcp_edit_state" + item.title}>
                                                    {item.title}
                                                </MenuItem>
                                            ))}
                                    </Field>
                                    <Field
                                        variant="inline"
                                        orientation="landscape"
                                        openTo="date"
                                        format="MM/dd/yyyy"
                                        views={["year", "month", "date"]}
                                        inputVariant="outlined"
                                        component={FormikDatePicker}
                                        placeholder="MM/DD/YYYY"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true}}
                                        label="DOB"
                                        name="dob"
                                    />
                                </div>
                                <div className="input-container d-flex">
                                    <div style={{width: "100%"}} className={'d-flex'}>
                                        <div style={{width: "100%"}}>
                                            <Field
                                                inputProps={{
                                                    maxLength: 6,
                                                }}
                                                variant="outlined"
                                                name="address.zip_code"
                                                type={"text"}
                                                component={TextField}
                                                label="Zip Code"
                                                autoComplete="off"
                                                id="input_facility_add_address_zip_code"
                                                fullWidth
                                            />
                                        </div>
                                        <div className={'mrg-left-10 mrg-top-5'}>
                                            <Button variant={'contained'} color={"primary"}
                                                //@ts-ignore
                                                    disabled={!values?.address?.zip_code}
                                                    onClick={() => {
                                                        //@ts-ignore
                                                        getLatLong('', '', '', values?.address?.zip_code)
                                                            .then((coords: any) => {
                                                                setFieldValue("location.coordinates[1]", coords.latitude);
                                                                setFieldValue("location.coordinates[0]", coords.longitude);
                                                                CommonService.showToast("Latitude and Longitude Fetched", "success")
                                                                // const googleMapsUrl = `https://www.google.com/maps?q=${coords.latitude},${coords.longitude}`;
                                                                // window.open(googleMapsUrl, '_blank');
                                                            })
                                                            .catch((error) => CommonService.showToast("Not Able to fetch Latitude and Longitude due to geocode errors", "error"));
                                                    }}>
                                                Check
                                            </Button>
                                        </div>
                                        <div className={'mrg-left-10 mrg-top-5'}>
                                            <Button variant={'contained'} color={"primary"}
                                                //@ts-ignore
                                                    disabled={!values.location.coordinates[0] || !values.location.coordinates[1]}
                                                    onClick={() => {
                                                        //@ts-ignore
                                                        getLatLong('', '', '', values?.address?.zip_code)
                                                            .then((coords: any) => {
                                                                //@ts-ignore
                                                                if (values.location.coordinates[0] && values.location.coordinates[1]) {
                                                                    //@ts-ignore
                                                                    const googleMapsUrl = `https://www.google.com/maps?q=${values.location.coordinates[1]},${values.location.coordinates[0]}`;
                                                                    window.open(googleMapsUrl, '_blank');
                                                                }
                                                            })
                                                            .catch((error) => console.error(error));
                                                    }}>
                                                Map
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-container">
                                    <Field fullWidth variant="outlined" name="location.coordinates[1]"
                                           component={TextField} label="Latitude" autoComplete="off"
                                           InputLabelProps={{shrink: true}}
                                           id="input_facility_edit_latitude"/>
                                    <Field fullWidth variant="outlined" name="location.coordinates[0]"
                                           component={TextField} label="Longitude" autoComplete="off"
                                           InputLabelProps={{shrink: true}}
                                           id="input_facility_edit_longitude"/>
                                </div>

                                <div className="facility-about mrg-top-10">
                                    <p className="card-header">About the Applicant</p>
                                    <Field variant="outlined" component={TextField} type={"text"} fullWidth
                                           InputLabelProps={{shrink: true}}
                                           autoComplete="off" id="input_hcp_edit_about" name="about" multiline/>
                                </div>
                            </div>

                            <div className="custom-border">
                                <div className="professional-summary mrg-top-10 ">
                                    <p className="card-header">Professional Summary</p>
                                    <Field variant="outlined" component={TextField} type={"text"} fullWidth
                                           InputLabelProps={{shrink: true}}
                                           autoComplete="off" name="professional_details.summary"
                                           id="input_hcp_edit_summary" multiline/>
                                </div>
                            </div>

                            <div className="custom-border">
                                <div className="hcp-profession-details mrg-top-10">
                                    <p className="card-header">Professional Details (Based On Work Experience)</p>
                                    <div className="input-container">
                                        <Field value={expInYears} disabled variant="outlined" component={TextField}
                                               InputLabelProps={{shrink: true}}
                                               label="Years of Experience" name="professional_details.experience"
                                               id="input_hcp_edit_proffesional_details" fullWidth
                                               autoComplete="off"/>
                                    </div>
                                    <div className="input-container ">
                                        <Field value={specialities} disabled variant="outlined"
                                               component={TextField} InputLabelProps={{shrink: true}}
                                               type={"text"} label="Specialities" id="input_hcp_edit_speciality"
                                               name="professional_details.speciality" fullWidth autoComplete="off"/>
                                    </div>
                                </div>
                            </div>

                            <div className="travel-preferences custom-border mrg-top-10">
                                <p className="card-header">Travel Preferences</p>
                                <div className="travel-preferences-container">
                                    {travelDistancePreference.map((item) => (
                                        <Field type="checkbox" component={CheckboxWithLabel}
                                               name="nc_details.travel_preferences" key={item.value} value={item.value}
                                               Label={{label: item.label}}/>
                                    ))}
                                </div>
                            </div>

                            <div className="notification-preferences custom-border mrg-top-10">

                                <p className="card-header">Notification Preferences</p>
                                <div className="notification-preferences-container">
                                    <Field type="checkbox" component={CheckboxWithLabel}
                                           name="notification_preferences.sms"
                                           Label={{label: "SMS"}}/>
                                    <Field type="checkbox" component={CheckboxWithLabel}
                                           name="notification_preferences.email" Label={{label: "Email"}}/>
                                    <Field type="checkbox" component={CheckboxWithLabel}
                                           name="notification_preferences.push_notification"
                                           Label={{label: "In-application"}}/>
                                </div>
                            </div>

                            <div className="nc-section custom-border mrg-top-10">
                                <p className="card-header">NC Section</p>
                                <div className="input-container">
                                    <Field variant="outlined" name="nc_details.dnr" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="DNR" id="input_hcp_add_dnr" fullWidth autoComplete="off"/>
                                    <Field SelectProps={showDropDownBelowField} select variant="outlined"
                                           name="nc_details.contact_type" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           id="input_hcp_add_contact_type" label="Contact Type" fullWidth
                                           autoComplete="off">
                                        <MenuItem value="">Select Value</MenuItem>
                                        {contactType.map((item: any, index: any) => (
                                            <MenuItem value={item.value} id={"menu_hcp_add_contact_type" + index}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </div>

                                <div className="input-container">
                                    <Field variant="outlined" name="nc_details.location_preference" type={"text"}
                                           component={TextField} label="Preferred Location to Work"
                                           InputLabelProps={{shrink: true}}
                                           id="input_hcp_add_location_preference" fullWidth autoComplete="off"/>

                                    <Field
                                        SelectProps={showDropDownBelowField}
                                        select
                                        variant="outlined"
                                        name="nc_details.shift_type_preference"
                                        type={"text"}
                                        component={TextField}
                                        id="input_hcp_add_shift_type_preference"
                                        label="Preference Shift Type"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true}}
                                    >
                                        <MenuItem value="">Select Shift Type</MenuItem>
                                        {shiftTypePreference.map((item: any, index: any) => (
                                            <MenuItem value={item.value}
                                                      id={"menu_hcp_add_shift_type_preference" + index}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </div>

                                <div className="input-container">
                                    <Field variant="outlined" name="nc_details.zone_assignment" type={"text"}
                                           InputLabelProps={{shrink: true}}
                                           component={TextField} id="input_hcp_add_zone_assignment"
                                           label="Zone Assignment"
                                           fullWidth autoComplete="off"/>
                                    <Field
                                        variant="outlined"
                                        component={TextField}
                                        placeholder="MM-DD-YYYY"
                                        fullWidth
                                        autoComplete="off"
                                        label="Last Call Date  (MM-DD-YYYY)"
                                        name="nc_details.last_call_date"
                                        InputLabelProps={{shrink: true}}
                                    />
                                </div>

                                <div className="input-container">

                                    <Field
                                        onClick={(e: any) => {
                                            setFieldValue("nc_details.vaccine", e.target.value);
                                            setVaccineStatus(e.target.value);
                                            if (e.target.value === 'exempted') {
                                                setFieldValue("nc_details.vaccination_dates.first_shot", '');
                                                setFieldValue("nc_details.vaccination_dates.latest_shot", '');
                                            }
                                            if (e.target.value === '') {
                                                setFieldValue("nc_details.vaccination_dates.first_shot", '');
                                                setFieldValue("nc_details.vaccination_dates.latest_shot", '');
                                            }

                                            if (e.target.value === 'half') {
                                                setFieldValue("nc_details.vaccination_dates.first_shot", '');
                                                setFieldValue("nc_details.vaccination_dates.latest_shot", '');
                                            }
                                        }}
                                        SelectProps={showDropDownBelowField}
                                        select
                                        variant="outlined"
                                        name="nc_details.vaccine"
                                        type={"text"}
                                        component={TextField}
                                        id="input_hcp_add_vaccine"
                                        label="Vaccine"
                                        fullWidth
                                        autoComplete="off"
                                    >
                                        <MenuItem value="">Select Value</MenuItem>
                                        {vaccine.map((item: any, index: any) => (
                                            <MenuItem value={item.value} id={"menu_hcp_add_vaccine_" + index}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                    <Field variant="outlined" name="nc_details.family_consideration" type={"text"}
                                           component={TextField} id="input_hcp_add_family_consideration"
                                           InputLabelProps={{shrink: true}}
                                           label="Family Considerations" fullWidth autoComplete="off"/>
                                </div>

                                <div
                                    className={`${isFirstShotVisible && isLastShotVisible ? "input-container" : "input-container"}`}>
                                    {isFirstShotVisible && (
                                        <Field variant="outlined" name="nc_details.vaccination_dates.first_shot"
                                               type={"text"} component={TextField} label="First Shot Date  (MM-DD-YYYY)"
                                               id="input_hcp_add_vaccination_dates_first_shot" fullWidth
                                               InputLabelProps={{shrink: true}}
                                               autoComplete="off"/>
                                    )}
                                    {isLastShotVisible && (
                                        <Field variant="outlined" name="nc_details.vaccination_dates.latest_shot"
                                               type={"text"} component={TextField}
                                               label="Latest Shot Date  (MM-DD-YYYY)"
                                               id="input_hcp_add_vaccination_dates_latest_shot" fullWidth
                                               InputLabelProps={{shrink: true}}
                                               autoComplete="off"/>
                                    )}
                                </div>

                                <div className="input-container d-flex">
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel
                                                className={"form-label"}>{"Do you have a Full-time Job?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.is_fulltime_job">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel key={"full-time" + index}
                                                                                  value={item.value}
                                                                                  control={<Radio
                                                                                      disabled={isSubmitting}/>}
                                                                                  disabled={isSubmitting}
                                                                                  name="nc_details.is_fulltime_job"
                                                                                  label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel
                                                className={"form-label"}>{"Covid (or) Non Covid Facility?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.covid_facility_preference">
                                                <div className="d-flex">
                                                    {covidPreference.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel key={"full-time" + index}
                                                                                  value={item.value}
                                                                                  control={<Radio
                                                                                      disabled={isSubmitting}/>}
                                                                                  disabled={isSubmitting}
                                                                                  name="nc_details.covid_facility_preference"
                                                                                  label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-container d-flex">
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel
                                                className={"form-label"}>{"What is more important for you?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.more_important_preference">
                                                <div className="d-flex">
                                                    {moreImportant.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_more_important_preference" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.more_important_preference"
                                                                    label={item.label}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel
                                                className={"form-label"}>{"Is this a Supplement to your Income ?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.is_supplement_to_income">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_more_important_preference" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.is_supplement_to_income"
                                                                    label={item.label}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-container d-flex">
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel className={"form-label"}>{"Are you Studying?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.is_studying">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_more_important_preference" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.is_studying"
                                                                    label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                    {/*<div className="flex-1">*/}
                                    {/*    <div className="pdd-top-10">*/}
                                    {/*        <FormLabel className={"form-label"}>{"Gusto"}</FormLabel>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="mrg-top-10">*/}
                                    {/*        <Field component={RadioGroup} name="nc_details.gusto_type">*/}
                                    {/*            <div className="d-flex">*/}
                                    {/*                {gustoType.map((item: any, index) => {*/}
                                    {/*                    return (*/}
                                    {/*                        <div>*/}
                                    {/*                            <FormControlLabel*/}
                                    {/*                                key={"input_hcp_add_more_important_preference" + index}*/}
                                    {/*                                value={item.value}*/}
                                    {/*                                control={<Radio disabled={isSubmitting}/>}*/}
                                    {/*                                disabled={isSubmitting} name="nc_details.gusto_type"*/}
                                    {/*                                label={item.label}/>*/}
                                    {/*                        </div>*/}
                                    {/*                    );*/}
                                    {/*                })}*/}
                                    {/*            </div>*/}
                                    {/*        </Field>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="input-container d-flex">
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel className={"form-label"}>{"Is Gusto Invited ?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.is_gusto_invited">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_more_important_preference" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.is_gusto_invited"
                                                                    label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel className={"form-label"}>{"Is Gusto Onboarded ?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.is_gusto_onboarded">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_gusto_onboarded" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.is_gusto_onboarded"
                                                                    label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-container d-flex">
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel className={"form-label"}>Require Sponsorship for Employment in
                                                United
                                                States?</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup}
                                                   name="nc_details.is_require_employment_sponsorship">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_is_require_employment_sponsorship" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.is_require_employment_sponsorship"
                                                                    label={item.label}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel className={"form-label"}>Legally Authorized to work in United
                                                States?</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.is_authorized_to_work">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_is_authorized_to_work" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.is_authorized_to_work"
                                                                    label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-container">
                                    <Field multiline rows={2} variant="outlined" name="nc_details.other_information"
                                           type={"text"} component={TextField} id="input_hcp_add_other_information"
                                           InputLabelProps={{shrink: true}}
                                           label="Other Information Gathered" fullWidth autoComplete="off"/>
                                </div>
                            </div>

                            {/*<div className="custom-border mrg-top-10 pdd-top-10 pdd-left-40 pdd-right-40 pdd-bottom-40">*/}
                            {/*    <h3 className="card-header">Documents/Attachments</h3>*/}
                            {/*    <div className="attachments_wrapper mrg-top-30">*/}
                            {/*        <HcpEditAttachmentsComponent*/}
                            {/*            setFileUpload={setFileUpload}*/}
                            {/*            setRequiredAttachments={setRequiredAttachments}*/}
                            {/*            attachmentsDetails={attachmentsDetails}*/}
                            {/*            required_attachments={required_attachments}*/}
                            {/*            handleExpiryDate={handleExpiryDate}*/}
                            {/*            fileUpload={fileUpload}*/}
                            {/*            previewFile={previewFile}*/}
                            {/*            isDeleted={isDeleted}*/}
                            {/*            openDeleteAttachment={openDeleteAttachment}*/}
                            {/*            OnFileSelected={OnFileSelected}*/}
                            {/*            deleteLocalAttachment={deleteLocalAttachment}*/}
                            {/*            otherDocuments={otherDocuments}*/}
                            {/*            setOtherDocuments={setOtherDocuments}*/}

                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}


                            <div className="custom-border">
                                <h3 className="card-header">Documents/Attachments</h3>
                                <HcpEditAttachmentComponentANTD attachmentsDetails={attachmentsDetails}
                                                                hcpDetails={hcpDetails} id={id}
                                                                getAttachmentsDetails={getAttachmentsDetails}
                                                                uploadedFiles={uploadedFiles}
                                                                setUploadedFiles={setUploadedFiles}
                                                                apiUploadedFiles={apiUploadedFiles}
                                                                setApiUploadedFiles={setApiUploadedFiles}
                                />
                            </div>


                            <div className="mrg-top-10 custom-border">
                                <p className="card-header">Contract</p>
                                {hcpDetails?.contract_details?.url ? (
                                    <div className="attachments">
                                        <div className="custom_file">
                                            <div className="d-flex">
                                                <div className="mrg-top-15">
                                                    <InsertDriveFileIcon color={"primary"}
                                                                         className="file-icon"/>
                                                </div>
                                                <div className="file_details mrg-left-20"></div>
                                            </div>
                                            <div className="contract_actions mrg-left-5 mrg-top-10 ">
                                                <Tooltip title={`Delete Contract`}>
                                                    <button style={{cursor: "pointer", width: "50px"}}
                                                            disabled={isContractDeleted}
                                                            onClick={openDeleteContract}
                                                            className="delete-button mrg-left-10">
                                                        Delete
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {contractFile?.wrapper?.map((item: any, index: any) => {
                                            return (
                                                <div className="attachments">
                                                    <div className="d-flex mrg-top-30">
                                                        <div className={'edit-contract-file-wrapper'}>
                                                            <div className={'file-preview-image'}>
                                                                <InsertDriveFileIcon color={"primary"}
                                                                                     className="edit-file-image"/>
                                                            </div>
                                                        </div>
                                                        <div className={'mrg-top-10'}>
                                                            <div className={'file-preview-btn-wrapper'}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="small"
                                                                    onClick={() => previewFile(index, "contract")}
                                                                    style={{marginRight: "8px"}}
                                                                >
                                                                    View
                                                                </Button>
                                                                <Button
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    size="small"
                                                                    onClick={() => deleteContractFile(index)}
                                                                    style={{marginRight: "8px"}}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                style={{marginTop: "8px", color: "#888"}}
                                                            >
                                                                File type: ".pdf"
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {contractFile?.wrapper.length >= 1 ? (
                                            <></>
                                        ) : (
                                            <Box display="flex" gridGap="10px">
                                                <Tooltip title={"Upload Contract"}>
                                                    <Box width="250px" className="mrg-top-10">
                                                        {/*<FileDropZoneComponent allowedTypes={".pdf"}*/}
                                                        {/*                       OnFileSelected={OnContractFileUpload}/>*/}
                                                        <FileUploadComponent allowedTypes={".pdf"}
                                                                             onFileSelected={OnContractFileUpload}/>
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        )}
                                    </>
                                )}
                                <div className="input-container mrg-top-30">
                                    <Field variant="outlined" component={TextField} type={"text"} fullWidth
                                           InputLabelProps={{shrink: true}}
                                           autoComplete="off" label="Rate / hr"
                                           name="contract_details.rate_per_hour"/>
                                    <Field placeholder="Checkrpay ID" variant="outlined" component={TextField}
                                           type={"text"}
                                           fullWidth autoComplete="off" InputLabelProps={{shrink: true}}
                                           label="Checkrpay ID"
                                           name="worker_id"/>
                                    <Field
                                        orientation="landscape"
                                        variant="inline"
                                        openTo="date"
                                        views={["year", "month", "date"]}
                                        inputVariant="outlined"
                                        component={FormikDatePicker}
                                        placeholder="MM/DD/YYYY"
                                        format="MM/dd/yyyy"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true}}
                                        label="Signed On"
                                        name="contract_details.signed_on"
                                    />
                                    <Field SelectProps={showDropDownBelowField} select variant="outlined"
                                           InputLabelProps={{shrink: true}}
                                           name="contract_details.salary_credit" type={"text"}
                                           component={TextField}
                                           id="input_hcp_add_salary_credit" label="Salary Credit Day" fullWidth
                                           autoComplete="off">
                                        <MenuItem value="">Select Value</MenuItem>
                                        {salaryCredit.map((item: any, index: any) => (
                                            <MenuItem value={item.value}
                                                      id={"menu_hcp_add_salary_credit_" + index}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Field>

                                    <Field
                                        SelectProps={showDropDownBelowField}
                                        select
                                        variant="outlined"
                                        name="nc_details.gusto_type"
                                        type={"text"}
                                        component={TextField}
                                        id="input_hcp_add_salary_credit"
                                        label="Applicant Payment Mode"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true}}
                                    >
                                        <MenuItem value="">Select Value</MenuItem>
                                        {hcpPaymentModeOptions.map((item: any, index: any) => (
                                            <MenuItem key={index} value={item.value}
                                                      id={"menu_hcp_add_salary_credit_" + index}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </div>
                            </div>
                            {hcpDetails && hcpDetails?.status !== "pending" && <>
                                <div className="mrg-top-10 custom-border">
                                    <p className="card-header" onClick={() => {
                                        console.log(hcpDetails);
                                    }}>Profile</p>
                                    {hcpDetails?.profile_url ? (
                                        <div className="attachments">
                                            <div className="custom_file">
                                                <div className="d-flex">
                                                    <div className="mrg-top-15">
                                                        <img src={hcpDetails?.profile_url} alt=""
                                                             style={{
                                                                 height: "100px",
                                                                 width: "100px"
                                                             }}
                                                        />
                                                    </div>
                                                    <div className="file_details mrg-left-20"></div>
                                                </div>
                                                <div className="contract_actions mrg-left-5 mrg-top-10 ">
                                                    <Tooltip title={`Delete Profile`}>
                                                        <button style={{cursor: "pointer", width: "50px"}}
                                                                disabled={isProfileImageDeleted}
                                                                onClick={openDeleteProfileImage}
                                                                className="delete-button mrg-left-10">
                                                            Delete
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {/*{profileImageFile?.wrapper?.map((item: any, index: any) => {*/}
                                            {/*    return (*/}
                                            {/*        <div className="attachments">*/}
                                            {/*            <div className="custom_file">*/}
                                            {/*                <div className="d-flex">*/}
                                            {/*                    <div className="mrg-top-15">*/}
                                            {/*                        <img src={item?.file?.base64} alt="" style={{*/}
                                            {/*                            height: "100px",*/}
                                            {/*                            width: "100px",*/}
                                            {/*                            cursor: "pointer"*/}
                                            {/*                        }}/>*/}
                                            {/*                    </div>*/}
                                            {/*                    <div className="file_details mrg-left-20"></div>*/}
                                            {/*                </div>*/}
                                            {/*                <div className="d-flex contract_actions mrg-left-5 mrg-top-10">*/}
                                            {/*                    <Tooltip title={"View Profile"}>*/}
                                            {/*                        <button style={{cursor: "pointer"}}*/}
                                            {/*                                onClick={() => previewProfileImageFile(index)}*/}
                                            {/*                                className="delete-button">*/}
                                            {/*                            View*/}
                                            {/*                        </button>*/}
                                            {/*                    </Tooltip>*/}
                                            {/*                    <Tooltip title={"Delete Profile"}>*/}
                                            {/*                        <button style={{cursor: "pointer", width: "50px"}}*/}
                                            {/*                                disabled={isProfileImageDeleted}*/}
                                            {/*                                className="mrg-left-20 delete-button"*/}
                                            {/*                                onClick={() => deleteProfileImage(index)}>*/}
                                            {/*                            Delete*/}
                                            {/*                        </button>*/}
                                            {/*                    </Tooltip>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    );*/}
                                            {/*})}*/}
                                            {/*{profileImageFile?.wrapper.length >= 1 ? (*/}
                                            {/*    <></>*/}
                                            {/*) : (*/}
                                            {/*    <Box display="flex" gridGap="10px">*/}
                                            {/*        <Tooltip title={"Upload Contract"}>*/}
                                            {/*            <Box width="250px" className="mrg-top-10">*/}
                                            {/*                <FileDropZoneComponent allowedTypes={".png,.jpeg,.jpg"}*/}
                                            {/*                                       OnFileSelected={onProfileImageUpload}/>*/}
                                            {/*            </Box>*/}
                                            {/*        </Tooltip>*/}
                                            {/*    </Box>*/}
                                            {/*)}*/}
                                            {profileImageFile?.wrapper.length > 0 ? (
                                                profileImageFile?.wrapper?.map((item: any, index: any) => {
                                                    return (
                                                        <div className="attachments">
                                                            <div className="custom_file">
                                                                <div className="d-flex">
                                                                    <div className="mrg-left-0 mrg-top-10">
                                                                        {" "}
                                                                        <Tooltip title="Preview Facility Icon">
                                                                            {item?.file?.type === "image/jpg" || item?.file?.type === "image/png" || item?.file?.type === "image/jpeg" ? (
                                                                                <img src={item?.file?.base64}
                                                                                     alt=""
                                                                                     style={{
                                                                                         height: "100px",
                                                                                         width: "100px"
                                                                                     }}
                                                                                     onClick={() => previewProfileImageFile(index)}/>
                                                                            ) : (
                                                                                <img src={PdfIcon} alt=""
                                                                                     style={{
                                                                                         height: "100px",
                                                                                         width: "100px"
                                                                                     }}
                                                                                     onClick={() => previewProfileImageFile(index)}/>
                                                                            )}
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div
                                                                        className="file_details mrg-left-20"></div>
                                                                </div>
                                                                <div
                                                                    className="d-flex image_actions mrg-top-10">
                                                                    <p style={{cursor: "pointer"}}
                                                                       onClick={() => previewProfileImageFile(index)}
                                                                       className="delete-image">
                                                                        View
                                                                    </p>
                                                                    <p style={{cursor: "pointer"}}
                                                                       onClick={() => deleteProfileImage(index)}
                                                                       className="delete-image mrg-left-20">
                                                                        Delete
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <Box display="flex" gridGap="10px">
                                                    <Tooltip title={"Upload Facility Image"}>
                                                        <Box width="250px" className="mrg-top-10">
                                                            <FileUploadComponent
                                                                allowedTypes={".jpg,.png,.jpeg,.jpg"}
                                                                onFileSelected={onProfileImageUpload}
                                                            />
                                                            {/*<FileDropZoneComponent allowedTypes={".jpg,.png,.jpeg,.jpg"}*/}
                                                            {/*                       OnFileSelected={onProfileImageUpload}/>*/}
                                                        </Box>
                                                    </Tooltip>
                                                </Box>
                                            )}
                                        </>

                                    )}
                                </div>
                            </>}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditHcpBasicDetailsComponent;