import "./PayRateComponent.scss";
import {FieldArray, useFormikContext} from "formik";
import React, {useEffect} from "react";
import FormikInputComponent from "../../../../../../ant-component/formik-input-component/FormikInputComponent";
import {ImageConfig} from "../../../../../../../constants";
import {Button} from "@material-ui/core";
import { InfoCircleOutlined } from "@ant-design/icons";
import {Tooltip} from "antd";

interface PayRateComponentProps{
    contractDetails?:any;
}

const PayRateComponent=(props:PayRateComponentProps)=> {
    const {values, setFieldValue} = useFormikContext<any>();
    const {contractDetails} = props;

    useEffect(() => {
        if (contractDetails?.days_per_week && contractDetails?.shift_duration) {
            values?.pay_rate?.travel_per_diems?.forEach((perDiem: any, index: number) => {
                const finalValue = Number(perDiem.rate) * Number(contractDetails.days_per_week) * Number(contractDetails.shift_duration);
                setFieldValue(`pay_rate.travel_per_diems.${index}.week_rate`, finalValue);
            });
        }
    }, [contractDetails?.days_per_week, contractDetails?.shift_duration,values?.pay_rate?.travel_per_diems,setFieldValue]);


    return (
        <div className={'pay-rate-component'}>
            {/*<div className={'mrg-bottom-10'}>*/}
            {/*    <div className={'mrg-top-5 flex-equal'}>*/}
            {/*        <FormLabelComponent Label={"Pay rate"}/>*/}
            {/*    </div>*/}
            {/*    <div className={'flex-double mrg-top-10 mrg-bottom-20'}>*/}
            {/*        <FormikInputComponent name="pay_rate.total_pay_rate" size={'middle'}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <FieldArray name="pay_rate.travel_per_diems">
                {({push, remove}) => (
                    <div className="pay-rate-card">
                            {values?.pay_rate?.travel_per_diems?.map((perDiem: any, index: number) => (
                                <div
                                    className="shift-row mrg-top-20">
                                    <FormikInputComponent
                                        name={`pay_rate.travel_per_diems.${index}.type`}
                                        disabled={index < 3}
                                        placeholder={'Enter Value'}
                                        className="time-wrapper"
                                    />
                                    <FormikInputComponent
                                        name={`pay_rate.travel_per_diems.${index}.rate`}
                                        placeholder={index === 0 ?'Gross hourly Rate' :'Gross Day Rate'}
                                        type={'number'}
                                        className="time-wrapper"
                                        prefix={"$"}
                                        suffix={index === 0 ? "/ Hr" : "/ day"}
                                        onUpdate={(value:any)=>{
                                            const finalValue=Number(value)*Number(contractDetails?.days_per_week || 1) * Number(contractDetails?.shift_duration || 1);
                                            setFieldValue(`pay_rate.travel_per_diems.${index}.week_rate`,finalValue)
                                        }}
                                    />
                                    <div className={'d-flex'}
                                         style={{minWidth: "31.5%"}}>
                                        <FormikInputComponent
                                            name={`pay_rate.travel_per_diems.${index}.week_rate`}
                                            placeholder={'Gross Weekly Rate'}
                                            type={'number'}
                                            disabled={true}
                                            className="time-wrapper"
                                            prefix={
                                            <div className={'d-flex'}>
                                                {index === 0 &&
                                                    <Tooltip title={'Total = Hourly Rate * Hours Per day * Days per week'} className={'mrg-right-4'}>
                                                        <InfoCircleOutlined style={{color: "#ffa940"}}/>
                                                    </Tooltip>
                                                }
                                                <div className={`${index === 0 ? 'mrg-left-10':''}`}>
                                                    $
                                                </div>
                                            </div>
                                        }
                                            suffix={"/ week"}
                                        />
                                        {(values?.pay_rate?.travel_per_diems?.length > 3 && index > 2) &&
                                            <div
                                                className="ts-col-1 ts-justify-content-center ts-align-content-center mrg-top-10 mrg-left-10">
                                                <div
                                                    onClick={() => {
                                                        remove(index)
                                                    }}
                                                    className={'cursor-pointer'}>
                                                    <img
                                                        src={ImageConfig.ContractDelete}
                                                        alt={ImageConfig.ContractDelete}/>
                                                </div>
                                            </div>}
                                    </div>

                                </div>
                            ))}
                                <div
                                    className={'d-flex justify-content-space-between mrg-top-10'}>
                                    <div
                                        className={'d-flex ts-justify-content-center align-items-center'}>
                                        <Button
                                            variant={"outlined"}
                                            color={"primary"}
                                            onClick={() =>
                                                push({
                                                    type: '',
                                                    rate: "",
                                                    week_rate: ""
                                                })
                                            }
                                        >
                                            Add More
                                        </Button>
                                    </div>
                                </div>
                    </div>
                )}
            </FieldArray>
        </div>
    )
}

export default PayRateComponent