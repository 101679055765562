import React, {useCallback, useEffect, useRef, useState} from "react";
import {CommonService, Communications} from "../../helpers";
import "./DashboardScreen.scss";
import {fromLonLat, toLonLat} from "ol/proj";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import 'ol/ol.css';
import {Cluster, XYZ} from "ol/source";
import {Feature} from "ol";
import {Point} from "ol/geom";
import {Fill, Icon, Stroke, Style, Text} from "ol/style";
import VectorLayer from "ol/layer/Vector";
import {ENV, ImageConfig} from "../../constants";
import VectorSource from "ol/source/Vector";
import LegendCardComponent from "./legend-card/LegendCardComponent";
import FilterComponentForMap from "./filter-for-map/FilterComponentForMap";
import HcpDetailsComponentOnMap from "./hcp-card-details/HcpDetailsComponentOnMap";
import moment from "moment";
import FacilityDetailsComponent from "./facility-card-details/FacilityDetailsComponent";
import {ZoomMinus, ZoomPlus} from "../../constants/ImageConfig";
import {createEmpty, extend, getHeight, getWidth} from "ol/extent";
import {useLocalStorage} from "../../components/useLocalStorage";
import {useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";

const DEFAULT_LONG_LAT = [-119.36237067709004, 37.1917919282538];
const DEFAULT_ZOOM_LEVEL = 3;
const MIN_ZOOM_LEVEL = 3;
const MAX_ZOOM_LEVEL = 20;
const DEFAULT_CLUSTER_DISTANCE = 30;
const DEFAULT_CLUSTER_MIN_DISTANCE = 10;

const MarkerMapping = {
    'ActiveHcp': ImageConfig.ActiveHcpIcon,
    'InActiveHcp': ImageConfig.InActiveHcpIcon,
    'selectedHcp': ImageConfig.HcpSelectedIcon,
    'ActiveFacility': ImageConfig.ActiveFacilityIcon,
    'InActiveFacility': ImageConfig.InActiveFacilityIcon,
    'selectedFacility': ImageConfig.FacilitySelectedIcon,
    'openShiftFacility': ImageConfig.OpenShiftFacilityIcon
};


const DashboardScreen = () => {
    const [mapPointer, setMapPointer] = useState(DEFAULT_LONG_LAT);
    const [mapPosition, setMapPosition] = useLocalStorage("mapPosition", DEFAULT_LONG_LAT);
    const [map, setMap] = useLocalStorage<any>("map", null);
    const mapElement = useRef<any>();
    const [hcpList, setHcpList] = useLocalStorage<any>("hcp-list", null);
    const [facilityList, setFacilityList] = useLocalStorage<any>("facility-list", null);
    const [facilityOpenStatusList, setFacilityOpenStatusList] = useLocalStorage<any>("facility-open-status-shift-list", null);
    const [zoomLevel, setZoomLevel] = useLocalStorage<any>("zoom-level", DEFAULT_ZOOM_LEVEL);
    const [hcpDetails, setHcpDetails] = useLocalStorage<any>("hcp-details-map-component", null);
    const [isHcpDetailsCardOpen, setIsHcpDetailsCardOpen] = useLocalStorage<boolean>("is-hcp-card-open", false);
    const [isFacilityDetailsCardOpen, setIsFacilityDetailsCardOpen] = useLocalStorage<boolean>("is-facility-card-open", false);
    // const [hcpTypes, setHcpTypes] = useLocalStorage<any>("hcp-types", null);
    const {hcpTypes}=useSelector((state:StateParams)=>state.meta)
    const [selectedHcpTypes, setSelectedHcpTypes] = useLocalStorage<any>("selected-hcp-types", null);
    const [selectedHcp, setSelectedHcp] = useLocalStorage<any>("selected-hcp", null);
    const [selectedFacility, setSelectedFacility] = useLocalStorage<any>("selected-facility", null);
    const {regions}=useSelector((state:StateParams)=>state.meta)
    const [selectedRegion, setSelectedRegion] = useLocalStorage<any>("selected-region", null);
    const [selectedHcpTypeForNearbyHcp, setSelectedHcpTypeForNearbyHcp] = useState<any>(null);
    const [selectedHcpRegionForNearbyHcp, setSelectedHcpRegionForNearbyHcp] = useState<any>(null);
    const [facilityNavigation, setFacilityNavigation] = useState<any>("facility-details");
    const [facilityWithOpenShift, setFacilityWithOpenShift] = useState<any>([]);
    const [facilityWithoutOpenShift, setFacilityWithoutOpenShift] = useState<any>([]);
    const [checkedFacility, setCheckedFacility] = useState<any>(null);
    const [isLegendCardOpen, setIsLegendCardOpen] = useLocalStorage<any>("isLegendCardOpen", false);
    const [selectedLegend, setSelectedLegend] = useLocalStorage<any>("selectedLegend", ["Open Shift"]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Map View");
        Communications.pageBackButtonSubject.next(null);
    }, []);


    const init = useCallback(() => {
        let url = "dashboard/hcpLite";
        let payload: any = {};
        payload.is_approved = true;
        CommonService._api.post(ENV.API_URL + url, payload).then((resp) => {
            setHcpList(resp?.data);
        }).catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const facilityListInit = useCallback(() => {
        let facilityUrl = "dashboard/facilityLite";
        let facilityPayload: any = {};
        CommonService._api.post(ENV.API_URL + facilityUrl, facilityPayload).then((resp) => {
            setFacilityList(resp?.data);
        }).catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const openShiftInit = useCallback(() => {
        let openShiftFacilityUrl = "dashboard/facilityMapList";
        let openShiftFacilityPayload: any = {};
        openShiftFacilityPayload.is_active = true;
        openShiftFacilityPayload.shift_requirements = true;
        openShiftFacilityPayload.new_shifts = moment(new Date()).format("YYYY-MM-DD");
        CommonService._api.post(ENV.API_URL + openShiftFacilityUrl, openShiftFacilityPayload).then((resp) => {
            setFacilityOpenStatusList(resp?.data);
        }).catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const getHcpList = useCallback(() => {
        let url = "dashboard/hcpLite";
        if (!hcpList) {
            init();
            return;
        }
        let payload: any = {};
        payload.is_approved = true;
        if (selectedHcpTypes) {
            payload.hcp_types = [selectedHcpTypes?.title];
        }
        if (selectedRegion) {
            payload.regions = [selectedRegion?.title];
        }
        if (selectedHcpTypeForNearbyHcp) {
            payload.hcp_types = [selectedHcpTypeForNearbyHcp?.title];
        }
        if (selectedHcpRegionForNearbyHcp) {
            payload.regions = [selectedHcpRegionForNearbyHcp?.title];
        }

        CommonService._api.post(ENV.API_URL + url, payload).then((resp) => {
            setHcpList(resp?.data);
        }).catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [selectedHcpTypes, selectedRegion, selectedHcpRegionForNearbyHcp, selectedHcpTypeForNearbyHcp]);

    const getFacilityList = useCallback(() => {
        let facilityUrl = "dashboard/facilityLite";
        if (!facilityList) {
            facilityListInit();
            return;
        }
        let facilityPayload: any = {};
        if (selectedRegion) {
            facilityPayload.regions = [selectedRegion?.title];
        }
        CommonService._api.post(ENV.API_URL + facilityUrl, facilityPayload).then((resp) => {
            setFacilityList(resp?.data);
        }).catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedFacility && facilityWithOpenShift) {
            if (!selectedLegend.includes("Open Shift") && facilityWithOpenShift?.some((item: any) => item?._id === selectedFacility?._id)) {
                setSelectedFacility(null);
                setIsFacilityDetailsCardOpen(false);
            }
        }
        if (selectedFacility && facilityWithoutOpenShift) {
            if (!selectedLegend.includes("Active Facility") && facilityWithoutOpenShift?.some((item: any) => item?._id === selectedFacility?._id)) {
                setSelectedFacility(null);
                setIsFacilityDetailsCardOpen(false);
            }
        }
        if (!selectedLegend.includes("Active Hcp")) {
            setSelectedHcp(null);
            setIsHcpDetailsCardOpen(false);
        }
        // eslint-disable-next-line
    }, [selectedLegend, facilityOpenStatusList, facilityWithoutOpenShift, selectedFacility]);

    useEffect(() => {
        getHcpList();
    }, [getHcpList]);

    useEffect(() => {
        getFacilityList();
    }, [getFacilityList]);

    useEffect(() => {
        openShiftInit();
    }, [openShiftInit]);


    useEffect(() => {
        if(facilityList) {
            const filterWithoutOpenShift = facilityList?.filter((item: any) => !facilityOpenStatusList?.some((items: any) => item?._id === items?._id));
            setFacilityWithoutOpenShift(filterWithoutOpenShift);
            const filterByOpenShift = facilityList?.filter((item: any) => facilityOpenStatusList?.some((items: any) => item?._id === items?._id));
            setFacilityWithOpenShift(filterByOpenShift);
        }
    }, [facilityList, facilityOpenStatusList, setFacilityWithoutOpenShift, setFacilityWithOpenShift]);

    const mapCreation = useCallback(() => {
        console.log('map created');
        //  const lonLat = fromLonLat(DEFAULT_LONG_LAT);
        const initialMap = new Map({
            target: mapElement.current,
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
                    }),
                }),
            ],
            view: new View({
                center: mapPosition,
                zoom: zoomLevel,
                minZoom: MIN_ZOOM_LEVEL,
                maxZoom: MAX_ZOOM_LEVEL,
            }),
            controls: [],
        });
        initialMap.on('loadstart', function () {
            initialMap.getTargetElement().classList.add('map-loading');
        });
        initialMap.on('loadend', function () {
            initialMap.getTargetElement().classList.add('map-loading');
        });
        initialMap.on('loadend', function () {
            initialMap.getTargetElement().classList.remove('map-loading');
        });
        setMap(initialMap);
        // eslint-disable-next-line
    }, [zoomLevel, mapPosition]);

    useEffect(() => {
        if (map) {
            return;
        } else {
            mapCreation();
        }
    }, [map, mapCreation]);

    const createMarker = useCallback((lng: any, lat: any, value: any) => {
        return new Feature({
            geometry: new Point(fromLonLat([parseFloat(lng), parseFloat(lat)]),),
            value: value,
        });
    }, []);

    const zoomTo = useCallback((zoomAmount: any) => {
        const updatedZoomLevel = zoomLevel + zoomAmount;
        if (map && (updatedZoomLevel <= MAX_ZOOM_LEVEL && MIN_ZOOM_LEVEL <= updatedZoomLevel)) {
            const view = map.getView();
            view.animate({zoom: updatedZoomLevel});
            setZoomLevel(updatedZoomLevel);
        }
        // eslint-disable-next-line
    }, [zoomLevel, map]);

    useEffect(() => {
        if (map) {
            map.on('moveend', function (e: any) {
                const newZoom = map.getView().getZoom();
                const newCenter = map.getView().getCenter();
                if (zoomLevel !== newZoom && newZoom) {
                    setZoomLevel(Math.round(newZoom));
                }
                if (newCenter !== mapPosition && newCenter) {
                    setMapPosition(newCenter);
                }
            });
            map.addEventListener("pointermove", (e: any) => {
                //EventManager.fireEvent('pointer-changed', e);
                const lonLat = toLonLat(e.coordinate);
                setMapPointer([lonLat[1], lonLat[0]]);
                if (!e.dragging) {
                    map.getTargetElement().style.cursor = map.hasFeatureAtPixel(map.getEventPixel(e.originalEvent)) ? 'pointer' : '';
                }
            });
            map.on('click', function (evt: any) {
                const mapFeature = map.forEachFeatureAtPixel(evt.pixel, function (feature: any) {
                    return feature;
                });
                if (mapFeature) {
                    const features = mapFeature?.get('features');
                    if (features?.length > 1) {
                        // Calculate the extent of the cluster members.
                        const extent = createEmpty();
                        features.forEach((item: any) =>
                            extend(extent, item.getGeometry().getExtent())
                        );
                        const view = map.getView();
                        const resolution = map.getView().getResolution();
                        if (
                            view.getZoom() === view.getMaxZoom() ||
                            (getWidth(extent) < resolution && getHeight(extent) < resolution)
                        ) {
                            // Show an expanded view of the cluster members.
                            // clickFeature = features[0];
                            // clickResolution = resolution;
                            // clusterCircles.setStyle(clusterCircleStyle);
                        } else {
                            // Zoom to the extent of the cluster members.
                            view.fit(extent, {duration: 500, padding: [100, 100, 100, 100]});
                        }
                    } else {
                        const feature = features[0];
                        const data = feature.get('value');
                        const type = data?.type;
                        console.log(data);
                        if (type === 'hcp') {
                            setIsHcpDetailsCardOpen(true);
                            setHcpDetails(data);
                            setSelectedHcp(data);
                            setSelectedFacility(null);
                            setIsFacilityDetailsCardOpen(false);
                            setCheckedFacility(false);
                        }
                        if (type === 'facility') {
                            setFacilityNavigation("facility-details");
                            setSelectedFacility(data);
                            setIsFacilityDetailsCardOpen(true);
                            setIsHcpDetailsCardOpen(false);
                            setSelectedHcp(null);
                        }

                    }
                }

            });
            //  });
        }
        // eslint-disable-next-line
    }, [zoomLevel, map, selectedHcp, selectedFacility, mapPosition]);

    const getLayerStyles = useCallback((feature: any, type: 'ActiveHcp' | 'InActiveHcp' | 'selectedHcp' | 'ActiveFacility' | 'InActiveFacility' | 'selectedFacility' | 'openShiftFacility') => {
        const size = feature.get('features').length;
        const selectedTypeIconStyle = new Style({
            image: new Icon({
                anchor: [0.5, 1],
                anchorXUnits: "fraction",
                anchorYUnits: "fraction",
                src: MarkerMapping[type],
            }),
            // text: new Text({
            //     text: size.toString(),
            //     font: 'bold 10px serif',
            //     offsetY: -20,
            //     offsetX:20,
            //     fill: new Fill({
            //         color: [0, 0, 0, 1],
            //     }),
            //     stroke: new Stroke({
            //         color: 'white',
            //         width: 20,
            //         lineCap: 'butt',
            //         lineJoin: 'miter',
            //         miterLimit: 4,
            //     }),
            // })
        });
        const selectedTypeUnionIconStyle = new Style({
            image: new Icon({
                anchor: [-0.2, 2],
                anchorXUnits: "fraction",
                anchorYUnits: "fraction",
                src: ImageConfig.UnionIcon,
            }),
            text: new Text({
                text: size.toString(),
                font: 'bold 10px serif',
                offsetY: -36,
                offsetX: 15,
                fill: new Fill({
                    color: [0, 0, 0, 1],
                }),
                stroke: new Stroke({
                    color: 'white',
                    width: 2,
                }),
            })
        });
        return [selectedTypeIconStyle, selectedTypeUnionIconStyle];
    }, []);

    useEffect(()=>{
        console.log(facilityList);
    },[facilityList])

    const paintHCPMarkers = useCallback(() => {
        if (map && hcpList) {
            map.getLayers().getArray()
                .filter((layer: any) => ['ActiveHcp', 'InActiveHcp', 'selectedHcp'].includes(layer.get('name')))
                .forEach((layer: any) => {
                    map.removeLayer(layer);
                });
            let selectedHCPFeatures: any = [];
            const activeHCPFeatures: any = [];
            const inActiveHCPFeatures: any = [];

            hcpList?.forEach((hcp: any) => {
                hcp.type = "hcp";
                const isSelected = selectedHcp?._id === hcp?._id;
                const isActive = hcp?.is_active;
                const lon = hcp?.location?.coordinates[0];
                const lat = hcp?.location?.coordinates[1];
                const feature = createMarker(lon, lat, hcp);
                if (isSelected && lon && lat) {
                    selectedHCPFeatures = [feature];
                    //setMapNavigationOnFeatureSelection(lon, lat);
                } else {
                    if (isActive && selectedLegend.includes("Active Hcp") && lon && lat) {
                        activeHCPFeatures.push(feature);
                    } else if (!isActive) {
                        // inActiveHCPFeatures.push(feature);
                    }
                }
            });

            const selectedHCPFeaturesSource = new VectorSource({
                features: selectedHCPFeatures
            });

            const selectedHCPFeaturesClusterSource = new Cluster({
                distance: DEFAULT_CLUSTER_DISTANCE,
                minDistance: DEFAULT_CLUSTER_MIN_DISTANCE,
                source: selectedHCPFeaturesSource,
            });

            const activeHCPFeaturesSource = new VectorSource({
                features: activeHCPFeatures
            });

            const activeHCPFeaturesClusterSource = new Cluster({
                distance: DEFAULT_CLUSTER_DISTANCE,
                minDistance: DEFAULT_CLUSTER_MIN_DISTANCE,
                source: activeHCPFeaturesSource,
            });

            const inActiveHCPFeaturesSource = new VectorSource({
                features: inActiveHCPFeatures
            });

            const inActiveHCPFeaturesClusterSource = new Cluster({
                distance: DEFAULT_CLUSTER_DISTANCE,
                minDistance: DEFAULT_CLUSTER_MIN_DISTANCE,
                source: inActiveHCPFeaturesSource,
            });

            const vectorLayerActiveIconForHcp: any = new VectorLayer({
                source: activeHCPFeaturesClusterSource,
                style: (feature: any) => getLayerStyles(feature, 'ActiveHcp')
            });

            vectorLayerActiveIconForHcp.set('name', 'ActiveHcp');
            map.addLayer(vectorLayerActiveIconForHcp);

            const vectorLayerInActiveIconForHcp: any = new VectorLayer({
                source: inActiveHCPFeaturesClusterSource,
                style: (feature: any) => getLayerStyles(feature, 'InActiveHcp')
            });
            vectorLayerInActiveIconForHcp.set('name', 'InActiveHcp');
            map.addLayer(vectorLayerInActiveIconForHcp);

            const vectorLayerSelectedForHcp: any = new VectorLayer({
                source: selectedHCPFeaturesClusterSource,
                style: (feature: any) => getLayerStyles(feature, 'selectedHcp'),
                zIndex: 100,
            });
            vectorLayerSelectedForHcp.set('name', 'selectedHcp');
            map.addLayer(vectorLayerSelectedForHcp);
        }
        // eslint-disable-next-line
    }, [getLayerStyles, createMarker, hcpList, map, selectedHcp, selectedLegend]);

    const paintFacilityMarkers = useCallback(() => {
        if (map && facilityList) {

            map.getLayers().getArray()
                .filter((layer: any) => ['openShiftFacility', 'ActiveFacility', 'InActiveFacility', 'selectedFacility'].includes(layer.get('name')))
                .forEach((layer: any) => {
                    map.removeLayer(layer);
                });

            const facilityWithOpenShiftFeatures: any = [];
            let selectedFacilityFeatures: any = [];
            const activeFacilityFeatures: any = [];
            const inActiveFacilityFeatures: any = [];
            if (selectedLegend.includes("Open Shift")) {
                facilityWithOpenShift?.forEach((facility: any) => {
                    facility.type = "facility";
                    const isSelected = selectedFacility?._id === facility?._id;
                    const lon = facility?.location?.coordinates[0];
                    const lat = facility?.location?.coordinates[1];
                    const feature = createMarker(lon, lat, facility);
                    if (isSelected && lon && lat) {
                        selectedFacilityFeatures = [feature];
                        // setMapNavigationOnFeatureSelection(lon, lat);
                    } else {
                        facilityWithOpenShiftFeatures.push(feature);
                    }
                });
            }
            if (selectedLegend.includes("Active Facility")) {
                facilityWithoutOpenShift?.forEach((facility: any, item: any) => {
                    facility.type = "facility";
                    const isSelected = selectedFacility?._id === facility?._id;
                    const isActive = facility?.is_active;
                    const lon = facility?.location?.coordinates[0];
                    const lat = facility?.location?.coordinates[1];
                    const feature = createMarker(lon, lat, facility);
                    if (isSelected && lon && lat) {
                        selectedFacilityFeatures = [feature];
                        // setMapNavigationOnFeatureSelection(lon, lat);
                    } else {
                        if (isActive) {
                            activeFacilityFeatures.push(feature);
                        } else {
                            //   inActiveFacilityFeatures.push(feature);
                        }
                    }
                });
            }


            const facilitySelectedFeaturesSource = new VectorSource({
                features: selectedFacilityFeatures
            });
            const facilitySelectedFeaturesClusterSource = new Cluster({
                distance: DEFAULT_CLUSTER_DISTANCE,
                minDistance: DEFAULT_CLUSTER_MIN_DISTANCE,
                source: facilitySelectedFeaturesSource,
            });

            const facilityWithOpenShiftFeaturesSource = new VectorSource({
                features: facilityWithOpenShiftFeatures
            });
            const facilityWithOpenShiftFeaturesClusterSource = new Cluster({
                distance: DEFAULT_CLUSTER_DISTANCE,
                minDistance: DEFAULT_CLUSTER_MIN_DISTANCE,
                source: facilityWithOpenShiftFeaturesSource,
            });

            const activeFacilityFeaturesSource = new VectorSource({
                features: activeFacilityFeatures
            });
            const activeFacilityFeaturesClusterSource = new Cluster({
                distance: DEFAULT_CLUSTER_DISTANCE,
                minDistance: DEFAULT_CLUSTER_MIN_DISTANCE,
                source: activeFacilityFeaturesSource,
            });

            const inActiveFacilityFeaturesSource = new VectorSource({
                features: inActiveFacilityFeatures
            });
            const inActiveFacilityFeaturesClusterSource = new Cluster({
                distance: DEFAULT_CLUSTER_DISTANCE,
                minDistance: DEFAULT_CLUSTER_MIN_DISTANCE,
                source: inActiveFacilityFeaturesSource,
            });

            const vectorLayerActiveIconForFacility: any = new VectorLayer({
                source: activeFacilityFeaturesClusterSource,
                className: 'activeFacility',
                style: (feature: any) => getLayerStyles(feature, 'ActiveFacility')
            });
            vectorLayerActiveIconForFacility.set('name', 'ActiveFacility');
            map.addLayer(vectorLayerActiveIconForFacility);

            const vectorLayerInActiveIconForFacility: any = new VectorLayer({
                source: inActiveFacilityFeaturesClusterSource,
                className: 'inactiveFacility',
                style: (feature: any) => getLayerStyles(feature, 'InActiveFacility')
            });
            vectorLayerInActiveIconForFacility.set('name', 'InActiveFacility');
            map.addLayer(vectorLayerInActiveIconForFacility);

            const vectorLayerSelectedForFacility: any = new VectorLayer({
                source: facilitySelectedFeaturesClusterSource,
                style: (feature: any) => getLayerStyles(feature, 'selectedFacility'),
                zIndex: 100
            });
            vectorLayerSelectedForFacility.set('name', 'selectedFacility');
            map.addLayer(vectorLayerSelectedForFacility);

            const vectorLayerForOpenShiftFacility: any = new VectorLayer({
                source: facilityWithOpenShiftFeaturesClusterSource,
                style: (feature: any) => getLayerStyles(feature, 'openShiftFacility'),
            });
            vectorLayerForOpenShiftFacility.set('name', 'openShiftFacility');
            map.addLayer(vectorLayerForOpenShiftFacility);

        }
        // eslint-disable-next-line
    }, [createMarker, getLayerStyles, facilityList, facilityWithOpenShift, facilityWithoutOpenShift, map, selectedFacility, selectedLegend]);

    useEffect(() => {
        paintHCPMarkers();
    }, [paintHCPMarkers, hcpList, map]);

    useEffect(() => {
        paintFacilityMarkers();
    }, [paintFacilityMarkers, facilityList, map]);

    const setMapNavigationOnFeatureSelection = useCallback((longitude: any, latitude: any) => {
        if (map) {
            let cordMin = fromLonLat([longitude, latitude], 'EPSG:3857');
            let cordMax = fromLonLat([longitude, latitude], 'EPSG:3857');
            let extent = [cordMin[0], cordMin[1], cordMax[0], cordMax[1]];
            map.getView().fit(extent, map.getSize());
            map.getView().setZoom(9);
        }
        // eslint-disable-next-line
    }, [map]);

    return (
        <div className="dashboard map-component">
            <div ref={mapElement} className="map-container"/>
            <div className={"top-filter-section-holder"}>
                <FilterComponentForMap facilityWithoutOpenShift={facilityWithoutOpenShift}
                                       facilityWithOpenShift={facilityWithOpenShift} selectedLegend={selectedLegend}
                                       setFacilityNavigation={setFacilityNavigation}
                                       setMapNavigationOnFeatureSelection={setMapNavigationOnFeatureSelection}
                                       setCheckedFacility={setCheckedFacility} selectedFacility={selectedFacility}
                                       facilityList={facilityList}
                                       setSelectedFacility={setSelectedFacility}
                                       setIsFacilityDetailsCardOpen={setIsFacilityDetailsCardOpen}
                                       setSelectedHcpRegionForNearbyHcp={setSelectedHcpRegionForNearbyHcp}
                                       setSelectedHcpTypeForNearbyHcp={setSelectedHcpTypeForNearbyHcp}
                                       selectedRegion={selectedRegion} setSelectedRegion={setSelectedRegion}
                                       regionList={regions}
                                       setIsHcpDetailsCardOpen={setIsHcpDetailsCardOpen} setHcpDetails={setHcpDetails}
                                       selectedHcp={selectedHcp} setSelectedHcp={setSelectedHcp} hcpList={hcpList}
                                       setSelectedHcpTypes={setSelectedHcpTypes} selectedHcpTypes={selectedHcpTypes}
                                       hcpTypes={hcpTypes}/>
            </div>
            {!isFacilityDetailsCardOpen && isHcpDetailsCardOpen && <>
                <div className={'top-card-details-section-holder'}>
                    <HcpDetailsComponentOnMap
                        setCheckedFacility={setCheckedFacility} checkedFacility={checkedFacility}
                        getFacilityList={getFacilityList} setFacilityList={setFacilityList}
                        setSelectedHcp={setSelectedHcp} selectedHcp={selectedHcp}
                        selectedHcpRegionForNearbyHcp={selectedHcpRegionForNearbyHcp}
                        selectedHcpTypeForNearbyHcp={selectedHcpTypeForNearbyHcp}
                        setSelectedHcpRegionForNearbyHcp={setSelectedHcpRegionForNearbyHcp}
                        setSelectedHcpTypeForNearbyHcp={setSelectedHcpTypeForNearbyHcp}
                        hcpDetails={hcpDetails} setIsHcpDetailsCardOpen={setIsHcpDetailsCardOpen}
                        isHcpDetailsCardOpen={isHcpDetailsCardOpen}/>
                </div>
            </>}
            {
                !isHcpDetailsCardOpen && isFacilityDetailsCardOpen && <>
                    <div className={'top-card-details-section-holder'}>
                        <FacilityDetailsComponent
                            hcpTypes={hcpTypes} facilityNavigation={facilityNavigation}
                            setFacilityNavigation={setFacilityNavigation}
                            setIsFacilityDetailsCardOpen={setIsFacilityDetailsCardOpen}
                            setSelectedFacility={setSelectedFacility}
                            selectedFacility={selectedFacility}
                            hcpList={hcpList}
                        />
                    </div>
                </>

            }

            <div className="bottom-tools-section-holder on-left">
                {
                    !isLegendCardOpen && <>
                        <div className={"legend-card-opener"} onClick={() => {
                            setIsLegendCardOpen(true);
                        }
                        }>
                            <div>
                                View Legend
                            </div>
                            <div style={{background: "#E8E8E8", height: "31px", width: "1.5px"}}/>
                            <div>
                                <img src={ImageConfig.RightArrow} alt={ImageConfig.RightArrow}/>
                            </div>
                        </div>
                    </>

                }
                {
                    isLegendCardOpen && <>
                        <LegendCardComponent setIsLegendCardOpen={setIsLegendCardOpen} setSelectedLegend={setSelectedLegend}
                                             selectedLegend={selectedLegend}/>
                    </>
                }
            </div>
            <div
                className={`bottom-tools-section-holder ${isFacilityDetailsCardOpen || isHcpDetailsCardOpen ? "on-center-padding" : "on-right-center"}`}>
                <div className="map-pointer-legend-control control-base" id={'mouse-position'}>
                    <span className="map-pointer-legend-control-label">Lon, Lat: &nbsp; </span> <span
                    className="map-pointer-legend-control-value">{mapPointer[1].toFixed(6)}&nbsp;,&nbsp;{mapPointer[0].toFixed(6)}
                    </span>
                </div>
            </div>
            <div
                className={`bottom-tools-section-holder ${isFacilityDetailsCardOpen || isHcpDetailsCardOpen ? "on-center" : "on-right"}`}>
                <div className="zoom-trigger-control control-base">
                    <div id="zoom_plus" className="zoom-control zoom-control-plus"
                         onClick={() => zoomTo(+1)}
                    >
                        <img src={ZoomPlus} alt=""/>
                    </div>
                    <div id="zoom_level" className="zoom-level">
                        {zoomLevel}
                    </div>
                    <div id="zoom_minus" className="zoom-control zoom-control-minus"
                         onClick={() => zoomTo(-1)}
                    >
                        <img style={{color: "white"}} src={ZoomMinus} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardScreen;
