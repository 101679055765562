import "./GenerateInvoiceComponent.scss";
import GenerateTimeSheetList from "./timesheet-list/GenerateTimeSheetList";

const GenerateInvoiceComponent = () => {

    return (
        <>
            <div className={"generating-invoice-component"}>
                <div className={"generate-timesheet-list mrg-top-50"}>
                    <GenerateTimeSheetList />
                </div>

            </div>
        </>
    );
};
export default GenerateInvoiceComponent;