import "./AddStaffToJobComponent.scss";
import FormLabel from "@material-ui/core/FormLabel";
import {CssTextField} from "../../../../../constants/data/styles";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Lottie from "react-lottie";
import React, {useCallback, useEffect, useState} from "react";
import animationData from "../../../../../assets/animations/NoData.json";
import {CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import SelectDropdownComponent from "../../../../ant-component/select/SelectComponent";
import LoaderComponent from "../../../../../components/loader/LoaderComponent";
import Button from "@material-ui/core/Button"

interface AddStaffToJobComponentProps{
    cancel: () => void;
    id:string;
    basicDetails:any;
    staff_type:string;
    setRefreshToken:any;
    hcpTypes?:any
}

const AddStaffToJobComponent=(props:AddStaffToJobComponentProps)=>{
    const {id,setRefreshToken,staff_type,hcpTypes}=props;
    const afterCancel = props?.cancel;
    const [hcpList, sethcpList] = React.useState<any>(null);
    const [searchHcp, setSearchHcp] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSelectedCount, setIsSelectedCount] = useState<any>(-1)
    const [selectedHcps, setSelectedHcps] = useState<any>([]);
    const [selectedStaffType, setSelectedStaffType] = useState<any[]>(staff_type ? [staff_type] : []);
    const [isSubmitting,setIsSubmitting]=useState<boolean>(false);


    const defaultOptions = {
        animationData,
    };

    const fetchHcpList = useCallback(() => {
        setIsLoading(true);
        let payload: any = {
            // roles:['hcp']
            job_id:[id],
            is_active:true,
            limit:50,
        };
        if (searchHcp !== "") {
            payload.search = searchHcp
        }
        if (selectedStaffType?.length > 0) {
            payload.hcp_types = selectedStaffType;
        } else {
            delete payload.hcp_types;
        }

        CommonService._api.post(ENV.API_URL + `hcp/list`,payload).then((resp) => {
            sethcpList(resp?.data?.docs);
            setIsLoading(false);
        })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [searchHcp,id,selectedStaffType]);


    const cancel = (resetForm: any) => {
        if (afterCancel) {
            afterCancel();
        }
    };

    useEffect(() => {
        fetchHcpList();
    }, [fetchHcpList]);

    const isSelected = useCallback((_id: any) => {
        if (selectedHcps?.indexOf(_id) !== -1) {
            return true;
        } else {
            return false;
        }
    }, [selectedHcps]);

    const handleAddStaffToJob=useCallback(()=>{
        const payload={
            job_ids:[id],
            user_ids:selectedHcps
        }
        setIsSubmitting(true);
        CommonService._api.post(ENV.API_URL + 'ats/applyJobs',payload).then((response:any)=>{
            CommonService.showToast(response.message,"success")
            setRefreshToken((prev:number)=>prev +1);
            afterCancel()
        }).catch((error:any)=>{
            CommonService.showToast(error.error || 'Error',"error");
        }).finally(()=>{
            setIsSubmitting(false);
        })
    },[selectedHcps,id,afterCancel,setRefreshToken])


    const handleChange = (event: any) => {
        let index = selectedHcps.indexOf(event.target.value);
        let tempHcps = [];
        if (index > -1) {
            setIsSelectedCount(selectedHcps?.length - 1 === 0 ? -1 : selectedHcps?.length - 1)
            tempHcps = selectedHcps.filter((item: any) => item !== event.target.value);
        } else {
            setIsSelectedCount(selectedHcps?.length + 1)
            tempHcps = [...selectedHcps, event.target.value];
        }
        setSelectedHcps(tempHcps);
    };


    return (
        <div className={'add-staff-to-job-component'}>
                <FormLabel component="legend" className="mrg-left-0">
                    List Of Staff
                </FormLabel>
                <div className="add-staff-to-job-search-wrapper">
                    <div className="d-flex position-relative">
                        <CssTextField
                            defaultValue={""}
                            onChange={(event) => {
                                setSearchHcp(event?.target?.value);
                            }}
                            className="staff-search-field"
                            variant={"outlined"}
                            size={"small"}
                            type={"text"}
                            placeholder={"Search Staff"}
                            value={searchHcp}
                        />
                        {searchHcp === "" ? (
                            <div className={"search_icon"}>
                                <SearchRounded/>
                            </div>
                        ) : (
                            <div className={"search_icon"}>
                                <ClearIcon onClick={(event) => setSearchHcp("")} id="clear_hcp_search"/>
                            </div>
                        )}
                    </div>
                    <div className={'mrg-left-20 add-staff-filter'}>
                        <SelectDropdownComponent
                            value={selectedStaffType}
                            options={hcpTypes || []}
                            size={"large"}
                            mode={'multiple'}
                            placeholder={'Select Profession'}
                            displayWith={(option) => option.title}
                            valueExtractor={(option) => option.code}
                            onChange={(value:any)=>{
                                setSelectedStaffType(value)
                            }}
                        />
                    </div>
                </div>
                <div className={'add-staff-to-job-list'}>
                    {isLoading ?
                        <div className={'basic-loading-wrapper'}>
                           <LoaderComponent type={'spinner'} size={'sm'} color={'primary'}/>
                        </div> :
                        <>
                            {hcpList?.length === 0 ?
                                <div className={'basic-loading-wrapper'}>
                                    <div>
                                        <Lottie width={250} height={250} speed={1} options={defaultOptions}/>
                                        <div style={{textAlign: 'center', fontWeight: 500, fontSize: '18px'}}>No Staff
                                            Found.
                                        </div>
                                    </div>
                                </div> :
                                <>
                                    {hcpList?.map((item: any) => {
                                        const isItemSelected = isSelected(item?.user_id);
                                        return (
                                            <div className={'add-staff-list-item'}>
                                                <FormControlLabel value={item?.user_id} checked={isItemSelected}
                                                                  control={<Checkbox/>} onChange={(event) => handleChange(event)}
                                                                  label={item?.first_name + " " + item?.last_name + (item?.hcp_type ? ( " | " + item?.hcp_type):'')}/>
                                            </div>
                                        );
                                    })}
                                </>
                            }
                        </>
                    }
                </div>
                <div className={'add-staff-btn-wrapper'}>
                    <Button type={"submit"} size="large" variant={"outlined"} color={'primary'} className={"normal"}
                            onClick={cancel}>
                        Cancel
                    </Button>
                    <Button type={"submit"} color={"primary"} size="large"
                            disabled={isSelectedCount === -1 || isSubmitting}
                            className={'mrg-left-20'} variant={"contained"}
                            onClick={handleAddStaffToJob}>
                        Add Staff
                    </Button>
                </div>
        </div>
    )
}

export default AddStaffToJobComponent