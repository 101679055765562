import * as Yup from "yup";

const user: any = localStorage.getItem("currentUser");
let currentUser = JSON.parse(user);

export interface HcpItemAddType {
    first_name: string;
    last_name: string;
    dob: any;
    email?: string;
    contact_number: string;
    hcp_type: string;
    gender: string;
    worker_id: any;
    about?: string;
    experience?: string;
    speciality?: string;
    summary?: string;
    address?: {
        street: string;
        city: string;
        state: string;
        region: string;
        country: string;
        zip_code: string;
    };

    professional_details?: {
        speciality: string;
        experience: number | string;
        summary: string;
    };
    contract_details: {
        rate_per_hour: any;
        signed_on: any;
        salary_credit: any;
    };

    nc_details?: {
        dnr: string;
        shift_type_preference: string;
        location_preference: string;
        more_important_preference: string;
        family_consideration: string;
        zone_assignment: string;
        covid_facility_preference: string;
        is_fulltime_job: any;
        is_supplement_to_income: any;
        is_studying: any;
        is_gusto_invited: any;
        is_gusto_onboarded: any;
        gusto_type: any;
        nc_last_updated: any;
        last_call_date: any;
        contact_type: any;
        other_information: any;
        is_vaccinated: any;
        vaccine: string;
        vaccination_dates?: {
            first_shot: string;
            latest_shot: string;
        };
        is_authorized_to_work?: any;
        is_require_employment_sponsorship?: any;
        travel_preferences?: any[];
    };
    notification_preferences: any;
    latitude: any;
    longitude: any;
}

export const AddApplicantInitialValues: HcpItemAddType = {
    first_name: "",
    last_name: "",
    email: "",
    dob: null,
    contact_number: "",
    hcp_type: "",
    gender: "",
    about: "",
    experience: "",
    worker_id: "",
    speciality: "",
    summary: "",
    address: {
        street: "",
        city: "",
        state: "",
        region: "",
        country: "US",
        zip_code: "",
    },

    professional_details: {
        experience: "",
        speciality: "",
        summary: "",
    },
    contract_details: {
        rate_per_hour: "",
        signed_on: null,
        salary_credit: "",
    },

    nc_details: {
        dnr: "",
        shift_type_preference: "",
        location_preference: "",
        more_important_preference: "",
        family_consideration: "",
        zone_assignment: "",
        vaccine: "",
        covid_facility_preference: "",
        is_fulltime_job: "",
        is_supplement_to_income: "",
        is_studying: "",
        is_gusto_invited: "",
        is_gusto_onboarded: "",
        gusto_type: "",
        nc_last_updated: `${currentUser?.first_name} ${currentUser?.last_name}`,
        last_call_date: "",
        contact_type: "",
        other_information: "",
        is_vaccinated: "",
        vaccination_dates: {
            first_shot: "",
            latest_shot: "",
        },
        is_authorized_to_work: "",
        is_require_employment_sponsorship: "",
        travel_preferences: [],
    },
    notification_preferences: {
        sms: true,
        email: true,
        push_notification: true,
    },
    latitude: undefined,
    longitude: undefined,
};

export const ApplicantFormValidation = Yup.object({
    first_name: Yup.string()
        .typeError(" must be a text")
        .min(1, "min 1 letter")
        .trim("empty space not allowed")
        .required("Required")
        .max(50, "max limit 50")
        // eslint-disable-next-line
        .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
            , "alphanumeric & special characters only "),
    // dob: Yup.string().typeError("must be date").nullable(),
    last_name: Yup.string()
        .typeError(" must be a text")
        .min(1, "min 1 letter")
        .trim("empty space not allowed")
        .required("Required")
        .max(50, "max limit 50")
        // eslint-disable-next-line
        .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
            , "alphanumeric & special characters only "),
    email: Yup.string().min(3, "invalid").trim("empty space not allowed").typeError(" must be a text").email("invalid").required("Required").max(50, "max limit 50"),
});
