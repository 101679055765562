import "./JobBulkUploadComponent.scss";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import {ENV} from "../../../../constants";
import {
    Box,
    Button,
    Tooltip,
    Tabs,
} from "@material-ui/core";
import DialogComponent from "../../../../components/DialogComponent";
import FilePickerComponent from "../../../../components/file-picker/FilePickerComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {TableColumnsType} from "antd";
import {Typography as Text} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const JobBulkUploadComponent=()=>{
    const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
    const [isBulkUploadDialogOpen, setIsBulkUploadDialogOpen] = useState<boolean>(false);
    const [shiftUploadFile, setShiftUploadFile] = React.useState<any>([]);
    const [filterData, setFilterData] = useState<any>({
        search: '',
        type:'per_diem',
        sort: {},
    })
    const [value, setValue] = useState<any>(0);
    const [selectedValue, setSelectedValue] = useState<any>("per_diem");
    const [uploadData, setUploadData] = useState([]);

    useEffect(() => {
        if (uploadData?.length > 0) {
            Communications.pageTitleSubject.next("Job Bulk Upload / Invalid Entries");
        } else {
            Communications.pageTitleSubject.next("Job Bulk Upload");
        }
        Communications.pageBackButtonSubject.next("/job");
    }, [uploadData]);

    useEffect(() => {
        if (value === 0) {
            setSelectedValue('per_diem')
        }
        if (value === 1) {
            setSelectedValue("contract")
        }
        if (value === 2) {
            setSelectedValue("permanent")
        }
    }, [value]);

    const handleChanges = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setUploadData([])
    }, []);

    const handleDownload = useCallback(() => {
        let fileUrl:any;
        if (value === 0) {
            //per diem
            fileUrl = "https://s3.us-east-2.amazonaws.com/bulkupload.template.com/bulkupload/job_per_diem_upload.xlsx";
        }
        if (value === 1) {
            //contract
            fileUrl = "https://s3.us-east-2.amazonaws.com/bulkupload.template.com/bulkupload/job_contract_upload.xlsx";
        }

        if (value === 2) {
            //permanent
            fileUrl = "https://s3.us-east-2.amazonaws.com/bulkupload.template.com/bulkupload/job_permanent_upload.xlsx";
        }
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'facility_upload_template.xlsx'; // Optional: specify a filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [value]);

    const handleParticularDataDownload = useCallback((key: any) => {
        let payload: any = {};
        payload.file_key = key;
        ApiService.post(ENV.API_URL + "downloadAttachment", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                } else {
                    CommonService.showToast(res?.error || "No Data to Download", "info");
                }
            })
            .catch((err) => {
                CommonService.showToast(err?.error || "Error", "error");
            });
    }, [])

    const removeFile = (index: number) => {
        // Create a new array excluding the file at the given index
        const newTimesheetFile = [...shiftUploadFile];
        newTimesheetFile.splice(index, 1);
        // Update the timesheetFile state with the new array
        setShiftUploadFile([]);
    };


    const onAddBulkFileUpload = useCallback(() => {
        setIsFileUploading(true)
        let url: any = "ats/bulkUploadJobs"
        const formData = new FormData();

        if (shiftUploadFile?.length > 0) {
            shiftUploadFile.forEach((file: File) => {
                // Ensure each file is appended correctly
                formData.append("attachment", file);
            });
            formData.append("type",selectedValue);
        } else {
            console.error("No files to upload");
            return;  // Early return if no files are uploaded
        }

        ApiService.upload(ENV.API_URL + url, formData)
            .then((res) => {
                CommonService.showToast(res?.message, "info");
                setIsBulkUploadDialogOpen(false)
                setValue(selectedValue === "per_diem" ? 0 : selectedValue === "contract" ? 1 : 2);
                setFilterData({
                    ...filterData,
                })
                setShiftUploadFile([])
                setUploadData([])
                setIsFileUploading(false)
            })
            .catch((err) => {
                console.log(err?.data)
                setIsBulkUploadDialogOpen(false);
                setUploadData(err?.data || [])
                setShiftUploadFile([])
                setIsFileUploading(false)
                CommonService.showToast(err?.error?.message || "Error", "error");
            });
    }, [shiftUploadFile, filterData,selectedValue])



    const jobListColumn: any = [
        {
            title: "Errors",
            dataIndex: "errors",
            key: "errors",
            render: (errors: string[]) =>
                errors && errors?.length > 0 ? (
                    <Tooltip
                        title={errors?.map((error, index) => (
                            <div key={index}>
                                <div color="red">{error}</div>
                            </div>
                        ))}
                    >
                        <ExclamationCircleOutlined style={{color: "red", fontSize: "16px",marginLeft:"20px"}}/>
                    </Tooltip>
                ) : null,
            fixed: "left",
            width: 80,
        },
        {
            title: "Facility UID",
            dataIndex: ["row", "Facility UID"],
            key: "facility_name",
            width: 200,
            render: (value: string) =>
                value ?
                    <div>
                        {value?.length > 20 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip>:
                            <div>
                                {value}
                            </div>
                        }
                    </div>
                    :
                    <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Facility Manager",
            dataIndex: ["row", "Facility Manager(Email)"],
            key: "facility_manager",
            width: 200,
            render: (value: string) =>
                value ? <div>
                    {value?.length > 20 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip> :
                        <div>
                            {value}
                        </div>
                    }
                </div> : <Text style={{color: "red"}}>Missing</Text>,
        },
        // {
        //     title: "Primary Recruiter",
        //     dataIndex: ["row", "Primary Recruiter(Email)"],
        //     key: "primary_recruiter",
        //     width: 150,
        //     render: (value: string) =>
        //         value ? <div>
        //             {value?.length > 15 ?
        //                 <Tooltip title={value}>
        //                     <div className={'ellipses-for-table-data'}>
        //                         {value}
        //                     </div>
        //                 </Tooltip> :
        //                 <div>
        //                     {value}
        //                 </div>
        //             }
        //         </div> : <Text style={{color: "red"}}>Missing</Text>,
        // },
        {
            title: "Job Title",
            dataIndex: ["row", "Job Title"],
            key: "job_title",
            width: 150,
            render: (value: string) =>
                value ? <div>
                    {value?.length > 20 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip> :
                        <div>
                            {value}
                        </div>
                    }
                </div> : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Staff Type",
            dataIndex: ["row", "Staff Type"],
            key: "staff_type",
            width: 200,
            render: (value: string) =>
                value ? <div>
                    {value?.length > 20 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip> :
                        <div>
                            {value}
                        </div>
                    }
                </div> : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Status",
            dataIndex: ["row", "Job Status"],
            key: "status",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
    ];

    const bulkUploadColumns: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Facility Name',
                width: 160,
                render: (item: any) => {
                    return (
                        <div>
                            {item?.facility?.name?.length > 20 ?
                                <Tooltip title={item?.facility?.name || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility?.name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.facility?.name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Uploaded On',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.formatDateTime(item?.updated_at) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Uploaded By',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            {item?.uploaded_by?.first_name + " " + item?.uploaded_by?.last_name}
                        </div>
                    )
                }
            },
            {
                title: 'Action',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            <Button variant={"outlined"}
                                    color="primary"
                                    onClick={() => {
                                        handleParticularDataDownload(item?.s3_path);
                                    }}
                                //  className={!isDownloading ? "" : "has-loading-spinner"}
                                //   disabled={isDownloading}
                            >
                                &nbsp;Download
                            </Button>
                        </div>
                    )
                }
            },

        ]
    }, [handleParticularDataDownload])

    console.log(uploadData,"upload data")

    return (
        <div className={'job-bulk-upload-component'}>
            <DialogComponent isEditableDialog={true} open={isBulkUploadDialogOpen} cancel={() => {
                setIsBulkUploadDialogOpen(false)
            }}>
                <div className={'mrg-20 bulk-upload-dialog'}>
                    <div className={'form-header-text mrg-bottom-20'}>Bulk Upload</div>
                    {shiftUploadFile?.length === 0 &&
                        <FilePickerComponent
                            height={'470px'}
                            id={" sc_upload_btn"}
                            multiple={false}
                            isBulkUpload={true}
                            showDropZone={false}
                            onFilesDrop={(acceptedFiles: any, rejectedFiles: any) => {
                                if (acceptedFiles && acceptedFiles.length > 0) {
                                    const file = acceptedFiles[0];
                                    setShiftUploadFile([...shiftUploadFile, file]);
                                }
                            }}
                            acceptedFileTypes={["xlsx"]}
                            acceptedFilesText={"Only .xlsx files are supported"}
                        />

                    }

                    <div className={'d-flex mrg-top-10 mrg-bottom-20'}>
                        {shiftUploadFile && shiftUploadFile?.map((file: any, index: number) => {
                            return (
                                <>
                                    <div
                                        className={'d-flex justify-content-space-between file-holder-wrapper mrg-right-10 align-items-center '}>
                                        <div>  {file?.name}</div>
                                        <div className={'cursor-pointer'}
                                             onClick={() => {
                                                 removeFile(index);
                                             }}>
                                            <DeleteIcon/>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                    <div className={'d-flex justify-content-center'}>
                        <div className="actions">
                            <Button variant={"outlined"} color="primary"
                                    onClick={() => {
                                        setIsBulkUploadDialogOpen(false)
                                    }}
                            >
                                &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div className="mrg-left-10">
                            <Button
                                onClick={onAddBulkFileUpload}
                                disabled={shiftUploadFile?.length === 0 || isFileUploading}
                                className={!isFileUploading ? "" : "has-loading-spinner"}
                                // disabled={isDownloading}
                                variant={"contained"}
                                color={"primary"}>
                                &nbsp;&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp;
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogComponent>
            <div>
                <Box sx={{width: '100%'}}>
                    <Box style={{borderBottom: "1px solid #e4e9ea"}}>
                        <Tabs
                            value={value} onChange={(e: any, value: any) => {

                            handleChanges(e, value);
                            setFilterData({
                                ...filterData,
                                type:value === 0 ? "per_diem": value === 1 ? 'contract': "permanent"
                            })
                        }
                        } aria-label="tab"
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#56ebff",
                                }
                            }}
                            style={{
                                color: "black",
                            }}
                        >
                            <Tab
                                label="Per Diem" {...a11yProps(0)} />
                            <Tab
                                label="Contract" {...a11yProps(0)} />
                            <Tab
                                label="Permanent" {...a11yProps(0)} />
                        </Tabs>
                    </Box>
                </Box>
                <div className={"facility-list screen crud-layout pdd-30 pdd-top-10"}>
                    <TabPanel value={value} index={value}>
                        <div className="custom-border pdd-10 pdd-top-20 pdd-bottom-0">
                            <div className="job-header">
                                <div>
                                    <Button variant={"outlined"} color="primary"
                                            onClick={handleDownload}
                                    >
                                        &nbsp;
                                        Download {value === 0 ? "Per Diem" : value === 1 ? "Contract" : "Permanent"} Template
                                    </Button>
                                </div>
                                <div className="mrg-left-10">
                                    <Button
                                        onClick={() => {
                                            setIsBulkUploadDialogOpen(true);
                                        }}
                                        variant={"contained"}
                                        color={"primary"}>
                                        &nbsp;&nbsp;{uploadData?.length === 0 ? "Upload File" : "Re-Upload File"}&nbsp;&nbsp;
                                    </Button>
                                </div>
                            </div>
                            {uploadData?.length === 0 &&
                                <div className={'facility-table-component mrg-top-20'}>
                                    <AntTableComponent
                                        isBordered={true}
                                        url={ENV.API_URL + "ats/uploadLogslist"}
                                        method={'post'}
                                        extraPayload={filterData}
                                        columns={bulkUploadColumns}
                                        noDataText={'No Data Found.'}
                                    />
                                </div>
                            }
                            {uploadData?.length !== 0 &&
                                <div className={'facility-table-component mrg-top-20'}>
                                    <AntTableComponent
                                        isBordered={true}
                                        data={uploadData?.map((item: any, index) => ({...item, key: index}))}
                                        columns={jobListColumn}
                                        extraPayload={uploadData}
                                        noDataText={'No Data Found.'}
                                    />
                                </div>
                            }
                        </div>
                    </TabPanel>
                </div>
            </div>

        </div>
    )
}

export default JobBulkUploadComponent