import "./ApplicantListComponent.scss";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ApiService, Communications} from "../../../../helpers";
import { Popover, TableColumnsType} from "antd";
import {Button} from "@material-ui/core";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {ENV, ImageConfig} from "../../../../constants";
import ApplicantMenuListComponent from "../applicant-menu-list/ApplicantMenuListComponent";
import moment from "moment/moment";
import CommonService from "../../../../helpers/common-service";
import {AddRounded, SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import {CssTextField} from "../../../../constants/data/styles";
import DrawerComponent from "../../../../components/drawer/DrawerComponent";
import ApplicantFilterDrawerComponent from "./applicant-filter-drawer/ApplicantFilterDrawerComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
import {useSessionStorage} from "../../../../components/useSessionStorage";
import {Link, useHistory} from "react-router-dom";
import { Drawer,Tooltip } from 'antd';
import ModalComponent from "../../../../components/modal/ModalComponent";
import ChangeRecruiterComponent from "../../Job/Job-list/change-recruiter/ChangeRecruiterComponent";
import ChangeStatusComponent from "../../Job/Job-list/change-status/ChangeStatusComponent";
import JobNotesComponent from "../../Job/job-view-details/job-notes/JobNotesComponent";
import TextEditorComponent from "../../../../components/text-editor/TextEditorComponent";
import SelectDropdownComponent from "../../../ant-component/select/SelectComponent";
import SingleEmailEditorComponent from "../../Job/job-view-details/email-editor-single/SingleEmailEditorComponent";
import {MailOutlined} from "@ant-design/icons";
import PipelineComponent from "../../../../components/ats/pipeline/PipelineComponent";
import SelectComponent from "../../../ant-component/select/SelectComponent";
import ChangeHrComponent from "../../Job/Job-list/change-hr/ChangeHrComponent";
import {applicantStatusList} from "../../../../data";
import TagJobComponent from "../applicant-view/tag-a-job/TagJobComponent";
import ResumeParserComponent from "../resume-parser/ResumeParserComponent";

const ApplicantListComponent=()=>{
    const history=useHistory();
    const [filterData,setFilterData]=useSessionStorage<any>("vitaApplicantFilterData",{
        search:'',
        sort: {},
        is_applicant:true,
        limit: 10,
        page:1
    })
    const [isRejectedHcpMoved, setIsRejectedHcpMoved] = useState<boolean>(false);
    const [rowStatus, setRowStatus] = useState<{ [key: string]: string }>({});
    const {hcpTypes,regions} = useSelector((state: StateParams) => state.meta);
    const [selectedApplicants,setSelectedApplicants]=useState<any>([])
    const [openFilterDrawer,setOpenFilterDrawer]=useState<boolean>(false);
    const [openJobListDrawer,setOpenJobListDrawer]=useState<boolean>(false);
    const [jobFilterData,setJobFilterData]=useState<any>({
        search:'',
        limit:10,
        page:1,
        is_applicant:true,
    })
    const [openChangeRecruiterModal,setOpenChangeRecruiterModal]=useState<boolean>(false);
    const [openChangeStatusModal,setOpenChangeStatusModal]=useState<boolean>(false);
    const [openNotesListDrawer,setOpenNotesListDrawer]=useState<boolean>(false);
    const [selectedApplicantId,setSelectedApplicantId]=useState<any>('');
    const [openAddNotesModal,setOpenAddNotesModal]=useState<boolean>(false);
    const [notes, setNotes] = useState<string>("");
    const [openEmailDrawer,setOpenEmailDrawer]=useState<boolean>(false);
    const [email,setEmail]=useState<any>(null);
    const [templateList,setTemplateList]=useState<any>([]);
    const [selectedTemplate,setSelectedTemplate]=useState<any>('')
    const [emailMessage,setEmailMessage]=useState<any>(null);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [jobRowStatus, setJobRowStatus] = useState<{ [key: string]: string }>({});
    const [openChangeHrModal,setOpenChangeHrModal]=useState<boolean>(false);
    const [openTagJobDrawer,setOpenTagJobDrawer]=useState<boolean>(false);
    const [selectedApplicantRowData,setSelectedApplicantRowData]=useState<any>()
    const [filterCount,setFilterCount]=useState<number>(0);
    const [openResumeParserModal,setOpenResumeParserModal]=useState<boolean>(false);

    useEffect(() => {
        let count=0;
        if(filterData?.staff_name && filterData?.staff_name?.length >0) count++;
        if(filterData?.hcp_types && filterData?.hcp_types?.length > 0) count++;
        if(filterData?.states && filterData?.states?.length > 0) count++;
        if(filterData?.recruiter_ids && filterData?.recruiter_ids?.length > 0) count++;
        if(filterData?.hr_ids && filterData?.hr_ids?.length > 0) count++;
        if(filterData?.source && filterData?.source?.length > 0) count++;
        if(filterData?.status) count++;
        if(filterData?.jobs) count++;
        if(filterData.created_at) count++;
        if(filterData.last_note_updated_at) count++;
        if(filterData?.applicant_status && filterData?.applicant_status?.length > 0) count++;
        setFilterCount(count);
    }, [filterData]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Applicants");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    const handleMenuItemClick = useCallback((action: string) => {
        setPopoverVisible(false);
        switch(action){
            case 'add_notes':
                setOpenAddNotesModal(true);
                break;
            case "change_recruiter":
                setOpenChangeRecruiterModal(true);
                break;
            case "change_status":
                setOpenChangeStatusModal(true);
                break;
            case "change_hr":
                setOpenChangeHrModal(true);
                break;
            case "tag_a_job":
                setOpenTagJobDrawer(true);
                break;
            default:
                return ;
        }
    },[])

    const handleStatusChange = useCallback((key: string, newStatus: string) => {
        setRowStatus((prev) => ({
            ...prev,
            [key]: newStatus, // Update only the selected row
        }));
        let payload: any = {};
        payload.applicant_status = newStatus;
        ApiService.put(ENV.API_URL + `hcp/${key}`, payload)
            .then((resp: any) => {
                CommonService.showToast('Status updated successfully','success');
                setJobFilterData({...jobFilterData});
            })
            .catch((err: any) => {
                CommonService.showToast(err.error,'error');
            });
    }, [jobFilterData,setJobFilterData]);

    const handleJobStatusChange = useCallback((key: string, newStatus: string) => {
        setJobRowStatus((prev) => ({
            ...prev,
            [key]: newStatus, // Update only the selected row
        }));
        let payload: any = {};
        payload.job_status = newStatus;
        ApiService.put(ENV.API_URL + `ats/${key}/job`, payload)
            .then((resp: any) => {
                CommonService.showToast('Status updated successfully','success');
                setJobFilterData({...jobFilterData});
            })
            .catch((err: any) => {
                CommonService.showToast(err.error,'error');
            });
    }, [jobFilterData,setJobFilterData]);

    const handleNotesDrawer=useCallback((applicantId:string)=>{
        setOpenNotesListDrawer(true)
        setSelectedApplicantId(applicantId)
    },[])


    const handleOpenJobList=useCallback((id:any)=>{
        setOpenJobListDrawer(true)
        const updatedFilterData={...jobFilterData};
        updatedFilterData.user_ids=[id]
        setJobFilterData(updatedFilterData)
    },[jobFilterData])


    const handleRejectedUserToPending = useCallback(() => {
        setIsRejectedHcpMoved(true);
        const payloadData = {
            "hcp_ids": selectedApplicants
        };
        CommonService._api
            .patch(ENV.API_URL + "hcp/recover", payloadData)
            .then((resp) => {
                CommonService.showToast(resp?.msg || "Success", "success");
                let updatedFilterData={...filterData};
                setFilterData(updatedFilterData);
                setSelectedApplicants([]);
                setIsRejectedHcpMoved(false);
            })
            .catch((err) => {
                CommonService.showToast(err?.msg || "Error", "error");
                setSelectedApplicants([]);
                setIsRejectedHcpMoved(false);
            });
    }, [selectedApplicants,filterData,setFilterData]);


    const clearFilterValues = useCallback(() => {
        setFilterData((prevFilterData: any) => {
            const updatedFilterData={
                ...prevFilterData,
            }
            const keysToRemove=[
                "staff_name",
                "status",
                "hcp_types",
                "jobs",
                "states",
                "recruiter_ids",
                "applicant_status",
                "source",
                "hr_ids",
                "created_at",
                "last_note_updated_at"
            ]
            keysToRemove.forEach((key:any)=> delete updatedFilterData[key])

            return updatedFilterData
        })
    },[setFilterData])

    const resetFilters = () => {
        clearFilterValues();
    };

    const handleNotesClose = useCallback(() => {
        setNotes("");
        setOpenAddNotesModal(false)
        setSelectedApplicants([])
    }, []);

    const handleNotesAdd = useCallback(() => {
        const payload = {
            notes: notes || "",
            hcp_ids:selectedApplicants,
        };
        CommonService._api.post(ENV.API_URL+ `ats/applicant/bulkNote`,payload)
            .then((response: any) => {
                CommonService.showToast(response.message, "success");
                setOpenAddNotesModal(false);
                setFilterData({...filterData})
            })
            .catch((error: any) => {
                CommonService.showToast(error.error, "error");
            })
            .finally(() => {
                setNotes("");
                setSelectedApplicants([])
            });
    }, [selectedApplicants, notes,filterData,setFilterData]);

    const jobColumnList:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'Job ID',
                width:120,
                render:(item:any)=>{
                    return (
                        <Link to={{
                            pathname:`/jobs/view/${item?._id}`,
                            search: `?tab=basic_details`,
                            state:{
                                from:'applicant-list'
                            }
                        }}
                             style={{textDecoration:'underline',color:'#10c4d3',cursor:'pointer'}}
                        >{item?._id?.substr(-7) || 'N/A'}</Link>
                    )
                }
            },
            {
                title:'Job Title',
                width:250,
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.job_title?.length > 35 ?
                                <Tooltip title={item?.job_title}>
                                    <Link
                                        to={{
                                            pathname:`/jobs/view/${item?._id}`,
                                            search: `?tab=basic_details`,
                                            state:{
                                                from:'applicant-list'
                                            }
                                        }}
                                        className={'ellipses-for-table-data'}
                                        style={{textDecoration:'underline',color:'#10c4d3',cursor:'pointer'}}
                                    >
                                        {item?.job_title || 'N/A'}
                                    </Link>
                                </Tooltip>:
                                <Link
                                    to={{
                                        pathname:`/jobs/view/${item?._id}`,
                                        search: `?tab=basic_details`,
                                        state:{
                                            from:'applicant-list'
                                        }
                                    }}
                                    style={{textDecoration:'underline',color:'#10c4d3',cursor:'pointer'}}>
                                    {item?.job_title ||  'N/A'}
                                </Link>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Profession',
                width:150,
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.staff_type?.length > 15 ?
                                <Tooltip title={item?.staff_type}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.staff_type || 'N/A'}
                                    </div>
                                </Tooltip>:
                                <div>
                                    {item?.staff_type ||  'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Address',
                width:250,
                render:(item:any)=>{
                    const address=[item?.address?.street,
                        item?.address?.city,
                        item?.address?.state,
                        item?.address?.country,
                        item?.address?.zip_code].filter(Boolean).join(', ')
                    return (
                        <div>
                            {address?.length > 35 ?
                                <Tooltip title={address}>
                                    <div className={'ellipses-for-table-data'}>
                                        {address || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {address || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Job Start Date',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            {item?.job_type === 'per_diem' ?
                                <>
                                    {CommonService.getUtcDate(item?.per_diem_details?.start_date) || "N/A"}
                                </> : item?.job_type === 'contract' ? <>{CommonService.getUtcDate(item?.contract_details?.start_date) || "N/A"}</>
                                    :<>{CommonService.getUtcDate(item?.job_start_date) || "N/A"}</>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Job End Date',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            {item?.job_type === 'per_diem' ?
                                <>
                                    {CommonService.getUtcDate(item?.per_diem_details?.end_date) || "N/A"}
                                </> : item?.job_type === 'contract' ? <>{CommonService.getUtcDate(item?.contract_details?.end_date) || "N/A"}</>
                                    :<>{CommonService.getUtcDate(item?.job_end_date) || "N/A"}</>
                            }
                        </div>
                    )
                }
            },
            {
                title: "Status",
                width: 150,
                render: (item: any) => {
                    return <div className={'table-under-select-box'}>
                        <SelectDropdownComponent
                            placeholder={'Select job status'}
                            value={jobRowStatus[item._id] || item.job_status}
                            options={[
                                {title: 'Open', code: 'open'},
                                {title: 'Closed', code: 'closed'},
                                {title: 'Filled', code: 'filled'},
                                {title: 'Hold', code: 'hold'},
                                {title: 'In Progress', code: 'in_progress'},
                            ]}
                            required={true}
                            size={"middle"}
                            displayWith={(option) => option.title}
                            valueExtractor={(option) => option.code}
                            onChange={(value) => handleJobStatusChange(item._id, value)}
                        />
                    </div>;
                },
            },
            {
                expandableComponent:PipelineComponent
            }
        ]
    },[jobRowStatus,handleJobStatusChange])

    const getTemplateList=useCallback(()=>{
        CommonService._api.get(ENV.API_URL+ 'ats/listTemplate').then((response:any)=>{
            const updatedList=response?.data?.map((item:any)=>({
                title:item?.template,
                code:item?._id,
                message:item?.template_format
            }))
            setTemplateList(updatedList);
        }).catch((error:any)=>{
            console.log(error)
        })
    },[])

    const handleOpenMailDrawer=useCallback((applicant:any)=>{
        setOpenEmailDrawer(true);
        setEmail(applicant?.email)
        getTemplateList();
    },[getTemplateList])

    const hcpListColumns:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'Applicant Name',
                width:200,
                sorter: (a: any, b: any) => {
                    const fullNameA = (a.first_name + " " + a.last_name).toLowerCase();
                    const fullNameB = (b.first_name + " " + b.last_name).toLowerCase();
                    return fullNameA?.localeCompare(fullNameB);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {(item?.first_name?.length + item?.last_name?.length)>20 ?
                                <Tooltip title={item?.first_name + " " + item?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.first_name + " " + item?.last_name}
                                    </div>
                                </Tooltip>:
                                <div className='facility-name'>
                                    {(item?.first_name + " " + item?.last_name) || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Job Applied For',
                width:160,
                sorter: (a: any, b: any) => {
                    return a.hcp_type?.localeCompare(b.hcp_type);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.hcp_type?.length > 20 ?
                                <Tooltip title={item?.hcp_type}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp_type}
                                    </div>
                                </Tooltip>
                                :
                                <div className='facility-name'>
                                    {item?.hcp_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Created On',
                width:130,
                sorter: (a: any, b: any) => {
                    return moment(a.created_at).unix() - moment(b.created_at).unix();
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {moment(item.created_at).utc().format("MM-DD-YYYY") || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Source',
                width:180,
                sorter: (a: any, b: any) => {
                    return a?.applied_from?.localeCompare(b?.applied_from);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {CommonService.formatTitleCase(item?.applied_from) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Recruiter',
                width:180,
                dataIndex:'recruiter_details',
                sorter: (a: any, b: any) => {
                    const nameA = `${a?.first_name ?? ''} ${a?.last_name ?? ''}`.toLowerCase();
                    const nameB = `${b?.first_name ?? ''} ${b?.last_name ?? ''}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                },
                render:(item:any)=>{
                    return(
                        <>
                            {item ?
                                <div>
                                    {(item?.first_name + item?.last_name)?.length > 20 ?
                                        <Tooltip title={item?.first_name + " " + item?.last_name}>
                                            <div className={'ellipses-for-table-data'}>
                                                {item?.first_name + " " + item?.last_name || 'N/A'}
                                            </div>
                                        </Tooltip>
                                        :
                                        <div>
                                            {item?.first_name + " " + item?.last_name || 'N/A'}
                                        </div>
                                    }
                                </div>
                                :
                                <div>N/A</div>}
                        </>
                    )
                }
            },
            {
                title:'HR',
                width:180,
                dataIndex:'hr_details',
                sorter: (a: any, b: any) => {
                    const nameA = `${a?.first_name ?? ''} ${a?.last_name ?? ''}`.toLowerCase();
                    const nameB = `${b?.first_name ?? ''} ${b?.last_name ?? ''}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                },
                render:(item:any)=>{
                    return(
                        <>
                            {item ?
                                <div>
                                    {(item?.first_name + item?.last_name)?.length > 20 ?
                                        <Tooltip title={item?.first_name + " " + item?.last_name}>
                                            <div className={'ellipses-for-table-data'}>
                                                {item?.first_name + " " + item?.last_name || 'N/A'}
                                            </div>
                                        </Tooltip>
                                        :
                                        <div>
                                            {item?.first_name + " " + item?.last_name || 'N/A'}
                                        </div>
                                    }
                                </div>
                                :
                                <div>N/A</div>}
                        </>
                    )
                }
            },
            {
                title:'Email',
                width:200,
                sorter: (a: any, b: any) => {
                    return a.email?.localeCompare(b.email);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.email?.length > 20 ?
                                <Tooltip title={item?.email}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.email}
                                    </div>
                                </Tooltip>
                                :
                                <div className='facility-name'>
                                    {item?.email || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'State',
                width:150,
                sorter: (a: any, b: any) => {
                    return a.address?.state?.localeCompare(b.address?.state);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.address?.state?.length > 17 ?
                                <Tooltip title={item?.address?.state}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.address?.state}
                                    </div>
                                </Tooltip>
                                :
                                <div className='facility-name'>
                                    {item?.address?.state || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Contact Number',
                width:160,
                render:(item:any)=>{
                    return (
                        <div>
                            {CommonService.formatPhoneNumber(item?.contact_number?.replace(/^\+1/,'')) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Status',
                width:120,
                sorter: (a: any, b: any) => {
                    return a?.status?.localeCompare(b?.status);
                },
                render:(item:any)=>{
                    return (
                        <div>{CommonService.formatTitleCase(item?.status) || 'N/A'}</div>
                    )
                }
            },
            {
                title:'Last Touch Point',
                width:150,
                sorter: (a: any, b: any) => moment(a?.last_note?.updated_at || 0).unix() - moment(b?.last_note?.updated_at || 0).unix(),
                render:(item:any)=>{
                    return (
                        <div>{CommonService.getUtcDate(item?.last_note?.updated_at) || 'N/A'}</div>
                    )
                }
            },
            {
                title:'Applicant Status',
                width:220,
                render:(item:any)=>{
                    return <div className={'table-under-select-box'}>
                        <SelectDropdownComponent
                            placeholder={'Select job status'}
                            disabled={item?.status === 'approved'}
                            value={rowStatus[item._id] || item.applicant_status}
                            options={applicantStatusList || []}
                            required={true}
                            size={"middle"}
                            displayWith={(option) => option.title}
                            valueExtractor={(option) => option.code}
                            onChange={(value) => handleStatusChange(item._id, value)}
                        />
                    </div>;
                }
            },
            {
                title:'View Details',
                width:130,
                fixed:'right',
                render:(item:any)=>{
                    return (
                        <div className={'applicant-list-btn-wrapper'}>
                            <div className={'icon-wrapper'} onClick={() => handleOpenMailDrawer(item)}>
                                <Tooltip title={'Send Mail'}>
                                    <MailOutlined size={16}/>
                                </Tooltip>
                            </div>
                            <Tooltip title={'Job List'}>
                                <div onClick={() => handleOpenJobList(item?.user_id)} className={'job-list-count'}>
                                    {item?.job_count}
                                </div>
                            </Tooltip>
                            <Tooltip title={'Notes'}>
                                <div onClick={() => handleNotesDrawer(item?._id)} className={'icon-wrapper'}>
                                    <ImageConfig.NoteIcon/>
                                </div>
                            </Tooltip>
                            <div onClick={() => {
                                history.push(`/applicant/view/${item?._id}`, {
                                    from: history.location.pathname,
                                })
                            }}
                                 style={{color: '#1e1e1e', marginTop: '5px'}}
                            >
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </div>
                        </div>
                    )
                }
            }
        ]
    }, [history, handleOpenJobList, handleStatusChange, rowStatus, handleNotesDrawer,handleOpenMailDrawer]);


    return (
        <>
            <ModalComponent open={openResumeParserModal} closable={false} onCancel={()=>setOpenResumeParserModal(false)} width={500} footer={null}>
                <ResumeParserComponent setOpenResumeParserModal={setOpenResumeParserModal}/>
            </ModalComponent>
            <DrawerComponent isOpen={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                <ApplicantFilterDrawerComponent
                    filterData={filterData}
                    setFilterData={setFilterData}
                    resetFilters={resetFilters}
                    hcpTypes={hcpTypes}
                    setOpenFilterDrawer={setOpenFilterDrawer}
                    stateList={regions}
                />
            </DrawerComponent>
            <ModalComponent open={openChangeStatusModal} onCancel={()=>setOpenChangeStatusModal(false)} width={600} footer={null} title={'Change Applicant Status'}>
                <ChangeStatusComponent
                    selectedJobs={selectedApplicants}
                    setOpenChangeStatusModal={setOpenChangeStatusModal}
                    setSelectedJobs={setSelectedApplicants}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    module={'applicant'}
                />
            </ModalComponent>
            <DrawerComponent isOpen={openTagJobDrawer} width={700} showClose={true} title={'Tag a Job'} onClose={()=>{
                setOpenTagJobDrawer(false);
            }}>
                <TagJobComponent
                    mode={'list'}
                    id={selectedApplicantId}
                    cancel={()=>setOpenTagJobDrawer(false)}
                    selectedRowData={selectedApplicantRowData}
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
            </DrawerComponent>
            <ModalComponent open={openAddNotesModal} onCancel={()=>setOpenAddNotesModal(false)} width={600} footer={null} title={'Add Notes'}>
                <div className={'bulk-notes-wrapper'}>
                    <TextEditorComponent
                        className={"notes-text-editor"}
                        value={notes}
                        onChange={(value: any) => setNotes(value)}
                    />
                    <div className={"job-notes-btn-wrapper"}>
                        <Button
                            onClick={handleNotesClose}
                            variant={"outlined"} id={'add_facility_btn'}
                            color={"primary"}
                        >
                            &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                        </Button>
                        <Button
                            onClick={handleNotesAdd}
                            variant={"contained"} id={'add_facility_btn'}
                            color={"primary"}
                        >
                            &nbsp;&nbsp;Save&nbsp;&nbsp;
                        </Button>
                    </div>
                </div>
            </ModalComponent>
            <ModalComponent open={openChangeRecruiterModal} onCancel={()=>setOpenChangeRecruiterModal(false)} width={600} footer={null} title={'Add/Change Recruiter'}>
                <ChangeRecruiterComponent selectedJobs={selectedApplicants} setSelectedJobs={setSelectedApplicants} setOpenChangeRecruiterModal={setOpenChangeRecruiterModal}  filterData={filterData}
                                          setFilterData={setFilterData} module={'applicant'}/>
            </ModalComponent>
            <ModalComponent open={openChangeHrModal} onCancel={()=>setOpenChangeHrModal(false)} width={600} footer={null} title={'Add/Change HR'}>
                <ChangeHrComponent selectedIds={selectedApplicants} setSelectedIds={setSelectedApplicants} setOpenChangeHrModal={setOpenChangeHrModal}  filterData={filterData}
                                          setFilterData={setFilterData} module={'applicant'}/>
            </ModalComponent>
            <Drawer title={'Notes'} onClose={()=>setOpenNotesListDrawer(false)} open={openNotesListDrawer} destroyOnClose width={500} zIndex={99999}>
                <JobNotesComponent
                    id={selectedApplicantId}
                    showTitle={false}
                    module={'applicant'}
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
            </Drawer>
            <DrawerComponent  isOpen={openEmailDrawer} title={'Email'} showClose={false} onClose={()=> {
                setOpenEmailDrawer(false)
                setSelectedTemplate('');
                setEmailMessage('');
            }} width={650}
                              headerActions={
                                  <div className={'d-flex align-items-center template-select-wrapper'}>
                                          <SelectComponent
                                              options={templateList || []}
                                              value={selectedTemplate}
                                              placeholder={"Select Template"}
                                              allowClear={true}
                                              valueExtractor={(option:any)=>option.code}
                                              onChange={(value: any) => {
                                                  const template = value
                                                      ? templateList?.find((t:any) => t.code === value)
                                                      : null;
                                                  setSelectedTemplate(value ? value : '');
                                                  setEmailMessage(value ? template?.message : '');
                                              }}
                                          />
                                      <div className={'mrg-left-20 cursor-pointer'} onClick={()=> {
                                          setOpenEmailDrawer(false)
                                          setSelectedTemplate('');
                                          setEmailMessage('');
                                      }}>
                                          <img src={ImageConfig.CrossIcon} alt="close"/>
                                      </div>
                                  </div>
                              }
            >
                <SingleEmailEditorComponent
                    recipientEmail={email}
                    setOpenEmailDrawer={setOpenEmailDrawer}
                    isEditable={false}
                    emailMessage={emailMessage}
                    setEmailMessage={setEmailMessage}
                />
            </DrawerComponent>
            <Drawer title="List of Jobs" onClose={()=>setOpenJobListDrawer(false)} open={openJobListDrawer} destroyOnClose width={900} zIndex={999999999}>
                <div>
                    <div className="d-flex">
                        <div className="d-flex position-relative">
                            {!jobFilterData.search ? (
                                <div className={"search_icon"}>
                                    <SearchRounded/>
                                </div>
                            ) : (
                                <div className={"search_icon"}>
                                    <ClearIcon
                                        onClick={(event) => {
                                            setJobFilterData({...jobFilterData, search: '', page: 1})
                                        }}
                                        id="clear_facility_search"
                                    />
                                </div>
                            )}
                            <div>
                                <CssTextField
                                    defaultValue={""}
                                    className="search-cursor searchField"
                                    id="input_search_facility"
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setJobFilterData({...jobFilterData, search: value, page: 1});
                                    }}
                                    value={jobFilterData.search}
                                    variant={"outlined"}
                                    size={"small"}
                                    type={"text"}
                                    placeholder={"Search Job Title"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'mrg-top-20'}>
                        <AntTableComponent
                            url={ENV.API_URL + 'ats/jobs/listLite'}
                            method={'post'}
                            columns={jobColumnList || []}
                            extraPayload={jobFilterData}
                            expandable={true}
                            noDataText={'No Jobs linked to this Applicant'}
                        />
                    </div>
                </div>
            </Drawer>
            <div className={"facility-list screen crud-layout pdd-30 pdd-top-10"}>
                <div className="custom-border pdd-10 pdd-top-20 pdd-bottom-0">
                    <div className="header">
                        <div className="mrg-left-5 filter">
                            <div>
                                <div className="d-flex">
                                    <div className="d-flex position-relative">
                                        {!filterData.search ? (
                                            <div className={"search_icon"}>
                                                <SearchRounded/>
                                            </div>
                                        ) : (
                                            <div className={"search_icon"}>
                                                <ClearIcon
                                                    onClick={(event) => {
                                                        setFilterData({...filterData, search: '', page: 1})
                                                        setSelectedApplicants([]);
                                                    }}
                                                    id="clear_facility_search"
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <CssTextField
                                                defaultValue={""}
                                                className="search-cursor searchField"
                                                id="input_search_facility"
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    setFilterData({...filterData, search: value, page: 1});
                                                }}
                                                value={filterData.search}
                                                variant={"outlined"}
                                                size={"small"}
                                                type={"text"}
                                                placeholder={"Search Applicants"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'mrg-left-20'}>
                            <Button
                                variant={"outlined"} id={'add_facility_btn'}
                                color={"primary"}
                                onClick={() => {
                                    setOpenFilterDrawer(true)
                                }}
                            >
                                {filterCount > 0 &&
                                    <span className={'filter-btn-badge'}>{filterCount}</span>
                                }
                                &nbsp;&nbsp;Filters&nbsp;&nbsp;
                            </Button>
                        </div>
                        {filterData.status === 'rejected' && <>
                            <div className={'mrg-left-20'}>
                                <Tooltip title="Move to Pending">
                                    <Button variant={"contained"} disabled={isRejectedHcpMoved} color={"primary"}
                                            onClick={handleRejectedUserToPending}>
                                        &nbsp;&nbsp;Move to pending&nbsp;&nbsp;
                                    </Button>
                                </Tooltip>
                            </div>
                        </>}
                        <div className="mrg-left-10">
                            <Button component={Link} to={"/applicant/bulk_upload"} variant={"contained"}
                                    color={"primary"}>
                                &nbsp;&nbsp;Bulk Upload&nbsp;&nbsp;
                            </Button>
                        </div>
                        {/*<div className="mrg-left-10">*/}
                        {/*    <Button variant={"contained"}*/}
                        {/*            color={"primary"} onClick={()=>setOpenResumeParserModal(true)}>*/}
                        {/*        &nbsp;&nbsp;Resume Parser&nbsp;&nbsp;*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                        <div className="mrg-left-20 mrg-right-20">
                            <Button
                                variant={"contained"} id={'add_facility_btn'}
                                color={"primary"}
                                component={Link}
                                to={'/applicant/add'}
                            >
                                <AddRounded/>
                                &nbsp;&nbsp;Add New&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div>
                            <Popover
                                trigger={'click'}
                                placement={'bottomRight'}
                                destroyTooltipOnHide={true}
                                open={popoverVisible}
                                onOpenChange={(visible) => setPopoverVisible(visible)}
                                content={<ApplicantMenuListComponent onMenuItemClick={handleMenuItemClick}
                                                                     selectedApplicants={selectedApplicants}
                                />}
                            >
                                <div className={'applicant-box'} onClick={() => setPopoverVisible(!popoverVisible)}>
                                    <ImageConfig.KebabIcon className={'applicant-edit-icon'}/>
                                </div>
                            </Popover>
                        </div>
                    </div>
                    <div className={'facility-table-component mrg-top-20'}>
                        <AntTableComponent
                            isBordered={true}
                            url={ENV.API_URL + "ats/applicantList"}
                            method={'post'}
                            extraPayload={filterData}
                            columns={hcpListColumns}
                            noDataText={'No Applicants Found.'}
                            isRowSelection={true}
                            selectedRowKeys={selectedApplicants}
                            setSelectedRowKeys={setSelectedApplicants}
                            setSelectedRowData={setSelectedApplicantRowData}
                        />
                    </div>
                </div>
            </div>
        </>
)
}

export default ApplicantListComponent