import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {ENV} from "../../../../constants";
import {useState} from "react";
import {PipelineListColumn} from "../pipelineListColumn";
import {useLocation} from "react-router-dom";


const NewLeadComponent=()=>{
   const location=useLocation();
    const [filterData]=useState<any>({
        recruiter_id:'',
        status:['open'],
        search:'',
    })
    return (
        <div>
            <AntTableComponent
                url={ENV.API_URL + "ats/jobApplicationPipeline"}
                method={'post'}
                extraPayload={filterData}
                columns={PipelineListColumn(location?.search) || []}
                noDataText={'No New Lead Data Found.'}
            />
        </div>
    )
}
export default NewLeadComponent