import {ColumnsType} from "antd/es/table";
import {Tooltip} from "@material-ui/core";
import React from "react";
import {CommonService} from "../../../helpers";
import {Link} from "react-router-dom";




export const ContactedTableColumnList=(tab:string | undefined):ColumnsType=>[
    {
        title: "Applicant Name",
        width: 250,
        render:(item:any)=>{
            return (
                <div>
                    {(item?.first_name?.length + item?.last_name?.length)>20 ?
                        <Tooltip title={item?.first_name + " " + item?.last_name}>
                            <Link to={{
                                pathname:`/applicant/view/${item?._id}`,
                                state: {
                                    from:'pipeline',
                                    currentTab:tab || 'new_lead'
                                }
                            }} className={'ellipses-for-table-data'} style={{textDecoration:'underline',cursor:'pointer'}}>
                                {item?.first_name + " " + item?.last_name}
                            </Link>
                        </Tooltip>:
                        <Link to={{
                            pathname:`/applicant/view/${item?._id}`,
                            state: {
                                from:'pipeline',
                                currentTab:tab || 'new_lead'
                            }
                        }} className='facility-name' style={{textDecoration:'underline',cursor:'pointer'}}>
                            {(item?.first_name + " " + item?.last_name) || 'N/A'}
                        </Link>
                    }
                </div>
            )
        }
    },
    {
        title:'Staff Type',
        width:200,
        render:(item:any)=>{
            return (
                <div>
                    {item?.hcp_type?.length > 20 ?
                        <Tooltip title={item?.hcp_type}>
                            <div className={'ellipses-for-table-data'}>
                                {item?.hcp_type}
                            </div>
                        </Tooltip>
                        :
                        <div className='facility-name'>
                            {item?.hcp_type || 'N/A'}
                        </div>
                    }
                </div>
            )
        }
    },
    {
        title:'Recruiter',
        width:180,
        dataIndex:'recruiter_details',
        render:(item:any)=>{
            return(
                <>
                    {item ?
                        <div>
                            {(item?.first_name + item?.last_name)?.length > 20 ?
                                <Tooltip title={item?.first_name + " " + item?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.first_name + " " + item?.last_name || 'N/A'}
                                    </div>
                                </Tooltip>
                                :
                                <div>
                                    {item?.first_name + " " + item?.last_name || 'N/A'}
                                </div>
                            }
                        </div>
                        :
                        <div>N/A</div>}
                </>
            )
        }
    },
    {
        title:'Email',
        width:200,
        render:(item:any)=>{
            return (
                <div>
                    {item?.email?.length > 20 ?
                        <Tooltip title={item?.email}>
                            <div className={'ellipses-for-table-data'}>
                                {item?.email}
                            </div>
                        </Tooltip>
                        :
                        <div className='facility-name'>
                            {item?.email || 'N/A'}
                        </div>
                    }
                </div>
            )
        }
    },
    {
        title: "Status",
        width: 150,
        render: (item: any) => {
            return (
                <div>
                    {item?.status?.length > 25 ?
                        <Tooltip title={item?.status}>
                            <div className={'ellipses-for-table-data '}>{CommonService.formatTitleCase(item?.status)}</div>
                        </Tooltip> :
                        <div className={'facility_name'}>
                            {CommonService.formatTitleCase(item?.status) || 'N/A'}
                        </div>
                    }
                </div>
            );
        },
    },
    {
        title:'Contact Number',
        width:160,
        render:(item:any)=>{
            return (
                <div>
                    {CommonService.formatPhoneNumber(item?.contact_number?.replace(/^\+1/,'')) || 'N/A'}
                </div>
            )
        }
    },
    {
        title:'Staff Source',
        width:180,
        render:(item:any)=>{
            return (
                <div>
                    {CommonService.formatTitleCase(item?.applied_from) || 'N/A'}
                </div>
            )
        }
    },

]