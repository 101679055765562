import "./DashboardGraphComponent.scss";
import {useEffect} from "react";
import {Communications} from "../../../helpers";

const DashboardGraphComponent=()=>{
    useEffect(() => {
        Communications.pageTitleSubject.next("Dashboard");
        Communications.pageBackButtonSubject.next(null);
    }, []);
    return (
        <div className={"facility-list screen crud-layout pdd-30 pdd-top-10"}>
            <div className="custom-border graph-component">
                <iframe
                    src="https://vars-dashboard-streamlit.superengineer.ai/"
                    width="100%"
                    height="100%"
                    style={{border: "1px solid #D2D2D2"}}
                    title="Dashboard"
                ></iframe>
            </div>
        </div>
    )
}

export default DashboardGraphComponent