import "./JobNotesComponent.scss";
import { Button, Popover, Menu } from "antd";
import { PlusOutlined, MoreOutlined } from "@ant-design/icons";
import React, {useCallback, useEffect, useRef, useState} from "react";
import TextEditorComponent from "../../../../../components/text-editor/TextEditorComponent";
import { ENV } from "../../../../../constants";
import { ApiService, CommonService } from "../../../../../helpers";
import LoaderComponent from "../../../../../components/loader/LoaderComponent";
import moment from "moment";
import { useSelector } from "react-redux";
import { StateParams } from "../../../../../store/reducers";

interface JobNotesComponentProps {
    id?: string;
    jobDetails?: any;
    showTitle?: boolean;
    module?:string;
    filterData?:any;
    setFilterData?:any;
}

const JobNotesComponent = (props: JobNotesComponentProps) => {
    const { id, showTitle = true ,module,filterData,setFilterData} = props;
    const drawerRef = useRef<HTMLDivElement>(null);
    const [isNotesFormOpen, setIsNotesFormOpen] = useState<boolean>(false);
    const [notes, setNotes] = useState<string>("");
    const [notesList, setNotesList] = useState<any[]>([]);
    const [isNotesListLoading, setIsNotesListLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [editableNoteId, setEditableNoteId] = useState<string | null>(null);
    const [addedById, setAddedById] = useState<string | null>(null);
    const [notesOwner, setNotesOwner] = useState<boolean>(false);
    const { user } = useSelector((state: StateParams) => state.auth);
    const [userRole, setUserRole] = useState<string | null>(null);
    const [isVisible, setIsVisible] = useState<{ [key: string]: boolean }>({});


    const togglePopover = useCallback((noteId: string,visible:boolean) => {
        setIsVisible((prev) => ({
            ...prev,
            [noteId]: !prev[noteId],
            [noteId]:visible
        }));
    },[])

    const closeAllPopovers = useCallback(() => {
        setIsVisible({});
    },[])



    const getNotesDetails = useCallback(() => {
        setIsNotesListLoading(true);
        let apiCall:any;
        if(module ==='applicant'){
            apiCall=CommonService._api.get(ENV.API_URL + `hcp/${id}/notes`)
        }else{
            apiCall=CommonService._api.get(ENV.API_URL + `job/${id}/notes`)
        }
       apiCall.then((response: any) => {
                setNotesList(response?.data?.reverse());
            })
            .catch((error: any) => {
                CommonService.showToast(error.error, "error");
            })
            .finally(() => {
                setIsNotesListLoading(false);
            });
    }, [id,module]);

    useEffect(() => {
        getNotesDetails();
    }, [getNotesDetails]);

    const handleNotesAdd = useCallback(() => {
        const payload = {
            notes: notes || "",
        };
        setIsSubmitting(true);
        let apiUrl:any;
        if(module === 'applicant'){
             apiUrl = editableNoteId
                ? ENV.API_URL + `hcp/${id}/note/${editableNoteId}`
                : ENV.API_URL + `hcp/${id}/notes`;
        }else{
             apiUrl = editableNoteId
                ? ENV.API_URL + `job/${id}/note/${editableNoteId}`
                : ENV.API_URL + `job/${id}/notes`;
        }
        const apiMethod = editableNoteId
            ? CommonService._api.put
            : CommonService._api.post;
        apiMethod(apiUrl, payload)
            .then((response: any) => {
                CommonService.showToast(response.message, "success");
                getNotesDetails();
                setFilterData({...filterData})
            })
            .catch((error: any) => {
                CommonService.showToast(error.error, "error");
            })
            .finally(() => {
                setIsSubmitting(false);
                setNotes("");
                setIsNotesFormOpen(false);
                setEditableNoteId(null);
            });
    }, [id, notes, getNotesDetails, editableNoteId,module,filterData,setFilterData]);

    const handleNotesClose = useCallback(() => {
        setNotes("");
        setIsNotesFormOpen(false);
        setEditableNoteId(null);
    }, []);

    const onDeleteNotes = useCallback(() => {
        let apiCall:any;
        if(module === 'applicant'){
            apiCall=ApiService.delete(ENV.API_URL + `hcp/${id}/note/${addedById}`)
        }else{
            apiCall=ApiService.delete(ENV.API_URL + `job/${id}/note/${addedById}`)
        }
        apiCall
            .then((resp: any) => {
                setIsNotesListLoading(true);
                if (resp) {
                    CommonService.showToast(
                        resp?.msg || "Job notes removed",
                        "success"
                    );
                    setIsNotesFormOpen(false);
                    setIsNotesListLoading(false);
                    getNotesDetails();
                }
            })
            .catch((err:any) => {
                console.log(err);
                CommonService.showToast(err.error || "Error", "error");
            })
            .finally(() => {
                setIsNotesListLoading(false);
            });
    }, [addedById, getNotesDetails, id,module]);

    useEffect(() => {
        if (user?.role === "super_admin" || user?.role === "teric_admin") {
            setUserRole(user?.role);
        } else {
            setUserRole(null);
        }
    }, [user, notesOwner]);

    const popoverContent = useCallback((note: any) => (
        <Menu className={'menu-note-wrapper'}>
            {notesOwner && (
                <Menu.Item
                    onClick={() => {
                        setIsNotesFormOpen(true);
                        console.log(note)
                        if (drawerRef.current) {
                            drawerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                        }
                        closeAllPopovers();
                    }}
                >
                    Edit
                </Menu.Item>
            )}
            {(userRole === "super_admin" || userRole === "teric_admin") && (
                <Menu.Item onClick={()=>{
                    closeAllPopovers();
                    onDeleteNotes();
                }}>Delete</Menu.Item>
            )}
        </Menu>
    ),[notesOwner,onDeleteNotes,userRole,closeAllPopovers])

    return (
        <div ref={drawerRef} className={"job-notes-component"}>
            <div className={"job-notes-header"}>
                {showTitle && <div className={"notes-heading"}>Notes</div>}
                <Button
                    type="primary"
                    className="add-job-button"
                    icon={<PlusOutlined />}
                    disabled={isNotesFormOpen}
                    onClick={() => {
                        setIsNotesFormOpen(true);
                        setNotes("");
                        setEditableNoteId(null);
                    }}
                >
                    Add Notes
                </Button>
            </div>
            <div className={"job-notes-content-holder"}>
                {isNotesFormOpen && (
                    <>
                        <TextEditorComponent
                            className={"notes-text-editor"}
                            value={notes}
                            onChange={(value: any) => setNotes(value)}
                        />
                        <div className={"job-notes-btn-wrapper"}>
                            <Button onClick={handleNotesClose} className="job-button">
                                Cancel
                            </Button>
                            <Button
                                onClick={handleNotesAdd}
                                className="job-button"
                                loading={isSubmitting}
                            >
                                Save
                            </Button>
                        </div>
                    </>
                )}
                {!isNotesListLoading && notesList ? (
                    <div className={"job-notes"}>
                        {notesList?.map((note: any) => {
                            const m = moment(note?.created_at);
                            return (
                                <div className={"notes-card"} key={note?._id}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: note.notes }}
                                            className={"notes-card-content"}
                                        ></div>
                                        <Popover
                                            content={popoverContent(note)}
                                            trigger="click"
                                            placement={'topLeft'}
                                            open={isVisible[note._id] || false}
                                            destroyTooltipOnHide={true}
                                            onOpenChange={(visible) => {
                                                if (!visible) {
                                                    togglePopover(note._id, false);
                                                }
                                            }}
                                        >
                                            <Button
                                                icon={<MoreOutlined />}
                                                disabled={
                                                    isNotesFormOpen ||
                                                    (note?.user_details?._id !== user?._id &&
                                                        userRole !== "super_admin" &&
                                                        userRole !== "teric_admin")
                                                }
                                                onClick={() => {
                                                    setEditableNoteId(note._id);
                                                    setNotes(note.notes);
                                                    setAddedById(note?._id);
                                                    togglePopover(note._id,true);
                                                    setNotesOwner(note?.user_details?._id === user?._id);
                                                }}
                                            />
                                        </Popover>
                                    </div>
                                    <div className={"notes-author"}>
                                        Added By : {note?.user_details?.first_name}{" "}
                                        {note?.user_details?.last_name} | {m.format("dddd")} |{" "}
                                        {m.format("MMMM Do YYYY")} | {m.format("h:mm:ss a")}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className={'loader-notes'}>
                    <LoaderComponent type={"spinner"} color={"primary"} size={"sm"} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default JobNotesComponent;
