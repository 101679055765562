import React from "react";
import AddHcpComponent from "./addHcpComponent/AddHcpComponent";
import "./AddHcpToShiftsDialog.scss";

interface AddHcpToShiftsProps {
    confirm: () => void;
    cancel: () => void;
    selectedHcp: any[];
    selectedShifts: any[];
    selectedHcps?: any;
    requirementType?: any;
}

const AddHcpToShifts = (props: AddHcpToShiftsProps) => {
    const [selectedHcp, setSelectedHcp] = React.useState<any>(null);
    const {cancel, confirm, selectedShifts, selectedHcp: selectedHcpType, selectedHcps, requirementType} = props;

    return (
        <div className="dialog-container">
            <AddHcpComponent
                requirementType={requirementType}
                shiftsCount={selectedShifts?.length}
                selectedShifts={selectedShifts}
                selectedHcp={selectedHcp}
                selectedHcps={selectedHcps}
                setSelectedHcp={setSelectedHcp}
                cancel={cancel}
                confirm={confirm}
                hcp_type={selectedHcpType?.length === 1 ? selectedHcpType[0] : ""}
            />
        </div>
    );
};
export default AddHcpToShifts;
