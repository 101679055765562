import {Form, Formik, FormikHelpers} from "formik";
import {useCallback, useEffect, useState} from "react";
import * as Yup from 'yup';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import PayRateTabComponent from "./pay-rate/PayRateTabComponent";
import BillRateTabComponent from "./bill-rate/BillRateTabComponent";
import {ApiService, CommonService} from "../../../../../../helpers";
import {ENV} from "../../../../../../constants";
import {useHistory} from "react-router-dom";

interface PermanentRateCardComponentProps{
    apiDataAfterAdd?:any;
    rate_details?:any;
    setIsSubmitting?:any;
}


const validationSchema = Yup.object().shape({
});
const PermanentRateCardComponent=(props:PermanentRateCardComponentProps)=>{
    const {apiDataAfterAdd,rate_details,setIsSubmitting}=props;
    const history=useHistory();
    const [activeTab,setActiveTab]=useState<string>('pay_rate')

    const updatedInitialValues = useCallback(() => ({
        bill_rate: {
            total_bill_rate: rate_details?.permanent_rate_card?.bill_rate?.total_bill_rate || '',
            salary: rate_details?.permanent_rate_card?.bill_rate?.salary || '',
        },
        pay_rate: {
            total_pay_rate: rate_details?.permanent_rate_card?.pay_rate?.total_pay_rate || '',
            salary: rate_details?.permanent_rate_card?.pay_rate?.salary || '',
        }
    }), [rate_details]);


    const TabColumn: TabsProps['items'] = [
        {
            key: 'pay_rate',
            label: 'Pay Rate',
            children: <PayRateTabComponent/>,
        },
        {
            key: 'bill_rate',
            label: 'Bill Rate',
            children: <BillRateTabComponent/>,
        },

    ];

    const handleTabChange=useCallback((key: string)=>{
        setActiveTab(key)
    },[])

    const onSubmit=useCallback((values: any, { setErrors, setSubmitting }: FormikHelpers<any>)=>{
        setIsSubmitting(true);
        let payload={
            permanent_rate_card:{
                ...values
            },
            workflow_status:'completed',
        }
        ApiService.put(ENV.API_URL + `ats/${apiDataAfterAdd?._id}/ratecard`, payload)
            .then((resp: any) => {
                CommonService.showToast('Job Details Updated Successfully','success');
                history.push("/job");
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors,error)
            }).finally(()=>{
            setIsSubmitting(false)
        })
    },[apiDataAfterAdd,history,setIsSubmitting])


    return (
        <div>
            <Formik
                validationSchema={validationSchema}
                initialValues={
                    updatedInitialValues()
                }
                validateOnChange={false}
                validateOnBlur={true}
                validateOnMount={false}
                onSubmit={onSubmit}
            >
                {({
                      values,
                      validateForm,
                  }) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                       <Form noValidate={true} id="add-job-rate-card">
                           <Tabs defaultActiveKey={activeTab} items={TabColumn} onChange={handleTabChange}/>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    )
}
export default PermanentRateCardComponent