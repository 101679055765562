import "./JobEditCredentialComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {jobCertificationsDocs, jobDocuments, jobLicense, jobSkillsDoc,jobCompetencyTestDocs} from "../../../../../constants/data";
import {Button, Divider} from "antd";
import {ApiService, CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import {Form, Formik} from "formik";
import AddDocumentDrawer from "../../add-job/job-credentialing/documents-modal/DocumentsModalComponent";
import InputComponent from "../../../../ant-component/input/InputComponent";




interface SectionState {
    [key: string]: string[];
}

interface StatJobCredentialingComponentsProps {
    setButtonText?: any
    next?: any
    jobDetails:any
    getJobDetails?:any;
    setJobId?:any;
    setIsSubmitting?:any;
}


const JobEditCredentialComponent=(props:StatJobCredentialingComponentsProps)=>{
    const {setButtonText, next,jobDetails,getJobDetails,setIsSubmitting} = props;
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [activeSection, setActiveSection] = useState<string>("");
    const [newDocument, setNewDocument] = useState<{ [key: string]: string }>({});
    const [showInput,setShowInput]=useState<{ [key: string]: boolean }>({})
    const [sectionState, setSectionState] = useState<SectionState>({
        documents: [],
        certifications: [],
        licenses: [],
        skills: [],
        competency_test:[]
    });

    // Available options for each section
    const availableOptions: SectionState = {
        documents: jobDocuments,
        certifications: jobCertificationsDocs,
        licenses: jobLicense,
        skills: jobSkillsDoc,
        competency_test:jobCompetencyTestDocs
    };

    useEffect(() => {
        const documentInitial = {
            documents: jobDetails?.required_credentials?.documents_submission || [],
            certifications: jobDetails?.required_credentials?.certifications_submission || [],
            licenses: jobDetails?.required_credentials?.license_submission || [],
            skills: jobDetails?.required_credentials?.skill_checklist_submission || [],
            competency_test:jobDetails?.required_credentials?.competency_test || [],
        }
        setSectionState(documentInitial)
    }, [jobDetails]);


    useEffect(() => {
        if (sectionState?.documents?.length > 0 || sectionState?.certifications?.length > 0 || sectionState?.licenses?.length > 0 || sectionState?.skills?.length > 0 || sectionState?.competency_test?.length > 0) {
            setButtonText("Next")
        } else {
            setButtonText("Skip")
        }
    }, [sectionState, setButtonText]);

    // Open the drawer for a specific section
    const openDrawer = useCallback((section: string) => {
        setActiveSection(section);
        setIsDrawerVisible(true);
    }, []);

    // Close the drawer
    const closeDrawer = useCallback(() => {
        setIsDrawerVisible(false);
    }, []);

    //add other document
    const addOtherDocument = useCallback((sectionKey: string) => {
        if (newDocument[sectionKey]) {
            setSectionState((prev) => ({
                ...prev,
                [sectionKey]: [...prev[sectionKey], newDocument[sectionKey]],
            }));
            setNewDocument((prev) => ({ ...prev, [sectionKey]: "" }));
            setShowInput((prev)=>({
                ...prev,
                [sectionKey]:false
            }))
        }
    },[newDocument])

    const handleOtherDocumentClose=useCallback((sectionKey)=>{
        setNewDocument((prev) => ({ ...prev, [sectionKey]: "" }));
        setShowInput((prev)=>({
            ...prev,
            [sectionKey]:false
        }))
    },[])


    // Update the selected items for a section
    const updateSection = useCallback((section: string, selectedItems: string[]) => {
        setSectionState((prev) => ({
            ...prev,
            [section]: selectedItems,
        }));
        closeDrawer();
    }, [closeDrawer]);


    const removeDocument = useCallback((sectionKey, index) => {
        setSectionState((prev) => ({
            ...prev,
            [sectionKey]: prev[sectionKey].filter((_, i) => i !== index),
        }))
    }, [])

    const renderSection = (
            title: string,
            sectionKey: string,
            addButtonText: string
        ) => (
            <div className="required-documents-layout__section">
                <h3 className="required-documents-layout__section-title">{title}</h3>
                <div className="required-documents-layout__content">
                    {sectionState[sectionKey]?.length > 0 && <>
                        {
                            sectionState[sectionKey]?.map((item, index) => {
                                return (
                                    <>
                                        <div className={'document-tag'}>
                                            <div className={'job-doc-text'}>{item}</div>
                                            <div className={'doc-cross-icon cursor-pointer'} onClick={() => {
                                                removeDocument(sectionKey, index)
                                            }}>x
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </>}
                    {sectionState[sectionKey]?.length === 0 && <>
                        <p className="required-documents-layout__no-selection">
                            No {CommonService.formatTitleCase(sectionKey)} Selected
                        </p>
                    </>}
                    <Button
                        type="link"
                        className="required-documents-layout__add-button"
                        icon={<span style={{fontWeight: "bold", fontSize: "16px"}}>+</span>}
                        onClick={() => openDrawer(sectionKey)}
                    >
                        {addButtonText}
                    </Button>
                    {showInput?.[sectionKey] ?
                        <div className={'d-flex'}>
                            <InputComponent
                                autoFocus={true}
                                className={'mrg-right-20'}
                                placeholder="Enter Other Document"
                                value={newDocument[sectionKey] || ""}
                                onChange={(value:string) => setNewDocument((prev) => ({ ...prev, [sectionKey]: value }))}
                                onPressEnter={() => addOtherDocument(sectionKey)}
                            />
                            <Button className={'custom-outline-button mrg-right-20'} onClick={()=>addOtherDocument(sectionKey)}>
                                Add
                            </Button>
                            <Button className={'custom-outline-button'} onClick={()=>handleOtherDocumentClose(sectionKey)}>
                               Close
                            </Button>
                        </div>:
                        <Button
                            type="link"
                            className="required-documents-layout__add-button"
                            icon={<span style={{fontWeight: "bold", fontSize: "16px"}}>+</span>}
                            onClick={() => {
                                setShowInput((prevState:any)=>({
                                    ...prevState,
                                    [sectionKey]:true
                                }))
                            }}
                        >
                            Add Additional Documents
                        </Button>
                    }
                </div>
                <Divider/>
            </div>
        )
    ;

    const onSubmitBasicDetails = useCallback((values: any) => {
        // insertedId
        const payload = {
            required_credentials: {
                ...values,
                documents_submission: sectionState.documents,
                certifications_submission: sectionState.certifications,
                license_submission: sectionState.licenses,
                skill_checklist_submission: sectionState.skills,
                competency_test:sectionState.competency_test
            }
        }
        if (sectionState?.documents?.length > 0 ||
            sectionState?.certifications?.length > 0 ||
            sectionState?.licenses?.length > 0 ||
            sectionState?.skills?.length > 0 ||
            sectionState?.competency_test?.length > 0
        ) {
            setIsSubmitting(true)
            ApiService.put(ENV.API_URL + `ats/${jobDetails?._id}/job`, payload)
                .then((resp: any) => {
                    CommonService.showToast('Documents updated successfully','success');
                    // setJobId(resp?.data?._id);
                    getJobDetails(jobDetails?._id);
                    next()
                })
                .catch((err: any) => {
                    CommonService.showToast(err.error ,'error')
                }).finally(()=>{
                setIsSubmitting(false)
            })
        } else {
            next()
        }

    }, [next, sectionState, jobDetails,getJobDetails,setIsSubmitting])

    return (
        <Formik
            initialValues={{}}
            onSubmit={onSubmitBasicDetails}
        >
            {({handleSubmit}) => (
                <Form onSubmit={handleSubmit} id="add-job-credentialing">
                    <div className="required-documents-layout">
                        {renderSection("Required Documents:", "documents", "Add Documents")}
                        {renderSection("Required Certifications:", "certifications", "Add Certificates")}
                        {renderSection("Required License:", "licenses", "Add Licenses")}
                        {renderSection("Required Skill Checklist:", "skills", "Add Skills")}
                        {renderSection("Required Competency Test:", "competency_test", "Add Competency Test")}
                        <AddDocumentDrawer
                            visible={isDrawerVisible}
                            onClose={closeDrawer}
                            onUpdate={(selectedItems: any) => updateSection(activeSection, selectedItems)}
                            selectedItems={sectionState[activeSection]}
                            availableOptions={availableOptions[activeSection]}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
export default JobEditCredentialComponent