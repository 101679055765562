import FormLabelComponent from "../../../../ant-component/form-label/FormLabelComponent";
import SelectDropdownComponent from "../../../../ant-component/select/SelectComponent";
import {useCallback, useEffect, useState} from "react";
import TextEditorComponent from "../../../../../components/text-editor/TextEditorComponent";
import {Button} from "antd";
import {CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";

interface ChangeHrComponentProps{
    selectedIds:string[];
    setOpenChangeHrModal?:any;
    setSelectedIds?:any;
    filterData?:any;
    setFilterData?:any;
    module?:any;
}

const ChangeHrComponent=(props:ChangeHrComponentProps)=>{
    const {selectedIds,setOpenChangeHrModal,setSelectedIds,filterData,setFilterData}=props;
    const [hrId,setHrId]=useState<string>("");
    const [reason,setReason]=useState<string>('');
    const [isSubmitting,setIsSubmitting]=useState<boolean>(false)
    const [isNcListLoading,setIsNcListLoading]=useState<boolean>(false);
    const [modifiedNcList,setModifiedNcList]=useState<any>([]);

    const getNcList=useCallback(()=>{
        setIsNcListLoading(true);
        CommonService._api.get(ENV.API_URL + `user/lite?is_active=true&roles[]=hr`).then((response:any)=>{
            const transformedData = response?.data?.map((item:any) => ({
                code: item?._id,
                title: item?.first_name + " " + item?.last_name,
            }));
            setModifiedNcList(transformedData || []);
        }).catch((error:any)=>{
            console.log(error)
        }).finally(()=>{
            setIsNcListLoading(false);
        })
    },[])

    useEffect(() => {
        getNcList()
    }, [getNcList]);

    const handleChangeRecruiter=useCallback(()=>{
        setIsSubmitting(true);
       let payload:any={
            user_id:hrId,
            reason:reason,
            hcp_ids:selectedIds
        }

        //integrate API
        CommonService._api.put(ENV.API_URL + `hcp/user/linkHrHcps`,payload).then((response:any)=>{
            CommonService.showToast(response.message || 'Successfully changed the hr','success')
            setFilterData({...filterData})
        }).catch((error:any)=>{
            CommonService.showToast(error.error,'error')
        }).finally(()=>{
            setOpenChangeHrModal(false);
            setSelectedIds([]);
            setIsSubmitting(false);
        })
    },[setOpenChangeHrModal,selectedIds,hrId,reason,setSelectedIds,filterData,setFilterData])

    const handleCancel=useCallback(()=>{
        setReason('');
        setHrId('');
        setOpenChangeHrModal(false);
        setSelectedIds([])
    },[setOpenChangeHrModal,setSelectedIds])

    return (
        <div className={'change-status-component'}>
            <div className={'mrg-top-20'}>
                <FormLabelComponent Label={"HR*"}/>
                <SelectDropdownComponent
                    placeholder={'Select'}
                    className={'mrg-top-10'}
                    value={hrId}
                    disabled={isNcListLoading}
                    options={modifiedNcList || []}
                    required={true}
                    size={"middle"}
                    allowClear={true}
                    displayWith={(option) => option.title}
                    valueExtractor={(option) => option.code}
                    onChange={(value: string) => {
                        setHrId(value)
                    }}
                />
            </div>
            <div className={'mrg-top-20'}>
                <FormLabelComponent Label={"Reason(Optional)"}/>
                <div className={'change-status-text-editor-wrapper'}>
                    <TextEditorComponent
                        value={reason}
                        onChange={(value: string) => setReason(value)}
                    />
                </div>
            </div>
            <div className={'status-btn-wrapper'}>
                <Button
                    type={'primary'}
                    disabled={!hrId}
                    className="custom-primary-button"
                    onClick={handleChangeRecruiter}
                    loading={isSubmitting}
                >Update</Button>
                <Button className="custom-outline-button" onClick={handleCancel}>Cancel</Button>
            </div>
        </div>
    )
}
export default ChangeHrComponent