import "./FacilityFilterDrawerComponent.scss";
import {ImageConfig} from "../../../constants";
import DatePicker from "react-datepicker";
import {DateRangeOutlined} from "@material-ui/icons";
import React, {useCallback} from "react";
import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    option: {
        '&[aria-selected="true"]': {
            backgroundColor: '#41D6C3',
            color: 'white',
        },
    },
}));
interface  FacilityFilterDrawerComponentProps{
    setOpenFilterDrawer?:any;
    filterData:any;
    setFilterData:any;
}

const statusList = [
    {name: "Active", code: true},
    {name: "Inactive", code: false},
];

const FacilityFilterDrawerComponent=(props:FacilityFilterDrawerComponentProps)=>{
    const {setOpenFilterDrawer,
        setFilterData,
        filterData,
    }=props;
    // const [startDate, endDate] = dateRange;
    const classes=useStyles();

    const updatedFilters=useCallback((updatedFilterData:any)=>{
        //add filter count if needed here
        setFilterData(updatedFilterData);
    },[setFilterData])

    const handleDateRangeSelection = useCallback((dateRange: [Date | null, Date | null]) => {
        const [newStartDate, newEndDate] = dateRange || [];

        const startDateFormatted = newStartDate
            ? moment(newStartDate).format("YYYY-MM-DD")
            : null;
        const endDateFormatted = newEndDate
            ? moment(newEndDate || newStartDate).format("YYYY-MM-DD")
            : null;

        const updatedFilterData={...filterData}


        if (startDateFormatted) {
            updatedFilterData.start_date = startDateFormatted;
            // Clear the end_date if the user picks a new start_date
            if (filterData.start_date !== startDateFormatted) {
                delete updatedFilterData.end_date;
            }
        }

        if(endDateFormatted){
            updatedFilterData.end_date=endDateFormatted;
        }


        if (!startDateFormatted && !endDateFormatted) {
            delete updatedFilterData.start_date;
            delete updatedFilterData.end_date;
        }


        updatedFilters(updatedFilterData);
    }, [filterData, updatedFilters]);


    const handleStatusChange = useCallback((value: any) => {
        // setStatus(value);
        const updatedFilterData={...filterData}
        updatedFilterData.is_active=value?.code;
        updatedFilters(updatedFilterData)
    },[filterData,updatedFilters])

    const handleClearAll=useCallback(()=>{
        const updatedFilterData={...filterData};
        delete updatedFilterData.is_active;
        delete updatedFilterData.start_date;
        delete updatedFilterData.end_date;
        setFilterData(updatedFilterData);
        setOpenFilterDrawer(false);
    },[filterData,setFilterData,setOpenFilterDrawer])

    return (
        <div className={'facility-filter-component'}>
            <div className={'facility-filter-header-wrapper'}>
                <div className={'facility-filter-header'}>
                    <div className={'facility-filter-title'}>Filters</div>
                    <div className={'facility-filter-clear-all'} onClick={handleClearAll}>Clear All</div>
                </div>
                <div
                    className={'facility-filter-close-btn'}
                    onClick={()=>setOpenFilterDrawer(false)}
                >
                   <img src={ImageConfig.CrossIcon} alt={'cross-icon'}/>
                </div>
            </div>
            <div className={'facility-filter-fields-wrapper'}>
                <div className={'facility-date-range'}>
                        <DatePicker
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select Date"
                            className="facility-custom-input"
                            selectsRange={true}
                            // startDate={startDate && new Date(startDate)}
                            // endDate={endDate && new Date(endDate)}
                            startDate={filterData.start_date && new Date(`${filterData.start_date}T00:00:00`) }
                            endDate={filterData.end_date && new Date(`${filterData.end_date}T00:00:00`)}
                            onChange={(update) => {
                                handleDateRangeSelection(update);
                                // setDateRange(update);
                            }}
                            isClearable={true}
                        />
                        {!filterData.start_date && !filterData.end_date &&
                        <DateRangeOutlined className="facility-date-icon" fontSize="medium" color="action" />}
                        {/*{!dateRange[0] && !dateRange[1] &&*/}
                        {/*    <DateRangeOutlined className="facility-date-icon" fontSize="medium" color="action"/>}*/}
                </div>
                <div>
                    <Autocomplete
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        // value={status}
                        value={statusList.find(status => status.code === filterData.is_active) || null}
                        options={statusList}
                        getOptionLabel={(option: any) => option.name}
                        placeholder={"Select Status"}
                        id="input_select_status"
                        className="mrg-top-10"
                        classes={{
                            option: classes.option,
                        }}
                        onChange={($event, value) => {
                            handleStatusChange(value);
                        }}
                        renderInput={(params) => <TextField {...params} id="select_status" variant="outlined"
                                                            placeholder={"Status"}/>}
                    />
                </div>
            </div>
        </div>
    )
}

export default FacilityFilterDrawerComponent