import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import LoaderComponent from "../../../../components/LoaderComponent";
import NoDataToShowCardComponent from "../../../../components/NoDataToShowCardComponent";
import {ENV} from "../../../../constants";
import {ApiService, CommonService} from "../../../../helpers";
import HcpContractComponent from "../contract/HcpContractComponent";
import HcpEducationComponent from "../education/HcpEducationComponent";
import HcpExperienceComponent from "../experience/HcpExperienceComponent";
import HcpReferenceComponent from "../reference/HcpReferenceComponent";
import HcpVolunteerExperienceComponent from "../volunteerExperience/HcpVolunteerExperienceComponent";
import "./HcpDetailsComponent.scss";
import DialogComponent from "../../../../components/DialogComponent";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import {Avatar, Button, IconButton, Paper, Tooltip} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {TableColumnsType} from "antd";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    tableBody: {
        height: 'auto',
        overflowY: 'auto',
    },
}));

// const webMatColumns = ["Facility Name", "Date", "Added By", "Role", "Delete"];
// const webMatColumnsForNcns = ["Facility Name", "Date", "Added By", "Role"];
// const webMatColumnsForDoc = ["Document Name", "Expires On", "Download"];

const HcpDetailsComponent = (props: any) => {
    const classes = useStyles();
    const params = useParams<{ id: string }>();
    const {id} = params;
    const {setIsAttachmentsLoading} = props;
    const [hcpBasicDetails, setBasicDetails] = useState<any | null>(null);
    //  const [educationDetails, setEducationDetails] = useState<any | null>(null);
    //  const [volunteerExperience, setVolunteerExperience] = useState<any | null>(null);
    //  const [referenceDetails, setReferenceDetails] = useState<any | null>(null);
    //  const [experience, setExperienceDetails] = useState<any | null>(null);
    const [attachmentsDetails, setAttachmentsDetails] = useState<any | null>(null);
    const [sortedAttachments, setSortedAttachments] = useState<any | null>(null);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);
    const [isDownload, setIsDownloading] = useState<boolean>(false);
    const [HcpDetails, setHcpDetails] = useState<any>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [openAttachment,setOpenAttachment]=useState<boolean>(false);
    const [previewAttachment,setPreviewAttachment]=useState<any | null>(null);
    const [openFacilityAdd, setOpenFacilityAdd] = useState<boolean>(false);
    const [NcnsList, setNcnsList] = useState<any | null>(null);
    const [isNcnsListLoading, setIsNcnsListLoading] = useState<boolean>(false);
    const [dnrList, setDnrList] = useState<any | null>(null);
    const [isDnrListLoading, setIsDnrListLoading] = useState<boolean>(false);
    const [facilityList, setFacilityList] = useState<any>(null);
    const [selectedFacility, setSelectedFacility] = useState<any>(null);
    const [hcpRating, setHcpRating] = useState<any>(null);
    const {user} = useSelector((state: StateParams) => state.auth);


    const hcpRatingCalculation = useCallback(() => {
        if (dnrList?.length > 0) {
            //  {hcpBasicDetails?.rating ? `${Math.round(hcpBasicDetails?.rating * 100) / 100}/5` : "N/A"}
            console.log(typeof (hcpBasicDetails?.rating));
            console.log(hcpBasicDetails?.rating, "jjkhk");
            const dnr = dnrList?.length / 3;
            const ncns = NcnsList?.length / 3;
            setHcpRating(Math.round(hcpBasicDetails?.rating * 100) / 100 - Math.min(Math.floor(dnr), Math.floor(ncns)));
            console.log();
        } else {
            setHcpRating(Math.round(hcpBasicDetails?.rating * 100) / 100);
        }
        console.log(isDnrListLoading);
    }, [dnrList, NcnsList, isDnrListLoading, hcpBasicDetails]);

    useEffect(() => {
        hcpRatingCalculation();
    }, [hcpRatingCalculation]);

    const facilityListInit = useCallback(() => {
        let facilityUrl = "facility/lite";
        let facilityPayload: any = {};
        CommonService._api.post(ENV.API_URL + facilityUrl, facilityPayload).then((resp) => {
            setFacilityList(resp?.data);
        }).catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const previewFile = useCallback((index: any) => {
        setOpen(true);
    }, []);

    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const previewAttachmentFile = useCallback((data: any) => {
        setOpenAttachment(true);
        setPreviewAttachment(data)
    }, []);

    const cancelAttachmentPreviewFile = useCallback(() => {
        setOpenAttachment(false);
    }, []);

    const confirmAttachmentPreviewFile = useCallback(() => {
        setOpenAttachment(false);
    }, []);

    const cancelFacilityAdd = useCallback(() => {
        setOpenFacilityAdd(false);
        setSelectedFacility(null);
    }, []);

    const openFacilityAddDialog = useCallback(() => {
        setOpenFacilityAdd(true);
    }, []);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getAttachmentsDetails = useCallback(
        (hcp_id) => {
            setAttachmentLoading(true);
            CommonService._api
                .get(ENV.API_URL + "hcp/" + hcp_id + "/attachments")
                .then((resp) => {
                    setAttachmentsDetails(resp?.data);
                    setIsAttachmentsLoading(false);
                    setAttachmentLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsAttachmentsLoading(false);
                    setAttachmentLoading(false);
                });
        },
        [setIsAttachmentsLoading, setAttachmentLoading]
    );

    const getNcnsList = useCallback(
        (hcp_id) => {
            setIsNcnsListLoading(true);
            CommonService._api
                .get(ENV.API_URL + "hcp/" + hcp_id + "/ncns")
                .then((resp) => {
                    setNcnsList(resp?.data);
                    setIsNcnsListLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsNcnsListLoading(false);
                });
        },
        [setIsNcnsListLoading]
    );


    const getDnrList = useCallback(
        (hcp_id) => {
            setIsDnrListLoading(true);
            CommonService._api
                .get(ENV.API_URL + "hcp/" + hcp_id + "/dnr")
                .then((resp) => {
                    setDnrList(resp?.data);
                    setIsDnrListLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsDnrListLoading(false);
                });
        },
        [setIsDnrListLoading]
    );


    const getHcpImageDetails = useCallback((hcpId: any) => {
        CommonService._api.get(ENV.API_URL + "hcp/" + hcpId).then((resp) => {
            setHcpDetails(resp.data);
            console.log(resp);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const init = useCallback(() => {
        // config
        CommonService._api
            .get(ENV.API_URL + "hcp/" + id)
            .then((resp) => {
                setBasicDetails(resp.data);
                console.log(resp.data?._id);
                console.log(id);
                getAttachmentsDetails(resp.data?._id);
                getNcnsList(resp.data?.user_id);
                getHcpImageDetails(resp.data?._id);
                getDnrList(resp.data?._id);
                if (user?.role !== "hr" && user?.role !== "operational_manager") {
                    facilityListInit();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [id, getAttachmentsDetails, getHcpImageDetails, getNcnsList, getDnrList, facilityListInit, user]);

    const confirmMarkingDnr = useCallback(() => {
        let payload: any = {};
        payload.facility_id = selectedFacility?._id;
        CommonService._api.post(ENV.API_URL + `hcp/${hcpBasicDetails?._id}/dnr`, payload).then((resp) => {
            CommonService.showToast("success", "success");
            getDnrList(hcpBasicDetails?._id);
            setOpenFacilityAdd(false);
            setSelectedFacility(null);
        }).catch((err) => {
            console.log(err);
            CommonService.showToast(err?.error || "Already Added", "error");
            setOpenFacilityAdd(false);
            setSelectedFacility(null);
        });
    }, [selectedFacility, hcpBasicDetails, getDnrList]);


    useEffect(() => {
        init();
    }, [init]);

    const handleDownload = useCallback(
        (file_key: any) => {
            setIsDownloading(true);
            console.log(file_key);
            let payload = {
                file_key: file_key,
            };
            CommonService._api
                .post(ENV.API_URL + "downloadAttachment", payload)
                .then((resp) => {
                    const link = document.createElement("a");
                    link?.setAttribute("href", resp?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsDownloading(false);
                });
        },
        []
    );

    useEffect(() => {
        const required_attachments = [
            {name: "Resume", index: -1},
            {name: "Physical Test", index: -1},
            {name: "TB Test", index: -1},
            {name: "Chest X-ray", index: -1},
            {name: "CPR/BLS Card", index: -1},
            {name: "Driver License", index: -1},
            {name: "SSN Card", index: -1},
            {name:"Nursing License",index:-1},
            {name: "License", index: -1},
            {name: "Covid Certificate", index: -1},
            {name: "Covid Vaccine Card", index: -1},
            {name: "Covid Test Result", index: -1},
            {name: "Live Scan", index: -1},
            {name: "Vaccine Exemption Letter", index: -1},
        ];
        let tempAttachemnts: any = [];
        required_attachments?.forEach((item: any) => {
            attachmentsDetails?.forEach((attachment: any, index: any) => {
                if (item.name === attachment?.attachment_type) {
                    attachmentsDetails.splice(index, 1);
                    tempAttachemnts.push(attachment);
                }
            });
        });

        attachmentsDetails?.forEach((attachment: any) => {
            tempAttachemnts.push(attachment);
        });
        setSortedAttachments([...tempAttachemnts]);
    }, [attachmentsDetails]);

    const deleteDnr = useCallback((dnrListId: any) => {
        ApiService.delete(ENV.API_URL + `hcp/${hcpBasicDetails?._id}/dnr/${dnrListId}`)
            .then((resp: any) => {
                if (resp) {
                    CommonService.showToast(resp?.msg || "Success", "success");
                    getDnrList(hcpBasicDetails?._id);
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
                //  CommonService.handleErrors(setErrors, err?.errors);
                CommonService.showToast(err.msg || "Error", "error");
            });
    }, [hcpBasicDetails, getDnrList]);

    const StyledLoader = () => {
        return (
            <div className="pdd-20" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <LoaderComponent position="block"/>
            </div>
        );
    };

    const dnrListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'Facility Name',
                width:200,
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.facility_details?.name?.length > 20  ?
                                <Tooltip title={item?.facility_details?.name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility_details?.name ||'N/A'}
                                    </div>
                                </Tooltip>:
                                <div>
                                    {item?.facility_details?.name ||'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Date',
                width:120,
                render:(item:any)=>{
                    return (
                        <div>
                            {moment(item?.created_at).format("MM-DD-YYYY") || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Added By',
                width:200,
                render:(item:any)=>{
                    return (
                        <div>
                            {(item?.user_details?.first_name + item?.user_details?.last_name)?.length > 20 ?
                                <Tooltip title={item?.user_details?.first_name + " " + item?.user_details?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {(item?.user_details?.first_name + " " + item?.user_details?.last_name) ||'N/A'}
                                    </div>
                                </Tooltip>:
                                <div>
                                    {(item?.user_details?.first_name + " " + item?.user_details?.last_name) ||'N/A'}
                                </div>

                            }
                        </div>
                    )
                }
            },
            {
                title:'Role',
                width:100,
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.role  || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Delete',
                width:120,
                render:(item:any)=>{
                    return (
                        <div className={''}>
                            <IconButton
                                disabled={user?.role === "hr" || user?.role === "operational_manager"}
                                onClick={() => {
                                    deleteDnr(item?._id);
                                }}>
                                <CancelIcon className="delete-icon"/>
                            </IconButton>
                        </div>
                    )
                }
            }
        ]
    },[user,deleteDnr])

    const ncnsListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'Facility Name',
                width:200,
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.facility_details?.name?.length > 20  ?
                                <Tooltip title={item?.facility_details?.name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility_details?.name ||'N/A'}
                                    </div>
                                </Tooltip>:
                                <div>
                                    {item?.facility_details?.name ||'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Date',
                width:120,
                render:(item:any)=>{
                    return (
                        <div>
                            {moment(item?.created_at).format("MM-DD-YYYY") || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Added By',
                width:200,
                render:(item:any)=>{
                    return (
                        <div>
                            {(item?.user_details?.first_name + item?.user_details?.last_name)?.length > 20 ?
                                <Tooltip title={item?.user_details?.first_name + " " + item?.user_details?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {(item?.user_details?.first_name + " " + item?.user_details?.last_name) ||'N/A'}
                                    </div>
                                </Tooltip>:
                                <div>
                                    {(item?.user_details?.first_name + " " + item?.user_details?.last_name) ||'N/A'}
                                </div>

                            }
                        </div>
                    )
                }
            },
            {
                title:'Role',
                width:100,
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.role  || 'N/A'}
                        </div>
                    )
                }
            },
        ]
    },[])

    const attachmentListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'Document Name',
                width:300,
                render:(item:any)=>{
                    return (
                        <div style={{cursor: 'pointer', textDecoration: "underline"}} onClick={()=>previewAttachmentFile(item)}>
                            {item?.attachment_type ? item?.attachment_type === 'License' ? "Nursing License" : item?.attachment_type : 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Expires On',
                width:100,
                render:(item:any)=>{
                    return(
                        <div>
                            {item?.expiry_date || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Download',
                width:80,
                render:(item:any)=>{
                    return (
                        <div className={''}>
                            <IconButton onClick={(e) => handleDownload(item?.file_key)}>
                                <GetAppIcon  aria-disabled={isDownload} className="download-icon"/>
                            </IconButton>
                        </div>
                    )
                }
            }
        ]
    },[handleDownload,isDownload,previewAttachmentFile])

    const addressParts = [
        hcpBasicDetails?.address?.region,
        hcpBasicDetails?.address?.city,
        hcpBasicDetails?.address?.state,
        hcpBasicDetails?.address?.country,
        hcpBasicDetails?.address?.zip_code
    ].filter(Boolean).join(', ') || 'N/A';
    return (
        <>
            <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile}
                                   previewData={HcpDetails?.profile_url}/>
            </DialogComponent>
            <DialogComponent open={openAttachment} cancel={cancelAttachmentPreviewFile} class='preview-content'>
                <CustomPreviewFile cancel={cancelAttachmentPreviewFile} confirm={confirmAttachmentPreviewFile}
                                   previewData={previewAttachment}/>
            </DialogComponent>
            <DialogComponent cancel={cancelFacilityAdd} open={openFacilityAdd} headerLabel={'Mark As DNR'}>
                <div className={'pdd-20'}>
                    {/*<div className={'header-title'}*/}
                    {/*     style={{display: "flex", alignItems: "center", justifyContent: "center",}}>*/}
                    {/*    Mark As DNR*/}
                    {/*</div>*/}
                    <div className={'mrg-bottom-10'}>
                        Select Facility for Marking DNR
                    </div>
                    <div>
                        <div className="form-field-item">
                            {facilityList && <>
                                <Autocomplete
                                    PaperComponent={({children}) => <Paper
                                        style={{color: "#1e1e1e"}}>{children}</Paper>}
                                    value={selectedFacility}
                                    options={facilityList}
                                    getOptionLabel={(option: any) => (option?.name)}
                                    placeholder={"Select Status"}
                                    id="input_select_status"
                                    style={{backgroundColor: "white", borderRadius: "3px"}}
                                    onChange={($event, value) => {
                                        if (value) {
                                            setSelectedFacility(value);
                                            console.log(value);
                                        } else {
                                            setSelectedFacility(null);
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} size={"small"}
                                                                        id="select_status" variant="outlined"
                                                                        value={[]} placeholder={"Facility"}
                                                                        fullWidth/>}
                                />
                            </>}
                        </div>
                    </div>
                    <DialogActions className={'pdd-20'}>
                        <Button onClick={cancelFacilityAdd} variant={"outlined"} color={'primary'}>
                            {'No, Cancel'}
                        </Button>
                        <Button
                            // className={isHcpNoShowApiInProgress ? "has-loading-spinner" : ""}
                            onClick={confirmMarkingDnr}
                            variant={"contained"} color={'primary'}>
                            {'Yes, Confirm'}
                        </Button>
                    </DialogActions>
                </div>
            </DialogComponent>
            <div className="hcp_details">
                <div className="pdd-0 custom-border">
                    <div className={'d-flex pdd-20 hcp-photo-details-wrapper'} style={{display: "flex"}}>
                        <div>
                            <div style={{display: "flex"}}>
                                <Avatar style={{height: "80px", width: "80px"}}>
                                    <img style={{width: "80px", height: "80px"}} src={HcpDetails?.image_url}
                                         alt=""/>
                                </Avatar>
                                <div className="hcp-name-type">
                                    <h2 style={{color: "black"}}>
                                        {hcpBasicDetails?.first_name}&nbsp;{hcpBasicDetails?.last_name}
                                    </h2>
                                    <p className={'mrg-top-10'}>{hcpBasicDetails?.hcp_type}</p>
                                </div>
                            </div>
                        </div>
                        {/*<div style={{marginLeft: "auto"}} onClick={()=>{*/}
                        {/*    console.log(typeof (hcpBasicDetails?.rating));*/}
                        {/*    console.log(hcpBasicDetails?.rating,"jjkhk");*/}
                        {/*}}>*/}
                        {/*    <h5 style={{color: "gray"}}>*/}
                        {/*        Average Rating*/}
                        {/*    </h5>*/}
                        {/*    <div style={{color:"black"}}>*/}
                        {/*        {hcpRating}/5*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="rating-container">
                            <p className="rating-title">Average Rating</p>
                            <p className="rating-content"> {hcpRating || 0}/5</p>
                        </div>
                    </div>
                </div>
                {hcpBasicDetails &&
                    <div className="d-flex hcp-details pdd-bottom-20 custom-border mrg-top-20" style={{gap: "20px"}}>
                    <div className="flex-1">
                        <h4>Years Of Experience</h4>
                        <p>
                            {hcpBasicDetails?.professional_details?.experience
                                ? hcpBasicDetails?.professional_details?.experience + " Years"
                                : hcpBasicDetails?.professional_details?.experience === 0
                                    ? 0 + " Years"
                                    : "N/A"}
                        </p>
                    </div>
                    <div className="flex-1">
                        <h4>Contact Number</h4>
                        <p>{CommonService.formatPhoneNumber(hcpBasicDetails?.contact_number?.replace(/\+1/, '')) || 'N/A'}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Address</h4>
                        <p>
                            {addressParts}
                        </p>
                    </div>
                    <div className="flex-1 text-lowercase">
                        <h4>Email</h4>
                        <p>{hcpBasicDetails?.email || 'N/A'}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Staff Rate (hr)</h4>
                        <p>{hcpBasicDetails?.contract_details?.rate_per_hour ?
                            `$ ${hcpBasicDetails?.contract_details?.rate_per_hour}` : "N/A"}</p>
                    </div>
                </div>
                }
                <>
                    <div className={"pdd-20 custom-border mrg-top-10 mrg-bottom-10"}>
                        <Accordion expanded={expanded === "DNR"}
                                   onChange={handleChange("DNR")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                              id="panel1bh-header">
                                <Typography className={classes.heading}> DNR (Count:{dnrList?.length})</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.root}>
                                    <div style={{display: "flex", marginBottom: "20px"}}>
                                        <div style={{marginLeft: "auto"}}>
                                            {user?.role !== "hr" && user?.role !== "operational_manager" &&
                                                <Button onClick={openFacilityAddDialog} size="large"
                                                        disabled={!facilityList}
                                                        variant={"contained"}
                                                        color={"primary"}>
                                                    + Add More
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                    {dnrList?.length !== 0 &&
                                        <div className={'dnr-table-wrapper'}>
                                        <AntTableComponent
                                            data={dnrList}
                                            extraPayload={dnrList}
                                            columns={dnrListColumn}
                                        />
                                        </div>
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </>
                {!isNcnsListLoading && NcnsList?.length > 0 && <>
                    <div className={"pdd-20 custom-border mrg-top-10 mrg-bottom-10"}>
                        <Accordion expanded={expanded === "NCNS"}
                                   onChange={handleChange("NCNS")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                              id="panel1bh-header">
                                <Typography className={classes.heading}> NCNS (Count:{NcnsList?.length})</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.root}>
                                    <div className={'dnr-table-wrapper'}>
                                            <AntTableComponent
                                                data={NcnsList}
                                                extraPayload={NcnsList}
                                                columns={ncnsListColumn}
                                            />
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </>}

                <div className="custom-border pdd-20">
                    <Accordion expanded={expanded === "Basic_Details"} onChange={handleChange("Basic_Details")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Basic Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>First Name</h4>
                                        <p>{hcpBasicDetails?.first_name || 'N/A'} </p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Last Name</h4>
                                        <p>{hcpBasicDetails?.last_name || 'N/A'} </p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Contact Number</h4>
                                        <p>{CommonService.formatPhoneNumber(hcpBasicDetails?.contact_number?.replace(/\+1/, '')) || 'N/A'}</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Email</h4>
                                        <p>{hcpBasicDetails?.email || 'N/A'}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Gender</h4>
                                        <p>{hcpBasicDetails?.gender || 'N/A'}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Role</h4>
                                        <p>{hcpBasicDetails?.hcp_type || 'N/A'}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Region Name</h4>
                                        <p>{hcpBasicDetails?.address?.region || 'N/A'}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Street</h4>
                                        <p>{hcpBasicDetails?.address?.street || 'N/A'}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>City</h4>
                                        <p>{hcpBasicDetails?.address?.city || 'N/A'}</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>State</h4>
                                        <p>{hcpBasicDetails?.address?.state || 'N/A'}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Country</h4>
                                        <p>{hcpBasicDetails?.address?.country || 'N/A'}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Zip Code</h4>
                                        <p>{hcpBasicDetails?.address?.zip_code || 'N/A'}</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Latitude</h4>
                                        <p>{hcpBasicDetails?.location?.coordinates?.[1] || 'N/A'}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Longitude</h4>
                                        <p>{hcpBasicDetails?.location?.coordinates?.[0] || 'N/A'}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>DOB</h4>
                                        <p>{hcpBasicDetails?.dob?.split("-").reverse().join("-") || 'N/A'}</p>
                                    </div>
                                </div>
                                <div className={"d-flex mrg-top-10"}>
                                    <div className={"flex-1"}>
                                        <h4>Staff ID</h4>
                                        <p>{hcpBasicDetails?.hcp_uniqueId || "N/A"}</p>
                                    </div>
                                    <div className={"flex-1"}>
                                        <h4>Recruiter</h4>
                                        <p>
                                            {hcpBasicDetails?.recruiter_details ?
                                                <>{hcpBasicDetails?.recruiter_details?.first_name} {hcpBasicDetails?.recruiter_details?.last_name}</> : 'N/A'
                                            }
                                        </p>
                                    </div>

                                </div>
                                <div className={"d-flex mrg-top-10"}>
                                    <div className={"flex-1"}>
                                        <h4>About the Staff</h4>
                                        <p className="summary">{hcpBasicDetails?.about ? hcpBasicDetails?.about : "N/A"}</p>
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className="custom-border mrg-top-10 pdd-20">
                    <Accordion expanded={expanded === "Professional_Details"}
                               onChange={handleChange("Professional_Details")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Professional Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Years of Experience</h4>
                                        <p>{hcpBasicDetails?.professional_details?.hasOwnProperty('experience') ? (hcpBasicDetails?.professional_details?.experience ? hcpBasicDetails?.professional_details?.experience : 'N/A') : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Speciality</h4>
                                        <p>{hcpBasicDetails?.professional_details?.speciality ? hcpBasicDetails?.professional_details?.speciality : "N/A"}</p>
                                    </div>
                                </div>
                                <div>
                                    <h4>Professional Summary</h4>
                                    <p className="summary">{hcpBasicDetails?.professional_details?.summary ? hcpBasicDetails?.professional_details?.summary : "N/A"}</p>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                {!attachmentLoading && sortedAttachments ? (
                    // <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                    //     <h3>Attachments/Documents</h3>
                    //     {sortedAttachments?.length === 0 && (
                    //         <p>
                    //             <NoDataToShowCardComponent/>
                    //         </p>
                    //     )}
                    //     <div className="attachments_wrapper">
                    //         {sortedAttachments?.map((item: any) => {
                    //             return (
                    //                 <div className="attachments">
                    //                     <CustomFile data={item} handleDownload={handleDownload}
                    //                                 isDownload={isDownload}/>
                    //                 </div>
                    //             );
                    //         })}
                    //     </div>
                    // </div>
                    <div className="custom-border mrg-top-20 pdd-20">
                        <Accordion expanded={expanded === "Attachments"} onChange={handleChange("Attachments")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                              id="panel1bh-header">
                                <Typography className={classes.heading}>Attachments/Documents</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.root}>
                                    {sortedAttachments?.length === 0 && (
                                        <p>
                                            <NoDataToShowCardComponent/>
                                        </p>
                                    )}
                                    {sortedAttachments?.length !== 0 && (
                                        <AntTableComponent
                                            data={sortedAttachments}
                                            extraPayload={sortedAttachments}
                                            columns={attachmentListColumn}
                                        />
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ) : (
                    <div className="custom-border mrg-top-10 pdd-20">
                        <StyledLoader/>
                    </div>
                )}

                <div className="custom-border mrg-top-10 pdd-20">
                    <Accordion expanded={expanded === "Contract"} onChange={handleChange("Contract")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Contract</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <HcpContractComponent id={hcpBasicDetails?._id} hcpDetails={hcpBasicDetails}/>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className="custom-border mrg-top-10 pdd-20">
                    <Accordion expanded={expanded === "Profile"} onChange={handleChange("Profile")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Profile Image</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <div>
                                    {HcpDetails?.profile_url ? (
                                        <div>
                                            <img src={HcpDetails?.profile_url} alt=""
                                                 style={{
                                                     height: "100px",
                                                     width: "100px",
                                                     cursor: "pointer",
                                                 }}
                                                 onClick={previewFile}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <NoDataToShowCardComponent/>
                                        </>
                                    )}
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className="custom-border mrg-top-10 pdd-20">
                    <Accordion expanded={expanded === "Travel_Preference"} onChange={handleChange("Travel_Preference")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Travel Preference</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <div className="d-flex mrg-top-20">
                                    {hcpBasicDetails?.nc_details?.travel_preferences && Array.isArray(hcpBasicDetails?.nc_details?.travel_preferences) && hcpBasicDetails?.nc_details?.travel_preferences?.length > 0 ? (
                                        hcpBasicDetails?.nc_details?.travel_preferences?.map((item: any) => <p
                                            className="flex-1">{item} Miles</p>)
                                    ) : (
                                        <p>N/A</p>
                                    )}
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className="custom-border mrg-top-10 pdd-20">
                    <Accordion expanded={expanded === "Notification"} onChange={handleChange("Notification")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Notification Preferences</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <div className="d-flex mrg-top-20">
                                    {hcpBasicDetails?.hasOwnProperty("notification_preferences") ? (
                                        <>
                                            {hcpBasicDetails?.notification_preferences?.sms &&
                                                <p className="flex-1">{hcpBasicDetails?.notification_preferences?.sms ? "SMS" : null}</p>}
                                            {hcpBasicDetails?.notification_preferences?.email &&
                                                <p className="flex-1">{hcpBasicDetails?.notification_preferences?.email ? "Email" : null}</p>}
                                            {hcpBasicDetails?.notification_preferences?.push_notification &&
                                                <p className="flex-1">{hcpBasicDetails?.notification_preferences?.push_notification ? "In-application" : null}</p>}
                                        </>
                                    ) : (
                                        <p className="flex-1">N/A</p>
                                    )}
                                    {
                                        hcpBasicDetails?.hasOwnProperty('notification_preference') && JSON.stringify(Object.values(hcpBasicDetails?.notification_preference))
                                    }
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className="custom-border mrg-top-10 pdd-20">
                    <Accordion expanded={expanded === "NC_Section"} onChange={handleChange("NC_Section")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>NC Section</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>DNR</h4>
                                        <p>{hcpBasicDetails?.nc_details?.dnr ? hcpBasicDetails?.nc_details?.dnr : "N/A"}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h4>Contact Type</h4>
                                        <p>{hcpBasicDetails?.nc_details?.contact_type ? CommonService.nameCoverterToNormal(hcpBasicDetails?.nc_details?.contact_type) : "N/A"}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h4>Preferred Location to Work</h4>
                                        <p>{hcpBasicDetails?.nc_details?.location_preference ? hcpBasicDetails?.nc_details?.location_preference : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Preference Shift Type</h4>
                                        <p>{hcpBasicDetails?.nc_details?.shift_type_preference ? hcpBasicDetails?.nc_details?.shift_type_preference : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Zone Assignment</h4>
                                        <p>{hcpBasicDetails?.nc_details?.zone_assignment !== "" ? hcpBasicDetails?.nc_details?.zone_assignment : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Last Call Date</h4>
                                        <p>{hcpBasicDetails?.nc_details?.last_call_date ? moment(hcpBasicDetails?.nc_details?.last_call_date).format("MMMM Do YYYY") : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Vaccine</h4>
                                        <p>{!hcpBasicDetails?.nc_details?.vaccine ? "N/A" : hcpBasicDetails?.nc_details?.vaccine === "half" ? "1st Dose" : hcpBasicDetails?.nc_details?.vaccine}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h4>First Shot Date</h4>
                                        <p>{hcpBasicDetails?.nc_details?.vaccination_dates?.first_shot ? hcpBasicDetails?.nc_details?.vaccination_dates?.first_shot : "N/A"}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h4>Latest Shot Date</h4>
                                        <p>{hcpBasicDetails?.nc_details?.vaccination_dates?.latest_shot ? hcpBasicDetails?.nc_details?.vaccination_dates?.latest_shot : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Do you have a Full-time Job ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_fulltime_job !== "" ? (hcpBasicDetails?.nc_details?.is_fulltime_job === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Covid (or) Non Covid Facility ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.covid_facility_preference ? hcpBasicDetails?.nc_details?.covid_facility_preference : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>What Is More Important for You ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.more_important_preference !== "" ? hcpBasicDetails?.nc_details?.more_important_preference : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Is this a Supplement to your Income ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_supplement_to_income !== "" ? (hcpBasicDetails?.nc_details?.is_supplement_to_income === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Are you Studying ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_studying !== "" ? (hcpBasicDetails?.nc_details?.is_studying === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                    {/*<div className="flex-1">*/}
                                    {/*    <h4>Gusto</h4>*/}
                                    {/*    <p>{hcpBasicDetails?.nc_details?.gusto_type !== "" ? CommonService.nameCoverterToNormal(hcpBasicDetails?.nc_details?.gusto_type) : "N/A"}</p>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Is Gusto Invited ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_gusto_invited !== "" ? (hcpBasicDetails?.nc_details?.is_gusto_invited === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Is Gusto Onboarded ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_gusto_onboarded !== "" ? (hcpBasicDetails?.nc_details?.is_gusto_onboarded === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Require Sponsorship for Employment in United States?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_require_employment_sponsorship !== "" ? (hcpBasicDetails?.nc_details?.is_require_employment_sponsorship === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Legally Authorized to work in United States ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_authorized_to_work !== "" ? (hcpBasicDetails?.nc_details?.is_authorized_to_work === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Family Considerations</h4>
                                        <p className="summary">{hcpBasicDetails?.nc_details?.family_consideration ? hcpBasicDetails?.nc_details?.family_consideration : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Other Information Gathered</h4>
                                        <p>{hcpBasicDetails?.nc_details?.other_information ? hcpBasicDetails?.nc_details?.other_information : "N/A"}</p>
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className="custom-border mrg-top-10 pdd-20">
                    <Accordion expanded={expanded === "Education"} onChange={handleChange("Education")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Education</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <HcpEducationComponent educationDetails={hcpBasicDetails?.education}/>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="custom-border mrg-top-10 pdd-20">
                    <Accordion expanded={expanded === "Experience"} onChange={handleChange("Experience")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Work Experience</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <HcpExperienceComponent experience={hcpBasicDetails?.experience}/>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="custom-border mrg-top-10 pdd-20">
                    <Accordion expanded={expanded === "Volunteer_Experience"}
                               onChange={handleChange("Volunteer_Experience")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Volunteer Experience</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <HcpVolunteerExperienceComponent volunteerExperience={hcpBasicDetails?.experience}/>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="custom-border mrg-top-10 pdd-20">
                    <Accordion expanded={expanded === "Reference"} onChange={handleChange("Reference")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Reference</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <HcpReferenceComponent referenceDetails={hcpBasicDetails?.reference}/>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </>
    );
};

export default HcpDetailsComponent;
