import "./TagRecruiterComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import {Button} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import SelectDropdownComponent from "../../../../ant-component/select/SelectComponent";

interface TagRecruiterComponentProps{
    staff_type:string[];
    id:string[];
    cancel:()=>void;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(3),
        },
        paper: {
            padding:"0px 26px 26px 26px",
            margin: "auto",
            marginTop:'10px',
            width:'750px',
        },
        assignNcActions: {
            margin: "auto",
            marginTop: "20px",
            textAlign: "center",
            justifyContent: "center",
        },
        title: {
            fontWeight: 450,
        },
    })
);


const TagRecruiterComponent=(props:TagRecruiterComponentProps)=>{
    const {id}=props
    const afterCancel = props?.cancel;
    const classes = useStyles();
    const [isRecruiterListLoading,setIsRecruiterListLoading]=useState<boolean>(false);
    const [recruiterList,setRecruiterList]=useState<any>([])
    const [recruiterId,setRecruiterId]=useState<any>('');

    const getNcList=useCallback(()=>{
        setIsRecruiterListLoading(true);
        CommonService._api.get(ENV.API_URL + `user/lite?is_active=true&roles[]=recruiter`).then((response:any)=>{
            const transformedData = response?.data?.map((item:any) => ({
                code: item?._id,
                title: item?.first_name + " " + item?.last_name,
            }));
            setRecruiterList(transformedData || []);
        }).catch((error:any)=>{
            console.log(error)
        }).finally(()=>{
            setIsRecruiterListLoading(false);
        })
    },[])

    useEffect(() => {
        getNcList()
    }, [getNcList]);


    const cancel = (resetForm: any) => {
        if (afterCancel) {
            afterCancel();
        }
    };



    const handleTagRecruiter=useCallback(()=>{
        const payload={
            hcp_ids:id,
            user_id:recruiterId,
        }
        CommonService._api.put(ENV.API_URL + 'ats/user/linkHcps',payload).then((response:any)=>{
            CommonService.showToast(response.message,"success")
            afterCancel();
        }).catch((error:any)=>{
            CommonService.showToast(error.error || 'Error',"error");
        })
    },[afterCancel,id,recruiterId])


    return (
        <div>
            <div className={'pdd-left-20 pdd-right-20 pdd-bottom-20 mrg-top-20'}>
                {/*<h2 className={classes.title}>Add Staff to this Shift</h2>*/}
                <div className={'mrg-top-20'}>
                    <SelectDropdownComponent
                        placeholder={'Select'}
                        className={'mrg-top-10'}
                        value={recruiterId}
                        disabled={isRecruiterListLoading}
                        options={recruiterList || []}
                        required={true}
                        size={"middle"}
                        allowClear={true}
                        displayWith={(option) => option.title}
                        valueExtractor={(option) => option.code}
                        onChange={(value: string) => {
                            setRecruiterId(value)
                        }}
                    />
                </div>
                <div id="alert-dialog-title">
                    <div className={classes.assignNcActions}>
                        <Button type={"submit"} size="large" variant={"outlined"}
                                className={"normal"} onClick={cancel}>
                            Cancel
                        </Button>
                        <Button type={"submit"} color={"primary"} size="large"
                                disabled={!recruiterId}
                                className={'mrg-left-20'} variant={"contained"}
                                onClick={handleTagRecruiter}>
                            Tag Recruiter
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TagRecruiterComponent