import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import StarIcon from "@material-ui/icons/Star";

export const designationNames = [
    {value: "DON", label: "Director Of Nursing (DON)"},
    {value: "DSD", label: "Director Of Staffing development (DSD)"},
    {value: "FA", label: "Facility Administrator (FA)"},
    {value: "AP", label: "Accounts Payable (AP)"},
    {value: "SC", label: "Staffing Coordinator (SC)"},
];

export const salaryCredit = [
    {value: "weekly", label: "Weekly"},
    {value: "bi_weekly", label: "Bi-Weekly"},
];

export const hcpPaymentModeOptions = [
    {value: "direct_deposit", label: "GUSTO - Direct Deposit"},
    {value: "checkrpay", label: "Checkr Pay"},
    {value: "paycheck", label: "MAIL - Paycheck"},
    {value: "none", label: "None"}
];

export const otHours = [
    {value: 40, label: "40 Hrs/Week"},
    {value: 8, label: "8 Hrs/Day"},
];

export const shiftType = [
    {
        value: "AM",
        label: "AM",
    },
    {
        value: "PM",
        label: "PM",
    },
    {
        value: "NOC",
        label: "NOC",
    },

    {
        value: "AM-12",
        label: "AM-12",
    },

    {
        value: "PM-12",
        label: "PM-12",
    },
    {
        value: 'AM/PM-Double',
        label: 'AM/PM-Double'
    },
    {
        value: 'PM/NOC-Double',
        label: 'PM/NOC-Double'
    },
    {
        value: 'NOC/AM-Double',
        label: 'NOC/AM-Double'
    },
    {
        value: 'AM-13',
        label: 'AM-13'
    },
    {
        value: 'PM-13',
        label: 'PM-13'
    }
];

export const home_shift_type: any = [
    {
        code: 'Evaluation (RN/OT/PT/ST)',
        title: 'Evaluation (RN/OT/PT/ST)'
    },
    {
        code: 'Start of Care (RN/OT/PT/ST)',
        title: 'Start of Care (RN/OT/PT/ST)'
    },
    {
        code: "Routine visit (RN)",
        title: "Routine visit (RN)"
    },
    {
        code: "Routine visit (LVN)",
        title: "Routine visit (LVN)"
    },
    {
        code: "Routine visit (OT/PT/ST)",
        title: "Routine visit (OT/PT/ST)"
    },
    {
        code: "Routine visit (OTA/PTA/STA)",
        title: "Routine visit (OTA/PTA/STA)"
    },
    {
        code: "Discharge",
        title: "Discharge"
    },
    {
        code: "Recertification",
        title: "Recertification"
    },
    {
        code: "Resumption of Care",
        title: "Resumption of Care"
    },
    {
        code: "Routine visit (CNA)",
        title: "Routine visit (CNA)"
    },
    {
        code: "Routine visit (CareGiver/CHHA)",
        title: "Routine visit (CareGiver/CHHA)"
    }

];

export const patientsGender = [
    {title: "Male", code: "male"},
    {title: "Female", code: "female"},
    {title: "Neutral", code: "neutral"},
];


export const genderTypes = [
    {label: "Male", value: "male"},
    {label: "Female", value: "female"},
    {label: "Other", value: "other"},
];

export const warningZone = [
    {value: "green", label: "Green"},
    {value: "yellow", label: "Yellow"},
    {value: "red", label: "Red"},
];

export const cardMenuActions = [
    {
        icon: <EditIcon/>,
        label: "Edit",
    },
    {
        icon: <DeleteIcon/>,
        label: "Delete",
    },
    {
        icon: <StarIcon/>,
        label: "Star",
    },
];

export const calenderMode = [
    {
        value: "multiple",
        label: "Multiple Dates",
    },
    {
        value: "range",
        label: "Date Range",
    },
];

export const DateRangeContract = [
    {
        value: "range",
        label: "Date Range",
    },
];

export const acknowledgement = [
    {value: "1", label: "Yes"},
    {value: "0", label: "No"},
];

export const boolAcknowledge = [
    {value: "true", label: "Yes"},
    {value: "false", label: "No"},
    {value: "", label: "None"},
];


export const genericAcknowledgement = [
    {value: "true", label: "Yes"},
    {value: "false", label: "No"},
];

export const timesheet = [
    {value: "", label: "All"},
    {value: true, label: "Yes"},
    {value: false, label: "No"},
];

export const requestedByMenu = [
    {value: "", label: "All"},
    {value: "facility", label: "Facility"},
    {value: "hcp", label: "HCP"},
]
export const requirementTypeList = [
    {value: "facility", label: "Per Diem"},
    {value: "home", label: "Per Job / Visit"},
    {value: "contract", label: "Contract / Travel"},
]
export const facilityConfirmation = [
    {value: "", label: "All"},
    {value: true, label: "Yes"},
    {value: false, label: "No"},
];

export const pendingConcern = [
    {value: "", label: "All"},
    {value: true, label: "Yes"},
    {value: false, label: "No"},
];

export const NurseCancelled = [
    {value: "1", label: "1 hours Notice"},
    {value: "4", label: "4 hours Notice"},
    {value: "8", label: "8 hours Notice"},
    {value: "24", label: "24 hours Notice"},
    {value: "48", label: "48 hours Notice"},
    {value: "72", label: "72 hours"},
    {value: "resignation", label: "Resignation"}
];
export const NoShow = [
    {value: "1-day", label: "1 day"},
    {value: "smultiple(2-30)", label: "Multiple - no. of days (2-30)"},
];
export const FacilityCancelled = [
    {value: "72", label: "Within 72 hours"},
    {value: "no-of-days(2-30)", label: "no. of days (2-30)"},
    {value: "low-census", label: "Low Census"},
    {value: "ncns-1-day", label: "NCNS 1 day"},
    {value: "ncns-multiple(2-30)", label: "NCNS Multiple - no. of days (2-30)"},
    {value: "hcp-issue", label: "Staff Issue"},
];
export const VitawerksCancelled = [
    {value: "snf", label: "SNF Issues"},
    {value: "hcp-issue-dnr", label: "Staff Issues(DNR)"},
    {value: "hcp-issues-all-snf", label: "Staff Issues(DNS All SNF)"},
];

export const othersCancelled = [
    {value: "duplicate-entry", label: "Duplicate Entries"},
    {value: "incorrect-information", label: "Incorrect Information"},
    {value: "testing-training", label: "Testing/Training"},
    {value: "no-timesheet", label: "No Timesheet"},
];
export const HcpPaymentMode = [
    {value: "", label: "All"},
    {value: "paycheck", label: "MAIL - Paycheck"},
    {value: "direct_deposit", label: "GUSTO - Direct Deposit"},
    {value: "checkrpay", label: "Checkr Pay"},
];


export const isHCPPaid = [
    {value: true, label: "Yes"},
    {value: false, label: "No"},
];

export const shiftTypePreference = [
    {
        value: "AM",
        label: "AM",
    },
    {
        value: "PM",
        label: "PM",
    },
    {
        value: "NOC",
        label: "NOC",
    },
    {
        value: "AM,PM",
        label: "AM and PM",
    },
    {
        value: "PM,NOC",
        label: "PM and NOC",
    },
    {
        value: "NOC,AM",
        label: "NOC and AM",
    },
];

export const travelDistancePreference = [
    {value: "<20", label: "<20 miles"},

    {value: "20-40", label: "20-40 miles"},

    {value: "40-60", label: "40-60 miles"},

    {value: ">60", label: ">60 miles"},
];

export const notificationPreference = [
    {value: "sms", label: "SMS"},
    {value: "email", label: "Email"},
    {value: "push_notification", label: "In-Application"},
];

export const moreImportant = [
    {
        value: "money",
        label: "Money",
    },
    {
        value: "location",
        label: "Location",
    },
    {value: "", label: "None"},
];

export const covidPreference = [
    {
        value: "covid",
        label: "Covid",
    },
    {
        value: "non_covid",
        label: "Non Covid",
    },

    {
        value: "both",
        label: "Both",
    },
    {value: "", label: "None"},
];

export const vaccine = [
    {
        value: "full",
        label: "Fully Vaccinated",
    },
    {
        value: "half",
        label: "1st Dose",
    },
    {
        value: "exempted",
        label: "Exempted",
    },
];

export const contactType = [
    {
        value: "email",
        label: "Email",
    },
    {
        value: "text_message",
        label: "Text Message",
    },
    {
        value: "voicemail",
        label: "Voicemail",
    },
    {
        value: "chat",
        label: "Chat",
    },
];

export const gustoType = [
    {
        value: "direct_deposit",
        label: "Direct Deposit",
    },
    {
        value: "paycheck",
        label: "Paycheck",
    },
    {value: "", label: "None"},
];

export const AllShiftStatusList = [
    {name: "In Progress", code: "in_progress"},
    {name: "Cancelled", code: "cancelled"},
    {name: "Complete", code: "complete"},
    {name: "Closed", code: "closed"},
    {name: "Approved", code: "pending"},
];

export const OpenShiftsStatusList = [
    {name: "Open", code: "open"},
    {name: "Cancelled", code: "cancelled"},
    {name: "Unfilled", code: "unfilled"},
    {name: "Partially Open", code: "PartiallyOpen"},
    {code: "filled", name: "Filled"},
];

export const pendingShiftsStatusList = [
    {name: "Pending", code: "pending"},
    // {name: "Awaiting", code: "awaiting"},
    {name: "Cancelled", code: "cancelled"},
    {name: "Rejected", code: "rejected"},
    {name: "Approved", code: "approved"},
    {name:'Expired',code:'expired'}
];
export const awaitingShiftsStatusList = [
    // {name: "Pending", code: "pending"},
    {name: "Awaiting", code: "awaiting"},
    {name: "Cancelled", code: "cancelled"},
    {name: "Rejected", code: "rejected"},
    {name: "Approved", code: "approved"},
    {name:'Expired',code:'expired'}
];


export const approvedListStatus = [
    {name: "Active", code: true},
    {name: "Inactive", code: false},
];
export const onboardedListStatus = [
    {name: "Pending", code: "pending"},
    {name: "Rejected", code: "rejected"},
    {name: "All", code: "all"},
];

export const americanTimeZone = [
    {value: 720, label: "(UTC−12:00) Baker Island and Howland Island "},
    {value: 660, label: "(UTC−11:00) (ST) American Samoa, Jarvis Island, Kingman Reef, Midway Atoll and Palmyra Atoll"},
    {value: 600, label: "(UTC−10:00) (HT) Hawaii, most of the Aleutian Islands, and Johnston Atoll"},
    {value: 540, label: "(UTC−09:00) (AKT) Most of the State of Alaska "},
    {
        value: 480,
        label: "(UTC−08:00) (PT) Pacific Time zone: the Pacific coast states, the Idaho panhandle and most of Nevada and Oregon "
    },
    {
        value: 420,
        label: "(UTC−07:00) (MT) Mountain Time zone: most of Idaho, part of Oregon, and the Mountain states plus western parts of some adjacent states "
    },
    {
        value: 360,
        label: "(UTC-06:00) (CT) Central Time zone: a large area spanning from the Gulf Coast to the Great Lakes "
    },
    {
        value: 300,
        label: "(UTC−05:00) (ET) Eastern Time zone: roughly a triangle covering all the states from the Great Lakes down to Florida and east to the Atlantic coast"
    },
    {value: 240, label: "((UTC−04:00) AST) Puerto Rico, the U.S. Virgin Islands  "},
    {value: -600, label: "(UTC+10:00) (ChT) Guam and the Northern Mariana Islands "},
    {value: -720, label: "(UTC+12:00) Wake Island "},
];

export const roles = [
    {value: "account_manager", label: "Accounting Manager - Facilities"},
    {value: "nurse_champion", label: "Accounting Manager - Staff"},
    {value: "hr", label: "Human Resource"},
    {value: 'operational_manager', label: "Operations Manager"},
    {value: 'finance', label: "Finance"},
    {value: 'super_admin', label: "Admin"},
    {value: 'recruiter',label:'Recruiter'},
    {value: 'hr_manager',label:'HR Manager'}
];

export const timeTypesForFilters = [
    {
        code: "AM",
        title: "AM",
    },
    {
        code: "PM",
        title: "PM",
    },
    {
        code: "NOC",
        title: "NOC",
    },

    {
        code: "AM-12",
        title: "AM-12",
    },

    {
        code: "PM-12",
        title: "PM-12",
    },
    {
        code: 'AM/PM-Double',
        title: 'AM/PM-Double'
    },
    {
        code: 'PM/NOC-Double',
        title: 'PM/NOC-Double'
    },
    {
        code: 'NOC/AM-Double',
        title: 'NOC/AM-Double'
    },
    {
        code: 'AM-13',
        title: 'AM-13'
    },
    {
        code: 'PM-13',
        title: 'PM-13'
    },
    {
        code: 'Evaluation (RN/OT/PT/ST)',
        title: 'Evaluation (RN/OT/PT/ST)'
    },
    {
        code: 'Start of Care (RN/OT/PT/ST)',
        title: 'Start of Care (RN/OT/PT/ST)'
    },
    {
        code: "Routine visit (RN)",
        title: "Routine visit (RN)"
    },
    {
        code: "Routine visit (LVN)",
        title: "Routine visit (LVN)"
    },
    {
        code: "Routine visit (OT/PT/ST)",
        title: "Routine visit (OT/PT/ST)"
    },
    {
        code: "Routine visit (OTA/PTA/STA)",
        title: "Routine visit (OTA/PTA/STA)"
    },
    {
        code: "Routine visit (CNA)",
        title: "Routine visit (CNA)"
    },
    {
        code: "Routine visit (CareGiver/CHHA)",
        title: "Routine visit (CareGiver/CHHA)"
    },
    {
        code: "Discharge",
        title: "Discharge"
    },
    {
        code: "Recertification",
        title: "Recertification"
    },
    {
        code: "Resumption of Care",
        title: "Resumption of Care"
    },
    {
        code: "days",
        title: "days",
    },
    {
        code: "nights",
        title: "nights",
    },
    {
        code: "flexible",
        title: "flexible",
    },

];


export const salaryCreditTypesForFilters = [
    {code: "weekly", name: "Weekly"},
    {code: "bi_weekly", name: "Bi-Weekly"},
    {code: "", name: "Salary Credit Day NA"},
];


export const visitPatientBy = [
    {
        title: "Date",
        code: "date",
    },
    {
        title: "Time",
        code: "time",
    },
]

export const languagesList = [
    {
        code: "English",
        title: "English",
    },
    {
        code: "Spanish",
        title: "Spanish",
    },
    {
        code: "Hindi",
        title: "Hindi",
    },
    {
        code: "Mandarin",
        title: "Mandarin",
    },
    {
        code: "Russian",
        title: "Russian",
    },
    {
        code: "Other",
        title: "Other",
    },

]


export const weekDaysList = [
    {
        code: "1",
        title: "1",
    }, {
        code: "2",
        title: "2",
    },
    {
        code: "3",
        title: "3",
    },
    {
        code: "4",
        title: "4",
    },
    {
        code: "5",
        title: "5",
    },
    {
        code: "6",
        title: "6",
    },
    {
        code: "7",
        title: "7",
    },
]


export const contractMealsType = [
    {
        code: "meals",
        title: "Meals",
    }, {
        code: "housing",
        title: "Housing",
    },
    {
        code: "other",
        title: "Other",
    },
]

export const contractShiftType = [
    {
        code: "contract",
        title: "Contract",
    },
    {
        code: "travel",
        title: "Travel",
    },
    {
        code: "permanent",
        title: "Permanent",
    },
]

export const shiftDurationList = [
    {
        code: "8",
        title: "8 Hours",
    },
    {
        code: "10",
        title: "10 Hours",
    },
    {
        code: "12",
        title: "12 Hours",
    },
]

export const contactTypeList = [
    {
        code: "days",
        title: "Days",
    }, {
        code: "nights",
        title: "Nights",
    }, {
        code: "flexible",
        title: "Flexible",
    }
]

export const languages = [
    {code: "Abkhazian", title: "Abkhazian"},
    {code: "Afar", title: "Afar"},
    {code: "Afrikaans", title: "Afrikaans"},
    {code: "Akan", title: "Akan"},
    {code: "Albanian", title: "Albanian"},
    {code: "Amharic", title: "Amharic"},
    {code: "Arabic", title: "Arabic"},
    {code: "Aragonese", title: "Aragonese"},
    {code: "Armenian", title: "Armenian"},
    {code: "Assamese", title: "Assamese"},
    {code: "Avaric", title: "Avaric"},
    {code: "Avestan", title: "Avestan"},
    {code: "Aymara", title: "Aymara"},
    {code: "Azerbaijani", title: "Azerbaijani"},
    {code: "Bambara", title: "Bambara"},
    {code: "Bashkir", title: "Bashkir"},
    {code: "Basque", title: "Basque"},
    {code: "Belarusian", title: "Belarusian"},
    {code: "Bengali", title: "Bengali"},
    {code: "Bihari", title: "Bihari"},
    {code: "Bislama", title: "Bislama"},
    {code: "Bosnian", title: "Bosnian"},
    {code: "Breton", title: "Breton"},
    {code: "Bulgarian", title: "Bulgarian"},
    {code: "Burmese", title: "Burmese"},
    {code: "Catalan; Valencian", title: "Catalan; Valencian"},
    {code: "Chamorro", title: "Chamorro"},
    {code: "Chechen", title: "Chechen"},
    {code: "Chinese", title: "Chinese"},
    {code: "Church Slavic; Old Slavonic", title: "Church Slavic; Old Slavonic"},
    {code: "Chuvash", title: "Chuvash"},
    {code: "Cornish", title: "Cornish"},
    {code: "Corsican", title: "Corsican"},
    {code: "Cree", title: "Cree"},
    {code: "Croatian", title: "Croatian"},
    {code: "Czech", title: "Czech"},
    {code: "Danish", title: "Danish"},
    {code: "Divehi; Dhivehi; Maldivian", title: "Divehi; Dhivehi; Maldivian"},
    {code: "Dutch", title: "Dutch"},
    {code: "Dzongkha", title: "Dzongkha"},
    {code: "English", title: "English"},
    {code: "Esperanto", title: "Esperanto"},
    {code: "Estonian", title: "Estonian"},
    {code: "Ewe", title: "Ewe"},
    {code: "Faroese", title: "Faroese"},
    {code: "Fijian", title: "Fijian"},
    {code: "Finnish", title: "Finnish"},
    {code: "French", title: "French"},
    {code: "Western Frisian", title: "Western Frisian"},
    {code: "Fulah", title: "Fulah"},
    {code: "Georgian", title: "Georgian"},
    {code: "German", title: "German"},
    {code: "Gaelic; Scottish Gaelic", title: "Gaelic; Scottish Gaelic"},
    {code: "Irish", title: "Irish"},
    {code: "Galician", title: "Galician"},
    {code: "Manx", title: "Manx"},
    {code: "Greek", title: "Greek"},
    {code: "Guarani", title: "Guarani"},
    {code: "Gujarati", title: "Gujarati"},
    {code: "Haitian; Haitian Creole", title: "Haitian; Haitian Creole"},
    {code: "Hausa", title: "Hausa"},
    {code: "Hebrew", title: "Hebrew"},
    {code: "Herero", title: "Herero"},
    {code: "Hindi", title: "Hindi"},
    {code: "Hiri Motu", title: "Hiri Motu"},
    {code: "Hungarian", title: "Hungarian"},
    {code: "Icelandic", title: "Icelandic"},
    {code: "Ido", title: "Ido"},
    {code: "Igbo", title: "Igbo"},
    {code: "Indonesian", title: "Indonesian"},
    {code: "Interlingua", title: "Interlingua"},
    {code: "Interlingue", title: "Interlingue"},
    {code: "Inuktitut", title: "Inuktitut"},
    {code: "Inupiaq", title: "Inupiaq"},
    {code: "Irish", title: "Irish"},
    {code: "Italian", title: "Italian"},
    {code: "Japanese", title: "Japanese"},
    {code: "Javanese", title: "Javanese"},
    {code: "Kalaallisut", title: "Kalaallisut"},
    {code: "Kannada", title: "Kannada"},
    {code: "Kanuri", title: "Kanuri"},
    {code: "Kashmiri", title: "Kashmiri"},
    {code: "Kazakh", title: "Kazakh"},
    {code: "Central Khmer", title: "Central Khmer"},
    {code: "Kikuyu", title: "Kikuyu"},
    {code: "Kinyarwanda", title: "Kinyarwanda"},
    {code: "Kirghiz", title: "Kirghiz"},
    {code: "Komi", title: "Komi"},
    {code: "Kongo", title: "Kongo"},
    {code: "Korean", title: "Korean"},
    {code: "Kuanyama", title: "Kuanyama"},
    {code: "Kurdish", title: "Kurdish"},
    {code: "Lao", title: "Lao"},
    {code: "Latin", title: "Latin"},
    {code: "Latvian", title: "Latvian"},
    {code: "Luxembourgish", title: "Luxembourgish"},
    {code: "Limburgan", title: "Limburgan"},
    {code: "Lingala", title: "Lingala"},
    {code: "Lithuanian", title: "Lithuanian"},
    {code: "Luba-Katanga", title: "Luba-Katanga"},
    {code: "Macedonian", title: "Macedonian"},
    {code: "Malagasy", title: "Malagasy"},
    {code: "Malay", title: "Malay"},
    {code: "Malayalam", title: "Malayalam"},
    {code: "Maltese", title: "Maltese"},
    {code: "Maori", title: "Maori"},
    {code: "Marathi", title: "Marathi"},
    {code: "Marshallese", title: "Marshallese"},
    {code: "Moldavian", title: "Moldavian"},
    {code: "Mongolian", title: "Mongolian"},
    {code: "Nauru", title: "Nauru"},
    {code: "Navajo", title: "Navajo"},
    {code: "North Ndebele", title: "North Ndebele"},
    {code: "Nepali", title: "Nepali"},
    {code: "Ndonga", title: "Ndonga"},
    {code: "Norwegian Nynorsk", title: "Norwegian Nynorsk"},
    {code: "Norwegian Bokmål", title: "Norwegian Bokmål"},
    {code: "Chichewa", title: "Chichewa"},
    {code: "Occitan", title: "Occitan"},
    {code: "Ojibwa", title: "Ojibwa"},
    {code: "Oromo", title: "Oromo"},
    {code: "Oriya", title: "Oriya"},
    {code: "Ossetian", title: "Ossetian"},
    {code: "Punjabi", title: "Punjabi"},
    {code: "Pali", title: "Pali"},
    {code: "Persian", title: "Persian"},
    {code: "Polish", title: "Polish"},
    {code: "Portuguese", title: "Portuguese"},
    {code: "Pushto", title: "Pushto"},
    {code: "Quechua", title: "Quechua"},
    {code: "Romanian", title: "Romanian"},
    {code: "Rundi", title: "Rundi"},
    {code: "Russian", title: "Russian"},
    {code: "Samoan", title: "Samoan"},
    {code: "Sango", title: "Sango"},
    {code: "Sanskrit", title: "Sanskrit"},
    {code: "Sardinian", title: "Sardinian"},
    {code: "Serbian", title: "Serbian"},
    {code: "Shona", title: "Shona"},
    {code: "Sindhi", title: "Sindhi"},
    {code: "Sinhala; Sinhalese", title: "Sinhala; Sinhalese"},
    {code: "Slovak", title: "Slovak"},
    {code: "Slovenian", title: "Slovenian"},
    {code: "Somali", title: "Somali"},
    {code: "Southern Sotho", title: "Southern Sotho"},
    {code: "Spanish; Castilian", title: "Spanish; Castilian"},
    {code: "Sundanese", title: "Sundanese"},
    {code: "Swahili", title: "Swahili"},
    {code: "Swati", title: "Swati"},
    {code: "Swedish", title: "Swedish"},
    {code: "Tamil", title: "Tamil"},
    {code: "Telugu", title: "Telugu"},
    {code: "Tajik", title: "Tajik"},
    {code: "Thai", title: "Thai"},
    {code: "Tigrinya", title: "Tigrinya"},
    {code: "Tibetan", title: "Tibetan"},
    {code: "Turkmen", title: "Turkmen"},
    {code: "Tagalog", title: "Tagalog"},
    {code: "Tswana", title: "Tswana"},
    {code: "Tonga", title: "Tonga"},
    {code: "Turkish", title: "Turkish"},
    {code: "Tsonga", title: "Tsonga"},
    {code: "Tatar", title: "Tatar"},
    {code: "Twi", title: "Twi"},
    {code: "Tahitian", title: "Tahitian"},
    {code: "Uighur", title: "Uighur"},
    {code: "Ukrainian", title: "Ukrainian"},
    {code: "Urdu", title: "Urdu"},
    {code: "Uzbek", title: "Uzbek"},
    {code: "Venda", title: "Venda"},
    {code: "Vietnamese", title: "Vietnamese"},
    {code: "Volapük", title: "Volapük"},
    {code: "Walloon", title: "Walloon"},
    {code: "Welsh", title: "Welsh"},
    {code: "Wolof", title: "Wolof"},
    {code: "Xhosa", title: "Xhosa"},
    {code: "Yiddish", title: "Yiddish"},
    {code: "Yoruba", title: "Yoruba"},
    {code: "Zhuang", title: "Zhuang"},
    {code: "Zulu", title: "Zulu"},
];

export const jobCertificationsDocs = [
    "(ABMP) Diagnostic Imaging",
    "(ABMP) Hyperthermia",
    "(ABMP) Magnetic Resonance Imaging",
    "(ABMP) Medical Health",
    "(ABMP) Nuclear Medicine",
    "(ABMP) Radiation",
    "ABLS: Advanced Burn Life Support",
    "ACHPN: Advanced Certified Hospice and Palliative Nurse",
    "ACHRN: Advanced Certified Hyperbaric Registered Nurse",
    "ACLS: Advanced Cardiac Life Support",
    "ACM-RN: Accredited Case Manager-Registered Nurse",
    "ACNP-BC: Acute Care Nurse Practitioner-Board Certified",
    "ACNPC: Acute Care Nurse Practitioner Certification",
    "ACNS-BC: Adult Clinical Nurse Specialist - Board Certified",
    "ACRN: AIDS Certified Registered Nurse",
    "ACS (CCI): Advanced Cardiac Sonographer",
    "ACSW (NASW): Academy of Certified Social Workers",
    "ADLS: Advanced Disaster Life Support",
    "AE-C: Asthma Educator-Certified",
    "AFN-BC: Advanced Forensic Nursing–Board Certified",
    "ALNC: Advanced Legal Nurse Consultant",
    "ANLC: Advanced Nurse Lactation Consultant",
    "ANP-BC: Adult Nurse Practitioner-Board Certified (certified by ANCC)",
    "ANP-C: Adult Nurse Practitioner-Certified (certified by AANP)",
    "ANVP: Advanced Neurovascular Practitioner",
    "AOCN: Advanced Oncology Certified Nurse",
    "AOCNP: Advanced Oncology Certified Nurse Practitioner",
    "AOCNS: Advanced Oncology Certified Clinical Nurse Specialist",
    "APHN-BC: Advanced Public Health Nurse-Board Certified",
    "APRN: Advanced Practice Registered Nurse",
    "ARMRIT: American Registry of Magnetic Resonance Imaging Technologists",
    "ARNP: Advanced Registered Nurse Practitioner",
    "ASN: Associate of Science in Nursing",
    "ASW-G (NASW): Advanced Social Worker in Gerontology",
    "BB (ASCP): Technologist in Blood Banking",
    "BCBA (BACB): Board Certified Behavioral Analyst",
    "BCBA-D (BACB): Board Certified Behavioral Analyst - Doctoral Designation",
    "BCEN: Board of Certification for Emergency Nursing",
    "BCPPS (BPS): Board Certified Pediatric Pharmacy Specialist",
    "BD (ARRT): Bone Densitometry RT",
    "BDLS: Basic Disaster Life Support",
    "BHSc Nsg: Bachelor of Health Science—Nursing",
    "BLS: Basic Life Support",
    "BM: Bachelor of Midwifery",
    "BMET (AAMI): Certified Biomedical Equipment Technician",
    "BMTCN: Blood and Marrow Transplant Certified Nurse",
    "BN: Bachelor of Nursing",
    "BNSc: Bachelor of Nursing Science",
    "BPS: Bachelor of Professional Studies with a concentration in Nursing",
    "BS (ARRT): Breast Sonography RT",
    "BSN: Bachelor of Science in Nursing",
    "C (ASCP): Technologist in Chemistry",
    "C-ASWCM (NASW): Certified Advanced Social Work Case Manager",
    "C-CATODSW (NASW): Certified Clinical Alcohol Tobacco & Other Drugs Social Worker",
    "C-CYFSW (NASW): Certified Advanced Children Youth and Family Social Worker",
    "C-EFM: Certified in Electronic Fetal Monitoring",
    "C-NPT: Certified in Neonatal Pediatric Transport",
    "C-SPI: Certified Specialist in Poison Information",
    "C-SSWS (NASW): Certified School Social Work Specialist",
    "C-SWCM (NASW): Certified Social Work Case Manager",
    "C-SWHC (NASW): Certified Social Worker in Health Care",
    "CANP: Certified Adult Nurse Practitioner",
    "CAPA: Certified Ambulatory Perianesthesia Nurse",
    "CAPSW (NASW): Certified Advanced Practice Social Worker",
    "CARN: Certified Addictions Registered Nurse",
    "CAS (BACB): Certified Autism Specialist",
    "CATC (NBCC): Certified Addictions Treatment Counselors",
    "CATN-I: Course in Advanced Trauma Nursing - Instructor",
    "CATN-P: Course in Advanced Trauma Nursing - Provider",
    "CBCN: Certified Breast Care Nurse",
    "CBN: Certified Bariatric Nurse",
    "CBSPD: Certification Board for Sterile Processing and Distribution",
    "CCCN: Certified Continence Care Nurse",
    "CCDS: Certified Clinical Documentation Specialist",
    "CCE: Certified Childbirth Educator",
    "CCM: Certified Case Manager",
    "CCNS: Acute Care Clinical Nurse Specialist",
    "CCRN: Certification in Acute/Critical Care Nursing",
    "CCT (CCT): Certified Cardiographic Technician",
    "CCTC: Certified Clinical Transplant Coordinator",
    "CCTN: Certified Clinical Transplant Nurse",
    "CDAL: Certified Director of Assisted Living",
    "CDDN: Certified Developmental Disabilities Nurse",
    "CDE: Certified Diabetes Educator",
    "CDMS: Certified Disability Management Specialist",
    "CDN: Certified Dialysis Nurse",
    "CDONA/LTC: Certified Director of Nursing Administration/Long Term Care",
    "CEN: Certified Emergency Nurse",
    "CENP: Certified Executive in Nursing Practice",
    "CER: Certified Endoscope Reprocessor",
    "CETN: Certified Enterostomal Therapy Nurse",
    "CFCN: Certified Foot Care Nurse",
    "CFN: Certified Forensic Nurse",
    "CFNP: Certified Family Nurse Practitioner",
    "CFRN: Certified Flight Registered Nurse",
    "CG (ASCP): Technologist in Cytogenetics",
    "CGN: Certified Gastroenterology Nurse",
    "CGRN: Certified Gastroenterology Registered Nurse",
    "CHES: Certified Health Education Specialist",
    "CHN: Certified Hemodialysis Nurse",
    "CHPLN: Certified Hospice and Palliative Licensed Nurse",
    "CHPN: Certified Hospice and Palliative Nurse",
    "CHPNA: Certified Hospice and Palliative Nursing Assistant",
    "CHPPN: Certified Hospice and Palliative Pediatric Nurse",
    "CHRN: Certified Hyperbaric Registered Nurse",
    "CHSE-A: Certified Healthcare Simulation Educator-Advanced",
    "CHSE: Certified Healthcare Simulation Educator",
    "CHSOS: Certified Healthcare Simulation Operations Specialist",
    "CI (ARRT): Cardiac-Interventional Radiography RT",
    "CIC: Certified in Infection Control",
    "CLC: Certified Lactation Counselor",
    "CLES (AAMI): Certified Laboratory Equipment Specialist",
    "CLNC: Certified Legal Nurse Consultant",
    "CLS (California DPH): Clinical Laboratory Scientist",
    "CLS: Clinical Laboratory Scientist",
    "CM: Certified Midwife",
    "CMA: Certified Medical Assistant",
    "CMAS: Certified Medical Audit Specialist",
    "CMC: Cardiac Medicine Certification",
    "CMCN: Certified Managed Care Nurse",
    "CMDSC: Certified MDS Coordinator",
    "CMSRN: Certified Medical—Surgical Registered Nurse",
    "CNA: Certified Nursing Assistant",
    "CNCC(C): Certified Nurse in Critical Care (Canada)",
    "CNeph(C): Certified in Nephrology Nursing (Canada)",
    "CNL: Clinical Nurse Leader",
    "CNLCP: Certified Nurse Life Care Planner",
    "CNM: Certified Nurse Midwife",
    "CNML: Certified Nurse Manager and Leader",
    "CNMT (NMTCB): Certified Nuclear Medicine Technologist",
    "CNN: Certified in Nephrology Nursing",
    "CNO: Chief Nursing Officer",
    "CNOR: Certified Perioperative Nurse",
    "CNP: Certified Nurse Practitioner",
    "CNRN: Certified Neuroscience Registered Nurse",
    "CNS: Clinical Nurse Specialist",
    "CNSC: Certified Nutrition Support Clinician (Formerly CNSN: Certified Nutrition Support Nurse)",
    "COCN: Certified Ostomy Care Nurse",
    "COHC: Certified Occupational Hearing Conservationist",
    "COHN: Certified Occupational Health Nurse",
    "CORLN: Certified Otorhinolaryngology Nurse",
    "CPAN: Certified Post Anesthesia Nurse",
    "CPDN: Certified Peritoneal Dialysis Nurse",
    "CPEN: Certified Pediatric Emergency Nurse",
    "CPFT: Certified Pulmonary Function Technologist",
    "CPHON: Certified Pediatric Hematology Oncology Nurse",
    "CPHQ: Certified Professional in Healthcare Quality",
    "CPhT (PTCB): Certified Pharmacy Technician",
    "CPLC: Certified in Perinatal Loss Care",
    "CPN: Certified Pediatric Nurse or Community Psychiatric Nurse (United Kingdom)",
    "CPNA: Certified Pediatric Nurse Associate",
    "CPNL: Certified Practical Nurse Long-term care",
    "CPNP: Certified Pediatric Nurse Practitioner",
    "CPON: Certified Pediatric Oncology Nurse",
    "CPSN: Certified Plastic Surgical Nurse",
    "CRAT (CCI): Certified Rhythm Analysis Technician",
    "CRCST: Certified Registered Central Service Technician",
    "CRES (AAMI): Certified Radiology Equipment Specialist",
    "CRN: Certified Radiologic Nurse",
    "CRNA: Certified Registered Nurse Anesthetist",
    "CRNFA: Certified Registered Nurse First Assistant",
    "CRNI: Certified Registered Nurse Infusion",
    "CRNL: Certified Registered Nurse Long-term care",
    "CRNO: Certified Registered Nurse in Ophthalmology",
    "CRNP: Certified Registered Nurse Practitioner",
    "CRRN: Certified Rehabilitation Registered Nurse",
    "CRT-NPS or RRT-NPS: Neonatal/Pediatric Respiratory Care Specialist",
    "CRT-SDS or RRT-SDS: Sleep Disorders Specialist",
    "CRT: Certified Respiratory Therapist",
    "CSC: Cardiac Surgery Certification",
    "CSFA: Certified Surgical First Assistant",
    "CSHA: Certified Specialist in Hospital Accreditation",
    "CSN: Certified School Nurse",
    "CST: Certified Surgical Technologist",
    "CSW-G (NASW): Clinical Social Worker in Gerontology",
    "CT (ARRT): Computed Tomography RT",
    "CT (ASCP): Cytotechnologist",
    "CTCT (NMTCB): Computed Tomography",
    "CT: Certified in Thanatology (dying death and bereavement)",
    "CTN: Certified Transcultural Nurse",
    "CTRN: Certified Transport Registered Nurse",
    "CTRS: Certified Therapeutic Recreational Specialist",
    "CUA: Certified Urologic Associate",
    "CUCNS: Certified Urologic Clinical Nurse Specialist",
    "CUNP: Certified Urologic Nurse Practitioner",
    "CURN: Certified Urologic Registered Nurse",
    "CV (ARRT): Cardiovascular-Interventional Radiography RT",
    "CVICU: Cardiovascular Intensive Care Unit",
    "CVN: Certified Vascular Nurse",
    "CVOR: Cardiovascular Operating Room",
    "CVRN-BC: Cardiovascular Nurse-Board Certified",
    "CWCN: Certified Wound Care Nurse",
    "CWOCN: Certified Wound Ostomy Continence Nurse",
    "CWS: Certified Wound Specialist",
    "DLM (ASCP): Diplomate in Laboratory Management",
    "DN: Doctor of Nursing",
    "DNP: Doctor of Nursing Practice",
    "DNS: Doctor of Nursing Science also seen as DNSc",
    "DrNP: Doctor of Nursing Practice",
    "DSN: Diabetes Specialist Nurse",
    "DVNE: Domestic Violence Nurse Examiner",
    "DWC: Diabetic Wound Certified",
    "ECRN: Emergency Communications Registered Nurse",
    "ED: Emergency Department",
    "EdD: Doctor of Education",
    "ENP-BC: Emergency Nurse Practitioner-Board Certified (certified by ANCC)",
    "ENP-C: Emergency Nurse Practitioner- Certified (certified by AANP)",
    "ENPC-I: Emergency Nursing Pediatric Course Instructor",
    "ENPC-P: Emergency Nursing Pediatric Course Provider",
    "ENPC: Emergency Nursing Pediatric Course",
    "ET: Enterostomal Therapist",
    "Fluoroscopy Permit (CA): Fluoroscopy Permit",
    "FNC: Family Nurse Clinician",
    "FNP-BC: Family Nurse Practitioner (Certified by ANCC)",
    "FNP-C: Family Nurse Practitioner (Certified by AANP)",
    "FPNP: Family Planning Nurse Practitioner",
    "GNP: Gerontological Nurse Practitioner",
    "H (ASCP): Technologist in Hematology",
    "HN-BC®: Holistic Nurse-Board Certified (certified by AHNCC)",
    "HT (ASCP): Histotechnician",
    "HTL (ASCP): Histotechnologist",
    "ICC: Intensive Care Certification",
    "INC: Intensive Neonatal Care certification",
    "INS: Informatics Nurse Specialist",
    "LCPC (NBCC): Licensed Clinical Professional Counselor",
    "LCSW (NASW): Licensed Clinical Social Worker",
    "LMFT (COAMFTE): Licensed Marriage and Family Therapist",
    "LMHC (NBCC): Licensed Mental Health Counselor",
    "LNC: Legal Nurse Consultant",
    "LNCC: Legal Nurse Consultant Certified",
    "LPC (NBCC): Licensed Professional Counselor",
    "LPCC (NBCC): Licensed Professional Clinical Counselor",
    "LPN: Licensed Practical Nurse",
    "LPT (NBCC): Licensed Psychiatric Technician",
    "LSN: Licensed School Nurse",
    "LTAC: Long Term Acute Care",
    "LTC: Long Term Care (LPN Specific)",
    "M (ARRT): Mammography RT",
    "M (ASCP): Technologist in Microbiology",
    "MAB: Management of Assaultive Behavior",
    "MAN: Master of Arts in Nursing",
    "MB (ASCP): Technologist in Molecular Biology",
    "MEd: Master of Education",
    "MFT (COAMFTE): Marriage and Family Therapist",
    "MHN: Mental Health Nurse",
    "MLA (ASCP): Medical Laboratory Assistant",
    "MLS (ASCP): Medical Laboratory Scientist",
    "MLT (ASCP): Medical Laboratory Technician",
    "MPH: Master of Public Health",
    "MR (ARRT): Magnetic Resonance Imaging RT",
    "MRCN: Member Royal College of Nursing (UK)",
    "MS: Master of Science",
    "MSA: Medicare Set-Aside",
    "MSN/Ed: Master of Science in Nursing Education",
    "MSN: Master of Science in Nursing",
    "MSW (NASW): Masters in Social Work",
    "N (ARRT): Nuclear Medicine Technology RT",
    "NC-BC®: Nurse Coach-Board Certified (certified by AHNCC)",
    "NCSN (NBCSN): National Certified School Nurse",
    "NCSP (NBCSN): Nationally Certified School Psychologist",
    "NCT (NMTCB): NMTCB - CNMT",
    "NIHSS: NIH Stroke Scale",
    "NNP-BC: Neonatal Nurse Practitioner",
    "NRP: Neonatal Resuscitation Program",
    "OCN: Oncology Certified Nurse",
    "OMS: Ostomy Management Specialist",
    "ONC: Orthopaedic Nurse Certified",
    "PA (ASCP): Pathology Assistant",
    "PALS: Pediatric Advanced Life Support",
    "PBT (ASCP): Phlebotomy Technician",
    "PCCN: Progressive Care Certified Nurse",
    "PCNS: Pediatric Clinical Nurse Specialist",
    "PET (NMTCB): Positron Emission Technology",
    "PHN: Public Health Nurse",
    "PHNA-BC: Advanced Public Health Nurse",
    "PMHCNS-BC: Psychiatric Mental Health Clinical Nurse Specialist",
    "PMHN-BC: Psychiatric Mental Health Nurse",
    "PMHNP-BC: Psychiatric Mental Health Nurse Practitioner",
    "PNP-AC: Pediatric Nurse Practitioner - Acute Care",
    "PNP-BC: Pediatric Nurse Practitioner - Board Certified",
    "PNP-PC: Pediatric Nurse Practitioner - Primary Care",
    "QM (ARRT): Quality Management RT",
    "R (ARRT): Radiography RT",
    "R - RRA (ARRT): Registered Radiologist Assistant RT",
    "RCCS (CCI): Registered Congenital Cardiac Sonographer",
    "RCES (CCI): Registered Cardiac Electrophysiology Specialist",
    "RCIS (CCI): Registered Cardiovascular Invasive Specialist",
    "RCP: Respiratory Care Practitioner",
    "RCS (CCI): Registered Cardiac Sonographer",
    "RDCS - AE: RDMS Adult Echocardiography",
    "RDCS - FE: RDMS Fetal Echocardiography",
    "RDMS - AB: RDMS - Abdomen",
    "RDMS - BR: RDMS - Breast",
    "RDMS - NE: RDMS Neurosonology",
    "RDMS - OB/GYN: RDMS - Obstetrics & Gynecology",
    "RDMS - PE: RDMS Pediatric Echocardiography",
    "RDMS - PS: RDMS - Pediatric Sonography",
    "RDMS - RMSKS: RDMS - Musculoskeletal Sonography",
    "RDMS - RVT - VT: RDMS - Vascular Technology",
    "RDMS - RVT: Registered Vascular Technologist",
    "RM: Registered Midwife",
    "RNC-LRN: Registered Nurse Certified in Low Risk Neonatal Nursing",
    "RNC-MNN: Registered Nurse Certified in Maternal Newborn Nursing",
    "RNC-NIC: Registered Nurse Certified in Neonatal Intensive Care",
    "RNC-OB: Registered Nurse Certified in Inpatient Obstetrics",
    "RNC: Registered Nurse Certified: American Academy Certified Nurse",
    "RNFA: Registered Nurse First Assistant",
    "RNM: Registered Nurse-Midwife",
    "RPFT: Registered Pulmonary Function Technologist",
    "RPhs (CCI): Registered Phlebology Sonographer",
    "RPSGT: Registered Polysomnographic Technologist",
    "RRT: Registered Respiratory Therapist",
    "RVS (CCI): Registered Vascular Specialist",
    "S (ARRT): Sonography RT",
    "SANE-A: Certified Sexual Assault Nurse Examiner-Adult/Adolescent",
    "SANE-P: Certified Sexual Assault Nurse Examiner-Pediatric",
    "SANE: Sexual Assault Nurse Examiner",
    "SBB (ASCP): Specialist in Blood Banking",
    "SC (ASCP): Specialist in Chemistry",
    "SCN: Supervisory Clinical Nurse",
    "SCRN: Stroke Certified Registered Nurse",
    "SCT (ASCP): Specialist in Cytotechnology",
    "SCYM (ASCP): Specialist in Cytometry",
    "SEN: State Enrolled Nurse",
    "SH (ASCP): Specialist in Hematology",
    "SHN: Sexual and Reproductive Health endorsed RN—Queensland Australia",
    "SM (ASCP): Specialist in Microbiology",
    "SMB (ASCP): Specialist in Molecular Biology",
    "SN: Student Nurse (RN preparation)",
    "SNSC: School Nurse Services Credential (California CTC)",
    "T (ARRT): Radiation Therapy RTTCRN",
    "TNCC: Trauma Nursing Core Course",
    "TNP: Telephone Nursing Practitioner",
    "TNS: Trauma Nurse Specialist",
    "VA-BC: Vascular Access Board Certified",
    "VI (ARRT): Vascular-Interventional Radiography RT",
    "VS (ARRT): Vascular Sonography RT",
    "WCC: Wound Care Certified",
    "WHNP-BC: Women's Health Care Nurse Practitioner",
    "WTA-C: Certified Wound Treatment Associate"
    // Add the remaining items in the same pattern...
];

export const jobDocuments = [
    "Chest X-ray",
    "Covid Certificate",
    "Covid Test Result",
    "Covid Vaccine Card",
    "CPR/BLS Card",
    "Driver License",
    "Nursing License",
    "Live Scan",
    "Physical Test",
    "Resume",
    "SSN Card",
    "TB Test",
    "Vaccine Exemption Letter"

];
export const jobLicense = [
    "APRN-CNM",
    "APRN-CNP",
    "APRN-CNS",
    "APRN-CRNA",
    "PN",
    "RN"
]

export const jobSkillsDoc = [
    "Acute Rehab RN",
    "Anesthesia Technician",
    "Antepartum RN",
    "Audiologist",
    "Behavioral Health LPN",
    "Behavioral Health RN",
    "Behavioral Health Tech",
    "Biomedical Technician",
    "Cardiac Sonographer",
    "Cardiovascular Tech (Cath Lab Tech)",
    "Case Management LPN",
    "Case Management RN",
    "Cath Lab RN",
    "Certified Nursing Assistant",
    "Certified Nursing Assistant (Hospice/Palliative Care)",
    "Certified Nursing Assistant (Long Term Care)",
    "Clinical Educator",
    "Clinical Nurse Manager",
    "Clinical Social Worker",
    "Clinic RN",
    "Corrections LPN",
    "Corrections RN",
    "COVID-19 Vaccination Administrator",
    "COVID ICU RN",
    "COVID Med-Surg RN",
    "CT Tech",
    "CVICU RN",
    "CVOR RN",
    "CVOR Tech",
    "Cytotechnologist",
    "Dental Assistant",
    "Dental Hygienist",
    "Dialysis RN",
    "Dialysis Technician",
    "Director of Nursing (LTAC)",
    "Director of Nursing (LTC)",
    "Dosimetrist",
    "EEG Tech - Electroencephalography",
    "Electrophysiology RN",
    "Electrophysiology Technologist",
    "Emergency Department LPN",
    "Emergency Department RN",
    "Emergency Department Technician",
    "EMT (Emergency Medical Technician)",
    "Endoscopy/GI Lab RN",
    "Endoscopy/GI Lab Technician",
    "Environmental Services",
    "Executive Leadership",
    "First Assist (OR)",
    "Food Services",
    "Histology Technician",
    "Home Health Aide",
    "Home Health LPN",
    "Home Health RN",
    "Hospice & Palliative Care RN",
    "House Supervisor",
    "ICU RN",
    "Interventional Radiology RN",
    "Interventional Radiology Technologist",
    "IV Therapy & Infusion RN",
    "Labor & Delivery RN",
    "Long Term Care/Skilled RN",
    "LPN - IV Therapy",
    "LPN/LVN",
    "LPN/LVN (Long Term Care)",
    "LPN - Pediatrics",
    "LTAC RN (Long-Term Acute Care)",
    "Mammography Tech",
    "MDS Coordinator",
    "Medical Assistant",
    "Medical Laboratory Technician",
    "Medical Lab Scientist/Technologist",
    "Medical Physicist",
    "Medication Aide",
    "Med Surgical RN",
    "Med-Surg/Telemetry RN",
    "Monitor Technician - ECG/EKG",
    "Mother-Baby RN",
    "MRI Tech",
    "NICU RN",
    "Nuclear Med Tech",
    "Nurse Practitioner",
    "Nursing Home/LTC Administrator",
    "Occupational Therapist",
    "Occupational Therapist Assistant",
    "Oncology RN",
    "Ophthalmology Tech/Assistant",
    "OR RN - Circulator",
    "OR RN - Scrub",
    "PACU RN",
    "Paramedic",
    "Pathology Assistant",
    "Patient Care Technician",
    "Pediatrics RN",
    "Perfusionist (Perfusion Technologist)",
    "Pharmacist",
    "Pharmacy Technician",
    "Phlebotomist",
    "Physical Therapist",
    "Physical Therapist Assistant",
    "PICU RN",
    "Postpartum RN",
    "Pre-Operative RN",
    "Progressive Care Unit RN",
    "Psychiatric RN",
    "Quality/Risk Manager",
    "Radiation Therapist",
    "Radiology Technologist_All Modalities",
    "Radiology Technologist_General",
    "Regional Nurse Consultant",
    "Registered Dietician/Nutritionist",
    "Rehab RN",
    "Research RN",
    "Respiratory Therapist",
    "School LPN",
    "School RN",
    "Sleep Tech - Polysomnography",
    "Speech Language Pathologist",
    "Step-Down Unit RN",
    "Sterile Processing Technologist",
    "Surgical Technologist",
    "Telemetry RN",
    "Ultrasound Technologist",
    "Utilization Review RN",
    "Vascular Ultrasound Technician",
    "Virtual RN",
    "Virtual RN - ICU",
    "Wound Care RN"
];

export const jobCompetencyTestDocs=[
    "RN - Administrative Registered Nurse",
    "RN - ICU (Intensive Care Unit)",
    "RN - CVOR (Cardiovascular Operating Room)",
    "RN - Med-Surg",
    "RN - Telemetry/Stepdown",
    "RN - Progressive Care Unit (PCU)",
    "RN - Pediatric Emergency Department (PED)",
    "RN - Neonatal ICU (NICU)",
    "RN - Oncology Nurse",
    "RN - OR Surgical Nurse",
    "RN - Labor and Delivery Nurse",
    "RN - Peri-Operative Nurse",
    "RN - Postpartum Nurse",
    "RN - Dialysis Nurse",
    "RN - Psychiatric Nurse",
    "RN - Infusion RN",
    "RN - Hospice Nurse",
    "RN - Home Health RN",
    "RN - Infection Control Nurse",
    "RN - Emergency Department (ER) Nurse",
    "RN - Wound Care Nurse",
    "RN - Travel Nurse",
    "RN - Nurse Practitioner (NP)",
    "RN - Registered Nurse Supervisor",
    "RN - Occupational and Employee Health RN",
    "RN - Registered Nurse Appeals Writer",
    "RN - Endoscopy Nurse",
    "RN - Skilled Nursing Facility (SNF) Nurse",
    "RN - Case Management/Utilization Review RN",
    "RN - ADON/DON (Assistant/Director of Nursing)",
    "Licensed Practical Nurse (LPN) / Licensed Vocational Nurse (LVN)",
    "Certified Nursing Assistant (CNA)",
    "Patient Care Technician (PCT)",
    "Psychiatric Technician (Psych Tech)",
    "Monitor Technician",
    "General Practitioner (GP)",
    "Hospitalist",
    "Emergency Medicine Physician",
    "Cardiologist",
    "Radiologist",
    "Surgeon",
    "Anesthesiologist",
    "Neonatologist",
    "Obstetrician/Gynecologist (OB/GYN)",
    "Psychiatrist",
    "Nurse Practitioner (NP)",
    "Physician Assistant (PA)",
    "Occupational Therapist (OT)",
    "Physical Therapist (PT)",
    "Physical Therapist Assistant (PTA)",
    "Speech-Language Pathologist (SLP)",
    "Respiratory Therapist (RT)",
    "Acute Rehab Therapist",
    "Mental Health Clinician",
    "Cath Lab Technologist",
    "CT Technologist",
    "MRI Technician",
    "Nuclear Medicine Technologist",
    "Ultrasound Technologist (Sonographer)",
    "Radiology Technologist",
    "Dosimetrist",
    "Surgical Technologist",
    "Sterile Processing Technologist",
    "Endoscopy Technician",
    "Medical Laboratory Technician (MLT)",
    "Medical Technologist (MT)",
    "Clinical Microbiology Specialist",
    "Histology Technician",
    "Phlebotomist",
    "Laboratory Assistant"
]

export const atsStatusList=[
    {value:'none',label:'None'},
    {value:'all',label:'All'},
    {value:'available',label:'Available'}
]