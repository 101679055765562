import "./UpdatePayRateComponent.scss";
import {Button} from "antd";
import {useCallback, useState} from "react";
import {CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import InputComponent from "../../../../ant-component/input/InputComponent";

interface UpdatePayRateComponentProps{
    selectedJobs:string[];
    setSelectedJobs:any;
    setOpenPayRateModal:any;
}

const UpdatePayRateComponent=(props:UpdatePayRateComponentProps)=>{
    const {selectedJobs,setSelectedJobs,setOpenPayRateModal}=props
    const [payRate,setPayRate]=useState<string>("");
    const [isSubmitting,setIsSubmitting]=useState<boolean>(false)

    const handleChangePayRate=useCallback(()=>{
        setIsSubmitting(true);
        let payload:any={
            pay_rate:Number(payRate)?.toFixed(2),
            job_ids:selectedJobs
        }
        //integrate API
        CommonService._api.put(ENV.API_URL + `ats/editBulkJobRate`,payload).then((response:any)=>{
            CommonService.showToast(response.message || 'Successfully updated the pay rate','success')
        }).catch((error:any)=>{
            CommonService.showToast(error.error,'error')
        }).finally(()=>{
            setOpenPayRateModal(false);
            setSelectedJobs([]);
            setIsSubmitting(false);
        })
    },[setOpenPayRateModal,selectedJobs,payRate,setSelectedJobs])

    const handleCancel=useCallback(()=>{
        setPayRate('')
        setOpenPayRateModal(false);
        setSelectedJobs([])
    },[setOpenPayRateModal,setSelectedJobs])

    return (
        <div className={'change-pay-rate-component'}>
            <div className={'mrg-top-20'}>
                <InputComponent
                    className={'mrg-top-10'}
                    value={payRate}
                    type={'number'}
                    placeholder={'Enter pay rate'}
                    onChange={(value:any)=>{
                    setPayRate(value)
                }}
                />
            </div>
            <div className={'status-btn-wrapper'}>
                <Button
                    type={'primary'}
                    disabled={!payRate}
                    className="custom-primary-button"
                    onClick={handleChangePayRate}
                    loading={isSubmitting}
                >Update</Button>
                <Button className="custom-outline-button" onClick={handleCancel}>Cancel</Button>
            </div>
        </div>
    )
}
export default UpdatePayRateComponent