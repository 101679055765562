import "./JobMenuListComponent.scss";
import {SendMailIcon} from "../../../../../constants/ImageConfig";
import {Link} from "react-router-dom";
import {CommonService} from "../../../../../helpers";
import {StateParams} from "../../../../../store/reducers";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";


interface JobMenuListComponentProps{
    onMenuItemClick?:(action:string)=>void;
    selectedJobs?:string[];
}

const MENU_ITEMS=[
    {
        label:"Bulk Email",
        id:'bulk_mail',
        icon:SendMailIcon,
        link:'/bulk-email',
        action:'navigate',
    },
    {
        label:'Add Notes',
        id:'add_notes',
        icon:SendMailIcon,
        action:'add_notes'
    },
    {
        label: "Change Recruiter",
        id: 'change_recruiter',
        icon: SendMailIcon,
        action: 'change_recruiter',
    },
    {
        label: "Change Posting Configuration",
        id: 'post_multiple_places',
        icon: SendMailIcon,
        action: 'post_multiple_places',
    },
    {
        label: "Change Status",
        id: 'change_status',
        icon: SendMailIcon,
        action: 'change_status',
    },
    {
        label: "Update Pay Rate",
        id: 'update_pay_rate',
        icon: SendMailIcon,
        action: 'update_pay_rate',
    },
]

const JobMenuListComponent=(props:JobMenuListComponentProps)=>{
    const {onMenuItemClick,selectedJobs}=props;
    const {user} = useSelector((state: StateParams) => state.auth);
    const [menuItems,setMenuItems]=useState<any>(MENU_ITEMS);

    useEffect(() => {
        if(user){
            const updatedList = MENU_ITEMS?.filter((item) => !(user?.role === "recruiter" && item.id === "change_recruiter"));
            setMenuItems(updatedList)
        }
    }, [user]);

    return (
        <div className={'applicant-menu-list-component'}>
            {menuItems?.map((item:any)=>{
                return (
                    <div
                        key={item.id}
                        className={'applicant-menu-item'}
                        onClick={()=>{
                            if(selectedJobs?.length === 0 && item?.action !== 'navigate') {
                                CommonService.showToast(`Select Jobs to ${item?.label}`,'info')
                            }else{
                                onMenuItemClick?.(item?.action)
                            }
                        }}
                    >
                        {item?.action === 'navigate' ?
                            <Link
                                style={{
                                    color:'black',
                                    width:'100%'
                                }}
                                to={{
                                pathname:item.link,
                                state: {
                                    from: 'job-list'
                                }
                            }}>
                                {/*<img src={item.icon} alt={item.id}/>*/}
                                <span>
                                    {item.label}
                                </span>
                            </Link>
                            :
                            <div>
                                {/*<img src={item.icon} alt={item.id}/>*/}
                                <span>
                                    {item.label}
                                </span>
                            </div>
                        }
                    </div>

                )
            })}
        </div>
    )
}

export default JobMenuListComponent