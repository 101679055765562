import {Button} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {AddRounded, SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import AccessControlComponent from "../../../components/AccessControl";
import {ENV, ImageConfig} from "../../../constants";
import {ApiService, Communications} from "../../../helpers";
import CommonService, {
	ACCOUNTMANAGER,
	ADMIN,
	FINANCE,
	HUMANRESOURCE,
	HRMANAGER,
	NURSECHAMPION, TERICADMIN,RECRUITER
} from "../../../helpers/common-service";
import {StateParams} from "../../../store/reducers";
import "./FacilityManagementListScreen.scss";
import {CssTextField} from "../../../constants/data/styles";
import {TableColumnsType} from "antd";
import CustomToggleComponent from "../../../components/custom-toogle-using-button-component/CustomToggleComponent";
import DrawerComponent from "../../../components/drawer/DrawerComponent";
import FacilityFilterDrawerComponent from "../facility-filter-drawer/FacilityFilterDrawerComponent";
import AntTableComponent from "../../../components/ant-table-component/AntTableComponent";
import {useSessionStorage} from "../../../components/useSessionStorage";


const FacilityManagementListScreen = () => {
	const {role} = useSelector((state: StateParams) => state?.auth?.user);
	const [isDownloading, setIsDownloading] = useState<boolean>(false);
	const [filterData, setFilterData] = useSessionStorage<any>("facilityFilterData", {
		search: '',
		sort: {},
	});
	const [filterCount,setFilterCount]=useState<number>(0);
	const [openFilterDrawer,setOpenFilterDrawer]=useState<boolean>(false);

	useEffect(() => {
		let count = 0;

		if (filterData.is_active !== null && filterData.is_active !== undefined) count++;
		if (filterData.start_date) count++;
		if (filterData.end_date) count++;

		setFilterCount(count);
	}, [filterData]);

	const handleDownload = useCallback(() => {
		let payload: any = {};

		if (filterData?.is_active !== null && filterData?.is_active !== undefined) {
			payload.is_active = filterData?.is_active;
		}

		if (filterData?.start_date || filterData?.end_date) {
			let startDate = moment(filterData?.start_date).format("YYYY-MM-DD");
			let endDate = moment(filterData?.end_date).format("YYYY-MM-DD");

			if (!filterData?.end_date) {
				payload.start_date = startDate;
				payload.end_date = startDate;
			} else {
				payload.start_date = startDate;
				payload.end_date = endDate;
			}
		}


		if (filterData?.search) {
			payload.search = filterData.search
		}

		setIsDownloading(true);

		ApiService.post(ENV.API_URL + "facility/download", payload)
			.then((res) => {
				if (res?.data) {
					const link = document.createElement("a");
					link?.setAttribute("href", res?.data);
					document.body.appendChild(link);
					link.click();
					setIsDownloading(false);
				} else {
					CommonService.showToast(res?.msg || "No Data to Download", "info");
					setIsDownloading(false);
				}
			})
			.catch((err) => {
				setIsDownloading(false);
				CommonService.showToast(err?.msg || "Error", "error");
			});
	}, [filterData]);

	const handleToggleStatus = useCallback(
		(id: any, is_active) => {
			let payload = {
				is_active: !is_active,
			};
			CommonService._api
				.put(ENV.API_URL + "facility/" + id, payload)
				.then((resp) => {
					CommonService.showToast("Success", "success");
					let updatedFilterData={...filterData};
					setFilterData(updatedFilterData);
				})
				.catch((err) => {
					//	console.log(err);
				});
		},
		[filterData,setFilterData]
	);

	useEffect(() => {
		Communications.pageTitleSubject.next("Facility Management");
		Communications.pageBackButtonSubject.next(null);
	}, []);

	const facilityListColumns: TableColumnsType = useMemo(() => {
		return [
			{
				title: 'Facility Name',
				dataIndex: 'name',
				key: 'name',
				width: 200,
				sorter: (a: any, b: any) => {
					return a.name?.localeCompare(b.name);
				},
				render: (item: any) => {
					return (
						<div>
							{item?.length > 20  ?
								<Tooltip title={item}>
									<div className={'ellipses-for-table-data '}>
										{item}
									</div>
								</Tooltip>:
								<div className={'facility-name'}>
									{item || 'N/A'}
								</div>

							}
						</div>
					)
				}
			},
			{
				title: 'Created On',
				dataIndex: 'created_at',
				key: 'created_at',
				width: 80,
				sorter: (a: any, b: any) => {
					return moment(a.created_at).unix() - moment(b.created_at).unix();
				},
				render: (item: any) => {
					return (
						<>
							{moment(item).utc().format("MM-DD-YYYY") || 'N/A'}
						</>
					)
				}
			},
			{
				title: 'State',
				dataIndex: 'address',
				key: 'address',
				width: 100,
				sorter: (a: any, b: any) => {
					return a.address?.state?.localeCompare(b.address?.state);
				},
				render: (item: any) => {
					return (
						<div>
							{item?.state?.length > 15 ?
								<Tooltip title={item.state}>
									<div className={'ellipses-for-table-data '}>
										{item.state}
									</div>
								</Tooltip> :
								<div className={'facility-name'}>
									{item.state || 'N/A'}
								</div>

							}
						</div>
				)}
			},
			{
				title: 'Contact Number',
				dataIndex: 'contact_number',
				key: 'contact_number',
				width: 100,
				// sorter: (a: any, b: any) => {
				// 	return a.contact_number?.localeCompare(b.contact_number);
				// },
				render: (item: any) => {
					return (
						<>
							{CommonService.formatPhoneNumber(item?.replace(/^\+1/, '')) || 'N/A'}
						</>
					)
				}
			},
			{
				title: 'Active / Inactive',
				width: 100,
				render: (item: any) => {
					return (
						<div className={'display-center'}>
							{role === "super_admin" ? (
								<CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
									handleToggleStatus(item._id,item.is_active);
								}}/>
							) : (
								<>{item?.is_active ?
									<span style={{color: "#41D6C3"}}>Active</span> :
									<span style={{color: "#808080"}}>Inactive</span>}</>
							)}
						</div>
					)
				}
			},
			{
				title: 'View Details',
				width: 80,
				fixed:'right',
				render: (item: any) => {
					return (
						<div className={'display-center'}>
							<Link to={`/facility/view/${item._id}`}
							style={{color:'#1e1e1e'}}
							>
								<ImageConfig.ArrowRightOutlinedIcon/>
							</Link>
						</div>
					)
				}
			}
		]
	}, [handleToggleStatus,role])

	return (
		<>
			<DrawerComponent isOpen={openFilterDrawer} onClose={()=>setOpenFilterDrawer(false)}>
				<FacilityFilterDrawerComponent
					setOpenFilterDrawer={setOpenFilterDrawer}
					filterData={filterData}
					setFilterData={setFilterData}
				/>
			</DrawerComponent>
			<div className={"facility-list screen crud-layout pdd-30 pdd-top-10"}>
				<div className="custom-border pdd-10 pdd-top-20 pdd-bottom-0">
					<div className="header">
						<div className="mrg-left-5 filter">
							<div>
								<div className="d-flex">
									<div className="d-flex position-relative">
										{!filterData.search ? (
											<div className={"search_icon"}>
												<SearchRounded/>
											</div>
										) : (
											<div className={"search_icon"}>
												<ClearIcon
													onClick={(event) => {
														setFilterData({...filterData, search: '', page: 1})
													}}
													id="clear_facility_search"
												/>
											</div>
										)}
										<div>
											<CssTextField
												defaultValue={""}
												className="search-cursor searchField"
												id="input_search_facility"
												onChange={(event) => {
													const value = event.target.value;
													setFilterData({...filterData, search: value, page: 1});
												}}
												value={filterData.search}
												variant={"outlined"}
												size={"small"}
												type={"text"}
												placeholder={"Search Facility"}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<Button
								variant={"outlined"} id={'add_facility_btn'}
								color={"primary"}
								onClick={() => {
									setOpenFilterDrawer(true)
								}}
							>
								{filterCount > 0 &&
									<span className={'filter-btn-badge'}>{filterCount}</span>
								}
								&nbsp;&nbsp;Filters&nbsp;&nbsp;
							</Button>
						</div>
						<div className="action pdd-right-5 d-flex">
							<AccessControlComponent
								role={[ADMIN, HUMANRESOURCE, NURSECHAMPION, ACCOUNTMANAGER, FINANCE,TERICADMIN,HRMANAGER]}>
								<div className="mrg-left-20">
									<Tooltip title="Download Facility List">
										<Button variant={"outlined"} color="primary" onClick={handleDownload}
												className={!isDownloading ? "" : "has-loading-spinner"}
												disabled={isDownloading}>
											&nbsp;Download
										</Button>
									</Tooltip>
								</div>
							</AccessControlComponent>
						</div>
						<div className="mrg-left-20">
							<AccessControlComponent role={[ACCOUNTMANAGER, ADMIN, FINANCE,TERICADMIN,RECRUITER,HRMANAGER]}>
								<Tooltip title="Add New Facility">
									<Button variant={"contained"} id={'add_facility_btn'} color={"primary"}
											component={Link} to={`/facility/add`}>
										<AddRounded/>
										&nbsp;&nbsp;Add Facility&nbsp;&nbsp;
									</Button>
								</Tooltip>
							</AccessControlComponent>
						</div>
					</div>
					<div className={'facility-table-component mrg-top-20'}>
						<AntTableComponent
							url={ENV.API_URL + "facility/list"}
							method={"post"}
							columns={facilityListColumns}
							isRowSelection={false}
							extraPayload={filterData}
							noDataText={filterData.search ? 'Oops! It seems like there are no Facilities Available for the name you have searched.' : 'No Facilities Data Found.'}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default FacilityManagementListScreen;
