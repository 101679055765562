import React, {useCallback, useEffect, useState} from "react";
import {
    americanTimeZone,
    jobCertificationsDocs,
    jobCompetencyTestDocs,
    jobLicense,
    jobSkillsDoc,
    otHours
} from "../../../../constants/data";
import {PdfIcon} from "../../../../constants/ImageConfig";
import {CheckboxWithLabel, TextField} from "formik-material-ui";
// import PhoneInputComponent from "../../../../components/phoneInput/PhoneInputComponent";
import {facilityFormEditValidation, FacilityItemEditType} from "../FacilityInitialAndValidationsComponent";
import {Button, IconButton, InputAdornment, MenuItem, Typography} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import {ScrollToError} from "../../../../components/ScrollToError";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import DialogComponent from "../../../../components/DialogComponent";
import {ImageConfig} from "../../../../constants";
import DrawerComponent from "../../../../components/drawer/DrawerComponent";
import MoreFieldsDrawerComponent from "../../add/MoreFieldsDrawer/MoreFieldsDrawerComponent";
import {FIELDS, PAY_RATE_FIELDS} from "../../../../data";
import FilePreviewComponent from "../../../../components/file-preview/FilePreviewComponent";
import FileUploadComponent from "../../../../components/file-upload/FileUploadComponent";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import {Col, Divider, Input, Modal, Row, Switch} from "antd";
import CommonService from "../../../../helpers/common-service";
import InputComponent from "../../../ant-component/input/InputComponent";
import AddDocumentDrawer from "../../../ats/Job/add-job/job-credentialing/documents-modal/DocumentsModalComponent";


export interface FacilityEditDetailsComponentProps {
    onAdd: any;
    regions: any;
    isImageRemoved: any;
    facilityDetails: any;
    deleteFacilityImage: any;
    fileUpload: any;
    previewFile: any;
    deleteFile: any;
    OnFileSelected: any;
    isContractImageRemoved: any;
    deleteFacilityContractImage: any;
    previewContractFile: any;
    contractFileUpload: any;
    OnContractFileSelected: any;
    deleteContractFile: any;
    facilityContractData: any;
    setOtherDocuments: any;
    otherDocuments: any;
    otherFileUpload?: any;
    setOtherFileUpload?: any;
    OnOtherFileSelected?: any;
    openDeleteAttachment?: any;
    setRequiredDocumentsForStaff?: any
    setNewlyAddedFacilityDocuments?: any;
    newlyAddedFacilityDocuments?: any
    sectionState?:any;
    setSectionState?:any;
}

interface DocumentToggleProps {
    id: string;
    label: string;
    initialChecked?: boolean;
    onChange: (id: string, checked: boolean) => void;
    disabled?: boolean;
}
interface SectionState {
    [key: string]: string[];
}

const DocumentToggle: React.FC<DocumentToggleProps> = ({id, label, initialChecked = false, onChange, disabled}) => {
    const [checked, setChecked] = useState(initialChecked);

    const handleToggle = (checked: boolean) => {
        setChecked(checked);
        onChange(id, checked);
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0.5rem',
                border: '1px solid #ddd',
                borderRadius: '8px',
                color: 'black',
                backgroundColor: checked ? '#e6f7ff' : '#fff', // Optional: Highlight when checked
            }}
        >
            <Typography style={{color: 'black'}}>{label}</Typography>
            <Switch size={'small'} checked={checked} disabled={disabled} onChange={handleToggle}
                    className="custom-switch"/>
        </div>
    );
};

const FacilityEditDetailsComponent = (props: FacilityEditDetailsComponentProps) => {
    const {sectionState,setSectionState}=props;
    const onAdd = props?.onAdd;
    const setRequiredDocumentsForStaff = props?.setRequiredDocumentsForStaff
    const OnOtherFileSelected = props?.OnOtherFileSelected;
    const openDeleteAttachment = props?.openDeleteAttachment;
    const otherFileUpload = props?.otherFileUpload;
    // const setOtherFileUpload = props?.setOtherFileUpload;
    const setOtherDocuments = props?.setOtherDocuments;
    const otherDocuments = props?.otherDocuments;
    const regions = props?.regions;
    // const isImageRemoved = props?.isImageRemoved;
    const facilityDetails = props?.facilityDetails;
    const deleteFacilityImage = props?.deleteFacilityImage;
    const previewFile = props?.previewFile;
    const setNewlyAddedFacilityDocuments = props?.setNewlyAddedFacilityDocuments
    const fileUpload = props?.fileUpload;
    const onFileSelected = props?.OnFileSelected;
    const deleteFile = props?.deleteFile;
    // const isContractImageRemoved = props?.isContractImageRemoved;
    const deleteFacilityContractImage = props?.deleteFacilityContractImage;
    const previewContractFile = props?.previewContractFile;
    const contractFileUpload = props?.contractFileUpload;
    const OnContractFileSelected = props?.OnContractFileSelected;
    const deleteContractFile = props?.deleteContractFile;
    const [open, setOpen] = useState<boolean>(false);
    const [previewFileData, setPreviewFile] = useState<any | null>(null);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [optionalFields, setOptionalFields] = useState<any>([]);
    const [openPayRatesDrawer,setOpenPayRatesDrawer]=useState<boolean>(false);
    const [payRateOptionalFields,setPayRateOptionalFields]=useState<any>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [currentTitle, setCurrentTitle] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [facilityDocuments, setFacilityDocuments] = useState<any>(facilityDetails?.document_checklist || []);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [activeSection, setActiveSection] = useState<string>("");
    const [newDocument, setNewDocument] = useState<{ [key: string]: string }>({});
    const [showInput,setShowInput]=useState<{ [key: string]: boolean }>({})

    const availableOptions: SectionState = {
        certifications: jobCertificationsDocs,
        licenses: jobLicense,
        skills: jobSkillsDoc,
        competency_test:jobCompetencyTestDocs
    };

    const initialDocumentStates = facilityDocuments?.reduce(
        (acc: { [key: string]: boolean }, doc: { key: string | number; is_required: boolean }) => {
            acc[doc.key] = doc.is_required; // Set the value based on `is_required`
            return acc;
        },
        {} as { [key: string]: boolean }
    );


    const [documentStates, setDocumentStates] = useState<{
        [key: string]: boolean
    }>(initialDocumentStates)
    // const facilityContractData = props?.facilityContractData;


    const handleOpenModal = useCallback(() => {
        setIsModalVisible(true);
        setError("");
    }, []);

    const showDropDownBelowField = {
        MenuProps: {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
            getContentAnchorEl: null,
        },
    };

    const facilityInitialState: FacilityItemEditType = {
        uid: facilityDetails?.uid || "",
        name: facilityDetails?.name || "",
        short_name: facilityDetails?.short_name || "",
        business_name: facilityDetails?.business_name || "",
        email: facilityDetails?.email || "",
        contact_number: facilityDetails?.contact_number?.replace(/^\+1/, '') || '',
        extension_number: facilityDetails?.extension_number,
        parent_company: facilityDetails?.parent_company,
        website_url: facilityDetails?.website_url,
        about: facilityDetails?.about,
        address: {
            street: facilityDetails?.address?.street,
            city: facilityDetails?.address?.city,
            state: facilityDetails?.address?.state,
            region: facilityDetails?.address?.region,
            country: facilityDetails?.address?.country,
            zip_code: facilityDetails?.address?.zip_code,
        },
        notification_preferences: {
            sms: facilityDetails?.notification_preferences?.sms || false,
            email: facilityDetails?.notification_preferences?.email || false,
            push_notification: facilityDetails?.notification_preferences?.push_notification || false,
        },
        timezone: facilityDetails?.timezone,
        hourly_base_rates: {
            lpn_lvn: facilityDetails?.hourly_base_rates?.lpn_lvn,
            lna: facilityDetails?.hourly_base_rates?.lna,
            cna_lna: facilityDetails?.hourly_base_rates?.cna_lna,
            cna: facilityDetails?.hourly_base_rates?.cna,
            lvn: facilityDetails?.hourly_base_rates?.lvn,
            lpn: facilityDetails?.hourly_base_rates?.lpn,
            rn: facilityDetails?.hourly_base_rates?.rn,
            care_giver: facilityDetails?.hourly_base_rates?.care_giver,
            med_tech: facilityDetails?.hourly_base_rates?.med_tech,
            spd_tech: facilityDetails?.hourly_base_rates?.spd_tech,
            dsp: facilityDetails?.hourly_base_rates?.dsp,
            pss: facilityDetails?.hourly_base_rates?.pss,
            crma: facilityDetails?.hourly_base_rates?.crma,
            cls: facilityDetails?.hourly_base_rates?.cls,
            rn_supervisor: facilityDetails?.hourly_base_rates?.rn_supervisor,
            caregiver_dsp: facilityDetails?.hourly_base_rates?.caregiver_dsp,
            caregiver_pss: facilityDetails?.hourly_base_rates?.caregiver_pss,
            caregiver_crma: facilityDetails?.hourly_base_rates?.caregiver_crma,
            holiday: facilityDetails?.hourly_base_rates?.holiday,
            hazard: facilityDetails?.hourly_base_rates?.hazard,
            rn_speciality1: facilityDetails?.hourly_base_rates?.rn_speciality1,
            rn_speciality2: facilityDetails?.hourly_base_rates?.rn_speciality2,
            rn_travel: facilityDetails?.hourly_base_rates?.rn_travel,
            emt: facilityDetails?.hourly_base_rates?.emt,
            monitor_tech: facilityDetails?.hourly_base_rates?.monitor_tech,
            orst: facilityDetails?.hourly_base_rates?.orst,
            radiology_tech: facilityDetails?.hourly_base_rates?.radiology_tech,
            occupational_therapist: facilityDetails?.hourly_base_rates?.occupational_therapist,
            physical_therapist: facilityDetails?.hourly_base_rates?.physical_therapist,
            chha: facilityDetails?.hourly_base_rates?.chha,
            cna_chha: facilityDetails?.hourly_base_rates?.cna_chha,
            slp: facilityDetails?.hourly_base_rates?.slp,
            scrub_tech: facilityDetails?.hourly_base_rates?.scrub_tech,
            medical_assistant: facilityDetails?.hourly_base_rates?.medical_assistant,
            marriage_family_therapist: facilityDetails?.hourly_base_rates?.marriage_family_therapist,
            clinical_social_worker: facilityDetails?.hourly_base_rates?.clinical_social_worker,
            psychologist: facilityDetails?.hourly_base_rates?.psychologist,
            pharmacist: facilityDetails?.hourly_base_rates?.pharmacist,
            pharmacy_technician: facilityDetails?.hourly_base_rates?.pharmacy_technician,
            evaluation: facilityDetails?.hourly_base_rates?.evaluation,
            start_of_care: facilityDetails?.hourly_base_rates?.start_of_care,
            rn_routine_vist: facilityDetails?.hourly_base_rates?.rn_routine_vist,
            lvn_routine_vist: facilityDetails?.hourly_base_rates?.lvn_routine_vist,
            ot_pt_st_routine_vist: facilityDetails?.hourly_base_rates?.ot_pt_st_routine_vist,
            ota_pta_sta_routine_vist: facilityDetails?.hourly_base_rates?.ota_pta_sta_routine_vist,
            discharge: facilityDetails?.hourly_base_rates?.discharge,
            recertification: facilityDetails?.hourly_base_rates?.recertification,
            resumption_of_care: facilityDetails?.hourly_base_rates?.resumption_of_care,
            bsw: facilityDetails?.hourly_base_rates?.bsw,
            cna_routine_vist: facilityDetails?.hourly_base_rates?.cna_routine_vist,
            caregiver_chha_routine_vist: facilityDetails?.hourly_base_rates?.caregiver_chha_routine_vist,
        },
        diff_rates: {
            pm: facilityDetails?.diff_rates?.pm,
            noc: facilityDetails?.diff_rates?.noc,
            weekend: facilityDetails?.diff_rates?.weekend,
        },
        conditional_rates: {
            overtime: {
                hours: facilityDetails?.conditional_rates?.overtime?.hours,
                rate: facilityDetails?.conditional_rates?.overtime?.rate,
            },
            rush: {
                hours: facilityDetails?.conditional_rates?.rush?.hours,
                rate: facilityDetails?.conditional_rates?.rush?.rate,
            },
            cancellation_before: {
                hours: facilityDetails?.conditional_rates?.cancellation_before?.hours,
                rate: facilityDetails?.conditional_rates?.cancellation_before?.rate,
            },
            shift_early_completion: {
                hours: facilityDetails?.conditional_rates?.shift_early_completion?.hours,
                rate: facilityDetails?.conditional_rates?.shift_early_completion?.rate,
            },
        },
        pay_hourly_base_rates: {
            lpn_lvn: facilityDetails?.pay_hourly_base_rates?.lpn_lvn,
            lna: facilityDetails?.pay_hourly_base_rates?.lna,
            cna_lna: facilityDetails?.pay_hourly_base_rates?.cna_lna,
            cna: facilityDetails?.pay_hourly_base_rates?.cna,
            lvn: facilityDetails?.pay_hourly_base_rates?.lvn,
            lpn: facilityDetails?.pay_hourly_base_rates?.lpn,
            rn: facilityDetails?.pay_hourly_base_rates?.rn,
            care_giver: facilityDetails?.pay_hourly_base_rates?.care_giver,
            med_tech: facilityDetails?.pay_hourly_base_rates?.med_tech,
            spd_tech: facilityDetails?.pay_hourly_base_rates?.spd_tech,
            dsp: facilityDetails?.pay_hourly_base_rates?.dsp,
            pss: facilityDetails?.pay_hourly_base_rates?.pss,
            crma: facilityDetails?.pay_hourly_base_rates?.crma,
            cls: facilityDetails?.pay_hourly_base_rates?.cls,
            rn_supervisor: facilityDetails?.pay_hourly_base_rates?.rn_supervisor,
            caregiver_dsp: facilityDetails?.pay_hourly_base_rates?.caregiver_dsp,
            caregiver_pss: facilityDetails?.pay_hourly_base_rates?.caregiver_pss,
            caregiver_crma: facilityDetails?.pay_hourly_base_rates?.caregiver_crma,
            holiday: facilityDetails?.pay_hourly_base_rates?.holiday,
            hazard: facilityDetails?.pay_hourly_base_rates?.hazard,
            rn_speciality1: facilityDetails?.pay_hourly_base_rates?.rn_speciality1,
            rn_speciality2: facilityDetails?.pay_hourly_base_rates?.rn_speciality2,
            rn_travel: facilityDetails?.pay_hourly_base_rates?.rn_travel,
            emt: facilityDetails?.pay_hourly_base_rates?.emt,
            monitor_tech: facilityDetails?.pay_hourly_base_rates?.monitor_tech,
            orst: facilityDetails?.pay_hourly_base_rates?.orst,
            radiology_tech: facilityDetails?.pay_hourly_base_rates?.radiology_tech,
            occupational_therapist: facilityDetails?.pay_hourly_base_rates?.occupational_therapist,
            physical_therapist: facilityDetails?.pay_hourly_base_rates?.physical_therapist,
            chha: facilityDetails?.pay_hourly_base_rates?.chha,
            cna_chha: facilityDetails?.pay_hourly_base_rates?.cna_chha,
            slp: facilityDetails?.pay_hourly_base_rates?.slp,
            scrub_tech: facilityDetails?.pay_hourly_base_rates?.scrub_tech,
            medical_assistant: facilityDetails?.pay_hourly_base_rates?.medical_assistant,
            marriage_family_therapist: facilityDetails?.pay_hourly_base_rates?.marriage_family_therapist,
            clinical_social_worker: facilityDetails?.pay_hourly_base_rates?.clinical_social_worker,
            psychologist: facilityDetails?.pay_hourly_base_rates?.psychologist,
            pharmacist: facilityDetails?.pay_hourly_base_rates?.pharmacist,
            pharmacy_technician: facilityDetails?.pay_hourly_base_rates?.pharmacy_technician,
            evaluation: facilityDetails?.pay_hourly_base_rates?.evaluation,
            start_of_care: facilityDetails?.pay_hourly_base_rates?.start_of_care,
            rn_routine_vist: facilityDetails?.pay_hourly_base_rates?.rn_routine_vist,
            lvn_routine_vist: facilityDetails?.pay_hourly_base_rates?.lvn_routine_vist,
            ot_pt_st_routine_vist: facilityDetails?.pay_hourly_base_rates?.ot_pt_st_routine_vist,
            ota_pta_sta_routine_vist: facilityDetails?.pay_hourly_base_rates?.ota_pta_sta_routine_vist,
            discharge: facilityDetails?.pay_hourly_base_rates?.discharge,
            recertification: facilityDetails?.pay_hourly_base_rates?.recertification,
            resumption_of_care: facilityDetails?.pay_hourly_base_rates?.resumption_of_care,
            bsw: facilityDetails?.pay_hourly_base_rates?.bsw,
            cna_routine_vist: facilityDetails?.pay_hourly_base_rates?.cna_routine_vist,
            caregiver_chha_routine_vist: facilityDetails?.pay_hourly_base_rates?.caregiver_chha_routine_vist,
        },
        pay_diff_rates: {
            pm: facilityDetails?.pay_diff_rates?.pm,
            noc: facilityDetails?.pay_diff_rates?.noc,
            weekend: facilityDetails?.pay_diff_rates?.weekend,
        },
        pay_conditional_rates: {
            overtime: {
                hours: facilityDetails?.pay_conditional_rates?.overtime?.hours,
                rate: facilityDetails?.pay_conditional_rates?.overtime?.rate,
            },
            rush: {
                hours: facilityDetails?.pay_conditional_rates?.rush?.hours,
                rate: facilityDetails?.pay_conditional_rates?.rush?.rate,
            },
            cancellation_before: {
                hours: facilityDetails?.pay_conditional_rates?.cancellation_before?.hours,
                rate: facilityDetails?.pay_conditional_rates?.cancellation_before?.rate,
            },
            shift_early_completion: {
                hours: facilityDetails?.pay_conditional_rates?.shift_early_completion?.hours,
                rate: facilityDetails?.pay_conditional_rates?.shift_early_completion?.rate,
            },
        },
        latitude:Number(facilityDetails?.location?.coordinates[1]),
        longitude:Number(facilityDetails?.location?.coordinates[0]),
        location: {
            coordinates: [Number(facilityDetails?.location?.coordinates[0]), Number(facilityDetails?.location?.coordinates[1])],
            type: "Point"
            // longitude: facilityDetails?.location?.coordinates[0],
            // latitude: facilityDetails?.location?.coordinates[1],
        },
    };
    const onlyNums = /^\d{1,3}(\.$|\.\d{1,3}$|$)/;

    //function to set the data in optionField (BILL RATE FIELDS)
    useEffect(() => {
        const selectedFields: any[] = [];

        if (facilityDetails?.hourly_base_rates) {
            Object.entries(facilityDetails.hourly_base_rates).forEach(([key, value]) => {
                if (value) {
                    const field = FIELDS.find((f) => f.name === `hourly_base_rates.${key}`);
                    if (field) selectedFields.push(field);
                }
            });
        }

        if (facilityDetails?.conditional_rates) {
            Object.entries(facilityDetails.conditional_rates).forEach(([key, value]) => {
                // @ts-ignore
                if (value?.hours) {
                    const hoursField = FIELDS.find(
                        (f) => f.name === `conditional_rates.${key}.hours`
                    );
                    if (hoursField) selectedFields.push(hoursField);
                }
                // @ts-ignore
                if (value?.rate) {
                    const rateField = FIELDS.find(
                        (f) => f.name === `conditional_rates.${key}.rate`
                    );
                    if (rateField) selectedFields.push(rateField);
                }
            });
        }
        setOptionalFields(selectedFields);
    }, [facilityDetails]);

    //function to set the data in optionField (PAY RATE FIELDS)
    useEffect(() => {
        const selectedFields: any[] = [];

        if (facilityDetails?.pay_hourly_base_rates) {
            Object.entries(facilityDetails.pay_hourly_base_rates).forEach(([key, value]) => {
                if (value) {
                    const field = PAY_RATE_FIELDS.find((f) => f.name === `pay_hourly_base_rates.${key}`);
                    if (field) selectedFields.push(field);
                }
            });
        }

        if (facilityDetails?.pay_conditional_rates) {
            Object.entries(facilityDetails.pay_conditional_rates).forEach(([key, value]) => {
                // @ts-ignore
                if (value?.hours) {
                    const hoursField = PAY_RATE_FIELDS.find(
                        (f) => f.name === `pay_conditional_rates.${key}.hours`
                    );
                    if (hoursField) selectedFields.push(hoursField);
                }
                // @ts-ignore
                if (value?.rate) {
                    const rateField = PAY_RATE_FIELDS.find(
                        (f) => f.name === `pay_conditional_rates.${key}.rate`
                    );
                    if (rateField) selectedFields.push(rateField);
                }
            });
        }
        setPayRateOptionalFields(selectedFields);
    }, [facilityDetails]);


    const deleteOtherFile = (temp: any, itemIndex: any) => {
        const updatedOthersDocument = [...otherDocuments];
        updatedOthersDocument.splice(temp, 1);
        setOtherDocuments(updatedOthersDocument);
    };

    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const previewOtherFile = useCallback(
        (item: any, index: any, type: any) => {
            console.log(otherFileUpload);

            setPreviewFile(otherFileUpload?.wrapper[index]);
            setOpen(true);
        },
        [otherFileUpload, setPreviewFile, setOpen]
    );

    const previewOtherUploadedFile = useCallback(
        (index: any, type: any) => {
            setPreviewFile(index);
            setOpen(true);
        },
        [setPreviewFile, setOpen]
    );

    const handleToggleChange = (id: string, checked: boolean) => {
        setDocumentStates((prevStates) => ({
            ...prevStates,
            [id]: checked,
        }));
    };

    const handleSubmit = useCallback(() => {
        const documentChecklist = facilityDocuments?.map((doc: any) => ({
            key: doc.key,
            title: doc.title === 'Nursing License' ? 'License' : doc.title,
            is_required: documentStates[doc.key] || false,
        }));

        // Send data to backend
        setRequiredDocumentsForStaff({document_checklist: documentChecklist})
        console.log(documentStates)
        console.log("Submitting data to backend:", {document_checklist: documentChecklist});
        // Add your backend call here
        // eslint-disable-next-line
    }, [documentStates, setRequiredDocumentsForStaff, facilityDetails, documentStates]);

    useEffect(() => {
        handleSubmit()
    }, [handleSubmit]);


    const combinedDocuments = [
        ...(facilityDetails?.attachments || []).map((attachment: any) => ({
            ...attachment,
            source: 'api', // Mark as from API
            name: attachment.file_name || 'N/A', // Standardize name key for consistency
        })),
        ...(otherDocuments || []).map((document: any) => ({
            ...document,
            source: 'local', // Mark as locally uploaded
        })),
    ];

    const handleCloseModal = useCallback(() => {
        setIsModalVisible(false);
        setCurrentTitle("");
        setError("");// Clear the input field when modal closes
    }, []);

    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentTitle(event.target.value);
            setError("");
        },
        []
    );

    const handleAddTitle = useCallback(() => {
        const trimmedTitle = currentTitle;
        if (trimmedTitle) {
            if (!trimmedTitle) {
                setError("Title cannot be empty.");
                return;
            }

            if(trimmedTitle?.replace(/\s/g, "").toLowerCase() === 'license'){
                setError('Document already exist');
                return;
            }

            // Check for duplicates (case-insensitive)
            const isDuplicate = facilityDocuments?.some(
                (doc: any) =>
                    doc?.title?.replace(/\s/g, "").toLowerCase() ===
                    trimmedTitle?.replace(/\s/g, "").toLowerCase()
            );
            if (isDuplicate) {
                setError("Document already exist");
                return;
            }

            const key = trimmedTitle?.toLowerCase().replace(/\s+/g, "_");
            const newDocument = {
                key,
                title: trimmedTitle,
                //  is_required: false,
            };
            setFacilityDocuments((prevDocuments: any) => [...prevDocuments, newDocument]);
            setNewlyAddedFacilityDocuments((prevDocuments: any) => [...prevDocuments, newDocument])
            handleCloseModal(); // Close the modal after adding
        }
    }, [currentTitle, handleCloseModal, facilityDocuments, setNewlyAddedFacilityDocuments]);

    async function getLatLong(street: any, city: any, state: any, zipcode: any) {
        // const address = `${street}, ${city}, ${state}, ${zipcode}, USA`;
        const url = `https://nominatim.openstreetmap.org/search?postalcode=${zipcode}&countrycodes=us&format=json&limit=1`;
        // const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&countrycodes=us&format=json&addressdetails=1&limit=1`;
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.length > 0) {
                const location = data[0];
                return {
                    latitude: location.lat,
                    longitude: location.lon,
                };
            } else {
                throw new Error('Geocoding failed: No results found');
            }
        } catch (error) {
            console.error('Error fetching geolocation:', error);
            return null;
        }
    }

    const openDocumentDrawer = useCallback((section: string) => {
        setActiveSection(section);
        setIsDrawerVisible(true);
    }, []);

    // Close the drawer
    const closeDrawer = useCallback(() => {
        setIsDrawerVisible(false);
    }, []);

    //add other document
    const addOtherDocument = useCallback((sectionKey: string) => {
        if (newDocument[sectionKey]) {
            setSectionState((prev:any) => ({
                ...prev,
                [sectionKey]: [...prev[sectionKey], newDocument[sectionKey]],
            }));
            setNewDocument((prev) => ({ ...prev, [sectionKey]: "" }));
            setShowInput((prev)=>({
                ...prev,
                [sectionKey]:false
            }))
        }
    },[newDocument,setSectionState])

    const handleOtherDocumentClose=useCallback((sectionKey)=>{
        setNewDocument((prev) => ({ ...prev, [sectionKey]: "" }));
        setShowInput((prev)=>({
            ...prev,
            [sectionKey]:false
        }))
    },[])


    // Update the selected items for a section
    const updateSection = useCallback((section: string, selectedItems: string[]) => {
        setSectionState((prev:any) => ({
            ...prev,
            [section]: selectedItems,
        }));
        closeDrawer();
    }, [closeDrawer,setSectionState]);

    const removeDocument = useCallback((sectionKey, index) => {
        setSectionState((prev:any) => ({
            ...prev,
            [sectionKey]: prev[sectionKey].filter((_:any, i:number) => i !== index),
        }))
    }, [setSectionState])




    const renderSection = (title: string,sectionKey: string,addButtonText: string) => (
        <div className="required-documents-layout__section">
            <h3 className="required-documents-layout__section-title">{title}</h3>
            <div className="required-documents-layout__content">
                {sectionState[sectionKey]?.length > 0 && <>
                    {
                        sectionState[sectionKey]?.map((item:any, index:number) => {
                            return (
                                <>
                                    <div className={'document-tag'}>
                                        <div className={'job-doc-text'}>{item}</div>
                                        <div className={'doc-cross-icon cursor-pointer'} onClick={() => {
                                            removeDocument(sectionKey, index)
                                        }}>x
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </>}
                {sectionState[sectionKey]?.length === 0 && <>
                    <p className="required-documents-layout__no-selection">
                        No {CommonService.formatTitleCase(sectionKey)} Selected
                    </p>
                </>}
                <Button
                    className="required-documents-layout__add-button"
                    onClick={() => openDocumentDrawer(sectionKey)}
                >
                    + {addButtonText}
                </Button>
                {showInput?.[sectionKey] ?
                    <div className={'d-flex'}>
                        <InputComponent
                            autoFocus={true}
                            className={'mrg-right-20'}
                            placeholder="Enter Other Document"
                            value={newDocument[sectionKey] || ""}
                            onChange={(value:string) => setNewDocument((prev) => ({ ...prev, [sectionKey]: value }))}
                            onPressEnter={() => addOtherDocument(sectionKey)}
                        />
                        <Button className={'custom-outline-button mrg-right-20'} onClick={()=>addOtherDocument(sectionKey)}>
                            Add
                        </Button>
                        <Button className={'custom-outline-button'} onClick={()=>handleOtherDocumentClose(sectionKey)}>
                            Close
                        </Button>
                    </div>:
                    <Button
                        className="required-documents-layout__add-button"
                        onClick={() => {
                            setShowInput((prevState:any)=>({
                                ...prevState,
                                [sectionKey]:true
                            }))
                        }}
                    >
                        + Add Additional Documents
                    </Button>
                }
            </div>
            <Divider/>
        </div>
    );



    return (
        <div>
            <div>
                {/* Button to open the modal */}


                {/* Modal dialog for adding a document title */}
                <Modal
                    title="Add New Document"
                    open={isModalVisible}
                    onCancel={handleCloseModal}
                    footer={null}
                    className="custom-modal"
                >
                    <Input
                        value={currentTitle}
                        onChange={handleInputChange}
                        placeholder="Enter document title"
                        maxLength={50}
                        allowClear
                    />
                    {error && (
                        <Typography style={{marginTop: "0.5rem", display: "block", color: "red"}}>
                            {error}
                        </Typography>
                    )}
                    <div className={'mrg-top-25 d-flex justify-content-end'}>
                        <Button variant={'outlined'} color={'primary'} onClick={handleAddTitle}>
                            Add Document
                        </Button>
                    </div>
                </Modal>

                {/* List to display all added document titles */}

            </div>
            <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile}
                                   previewData={previewFileData}/>
            </DialogComponent>
            <Formik initialValues={facilityInitialState} validateOnChange={true}
                    validationSchema={facilityFormEditValidation} onSubmit={onAdd}>
                {({isSubmitting, isValid, setFieldValue, resetForm, values,errors}) => {
                    console.log(errors,"ERROR")
                    return (
                        <Form id="facility-edit-form" className={"form-holder"}>
                            <DrawerComponent
                                isOpen={openDrawer}
                                onClose={() => setOpenDrawer(false)}
                                title={'More Fields'}
                                showClose={true}

                            >
                                <MoreFieldsDrawerComponent
                                    fields={FIELDS}
                                    setOpenDrawer={setOpenDrawer}
                                    setOptionalFields={setOptionalFields}
                                    optionalFields={optionalFields}
                                    setFieldValue={setFieldValue}
                                />
                            </DrawerComponent>
                            <DrawerComponent
                                isOpen={openPayRatesDrawer}
                                onClose={() => setOpenPayRatesDrawer(false)}
                                title={'More Fields'}
                                showClose={true}

                            >
                                <MoreFieldsDrawerComponent
                                    fields={PAY_RATE_FIELDS}
                                    setOpenDrawer={setOpenPayRatesDrawer}
                                    setOptionalFields={setPayRateOptionalFields}
                                    optionalFields={payRateOptionalFields}
                                    setFieldValue={setFieldValue}
                                />
                            </DrawerComponent>
                            <ScrollToError/>
                            <div className="facility-basic-details mrg-top-40 custom-border">
                                <p className="card-header">Basic Details</p>
                                <div className="input-container">
                                    <Field variant="outlined" name="name" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="Facility Name*" fullWidth autoComplete="off"
                                           id="input_facility_edit_facility_name"/>
                                    <Field variant="outlined" name="business_name" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="Business Name" fullWidth autoComplete="off"
                                           id="input_facility_edit_business_name"/>
                                </div>

                                <div className="input-container">
                                    <Field variant="outlined" name="uid" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="Facility Unique ID*" fullWidth autoComplete="off"
                                           id="input_facility_edit_facility_uid"/>
                                    <Field variant="outlined" name="short_name" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="Facility Short Name*" fullWidth autoComplete="off"
                                           id="input_facility_edit_facility_short_name"/>
                                </div>
                                <div className="input-container mrg-top-10">
                                    <Field
                                        inputProps={{
                                            maxLength: 10,
                                            onInput: (e: any) => {
                                                if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10);
                                            },
                                        }}
                                        className="phone_number"
                                        variant="outlined"
                                        name="contact_number"
                                        type={"number"}
                                        component={TextField}
                                        label="Phone Number*"
                                        fullWidth
                                        autoComplete="off"
                                        id="input_facility_member_add_phone_number"
                                        InputLabelProps={{shrink: true}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    +1
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Field variant="outlined" name="email" type={"text"} component={TextField}
                                           label="Email"
                                           InputLabelProps={{shrink: true}}
                                           fullWidth autoComplete="off" id="input_facility_add_email"/>
                                </div>
                                <div className="input-container mrg-top-10">
                                    <Field variant="outlined" name="address.street" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="Street*" fullWidth autoComplete="off"
                                           id="input_facility_edit_address_street"/>
                                    <Field variant="outlined" name="address.city" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="City*" fullWidth autoComplete="off"
                                           id="input_facility_edit_address_city"/>
                                </div>

                                <div className="input-container">
                                    <Field
                                        SelectProps={showDropDownBelowField}
                                        variant="outlined"
                                        name="address.state"
                                        type={"text"}
                                        component={TextField}
                                        select
                                        label="State*"
                                        fullWidth
                                        className="flex-1"
                                        autoComplete="off"
                                        id="input_facility_add_address_state"
                                        InputLabelProps={{shrink: true}}
                                    >
                                        {regions &&
                                            regions.map((item: any, index: any) => (
                                                <MenuItem id={`${item}`} value={item.code} key={index}>
                                                    {item.title}
                                                </MenuItem>
                                            ))}
                                    </Field>
                                    <Field variant="outlined" name="address.country" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="Country*" fullWidth autoComplete="off"
                                           id="input_facility_add_address_country"/>
                                </div>

                                <div className="input-container mrg-top-10">
                                    <div style={{width: "50%"}}>
                                        <Field
                                            fontSize="small"
                                            className="timezone-select"
                                            variant="outlined"
                                            name="timezone"
                                            type={"text"}
                                            component={TextField}
                                            select
                                            SelectProps={showDropDownBelowField}
                                            label="Facility Timezone*"
                                            fullWidth
                                            autoComplete="off"
                                            id="input_facility_edit_timezone"
                                            InputLabelProps={{shrink: true}}
                                        >
                                            <MenuItem style={{fontWeight: 500}} value="">
                                                {" "}
                                                Select Timezone
                                            </MenuItem>
                                            {americanTimeZone &&
                                                americanTimeZone.map((item: any, index: any) => (
                                                    <MenuItem style={{fontSize: "14px"}} value={item.value} key={index}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                        </Field>
                                    </div>
                                    <div style={{width: "50%"}} className={'d-flex'}>
                                        <div style={{width: "70%"}}>
                                            <Field
                                                inputProps={{
                                                    maxLength: 6,
                                                }}
                                                variant="outlined"
                                                name="address.zip_code"
                                                type={"text"}
                                                component={TextField}
                                                label="Zip Code*"
                                                autoComplete="off"
                                                id="input_facility_add_address_zip_code"
                                                fullWidth
                                                InputLabelProps={{shrink: true}}
                                            />
                                        </div>
                                        <div className={'mrg-left-10 mrg-top-5'}>
                                            <Button variant={'contained'} color={"primary"}
                                                //@ts-ignore
                                                    disabled={!values?.address?.zip_code}
                                                    onClick={() => {
                                                        //@ts-ignore
                                                        getLatLong('', '', '', values?.address?.zip_code)
                                                            .then((coords: any) => {
                                                                setFieldValue("latitude", coords.latitude);
                                                                setFieldValue("longitude", coords.longitude);
                                                                CommonService.showToast("Latitude and Longitude Fetched", "success")
                                                                // const googleMapsUrl = `https://www.google.com/maps?q=${coords.latitude},${coords.longitude}`;
                                                                // window.open(googleMapsUrl, '_blank');
                                                            })
                                                            .catch((error: any) => CommonService.showToast("Not Able to fetch Latitude and Longitude due to geocode errors", "error"));
                                                    }}>
                                                Check
                                            </Button>
                                        </div>
                                        <div className={'mrg-left-10 mrg-top-5'}>
                                            <Button variant={'contained'} color={"primary"}
                                                //@ts-ignore
                                                    disabled={!values.latitude || !values.longitude}
                                                    onClick={() => {
                                                        //@ts-ignore
                                                        getLatLong('', '', '', values?.patient_zip_code)
                                                            .then((coords: any) => {
                                                                //@ts-ignore
                                                                if (values.latitude && values.longitude) {
                                                                    //@ts-ignore
                                                                    const googleMapsUrl = `https://www.google.com/maps?q=${values.latitude},${values.longitude}`;
                                                                    window.open(googleMapsUrl, '_blank');
                                                                }
                                                            })
                                                            .catch((error) => console.error(error));
                                                    }}>
                                                Map
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-container">
                                    <Field fullWidth variant="outlined" name="latitude" type={"text"}
                                           component={TextField} label="Latitude*" autoComplete="off"
                                           InputLabelProps={{shrink: true}}
                                           id="input_facility_add_latitude"/>
                                    <Field fullWidth variant="outlined" name="longitude" type={"text"}
                                           component={TextField} label="Longitude*" autoComplete="off"
                                           InputLabelProps={{shrink: true}}
                                           id="input_facility_add_longitude"/>
                                </div>
                                <div className="input-container">
                                    <Field variant="outlined" name="website_url" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="Website" fullWidth autoComplete="off"
                                           id="input_facility_edit_website_url"/>
                                    <Field fullWidth variant="outlined" name="parent_company" type={"text"}
                                           component={TextField} InputLabelProps={{shrink: true}}
                                           label="SNF Parent Company" autoComplete="off"
                                           id="input_facility_add_SNF_Parent_company"/>
                                </div>

                                <div className="facility-about mrg-top-20">
                                    <Typography color="textPrimary">About the Facility</Typography>
                                    <Field variant="outlined" component={TextField} type={"text"} name="about" fullWidth
                                           InputLabelProps={{shrink: true}}
                                           multiline rows={2} id="input_facility_edit_about"/>
                                </div>

                                <div style={{display: "flex", justifyContent: 'space-between'}}>
                                    <div>
                                        <p className={'card-header facility-image-header'}>Upload Facility Image</p>
                                        {facilityDetails?.profile_url ?
                                            <div className="attachments">
                                                <div className="facility-edit-file">
                                                    <div className="d-flex">
                                                        <div className="facility-edit-image-wrapper">
                                                            <img src={facilityDetails?.profile_url} alt=""
                                                                 className={'facility-edit-image'}/>{" "}
                                                        </div>
                                                        <div className="file_details mrg-left-20"></div>
                                                    </div>
                                                    <div className={'file-preview-content'}>
                                                        <div className={'file-preview-btn-wrapper'}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                onClick={deleteFacilityImage}
                                                                style={{marginRight: "8px"}}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                        <Typography
                                                            variant="caption"
                                                            display="block"
                                                            style={{marginTop: "8px", color: "#888"}}
                                                        >
                                                            File type: ".jpg,.png,.jpeg"
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {fileUpload && fileUpload?.wrapper.length >= 1 ?
                                                    <FilePreviewComponent
                                                        allowedTypes={".jpg,.png,.jpeg"}
                                                        uploadedFile={fileUpload?.wrapper}
                                                        previewFile={previewFile}
                                                        deleteFile={deleteFile}
                                                    /> :
                                                    <FileUploadComponent
                                                        onFileSelected={onFileSelected}
                                                        allowedTypes={".jpg,.png,.jpeg"}
                                                    />
                                                }
                                            </>
                                        }
                                    </div>
                                    <div>
                                        <p className={'card-header facility-image-header'}>Upload Facility Contract</p>
                                        {facilityDetails?.contract_details && facilityDetails?.contract_details?.url ?
                                            <div className="attachments">
                                                <div className="facility-edit-file">
                                                    <div className="d-flex">
                                                        <div className="facility-edit-image-wrapper">
                                                            {facilityDetails?.contract_details?.ContentType === "image/png" || facilityDetails?.contract_details?.ContentType === "image/jpeg" || facilityDetails?.contract_details?.ContentType === "image/jpg" ? <>
                                                                <img src={facilityDetails?.contract_details?.url} alt=""
                                                                     className={'facility-edit-image'}
                                                                />
                                                            </> : <>
                                                                <img src={PdfIcon} alt=""
                                                                     className={'facility-edit-image'}/>
                                                            </>}
                                                        </div>
                                                        <div className="file_details mrg-left-20"></div>
                                                    </div>
                                                    <div className={'file-preview-content'}>
                                                        <div className={'file-preview-btn-wrapper'}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                onClick={deleteFacilityContractImage}
                                                                style={{marginRight: "8px"}}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                        <Typography
                                                            variant="caption"
                                                            display="block"
                                                            style={{marginTop: "8px", color: "#888"}}
                                                        >
                                                            File type: ".jpg,.png,.jpeg,.pdf"
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {contractFileUpload && contractFileUpload?.wrapper?.length >= 1 ?
                                                    <FilePreviewComponent
                                                        allowedTypes={".jpg,.png,.jpeg,.pdf"}
                                                        uploadedFile={contractFileUpload?.wrapper}
                                                        previewFile={previewContractFile}
                                                        deleteFile={deleteContractFile}
                                                    /> :
                                                    <FileUploadComponent
                                                        onFileSelected={OnContractFileSelected}
                                                        allowedTypes={".jpg,.png,.jpeg,.pdf"}
                                                    />}
                                            </>
                                        }


                                    </div>
                                    <div>
                                        <p className={'card-header facility-image-header'}>Others</p>
                                        <FileUploadComponent
                                            onFileSelected={OnOtherFileSelected}
                                            allowedTypes={".pdf"}
                                        />
                                    </div>
                                </div>
                                {
                                    combinedDocuments.length > 0 && (
                                        <div className="mrg-top-20">
                                            <AntTableComponent
                                                columns={[
                                                    {
                                                        title: 'Document Name',
                                                        width: 300,
                                                        render: (item: any, index: number) => (
                                                            <div
                                                                style={{cursor: 'pointer', textDecoration: 'underline'}}
                                                                onClick={() =>
                                                                    item.source === 'api'
                                                                        ? previewOtherUploadedFile(item, index)
                                                                        : previewOtherFile(item, item.index, 'attachment')
                                                                }
                                                            >
                                                                {item.name || 'N/A'}
                                                            </div>
                                                        ),
                                                    },
                                                    {
                                                        title: 'Delete',
                                                        width: 50,
                                                        render: (item: any) => (
                                                            <div className="display-center">
                                                                <IconButton
                                                                    onClick={(e) =>
                                                                        item.source === 'api'
                                                                            ? openDeleteAttachment(e, item)
                                                                            : deleteOtherFile(item.index, item.index)
                                                                    }
                                                                >
                                                                    <DeleteIcon className="delete-icon"/>
                                                                </IconButton>
                                                            </div>
                                                        ),
                                                    },
                                                ]}
                                                extraPayload={combinedDocuments}
                                                data={combinedDocuments}
                                                isRowSelection={false}
                                            />
                                        </div>
                                    )
                                }
                            </div>

                            <div className="notification-preferences custom-border mrg-top-10">
                                <p className="card-header">Notification Preferences</p>
                                <div className="notification-preferences-container">
                                    <Field type="checkbox" component={CheckboxWithLabel}
                                           name="notification_preferences.sms"
                                           Label={{label: "SMS", style: {color: 'black'}}}/>
                                    <Field type="checkbox" component={CheckboxWithLabel}
                                           name="notification_preferences.email"
                                           Label={{label: "Email", style: {color: 'black'}}}/>
                                    <Field type="checkbox" component={CheckboxWithLabel}
                                           name="notification_preferences.push_notification"
                                           Label={{label: "In-application", style: {color: 'black'}}}/>
                                </div>
                            </div>

                            <div className="facility-basic-details custom-border mrg-top-10">
                                <div className={'d-flex justify-content-space-between mrg-bottom-20'}>
                                    <div className="card-header mrg-top-5">Mandatory Documents</div>
                                    <div>
                                        <Button variant={"contained"} color={'primary'} onClick={handleOpenModal}>
                                            Add New Document
                                        </Button>
                                    </div>
                                </div>
                                <Row gutter={[16, 16]}>
                                    {facilityDocuments?.map((doc: any, index: any) => (
                                        <Col xs={32} sm={16} md={8} key={doc.key}>
                                            <DocumentToggle initialChecked={documentStates[doc.key]} id={doc.key}
                                                            disabled={doc.title === "License" || doc.is_mandatory}
                                                            label={`${index + 1}. ${doc.title === 'License' ? "Nursing License" : doc.title}`}
                                                            onChange={handleToggleChange}/>
                                        </Col>
                                    ))}
                                </Row>
                            </div>

                            <div className="facility-basic-details custom-border mrg-top-10">
                                {renderSection("Required Certifications:", "certifications", "Add Certificates")}
                                {renderSection("Required License:", "licenses", "Add Licenses")}
                                {renderSection("Required Skill Checklist:", "skills", "Add Skills")}
                                {renderSection("Required Competency Test:", "competency_test", "Add Competency Test")}
                                <AddDocumentDrawer
                                    visible={isDrawerVisible}
                                    onClose={closeDrawer}
                                    onUpdate={(selectedItems: any) => updateSection(activeSection, selectedItems)}
                                    selectedItems={sectionState[activeSection]}
                                    availableOptions={availableOptions[activeSection]}
                                />
                            </div>

                            <div className="facility-other-details mrg-top-10  custom-border">
                                <p className="card-header">Bill Rate Details</p>

                                <div className="input-container ">
                                    <Field variant="outlined" name="hourly_base_rates.cna" type={"text"}
                                           InputLabelProps={{shrink: true}}
                                           component={TextField} label="CNA Rate ($/hr)*" fullWidth autoComplete="off"
                                           id="input_facility_add_hourly_base_rates_cna" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("hourly_base_rates.cna", e.target.value);
                                        }
                                    }}/>
                                    <Field variant="outlined" name="hourly_base_rates.lvn" type={"text"}
                                           InputLabelProps={{shrink: true}}
                                           component={TextField} label="LVN Rate ($/hr)*" fullWidth autoComplete="off"
                                           id="input_facility_add_hourly_base_rates_lvn" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("hourly_base_rates.lvn", e.target.value);
                                        }
                                    }}/>
                                    <Field variant="outlined" name="diff_rates.noc" type={"text"} component={TextField}
                                           label="NOC Diff Rate ($/hr)*" fullWidth autoComplete="off"
                                           InputLabelProps={{shrink: true}}
                                           id="input_facility_add_diff_rates_noc" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("diff_rates.noc", e.target.value);
                                        }
                                    }}/>
                                    <Field variant="outlined" name="hourly_base_rates.hazard" type={"text"}
                                           component={TextField} label="Hazard Rate ($/hr)*" fullWidth
                                           InputLabelProps={{shrink: true}}
                                           autoComplete="off"
                                           id="input_facility_add_hourly_base_rates_hazard" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("hourly_base_rates.hazard", e.target.value);
                                        }
                                    }}/>
                                </div>

                                <div className="input-container ">
                                    <Field variant="outlined" name="diff_rates.pm" type={"text"} component={TextField}
                                           label="PM Diff Rate ($/hr)*" fullWidth autoComplete="off"
                                           InputLabelProps={{shrink: true}}
                                           id="input_facility_add_diff_rates_pm" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("diff_rates.pm", e.target.value);
                                        }
                                    }}/>
                                    <Field variant="outlined" name="diff_rates.weekend" type={"text"}
                                           component={TextField} InputLabelProps={{shrink: true}}
                                           label="Weekend Rate ($/hr)*" fullWidth autoComplete="off"
                                           id="input_facility_add_diff_rates_weekend" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("diff_rates.weekend", e.target.value);
                                        }
                                    }}/>
                                    <Field variant="outlined" name="hourly_base_rates.rn" type={"text"}
                                           InputLabelProps={{shrink: true}}
                                           component={TextField} label="RN Rate ($/hr)*" fullWidth autoComplete="off"
                                           id="input_facility_add_hourly_base_rates_rn" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("hourly_base_rates.rn", e.target.value);
                                        }
                                    }}/>


                                    <Field variant="outlined" name="hourly_base_rates.holiday" type={"text"}
                                           InputLabelProps={{shrink: true}}
                                           component={TextField} label="Holiday Multiplier Rate*" fullWidth
                                           autoComplete="off"
                                           id="input_facility_add_hourly_base_rates_holiday" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("hourly_base_rates.holiday", e.target.value);
                                        }
                                    }}/>
                                </div>
                                <div className={'optional-field-wrapper'}>
                                    {optionalFields?.map((field: any) => (
                                        field.name === 'conditional_rates.overtime.hours' ? (
                                            <Field
                                                SelectProps={showDropDownBelowField}
                                                variant="outlined"
                                                name={field.name}
                                                type="text"
                                                component={TextField}
                                                select
                                                label={field.label || 'OT Hours (hr/day)'}
                                                fullWidth
                                                autoComplete="off"
                                                id={field.id || 'input_' + field.name}
                                                InputLabelProps={{shrink: true}}
                                            >
                                                <MenuItem>Select</MenuItem>
                                                {otHours &&
                                                    otHours.map((item: any, index: any) => (
                                                        <MenuItem value={item.value} key={index}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        ) : (
                                            <Field
                                                key={field.id}
                                                variant="outlined"
                                                name={field.name || ''}
                                                type="text"
                                                component={TextField}
                                                label={field.label}
                                                fullWidth
                                                autoComplete="off"
                                                id={field.id}
                                                InputLabelProps={{shrink: true}}
                                                onChange={(e: any) => {
                                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                                        const value = e.target.value;
                                                        setFieldValue(field.name, value ? value : "");
                                                    }
                                                }}
                                            />
                                        )
                                    ))}
                                </div>

                                <div className={'add-fields-wrapper'} onClick={(event: any) => {
                                    setOpenDrawer(true)
                                }}>
                                    <ImageConfig.AddIcon/>
                                    <p>Add fields</p>
                                </div>
                            </div>
                            <div className="facility-other-details mrg-top-10 custom-border">
                                <p className="card-header">Pay Rate Details</p>

                                {/*REQUIRED FIELDS*/}


                                <div className="input-container ">
                                    <Field variant="outlined" name="pay_hourly_base_rates.cna" type={"text"}
                                           component={TextField} label="CNA Rate ($/hr)*" fullWidth autoComplete="off"
                                           InputLabelProps={{shrink: true}}
                                           id="input_facility_add_pay_hourly_base_rates_cna" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("pay_hourly_base_rates.cna", e.target.value);
                                        }
                                    }}/>
                                    <Field variant="outlined" name="pay_hourly_base_rates.lvn" type={"text"}
                                           InputLabelProps={{shrink: true}}
                                           component={TextField} label="LVN Rate ($/hr)*" fullWidth autoComplete="off"
                                           id="input_facility_add_pay_hourly_base_rates_lvn" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("pay_hourly_base_rates.lvn", e.target.value);
                                        }
                                    }}/>
                                    <Field variant="outlined" name="pay_diff_rates.noc" type={"text"}
                                           component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="NOC Diff Rate ($/hr)*" fullWidth autoComplete="off"
                                           id="input_facility_add_pay_diff_rates_noc" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("pay_diff_rates.noc", e.target.value);
                                        }
                                    }}/>
                                    <Field variant="outlined" name="pay_hourly_base_rates.hazard" type={"text"}
                                           InputLabelProps={{shrink: true}}
                                           component={TextField} label="Hazard Rate ($/hr)*" fullWidth
                                           autoComplete="off"
                                           id="input_facility_add_pay_hourly_base_rates_hazard" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("pay_hourly_base_rates.hazard", e.target.value);
                                        }
                                    }}/>
                                </div>

                                <div className="input-container ">
                                    <Field variant="outlined" name="pay_diff_rates.pm" type={"text"}
                                           component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="PM Diff Rate ($/hr)*" fullWidth autoComplete="off"
                                           id="input_facility_add_pay_diff_rates_pm" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("pay_diff_rates.pm", e.target.value);
                                        }
                                    }}/>
                                    <Field variant="outlined" name="pay_diff_rates.weekend" type={"text"}
                                           InputLabelProps={{shrink: true}}
                                           component={TextField}
                                           label="Weekend Rate ($/hr)*" fullWidth autoComplete="off"
                                           id="input_facility_add_pay_diff_rates_weekend" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("pay_diff_rates.weekend", e.target.value);
                                        }
                                    }}/>
                                    <Field variant="outlined" name="pay_hourly_base_rates.rn" type={"text"}
                                           InputLabelProps={{shrink: true}}
                                           component={TextField} label="RN Rate ($/hr)*" fullWidth autoComplete="off"
                                           id="input_facility_add_pay_hourly_base_rates_rn" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("pay_hourly_base_rates.rn", e.target.value);
                                        }
                                    }}/>

                                    <Field variant="outlined" name="pay_hourly_base_rates.holiday" type={"text"}
                                           component={TextField} label="Holiday Multiplier Rate*" fullWidth
                                           autoComplete="off"
                                           InputLabelProps={{shrink: true}}
                                           id="input_facility_add_pay_hourly_base_rates_holiday" onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("pay_hourly_base_rates.holiday", e.target.value);
                                        }
                                    }}/>
                                </div>

                                {/*REQUIRED FIELDS*/}

                                {/*-----OPTIONAL FIELDS----*/}
                                <div className={'optional-field-wrapper'}>
                                    {payRateOptionalFields?.map((field: any) => (
                                        field.name === 'pay_conditional_rates.overtime.hours' ? (
                                            <Field
                                                SelectProps={showDropDownBelowField}
                                                variant="outlined"
                                                name={field.name}
                                                type="text"
                                                component={TextField}
                                                select
                                                label={field.label || 'OT Hours (hr/day)'}
                                                fullWidth
                                                autoComplete="off"
                                                id={field.id || 'input_' + field.name}
                                                InputLabelProps={{shrink: true}}
                                            >
                                                <MenuItem>Select</MenuItem>
                                                {otHours &&
                                                    otHours.map((item: any, index: any) => (
                                                        <MenuItem value={item.value} key={index}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        ) : (
                                            <Field
                                                key={field.id}
                                                variant="outlined"
                                                name={field.name || ''}
                                                type="text"
                                                component={TextField}
                                                label={field.label}
                                                fullWidth
                                                autoComplete="off"
                                                id={field.id}
                                                InputLabelProps={{shrink: true}}
                                                onChange={(e: any) => {
                                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                                        const value = e.target.value;
                                                        setFieldValue(field.name, value ? value : "");
                                                    }
                                                }}
                                            />
                                        )
                                    ))}
                                </div>

                                <div className={'add-fields-wrapper'} onClick={(event: any) => {
                                    setOpenPayRatesDrawer(true)
                                }}>
                                    <ImageConfig.AddIcon/>
                                    <p>Add fields</p>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
};

export default FacilityEditDetailsComponent;
