import "./MatchingApplicantComponent.scss";
import FormLabelComponent from "../../../../ant-component/form-label/FormLabelComponent";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import SelectDropdownComponent from "../../../../ant-component/select/SelectComponent";
import {Button, Checkbox, CheckboxProps, TableColumnsType} from "antd";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../../store/reducers";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";
import {CommonService} from "../../../../../helpers";

interface MatchingApplicantComponentProps{
    jobDetails?:any;
}

const MatchingApplicantComponent=(props:MatchingApplicantComponentProps)=>{
    const {jobDetails}=props;
    const {regions,hcpTypes}=useSelector((state:StateParams)=>state.meta);
    const [certificationList,setCertificationList]=useState<any>([]);
    const [licenseList,setLicenseList]=useState<any>([]);
    const [selectedState,setSelectedState]=useState<any>([]);
    const [selectedProfession,setSelectedProfession]=useState<any>([]);
    const [selectedCertificationType,setSelectedCertificationType]=useState<any>([]);
    const [selectedLicenseType,setSelectedLicenseType]=useState<any>([]);
    const [isExpiredCertificateChecked,setIsExpiredCertificateChecked]=useState<boolean>(false);
    const [isExpiredLicensedChecked,setIsExpiredLicensedChecked]=useState<boolean>(false);
    const [matchingApplicantsList]=useState<any>([]);

    useEffect(() => {
        if(jobDetails?.required_credentials && jobDetails?.required_credentials?.certifications_submission){
            const updatedList=jobDetails?.required_credentials?.certifications_submission?.map((list:any)=>({
                title:list,
                code:list,
            }))
            setCertificationList(updatedList || [])
        }
    }, [jobDetails]);

    useEffect(() => {
        if(jobDetails?.required_credentials && jobDetails?.required_credentials?.license_submission){
            const updatedList=jobDetails?.required_credentials?.license_submission?.map((list:any)=>({
                title:list,
                code:list,
            }))
            setLicenseList(updatedList || [])
        }
    }, [jobDetails]);

    const handleCertificateChecked: CheckboxProps['onChange'] = (e) => {
        setIsExpiredCertificateChecked(e.target.checked);
    };

    const handleLicenseChecked: CheckboxProps['onChange'] = (e) => {
        setIsExpiredLicensedChecked(e.target.checked);
    };

    const handleResetSearch=useCallback(()=>{
        setIsExpiredLicensedChecked(false);
        setIsExpiredCertificateChecked(false);
        setSelectedLicenseType([]);
        setSelectedState([]);
        setSelectedProfession([]);
        setSelectedCertificationType([]);
        setSelectedLicenseType([]);
    },[])

    const applicantColumnList:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'Name',
                width:150,
                render:()=>{
                    return (<div></div>)
                }
            },
            {
                title:'Profession',
                width:150,
                render:()=>{
                    return <div></div>
                }
            },
            {
                title:'Submitted By',
                width:150,
                render:()=><></>
            },
            {
                title:'State',
                width:120,
                render:()=><></>
            },
            {
                title:'Certification Type',
                width:180,
                render:()=><></>
            },
            {
                title:'License Type',
                width:180,
                render:()=><></>
            },
            {

            }
        ]
    },[])

    return (
        <div className={"matching-applicant-component"}>
            <div className={'matching-applicant-field-wrapper'}>
                <div className={'matching-applicant-field-title'}>Search Matching Applicants:</div>
                <div>
                    <FormLabelComponent Label={"Profession"}/>
                    <SelectDropdownComponent
                        className={'mrg-top-5'}
                        options={hcpTypes ||  []}
                        mode={'multiple'}
                        displayWith={(option:any)=>option.title}
                        value={selectedProfession}
                        placeholder={'Select Profession'}
                        onChange={(value)=>{
                            setSelectedProfession(value)
                        }}
                    />
                </div>
                <div>
                    <FormLabelComponent Label={"State"}/>
                    <SelectDropdownComponent
                        className={'mrg-top-5'}
                        options={regions ||  []}
                        mode={'multiple'}
                        displayWith={(option:any)=>option.title}
                        value={selectedState}
                        placeholder={'Select State'}
                        onChange={(value:any)=>{
                            setSelectedState(value)
                        }}
                    />
                </div>
                <div>
                    <FormLabelComponent Label={"Certification Type"}/>
                    <SelectDropdownComponent
                        className={'mrg-top-5'}
                        options={certificationList || []}
                        mode={'multiple'}
                        placeholder={'Select Certification Type'}
                        displayWith={(option:any)=>option.title}
                        value={selectedCertificationType}
                        onChange={(value:any)=>{
                            setSelectedCertificationType(value)
                        }}
                    />
                </div>
                <div className={'d-flex'}>
                    <Checkbox
                        checked={isExpiredCertificateChecked}
                        onChange={handleCertificateChecked}/>
                    <div className={'mrg-left-10 mrg-top-15'}>Do not show applicants with expired certificates</div>
                </div>
                <div>
                    <FormLabelComponent Label={"License Type"}/>
                    <SelectDropdownComponent
                        className={'mrg-top-5'}
                        options={licenseList || []}
                        value={selectedLicenseType}
                        mode={'multiple'}
                        placeholder={'Select License Type'}
                        displayWith={(option:any)=>option.title}
                        onChange={(value:any)=>{
                            setSelectedLicenseType(value)
                        }}
                    />
                </div>
                <div className={'d-flex'}>
                    <Checkbox checked={isExpiredLicensedChecked} onChange={handleLicenseChecked}/>
                    <div className={'mrg-left-15'}>Do not show applicants with expired licenses</div>
                </div>
                <div className={'d-flex align-items-center justify-content-center mrg-top-20'}>
                    <Button onClick={handleResetSearch}>Reset Search</Button>
                    <Button
                        className={'mrg-left-20 next-button'}
                        onClick={()=>{
                            CommonService.showToast('Coming Soon!','info')
                        }}>Search</Button>
                </div>
            </div>
            <div className={'matching-applicant-table-wrapper'}>
                <AntTableComponent
                    data={matchingApplicantsList || []}
                    columns={applicantColumnList}
                    noDataText={'No Matching Applicant Found.'}
                />
            </div>
        </div>
    )
}
export default MatchingApplicantComponent