import {Button, Tooltip} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import AccessControlComponent from "../../../components/AccessControl";
import DialogComponent from "../../../components/DialogComponent";
import LoaderComponent from "../../../components/LoaderComponent";
import {ENV} from "../../../constants";
import {Communications} from "../../../helpers";
import CommonService, {ACCOUNTMANAGER, ADMIN, HUMANRESOURCE,TERICADMIN,RECRUITER,HRMANAGER} from "../../../helpers/common-service";
import AssignToNcComponent from "../assignToNc/AssignToNcComponent";
import HcpDetailsComponent from "./details/HcpDetailsComponent";
import HcpEducationComponent from "./education/HcpEducationComponent";
import HcpExperienceComponent from "./experience/HcpExperienceComponent";
import "./HcpManagementViewScreen.scss";
import HcpReferenceComponent from "./reference/HcpReferenceComponent";
import RejectHcpComponent from "./rejectHcp/RejectHcpComponent";
import HcpVolunteerExperienceComponent from "./volunteerExperience/HcpVolunteerExperienceComponent";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import HcpNoteComponent from "../approvedhcpview/hcp-note/HcpNoteComponent";
import RequiredDocumentListComponent from "../../ats/Job/Job-list/required-document/RequiredDocumentListComponent";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const HcpManagementViewScreen = () => {
    const classes = useStyles();
    const param = useParams<any>();
    const {id} = param;
    const [hcpBasicDetails, setBasicDetails] = useState<any | null>(null);
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [isRejectOpen, setIsRejectOpen] = useState<boolean>(false);
    const [value, setValue] = useState<any>(0);


    const handleChanges = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const init = useCallback(() => {
        // config
        CommonService._api
            .get(ENV.API_URL + "hcp/" + id)
            .then((resp) => {
                setBasicDetails(resp.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [id]);

    useEffect(() => {
        init();
        Communications.pageTitleSubject.next("Staff Details");
        Communications.pageBackButtonSubject.next("/hcp/list");
    }, [init]);

    const openAdd = useCallback(() => {
        setIsAddOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        setIsAddOpen(false);
        init();
    }, [init]);

    const openRejectHcp = useCallback(() => {
        setIsRejectOpen(true);
    }, []);

    const cancelRejectHcp = useCallback(() => {
        setIsRejectOpen(false);
    }, []);

    const confirmRejectHcp = useCallback(() => {
        setIsRejectOpen(false);
        init();
    }, [init]);

    if (isLoading) {
        return <LoaderComponent/>;
    }

    return (
        <Box sx={{width: '100%'}}>
            <Box style={{borderBottom: "1px solid #e4e9ea"}}>
                <Tabs value={value} onChange={(e: any, value: any) => {
                    handleChanges(e, value);
                }
                } aria-label="tab"
                      TabIndicatorProps={{
                          style: {
                              backgroundColor: "#56ebff",
                          }
                      }}
                      style={{
                          color: "black",
                      }}>
                    <Tab label="About" {...a11yProps(0)} />
                    {/*disabled={user?.role === "hr" || user?.role === "operational_manager"}*/}
                    <Tab label="Notes" {...a11yProps(1)} />
                    <Tab label="Documents" {...a11yProps(2)}/>
                </Tabs>
            </Box>
            <div className="hcp_view_details crud-layout">
                <TabPanel value={value} index={0}>
                    {!isLoading && (
                        <>
                            <DialogComponent open={isAddOpen} cancel={cancelAdd} headerLabel={'Assign to NC'}>
                                <AssignToNcComponent cancel={cancelAdd} confirm={confirmAdd} from={'staff'}/>
                            </DialogComponent>
                            <DialogComponent open={isRejectOpen} cancel={cancelRejectHcp} headerLabel={'Rejection Request'}>
                                <RejectHcpComponent cancel={cancelRejectHcp} confirm={confirmRejectHcp}/>
                            </DialogComponent>
                            <div className="">
                                <div className="d-flex profile-status-wrapper">
                                    <div>
                                        <AccessControlComponent role={[HUMANRESOURCE, ADMIN,TERICADMIN,RECRUITER,HRMANAGER]}>
                                            {hcpBasicDetails?.status === "pending" ? (
                                                <Tooltip title="Approve Hcp">
                                                    <Button color="primary" variant={"outlined"} onClick={openAdd}
                                                            className="mrg-right-20">
                                                        Approve
                                                    </Button>
                                                </Tooltip>
                                            ) : (
                                                <></>
                                            )}
                                            {hcpBasicDetails?.status === "pending" ? (
                                                <Tooltip title="Reject Hcp">
                                                    <Button color="primary" variant={"outlined"} className="mrg-right-20"
                                                            onClick={openRejectHcp}>
                                                        Reject
                                                    </Button>
                                                </Tooltip>
                                            ) : (
                                                <></>
                                            )}
                                        </AccessControlComponent>
                                        <AccessControlComponent role={[ACCOUNTMANAGER, ADMIN, HUMANRESOURCE,TERICADMIN,RECRUITER,HRMANAGER]}>
                                            <Tooltip title="Edit Staff">
                                                <Button variant={"contained"} color={"primary"}
                                                        component={Link}
                                                        to={`/hcp/edit/${id}`}>
                                                    Edit Staff
                                                </Button>
                                            </Tooltip>
                                        </AccessControlComponent>
                                    </div>
                                </div>
                                <div className="mrg-top-15">
                                    <HcpDetailsComponent hcpBasicDetails={hcpBasicDetails}/>
                                </div>
                                <div className="custom-border mrg-top-10 pdd-20">
                                    <Accordion expanded={expanded === "Education"} onChange={handleChange("Education")}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                                          aria-controls="panel1bh-content"
                                                          id="panel1bh-header">
                                            <Typography className={classes.heading}>Education</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={classes.root}>
                                                <HcpEducationComponent educationDetails={hcpBasicDetails}/>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className="custom-border mrg-top-10 pdd-20">
                                    <Accordion expanded={expanded === "Experience"}
                                               onChange={handleChange("Experience")}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                                          aria-controls="panel1bh-content"
                                                          id="panel1bh-header">
                                            <Typography className={classes.heading}>Work Experience</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={classes.root}>
                                                <HcpExperienceComponent experience={hcpBasicDetails?.experience}/>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className="custom-border mrg-top-10 pdd-20">
                                    <Accordion expanded={expanded === "Volunteer_Experience"}
                                               onChange={handleChange("Volunteer_Experience")}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                                          aria-controls="panel1bh-content"
                                                          id="panel1bh-header">
                                            <Typography className={classes.heading}>Volunteer Experience</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={classes.root}>
                                                <HcpVolunteerExperienceComponent
                                                    volunteerExperience={hcpBasicDetails?.experience}/>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className="custom-border mrg-top-10 pdd-20">
                                    <Accordion expanded={expanded === "Reference"} onChange={handleChange("Reference")}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                                          aria-controls="panel1bh-content"
                                                          id="panel1bh-header">
                                            <Typography className={classes.heading}>Reference</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={classes.root}>
                                                <HcpReferenceComponent referenceDetails={hcpBasicDetails?.reference}/>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        </>
                    )}
                    <ScrollToTop smooth color="white"/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <HcpNoteComponent id={id} hcpBasicDetails={hcpBasicDetails}/>
                </TabPanel>
                <TabPanel index={2} value={value}>
                    <div className={'required-documents-staff-wrapper'}>
                        <RequiredDocumentListComponent module={'hcp'} hcpId={id}/>
                    </div>
                </TabPanel>
            </div>
        </Box>
    );
};

export default HcpManagementViewScreen;
