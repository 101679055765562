import "./RequiredDocumentListComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import LoaderComponent from "../../../../../components/loader/LoaderComponent";
import {Button, DatePicker, Divider, Modal, Upload,Tooltip} from "antd";
import {UploadOutlined,EyeOutlined} from "@ant-design/icons";
import type {RcFile} from "antd/es/upload";
import moment from "moment/moment";
import dayjs from "dayjs";
import CustomPreviewFile from "../../../../../components/shared/CustomPreviewFile";

interface RequiredDocumentListComponentProps{
    hcpId?:string;
    jobId?:string;
    setRefreshToken?:any;
    module?:string;
}

const RequiredDocumentListComponent=(props:RequiredDocumentListComponentProps)=>{
    const {hcpId,jobId,setRefreshToken,module='job'}=props;
    const [documentList, setDocumentList] = useState<any>({})
    const [receivedDocuments,setReceivedDocuments]=useState<any>('');
    const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<any>({})
    const [expiryDates,setExpiryDates]=useState<any>({});
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<any>();


    const handlePreview = useCallback((fileUrl: any) => {
        setSelectedFile(fileUrl);
        setIsModalVisible(true);
    }, []);


    const getDocumentList = useCallback(() => {
        // if (!jobId || !hcpId) return;
        setIsDocumentLoading(true);
        let apiCall:any;
        if(module === 'job'){
            apiCall=CommonService._api.get(ENV.API_URL + `ats/job/${jobId}/hcp/${hcpId}`)
        }else{
            let payload:any={
                hcp_id:hcpId
            }
            apiCall=CommonService._api.get(ENV.API_URL + `hcp/${hcpId}/hcpDocsView`,payload)
        }

        apiCall.then((response:any) => {
            setDocumentList(response?.data?.required_credentials);
            setReceivedDocuments(response?.data?.received_documents);
        }).catch((error: any) => {
            CommonService.showToast(error.error, 'error')
        }).finally(() => {
            setIsDocumentLoading(false);
        })
    }, [jobId, hcpId,module])

    useEffect(() => {
        getDocumentList();
    }, [getDocumentList]);

    const handleDateChange = useCallback((date: dayjs.Dayjs | null, item: string) => {
        console.log(date,item)
        setExpiryDates((prev: any) => ({
            ...prev,
            [item]: date ? date.format("YYYY-MM-DD") : null,
        }));
    }, []);


    const handleUpload = useCallback((file: RcFile, submissionType, attachmentType) => {
        const formData = new FormData();
        formData.append('attachment_type', attachmentType);
        formData.append('expiry_date', expiryDates[attachmentType] ? moment(expiryDates[attachmentType]).format('MM-DD-YYYY') : '');
        formData.append('attachment', file);
        formData.append('submission_type', submissionType);
        setIsUploading((prevState: any) => ({
            ...prevState,
            [attachmentType]: true
        }))
        let apiCall:any;
        if(module === 'job'){
            apiCall=CommonService._api.upload(ENV.API_URL + `job/${jobId}/hcp/${hcpId}`, formData);
        }else {
            apiCall=CommonService._api.upload(ENV.API_URL + "hcp/" + hcpId + "/attachment", formData)
        }
        apiCall.then((response: any) => {
            if(module === 'job') {
                setRefreshToken((prev:number)=>prev+1)
            }
            getDocumentList();
            CommonService.showToast(response.message || 'Document Uploaded Successfully', 'success')
        }).catch((error: any) => {
            CommonService.showToast(error.error, 'error');
        }).finally(() => {
            setIsUploading((prevState: any) => ({
                ...prevState,
                [attachmentType]: false
            }))
        })
    }, [jobId,module,hcpId,setRefreshToken,expiryDates,getDocumentList])

    const renderReceivedSection = (submissionType: string, items: any) => {
        if (!items || items.length === 0) return null;
        const title = submissionType?.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        return (
            <div key={submissionType} className={'mrg-top-20'}>
                <div className={'submission-title mrg-bottom-10'}>{title}</div>
                {items.map((item: any, index: number) => (
                    <div className={'document-card'} key={index}>
                        <div className={'document-attachment-type'}>{item?.attachment_type}</div>
                        <div className={'document-attachment-btn'}>
                            <Tooltip title={'Preview'}>
                                <Button icon={<EyeOutlined />}
                                        className={'custom-outline-button'}
                                        onClick={()=>handlePreview(item)}
                                >
                                </Button>
                            </Tooltip>
                            {/*<Tooltip title={'Download'}>*/}
                            {/*    <Button type="primary" icon={<DownloadOutlined />}*/}
                            {/*            className={'custom-primary-button mrg-left-10'}*/}
                            {/*            onClick={()=>handleDownload(item?.s3_url)}*/}
                            {/*    >*/}
                            {/*    </Button>*/}
                            {/*</Tooltip>*/}
                        </div>
                    </div>
                ))}
                <Divider/>
            </div>
        );
    };

    const renderSubmissionSection = (submissionType: string, items: any) => {
        if (!items || items.length === 0) return null;
        const title = submissionType?.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        return (
            <div key={submissionType} className={'mrg-top-20'}>
                <div className={'submission-title mrg-bottom-10'}>{title}</div>
                {items.map((item: any, index: number) => (
                    <div className={'document-card'} key={index}>
                        <div className={'document-attachment-type'}>{item}</div>
                        <div className={'document-attachment-btn'}>
                            <DatePicker
                                placeholder={'Expiry Date'}
                                disabledDate={(current) => current && current < dayjs().startOf('day')}
                                value={expiryDates[item] ? dayjs(expiryDates[item]) : null}
                                onChange={(date)=>handleDateChange(date,item)}
                                className={'mrg-right-20'}
                                getPopupContainer={(triggerNode:any)=>triggerNode.parentNode}
                            />
                            <Upload
                                beforeUpload={(file) => handleUpload(file, submissionType, item)}
                                showUploadList={false}
                                accept=".pdf,.doc,.docx,.jpg,.png"
                            >
                                <Button type="primary" icon={<UploadOutlined/>} loading={isUploading[item]}
                                        className={'custom-primary-button'}>
                                    Upload
                                </Button>
                            </Upload>
                        </div>
                    </div>
                ))}
                <Divider/>
            </div>
        );
    };

    const allReceivedDocumentsEmpty =
        receivedDocuments?.certifications_submission?.length === 0 &&
        receivedDocuments?.documents_submission?.length === 0 &&
        receivedDocuments?.license_submission?.length === 0 &&
        receivedDocuments?.skill_checklist_submission?.length === 0 &&
        receivedDocuments?.competency_test?.length === 0;

    return (
        <div className={'required-documents-wrapper'}>
            <Modal
                zIndex={999999999}
                title="Document Preview"
                open={isModalVisible}
                footer={null}
                onCancel={() => {
                    setIsModalVisible(false)
                    setSelectedFile(undefined)
                }}
                width={800}
            >
                <CustomPreviewFile
                    previewData={{
                        url: selectedFile?.s3_url,
                        file_type: selectedFile?.file_type,
                    }}/>
            </Modal>
            {isDocumentLoading ?
                <div className={'loading-container'}>
                    <LoaderComponent type={'spinner'} color={'primary'}/>
                </div>
                :
                <div>
                    <>
                        <div className={'heading'}>Received Documents</div>
                                {Object.keys(receivedDocuments)?.length === 0 ?
                                    <div style={{color: 'black',borderBottom:'1px solid lightgray',marginBottom:'10px',textAlign:'center', paddingBottom:'10px'}}>
                                        No Documents Received Yet!
                                    </div> :
                                    <>
                                    <Divider/>
                                    <div className={'document-list'}>
                                        {allReceivedDocumentsEmpty  ?
                                            <div>
                                                No Documents Received.
                                            </div>:
                                            <>
                                                {renderReceivedSection('documents_submission', receivedDocuments?.documents_submission)}
                                                {renderReceivedSection('certifications_submission', receivedDocuments?.certifications_submission)}
                                                {renderReceivedSection('license_submission', receivedDocuments?.license_submission)}
                                                {renderReceivedSection('skill_checklist_submission', receivedDocuments?.skill_checklist_submission)}
                                                {renderReceivedSection('competency_test',receivedDocuments?.competency_test)}
                                            </>
                                        }
                                    </div>
                                    </>
                                }
                    </>
                    <>
                        <div className={'heading'}>Required Documents to upload</div>
                        {Object.keys(documentList).length === 0 ?
                            <div style={{
                                color: 'black',
                                borderBottom: '1px solid lightgray',
                                marginBottom: '10px',
                                textAlign: 'center',
                                paddingBottom: '10px'
                            }}>
                                No Documents Added.
                            </div>:
                            <>
                                <Divider/>
                                <div className={'document-list'}>
                                    {renderSubmissionSection('documents_submission', documentList?.documents_submission)}
                                    {renderSubmissionSection('certifications_submission', documentList?.certifications_submission)}
                                    {renderSubmissionSection('license_submission', documentList?.license_submission)}
                                    {renderSubmissionSection('skill_checklist_submission', documentList?.skill_checklist_submission)}
                                    {renderSubmissionSection('competency_test',documentList?.competency_test)}
                                </div>
                            </>
                        }
                    </>
                </div>
            }
        </div>
    )
}

export default RequiredDocumentListComponent;