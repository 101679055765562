import "./PermanentRateCardDetailsComponent.scss";
import DataLabelValueComponent from "../../../../../components/data-label-value/DataLabelValueComponent";
import {Divider} from "antd";

interface PermanentRateCardDetailsComponentProps{
    rateDetails?:any;
}

const PermanentRateCardDetailsComponent=(props:PermanentRateCardDetailsComponentProps)=>{
    const {rateDetails}=props;
    return (
        <div className={'mrg-top-20'}>
            <div className={'rate-type-header'}>Pay Rate Details</div>
            <div className={'d-flex mrg-top-20'}>
                <div className={'flex-equal'}>
                    <DataLabelValueComponent label={'Pay Rate / Year'}>
                        {rateDetails?.pay_rate?.total_pay_rate ? <>
                            $ {Number(rateDetails?.pay_rate?.total_pay_rate)?.toFixed(2)}
                        </> : 'N/A'}
                    </DataLabelValueComponent>
                </div>
                <div className={'flex-equal'}>
                    <DataLabelValueComponent label={'Salary / Year'}>
                        {rateDetails?.pay_rate?.salary ? <>
                            $ {Number(rateDetails?.pay_rate?.salary)?.toFixed(2)}
                        </> : 'N/A'}
                    </DataLabelValueComponent>
                </div>
            </div>
            <Divider/>
            <div className={'rate-type-header'}>Bill Rate Details</div>
            <div className={'d-flex mrg-top-20'}>
                <div className={'flex-equal'}>
                    <DataLabelValueComponent label={'Bill Rate / Year'}>
                        {rateDetails?.bill_rate?.total_bill_rate ? <>
                            $ {Number(rateDetails?.bill_rate?.total_bill_rate)?.toFixed(2)}
                        </> : 'N/A'}
                    </DataLabelValueComponent>
                </div>
                <div className={'flex-equal'}>
                    <DataLabelValueComponent label={'Salary / Year'}>
                        {rateDetails?.bill_rate?.salary ? <>
                            $ {Number(rateDetails?.bill_rate?.salary)?.toFixed(2)}
                        </> : 'N/A'}
                    </DataLabelValueComponent>
                </div>
            </div>
        </div>
    )
}

export default PermanentRateCardDetailsComponent