import {Button, IconButton, Tooltip} from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import { DatePicker } from "formik-material-ui-pickers";
import moment from "moment";
import React, {useCallback, useMemo, useState} from "react";
import VitawerksConfirmComponent from "../../../../../components/VitawerksConfirmComponent";
import { ENV } from "../../../../../constants";
import { ApiService, CommonService } from "../../../../../helpers";
import {educationValidation} from "../../applicant-add/EducationAddComponent/EducationValidation";
import "./EducationEditComponent.scss";
import {TableColumnsType} from "antd";
import DeleteIcon from "@material-ui/icons/Delete";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";

type EducationAddComponentProps = {
  hcpId: string;
  education: any;
  getEducationDetails: any;
  onAddEducation: any;
};

interface EducationItem {
  institute_name: string;
  degree: string;
  location: string;
  start_date: any;
  graduation_date: any;
}

const educationInitialState: EducationItem = {
  institute_name: "",
  degree: "",
  location: "",
  start_date: null,
  graduation_date: null,
};

const EducationAddComponent = ({ onAddEducation, getEducationDetails, education, hcpId }: EducationAddComponentProps) => {
  const [isEducation, setIsEducation] = useState<boolean>(false);
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
  const [educationId, setEducationId] = useState<any>(null);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const onAdd = (education: EducationItem, { setSubmitting, setErrors, resetForm }: FormikHelpers<EducationItem>) => {
    const newEducation = {
      institute_name: education?.institute_name,
      degree: education?.degree,
      location: education?.location,
      start_date: education?.start_date ?  moment(education?.start_date).format("YYYY-MM") : null,
      graduation_date: education?.graduation_date ? moment(education?.graduation_date).format("YYYY-MM") : null,
    };

    //add new education
    onAddEducation(newEducation)
      .then((resp: any) => {
        getEducationDetails();
        setIsEducation(false);
        resetForm();
        CommonService.showToast(resp?.msg || "Staff education added", "info");
      })
      .catch((err: any) => console.log(err));
  };

  const handleDeleteClick = useCallback(
    (educationId: number) => {
      setIsConfirm(true);
      ApiService.delete(ENV.API_URL + "hcp/" + hcpId + "/education/" + educationId)
        .then((resp: any) => {
          getEducationDetails();
          CommonService.showToast(resp?.msg || "Staff education deleted", "success");
          setIsConfirm(false);
          setIsAddOpen(false);
        })
        .catch((err) => {
          console.log(err);
          setIsConfirm(false);
        });
    },
    [getEducationDetails, hcpId]
  );

  const openAdd = useCallback((id: any) => {
    setEducationId(id);
    setIsAddOpen(true);
  }, []);

  const cancelAdd = useCallback(() => {
    setIsAddOpen(false);
  }, []);

  const confirmAdd = useCallback(() => {
    handleDeleteClick(educationId);
  }, [educationId, handleDeleteClick]);

  const sortedEducationData = education ? CommonService.sortDatesByLatest(education, "start_date") : []

  const educationListColumn:TableColumnsType=useMemo(()=>{
    return [
      {
        title:'Institution Name',
        width:250,
        render:(item:any)=>{
          return (
              <div>{item?.institute_name || 'N/A'}</div>
          )
        }
      },
      {
        title:'Degree',
        width:200,
        render:(item:any)=>{
          return (
              <div>{item?.degree || 'N/A'}</div>
          )
        }
      },
      {
        title:'Location',
        width:200,
        render:(item:any)=>{
          return (
              <div>{item?.location || 'N/A'}</div>
          )
        }
      },
      {
        title:'Start Date',
        width:100,
        render:(item:any)=>{
          return (
              <div>
                {moment(item.start_date).format('MM-YYYY') || 'N/A'}
              </div>
          )
        }
      },
      {
        title: 'End Date',
        width: 100,
        render: (item: any) => {
          return (
              <div>
                {moment(item.end_date).format('MM-YYYY') || 'N/A'}
              </div>
          )
        }
      },
      {
        title:'Delete',
        width:100,
        render:(item:any)=>{
          return (
              <div className={'display-center'}>
                <Tooltip title={`Delete Education`}>
                  <IconButton onClick={() => openAdd(item?._id)}>
                    <DeleteIcon className="delete-icon" />
                  </IconButton>
                </Tooltip>
              </div>
          )
        }
      }
    ]
  },[openAdd])

  return (
    <div className="add-container">
      <VitawerksConfirmComponent open={isAddOpen} isConfirm={isConfirm} cancel={cancelAdd} confirm={confirmAdd} text1="Want to delete" hcpname={"Education"} groupname={""} confirmationText={""} notext={"Back"} yestext={"Delete"} />
      {isEducation ? (
        <Formik initialValues={educationInitialState} validateOnChange={true} validationSchema={educationValidation} onSubmit={onAdd}>
          {({ isSubmitting, handleSubmit, isValid, resetForm }) => (
            <Form className={"form-holder"}>
              <div className="input-container">
                <Field variant="outlined" InputLabelProps={{ shrink: true }} fullWidth component={TextField} name="institute_name" label="Institution Name*" id="input_hcp_edit_education_institution_name" />

                <Field variant="outlined" InputLabelProps={{ shrink: true }} fullWidth component={TextField} name="location" label="Location*" id="input_hcp_edit_education_start_date" />
              </div>

              <div className="input-container">
                <Field variant="outlined" InputLabelProps={{ shrink: true }} fullWidth component={TextField} name="degree" label="Degree*" id="input_hcp_edit_education_location" />
                <Field
                  fullWidth
                  variant="inline"
                  openTo="year"
                  views={["year", "month"]}
                  inputVariant="outlined"
                  component={DatePicker}
                  placeholder="MM/YYYY"
                  name="start_date"
                  id="input_hcp_edit_education_start_date"
                  label="Start Date"
                  InputLabelProps={{ shrink: true }}
                />
              </div>

              <div className="input-container minor">
                <Field
                  fullWidth
                  variant="inline"
                  openTo="year"
                  views={["year", "month"]}
                  inputVariant="outlined"
                  component={DatePicker}
                  placeholder="MM/YYYY"
                  id="input_hcp_edit_education_end_date"
                  name="graduation_date"
                  label="End Date"
                  InputLabelProps={{ shrink: true }}
                />
              </div>

              <div className="hcp-common-btn-grp">
                <Button
                  variant="outlined"
                  type="reset"
                  id="btn_hcp_edit_education_close"
                  onClick={() => {
                    resetForm();
                    setIsEducation(false);
                  }}
                >
                  Delete
                </Button>

                <Button color="primary" variant="contained" type="submit" id="btn_hcp_edit_education_submit" className={isSubmitting ? "has-loading-spinner" : ""} disabled={isSubmitting}>
                  {isSubmitting ? "Saving" : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="edu-add-action">
          <Tooltip title={"Add New Education"}>
            <p id="btn_hcp_add_education" onClick={() => setIsEducation(true)} className="generic-add-multiple">
              + Add Education
            </p>
          </Tooltip>
        </div>
      )}
      {education?.length > 0 &&
          <div className={'mrg-top-20'}>
            <AntTableComponent
              columns={educationListColumn}
              data={sortedEducationData}
              extraPayload={sortedEducationData}
              noDataText={'No Education Found'}
            />
          </div>
      }
    </div>
  );
};

export default EducationAddComponent;
