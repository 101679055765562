import * as Yup from "yup";

export interface FacilityItemAddType {
    uid: string;
    name: string;
    short_name: string;
    business_name: string;
    email?: string;
    parent_company?: any;
    contact_number?: string;
    extension_number: string;
    website_url?: string;
    about?: string;
    timezone?: string;
    address?: {
        street: string;
        city: string;
        state: string;
        region: string;
        country: string;
        zip_code: string;
    };
    notification_preferences: {
        sms: boolean,
        email: boolean,
        push_notification: boolean,
    },
    hourly_base_rates?: {
        lpn_lvn?: number;
        lna?: number;
        cna_lna?: number;
        cna?: number;
        lvn?: number;
        lpn?: number;
        rn?: number;
        care_giver?: number;
        med_tech?: number;
        spd_tech?: number;
        dsp?: number;
        pss?: number;
        crma?: number;
        physical_therapist?: number;
        occupational_therapist?: number;
        rn_supervisor?: number;
        caregiver_dsp?: number;
        caregiver_pss?: number;
        caregiver_crma?: number;
        holiday?: number;
        hazard?: number;
        rn_speciality1?: number;
        rn_speciality2?: number;
        rn_travel?: number;
        emt?: number;
        monitor_tech?: number;
        orst?: number;
        radiology_tech?: number;
        cls?: number;
        slp?: number;
        bsw?: number;
        scrub_tech?: number;
        medical_assistant?: number,
        marriage_family_therapist?: number,
        clinical_social_worker?: number,
        psychologist?: number,
        pharmacist?: number,
        pharmacy_technician?: number,
        evaluation?: number,
        start_of_care?: number,
        rn_routine_vist?: number,
        lvn_routine_vist?: number,
        ot_pt_st_routine_vist?: number,
        cna_routine_vist?: number,
        caregiver_chha_routine_vist?: number,
        ota_pta_sta_routine_vist?: number,
        discharge?: number,
        recertification?: number,
        resumption_of_care?: number,
    };
    diff_rates?: {
        pm?: number;
        noc?: number;
        weekend?: number;
    };
    conditional_rates: {
        overtime?: {
            hours?: number;
            rate?: number;
        };
        rush?: {
            hours?: number;
            rate?: number;
        };
        cancellation_before?: {
            hours?: number;
            rate?: number;
        };
        shift_early_completion?: {
            hours?: number;
            rate?: number;
        };
    };
    pay_hourly_base_rates?: {
        lpn_lvn?: number;
        lna?: number;
        cna_lna?: number;
        cna?: number;
        lvn?: number;
        lpn?: number;
        rn?: number;
        care_giver?: number;
        med_tech?: number;
        spd_tech?: number;
        dsp?: number;
        pss?: number;
        crma?: number;
        physical_therapist?: number;
        occupational_therapist?: number;
        rn_supervisor?: number;
        caregiver_dsp?: number;
        caregiver_pss?: number;
        caregiver_crma?: number;
        holiday?: number;
        hazard?: number;
        rn_speciality1?: number;
        rn_speciality2?: number;
        rn_travel?: number;
        emt?: number;
        monitor_tech?: number;
        orst?: number;
        radiology_tech?: number;
        cls?: number;
        slp?: number;
        bsw?: number;
        scrub_tech?: number;
        medical_assistant?: number,
        marriage_family_therapist?: number,
        clinical_social_worker?: number,
        psychologist?: number,
        pharmacist?: number,
        pharmacy_technician?: number,
        evaluation?: number,
        start_of_care?: number,
        rn_routine_vist?: number,
        lvn_routine_vist?: number,
        ot_pt_st_routine_vist?: number,
        cna_routine_vist?: number,
        caregiver_chha_routine_vist?: number,
        ota_pta_sta_routine_vist?: number,
        discharge?: number,
        recertification?: number,
        resumption_of_care?: number,
    };
    pay_diff_rates?: {
        pm?: number;
        noc?: number;
        weekend?: number;
    };
    pay_conditional_rates: {
        overtime?: {
            hours?: number;
            rate?: number;
        };
        rush?: {
            hours?: number;
            rate?: number;
        };
        cancellation_before?: {
            hours?: number;
            rate?: number;
        };
        shift_early_completion?: {
            hours?: number;
            rate?: number;
        };
    };

    latitude: any;
    longitude: any;
    required_credentials:any;
}

export const facilityInitialState: FacilityItemAddType = {
    uid: "",
    name: "",
    short_name: "",
    business_name: "",
    email: "",
    contact_number: "",
    extension_number: "",
    parent_company: "",
    website_url: "",
    about: "",
    timezone: "",
    address: {
        street: "",
        city: "",
        state: "",
        region: "",
        country: "US",
        zip_code: "",
    },
    notification_preferences: {
        sms: false,
        email: false,
        push_notification: false,
    },
    hourly_base_rates: {
        lpn_lvn: undefined,
        cna_lna: undefined,
        lna: undefined,
        cna: undefined,
        lvn: undefined,
        lpn: undefined,
        rn: undefined,
        care_giver: undefined,
        med_tech: undefined,
        spd_tech: undefined,
        dsp: undefined,
        pss: undefined,
        crma: undefined,
        rn_supervisor: undefined,
        caregiver_dsp: undefined,
        caregiver_pss: undefined,
        caregiver_crma: undefined,
        holiday: undefined,
        hazard: undefined,
        rn_speciality1: undefined,
        rn_speciality2: undefined,
        rn_travel: undefined,
        emt: undefined,
        monitor_tech: undefined,
        orst: undefined,
        radiology_tech: undefined,
        occupational_therapist: undefined,
        physical_therapist: undefined,
        cls: undefined,
        slp: undefined,
        scrub_tech: undefined,
        medical_assistant: undefined,
        marriage_family_therapist: undefined,
        clinical_social_worker: undefined,
        psychologist: undefined,
        pharmacist: undefined,
        pharmacy_technician: undefined,
        evaluation: undefined,
        start_of_care: undefined,
        rn_routine_vist: undefined,
        lvn_routine_vist: undefined,
        bsw: undefined,
        ot_pt_st_routine_vist: undefined,
        cna_routine_vist: undefined,
        caregiver_chha_routine_vist: undefined,
        ota_pta_sta_routine_vist: undefined,
        discharge: undefined,
        recertification: undefined,
        resumption_of_care: undefined,
    },
    diff_rates: {
        pm: undefined,
        noc: undefined,
        weekend: undefined,
    },
    conditional_rates: {
        overtime: {
            hours: undefined,
            rate: undefined,
        },
        rush: {
            hours: undefined,
            rate: undefined,
        },
        cancellation_before: {
            hours: undefined,
            rate: undefined,
        },
        shift_early_completion: {
            hours: undefined,
            rate: undefined,
        },
    },
    pay_hourly_base_rates: {
        lpn_lvn: undefined,
        cna_lna: undefined,
        lna: undefined,
        cna: undefined,
        lvn: undefined,
        lpn: undefined,
        rn: undefined,
        care_giver: undefined,
        med_tech: undefined,
        spd_tech: undefined,
        dsp: undefined,
        pss: undefined,
        crma: undefined,
        rn_supervisor: undefined,
        caregiver_dsp: undefined,
        caregiver_pss: undefined,
        caregiver_crma: undefined,
        holiday: undefined,
        hazard: undefined,
        rn_speciality1: undefined,
        rn_speciality2: undefined,
        rn_travel: undefined,
        emt: undefined,
        monitor_tech: undefined,
        orst: undefined,
        radiology_tech: undefined,
        occupational_therapist: undefined,
        physical_therapist: undefined,
        cls: undefined,
        slp: undefined,
        scrub_tech: undefined,
        medical_assistant: undefined,
        marriage_family_therapist: undefined,
        clinical_social_worker: undefined,
        psychologist: undefined,
        pharmacist: undefined,
        pharmacy_technician: undefined,
        evaluation: undefined,
        start_of_care: undefined,
        rn_routine_vist: undefined,
        lvn_routine_vist: undefined,
        bsw: undefined,
        ot_pt_st_routine_vist: undefined,
        cna_routine_vist: undefined,
        caregiver_chha_routine_vist: undefined,
        ota_pta_sta_routine_vist: undefined,
        discharge: undefined,
        recertification: undefined,
        resumption_of_care: undefined,
    },
    pay_diff_rates: {
        pm: undefined,
        noc: undefined,
        weekend: undefined,
    },
    pay_conditional_rates: {
        overtime: {
            hours: undefined,
            rate: undefined,
        },
        rush: {
            hours: undefined,
            rate: undefined,
        },
        cancellation_before: {
            hours: undefined,
            rate: undefined,
        },
        shift_early_completion: {
            hours: undefined,
            rate: undefined,
        },
    },

    latitude: undefined,
    longitude: undefined,
    required_credentials:{
        certifications_submission:[],
        license_submission:[],
        skill_checklist_submission:[],
        competency_test:[]
    }
};

//const URL = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;


export const facilityFormValidation = Yup.object({
    uid: Yup.string().typeError(" must be a text").required("Required").trim("empty space not allowed").required("Required"),
    name: Yup.string()
        .typeError("Name should be at least 3 characters")
        .max(255, "max limit 255")
        .trim("empty space not allowed")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .required("Required"),
    short_name: Yup.string()
        .typeError(" must be a text")
        .max(30, "max limit 30")
        .trim("empty space not allowed")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .required("Required"),
    business_name: Yup.string()
        .typeError(" must be a text")
        .max(255, "max limit 255")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .trim("empty space not allowed"),
    email: Yup.string().typeError("must be a text").max(200, "max limit 200").email("invalid"),
    contact_number: Yup.string().min(10, "Phone number must be 10 digits").required("Required"),
    // extension_number: Yup.string()
    //     .matches(/^[0-9]+$/, "Must be only digits")
    //     .trim("empty space not allowed")
    //     .min(1, "min 1 digit")
    //     .max(10, "max 10 digits"),
    website_url: Yup.string()
        .max(200, "max limit 200"),
    timezone: Yup.number().typeError(" must be a number").required("Required"),
    about: Yup.string().typeError(" must be a text").max(750, "max limit 750").trim("empty space not allowed"),
    address: Yup.object({
        street: Yup.string().typeError(" must be a text").max(30, "max limit 30").trim("empty space not allowed").required("Required"),
        city: Yup.string().typeError(" must be a text").max(30, "max limit 30").trim("empty space not allowed").required("Required"),
        state: Yup.string().typeError(" must be a text").max(30, "max limit 30").trim("empty space not allowed").required("Required"),
        // region: Yup.string().typeError(" must be a text").max(30, "max limit 30").trim("empty space not allowed").required("Required"),
        country: Yup.string().typeError(" must be a text").max(30, "max limit 30").trim("empty space not allowed").required("Required"),
        zip_code: Yup.string()
            .typeError(" must be a text")
            .matches(/^[0-9]+$/, "Must be only digits")
            .trim("empty space not allowed")
            .min(5, "min 5 digits")
            .max(6, "max 6 digits allowed")
            .required("Required"),
    }),
    notification_preferences: Yup.object({
        sms: Yup.boolean(),
        email: Yup.boolean(),
        push_notification: Yup.boolean(),
    }),
    hourly_base_rates: Yup.object({
        lpn_lvn: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        lna: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        cna_lna: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        cna: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        cls: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        lvn: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        rn: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        bsw: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        medical_assistant: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        marriage_family_therapist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        clinical_social_worker: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        psychologist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        pharmacist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        pharmacy_technician: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        care_giver: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        med_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        spd_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        dsp: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        pss: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        crma: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_supervisor: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        caregiver_dsp: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        caregiver_pss: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        caregiver_crma: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        holiday: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        hazard: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        lpn: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),

        rn_speciality1: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_speciality2: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_travel: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        emt: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        monitor_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        orst: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        radiology_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        physical_therapist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        occupational_therapist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        chha: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        cna_chha: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        evaluation: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        start_of_care: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        lvn_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        ot_pt_st_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        cna_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        caregiver_chha_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        ota_pta_sta_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        discharge: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        recertification: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        resumption_of_care: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        slp: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        scrub_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),

    }),
    diff_rates: Yup.object({
        pm: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required"),
        noc: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required"),
        weekend: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required"),
    }),
    conditional_rates: Yup.object({
        overtime: Yup.object({
            hours: Yup.string()
                .typeError(" must be valid")
                .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
            rate: Yup.string()
                .typeError(" must be valid")
                .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
        }),
        rush: Yup.object({
            hours: Yup.string()
                .typeError(" must be valid")
                .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
            rate: Yup.string()
                .typeError(" must be valid")
                .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
        }),
        cancellation_before: Yup.object({
            hours: Yup.string()
                .typeError(" must be valid")
                .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
            rate: Yup.string()
                .typeError(" must be valid")
                .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
        }),
        shift_early_completion: Yup.object({
            hours: Yup.string()
                .typeError(" must be valid")
                .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
            rate: Yup.string()
                .typeError(" must be valid")
                .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
        }),
    }),
    pay_hourly_base_rates: Yup.object({
        lpn_lvn: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        lna: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        cna_lna: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        cna: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        cls: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        lvn: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        rn: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        bsw: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        medical_assistant: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        marriage_family_therapist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        clinical_social_worker: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        psychologist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        pharmacist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        pharmacy_technician: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        care_giver: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        med_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        spd_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        dsp: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        pss: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        crma: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_supervisor: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        caregiver_dsp: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        caregiver_pss: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        caregiver_crma: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        holiday: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        hazard: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        lpn: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),

        rn_speciality1: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_speciality2: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_travel: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        emt: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        monitor_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        orst: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        radiology_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        physical_therapist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        occupational_therapist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        chha: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        cna_chha: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        evaluation: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        start_of_care: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        lvn_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        ot_pt_st_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        cna_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        caregiver_chha_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        ota_pta_sta_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        discharge: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        recertification: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        resumption_of_care: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        slp: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        scrub_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),

    }),
    pay_diff_rates: Yup.object({
        pm: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required"),
        noc: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required"),
        weekend: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required"),
    }),
    pay_conditional_rates: Yup.object({
        overtime: Yup.object({
            hours: Yup.string()
                .typeError(" must be valid")
                .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
            rate: Yup.string()
                .typeError(" must be valid")
                .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
        }),
        rush: Yup.object({
            hours: Yup.string()
                .typeError(" must be valid")
                .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
            rate: Yup.string()
                .typeError(" must be valid")
                .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
        }),
        cancellation_before: Yup.object({
            hours: Yup.string()
                .typeError(" must be valid")
                .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
            rate: Yup.string()
                .typeError(" must be valid")
                .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
        }),
        shift_early_completion: Yup.object({
            hours: Yup.string()
                .typeError(" must be valid")
                .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
            rate: Yup.string()
                .typeError(" must be valid")
                .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
        }),
    }),

    longitude: Yup.number().typeError("must be a number").required("Required"),
    latitude: Yup.number().typeError("must be a number").required("Required"),
});
