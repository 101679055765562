import {Button, Tooltip} from "@material-ui/core";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import AccessControlComponent from "../../../components/AccessControl";
import {ENV, ImageConfig} from "../../../constants";
import {CssTextField} from "../../../constants/data/styles";
import {ApiService, CommonService, Communications} from "../../../helpers";
import commonService, {
    ACCOUNTMANAGER,
    ADMIN,
    FINANCE,
    HUMANRESOURCE,
    NURSECHAMPION, TERICADMIN,RECRUITER,HRMANAGER
} from "../../../helpers/common-service";
import {StateParams} from "../../../store/reducers";
import "./HcpApprovedListScreen.scss";
import WarningIcon from "@material-ui/icons/Warning";
import AntTableComponent from "../../../components/ant-table-component/AntTableComponent";
import {Popover, TableColumnsType} from "antd";
import CustomToggleComponent from "../../../components/custom-toogle-using-button-component/CustomToggleComponent";
import DrawerComponent from "../../../components/drawer/DrawerComponent";
import ApprovedStaffFilterDrawerComponent from "../approved-staff-filter-drawer/ApprovedStaffFilterDrawerComponent";
import {useSessionStorage} from "../../../components/useSessionStorage";
import ChangeRecruiterComponent from "../../ats/Job/Job-list/change-recruiter/ChangeRecruiterComponent";
import ModalComponent from "../../../components/modal/ModalComponent";
import ChangeHrComponent from "../../ats/Job/Job-list/change-hr/ChangeHrComponent";
import HcpMenuListComponent from "../hcp-menu-list/HcpMenuListComponent";
import LeavePageConfirmationComponent from "../../../components/shared/LeavePageConfirmationComponent";
import {MailOutlined} from "@ant-design/icons";

const HcpApprovedListScreen = () => {
    const {role} = useSelector((state: StateParams) => state?.auth?.user);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [openFilterDrawer,setOpenFilterDrawer]=useState<boolean>(false);
    const [openChangeRecruiterModal,setOpenChangeRecruiterModal]=useState<boolean>(false);
    const [selectedHcps,setSelectedHcps]=useState<string[]>([]);
    const [filterData,setFilterData]=useSessionStorage<any>("approvedHcpFilterData",{
        search:'',
        sort:{},
        is_approved:true,
    })
    const [openChangeHrModal,setOpenChangeHrModal]=useState<boolean>(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [selectedData,setSelectedData]=useState<any>(null);
    const [openConfirmation,setOpenConfirmation]=useState<boolean>(false);
    const [filterCount,setFilterCount]=useState<number>(0);
    const [sending,setSending]=useState<any>({});

    useEffect(() => {
        let count=0;
        if(filterData?.start_date) count++;
        if(filterData?.end_date) count++;
        if(filterData?.is_active !== null && filterData?.is_active !== undefined) count++;
        if(filterData?.hcp_types && filterData?.hcp_types?.length > 0) count++;
        if(filterData?.recruiter_ids && filterData?.recruiter_ids?.length > 0) count++;
        if(filterData?.hr_ids && filterData?.hr_ids?.length > 0) count++;
        if(filterData?.source && filterData?.source?.length > 0) count++;
        setFilterCount(count)
    }, [filterData]);

    const handleMenuItemClick = useCallback((action: string) => {
        setPopoverVisible(false);
        switch(action){
            case "change_recruiter":
                setOpenChangeRecruiterModal(true);
                break;
            case "change_hr":
                setOpenChangeHrModal(true);
                break;
            default:
                return ;
        }
    },[])

    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        let payload: any = {};
        payload.is_approved = true;

        if (filterData?.hcp_types?.length > 0) {
            // payload.hcp_types = selectedHcpTypes.map((item: any) => item?.title);
            payload.hcp_types=filterData?.hcp_types;
        }

        if (filterData?.is_active !== undefined && filterData?.is_active !== null) {
            payload.is_active = filterData.is_active;
        }

        if (filterData?.start_date || filterData?.end_date) {
            let startDate = moment(filterData?.start_date).format("YYYY-MM-DD");
            let endDate = moment(filterData?.end_date).format("YYYY-MM-DD");

            if (!filterData?.end_date) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }

        if (filterData?.search) {
            payload.search = filterData.search;
        }

        if(filterData?.recruiter_ids?.length > 0){
            payload.recruiter_ids = filterData?.recruiter_ids
        }

        if(filterData?.hr_ids?.length > 0){
            payload.hr_ids = filterData?.hr_ids
        }

        ApiService.post(ENV.API_URL + "hcp/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.msg || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.msg || "Error", "error");
            });
    }, [filterData]);


    const handleToggleStatus = useCallback(
        (id: any, is_active) => {
            let payload = {
                is_active: !is_active,
            };
            CommonService._api
                .put(ENV.API_URL + "hcp/" + id + '/edit', payload)
                .then(() => {
                    CommonService.showToast('Status Updated Successfully', 'success');
                    let updatedFilterData={...filterData};
                    setFilterData(updatedFilterData)
                })
                .catch((err:any) => {
                    CommonService.showToast(err.error || 'Something went wrong!','error')
                    console.log(err);
                });
        },
        [filterData,setFilterData]
    );

    const handleOpenConfirmation=useCallback(()=>{
        setOpenConfirmation(true);
    },[])

    const handleCloseConfirmation=useCallback(()=>{
        setOpenConfirmation(false);
        handleToggleStatus(selectedData._id,selectedData.is_active);
    },[handleToggleStatus,selectedData])

    useEffect(() => {
        Communications.pageTitleSubject.next("Approved Users");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    const createWorkerId = useCallback((workerId: any, index: any) => {
        CommonService._api
            .post(ENV.API_URL + `hcp/${workerId}/createWorker`)
            .then((resp) => {
                setFilterData({...filterData})
            })
            .catch((err) => {
                console.log(err);
                commonService.showToast(err?.error || "Error", "error");
            });
    }, [filterData,setFilterData]);

    const handleNotification=useCallback((id)=>{
        let payload:any = {
            "_id": id,
        }
        setSending((prev:any)=>({
            ...prev,
            [id]:true
        }));
        CommonService._api.post(ENV.API_URL + 'hcp/sendSingleHcpMandatoryDocMail',payload).then((response:any)=>{
            CommonService.showToast(response?.message ,"success")
        }).catch((error:any)=>{
            CommonService.showToast(error?.message || error?.error,"error")
        }).finally(()=>{
            setSending((prev:any)=>({
                ...prev,
                [id]:false
            }));
        })
    },[])

    const staffApprovedListColumns:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'',
                width:60,
                render:(item:any)=>{
                    return (
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            {(item["is_license_expired"] || !item?.hasOwnProperty("license_expiry_date")) &&
                                <Tooltip title={'Nursing License Expired Please Renew It'}>
                                    <Link to={`/hcp/edit/` + item["_id"]} style={{color:'red'}}>
                                    <div>
                                        {(item["is_license_expired"] || !item?.hasOwnProperty("license_expiry_date")) &&
                                        <WarningIcon/>}
                                    </div>
                                    </Link>
                                </Tooltip>}
                        </div>
                    )
                }
            },
            {
                title:"Name",
                width:250,
                sorter: (a: any, b: any) => (a.first_name + a.last_name)?.localeCompare(b.first_name + b.last_name),
                render:(item:any)=>{
                    return (
                        <div>
                            {(item?.first_name + item?.last_name)?.length > 20 ?
                                <Tooltip title={item?.first_name + " " + item?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.first_name + " " + item?.last_name || 'N/A'}
                                    </div>
                                </Tooltip>:
                                <div>
                                    {item?.first_name + " " + item?.last_name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Staff Type',
                width:200,
                sorter: (a: any, b: any) => a.hcp_type?.localeCompare(b.hcp_type),
                render:(item:any)=>{
                    return (
                        <div>
                            {(item?.hcp_type)?.length > 20 ?
                                <Tooltip title={item?.hcp_type}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp_type || 'N/A'}
                                    </div>
                                </Tooltip>:
                                <div>
                                    {item?.hcp_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Staff ID',
                width:140,
                sorter: (a: any, b: any) => a.hcp_uniqueId?.localeCompare(b.hcp_uniqueId),
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.hcp_uniqueId?.length > 15 ?
                                <Tooltip title={item?.hcp_uniqueId}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp_uniqueId || 'N/A'}
                                    </div>
                                </Tooltip>
                                :
                                <div>
                                    {item?.hcp_uniqueId || 'N/A'}
                                </div>}
                        </div>
                    )
                }
            },
            {
                title:'Source',
                width:180,
                sorter: (a: any, b: any) => {
                    return a?.applied_from?.localeCompare(b?.applied_from);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {CommonService.formatTitleCase(item?.applied_from) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Email',
                width:200,
                sorter: (a: any, b: any) => a.email?.localeCompare(b.email),
                render:(item:any)=>{
                    return (
                        <div>
                            {(item?.email)?.length > 20 ?
                                <Tooltip className={'text-lowercase'} title={item?.email}>
                                    <div className={'ellipses-for-table-data text-lowercase'}>
                                        {item?.email || 'N/A'}
                                    </div>
                                </Tooltip>:
                                <div className={'text-lowercase'}>
                                    {item?.email || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Recruiter',
                width:180,
                dataIndex:'recruiter_details',
                sorter: (a: any, b: any) => {
                    const nameA = `${a?.first_name ?? ''} ${a?.last_name ?? ''}`.toLowerCase();
                    const nameB = `${b?.first_name ?? ''} ${b?.last_name ?? ''}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                },
                render:(item:any)=>{
                    return(
                        <>
                            {item ?
                                <div>
                                    {(item?.first_name + item?.last_name)?.length > 20 ?
                                        <Tooltip title={item?.first_name + " " + item?.last_name}>
                                            <div className={'ellipses-for-table-data'}>
                                                {item?.first_name + " " + item?.last_name || 'N/A'}
                                            </div>
                                        </Tooltip>
                                        :
                                        <div>
                                            {item?.first_name + " " + item?.last_name || 'N/A'}
                                        </div>
                                    }
                                </div>
                                :
                                <div>N/A</div>}
                        </>
                    )
                }
            },
            {
                title:'HR',
                width:180,
                dataIndex:'hr_details',
                sorter: (a: any, b: any) => {
                    const nameA = `${a?.first_name ?? ''} ${a?.last_name ?? ''}`.toLowerCase();
                    const nameB = `${b?.first_name ?? ''} ${b?.last_name ?? ''}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                },
                render:(item:any)=>{
                    return(
                        <>
                            {item ?
                                <div>
                                    {(item?.first_name + item?.last_name)?.length > 20 ?
                                        <Tooltip title={item?.first_name + " " + item?.last_name}>
                                            <div className={'ellipses-for-table-data'}>
                                                {item?.first_name + " " + item?.last_name || 'N/A'}
                                            </div>
                                        </Tooltip>
                                        :
                                        <div>
                                            {item?.first_name + " " + item?.last_name || 'N/A'}
                                        </div>
                                    }
                                </div>
                                :
                                <div>N/A</div>}
                        </>
                    )
                }
            },
            {
                title:'Created On',
                width:130,
                sorter: (a: any, b: any) => moment(a["created_at"]).utc().unix() - moment(b["created_at"]).utc().unix(),
                render:(item:any)=>{
                    return moment(item["created_at"]).utc().format("DD-MM-YYYY") || 'N/A'
                }
            },
            {
                title:'Contact Number',
                width:160,
                // sorter: (a: any, b: any) => a.contact_number?.localeCompare(b.contact_number),
                render:(item:any)=>{
                    return (
                        <div>
                            {CommonService.formatPhoneNumber(item?.contact_number?.replace(/^\+1/, '')) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Active/Inactive',
                width:140,
                render:(item:any)=>{
                    return (
                        <div className={'display-center'}>
                            {role === "super_admin" ? (
                                <Tooltip title={"ACTIVE / INACTIVE"}>
                                    <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                                        if(item?.is_active){
                                            setSelectedData(item);
                                            handleOpenConfirmation();
                                        }else{
                                            handleToggleStatus(item._id,item.is_active);
                                        }
                                    }}/>
                                </Tooltip>
                            ) : item["is_active"] ? (
                                <div style={{color: "#41D6C3"}}>
                                    Active
                                </div>
                            ) : (
                                <div style={{color: "#808080"}}>
                                    Inactive
                                </div>
                            )}
                        </div>
                    )
                }
            },
            {
                title:'Checkr Pay ID',
                width:130,
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.worker_creation_details?.status ?
                            <div style={{color:'green'}}>Created</div>:
                            <Tooltip placement={"top"}
                                         title={item["worker_creation_details"]?.details}>
                                 <div style={{color: "red"}}>Failed</div>
                            </Tooltip>
                            }

                        </div>
                    )
                }
            },
            {
                title:'Request Worker Creation',
                width:200,
                render:(_:any,item:any,rowIndex:number)=>{
                    return (
                        <div>
                            {!item?.worker_creation_details?.status ?
                                <div style={{
                                textDecoration: "underline",
                                color: "blue",
                                cursor: "pointer"
                                    }} onClick={() => {
                                        createWorkerId(item["_id"], rowIndex);
                                    }
                                    }>
                                    Request
                                </div>
                                :
                                <div style={{color:'green'}}>Requested</div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Document Request',
                width:180,
                render:((item:any)=>{
                    return (
                        <div className={'display-center'}>
                            <Tooltip  title={`Send an email requesting ${item?.first_name + " " + item?.last_name} to upload their documents.`}>
                                <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    className={!sending[item?._id] ? "" : "has-loading-spinner"}
                                    onClick={()=>handleNotification(item?._id)}
                                >
                                    <MailOutlined/>
                                </Button>
                            </Tooltip>
                        </div>
                    )
                })
            },
            {
                title:'View Details',
                width:150,
                fixed:'right',
                render:(item:any)=>{
                    return (
                        <div className={'display-center'}>
                            <Tooltip
                                title={`${item["first_name"]} ${item["last_name"]} view details`}>
                                <Link to={"/hcp/user/view/" + item["_id"]}
                                      style={{color:'#1e1e1e'}}
                                      id={"link_hospital_details" + item?._id}>
                                    <ImageConfig.ArrowRightOutlinedIcon/>
                                </Link>
                            </Tooltip>
                        </div>
                    )
                }
            }
        ]
    }, [handleOpenConfirmation,role,createWorkerId,handleToggleStatus,handleNotification,sending])

    return (
        <>
            <LeavePageConfirmationComponent
                visible={openConfirmation}
                cancel={()=>{
                    setOpenConfirmation(false);
                    setSelectedData(null);
                }}
                title={`Are you sure you want to Inactive this staff member?`}
                confirm={handleCloseConfirmation}
                confirmationText="This action will cancel all their pending/approved shifts."
                notext="Cancel"
                yestext="Confirm"
            />
            <DrawerComponent isOpen={openFilterDrawer} onClose={()=>setOpenFilterDrawer(false)}>
                <ApprovedStaffFilterDrawerComponent
                    setOpenFilterDrawer={setOpenFilterDrawer}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    hcpTypes={hcpTypes}
                    isApprovedList={true}
                />
            </DrawerComponent>
            <ModalComponent open={openChangeRecruiterModal} onCancel={()=>setOpenChangeRecruiterModal(false)} width={600} footer={null} title={'Add/Change Recruiter'}>
                <ChangeRecruiterComponent
                    selectedJobs={selectedHcps}
                    setSelectedJobs={setSelectedHcps}
                    setOpenChangeRecruiterModal={setOpenChangeRecruiterModal}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    module={'applicant'}
                />
            </ModalComponent>
            <ModalComponent open={openChangeHrModal} onCancel={()=>setOpenChangeHrModal(false)} width={600} footer={null} title={'Add/Change HR'}>
                <ChangeHrComponent selectedIds={selectedHcps} setSelectedIds={setSelectedHcps} setOpenChangeHrModal={setOpenChangeHrModal}  filterData={filterData}
                                   setFilterData={setFilterData} module={'applicant'}/>
            </ModalComponent>
            <div className={"hcp-list screen crud-layout pdd-30"}>
                {/*<HcpFiltersComponent selectedHcpTypes={selectedHcpTypes} setSelectedHcpTypes={setSelectedHcpTypes}*/}
                {/*                     resetFilters={resetFilters} isApprovedList={true} hcpTypes={hcpTypes}*/}
                {/*                     status={status} setStatus={setStatus} dateRange={dateRange}*/}
                {/*                     setDateRange={setDateRange}/>*/}

                <div className="custom-border pdd-10  pdd-top-20 pdd-bottom-0">
                    <div className="header">
                        <div className="mrg-left-5 filter">
                            <div className="d-flex">
                                <div className="d-flex position-relative">
                                    {!filterData.search ? (
                                        <div className={"search_icon"}>
                                            <SearchRounded/>
                                        </div>
                                    ) : (
                                        <div className={"search_icon"}>
                                            <ClearIcon
                                                onClick={(event) => {
                                                    setFilterData({...filterData, search: '', page: 1})
                                                    setSelectedHcps([]);
                                                }}
                                                id="clear_hcp_search"
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <CssTextField
                                            defaultValue={""}
                                            className="search-cursor searchField"
                                            id="input_search_hcp"
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                setFilterData({...filterData, search: value, page: 1});
                                            }}
                                            value={filterData.search}
                                            variant={"outlined"}
                                            size={"small"}
                                            type={"text"}
                                            placeholder={"Search Staff"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Button
                                variant={"outlined"} id={'add_facility_btn'}
                                color={"primary"}
                                onClick={() => {
                                    setOpenFilterDrawer(true)
                                }}
                            >
                                {filterCount > 0 &&
                                    <span className={'filter-btn-badge'}>{filterCount}</span>
                                }
                                &nbsp;&nbsp;Filters&nbsp;&nbsp;
                            </Button>
                        </div>
                        <AccessControlComponent
                            role={[ADMIN, HUMANRESOURCE, NURSECHAMPION, ACCOUNTMANAGER, FINANCE, TERICADMIN, RECRUITER,HRMANAGER]}>
                            <div className="hcp-list-download mrg-left-20">
                                <Tooltip title="Download Approved Staff List">
                                    <Button variant={"outlined"} color="primary" onClick={handleDownload}
                                            className={!isDownloading ? "" : "has-loading-spinner"}
                                            disabled={isDownloading}>
                                        &nbsp;Download
                                    </Button>
                                </Tooltip>
                            </div>
                        </AccessControlComponent>
                        <AccessControlComponent
                            role={[ADMIN, TERICADMIN,HRMANAGER]}>
                            <div className={'mrg-left-20'}>
                                <Popover
                                    trigger={'click'}
                                    placement={'bottomRight'}
                                    destroyTooltipOnHide={true}
                                    open={popoverVisible}
                                    onOpenChange={(visible) => setPopoverVisible(visible)}
                                    content={<HcpMenuListComponent onMenuItemClick={handleMenuItemClick}
                                                                   selectedApplicants={selectedHcps}
                                    />}
                                >
                                    <div className={'applicant-box'} onClick={() => setPopoverVisible(!popoverVisible)}>
                                        <ImageConfig.KebabIcon className={'applicant-edit-icon'}/>
                                    </div>
                                </Popover>
                            </div>
                        </AccessControlComponent>
                    </div>
                    <div className={'approved-staff-table-wrapper'}>
                        <AntTableComponent
                            url={ENV.API_URL + "hcp/list"}
                            method={'post'}
                            isRowSelection={role !== 'recruiter'}
                            selectedRowKeys={selectedHcps}
                            setSelectedRowKeys={setSelectedHcps}
                            extraPayload={filterData}
                            columns={staffApprovedListColumns}
                            noDataText={'No Staff Data Found.'}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default HcpApprovedListScreen;
