import {Button, Checkbox, Tooltip} from "@material-ui/core";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import DialogComponent from "../../../../components/DialogComponent";
import {ENV, ImageConfig} from "../../../../constants";
import {CssTextField} from "../../../../constants/data/styles";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import AddFacilityBillDetailsComponent from "../addFacilityBillDetails/AddFacilityBillDetailsComponent";
import "./ShiftsMasterListScreen.scss";
import HcpSentHomeDialogComponent from "../../hcpSentHomeDialog/HcpSentHomeDialogComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
import {Popover, TableColumnsType} from "antd";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import DrawerComponent from "../../../../components/drawer/DrawerComponent";
import ShiftFilterDrawerComponent from "../../shift-filter-drawer/ShiftFilterDrawerComponent";
import {useSessionStorage} from "../../../../components/useSessionStorage";
import ShiftMenuListComponent from "../../shift-menu-list/ShiftMenuListComponent";


const [firstDayOfMonth, lastDayOfMonth] = CommonService.getFirstDayAndLastDayUsingDate();

const ShiftsMasterListScreen = () => {
    const location=useLocation()
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [isHcpSentHomeOpen, setIsHcpSentHomeOpen] = useState<boolean>(false);
    const [isAddBillDetailsOpen, setIsAddBillDetailsOpen] = useState<boolean>(false);
    const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);
    const [selectedRowData,setSelectedRowData]=useState<any>([]);
    const [prevLocation, setPrevLocation] = useState<string>('');
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [filterData, setFilterData] = useSessionStorage<any>("vitaMasterFilterData",{
        search: '',
        sort: {},
        limit: 10,
        start_date: CommonService.changeToUtcDate(firstDayOfMonth),
        end_date:CommonService.changeToUtcDate(lastDayOfMonth),
    })
    const [filterCount,setFilterCount]=useState<number>(0);

    useEffect(() => {
        let count=0;

        if(filterData?.start_date)count++;
        if(filterData?.end_date)count++;
        if(filterData?.status && filterData?.status?.length >0) count++;
        if(filterData?.facilities && filterData?.facilities?.length >0) count++;
        if(filterData?.hcp_types && filterData?.hcp_types?.length >0) count++;
        if(filterData?.shift_types && filterData?.shift_types?.length >0) count++;
        if(filterData?.hcp_user_ids && filterData?.hcp_user_ids?.length >0) count++;
        if(filterData?.type) count++;
        if(filterData?.salary_credit && filterData?.salary_credit?.length > 0) count++;
        if(filterData?.is_timesheet_uploaded !== null && filterData?.is_timesheet_uploaded !== undefined) count++;
        if(filterData?.is_facility_approved !== null && filterData?.is_facility_approved !== undefined) count++;
        if(filterData?.is_pending_concern !== null && filterData?.is_pending_concern !== undefined) count++;
        if(filterData?.is_sent_home !== null && filterData?.is_sent_home !== undefined) count++;
        if(filterData?.is_hcp_paid !== null && filterData?.is_hcp_paid !== undefined) count++;
        if(filterData?.is_billed !== null && filterData?.is_billed !== undefined) count++;
        if(filterData?.staff_status !== null && filterData?.staff_status !==undefined) count++;
        setFilterCount(count);
    }, [filterData]);

    useEffect(() => {
        // Detect page change by comparing current location with previous location
        if (location.pathname !== prevLocation) {
            setPrevLocation(location.pathname); // Update the previous location state

            // Only reset the filterData when navigating to /shiftMaster/list from a different page
            if (location.pathname.startsWith('/shiftMaster') && (!filterData.start_date && !filterData.end_date)) {
                let updatedFilterData={...filterData};
                updatedFilterData.start_date=CommonService.changeToUtcDate(firstDayOfMonth);
                updatedFilterData.end_date=CommonService.changeToUtcDate(lastDayOfMonth);
                delete updatedFilterData.new_shifts;
                setFilterData(updatedFilterData);
            }
        }
    }, [location, filterData, setFilterData, prevLocation]);



    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        let payload: any = {};


        if (filterData?.facilities?.length > 0) {
            payload.facilities = filterData?.facilities;
        }
        if (filterData?.hcp_types?.length > 0) {
            payload.hcp_types = filterData?.hcp_types;
        }

        if (filterData?.hcp_user_ids?.length > 0) {
            payload.hcp_user_ids = filterData?.hcp_user_ids;
        }
        if (filterData?.status?.length > 0) {
            payload.status = filterData?.status;
        }

        if (filterData?.salary_credit?.length > 0) {
            payload.salary_credit = filterData?.salary_credit;
        }

        if (filterData?.type !== '') {
            payload.type = filterData?.type;
        }

        if (filterData?.start_date || filterData?.end_date) {
            let startDate = moment(filterData?.start_date).format("YYYY-MM-DD");
            let endDate = moment(filterData?.end_date).format("YYYY-MM-DD");
            if (!filterData?.end_date) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }
        if (filterData?.shift_types?.length > 0) {
            payload.shift_types = filterData?.shift_types;
        }

        if (filterData?.is_billed !== "") {
            payload.is_billed = filterData?.is_billed;
        }


        if (filterData?.is_sent_home !== "") {
            payload.is_sent_home = filterData?.is_sent_home;
        }

        if (filterData?.is_timesheet_uploaded !== "") {
            payload.is_timesheet_uploaded = filterData?.is_timesheet_uploaded;
        }

        if (filterData?.is_facility_approved !== "") {
            payload.is_facility_approved = filterData?.is_facility_approved;
        }

        if (filterData?.search !== "") {
            payload.search = filterData?.search;
        }

        ApiService.post(ENV.API_URL + "shift/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.msg || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.msg || "Error", "error");
            });
    }, [ filterData]);

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);

    useEffect(() => getFacilityData(), [ getFacilityData]);

    const afterConfirm = useCallback(() => {
        let updatedFilterData = {...filterData};
        setFilterData(updatedFilterData)
        setIsAddBillDetailsOpen(false);
        setSelectedShifts([]);
        // setSelectedCount(-1);
    }, [filterData,setFilterData]);

    const cancelBillDetails = useCallback(() => {
        setIsAddBillDetailsOpen(false);
        setSelectedShifts([]);
        // setSelectedCount(-1);
    }, []);

    const clearFilterValues = useCallback(() => {

        setFilterData((prevFilterData: any) => {
            const updatedFilterData = {
                ...prevFilterData,
            }
            // Dynamically remove keys if they exist
            const keysToRemove = [
                "facilities",
                "hcp_types",
                "status",
                "is_billed",
                "is_timesheet_uploaded",
                "is_facility_approved",
                "is_sent_home",
                "shift_types",
                "salary_credit",
                "is_sent_home",
                "type",
                "hcp_user_ids",
                "start_date",
                "end_date",
                "staff_status"
            ];

            keysToRemove.forEach((key: any) => delete updatedFilterData[key]);
            updatedFilterData.start_date=CommonService.changeToUtcDate(firstDayOfMonth);
            updatedFilterData.end_date=CommonService.changeToUtcDate(lastDayOfMonth);

            return updatedFilterData;
        })
    }, [setFilterData])

    const resetFilters = () => {
        clearFilterValues();
    };

    useEffect(() => {
        Communications.pageTitleSubject.next("Master");
        Communications.pageBackButtonSubject.next(null);
    }, []);


    // const handleApproveButton = useCallback(() => {
    //     if (selectedShifts?.length > 0) {
    //         return !selectedRowData?.every((row: any) => row?.status === 'in_progress' || row?.status === 'pending');
    //     }
    //     return true;
    // },[selectedRowData,selectedShifts])


    const cancelHcpSentHome = useCallback(() => {
        setIsHcpSentHomeOpen(false);
        // setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const confirmHcpSentHome = useCallback(() => {
        setIsHcpSentHomeOpen(false);
        // setSelectedCount(-1);
        let updatedFilterData = {...filterData};
        setFilterData(updatedFilterData)
        setSelectedShifts([]);
    }, [filterData, setSelectedShifts,setFilterData]);


    const masterShiftsListColumn: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Facility Name',
                width: 250,
                sorter: (a: any, b: any) => {
                    const nameA = a?.facility?.name?.toLowerCase() || '';
                    const nameB = b?.facility?.name?.toLowerCase() || '';
                    return nameA.localeCompare(nameB);
                },
                render: (item: any) => {
                    return (
                        <div>
                            {item?.facility?.name?.length > 20 ?
                                <Tooltip title={item?.facility?.name || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility?.name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.facility?.name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Staff Name',
                width: 250,
                sorter: (a: any, b: any) => {
                    const nameA = (a?.hcp_details?.first_name + a?.hcp_details?.last_name)?.toLowerCase();
                    const nameB = (b?.hcp_details?.first_name + b?.hcp_details?.last_name)?.toLowerCase();
                    return nameA.localeCompare(nameB);
                },
                render: (item: any) => {
                    return (
                        <div>
                            {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)?.length > 20 ?
                                <Tooltip
                                    title={(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Staff Type',
                width: 170,
                sorter: (a: any, b: any) => {
                    const typeA = a?.hcp_type?.toLowerCase() || '';
                    const typeB = b?.hcp_type?.toLowerCase() || '';
                    return typeA.localeCompare(typeB);
                },
                render: (item: any) => {
                    return (
                        <div>
                            {item?.hcp_type?.length > 20 ?
                                <Tooltip title={item?.hcp_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.hcp_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Type',
                width: 200,
                sorter: (a: any, b: any) => {
                    const typeA = a?.shift_type?.toLowerCase() || '';
                    const typeB = b?.shift_type?.toLowerCase() || '';
                    return typeA.localeCompare(typeB);
                },
                render: (item: any) => {
                    return (
                        <div>
                            {item?.shift_type?.length > 20 ?
                                <Tooltip title={item?.shift_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.shift_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.shift_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Date',
                width: 120,
                sorter: (a: any, b: any) => {
                    const dateA = new Date(a["shift_date"]).getTime();
                    const dateB = new Date(b["shift_date"]).getTime();
                    return dateA - dateB;
                },
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.getUtcDate(item["shift_date"]) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Shift Rate',
                width: 120,
                sorter: (a: any, b: any) => {
                    const rateA = a?.shift_rate || 0;
                    const rateB = b?.shift_rate || 0;
                    return rateA - rateB;
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.shift_rate  ||  'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Status',
                width: 120,
                // sorter: (a: any, b: any) => (a["status"] || '').localeCompare(b["status"] || ''),
                render: (item: any) => {
                    return (
                        <div className={`captalize ${item["status"]}`}>
                            {item["status"] === "pending" ? "approved" : item["status"]}
                            {/*{item["status"]}*/}
                        </div>
                    )
                }
            },
            {
                title: "Facility Billed",
                width: 160,
                render: (item: any) => {
                    const isFacilityBilled = item?.hasOwnProperty('facility_billed_details') && item["facility_billed_details"]["is_billed"];
                    return (
                        <div className={`${isFacilityBilled === true ? "success" : "danger"}`}>
                            {"facility_billed_details" in item ? (isFacilityBilled === true ? "Yes" : "No") : "No"}
                        </div>
                    )
                }
            },
            {
                title: "Staff Sent Home",
                width: 160,
                render: (item: any) => {
                    const isHcpSentHome = item?.hasOwnProperty('is_sent_home') ? (item["is_sent_home"] === true ? "Yes" : "No") : "No";
                    return (
                        <div className={`${isHcpSentHome === 'Yes' ? 'success' : 'danger'}`}>
                            {isHcpSentHome}
                        </div>
                    )
                }
            },
            {
                title: 'Timesheet',
                width: 120,
                render: (item: any) => {
                    const isTimeSheetUploaded =
                        item?.hasOwnProperty("is_timesheet_uploaded") && item["is_timesheet_uploaded"];
                    return (
                        <div className={'display-center'}>
                            {isTimeSheetUploaded ? (
                                <Checkbox checked={isTimeSheetUploaded}/>
                            ) : (
                                <Checkbox checked={Boolean(isTimeSheetUploaded)}/>
                            )}
                        </div>
                    )
                }
            },
            {
                title: 'Facility Confirmation',
                width: 180,
                render: (item: any) => {
                    const isFacilityApproved =
                        item?.hasOwnProperty("is_facility_approved") && item["is_facility_approved"];
                    return (
                        <div className={'display-center'}>
                            {isFacilityApproved ? (
                                <Checkbox checked={isFacilityApproved}/>
                            ) : (
                                <Checkbox checked={Boolean(isFacilityApproved)}/>
                            )}
                        </div>
                    )
                }
            },
            {
                title: 'View Details',
                width: 140,
                render: (_: any, item: any, rowIndex: number) => {
                    return (
                        <div className={'display-center'}>
                            <Link to={"/shiftMaster/view/" + item["_id"]}
                                  className={isDownloading ? "disabled-link" : ""}
                                  style={{color: '#1e1e1e'}}
                                  id={"link_hospital_details" + rowIndex}>
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </Link>
                        </div>
                    )
                }
            },
        ]
    }, [isDownloading])

    const menuItems=useCallback(()=>{
        return [
            {
                label:'Staff Sent Home',
                id:'staff_sent_home',
                action:'staff_sent_home',
                tooltip:"Select In Progress or Approved Status Filter",
                disabled:!selectedRowData?.every(
                    (row: any) => row?.status === 'in_progress' || row?.status === 'pending'
                )
            },
            {
                label:'Facility Billed Details',
                id:'facility_billed_details',
                action:'facility_billed_details',
                tooltip:"Facility Billed Details",
            },

        ]
    },[selectedRowData])

    const handleMenuItemClick = useCallback((action: string) => {
        setPopoverVisible(false);
        switch(action){
            case "staff_sent_home":
                setIsHcpSentHomeOpen(true);
                break;
            case "facility_billed_details":
                 setIsAddBillDetailsOpen(true);
                 break;
            default:
                return ;
        }
    },[])

    return (
        <>
            <div className={"shift-master screen crud-layout pdd-30"}>
                <DialogComponent open={isAddBillDetailsOpen} cancel={cancelBillDetails} headerLabel={"Facility Billed Details"}>
                    <AddFacilityBillDetailsComponent cancel={cancelBillDetails} confirm={afterConfirm}
                                                     selectedShifts={selectedShifts}/>
                </DialogComponent>
                <DialogComponent open={isHcpSentHomeOpen} cancel={cancelHcpSentHome} headerLabel={"Enter the Actual Hours"}>
                    <HcpSentHomeDialogComponent
                        cancel={cancelHcpSentHome}
                        confirm={confirmHcpSentHome}
                        selectedShifts={selectedShifts}
                    />
                </DialogComponent>
                <DrawerComponent isOpen={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                    <ShiftFilterDrawerComponent
                        filterData={filterData}
                        setFilterData={setFilterData}
                        setOpenFilterDrawer={setOpenFilterDrawer}
                        isFacilityListLoading={isFacilityListLoading}
                        isMaster={true}
                        noStatus={false}
                        resetFilters={resetFilters}
                        facilityList={facilityList}
                        hcpTypes={hcpTypes}
                    />
                </DrawerComponent>
                <div className="header">
                    <div className="filter"></div>
                    <div className="action"></div>
                </div>
                <div className="custom-border pdd-20 mrg-top-0">
                    <div className="header">
                        <div className="mrg-left-5 filter">
                            <div>
                                <div className="d-flex">
                                    <div className="d-flex position-relative">
                                        {!filterData.search ? (
                                            <div className={"search_icon"}>
                                                <SearchRounded/>
                                            </div>
                                        ) : (
                                            <div className={"search_icon"}>
                                                <ClearIcon
                                                    onClick={(event) => {
                                                        setFilterData({...filterData, search: ''})
                                                        setSelectedShifts([]);
                                                        setSelectedRowData([]);
                                                    }}
                                                    id="clear_facility_search"
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <CssTextField
                                                defaultValue={""}
                                                className="search-cursor searchField"
                                                id="input_search_facility"
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    setFilterData({...filterData, search: value});
                                                }}
                                                value={filterData.search}
                                                variant={"outlined"}
                                                size={"small"}
                                                type={"text"}
                                                placeholder={"Search Shift"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="actions d-flex">
                            <div className={'mrg-left-10'}>
                                <Button
                                    variant={"outlined"} id={'add_facility_btn'}
                                    color={"primary"}
                                    onClick={() => {
                                        setOpenFilterDrawer(true)
                                    }}
                                >
                                    {filterCount > 0 &&
                                        <span className={'filter-btn-badge'}>{filterCount}</span>
                                    }
                                    &nbsp;&nbsp;Filters&nbsp;&nbsp;
                                </Button>
                            </div>
                            <div>
                                <Tooltip title="Download Shifts List">
                                    <Button variant={"outlined"} color="primary" onClick={handleDownload}
                                            className={!isDownloading ? "" : "has-loading-spinner"}
                                            disabled={isDownloading}>
                                        &nbsp;Download
                                    </Button>
                                </Tooltip>
                            </div>
                            <div className={'mrg-left-15'}>
                                <Popover
                                    trigger={'click'}
                                    placement={'bottomRight'}
                                    destroyTooltipOnHide={true}
                                    open={popoverVisible}
                                    onOpenChange={(visible) => setPopoverVisible(visible)}
                                    content={<ShiftMenuListComponent
                                        selectedShifts={selectedShifts}
                                        onMenuItemClick={handleMenuItemClick}
                                        menuItems={menuItems}
                                    />}
                                >
                                    <div className={'applicant-box'} onClick={() => setPopoverVisible(!popoverVisible)}>
                                        <ImageConfig.KebabIcon className={'applicant-edit-icon'}/>
                                    </div>
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <div className={'master-table-wrapper'}>
                        <AntTableComponent
                            url={ENV.API_URL + "shift/master"}
                            method={'post'}
                            extraPayload={filterData}
                            columns={masterShiftsListColumn}
                            noDataText={'No Shift Found.'}
                            isRowSelection={true}
                            selectedRowKeys={selectedShifts}
                            setSelectedRowKeys={setSelectedShifts}
                            setSelectedRowData={setSelectedRowData}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShiftsMasterListScreen;
