import "./JobViewDetailsComponent.scss"
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Tabs, Descriptions, Typography, Divider, Button, TableColumnsType, Row, Col, Modal} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import {ApiService, CommonService} from "../../../../helpers";
import {ENV, ImageConfig} from "../../../../constants";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {Spin} from "antd";
import {LoadingOutlined, MailOutlined, SendOutlined } from "@ant-design/icons";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import SelectDropdownComponent from "../../../ant-component/select/SelectComponent";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import DrawerComponent from "../../../../components/drawer/DrawerComponent";
import SingleEmailEditorComponent from "./email-editor-single/SingleEmailEditorComponent";
import JobNotesComponent from "./job-notes/JobNotesComponent";
import AddStaffToJobComponent from "./add-staff-to-job/AddStaffToJobComponent";
import PerDiemRateCardDetailsComponent from "./per-diem-rate-card-details/PerDiemRateCardDetailsComponent";
import ContractRateCardDetailsComponent from "./contract-rate-card-details/ContractRateCardDetailsComponent";
import PermanentRateCardDetailsComponent from "./permanent-rate-card-details/PermanentRateCardDetailsComponent";
import {Tooltip} from "@material-ui/core";
import RequiredDocumentListComponent from "../Job-list/required-document/RequiredDocumentListComponent";
import {FacebookIcon, LinkedInIcon, TwitterIcon} from "../../../../constants/ImageConfig";
import PipelineComponent from "../../../../components/ats/pipeline/PipelineComponent";
import SelectComponent from "../../../ant-component/select/SelectComponent";
import JobActivityLogsComponent from "./job-activity-logs/JobActivityLogsComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
import MatchingApplicantComponent from "./matching-applicant/MatchingApplicantComponent";

const {Title} = Typography;

const JobViewDetailsComponent = (props:any) => {
    const history = useHistory();
    const location=useLocation<any>();
    const defaultTab='basic_details';
    const searchParams = new URLSearchParams(location.search);
    const urlTab = searchParams.get('tab');
    const [activeTab,setActiveTab]=useState<string>(urlTab || defaultTab)
    const params = useParams<{ id: string }>();
    const {id} = params;
    const {hcpTypes}=useSelector((state:StateParams)=>state.meta)
    const [jobDetails, setJobDetails] = React.useState<any>();
    const [jobRateDetails, setJobRateDetails] = React.useState<any>([]);
    const [isJobDetailsLoading, setIsJobDetailsLoading] = useState<any>();
    const [isJobRateDetailsLoading, setIsRateJobDetailsLoading] = useState<any>();
    const [rowStatus, setRowStatus] = useState<{ [key: string]: string }>({});
    const [jobFilterData,setJobFilterData] = React.useState<any>({
        search: '',
        sort: {},
        limit: 10,
        job_id: id
    });

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>();
    const [openEmailDrawer,setOpenEmailDrawer]=useState<boolean>(false);
    const [emailDetails,setEmailDetails]=useState<any>(null);
    const [openAddStaffDrawer,setOpenAddStaffDrawer]=useState<boolean>(false);
    const [refreshToken,setRefreshToken]=useState<number>(0);
    const [openDocumentDrawer,setOpenDocumentDrawer]=useState<boolean>(false)
    const [selectedJobId,setSelectedJobId]=useState<string>('');
    const [selectedHcpId,setSelectedHcpId]=useState<string>('');
    const handlePreview = useCallback((fileUrl: any) => {
        setSelectedFile(fileUrl);
        setIsModalVisible(true);
    }, []);
    const [templateList,setTemplateList]=useState<any>([]);
    const [selectedTemplate,setSelectedTemplate]=useState<any>('')
    const [emailMessage,setEmailMessage]=useState<any>('');
    const [isEmailSending,setIsEmailSending]=useState<any>({});

    useEffect(() => {
        if (!urlTab) {
            history.replace(`?tab=${defaultTab}`);
        }
    }, [urlTab, history]);

    const handleTabChange = useCallback((key: string) => {
        setActiveTab(key);
        history.push(`?tab=${key}`);
    },[history])

    const handleStatusChange = useCallback((key: string, newStatus: string) => {
        setRowStatus((prev) => ({
            ...prev,
            [key]: newStatus, // Update only the selected row
        }));
        let payload: any = {};
        payload.status = newStatus;
        ApiService.put(ENV.API_URL + `ats/${key}/jobSubmissons`, payload)
            .then((resp: any) => {
                setJobFilterData({...jobFilterData})
                CommonService.showToast('Status updated successfully','success')
            })
            .catch((err: any) => {
                CommonService.showToast(err.error,'error')
            });
    }, [jobFilterData]);


    const getJobDetails = useCallback(() => {
        setIsJobDetailsLoading(true);
        CommonService._api
            .get(ENV.API_URL + `ats/${id}/job`)
            .then((resp) => {
                setJobDetails(resp.data);
                setIsJobDetailsLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setIsJobDetailsLoading(false)
            });
    }, [id])

    const getJobRateDetails = useCallback(() => {
        setIsRateJobDetailsLoading(true);
        CommonService._api
            .get(ENV.API_URL + `ats/${id}/ratecard`)
            .then((resp) => {
                setJobRateDetails(resp.data);
                setIsRateJobDetailsLoading(false)
            })
            .catch((err) => {
                console.log(err);
            });
    }, [id])

    useEffect(() => {
        getJobDetails();
        getJobRateDetails()
    }, [getJobDetails, getJobRateDetails]);

    const onBack = useCallback(() => {
        let navigate='/job';
        if(props?.location?.state?.from === 'applicant-list'){
            navigate='/applicants'
        }else if(props?.location?.state?.from  === 'pipeline'){
            navigate=`/pipeline${props?.location?.state?.currentTab}`;
        }
        history.push(navigate);
    }, [history,props])

    const handleEmailDrawer=useCallback((details:any)=>{
        setOpenEmailDrawer(true)
        setEmailDetails(details);
    },[])

    const cancelAdd = useCallback(() => {
        setOpenAddStaffDrawer(false);
    }, []);

    const handleDocumentDrawerOpen=useCallback((jobId:string,hcpId:string)=>{
        console.log(jobId,hcpId)
            setSelectedJobId(jobId);
            setSelectedHcpId(hcpId);
            setOpenDocumentDrawer(true);
    },[])

    const handleDocumentDrawerClose=useCallback(()=>{
        setSelectedJobId('');
        setSelectedHcpId('');
        setOpenDocumentDrawer(false);
    },[])

    const getTemplateList=useCallback(()=>{
        CommonService._api.get(ENV.API_URL+ 'ats/listTemplate').then((response:any)=>{
            const updatedList=response?.data?.map((item:any)=>({
                title:item?.template,
                code:item?._id,
                message:item?.template_format
            }))
            setTemplateList(updatedList);
        }).catch((error:any)=>{
            console.log(error)
        })
    },[])

    useEffect(() => {
        getTemplateList()
    }, [getTemplateList]);

    const handleEmailTrigger=useCallback((id:any)=>{
            let payload={
                application_id:id
            }
            setIsEmailSending((prev:any)=>({
                ...prev,
                [id]:true
            }))
            CommonService._api.post(ENV.API_URL + 'ats/sentJobApplicationDoc',payload).then((response:any)=>{
                CommonService.showToast(response?.message,"success");
                setJobFilterData({...jobFilterData})
            }).catch((error:any)=>{
                CommonService.showToast(error.error || error.message || 'Error','error')
            }).finally(()=>{
                setIsEmailSending((prev:any)=>({
                    ...prev,
                    [id]:false
                }))
            })
    },[jobFilterData])


    const JobSubmissionTableColumns: TableColumnsType =useMemo(()=>{
       return  [
           {
               title: "Name",
               width: 250,
               ellipsis:true,
               render: (item: any) => {
                   return (
                       <div>
                           {(item?.hcp_details?.first_name?.length + item?.hcp_details?.last_name?.length) > 15 ?
                           <Tooltip title={item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name}>
                               <Link
                                   className={'ellipses-for-table-data'}
                                   to={{
                                   pathname:item?.hcp_details?.is_applicant ?`/applicant/view/${item?.hcp_details?._id}` : `/hcp/user/view/${item?.hcp_details?._id}` ,
                                   state:{
                                       from:'job-view-submissions',
                                       job_id:item?.job_id,
                                       currentTab:'?tab=applications',
                                   }
                               }}  style={{textDecoration:"underline",cursor:'pointer',color:'#10c4d3'}}>
                                   {item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name
                                       || "N/A"}</Link>
                           </Tooltip>:
                               <Link to={{
                                   pathname:item?.hcp_details?.is_applicant ?`/applicant/view/${item?.hcp_details?._id}` : `/hcp/user/view/${item?.hcp_details?._id}`,
                                   state:{
                                       from:'job-view-submissions',
                                       job_id:item?.job_id,
                                       currentTab:'?tab=applications',
                                   }
                               }}  style={{textDecoration:"underline",cursor:'pointer',color:'#10c4d3'}}>
                                   {item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name
                                       || "N/A"}</Link>
                           }
                       </div>
                   );
               },
           },
           {
               title: "Source",
               width: 200,
               render: (item: any) => {
                   return (
                       <div>
                           <div>{CommonService.formatTitleCase(item?.applied_source) || "N/A"}</div>
                       </div>
                   );
               },
           },
           {
               title: "Email",
               width: 150,
               render: (item: any) => {
                   return (
                       <div>
                           {item?.hcp_details?.email?.length > 20 ?
                            <Tooltip title={item?.hcp_details?.email}>
                                <div className={'ellipses-for-email'}>
                                    {item?.hcp_details?.email}
                                </div>
                            </Tooltip>:
                               <div>
                                   {item?.hcp_details?.email}
                               </div>
                           }
                       </div>
                   );
               },
           },
           {
               title: "Pay Rate",
               width: 200,
               render: (item: any) => {
                   const contractTotalHourlyRate=item?.job_details?.job_type === 'contract' &&
                       (item?.pay_rate?.travel_per_diems && item?.pay_rate?.travel_per_diems.reduce((sum:any, item:any) => sum + Number(item.rate), 0));
                   const contractTotalWeeklyRate=item?.job_details?.job_type === 'contract' &&
                       ( item?.pay_rate?.travel_per_diems && item?.pay_rate?.travel_per_diems.reduce((sum:any, item:any) => sum + Number(item.week_rate), 0));
                   return (
                       <div>
                           {item?.job_details?.job_type === 'per_diem' ?
                               <div>
                                   {item?.pay_rate?.total_pay_rate || 'N/A'}
                               </div> :
                               item?.job_details?.job_type === 'permanent' ?
                                   <div>
                                        {item?.pay_rate?.salary || 'N/A'}
                                   </div>:
                                   <div>
                                       {item?.pay_rate?.travel_per_diems ?
                                           <>
                                               ${contractTotalHourlyRate}/Hr - ${contractTotalWeeklyRate}/Week
                                           </>: 'N/A'}
                                   </div>
                           }
                       </div>
                   );
               },
           },
           {title:'Document Received',
               width:160,
               render:(item:any)=> {
                   return <div>
                       {item?.required_credentials_count !== 0 ?
                           <div
                               style={{textDecoration:"underline",cursor:'pointer',color:'#10c4d3'}}
                               onClick={()=>handleDocumentDrawerOpen(item?.job_id,item?.hcp_details?._id)}>
                            {item?.received_credentials_count}/{item?.required_credentials_count}
                           </div>: 'N/A'
                       }
                   </div>
               }
           },
           {
               title: "Application Status",
               width: 180,
               render: (item: any) => {
                   return <div className={'table-under-select-box'}>
                       <SelectDropdownComponent
                           placeholder={'Select job status'}
                           value={rowStatus[item._id] || item.status}
                           options={[
                               {title: 'Open', code: 'open'},
                               {title: 'Closed', code: 'closed'},
                               {title: 'Filled', code: 'filled'},
                               {title: 'Hold', code: 'hold'},
                               {title: 'In Progress', code: 'in_progress'},
                               {title:'Submitted',code:'submitted'},
                               {title:'Offer Accepted',code:'offer_accepted'},
                               {title:'Offer Extended',code:'offer_extended'}
                           ]}
                           required={true}
                           size={"middle"}
                           displayWith={(option) => option.title}
                           valueExtractor={(option) => option.code}
                           onChange={(value) => handleStatusChange(item._id, value)}
                       />
                   </div>;
               },
           },
           {
               title:'Send Mail',
               width:100,
               render:(item:any)=>{
                   return (
                       <div style={{marginLeft:'10px'}}>
                           <Button icon={<SendOutlined/>}
                                   iconPosition={'end'} onClick={()=>handleEmailDrawer(item)}>
                           </Button>
                       </div>
                   )
               }
           },
           {
               title:'Document Request',
               width:150,
               render:(item:any)=>{
                   return (
                       <Tooltip placement={'top'} title={`Send an email requesting ${item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name} to upload their documents.`}>
                           <Button style={{marginLeft:'10px'}} loading={isEmailSending[item?._id]} onClick={()=>handleEmailTrigger(item?._id)}
                                   icon={<MailOutlined size={16}/>}
                                   iconPosition={'end'}>
                           </Button>
                       </Tooltip>
                   )
               }
           },
           {
               title: "Action",
               width: 300,
               render: (item: any) => {
                   return(
                       <div className={'d-flex align-items-center'}>
                           <div className={'cursor-pointer underline-text color-underline-text mrg-left-25'}
                                onClick={() => handlePreview(item)}>View Resume
                           </div>
                       </div>
                   );
               },
           },
           {
               expandableComponent: PipelineComponent
           }
       ]
    },[isEmailSending,handleEmailDrawer,handlePreview,handleStatusChange,rowStatus,handleDocumentDrawerOpen,handleEmailTrigger]) ;

    return (
        <>
            <Modal
                title="Resume Preview"
                open={isModalVisible}
                footer={null}
                onCancel={() => {
                    setIsModalVisible(false)
                    setSelectedFile(undefined)
                }}
                width={800}
            >
                <CustomPreviewFile
                    previewData={{
                        url: selectedFile?.resume_url,
                        file_type: selectedFile?.attachments[0]?.file_type,
                    }}/>
            </Modal>
            {/*<DialogComponent open={openAddStaffDrawer} cancel={cancelAdd} headerLabel={'Add Staff to this Job'}>*/}
            {/*    <AddStaffToJobComponent*/}
            {/*        cancel={cancelAdd}*/}
            {/*        staff_type={jobDetails?.staff_type}*/}
            {/*        setRefreshToken={setRefreshToken}*/}
            {/*        id={id}*/}
            {/*        basicDetails={jobDetails}*/}
            {/*    />*/}
            {/*</DialogComponent>*/}
            <DrawerComponent
                isOpen={openAddStaffDrawer}
                title={'Add Staff to Job'}
                showClose={true}
                onClose={()=>{
                    setOpenAddStaffDrawer(false);
                }}
                width={550}
            >
                <AddStaffToJobComponent
                    cancel={cancelAdd}
                    staff_type={jobDetails?.staff_type}
                    setRefreshToken={setRefreshToken}
                    id={id}
                    basicDetails={jobDetails}
                    hcpTypes={hcpTypes}
                />
            </DrawerComponent>
            <DrawerComponent  isOpen={openEmailDrawer} title={'Email'} showClose={false} onClose={()=> {
                setOpenEmailDrawer(false);
                setSelectedTemplate('');
                setEmailMessage('');
            }} width={650}
                headerActions={
                <div className={'d-flex align-items-center template-select-wrapper'}>
                        <SelectComponent
                            options={templateList || []}
                            value={selectedTemplate}
                            placeholder={"Select Template"}
                            allowClear={true}
                            valueExtractor={(option:any)=>option.code}
                            onChange={(value: any) => {
                                const template = value
                                    ? templateList?.find((t:any) => t.code === value)
                                    : null;
                                setSelectedTemplate(value ? value : '');
                                setEmailMessage(value ? template?.message : '');
                            }}
                        />
                    <div className={'mrg-left-20 cursor-pointer'} onClick={()=>setOpenEmailDrawer(false)}>
                        <img src={ImageConfig.CrossIcon} alt="close"/>
                    </div>
                </div>
                }
            >
                <SingleEmailEditorComponent
                        recipientEmail={emailDetails?.hcp_details?.email || ''}
                        setOpenEmailDrawer={setOpenEmailDrawer}
                        emailMessage={emailMessage}
                        setEmailMessage={setEmailMessage}
                    />
            </DrawerComponent>
            <DrawerComponent  isOpen={openDocumentDrawer} title={'Documents'} showClose={true} onClose={handleDocumentDrawerClose} width={700}>
                    <RequiredDocumentListComponent
                        hcpId={selectedHcpId}
                        jobId={selectedJobId}
                        setRefreshToken={setRefreshToken}
                    />
            </DrawerComponent>
            <div className={'view-job-container-whole'}>
                <div className={'view-job-header'}>
                    <div className={'d-flex header-back-content'} onClick={onBack}>
                        <img src={ImageConfig.BackIcon} alt="back"/>
                        <div className={'header-back mrg-left-10'}>
                            Back
                        </div>
                    </div>
                    <div className="header-title pdd-10">View Job</div>
                    <div>
                        <Link to={`/job/edit/` + id} className="add-job-link">
                            <Button
                                type="primary"
                                className="add-job-button"
                            >
                                Edit Job
                            </Button>
                        </Link>
                    </div>
                </div>
                {isJobDetailsLoading &&
                    <div className={'flex-equal d-flex justify-content-center align-items-center'}>
                        <div>
                            <Spin indicator={<LoadingOutlined style={{fontSize: 50, color: "#40D6C3"}} spin/>}
                                  size={"large"} spinning={true}>
                            </Spin>

                        </div>
                    </div>}
                {(!isJobDetailsLoading && !isJobRateDetailsLoading) &&
                    <div className={'job-view-details-content'}>
                        <Tabs defaultActiveKey={activeTab} onChange={handleTabChange} destroyInactiveTabPane={true}>
                            {/* Basic Details Section */}
                            <TabPane tab="Basic Details" key="basic_details">
                                <div className={'job-details-content-container'}>
                                    <div
                                        className={'d-flex align-items-center justify-content-space-between mrg-bottom-20'}>
                                        <Title level={5}>Job Details</Title>
                                        <div className={'job-view-button-wrapper'}>
                                            {/*<Button className={'add-job-button'} onClick={()=>CommonService.showToast('Coming Soon!','info')}>*/}
                                            {/*    Matching Applicants*/}
                                            {/*</Button>*/}
                                            <Button className={'add-job-button'}
                                                    onClick={() => setOpenAddStaffDrawer(true)}>
                                                Add Staff
                                            </Button>
                                            <Tooltip title={'Share to twitter'}>
                                                <div
                                                    className={'share-logo-wrapper'}
                                                    onClick={() => window.open(`https://twitter.com/intent/tweet?text=${jobDetails?.job_title}&url=${ENV.API_JOB_BOARD_URL}job/${id}`, "_blank")}
                                                >
                                                    <img src={TwitterIcon} alt={'twitter'}/>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title={'Share to facebook'}>
                                                <div
                                                    className={'share-logo-wrapper'}
                                                    onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${ENV.API_JOB_BOARD_URL}job/${id}`, "_blank")}
                                                >
                                                    <img src={FacebookIcon} alt={'facebook'}/>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title={'Share to linkedIn'}>
                                                <div
                                                    className={'share-logo-wrapper'}
                                                    onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${ENV.API_JOB_BOARD_URL}job/${id}`, "_blank")}
                                                >
                                                    <img src={LinkedInIcon} alt={'linkedin'}/>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <div className={'section-heading'}>Basic Details:</div>
                                    {/*First row*/}
                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Facility</div>
                                            <div>{jobDetails?.facility_details?.name || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Job Type</div>
                                            <div>{jobDetails?.job_type ? CommonService.formatTitleCase(jobDetails?.job_type) : 'N/A'}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Job Title</div>
                                            <div>{jobDetails?.job_title || "N/A"}</div>
                                        </Col>
                                    </Row>

                                    {/*Second row*/}

                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Job ID</div>
                                            <div>
                                                {jobDetails?._id
                                                    ? jobDetails?._id?.slice(-7)
                                                    : "N/A"}
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Recruiter</div>
                                            <div>
                                                {jobDetails?.recruiter_details
                                                    ? `${jobDetails?.recruiter_details?.first_name} ${jobDetails?.recruiter_details?.last_name}`
                                                    : "N/A"}
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Job Status</div>
                                            <div>{jobDetails?.job_status ? CommonService.formatTitleCase(jobDetails?.job_status) : "N/A"}</div>
                                        </Col>
                                        {/*<Col span={8}>*/}
                                        {/*    <div className={'text-header-for-soft-content'}>Job Id*/}
                                        {/*    </div>*/}
                                        {/*    <div>{jobDetails?.job_id || "-"}</div>*/}
                                        {/*</Col>*/}
                                    </Row>


                                    {/*Fourth Row*/}

                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Profession</div>
                                            <div>{jobDetails?.staff_type || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Specialty</div>
                                            <div>{jobDetails?.speciality || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Offerings</div>
                                            <div>{jobDetails?.offerings ? CommonService.formatTitleCase(jobDetails?.offerings) : "N/A"}</div>
                                        </Col>
                                    </Row>

                                    {/*Seventh Row*/}
                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Number of Positions</div>
                                            <div>{jobDetails?.no_of_positions || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Tax Terms</div>
                                            <div>{jobDetails?.tax_terms || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Remote Work</div>
                                            <div>{jobDetails?.remote_job || "No"}</div>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Job Category</div>
                                            <div>{jobDetails?.job_category || "N/A"}</div>
                                        </Col>
                                    </Row>

                                    <div className={'section-heading'}>Address Details:</div>
                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Street</div>
                                            <div>{jobDetails?.address?.street || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>City</div>
                                            <div>{jobDetails?.address?.city || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>State</div>
                                            <div>{jobDetails?.address?.state || "N/A"}</div>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Country</div>
                                            <div>{jobDetails?.address?.country || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Zip Code</div>
                                            <div>{jobDetails?.address?.zip_code || "N/A"}</div>
                                        </Col>
                                    </Row>

                                    <div className={'section-heading'}>Job Timings:</div>

                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        {jobDetails?.job_type === 'permanent' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Start Date</div>
                                                    <div>{jobDetails?.job_start_date ? CommonService.getUtcDate(jobDetails?.job_start_date) : "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>End Date</div>
                                                    <div>{jobDetails?.job_end_date ? CommonService.getUtcDate(jobDetails?.job_end_date) : "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                        {jobDetails?.job_type === 'per_diem' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Start Date</div>
                                                    <div>{jobDetails?.per_diem_details?.start_date ? CommonService.getUtcDate(jobDetails?.per_diem_details?.start_date) : "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>End Date</div>
                                                    <div>{jobDetails?.per_diem_details?.end_date ? CommonService.getUtcDate(jobDetails?.per_diem_details?.end_date) : "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                        {jobDetails?.job_type === 'contract' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Start Date</div>
                                                    <div>{jobDetails?.contract_details?.start_date ? CommonService.getUtcDate(jobDetails?.contract_details?.start_date) : "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>End Date</div>
                                                    <div>{jobDetails?.contract_details?.end_date ? CommonService.getUtcDate(jobDetails?.contract_details?.end_date) : "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                        {jobDetails?.job_type === 'contract' &&
                                            <Col span={8}>
                                                <div className={'text-header-for-soft-content'}>Shift Type</div>
                                                <div>{jobDetails?.contract_details?.shift_type ?
                                                    jobDetails?.contract_details?.shift_type : "N/A"}</div>
                                            </Col>
                                        }
                                        {jobDetails?.job_type === 'per_diem' &&
                                            <Col span={8}>
                                                <div className={'text-header-for-soft-content'}>Shift Type</div>
                                                <div>{jobDetails?.per_diem_details?.shift_type ?
                                                    jobDetails?.per_diem_details?.shift_type : "N/A"}</div>
                                            </Col>
                                        }
                                        {jobDetails?.job_type === 'permanent' &&
                                            <Col span={8}>
                                                <div className={'text-header-for-soft-content'}>Shift Type</div>
                                                <div>{jobDetails?.shift_type ?
                                                    jobDetails?.shift_type : "N/A"}</div>
                                            </Col>
                                        }
                                    </Row>

                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        {jobDetails?.job_type === 'contract' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>No of Days Per
                                                        Week
                                                    </div>
                                                    <div>{jobDetails?.contract_details?.days_per_week || "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>No of Weeks</div>
                                                    <div>{jobDetails?.contract_details?.no_of_weeks || "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Hours Per Shift
                                                    </div>
                                                    <div>{jobDetails?.contract_details?.shift_duration || "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        {jobDetails?.job_type === 'per_diem' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Start Time</div>
                                                    <div>{jobDetails?.per_diem_details?.start_time ?
                                                        CommonService.convertMinsToHrsMins(jobDetails?.per_diem_details?.start_time) : "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>End Time</div>
                                                    <div>{jobDetails?.per_diem_details?.end_time ?
                                                        CommonService.convertMinsToHrsMins(jobDetails?.per_diem_details?.end_time) : "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                        {jobDetails?.job_type === 'contract' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Start Time</div>
                                                    <div>{jobDetails?.contract_details?.start_time ?
                                                        CommonService.convertMinsToHrsMins(jobDetails?.contract_details?.start_time) : "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>End Time</div>
                                                    <div>{jobDetails?.contract_details?.end_time ?
                                                        CommonService.convertMinsToHrsMins(jobDetails?.contract_details?.end_time) : "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                        {jobDetails?.job_type === 'permanent' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Start Time</div>
                                                    <div>{jobDetails?.start_time ?
                                                        CommonService.convertMinsToHrsMins(jobDetails?.start_time) : "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>End Time</div>
                                                    <div>{jobDetails?.end_time ?
                                                        CommonService.convertMinsToHrsMins(jobDetails?.end_time) : "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                    </Row>


                                    <Divider/>

                                    {/* Job Description Section */}
                                    <Title level={5}>Job Description</Title>
                                    <Typography.Paragraph>
                                        <div dangerouslySetInnerHTML={{__html: jobDetails?.job_description || 'N/A'}}
                                             style={{width: '100%'}}></div>
                                        {/*{jobDetails?.job_description || "N/A"}*/}
                                    </Typography.Paragraph>
                                    <Divider/>
                                    <Title level={5}>Job Benefits</Title>
                                    <Typography.Paragraph>
                                        <div dangerouslySetInnerHTML={{__html: jobDetails?.job_benefits || 'N/A'}}
                                             style={{width: '100%'}}></div>
                                    </Typography.Paragraph>
                                    <Divider/>
                                    <Title level={5}>Job Requirements</Title>
                                    <Typography.Paragraph>
                                        <div dangerouslySetInnerHTML={{__html: jobDetails?.job_requirements || 'N/A'}}
                                             style={{width: '100%'}}></div>
                                    </Typography.Paragraph>


                                    <Divider/>

                                    <Title level={5}>Posting Configuration</Title>
                                    <Row gutter={[16, 16]} className={'mrg-bottom-10'}>
                                        <Col span={6}>
                                            <div className={'job-basic-details-header-wrapper'}>On Company website</div>
                                            <div>{jobDetails?.posting_status?.website ? "Yes" : "No"}</div>
                                        </Col>
                                        <Col span={6}>
                                            <div className={'job-basic-details-header-wrapper'}>On mobile</div>
                                            <div>{jobDetails?.posting_status?.mobile_app ? "Yes" : "No"}</div>
                                        </Col>
                                        <Col span={6}>
                                            <div className={'job-basic-details-header-wrapper'}>On job boards
                                            </div>
                                            <div>{jobDetails?.posting_status?.is_job_boards ? "Yes" : "No"}</div>
                                        </Col>
                                        <Col span={6}>
                                            <div className={'job-basic-details-header-wrapper'}>Show Facility on Job
                                                Board
                                            </div>
                                            <div>{jobDetails?.posting_status?.show_facility ? "Yes" : "No"}</div>
                                        </Col>
                                    </Row>
                                    {jobDetails?.posting_status?.is_job_boards &&
                                        <div>
                                            <Row gutter={[16, 16]}>
                                                <Col span={24}>
                                                    <div>
                                                        <div
                                                            className={'text-header-for-soft-content  mrg-bottom-10'}>Free
                                                            Job
                                                            boards
                                                        </div>
                                                        <div className={'view-details-document-tag'}>
                                                            {Array.isArray(jobDetails?.posting_status?.job_boards) && jobDetails?.posting_status?.job_boards?.map((docName: any, index: any) => {
                                                                return (
                                                                    <>
                                                                        <div className={'document-tag'}>
                                                                            <div
                                                                                className={'job-doc-text'}>{CommonService.formatTitleCase(docName) || 'N/A'}</div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={24}>
                                                    <div>
                                                        <div
                                                            className={'text-header-for-soft-content  mrg-bottom-10 mrg-top-10'}>Paid
                                                            Job
                                                            boards
                                                        </div>
                                                        <div className={'view-details-document-tag'}>
                                                            {Array.isArray(jobDetails?.posting_status?.paid_job_boards) && jobDetails?.posting_status?.paid_job_boards?.map((docName: any, index: any) => {
                                                                return (
                                                                    <>
                                                                        <div className={'document-tag'}>
                                                                            <div
                                                                                className={'job-doc-text'}>{CommonService.formatTitleCase(docName) || 'N/A'}</div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    }

                                    <Divider/>

                                    {/* Documents Section */}
                                    <Title level={5}>Documents</Title>
                                    {jobDetails?.required_credentials?.documents_submission?.length > 0 &&
                                        <div className={'mrg-bottom-20 mrg-top-10'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}>Required
                                                documents for
                                                submission
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.documents_submission?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.license_submission?.length > 0 &&
                                        <div className={'mrg-bottom-20'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}>Required
                                                license for
                                                submission
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.license_submission?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.certifications_submission?.length > 0 &&
                                        <div className={'mrg-bottom-20'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}>Required
                                                certifications for
                                                submission
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.certifications_submission?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.skill_checklist_submission?.length > 0 &&
                                        <div className={'mrg-bottom-20'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}>Required
                                                skills checklist for
                                                submission
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.skill_checklist_submission?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.competency_test?.length > 0 &&
                                        <div className={'mrg-bottom-20 mrg-top-10'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}>Required
                                                documents for Competency Test
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.competency_test?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.documents_eboarding?.length > 0 &&
                                        <div className={'mrg-bottom-20'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}>
                                                Required documents for eBoarding
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.documents_eboarding?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.license_eboarding?.length > 0 &&
                                        <div className={'mrg-bottom-20'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}> Required
                                                license
                                                for eBoarding
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.license_eboarding?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.certifications_eboarding?.length > 0 &&
                                        <div className={'mrg-bottom-20'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}> Required
                                                certifications for eBoarding
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.certifications_eboarding?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    <Divider/>

                                    {/* Skills Section */}
                                    <Title level={5}>Skills</Title>
                                    <Descriptions column={1} size="middle">
                                        <Descriptions.Item label="Degree">
                                            <div className={'view-details-document-tag'}>
                                                {(Array.isArray(jobDetails?.job_qualifications?.degree) && jobDetails?.job_qualifications?.degree?.length !== 0) ?
                                                    jobDetails.job_qualifications.degree.map((docName: any, index: number) => (
                                                        <div key={index} className="document-tag">
                                                            <div
                                                                className="job-doc-text">{CommonService.formatTitleCase(docName)}</div>
                                                        </div>
                                                    )) : 'N/A'}
                                            </div>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Experience">
                                            <div className={'view-details-document-tag'}>
                                                <div className={'document-tag'}>
                                                    <div
                                                        className={'job-doc-text'}>{jobDetails?.job_qualifications?.min_experience} years
                                                        to {jobDetails?.job_qualifications?.max_experience} years
                                                    </div>
                                                </div>
                                            </div>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Priority">
                                            <div className={'view-details-document-tag'}>
                                                <div className={'document-tag'}>
                                                    <div
                                                        className={'job-doc-text'}>{String(jobDetails?.job_qualifications?.priority) ? CommonService.formatTitleCase(jobDetails?.job_qualifications?.priority) : 'N/A'}
                                                    </div>
                                                </div>
                                            </div>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Languages">
                                            <div className={'view-details-document-tag'}>
                                                {(Array.isArray(jobDetails?.job_qualifications?.languages) && jobDetails?.job_qualifications?.languages?.length !== 0) ? jobDetails?.job_qualifications?.languages?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div
                                                                    className={'job-doc-text'}>{CommonService.formatTitleCase(docName)}</div>
                                                            </div>
                                                        </>
                                                    )
                                                }) : 'N/A'}
                                            </div>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Skills">
                                            <div className={'view-details-document-tag'}>
                                                {(Array.isArray(jobDetails?.job_qualifications?.skills) && jobDetails?.job_qualifications?.skills?.length !== 0) ? jobDetails?.job_qualifications?.skills?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div
                                                                    className={'job-doc-text'}>{CommonService.formatTitleCase(docName)}</div>
                                                            </div>
                                                        </>
                                                    )
                                                }) : 'N/A'}
                                            </div>
                                        </Descriptions.Item>
                                    </Descriptions>


                                    {/* Posting Configuration Section */}

                                </div>
                            </TabPane>

                            {/* Rate Details Section */}
                            <TabPane tab="Rate Details" key="rate_details">
                                <div className={'job-details-content-container'}>
                                    {jobDetails?.job_type ==='per_diem' &&
                                        <PerDiemRateCardDetailsComponent rateDetails={jobRateDetails?.per_diem_rate_card}/>}
                                    {jobDetails?.job_type ==='contract' &&
                                        <ContractRateCardDetailsComponent rateDetails={jobRateDetails?.contract_rate_card}/>}
                                    {jobDetails?.job_type ==='permanent' &&
                                    <PermanentRateCardDetailsComponent rateDetails={jobRateDetails?.permanent_rate_card}/>}

                                </div>
                            </TabPane>

                            {/* Submissions Details Section */}
                            <TabPane tab="Applications" key="applications">
                                <AntTableComponent
                                    key={refreshToken}
                                    isBordered={true}
                                    url={ENV.API_URL + "ats/jobSubmissons"}
                                    method={'post'}
                                    extraPayload={jobFilterData}
                                    expandable={true}
                                    columns={JobSubmissionTableColumns}
                                    noDataText={'No Submissions Found.'}
                                    isRowSelection={false}
                                />
                            </TabPane>

                            <TabPane tab="Matching Applicants" key='matching_applicants'>
                                <MatchingApplicantComponent jobDetails={jobDetails}/>
                            </TabPane>

                            {/* Notes Details Section*/}
                            <TabPane tab="Notes" key="notes" className={'job-notes-tab'}>
                                <JobNotesComponent id={id} jobDetails={jobDetails}/>
                            </TabPane>

                            <TabPane tab="Activity Logs" key="activity_logs" className={'job-notes-tab'}>
                               <JobActivityLogsComponent/>
                            </TabPane>
                        </Tabs>
                    </div>
                }
            </div>
        </>
    )
}

export default JobViewDetailsComponent
