import {Button, Paper, TextField, Tooltip} from "@material-ui/core";
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import moment from "moment";
import * as Yup from "yup";
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ENV} from "../../../../constants";
import {ApiService, CommonService} from "../../../../helpers";
import "./EditTimelineComponent.scss";
import DatePickerComponent from "../../../../form-controls/date-picker/DatePickerComponent";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Checkbox from "@material-ui/core/Checkbox";

export interface EditTimelineComponentProps {
    cancel: () => void;
    confirm: () => void;
    shiftDetails: any;
}


// const editTimelineFieldValidation = Yup.object({
//     check_in_time: Yup.mixed().nullable(),
//     check_in_date: Yup.mixed().nullable(),
//     check_out_time: Yup.mixed().nullable(),
//     check_out_date: Yup.mixed().nullable(),
//
//     break_in_time_1: Yup.mixed().nullable(),
//     break_out_time_1: Yup.mixed().nullable(),
//     break_in_date_1: Yup.mixed().nullable(),
//     break_out_date_1: Yup.mixed().nullable(),
//     break_in_time_2: Yup.mixed().nullable(),
//     break_out_time_2: Yup.mixed().nullable(),
//     break_in_date_2: Yup.mixed().nullable(),
//     break_out_date_2: Yup.mixed().nullable(),
// });


const editTimelineFieldValidation = Yup.object({
    check_in_time: Yup.mixed().nullable().test('valid-time', 'Invalid time format', function (value) {
        if (!value) return true;
        return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
    }),
    check_in_date: Yup.mixed().nullable(),
    check_out_time: Yup.mixed()
        .nullable()
        .test('valid-time', 'Invalid time format', function (value) {
            if (!value) return true;
            return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
        })
        .test(
            'after-times',
            'Checkout time must be after ClockIn and Break times',
            function (value) {
                if (!value) return true;
                const checkInTime = this.parent.check_in_time;
                const breakInTime1 = this.parent.break_in_time_1;
                const breakInTime2 = this.parent.break_in_time_2;
                const breakOutTime1 = this.parent.break_out_time_1;
                const breakOutTime2 = this.parent.break_out_time_2;
                const checkInDate = this.parent.check_in_date;
                const breakInDate1 = this.parent.break_in_date_1;
                const breakInDate2 = this.parent.break_in_date_2;
                const breakOutDate1 = this.parent.break_out_date_1;
                const breakOutDate2 = this.parent.break_out_date_2;
                const checkOutDate = this.parent.check_out_date

                const checkInDateTime = checkInTime ? new Date(`${checkInDate}T${checkInTime}`) : null;
                const breakIn1DateTime = breakInTime1 ? new Date(`${breakInDate1}T${breakInTime1}`) : null;
                const breakIn2DateTime = breakInTime2 ? new Date(`${breakInDate2}T${breakInTime2}`) : null;
                const breakOut1DateTime = breakOutTime1 ? new Date(`${breakOutDate1}T${breakOutTime1}`) : null;
                const breakOut2DateTime = breakOutTime2 ? new Date(`${breakOutDate2}T${breakOutTime2}`) : null;
                const checkoutDateTime = new Date(`${checkOutDate}T${value}`);

                return (
                    (!checkInDateTime || (checkoutDateTime && checkoutDateTime > checkInDateTime)) &&
                    (!breakIn1DateTime || (checkoutDateTime && checkoutDateTime > breakIn1DateTime)) &&
                    (!breakIn2DateTime || (checkoutDateTime && checkoutDateTime > breakIn2DateTime)) &&
                    (!breakOut1DateTime || (checkoutDateTime && checkoutDateTime > breakOut1DateTime)) &&
                    (!breakOut2DateTime || (checkoutDateTime && checkoutDateTime > breakOut2DateTime))
                );
            }
        ),


    //check_out_date: Yup.mixed().nullable(),
    check_out_date: Yup.mixed().nullable().when('check_out_time', {
        is: (checkOutTime: any) => !!checkOutTime,
        then: Yup.mixed()
            .test(
                'valid-date',
                'Clock Out date should be equal to or greater than Clock In date and break dates',
                function (value: any) {
                    const checkInDate = this.resolve(Yup.ref('check_in_date')) as any;
                    // const breakOut1Date = this.resolve(Yup.ref('break_out_date_1')) as any;
                    // const breakIn1Date = this.resolve(Yup.ref('break_in_date_1')) as any;
                    // const breakOut2Date = this.resolve(Yup.ref('break_out_date_2')) as any;
                    // const breakIn2Date = this.resolve(Yup.ref('break_in_date_2')) as any;
                    return !value || (checkInDate && value >= checkInDate
                        //&& breakOut1Date && value >= breakOut1Date && breakIn1Date && value >= breakIn1Date && breakOut2Date && value >= breakOut2Date && breakIn2Date && value >= breakIn2Date
                    );
                }
            )
            .typeError('Clock Out date must be a valid date')
            .required('Clock Out date is required'),
        otherwise: Yup.mixed().nullable(),
    }),

    break_in_time_1: Yup.mixed()
        .nullable()
        .test('valid-time', 'Invalid time format', function (value) {
            if (!value) return true;
            return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
        })
        .test(
            'after-break-in',
            'Breakin 1 time must be after Clock In time',
            function (value) {
                if (!value || !this.parent.check_in_time) return true;
                const checkIn = this.parent.check_in_time;
                const breakout = value;
                const checkInDate = this.parent.check_in_date;
                const breakoutDate = this.parent.break_in_date_1;
                const breakInDateTime = new Date(`${checkInDate}T${checkIn}:00`);
                const breakoutDateTime = new Date(`${breakoutDate}T${breakout}:00`);
                return breakoutDateTime > breakInDateTime;
            }
        ),
    break_out_time_1: Yup.mixed()
        .nullable()
        .test('valid-time', 'Invalid time format', function (value) {
            if (!value) return true;
            return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
        })
        .test(
            'after-break-in',
            'Breakout 1 time must be after breakin 1 time',
            function (value) {
                if (!value || !this.parent.break_in_time_1) return true;
                const breakIn = this.parent.break_in_time_1;
                const breakout = value;
                const breakInDate = this.parent.break_in_date_1 || this.parent.check_in_date;
                const breakoutDate = this.parent.break_out_date_1 || this.parent.check_in_date;
                const breakInDateTime = new Date(`${breakInDate}T${breakIn}:00`);
                const breakoutDateTime = new Date(`${breakoutDate}T${breakout}:00`);
                return breakoutDateTime > breakInDateTime;
            }
        ),
    // break_in_date_1: Yup.mixed().nullable(),
    break_in_date_1: Yup.mixed().nullable().when('break_in_time_1', {
        is: (breakInTime: any) => !!breakInTime,
        then: Yup.mixed()
            .test(
                'valid-date',
                'Break In 1 date should be greater than or equal to Clock In date',
                function (value: any) {
                    const checkInDate = this.resolve(Yup.ref('check_in_date')) as any;
                    return !value || (checkInDate && value >= checkInDate);
                }
            )
            .typeError('Break In 1 date must be a valid date')
            .required('Break In 1 date is required'),
        otherwise: Yup.mixed().nullable()
    }),
    break_out_date_1: Yup.mixed().nullable(),
    // break_in_time_2: Yup.mixed()
    //     .nullable()
    //     .test('valid-time', 'Invalid time format', function(value) {
    //         if (!value) return true;
    //         return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
    //     }),
    break_in_time_2: Yup.mixed()
        .nullable()
        .test(
            'valid-time',
            'Breakout 2 time must be after Break in 1 time',
            function (value) {
                const checkInTime = this.parent.check_in_time;
                const checkInDate = this.parent.check_in_date;
                const breakInTime1 = this.parent.break_in_time_1;
                const breakInDate1 = this.parent.break_in_date_1;
                const breakOutTime1 = this.parent.break_out_time_1;
                const breakOutDate1 = this.parent.break_out_date_1;
                const breakInDate2 = this.parent.break_in_date_2;

                if (!value) return true;

                if (
                    checkInTime ||
                    checkInDate ||
                    breakInTime1 ||
                    breakInDate1 ||
                    breakOutTime1 ||
                    breakOutDate1
                ) {
                    const checkInDateTime = checkInTime ? new Date(`${checkInDate}T${checkInTime}`) : null;
                    const breakIn1DateTime = breakInTime1 ? new Date(`${breakInDate1}T${breakInTime1}`) : null;
                    const breakOut1DateTime = breakOutTime1 ? new Date(`${breakOutDate1}T${breakOutTime1}`) : null;
                    const breakIn2DateTime = new Date(`${breakInDate2}T${value}`);

                    return (
                        (!checkInDateTime || breakIn2DateTime > checkInDateTime) &&
                        (!breakIn1DateTime || breakIn2DateTime > breakIn1DateTime) &&
                        (!breakOut1DateTime || breakIn2DateTime > breakOut1DateTime)
                    );
                }

                return true;
            }
        ),
    break_out_time_2: Yup.mixed()
        .nullable()
        .test('valid-time', 'Invalid time format', function (value) {
            if (!value) return true;
            return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
        })
        .test(
            'after-break-in',
            'Breakout 2 time must be after breakin 2 time',
            function (value) {
                if (!value || !this.parent.break_in_time_2) return true;
                const breakIn = this.parent.break_in_time_2;
                const breakout = value;
                const breakInDate = this.parent.break_in_date_2 || this.parent.check_in_date;
                const breakoutDate = this.parent.break_out_date_2 || this.parent.check_in_date;
                const breakInDateTime = new Date(`${breakInDate}T${breakIn}:00`);
                const breakoutDateTime = new Date(`${breakoutDate}T${breakout}:00`);
                return breakoutDateTime > breakInDateTime;
            }
        ),
    break_in_date_2: Yup.mixed().nullable().when('break_in_time_2', {
        is: (breakInTime: any) => !!breakInTime,
        then: Yup.mixed()
            .test(
                'valid-date',
                'Break In 2 date should be greater than or equal to break In 1 date',
                function (value: any) {
                    const checkInDate = this.resolve(Yup.ref('break_in_date_1')) as any;
                    return !value || (checkInDate && value >= checkInDate);
                }
            )
            .typeError('Break In 2 date must be a valid date')
            .required('Break In 2 date is required'),
        otherwise: Yup.mixed().nullable()
    }),
    break_out_date_2: Yup.mixed().nullable(),
});


const EditTimelineComponent = (props: PropsWithChildren<EditTimelineComponentProps>) => {
    const {cancel, confirm, shiftDetails} = props;
    console.log(shiftDetails,"SHIFT DETAILS")
    const [isAlreadyCheckedIn, setIsAlreadyCheckedIn] = useState<boolean>(false);
    const [isAlreadyCheckedOut, setIsAlreadyCheckedOut] = useState<boolean>(false);
    const [breakId1, setBreakId1] = useState(null);
    const [breakId2, setBreakId2] = useState(null);
    const [overRideBreakTime,setOverRideBreakTime] = useState<boolean>(false);
    const [timeBreakup] = useState(shiftDetails?.time_breakup);
    const param = useParams<any>();
    const {id} = param;

    useEffect(() => {
        if (timeBreakup?.check_in_time) {
            setIsAlreadyCheckedIn(true);
        }
        if (timeBreakup?.check_out_time) {
            setIsAlreadyCheckedOut(true);
        }
        if (timeBreakup?.break_timings?.length > 0) {
            if (timeBreakup.break_timings[0]?._id) {
                setBreakId1(timeBreakup.break_timings[0]?._id);
            }
            if (timeBreakup.break_timings[1]?._id) {
                setBreakId2(timeBreakup.break_timings[1]?._id);
            }
        }
        setOverRideBreakTime(shiftDetails?.is_manual_break_overridden || false);
    }, [timeBreakup?.break_timings, timeBreakup?.check_in_time, timeBreakup?.check_out_time,shiftDetails]);

    const handleEditTimelineInitialState = () => {
        const {start_time: check_in_time, end_time: check_out_time} = CommonService.getUtcTimeinHours(
            timeBreakup?.check_in_time,
            timeBreakup?.check_out_time
        );

        const {start_time: break_in_time_1, end_time: break_out_time_1} = CommonService.getUtcTimeinHours(
            timeBreakup?.break_timings?.[0]?.break_in_time,
            timeBreakup?.break_timings?.[0]?.break_out_time
        );

        const {start_time: break_in_time_2, end_time: break_out_time_2} = CommonService.getUtcTimeinHours(
            timeBreakup?.break_timings?.[1]?.break_in_time,
            timeBreakup?.break_timings?.[1]?.break_out_time
        );

        const check_in_date = CommonService.getUtcDate(timeBreakup?.check_in_time);
        const check_out_date = CommonService.getUtcDate(timeBreakup?.check_out_time);

        const break_in_date_1 = CommonService.getUtcDate(timeBreakup?.break_timings?.[0]?.break_in_time);
        const break_out_date_1 = CommonService.getUtcDate(timeBreakup?.break_timings?.[0]?.break_out_time);

        const break_in_date_2 = CommonService.getUtcDate(timeBreakup?.break_timings?.[1]?.break_in_time);
        const break_out_date_2 = CommonService.getUtcDate(timeBreakup?.break_timings?.[1]?.break_out_time);

        return {
            check_in_time: check_in_time ?? null,
            check_out_time: check_out_time ?? null,
            check_in_date: check_in_date ? moment(check_in_date).format("YYYY-MM-DD") : null,
            check_out_date: check_out_date ? moment(check_out_date).format("YYYY-MM-DD") : null,

            break_in_time_1: break_in_time_1 ?? null,
            break_out_time_1: break_out_time_1 ?? null,
            break_in_date_1: break_in_date_1 ? moment(break_in_date_1).format("YYYY-MM-DD") : null,
            break_out_date_1: break_out_date_1 ? moment(break_out_date_1).format("YYYY-MM-DD") : null,

            break_in_time_2: break_in_time_2 ?? null,
            break_out_time_2: break_out_time_2 ?? null,
            break_in_date_2: break_in_date_2 ? moment(break_in_date_2).format("YYYY-MM-DD") : null,
            break_out_date_2: break_out_date_2 ? moment(break_out_date_2).format("YYYY-MM-DD") : null,
        };
    };

    const onAdd = useCallback(
        (data, {setSubmitting, setErrors}: FormikHelpers<any>) => {
            setSubmitting(true);


            let {
                check_in_date,
                check_out_date,
                check_in_time,
                check_out_time,
                break_out_time_1,
                break_out_time_2,
                break_in_date_1,
                break_in_date_2,
                break_in_time_1,
                break_in_time_2,
                break_out_date_2,
                break_out_date_1,
            } = data;

            if (!check_in_time) {
                CommonService.showToast("Please fill Clock-in Time" || "Error", "error");
                setSubmitting(false);
                return;
            }
            if (!check_in_date) {
                CommonService.showToast("Please fill Clock-in Date" || "Error", "error");
                setSubmitting(false);
                return;
            }


            if (check_out_time && !check_out_date) {
                CommonService.showToast("Please fill Clock-out Date" || "Error", "error");
                setSubmitting(false);
                return;
            }

            if (check_out_date && !check_out_time) {
                CommonService.showToast("Please fill Clock-out Time" || "Error", "error");
                setSubmitting(false);
                return;
            }


            let break_timings: any[] = [];

            if (break_in_time_1 || break_out_time_1) {
                break_timings[0] = {
                    break_in_time: break_in_time_1 ? CommonService.convertHoursToMinutes(break_in_time_1) : null,
                    break_out_time: break_out_time_1 ? CommonService.convertHoursToMinutes(break_out_time_1) : null,
                    break_in_date: break_in_date_1,
                    break_out_date: break_out_date_1,
                };

                if (breakId1) {
                    break_timings[0]._id = breakId1;
                }
            }

            if (break_in_time_2 || break_out_time_2) {
                break_timings[1] = {
                    break_in_time: break_in_time_2 ? CommonService.convertHoursToMinutes(break_in_time_2) : null,
                    break_out_time: break_out_time_2 ? CommonService.convertHoursToMinutes(break_out_time_2) : null,
                    break_in_date: break_in_date_2,
                    break_out_date: break_out_date_2,
                };
                if (breakId2) {
                    break_timings[1]._id = breakId2;
                }
            }

            let payload: any = {
                hcp_user_id: shiftDetails.hcp_user_id,
                break_timings: break_timings,
            };

            if (check_in_time) {

                payload = {
                    hcp_user_id: shiftDetails.hcp_user_id,
                    break_timings: break_timings,
                    check_in_time: check_in_time ? CommonService.convertHoursToMinutes(check_in_time) : null,
                    check_in_date: check_in_date,
                    type: 'check_in'

                };

                if (isAlreadyCheckedIn) {
                    delete payload.type;
                }

            }

            if (check_out_time) {
                payload = {
                    ...payload,
                    hcp_user_id: shiftDetails.hcp_user_id,
                    break_timings: break_timings,
                    check_out_time: check_out_time ? CommonService.convertHoursToMinutes(check_out_time) : null,
                    check_out_date: check_out_date,
                    type: "check_out",
                };
                if (isAlreadyCheckedOut) {
                    delete payload.type;
                }
            }
            payload.is_manual_break_overridden=overRideBreakTime;

            //if check_out_time -> "type": "check_out",
            //if check_in_time -> "type": "check_in",
            //if both -> avoid sending type field

            ApiService.post(ENV.API_URL + "shift/" + id + "/webCheckInOut", payload)
                .then((resp: any) => {
                    if (resp && resp.success) {
                        CommonService.showToast(resp.msg || "success", "success");
                        confirm();
                        setSubmitting(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    CommonService.showToast(err?.msg || err?.error || "Invalid Date", "error");
                    confirm();
                    setSubmitting(false);
                });
        },
        [confirm, id, breakId1, breakId2, shiftDetails, isAlreadyCheckedOut, isAlreadyCheckedIn,overRideBreakTime]
    );


    return (
        <Paper>
            <div className="edit-timeline-container mrg-20 pdd-20">
                <div
                    className="edit-timeline-header">{`${shiftDetails?.hcp_details?.first_name} ${shiftDetails?.hcp_details?.last_name}`}</div>
                <div className="edit-timeline-sub-header">Update Shift Timeline Details</div>

                <Formik initialValues={handleEditTimelineInitialState()} validationSchema={editTimelineFieldValidation}
                        onSubmit={onAdd} validateOnChange={true} validateOnBlur={true}>
                    {({isValid, resetForm, setFieldValue, values, isSubmitting}) => (
                        <Form>
                            <div className="edit-timeline-title">Clock-In</div>
                            <div className="input-container mrg-top-20">
                                <Field
                                    variant="outlined"
                                    type="time"
                                    InputLabelProps={{shrink: true}}
                                    name="check_in_time"
                                    component={TextField}
                                    label="Clock-In Time"
                                    fullWidth
                                    value={values.check_in_time}
                                    onChange={(e: any) => setFieldValue("check_in_time", e?.target?.value)}
                                />
                                <div style={{color: "red"}}>
                                    <ErrorMessage name="check_in_time"/>
                                </div>
                                <DatePickerComponent placeholder={'Clock-In Date (MM/DD/YYYY)'}
                                                     value={moment(values.check_in_date).toISOString(false)}
                                                     onChange={(e: any) => setFieldValue("check_in_date", e)}/>
                                <div style={{color: "red"}}>
                                    <ErrorMessage name="check_in_date"/>
                                </div>
                            </div>

                            {shiftDetails?.status !== "pending" && (
                                <>
                                    <div className=" d-flex">
                                        <div className={'edit-timeline-title '}>
                                            1st Break
                                        </div>
                                        {(values.break_in_time_1 || values.break_out_time_1 || values.break_in_time_2 || values.break_out_time_2) &&
                                            <Tooltip title={"Clear Break Timings"} placement={'top-start'} arrow={true}>
                                                <div className={"cursor-pointer mrg-left-10"} onClick={() => {
                                                    setFieldValue("break_in_time_1", "");
                                                    setFieldValue("break_in_date_1", null);
                                                    setFieldValue("break_out_time_1", "");
                                                    setFieldValue("break_out_date_1", null);
                                                    setFieldValue("break_in_time_2", "");
                                                    setFieldValue("break_in_date_2", null);
                                                    setFieldValue("break_out_time_2", "");
                                                    setFieldValue("break_out_date_2", null);
                                                }}>
                                                    <HighlightOffIcon color={"error"}/>
                                                </div>
                                            </Tooltip>}
                                    </div>
                                    <div className="input-container mrg-top-10">
                                        <Field
                                            variant="outlined"
                                            type="time"
                                            InputLabelProps={{shrink: true}}
                                            name="break_in_time_1"
                                            component={TextField}
                                            label="Start Break Time "
                                            fullWidth
                                            value={values.break_in_time_1}
                                            onChange={(e: any) => setFieldValue("break_in_time_1", e.target.value)}
                                        />
                                        <div style={{color: "red"}}>
                                            <ErrorMessage name="break_in_time_1"/>
                                        </div>
                                        <DatePickerComponent placeholder={'Start Break Date (MM/DD/YYYY)'}
                                                             value={moment(values.break_in_date_1).toISOString(false)}
                                                             onChange={(e: any) => setFieldValue("break_in_date_1", e)}/>
                                        <div style={{color: "red"}}>
                                            <ErrorMessage name="break_in_date_1"/>
                                        </div>
                                    </div>

                                    <div className="input-container mrg-top-20">
                                        {values.break_in_time_1 && <>
                                            <Field
                                                variant="outlined"
                                                type="time"
                                                InputLabelProps={{shrink: true}}
                                                name="break_out_time_1"
                                                component={TextField}
                                                label="End Break Time "
                                                fullWidth
                                                value={values.break_out_time_1}
                                                onChange={(e: any) => setFieldValue("break_out_time_1", e.target.value)}
                                            />
                                            <div style={{color: "red"}}>
                                                <ErrorMessage name="break_out_time_1"/>
                                            </div>
                                        </>}
                                        <DatePickerComponent placeholder={'End Break Date (MM/DD/YYYY)'}
                                                             value={moment(values.break_out_date_1).toISOString(false)}
                                                             onChange={(e: any) => setFieldValue("break_out_date_1", e)}/>
                                        <div style={{color: "red"}}>
                                            <ErrorMessage name="break_out_date_1"/>
                                        </div>

                                    </div>

                                    <div className="edit-timeline-title">2nd Break</div>
                                    <div className="input-container mrg-top-10">
                                        <Field
                                            variant="outlined"
                                            type="time"
                                            InputLabelProps={{shrink: true}}
                                            name="break_in_time_2"
                                            component={TextField}
                                            label="Start Break Time "
                                            fullWidth
                                            value={values.break_in_time_2}
                                            onChange={(e: any) => setFieldValue("break_in_time_2", e.target.value)}
                                        />
                                        <div style={{color: "red"}}>
                                            <ErrorMessage name="break_in_time_2"/>
                                        </div>
                                        <DatePickerComponent placeholder={'Start Break Date (MM/DD/YYYY)'}
                                                             value={moment(values.break_in_date_2).toISOString(false)}
                                                             onChange={(e: any) => setFieldValue("break_in_date_2", e)}/>
                                        <div style={{color: "red"}}>
                                            <ErrorMessage name="break_in_date_2"/>
                                        </div>
                                    </div>

                                    <div className="input-container mrg-top-20">
                                        {values.break_in_time_2 && <>
                                            <Field
                                                variant="outlined"
                                                type="time"
                                                InputLabelProps={{shrink: true}}
                                                name="break_out_time_2"
                                                component={TextField}
                                                label="End Break Time "
                                                fullWidth
                                                value={values.break_out_time_2}
                                                onChange={(e: any) => setFieldValue("break_out_time_2", e.target.value)}
                                            />
                                            <div style={{color: "red"}}>
                                                <ErrorMessage name="break_out_time_2"/>
                                            </div>
                                        </>}
                                        <DatePickerComponent placeholder={'End Break Date (MM/DD/YYYY)'}
                                                             value={moment(values.break_out_date_2).toISOString(false)}
                                                             onChange={(e: any) => setFieldValue("break_out_date_2", e)}/>
                                        <div style={{color: "red"}}>
                                            <ErrorMessage name="break_out_date_2"/>
                                        </div>

                                    </div>
                                    <div className="edit-timeline-title">Clock-Out</div>
                                    <div className="input-container mrg-top-20">
                                        <Field
                                            variant="outlined"
                                            type="time"
                                            InputLabelProps={{shrink: true}}
                                            name="check_out_time"
                                            component={TextField}
                                            label="Clock-Out Time "
                                            fullWidth
                                            value={values.check_out_time}
                                            onChange={(e: any) => setFieldValue("check_out_time", e.target.value)}
                                        />
                                        <div style={{color: "red"}}>
                                            <ErrorMessage name="check_out_time"/>
                                        </div>
                                        <DatePickerComponent placeholder={'Clock-Out Date (MM/DD/YYYY)'}
                                                             value={moment(values.check_out_date).toISOString(false)}
                                                             onChange={(e: any) => setFieldValue("check_out_date", e)}/>
                                        <div style={{color: "red"}}>
                                            <ErrorMessage name="check_out_date"/>
                                        </div>
                                    </div>
                                </>
                            )}
                            {shiftDetails?.is_break_taken &&
                                <div className={'d-flex align-items-center mrg-top-20'}>
                                    <div>
                                        <Checkbox id={"cb_"}
                                                  checked={overRideBreakTime}
                                                  onChange={(event:any) => {
                                                      setOverRideBreakTime(event.target.checked)
                                                  }
                                                  }/>
                                    </div>
                                    <div style={{display: "flex", width: "350px"}}>
                                        <div style={{
                                            color: 'black',
                                            fontSize: '1rem'
                                        }}> Override Manual Break Time.
                                        </div>
                                    </div>
                                </div>
                            }
                                <div className="edit-timeline-actions mrg-top-20">
                                    <Button variant="outlined" size="large" color="primary" id="cancel-timeline-btn"
                                            onClick={cancel}>
                                        Back
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="large"
                                        id="btn_timeline_edit_submit"
                                        variant={"contained"}
                                        className={isSubmitting ? "normal has-loading-spinner" : "normal"}
                                        color={"primary"}
                                    >
                                        {!isSubmitting ? "Save" : "Saving"}
                                    </Button>
                                </div>
                        </Form>
                        )}
                </Formik>
            </div>
        </Paper>
);
};
export default EditTimelineComponent;
