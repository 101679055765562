import "./JobEditBasicDetailsComponent.scss";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {StateParams} from "../../../../../store/reducers";
import {ApiService, CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import { Form, Formik, FormikHelpers} from "formik";
import {Divider, Form as AntdForm, Radio, Skeleton, Tooltip} from "antd";
import FormLabelComponent from "../../../../ant-component/form-label/FormLabelComponent";
import FormikSelectDropdownComponent from "../../../../ant-component/formik-select-dropdown/FormikSelectDropdownComponent";
import FormikInputComponent from "../../../../ant-component/formik-input-component/FormikInputComponent";
import FormikTimePickerComponent from "../../../../ant-component/formik-time-picker/FormikTimePickerComponent";
import {languages, shiftDurationList, weekDaysList} from "../../../../../constants/data";
import FormikDatePickerComponent from "../../../../ant-component/formik-date-picker/FormikDatePickerComponent";
import TextEditorComponent from "../../../../../components/text-editor/TextEditorComponent";
import {useParams} from "react-router-dom";
import {InfoCircleOutlined} from "@ant-design/icons";
import {freeJobBoardList, paidJobBoardList} from "../../../../../data";
import {AiIcon,AiGif} from "../../../../../constants/ImageConfig";
import dayjs from 'dayjs';

interface jobBasicDetailsProps {
    next: () => void;
    jobDetails?:any;
    facilityList?:any;
    modifiedNcList?:any;
    getJobDetails?:any;
    setIsSubmitting?:any;
    setHasErrors?:any;
    jobTitleRef?:any;
    zipcodeRef?:any;
}
const validationSchema = Yup.object({
    // facility_id: Yup.string().required("Facility ID is required"),
    job_type: Yup.string().required("Job Type is required"),
    job_title: Yup.string().required("Job title is required"),
    address:Yup.object({
        zip_code:Yup.string().matches(/^\d{5}(-\d{4})?$/, "Invalid ZIP code")
    })
});


const JobEditBasicDetailsComponent=(props:jobBasicDetailsProps)=>{
    const {next,jobDetails,facilityList,modifiedNcList,getJobDetails,setIsSubmitting,setHasErrors,jobTitleRef,zipcodeRef} = props;
    const params=useParams<any>();
    const {id}=params;
    const {regions, isLoading} = useSelector((state: StateParams) => state.meta);
    const {hcpTypes, specialitiesMasterList} = useSelector((state: StateParams) => state.meta);
    const [hcpTypeSpecialities, setHcpTypeSpecialities] = useState<any>([]);
    const [jobDescription,setJobDescription]=useState<any>('');
    const [jobBenifits,setJobBenifits]=useState<any>('');
    const [jobRequirements,setJobRequirements]=useState<any>('');
    const [isDescriptionGenerating,setIsDescriptionGenerating]=useState<boolean>(false);
    const [isBenefitsGenerating,setIsBenefitsGenerating]=useState<boolean>(false);
    const [isRequirementGenerating,setIsRequirementGenerating]=useState<boolean>(false);
    const {user}=useSelector((state:StateParams)=>state.auth);


    useEffect(() => {
        if(jobDetails){
            setJobDescription(jobDetails?.job_description)
            setJobBenifits(jobDetails?.job_benefits)
            setJobRequirements(jobDetails?.job_requirements)
        }
    }, [jobDetails]);

    const disablePreviousDates = (current:any) => {
        // Disable all dates before today
        return current && current < dayjs().startOf('day');
    };

    useEffect(() => {
        const selectedSpeciality = specialitiesMasterList[jobDetails?.staff_type];
        setHcpTypeSpecialities(selectedSpeciality);
    }, [jobDetails?.staff_type, specialitiesMasterList]);

    const jobEditInitialValues = useCallback(() => (
        {
            facility_id: jobDetails?.facility_id || "",
            job_title: jobDetails?.job_title || "",
            job_type: jobDetails?.job_type || "",
            remote_job: jobDetails?.remote_job || "",
            staff_type:jobDetails?.staff_type|| "",
            offerings:jobDetails?.offerings || "",
            is_clearance_required: jobDetails?.is_clearance_required ? "Yes" : "No",
            speciality: jobDetails?.speciality || "",
            job_category: jobDetails?.job_category || "",
            job_status: jobDetails?.job_status || "",
            job_start_date: jobDetails?.job_start_date ? CommonService.getUtcDate(jobDetails?.job_start_date) : '',
            job_end_date: jobDetails?.job_end_date ?  CommonService.getUtcDate(jobDetails?.job_end_date) : '',
            start_time:jobDetails?.start_time ? CommonService.convertMinsToHrsMins(jobDetails?.start_time):"",
            end_time:jobDetails?.end_time ? CommonService.convertMinsToHrsMins(jobDetails?.end_time): "",
            shift_type:jobDetails?.shift_type || '',
            per_diem_details:{
                shift_type: jobDetails?.per_diem_details?.shift_type || '',
                start_date: jobDetails?.per_diem_details?.start_date ? CommonService.getUtcDate(jobDetails?.per_diem_details?.start_date) : '',
                end_date: jobDetails?.per_diem_details?.end_date ?  CommonService.getUtcDate(jobDetails?.per_diem_details?.end_date) : '',
                start_time:jobDetails?.per_diem_details?.start_time ? CommonService.convertMinsToHrsMins(jobDetails?.per_diem_details?.start_time):"",
                end_time:jobDetails?.per_diem_details?.end_time ? CommonService.convertMinsToHrsMins(jobDetails?.per_diem_details?.end_time):"",
            },
            work_authorization: jobDetails?.work_authorization || "",
            recruiter_id: (user?.role === 'recruiter' ? user?._id :  jobDetails?.recruiter_id || "") ||  "",
            no_of_positions: jobDetails?.no_of_positions || "",
            tax_terms: jobDetails?.tax_terms || "",
            job_description: jobDetails?.job_description || "",
            job_benefits: jobDetails?.job_benefits || "",
            job_requirements: jobDetails?.job_requirements || "",
            contract_details:{
                days_per_week:jobDetails?.contract_details?.days_per_week || '',
                no_of_weeks:jobDetails?.contract_details?.no_of_weeks || '',
                shift_duration:jobDetails?.contract_details?.shift_duration || '',
                shift_type: jobDetails?.contract_details?.shift_type || '',
                start_date: jobDetails?.contract_details?.start_date ? CommonService.getUtcDate(jobDetails?.contract_details?.start_date) : '',
                end_date: jobDetails?.contract_details?.end_date ?  CommonService.getUtcDate(jobDetails?.contract_details?.end_date) : '',
                start_time:jobDetails?.contract_details?.start_time ? CommonService.convertMinsToHrsMins(jobDetails?.contract_details?.start_time):"",
                end_time:jobDetails?.contract_details?.end_time ? CommonService.convertMinsToHrsMins(jobDetails?.contract_details?.end_time):"",
            },
            address:{
                street:jobDetails?.address?.street || "",
                city:jobDetails?.address?.city || "",
                state:jobDetails?.address?.state || "",
                country:jobDetails?.address?.country || 'US',
                zip_code:jobDetails?.address?.zip_code || "",
            },
            job_qualifications: {
                degree: jobDetails?.job_qualifications?.degree || [],
                skills: jobDetails?.job_qualifications?.skills ? jobDetails?.job_qualifications?.skills?.join(',') : '',
                languages: jobDetails?.job_qualifications?.languages || [],
                priority: jobDetails?.job_qualifications?.priority || "",
                min_experience: jobDetails?.job_qualifications?.min_experience || "",
                max_experience: jobDetails?.job_qualifications?.max_experience || "",
            },
            posting_status: {
                website: jobDetails?.posting_status?.website ? 'Yes': 'No' || '',
                mobile_app: jobDetails?.posting_status?.mobile_app ? "Yes": 'No' || '',
                is_job_boards: jobDetails?.posting_status?.is_job_boards ? "Yes": "No" || '',
                show_facility: jobDetails?.posting_status?.show_facility ? "Yes": "No" || '',
                job_boards:jobDetails?.posting_status?.job_boards || ['google'],
                paid_job_boards:jobDetails?.posting_status?.paid_job_boards || [],
            },

        }), [jobDetails,user?.role,user?._id]);

    const generateJobDescriptionText=useCallback(()=>{
        let payload={
            text:jobDescription,
        }
        setIsDescriptionGenerating(true);
        CommonService._api.post(ENV.API_URL + `ats/generate`,payload).then((response:any)=>{
            console.log(response?.data)
            const text=response?.data?.candidates[0]?.content?.parts[0]?.text || "";
            const formatedText=CommonService.cleanAiTextGenerated(text);
            setJobDescription(formatedText);
        }).catch((error:any)=>{
            console.log(error.error);
        }).finally(()=>{
            setIsDescriptionGenerating(false);
        })
    },[jobDescription])

    const generateJobBenefitsText=useCallback(()=>{
        let payload={
            text:jobBenifits,
        }
        setIsBenefitsGenerating(true);
        //API WILL CHANGE IN FUTURE
        CommonService._api.post(ENV.API_URL + `ats/generate`,payload).then((response:any)=>{
            console.log(response?.data)
            const text=response?.data?.candidates[0]?.content?.parts[0]?.text || "";
            const formatedText=CommonService.cleanAiTextGenerated(text);
            setJobBenifits(formatedText);
        }).catch((error:any)=>{
            console.log(error.error);
        }).finally(()=>{
            setIsBenefitsGenerating(false);
        })
    },[jobBenifits])

    const generateJobRequirementsText=useCallback(()=>{
        let payload={
            text:jobRequirements,
        }
        setIsRequirementGenerating(true);
        //API WILL CHANGE IN FUTURE
        CommonService._api.post(ENV.API_URL + `ats/generate`,payload).then((response:any)=>{
            console.log(response?.data)
            const text=response?.data?.candidates[0]?.content?.parts[0]?.text || "";
            const formatedText=CommonService.cleanAiTextGenerated(text);
            setJobRequirements(formatedText);
        }).catch((error:any)=>{
            console.log(error.error);
        }).finally(()=>{
            setIsRequirementGenerating(false);
        })
    },[jobRequirements])

    const onSubmitBasicDetails = useCallback((values: any, {
        setSubmitting,
    }: FormikHelpers<any>) => {
        setIsSubmitting(true);

        const payload = {
            ...values,
            is_clearance_required: values?.is_clearance_required === "Yes",
            job_description: jobDescription || '',
            job_benefits: jobBenifits || '',
            job_requirements: jobRequirements || '',
            start_time:CommonService.convertHoursToMinutes(values?.start_time || ''),
            end_time:CommonService.convertHoursToMinutes(values?.end_time || ''),
            posting_status: {
                website: values?.posting_status?.website === "Yes",
                mobile_app: values?.posting_status?.mobile_app === "Yes",
                is_job_boards: values?.posting_status?.is_job_boards === "Yes",
                show_facility:values?.posting_status?.show_facility === 'Yes',
                job_boards: values?.posting_status?.job_boards || ['google'],
                paid_job_boards:values?.posting_status?.paid_job_boards || [],
            },
            job_qualifications:{
                ...values?.job_qualifications,
                skills: values?.job_qualifications?.skills ? CommonService.convertCommaSeparateToArray(values?.job_qualifications?.skills) : [],
            },
        }

        if(payload?.job_type === 'per_diem'){
            payload.per_diem_details= {
                ...values?.per_diem_details,
                start_time:CommonService.convertHoursToMinutes(values?.per_diem_details?.start_time || ''),
                end_time:CommonService.convertHoursToMinutes(values?.per_diem_details?.end_time || ''),
            }
        }
        if(payload?.job_type === 'contract'){
            payload.contract_details= {
                ...values?.contract_details,
                start_time:CommonService.convertHoursToMinutes(values?.contract_details?.start_time || ''),
                end_time:CommonService.convertHoursToMinutes(values?.contract_details?.end_time || ''),
            }
        }

        ApiService.put(ENV.API_URL + `ats/${id}/job`, payload)
            .then((resp: any) => {
                CommonService.showToast(resp.message,'success');
                getJobDetails()
                next()
            })
            .catch((err: any) => {
                CommonService.showToast(err.error,'error')
            }).finally(()=>{
            setIsSubmitting(false);
        })
    }, [next,id,jobBenifits,jobRequirements,jobDescription,getJobDetails,setIsSubmitting])

    return (
        <>
            <div className={'job-basic-details-form'}>
                <Formik
                    initialValues={
                        jobEditInitialValues()
                    }
                    onSubmit={onSubmitBasicDetails}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    validateOnBlur={false}
                >
                    {({values,validateForm, handleChange,errors, handleSubmit, setFieldValue, resetForm}) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            if(errors){
                                setHasErrors(errors)
                            }
                        }, [errors]);
                        return (
                            <Form id="add-job-basic-details">
                                <AntdForm layout="vertical">
                                    <div className={'job-main-header'}>Job Details:</div>
                                    <div className={''}>
                                        <div className={'mrg-top-5 flex-equal'}>
                                            <FormLabelComponent Label={"Job Type"}/>
                                        </div>
                                        <div className={'flex-double mrg-top-10'}>
                                            <FormikSelectDropdownComponent
                                                placeholder={'Select Job type'}
                                                name={'job_type'}
                                                size={'middle'}
                                                required={true}
                                                disabled={true}
                                                options={[
                                                    {title: 'Contract', code: 'contract'},
                                                    {title: 'Per Diem', code: 'per_diem'},
                                                    {title: "Permanent", code: 'permanent'},
                                                ]}
                                                //  mode={'tags'}
                                                // label={`Multiple Select dropdown`}
                                                displayWith={(option) => option.title}
                                                valueExtractor={(option) => option.code}
                                                onUpdate={(value: any) => {
                                                    resetForm();
                                                    setJobDescription('');
                                                    setJobBenifits('');
                                                    setJobRequirements('');
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <Divider/>
                                    <>
                                        <div className={''}>
                                            <div className={'section-heading'}>Basic Details:</div>
                                            <div className={'d-flex'} ref={jobTitleRef}>
                                                <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Facility"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select Facility'}
                                                            name={'facility_id'}
                                                            options={facilityList || []}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                            onUpdate={(value) => {
                                                                if (value) {
                                                                    const selectedFacility = facilityList?.find((facility: any) => facility?.code === value);
                                                                    setFieldValue(`address.street`, selectedFacility?.address?.street || '');
                                                                    setFieldValue(`address.city`, selectedFacility?.address?.city || '');
                                                                    setFieldValue(`address.state`, selectedFacility?.address?.state || '');
                                                                    setFieldValue(`address.country`, selectedFacility?.address?.country || '');
                                                                    setFieldValue(`address.zip_code`, selectedFacility?.address?.zip_code || '');
                                                                    setTimeout(() => {
                                                                        validateForm();
                                                                    }, 0);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Recruiter"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select recruiter'}
                                                            name={'recruiter_id'}
                                                            options={modifiedNcList || []}
                                                            required={true}
                                                            size={"middle"}
                                                            disabled={user?.role === 'recruiter'}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Job Title*"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikInputComponent name="job_title" size={'middle'}
                                                                              required={true}
                                                                              placeholder={'Enter job title'}/>
                                                    </div>
                                                </div>
                                                <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Offerings"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select offering'}
                                                            name={'offerings'}
                                                            options={[
                                                                {
                                                                    title: 'Allied-Advanced Practice',
                                                                    code: 'allied_advanced_practice'
                                                                },
                                                                {title: 'Allied-Laboratory', code: 'allied_laboratory'},
                                                                {title: 'Allied-Others', code: 'allied_others'},
                                                                {title: 'Allied-Radiology', code: 'allied_radiology'},
                                                                {title: 'Allied-Rehab', code: 'allied_rehab'},
                                                                {title: 'IT', code: 'it'},
                                                                {title: 'Locums', code: 'locums'},
                                                                {title: 'Non-Clinical', code: 'non-clinical'},
                                                                {title: 'Nursing', code: 'nursing'},
                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Profession"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select profession'}
                                                            name={'staff_type'}
                                                            options={hcpTypes || []}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                            onUpdate={(e) => {
                                                                const selectedSpeciality = specialitiesMasterList[e];
                                                                setHcpTypeSpecialities(selectedSpeciality);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Speciality"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select speciality'}
                                                            name={'speciality'}
                                                            options={hcpTypeSpecialities || []}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Job Category"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select job category'}
                                                            name={'job_category'}
                                                            options={[
                                                                {title: 'None', code: 'none'},
                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Job Status"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select job status'}
                                                            name={'job_status'}
                                                            options={[
                                                                {title: 'Open', code: 'open'},
                                                                {title: 'Closed', code: 'closed'},
                                                                {title: 'Filled', code: 'filled'},
                                                                {title: 'Hold', code: 'hold'},
                                                                {title: 'In Progress', code: 'in_progress'},
                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Number of positions"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikInputComponent name="no_of_positions"
                                                                              size={'middle'}
                                                                              placeholder={'Enter no of positions'}/>
                                                    </div>
                                                </div>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Tax Terms"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select tax terms'}
                                                            name={'tax_terms'}
                                                            options={[
                                                                {title: '1099', code: '1099'},
                                                                {title: 'C2C', code: 'C2C'},
                                                                {title: 'C2H', code: 'C2H'},
                                                                {title: 'Contract-W2', code: 'Contract-W2'},
                                                                {title: 'Full Time', code: 'Full Time'},
                                                                {title: 'Intern', code: 'Intern'},
                                                                {title: 'Other', code: 'Other'},
                                                                {title: 'Part Time', code: 'Part Time'},
                                                                {title: 'Seasonal', code: 'Seasonal'},
                                                                {title: 'W-2', code: 'W-2'},
                                                                {title: 'W2', code: 'W2'},

                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Remote Job"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <AntdForm.Item>
                                                            <Radio.Group
                                                                className="custom-radio"
                                                                value={values.remote_job}
                                                                onChange={(e) => setFieldValue("remote_job", e.target.value)}
                                                            >
                                                                <Radio value="Yes">Yes</Radio>
                                                                <Radio value="No">No</Radio>
                                                                <Radio value="Hybrid">Hybrid</Radio>
                                                            </Radio.Group>
                                                        </AntdForm.Item>
                                                    </div>
                                                </div>
                                                <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                </div>
                                            </div>
                                            <div className={'section-heading'} ref={zipcodeRef}>Address Details:</div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Street"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikInputComponent name="address.street" size={'middle'}
                                                                              placeholder={'Enter street'}/>
                                                    </div>
                                                </div>
                                                <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"City"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikInputComponent name="address.city" size={'middle'}
                                                                              placeholder={'Enter city'}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"State"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select State'}
                                                            name={'address.state'}
                                                            options={regions}
                                                            required={true}
                                                            size={"middle"}
                                                            disabled={isLoading}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Country"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikInputComponent name="address.country" size={'middle'}
                                                                              placeholder={'Enter country'}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Zip Code"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikInputComponent name="address.zip_code" size={'middle'}
                                                                              placeholder={'Enter Zip Code'}
                                                                              maxLength={6}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                </div>
                                            </div>
                                            <div className={'section-heading'}>Job Timings:</div>
                                            {values?.job_type === 'permanent' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Start Date"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikDatePickerComponent
                                                                // size={size}
                                                                name={'job_start_date'}
                                                                required
                                                                size={"middle"}
                                                                layout={'vertical'}
                                                                className="custom-class"
                                                                disabledDates={disablePreviousDates}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"End Date"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikDatePickerComponent
                                                                // size={size}
                                                                name={'job_end_date'}
                                                                required
                                                                size={"middle"}
                                                                layout={'vertical'}
                                                                className="custom-class"
                                                                disabledDates={disablePreviousDates}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'per_diem' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Start Date"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikDatePickerComponent
                                                                // size={size}
                                                                name={'per_diem_details.start_date'}
                                                                required
                                                                size={"middle"}
                                                                layout={'vertical'}
                                                                className="custom-class"
                                                                disabledDates={disablePreviousDates}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"End Date"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikDatePickerComponent
                                                                // size={size}
                                                                name={'per_diem_details.end_date'}
                                                                required
                                                                size={"middle"}
                                                                layout={'vertical'}
                                                                className="custom-class"
                                                                disabledDates={disablePreviousDates}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'contract' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Start Date"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikDatePickerComponent
                                                                // size={size}
                                                                name={'contract_details.start_date'}
                                                                required
                                                                size={"middle"}
                                                                layout={'vertical'}
                                                                className="custom-class"
                                                                disabledDates={disablePreviousDates}

                                                            />

                                                        </div>
                                                    </div>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"End Date"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikDatePickerComponent
                                                                // size={size}
                                                                name={'contract_details.end_date'}
                                                                required
                                                                size={"middle"}
                                                                layout={'vertical'}
                                                                className="custom-class"
                                                                disabledDates={disablePreviousDates}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'per_diem' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Start Time"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikTimePickerComponent
                                                                name={'per_diem_details.start_time'}
                                                                className="time-wrapper"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"End Time"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikTimePickerComponent
                                                                name={'per_diem_details.end_time'}
                                                                className="time-wrapper"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'permanent' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Start Time"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikTimePickerComponent
                                                                name={'start_time'}
                                                                className="time-wrapper"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"End Time"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikTimePickerComponent
                                                                name={'end_time'}
                                                                className="time-wrapper"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'contract' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Start Time"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikTimePickerComponent
                                                                name={'contract_details.start_time'}
                                                                className="time-wrapper"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"End Time"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikTimePickerComponent
                                                                name={'contract_details.end_time'}
                                                                className="time-wrapper"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'contract' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"No. of Days per Week"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikSelectDropdownComponent
                                                                placeholder={'Select'}
                                                                name={'contract_details.days_per_week'}
                                                                options={weekDaysList || []}
                                                                required={true}
                                                                size={"middle"}
                                                                //  mode={'multiple'}
                                                                // label={`Multiple Select dropdown`}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.code}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"No. of Weeks"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <div className={'flex-double'}>
                                                                <FormikInputComponent
                                                                    name="contract_details.no_of_weeks"
                                                                    size={'middle'}
                                                                    required={true}
                                                                    placeholder={'No of Weeks'}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'contract' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Hours per Shift"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikSelectDropdownComponent
                                                                placeholder={'Select Duration'}
                                                                name={'contract_details.shift_duration'}
                                                                options={shiftDurationList}
                                                                required={true}
                                                                size={"middle"}
                                                                //  mode={'multiple'}
                                                                // label={`Multiple Select dropdown`}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.code}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Shift Type"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikSelectDropdownComponent
                                                                placeholder={'Select shift type'}
                                                                name={'contract_details.shift_type'}
                                                                options={[
                                                                    {title: 'AM', code: 'AM'},
                                                                    {title: 'PM', code: 'PM'},
                                                                    {title: 'NOC', code: 'NOC'},
                                                                    {title: 'Flexible', code: 'Flexible'}
                                                                ] || []}
                                                                required={true}
                                                                size={"middle"}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.code}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'per_diem' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Shift Type"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikSelectDropdownComponent
                                                                placeholder={'Select Shift Type'}
                                                                name={'per_diem_details.shift_type'}
                                                                options={[
                                                                    {title: 'AM', code: 'AM'},
                                                                    {title: 'PM', code: 'PM'},
                                                                    {title: 'NOC', code: 'NOC'},
                                                                    {title: 'Flexible', code: 'flexible'}
                                                                ] || []}
                                                                required={true}
                                                                size={"middle"}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.code}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal'}/>
                                                </div>
                                            }
                                            {values?.job_type === 'permanent' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Shift Type"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikSelectDropdownComponent
                                                                placeholder={'Select Shift Type'}
                                                                name={'shift_type'}
                                                                options={[
                                                                    {title: 'AM', code: 'AM'},
                                                                    {title: 'PM', code: 'PM'},
                                                                    {title: 'NOC', code: 'NOC'},
                                                                    {title: 'Flexible', code: 'flexible'}
                                                                ] || []}
                                                                required={true}
                                                                size={"middle"}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.code}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal'}/>
                                                </div>
                                            }
                                        </div>
                                        <div className={'section-heading'}>Job Description:</div>
                                        <div className={'job-editor-wrapper'}>
                                            <TextEditorComponent
                                                value={jobDescription}
                                                onChange={(value) => setJobDescription(value)}
                                                placeholder={'Enter job description'}
                                                className={'job-text-editor'}
                                            />
                                            <Tooltip title={'Generate Job Description using AI'} placement={'top'}>
                                                <div className={'job-ai-btn'} onClick={generateJobDescriptionText}>
                                                    {isDescriptionGenerating ?
                                                        <img src={AiGif} alt={'Generating....'} className={'ai-image'}/>
                                                        :
                                                        <img src={AiIcon} alt={'ai-icon'} className={'ai-image'}/>
                                                    }
                                                </div>
                                            </Tooltip>
                                            {isDescriptionGenerating &&
                                                <div className={'job-ai-generating-box'}>
                                                    <Skeleton active className={'ai-generating-skeleton'}/>
                                                </div>
                                            }
                                        </div>

                                        <div className={'section-heading'}>Job Benefits:</div>
                                        <div className={'job-editor-wrapper'}>
                                            <TextEditorComponent
                                                value={jobBenifits}
                                                onChange={(value) => setJobBenifits(value)}
                                                placeholder={'Enter job benefits'}
                                                className={'job-text-editor'}
                                            />
                                            <Tooltip title={'Generate Job Benefits using AI'} placement={'top'}>
                                                <div className={'job-ai-btn'} onClick={generateJobBenefitsText}>
                                                    {isBenefitsGenerating ?
                                                        <img src={AiGif} alt={'Generating....'} className={'ai-image'}/>
                                                        :
                                                        <img src={AiIcon} alt={'ai-icon'} className={'ai-image'}/>
                                                    }
                                                </div>
                                            </Tooltip>
                                            {isBenefitsGenerating &&
                                                <div className={'job-ai-generating-box'}>
                                                    <Skeleton active className={'ai-generating-skeleton'}/>
                                                </div>
                                            }
                                        </div>

                                        <div className={'section-heading'}>Job Requirements:</div>
                                        <div className={'job-editor-wrapper'}>
                                            <TextEditorComponent
                                                value={jobRequirements}
                                                onChange={(value) => setJobRequirements(value)}
                                                placeholder={'Enter job requirements'}
                                                className={'job-text-editor'}
                                            />
                                            <Tooltip title={'Generate Job Requirements using AI'} placement={'top'}>
                                                <div className={'job-ai-btn'} onClick={generateJobRequirementsText}>
                                                    {isRequirementGenerating ?
                                                        <img src={AiGif} alt={'Generating....'} className={'ai-image'}/>
                                                        :
                                                        <img src={AiIcon} alt={'ai-icon'} className={'ai-image'}/>
                                                    }
                                                </div>
                                            </Tooltip>
                                            {isRequirementGenerating &&
                                                <div className={'job-ai-generating-box'}>
                                                    <Skeleton active className={'ai-generating-skeleton'}/>
                                                </div>
                                            }
                                        </div>


                                        <div className={'section-heading'}>Skills:</div>
                                        <div className={'d-flex'}>
                                            <div className={'flex-equal pdd-right-85'}>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Degree"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select degree'}
                                                            name={'job_qualifications.degree'}
                                                            options={[
                                                                {title: 'Associate Degree', code: 'associate_degree'},
                                                                {title: 'Bachelors Degree', code: 'bachelors_degree'},
                                                                {title: 'HighSchool', code: 'high_school'},
                                                                {title: 'Masters Degree', code: 'masters_degree'},
                                                                {title: 'PHD', code: 'phd'},
                                                                {title: 'Undergraduate', code: 'under_graduate'},
                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Priority"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select Priority'}
                                                            name={'job_qualifications.priority'}
                                                            options={[
                                                                {title: 'Critical', code: 'critical'},
                                                                {title: 'High', code: 'high'},
                                                                {title: 'Low', code: 'low'},
                                                                {title: 'Medium', code: 'medium'},
                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Languages"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select Languages'}
                                                            name={'job_qualifications.languages'}
                                                            options={languages}
                                                            required={true}
                                                            size={"middle"}
                                                            mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex-equal'}>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Experience"}/>
                                                    </div>
                                                    <div className={'flex-double d-flex'}>
                                                        <div className={'flex-equal'}>
                                                            <FormikInputComponent
                                                                name="job_qualifications.min_experience"
                                                                size={'middle'} placeholder={'Minimum'}/>
                                                        </div>
                                                        <div className={'flex-equal mrg-left-10'}>
                                                            <FormikInputComponent
                                                                name="job_qualifications.max_experience"
                                                                size={'middle'} placeholder={'Maximum'}/>
                                                        </div>
                                                        <div
                                                            className={'d-flex  justify-content-center mrg-left-10 mrg-top-5'}>
                                                            in years
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Skills"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <div className={'flex-double'}>
                                                            <FormikInputComponent
                                                                name="job_qualifications.skills"
                                                                size={'middle'}
                                                                required={true}
                                                                placeholder={'Skills'}
                                                                suffix={
                                                                    <Tooltip title={'Separate skills with commas (,)'}>
                                                                        <InfoCircleOutlined style={{color: "#ffa940"}}/>
                                                                    </Tooltip>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {/*<div className={'mrg-top-5 flex-equal'}>*/}
                                                    {/*    <FormLabelComponent Label={"Skills"}/>*/}
                                                    {/*</div>*/}
                                                    {/*<div className={'flex-double'}>*/}
                                                    {/*    <FormikSelectDropdownComponent*/}
                                                    {/*        placeholder={'Select skills'}*/}
                                                    {/*        name={'job_qualifications.skills'}*/}
                                                    {/*        options={[*/}
                                                    {/*            {title: 'Skill1', code: 'skill1'},*/}
                                                    {/*            {title: 'Skill2', code: 'skill2'},*/}
                                                    {/*            {title: 'Skill3', code: 'skill3'},*/}
                                                    {/*        ]}*/}
                                                    {/*        required={true}*/}
                                                    {/*        size={"middle"}*/}
                                                    {/*        mode={'multiple'}*/}
                                                    {/*        // label={`Multiple Select dropdown`}*/}
                                                    {/*        displayWith={(option) => option.title}*/}
                                                    {/*        valueExtractor={(option) => option.code}*/}
                                                    {/*    />*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'section-heading'}>Posting Configuration:</div>
                                        <div className={'job-board-wrapper'}>
                                            <div className={'job-board-selection-wrapper'}>
                                                <div className={'mrg-top-5 flex-equal'} style={{width: '200px'}}>
                                                    <FormLabelComponent Label={"On Company Website"}/>
                                                </div>
                                                <div className={'job-board-radio-wrapper'}>
                                                    <AntdForm.Item>
                                                        <Radio.Group
                                                            className="custom-radio"
                                                            value={values.posting_status.website}
                                                            onChange={(e) => setFieldValue("posting_status.website", e.target.value)}
                                                        >
                                                            <Radio value="Yes">Yes</Radio>
                                                            <Radio value="No">No</Radio>
                                                        </Radio.Group>
                                                    </AntdForm.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'job-board-wrapper'}>
                                            <div className={'job-board-selection-wrapper'}>
                                                <div className={'mrg-top-5 flex-equal'} style={{width: '200px'}}>
                                                    <FormLabelComponent Label={"On Mobile"}/>
                                                </div>
                                                <div className={'job-board-radio-wrapper'}>
                                                    <AntdForm.Item>
                                                        <Radio.Group
                                                            className="custom-radio"
                                                            value={values.posting_status.mobile_app}
                                                            onChange={(e) => setFieldValue("posting_status.mobile_app", e.target.value)}
                                                        >
                                                            <Radio value="Yes">Yes</Radio>
                                                            <Radio value="No">No</Radio>
                                                        </Radio.Group>
                                                    </AntdForm.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'job-board-wrapper'}>
                                            <div className={'job-board-selection-wrapper'}>
                                                <div className={'mrg-top-5 flex-equal'} style={{width: '200px'}}>
                                                    <FormLabelComponent Label={"Show Facility on Job boards"}/>
                                                </div>
                                                <div className={'job-board-radio-wrapper'}>
                                                    <AntdForm.Item>
                                                        <Radio.Group
                                                            className="custom-radio"
                                                            value={values.posting_status.show_facility}
                                                            onChange={(e) => setFieldValue("posting_status.show_facility", e.target.value)}
                                                        >
                                                            <Radio value="Yes">Yes</Radio>
                                                            <Radio value="No">No</Radio>
                                                        </Radio.Group>
                                                    </AntdForm.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'job-board-wrapper'}>
                                            <div className={'job-board-selection-wrapper'}>
                                                <div className={'mrg-top-5 flex-equal'} style={{width: '200px'}}>
                                                    <FormLabelComponent Label={"On Job Boards"}/>
                                                </div>
                                                <div className={'job-board-radio-wrapper'}>
                                                    <AntdForm.Item>
                                                        <Radio.Group
                                                            className="custom-radio"
                                                            value={values.posting_status.is_job_boards}
                                                            onChange={(e) => setFieldValue("posting_status.is_job_boards", e.target.value)}
                                                        >
                                                            <Radio value="Yes">Yes</Radio>
                                                            <Radio value="No">No</Radio>
                                                        </Radio.Group>
                                                    </AntdForm.Item>
                                                </div>
                                            </div>

                                            <div className={'job-board-option-wrapper'}>
                                                {values?.posting_status?.is_job_boards === 'Yes' &&
                                                    <div className={'d-flex'}>
                                                        <div className={'flex-equal'}>
                                                            <FormikSelectDropdownComponent
                                                                className={'job-board-select'}
                                                                placeholder={'Select Free Job Board'}
                                                                name={'posting_status.job_boards'}
                                                                options={freeJobBoardList || []}
                                                                required={true}
                                                                size={"middle"}
                                                                mode={'multiple'}
                                                                // label={`Multiple Select dropdown`}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.code}
                                                            />
                                                        </div>
                                                        <div className={'flex-equal mrg-left-20'}>
                                                            <FormikSelectDropdownComponent
                                                                className={'job-board-select'}
                                                                placeholder={'Select Paid Job Board'}
                                                                name={'posting_status.paid_job_boards'}
                                                                options={paidJobBoardList || []}
                                                                required={true}
                                                                size={"middle"}
                                                                mode={'multiple'}
                                                                // label={`Multiple Select dropdown`}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.code}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                </AntdForm>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </>
    )
}
export default JobEditBasicDetailsComponent