import "./ChangeRecruiterComponent.scss";
import FormLabelComponent from "../../../../ant-component/form-label/FormLabelComponent";
import SelectDropdownComponent from "../../../../ant-component/select/SelectComponent";
import {useCallback, useEffect, useState} from "react";
import TextEditorComponent from "../../../../../components/text-editor/TextEditorComponent";
import {Button} from "antd";
import {CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";

interface ChangeRecruiterComponentProps{
    selectedJobs:string[];
    setOpenChangeRecruiterModal?:any;
    setSelectedJobs?:any;
    filterData?:any;
    setFilterData?:any;
    module?:any;
}

const ChangeRecruiterComponent=(props:ChangeRecruiterComponentProps)=>{
    const {selectedJobs,setOpenChangeRecruiterModal,setSelectedJobs,filterData,setFilterData,module}=props;
    const [recruiterId,setRecruiterId]=useState<string>("");
    const [reason,setReason]=useState<string>('');
    const [isSubmitting,setIsSubmitting]=useState<boolean>(false)
    const [isNcListLoading,setIsNcListLoading]=useState<boolean>(false);
    const [modifiedNcList,setModifiedNcList]=useState<any>([]);

    const getNcList=useCallback(()=>{
        setIsNcListLoading(true);
        CommonService._api.get(ENV.API_URL + `user/lite?is_active=true&roles[]=recruiter`).then((response:any)=>{
            const transformedData = response?.data?.map((item:any) => ({
                code: item?._id,
                title: item?.first_name + " " + item?.last_name,
            }));
            setModifiedNcList(transformedData || []);
        }).catch((error:any)=>{
            console.log(error)
        }).finally(()=>{
            setIsNcListLoading(false);
        })
    },[])

    useEffect(() => {
        getNcList()
    }, [getNcList]);

    const handleChangeRecruiter=useCallback(()=>{
        setIsSubmitting(true);
        let apiCall:any;
        let payload:any;
        if(module === 'applicant'){
            payload={
                user_id:recruiterId,
                reason:reason,
                hcp_ids:selectedJobs
            }
            apiCall=CommonService._api.put(ENV.API_URL + `ats/user/linkHcps`,payload)
        }else{
            payload={
                user_id:recruiterId,
                reason:reason,
                job_ids:selectedJobs
            }
            apiCall= CommonService._api.put(ENV.API_URL + `ats/user/linkRecruiters`,payload)
        }
        // ats/user/linkHcps
        // ats/user/linkRecruiters
        //integrate API
        apiCall.then((response:any)=>{
            CommonService.showToast(response.message || 'Successfully changed the recruiter','success')
            setFilterData({...filterData})
        }).catch((error:any)=>{
            CommonService.showToast(error.error,'error')
        }).finally(()=>{
            setOpenChangeRecruiterModal(false);
            setSelectedJobs([]);
            setIsSubmitting(false);
        })
    },[module,setOpenChangeRecruiterModal,selectedJobs,recruiterId,reason,setSelectedJobs,filterData,setFilterData])

    const handleCancel=useCallback(()=>{
        setReason('');
        setRecruiterId('');
        setOpenChangeRecruiterModal(false);
        setSelectedJobs([])
    },[setOpenChangeRecruiterModal,setSelectedJobs])

    return (
        <div className={'change-status-component'}>
            <div className={'mrg-top-20'}>
                <FormLabelComponent Label={"Recruiter*"}/>
                <SelectDropdownComponent
                    placeholder={'Select'}
                    className={'mrg-top-10'}
                    value={recruiterId}
                    disabled={isNcListLoading}
                    options={modifiedNcList || []}
                    required={true}
                    size={"middle"}
                    allowClear={true}
                    displayWith={(option) => option.title}
                    valueExtractor={(option) => option.code}
                    onChange={(value: string) => {
                        setRecruiterId(value)
                    }}
                />
            </div>
            <div className={'mrg-top-20'}>
                <FormLabelComponent Label={"Reason(Optional)"}/>
                <div className={'change-status-text-editor-wrapper'}>
                    <TextEditorComponent
                        value={reason}
                        onChange={(value: string) => setReason(value)}
                    />
                </div>
            </div>
            <div className={'status-btn-wrapper'}>
                <Button
                    type={'primary'}
                    disabled={!recruiterId}
                    className="custom-primary-button"
                    onClick={handleChangeRecruiter}
                    loading={isSubmitting}
                >Update</Button>
                <Button className="custom-outline-button" onClick={handleCancel}>Cancel</Button>
            </div>
        </div>
    )
}
export default ChangeRecruiterComponent