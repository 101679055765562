import {Button, Checkbox, Tooltip} from "@material-ui/core";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {ENV, ImageConfig} from "../../../../constants";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import RejectShiftComponent from "../../rejectShift/RejectShiftComponent";
import DialogComponent from "../../../../components/DialogComponent";
import "./ShiftInprogressListScreen.scss";
import HcpSentHomeDialogComponent from "../../hcpSentHomeDialog/HcpSentHomeDialogComponent";
import {CssTextField} from "../../../../constants/data/styles";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
// import WarningIcon from "@material-ui/icons/Warning";
import {Popover, TableColumnsType} from "antd";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import ShiftFilterDrawerComponent from "../../shift-filter-drawer/ShiftFilterDrawerComponent";
import DrawerComponent from "../../../../components/drawer/DrawerComponent";
import {useSessionStorage} from "../../../../components/useSessionStorage";
import ShiftMenuListComponent from "../../shift-menu-list/ShiftMenuListComponent";

const ShiftInprogressListScreen = () => {
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [isRejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);
    const [isHcpSentHomeOpen, setIsHcpSentHomeOpen] = useState<boolean>(false);
    const [refreshToken,setRefreshToken]=useState<number>(0);
    const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [filterData,setFilterData]=useSessionStorage<any>("vitaInprogressShiftFilterData",{
        search:'',
        sort:{},
        new_shifts: CommonService.changeToUtcDate(new Date()),
        status:'in_progress',
        limit:10,
    })
    const [filterCount,setFilterCount]=useState<number>(0);

    useEffect(() => {
        let count=0;
        if(filterData?.start_date)count++;
        if(filterData?.end_date)count++;
        if(filterData?.facilities && filterData?.facilities?.length >0) count++;
        if(filterData?.hcp_types && filterData?.hcp_types?.length >0) count++;
        if(filterData?.shift_types && filterData?.shift_types?.length >0) count++;
        if(filterData?.hcp_user_ids && filterData?.hcp_user_ids?.length >0) count++;
        if(filterData?.type) count++;
        if(filterData?.salary_credit && filterData?.salary_credit?.length > 0) count++;
        setFilterCount(count);
    }, [filterData]);

    useEffect(() => {
        setFilterData((prev: any) => {
            const newUtcDate = CommonService.changeToUtcDate(new Date());
            if (filterData?.new_shifts && (!filterData?.start_date && !filterData?.end_date) && prev.new_shifts !== newUtcDate) {
                return {
                    ...prev,
                    new_shifts: newUtcDate,
                };
            }
            return prev;
        });
    }, [setFilterData,filterData?.new_shifts,filterData?.start_date,filterData?.end_date]);


    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        let payload: any = {};

        // if (selectedRegion.length > 0) {
        //     payload.regions = selectedRegion;
        // }

        if (filterData?.facilities?.length > 0) {
            payload.facilities = filterData?.facilities;
        }
        if (filterData?.hcp_types?.length > 0) {
            payload.hcp_types = filterData?.hcp_types;
        }

        if (filterData?.hcp_user_ids?.length > 0) {
            payload.hcp_user_ids = filterData?.hcp_user_ids;
        }

        if (filterData?.type !== '') {
            payload.type = filterData?.type;
        }

        payload.status = ['in_progress'];

        if (filterData?.salary_credit?.length > 0) {
            payload.salary_credit = filterData?.salary_credit;
        }


        if(filterData?.start_date || filterData?.end_date) {
            let startDate = moment(filterData?.start_date).format("YYYY-MM-DD");
            let endDate = moment(filterData?.end_date).format("YYYY-MM-DD");
            if (!filterData?.end_date) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        } else {
            let today = moment(new Date()).format("YYYY-MM-DD");
            payload.new_shifts = today;
        }

        if (filterData?.shift_types?.length > 0) {
            payload.shift_types = filterData?.shift_types;
        }

        if (filterData?.search !== "") {
            payload.search = filterData?.search;
        }

        ApiService.post(ENV.API_URL + "shift/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.msg || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.msg || "Error", "error");
            });
    }, [filterData]);

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);

    useEffect(() => getFacilityData(), [getFacilityData]);


    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        // setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const confirmRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        // setSelectedCount(-1);
        setSelectedShifts([]);
        setRefreshToken((prev:number)=>prev + 1)
    }, [setSelectedShifts]);

    const cancelHcpSentHome = useCallback(() => {
        setIsHcpSentHomeOpen(false);
        // setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const confirmHcpSentHome = useCallback(() => {
        setIsHcpSentHomeOpen(false);
        setRefreshToken((prev:number)=>prev + 1)
        // setSelectedCount(-1);
        setSelectedShifts([]);
    }, [setSelectedShifts]);


    const clearFilterValues = useCallback(() => {
        setFilterData((prevFilterData:any) => {
            const updatedFilterData = {
                ...prevFilterData,
            };

            // Dynamically remove keys if they exist
            const keysToRemove = [
                "start_date",
                "end_date",
                "facilities",
                "hcp_types",
                "shift_types",
                "salary_credit",
                "type",
                "hcp_user_ids"
            ];

            keysToRemove.forEach((key:any) => delete updatedFilterData[key]);
            updatedFilterData.status='in_progress';
            updatedFilterData.new_shifts=CommonService.changeToUtcDate(new Date())

            return updatedFilterData;
        });
    },[setFilterData]);

    const resetFilters = () => {
        clearFilterValues();
    };

    useEffect(() => {
        Communications.pageTitleSubject.next("Inprogress");
        Communications.pageBackButtonSubject.next(null);
    }, []);


    const inProgressShiftsListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title: 'Facility Name',
                width: 250,
                sorter: (a: any, b: any) => (a?.facility?.name || "").localeCompare(b?.facility?.name || ""),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.facility?.name?.length > 20 ?
                                <Tooltip title={item?.facility?.name || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility?.name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.facility?.name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Staff Name',
                width:250,
                sorter: (a: any, b: any) =>
                    (`${a?.hcp_details?.first_name || ""} ${a?.hcp_details?.last_name || ""}`.trim())
                        .localeCompare(`${b?.hcp_details?.first_name || ""} ${b?.hcp_details?.last_name || ""}`.trim()),
                render:(item:any)=>{
                    return (
                        <div>
                            {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)?.length > 20 ?
                                <Tooltip title={(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Clock In',
                width: 100,
                // sorter: (a: any, b: any) =>
                //     (a["time_breakup"]?.check_in_time || "").localeCompare(b["time_breakup"]?.check_in_time || ""),
                render: (item: any) => {
                    const checkinTime: any = item["time_breakup"]?.check_in_time ? CommonService.startTime(item["time_breakup"]?.check_in_time) : "N/A";
                    const result = CommonService.compareCheckIn(item["expected"], item["time_breakup"]);
                    return (
                        <div>
                            <Tooltip
                                title={`${result.isBefore ? `Staff has clocked in earlier than shift start time by ${result.hours} hour(s) and ${result.minutes} minute(s).` : "Staff has clocked in on time or after the shift start"}`}>
                                <p style={{color: `${result.isBefore ? "red" : ""}`}}>
                                    {checkinTime}
                                </p>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title:'Break In',
                width: 100,
                // sorter: (a: any, b: any) =>
                //     (a["time_breakup"]?.break_timings?.[0]?.break_in_time || "").localeCompare(b["time_breakup"]?.break_timings?.[0]?.break_in_time || ""),
                render:(item:any)=>{
                    const breakInTime: any = item["time_breakup"]?.break_timings?.length > 0 ? CommonService.startTime(item["time_breakup"]?.break_timings[0]?.break_in_time) : "N/A";
                    return (
                        <div>
                            {breakInTime}
                        </div>
                    )
                }
            },
            {
                title:'Break Out',
                width: 120,
                // sorter: (a: any, b: any) =>
                //     (a["time_breakup"]?.break_timings?.[0]?.break_out_time || "").localeCompare(b["time_breakup"]?.break_timings?.[0]?.break_out_time || ""),
                render:(item:any)=>{
                    const breakOutTime: any = item["time_breakup"]?.break_timings?.length > 0 ? (item["time_breakup"]?.break_timings[0]?.break_out_time ?  CommonService.startTime(item["time_breakup"]?.break_timings[0]?.break_out_time):'N/A') : "N/A";
                    return (
                        <div>
                            {breakOutTime}
                        </div>
                    )
                }
            },
            {
                title:'Required On',
                width: 140,
                sorter: (a: any, b: any) =>
                    (a["shift_date"] || "").localeCompare(b["shift_date"] || ""),
                render:(item:any)=>{
                     return (
                        <div>
                            { CommonService.getUtcDate(item["shift_date"]) ||'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Staff Type',
                width: 170,
                sorter: (a: any, b: any) => (a?.hcp_type || "").localeCompare(b?.hcp_type || ""),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.hcp_type?.length > 20 ?
                                <Tooltip title={item?.hcp_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.hcp_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Type',
                width: 160,
                sorter: (a: any, b: any) => (a?.shift_type || "").localeCompare(b?.shift_type || ""),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.shift_type?.length > 20 ?
                                <Tooltip title={item?.shift_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.shift_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.shift_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Hours',
                width: 200,
                sorter: (a: any, b: any) =>
                    (a["expected"]?.shift_start_time || "").localeCompare(b["expected"]?.shift_start_time || ""),
                render: (item: any) => {
                    const {
                        start_time,
                        end_time
                    } = CommonService.getUtcTimeInAMPM(item["expected"]?.shift_start_time, item["expected"]?.shift_end_time);

                    return (
                        <div>
                            { (start_time + " - " +end_time) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Timesheet',
                width: 120,
                render:(item:any)=>{
                    const isTimeSheetUploaded =
                        item?.hasOwnProperty("is_timesheet_uploaded") && item["is_timesheet_uploaded"];
                    return (
                        <div className={'display-center'}>
                            {isTimeSheetUploaded ? (
                                <Checkbox checked={isTimeSheetUploaded}/>
                            ) : (
                                <Checkbox checked={Boolean(isTimeSheetUploaded)}/>
                            )}
                        </div>
                    )
                }
            },
            {
                title:'Facility Confirmation',
                width:180,
                render:(item:any)=>{
                    const isFacilityApproved =
                        item?.hasOwnProperty("is_facility_approved") && item["is_facility_approved"];
                    return(
                        <div className={'display-center'}>
                            {isFacilityApproved ? (
                                <Checkbox checked={isFacilityApproved}/>
                            ) : (
                                <Checkbox checked={Boolean(isFacilityApproved)}/>
                            )}
                        </div>
                    )
                }
            },
            {
                title:'View Details',
                width:140,
                render: (_: any, item: any, rowIndex: number)=>{
                    return(
                        <div className={'display-center'}>
                            <Link to={"/inprogessShifts/view/" + item["_id"]}
                                  id={"link_hospital_details" + rowIndex}
                                  style={{color:'#1e1e1e'}}
                            >
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </Link>
                        </div>
                    )
                }
            },
        ]
    },[])


    const menuItems=useCallback(()=>{
        return [
            {
                label:'Staff Sent Home',
                id:'staff_sent_home',
                action:'staff_sent_home',
                tooltip:"Send Staff Home",
            },
            {
                label:'Cancel Shift',
                id:'cancel_shift',
                action:'cancel_shift',
                tooltip: 'Cancel In Progress Shift',
            },
        ]
    },[])

    const handleMenuItemClick = useCallback((action: string) => {
        setPopoverVisible(false);
        switch(action){
            case "staff_sent_home":
                setIsHcpSentHomeOpen(true);
                break;
            case "cancel_shift":
                setRejectShiftOpen(true);
                break;
            default:
                return ;
        }
    },[])
    return (
        <div className="shift-inprogress screen crud-layout pdd-30">
            <DialogComponent open={isRejectShiftOpen} cancel={cancelRejectShift} headerLabel={selectedShifts && selectedShifts?.length > 1 ? "Cancel Shifts" : "Cancel Shift"}>
                <RejectShiftComponent isMultipleReasons={true} cancel={cancelRejectShift} confirm={confirmRejectShift}
                                      selectedShifts={selectedShifts}/>
            </DialogComponent>

            <DialogComponent open={isHcpSentHomeOpen} cancel={cancelHcpSentHome} headerLabel={"Enter the Actual Hours"}>
                <HcpSentHomeDialogComponent
                    cancel={cancelHcpSentHome}
                    confirm={confirmHcpSentHome}
                    selectedShifts={selectedShifts}
                />
            </DialogComponent>

            <DrawerComponent isOpen={openFilterDrawer} onClose={()=>setOpenFilterDrawer(false)}>
                <ShiftFilterDrawerComponent
                    filterData={filterData}
                    setFilterData={setFilterData}
                    setOpenFilterDrawer={setOpenFilterDrawer}
                    isFacilityListLoading={isFacilityListLoading}
                    noStatus={true}
                    isInProgress={true}
                    resetFilters={resetFilters}
                    facilityList={facilityList}
                    hcpTypes={hcpTypes}
                />
            </DrawerComponent>

            <div className="custom-border pdd-20">
                <div className="header">
                    <div className="mrg-left-5 filter">
                        <div>
                            <div className="d-flex">
                                <div className="d-flex position-relative">
                                    {!filterData.search ? (
                                        <div className={"search_icon"}>
                                            <SearchRounded/>
                                        </div>
                                    ) : (
                                        <div className={"search_icon"}>
                                            <ClearIcon
                                                onClick={(event) => {
                                                    setFilterData({...filterData, search: ''})
                                                    setSelectedShifts([]);
                                                }}
                                                id="clear_facility_search"
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <CssTextField
                                            defaultValue={""}
                                            className="search-cursor searchField"
                                            id="input_search_facility"
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                setFilterData({...filterData, search: value});
                                            }}
                                            value={filterData.search}
                                            variant={"outlined"}
                                            size={"small"}
                                            type={"text"}
                                            placeholder={"Search Shift"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actions d-flex">
                        <div className={'mrg-left-10'}>
                            <Button
                                variant={"outlined"} id={'add_facility_btn'}
                                color={"primary"}
                                onClick={() => {
                                    setOpenFilterDrawer(true)
                                }}
                            >
                                {filterCount > 0 &&
                                    <span className={'filter-btn-badge'}>{filterCount}</span>
                                }
                                &nbsp;&nbsp;Filters&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div className="mrg-left-10">
                            <Tooltip title="Download Shifts List">
                                <Button variant={"outlined"} color="primary" onClick={handleDownload}
                                        className={!isDownloading ? "" : "has-loading-spinner"}
                                        disabled={isDownloading}>
                                    &nbsp;Download
                                </Button>
                            </Tooltip>
                        </div>
                        <div className={'mrg-left-15'}>
                            <Popover
                                trigger={'click'}
                                placement={'bottomRight'}
                                destroyTooltipOnHide={true}
                                open={popoverVisible}
                                onOpenChange={(visible) => setPopoverVisible(visible)}
                                content={<ShiftMenuListComponent
                                    selectedShifts={selectedShifts}
                                    onMenuItemClick={handleMenuItemClick}
                                    menuItems={menuItems}
                                />}
                            >
                                <div className={'applicant-box'} onClick={() => setPopoverVisible(!popoverVisible)}>
                                    <ImageConfig.KebabIcon className={'applicant-edit-icon'}/>
                                </div>
                            </Popover>
                        </div>
                    </div>
                </div>
                <div className={'inprogress-table-wrapper'}>
                    <AntTableComponent
                        key={refreshToken}
                        url={ENV.API_URL + "shift/progress"}
                        method={'post'}
                        extraPayload={filterData}
                        columns={inProgressShiftsListColumn}
                        noDataText={'No In Progress Shift Found.'}
                        isRowSelection={true}
                        selectedRowKeys={selectedShifts}
                        setSelectedRowKeys={setSelectedShifts}
                    />
                </div>
            </div>
        </div>
    );
};

export default ShiftInprogressListScreen;
