import "./ChatComponent.scss";
import InputComponent from "../../ant-component/input/InputComponent";
import {FormOutlined} from '@ant-design/icons'
import {Tooltip} from "antd";
import {useCallback, useEffect, useState} from "react";
import ModalComponent from "../../../components/modal/ModalComponent";
import ChatUserListComponent from "../../../components/chat/user-list/ChatUserListComponent";
import {CommonService, Communications} from "../../../helpers";
import {ENV} from "../../../constants";
import {io} from 'socket.io-client';
import ChatCardComponent from "../../../components/chat/chat-card/ChatCardComponent";
import ChatHeaderComponent from "../../../components/chat/chat-header/ChatHeaderComponent";
import ChatSearchComponent from "../../../components/chat/chat-search/ChatSearchComponent";
import MessageReceiveCardComponent from "../../../components/chat/message-receive-card/MessageReceiveCardComponent";
import MessageSenderCardComponent from "../../../components/chat/message-sender-card/MessageSenderCardComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import LoaderComponent from "../../../components/loader/LoaderComponent";
import AccessControlComponent from "../../../components/AccessControl";
import {ADMIN, TERICADMIN} from "../../../helpers/common-service";


const ChatComponent=()=>{
    const {user}=useSelector((state:StateParams)=>state.auth);
    const [openModal,setOpenModal]=useState<boolean>(false);
    const [searchChat,setSearchChat]=useState<string>('');
    const [chatMessage,setChatMessage]=useState<string>('');
    const [chatList,setChatList]=useState<any>([]);
    const [isChatListLoading,setIsChatListLoading]=useState<boolean>(false);
    const [messageList,setMessageList]=useState<any>([]);
    const [chatId,setChatId]=useState<any>(null);
    const [chatDetails,setChatDetails]=useState<any>(null);
    const [isChatDetailsLoading,setIsChatDetailsLoading]=useState<boolean>(false);
    const [socket, setSocket] = useState<any>(null);
    console.log(isChatListLoading,socket)

    const handleOpenModal=useCallback(()=>{
        setOpenModal(true)
    },[])

    const handleCloseModal=useCallback(()=>{
        setOpenModal(false);
    },[])

    const getChatList=useCallback(()=>{
        setIsChatListLoading(true);
        CommonService._api.get(ENV.API_URL +'chat/getChatList').then((response:any)=>{
            setChatList(response?.data)
            console.log(response?.data,"CHAT LIST")
        }).catch((error:any)=>{
            CommonService.showToast(error.error,'error')
        }).finally(()=>{
            setIsChatListLoading(false)
        })
    },[])

    useEffect(() => {
        getChatList();
    }, [getChatList]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Chat");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    const handleChatRead=useCallback((chatId)=>{
        CommonService._api.put(ENV.API_URL +`chat/${chatId}/markMessagesAsViewed`)
            .then((response:any)=>{
                console.log(response?.data)
            }).catch((error:any)=>{
            CommonService.showToast(error.error,'error')
        }).finally(()=>{
        })
    },[])

    const getChatDetails=useCallback((chatId)=>{
        setIsChatDetailsLoading(true);
        setChatId(chatId);
        CommonService._api.get(ENV.API_URL +`chat/${chatId}/getChatMessagesList`)
            .then((response:any)=>{
                setMessageList(response?.data?.docs);
                console.log(response?.data?.docs)
                handleChatRead(chatId)
            }).catch((error:any)=>{
            CommonService.showToast(error.error,'error')
        }).finally(()=>{
            setIsChatDetailsLoading(false);
        })
    },[handleChatRead])

    const handleSendMessage=useCallback((user:any)=>{
        let payload:any
        if(chatId){
            payload={
                message:chatMessage,
                chat_id:chatId,
                user_name:user?.user_2_name
            }
        }else{
            payload={
                message:'Hi',
                user_id:user?.code,
                user_role:user?.role,
                user_name:user?.label,
            }
        }
        CommonService._api.post(ENV.API_URL + 'chat/sendMessage',payload).then((response)=>{
            if (!chatId) {
                // If it's a new chat, fetch the new chat details
                const newChatId = response.data.chatId; // Assuming the API returns the new chat ID
                setChatId(newChatId);
                getChatDetails(newChatId);
            }
            getChatList();
        }).catch((error:any)=>{
            console.log(error.error)
        }).finally(()=>{})
    },[getChatList,chatId,chatMessage,getChatDetails])

    useEffect(() => {
        const newSocket = io(ENV.SOCKET_URL, {
            transports: ["websocket"]
        });

        newSocket.on('connect', () => {
            if (chatId) {
                newSocket.on(`chat_${chatId}`, (data: any) => {
                    console.log(data,"DATA")
                    if(data){
                        setMessageList((prev:any)=>[data,...prev])
                    }
                });
            }
            if (chatDetails) {
                console.log("IN CONDITION", chatDetails);
                // newSocket.on(`user-status-${chatDetails?.customerId}`, (val) => {
                //     console.log(val, 'VALUE')
                // })
            }
        });

        newSocket.on('disconnect', () => {
            console.log('disconnected socket');
        });

        setSocket(newSocket);

        return () => {
            newSocket.disconnect(); // Disconnect the socket when component unmounts
            newSocket.removeAllListeners(); // Remove all event listeners
        };
    }, [chatId, messageList, chatDetails]);

    const filterChatList= chatList?.filter((chat:any)=>chat?.user_2_name?.toLowerCase().includes(searchChat.toLowerCase()));
    return (
        <>
            <ModalComponent open={openModal} onCancel={handleCloseModal} footer={null} title={'New Message'}>
                <ChatUserListComponent
                    chatList={chatList}
                    handleClick={handleSendMessage}
                    handleClose={handleCloseModal}
                    setChatId={setChatId}
                    getChatDetails={getChatDetails}
                    setChatDetails={setChatDetails}
                />
            </ModalComponent>
        <div className={'chat-component'}>
            <div className={'chat-list-wrapper'}>
                {/* top search wrapper*/}
                <div className={'chat-user-search-wrapper'}>
                    <div className={'chat-user-search-input-wrapper'}>
                        <InputComponent
                            placeholder={'Search Chat'}
                            size={'large'}
                            value={searchChat}
                            onChange={(value:string)=>setSearchChat(value)}
                            allowClear={true}
                        />
                    </div>
                    <AccessControlComponent role={[ADMIN,TERICADMIN]}>
                        <Tooltip title='Start New Chat' placement={'bottomRight'}>
                            <div className={'chat-new-user'} onClick={handleOpenModal}>
                                <FormOutlined/>
                            </div>
                        </Tooltip>
                    </AccessControlComponent>
                </div>
                {/* List of chats*/}
                <div className={'chat-card-wrapper'}>
                    {filterChatList?.map((chat:any)=>{
                        return (
                            <ChatCardComponent
                                key={chat?._id}
                                chat={chat}
                                chatId={chatId}
                                setChatDetails={setChatDetails}
                                getDetails={getChatDetails}
                            />
                        )
                    })}
                </div>
            </div>
            <div className={'chat-main-wrapper'}>
                {chatDetails ?
                    <>
                    {isChatDetailsLoading ?
                        <div className={'basic-loading-wrapper'}>
                            <LoaderComponent type={'spinner'} color={'primary'} size={'xs'}/>
                        </div>:
                        <>
                        <ChatHeaderComponent chatDetails={chatDetails}/>
                        <div className={'chat-main-area-wrapper'}>
                        <div className={'chat-message-area'}>
                            {messageList?.map((chat: any, index: number) => {
                                        const isSentMessage = user?._id === chat?.sender_id;

                                        return (
                                            <div key={index} className={`message-container ${isSentMessage ? 'sent-message' : 'received-message'}`}>
                                                {isSentMessage ? (
                                                    <MessageSenderCardComponent message={chat?.message} timestamp={chat?.updated_at} />
                                                ) : (
                                                    <MessageReceiveCardComponent message={chat?.message} timestamp={chat?.updated_at} />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className={'chat-message-search-wrapper'}>
                                    <ChatSearchComponent chatMessage={chatMessage} setChatMessage={setChatMessage} handleClick={handleSendMessage}/>
                                </div>
                            </div>
                        </>}
                    </> :
                    <div className={'no-chat-selected-wrapper'}>
                        <div className={'no-chat-selected-title'}>Send a message to start a chat.</div>
                        <div onClick={handleOpenModal} className={'no-chat-selected-button'}>Send Message</div>
                    </div>
                }

            </div>
        </div>
        </>
    )
}
export default ChatComponent