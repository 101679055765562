import React, { useCallback, useEffect, useState } from "react";
import {Button, FormControlLabel, IconButton, InputAdornment, MenuItem, Radio, Tooltip} from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField, RadioGroup } from "formik-material-ui";
import FormLabel from "@material-ui/core/FormLabel";
import "./EmployeeAddScreen.scss";
import { ApiService, CommonService, Communications } from "../../../helpers";
import {ENV, ImageConfig} from "../../../constants";
import { genderTypes, roles } from "../../../constants/data";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
// import LoaderComponent from "../../../components/LoaderComponent";
import LeavePageConfirmationComponent from "../../../components/shared/LeavePageConfirmationComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import LoaderComponent from "../../../components/loader/LoaderComponent";

export const employeeFormValidation = Yup.object({
  first_name: Yup.string()
    .typeError("must be a text")
    .min(3, "min 3 letters")
    .trim("empty space not allowed")
    .required("Required")
    .max(50, "max limit 50")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  last_name: Yup.string()
    .typeError("must be a text")
    .min(3, "min 3 letters")
    .trim("empty space not allowed")
    .required("Required")
    .max(50, "max limit 50")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  email: Yup.string().min(3, "invalid").trim("empty space not allowed").typeError("must be a text").email("invalid").required("Required").max(50, "max limit 50"),
  contact_number: Yup.string().min(10, "min 10 digits").required("Required"),
  gender: Yup.string().typeError("must be a text").min(2, "invalid").trim("empty space not allowed"),
  address: Yup.object({
    street: Yup.string().typeError("must be a text").min(3, "min 3 letters").trim("empty space not allowed").max(150, "max limit 150"),
    city: Yup.string().typeError("must be a text").min(2, "min 2 letters").trim("empty space not allowed").max(150, "max limit 150"),
    state: Yup.string().typeError("must be a text").min(2, "min 2 letters").trim("empty space not allowed").max(150, "max limit 150"),
    // region: Yup.string().typeError("must be a text").min(2, "min 2 letters").trim("empty space not allowed").max(150, "max limit 150"),
    country: Yup.string().typeError("must be a text").min(2, "min 2 letters").trim("empty space not allowed").max(150, "max limit 150"),
    zip_code: Yup.string().typeError("must be a text").trim("empty space not allowed").min(5, "min 5 digits").max(6, "max 6 digits allowed"),
  }),
  role: Yup.string().required("Required"),
  password: Yup.string().typeError("must be a text").min(6, "min 6 letters").max(16, "max limit 16").required("Required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

const showDropDownBelowField = {
  MenuProps: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  },
};

interface EmployeeAddProps {
  first_name?: string;
  last_name: string;
  email: string;
  contact_number: string;
  address?: {
    street: string;
    city: string;
    state: string;
    region: string;
    country: string;
    zip_code: string;
  };
  role: string;
  gender: string;
  password: string;
  confirm_password: string;
}

let EmployeeInitialState: EmployeeAddProps = {
  first_name: "",
  last_name: "",
  email: "",
  contact_number: "",
  address: {
    street: "",
    city: "",
    state: "",
    region: "",
    country: "US",
    zip_code: "",
  },
  role: "",
  gender: "",
  password: "",
  confirm_password: "",
};

const EmployeeAddScreen = () => {
  const history = useHistory();
  //const [regions, setRegions] = useState<any>([]);
  const {isLoading,regions} = useSelector((state:StateParams) => state.meta);
 // const [regIsLoading, setRegIsLoading] = useState<boolean>(true);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);


  // const getRegions = useCallback(() => {
  //   CommonService._api
  //     .get(ENV.API_URL + "meta/hcp-regions")
  //     .then((resp) => {
  //       setRegions(resp.data || []);
  //       setRegIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const openAdd = useCallback(() => {
    setIsAddOpen(true);
  }, []);

  const cancelAdd = useCallback(() => {
    setIsAddOpen(false);
  }, []);

  const confirmAdd = useCallback(() => {
    history.push("/employee/list");
  }, [history]);

  useEffect(() => {
    Communications.pageTitleSubject.next("Add Employee");
    Communications.pageBackButtonSubject.next(null);
    // getRegions();

  }, []);

  const onAdd = (employee: EmployeeAddProps, { setSubmitting, setErrors, resetForm, setFieldValue }: FormikHelpers<any>) => {
    let payload:any={
      ...employee,
      email:employee.email.toLowerCase(),
      contact_number:employee?.contact_number ?  "+1" + employee?.contact_number : null
    }
    ApiService.post(ENV.API_URL + "user", payload)
        .then((resp: any) => {
          CommonService.showToast(resp?.msg || "Success", "success");
          setSubmitting(false);
          history.push("/employee/view/" + resp?.data?._id);
        })
        .catch((err) => {
          CommonService.handleErrors(setErrors, err);
          setSubmitting(false);
        });
  };

  return (
      <div className={'employee-add-component'}>
        {isLoading ?
          <div style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            height:'100vh'
          }}>
            <LoaderComponent type={'spinner'}/>
          </div>:
            <div className={'employee-add'}>
              <LeavePageConfirmationComponent  cancel={cancelAdd} visible={isAddOpen} confirm={confirmAdd} confirmationText={""} notext={"Cancel"} yestext={"Leave"} />
              <div className={'employee-add-content'}>
                <Formik
                    initialValues={EmployeeInitialState}
                    validateOnChange={true}
                    validationSchema={employeeFormValidation}
                    onSubmit={onAdd}>
                  {({ isSubmitting, isValid, resetForm }) => (
                      <Form id="employee-add-form" className={"form-holder"}>
                        <div>
                          <div className={'add-header-wrapper'}>
                            <div className={'add-header-back-wrapper'} onClick={openAdd}>
                              <img src={ImageConfig.BackIcon} alt="back"/>
                              <div  className={'add-header-back'}>
                                Back
                              </div>
                            </div>
                            <div className={'add-header-title'}>Add Employee</div>
                            <div className={'add-header-btn-wrapper mrg-right-30'}>
                              <Tooltip title={"Cancel"}>
                                <Button variant={"outlined"} id="cancel-employee-btn" size={"medium"} color={"primary"}
                                        onClick={() => {
                                          openAdd()
                                        }}>
                                  Cancel
                                </Button>
                              </Tooltip>
                              <Tooltip title={"Save Changes"}>
                                <Button
                                    disabled={isSubmitting}
                                    variant={"contained"}
                                    id="Add-employee-btn"
                                    type={"submit"}
                                    size={"medium"}
                                    color={"primary"}
                                    className={isSubmitting ? "has-loading-spinner mrg-left-20 pdd-left-30 pdd-right-30" : "mrg-left-20 pdd-left-30 pdd-right-30"}
                                >
                                  {isSubmitting ? "Saving" : "Save"}
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="custom-border pdd-20">
                            <h3 className="card-header">Basic Details</h3>
                            <div className="input-container">
                              <Field variant="outlined"
                                     name="first_name"
                                     type={"text"}
                                     component={TextField}
                                     InputLabelProps={{shrink: true}}
                                     id="input_employee_add_first_name" label="First Name*" fullWidth
                                     autoComplete="off"/>
                              <Field
                                  variant="outlined"
                                  name="last_name"
                                  id="input_employee_add_last_name"
                                  type={"text"}
                                  InputLabelProps={{shrink: true}}
                                  component={TextField} label="Last Name*" fullWidth autoComplete="off"/>
                            </div>
                            <div className="input-container">
                              <Field variant="outlined"
                                     component={TextField}
                                     type={"email"}
                                     InputLabelProps={{shrink: true}}
                                     fullWidth autoComplete="off" className="flex-1" label="Email*" name="email"
                                     id="input_employee_add_email"/>
                              <div className="flex-1 d-flex">
                                {/*<Field required name={"contact_number"} className="flex-1">*/}
                                {/*  {(field: FieldProps) => {*/}
                                {/*    return <PhoneInputComponent field={field} placeholder={"Enter Phone number*"} />;*/}
                                {/*  }}*/}
                                {/*</Field>*/}
                                <Field
                                    inputProps={{ maxLength: 10,
                                      onInput: (e:any) => {
                                        if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10);
                                      },
                                    }}
                                    className="phone_number"
                                    variant="outlined"
                                    name="contact_number"
                                    type={"number"}
                                    component={TextField}
                                    label="Contact Number*"
                                    fullWidth
                                    autoComplete="off"
                                    id="input_facility_member_add_phone_number"
                                    InputLabelProps={{shrink: true}}
                                    InputProps={{
                                      startAdornment: (
                                          <InputAdornment position="start">
                                            +1
                                          </InputAdornment>
                                      ),
                                    }}
                                />
                              </div>
                            </div>
                            <div className="input-container">
                              <Field variant="outlined" InputLabelProps={{shrink: true}} name="address.street"
                                     type={"text"} component={TextField} label="Street" id="input_employee_add_street"
                                     fullWidth autoComplete="off"/>
                              <Field variant="outlined" InputLabelProps={{shrink: true}} name="address.city"
                                     type={"text"} component={TextField} id="input_employee_add_city" label="City"
                                     fullWidth autoComplete="off"/>
                            </div>
                            <div className="input-container">
                              <Field SelectProps={showDropDownBelowField} variant="outlined"
                                     InputLabelProps={{shrink: true}} component={TextField} type={"text"} select
                                     label="State" name="address.state" id="menu_employee_add_state" fullWidth
                                     autoComplete="off">
                                <MenuItem value="">Select State</MenuItem>
                                {regions &&
                                    regions.map((item: any, index: any) => (
                                        <MenuItem value={item.code} key={"state_" + index}
                                                  id={"menu_employee_add_state" + item.code}>
                                          {item.title}
                                        </MenuItem>
                                    ))}
                              </Field>
                              <Field variant="outlined" InputLabelProps={{shrink: true}} fullWidth
                                     name="address.zip_code" type={"number"} component={TextField}
                                     id="input_employee_add_zip" label="Zip Code" autoComplete="off"/>
                            </div>
                            <div className="input-container">
                              <Field variant="outlined" name="address.country" type={"text"} component={TextField}
                                     id="input_employee_add_country" InputLabelProps={{shrink: true}} label="Country"
                                     fullWidth autoComplete="off"/>
                              <Field
                                  InputLabelProps={{shrink: true}}
                                  SelectProps={showDropDownBelowField} variant="outlined" name="role" type={"text"}
                                  component={TextField} select label="Role*" fullWidth autoComplete="off"
                                  id="employee_add_role">
                                <MenuItem value="">Select Role</MenuItem>
                                {roles &&
                                    roles.map((item: any, index: any) => (
                                        <MenuItem value={item.value} key={"role_" + index}>
                                          {item.label}
                                        </MenuItem>
                                    ))}
                              </Field>
                            </div>
                            <div className="input-container">
                              <div className="flex-1">
                                <div className="pdd-top-10">
                                  <FormLabel className={"form-label"}>{"Gender"}</FormLabel>
                                </div>
                                <div className="mrg-top-10">
                                  <Field component={RadioGroup} name="gender" id="radio_add_gender">
                                    <div className="d-flex">
                                      {genderTypes.map((item: any, index) => {
                                        return (
                                            <div>
                                              <FormControlLabel key={"gender_type_" + index} value={item.value}
                                                                control={<Radio disabled={isSubmitting}/>}
                                                                disabled={isSubmitting} label={item.label}/>
                                            </div>
                                        );
                                      })}
                                    </div>
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div className="input-container">
                              <div className="form-field position-relative flex-1">
                                <Field
                                    InputLabelProps={{shrink: true}}
                                    name="password"
                                    type={showNewPassword ? "text" : "password"}
                                    component={TextField}
                                    variant={"outlined"}
                                    color={"primary"}
                                    autoComplete="off"
                                    label="Password*"
                                    id="input_new_password"
                                    placeholder={"Enter New Password"}
                                    inputProps={{maxLength: 16}}
                                />
                                <div className={"eye_btn_wrapper"}>
                                  <IconButton size={"small"} aria-label="toggle password visibility"
                                              onClick={() => setShowNewPassword(!showNewPassword)}
                                              onMouseDown={handleMouseDownPassword} id="btn_new_password_show">
                                    {showNewPassword ? <Visibility/> : <VisibilityOff/>}
                                  </IconButton>
                                </div>
                              </div>
                              <div className="form-field position-relative flex-1">
                                <Field
                                    InputLabelProps={{shrink: true}}
                                    name="confirm_password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    component={TextField}
                                    variant={"outlined"}
                                    color={"primary"}
                                    autoComplete="off"
                                    id="input_confirm_password"
                                    label="Confirm Password*"
                                    placeholder={"Confirm Password"}
                                    inputProps={{maxLength: 16}}
                                />
                                <div className={"eye_btn_wrapper"}>
                                  <IconButton size={"small"} aria-label="toggle password visibility"
                                              id="btn_confirm_password_show"
                                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                              onMouseDown={handleMouseDownPassword}>
                                    {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                  </IconButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                  )}
                </Formik>
              </div>
            </div>
        }
      </div>
  );
};
export default EmployeeAddScreen;
