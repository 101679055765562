import {ColumnsType} from "antd/es/table";
import {Tooltip} from "@material-ui/core";
import React from "react";
import {CommonService} from "../../../helpers";
import {Link} from "react-router-dom";




export const PipelineListColumn=(tab?:string | undefined):ColumnsType=>[
    {
        title: "Job Title",
        width: 250,
        render: (item: any) => {
            return (
                <div>
                    {item?.job_details?.job_title?.length > 20 ?
                        <Tooltip title={item?.job_details?.job_title}>
                            <Link to={{
                                pathname:`/jobs/view/${item?.job_id}`,
                                search: `?tab=basic_details`,
                                state: {
                                    from:'pipeline',
                                    currentTab:tab || 'new_lead'
                                }
                            }}  className={'ellipses-for-table-data'} style={{textDecoration:'underline',cursor:'pointer'}}>
                                {item?.job_details?.job_title}
                            </Link>
                        </Tooltip>
                        :
                        <Link to={{
                            pathname:`/jobs/view/${item?.job_id}`,
                            search: `?tab=basic_details`,
                            state: {
                                from:'pipeline',
                                currentTab:tab || 'new_lead'
                            }
                        }} className='facility-name' style={{textDecoration:'underline',cursor:'pointer'}}>
                            {item?.job_details?.job_title || 'N/A'}
                        </Link>
                    }
                </div>
            )
        },
    },
    {
        title: "Facility",
        width: 200,
        render: (item: any) => {
            return (
                <div>
                    {item?.facility?.name?.length > 20 ?
                        <Tooltip title={item?.facility?.name}>
                            <div className={'ellipses-for-table-data'}>
                                {CommonService.formatTitleCase(item?.facility?.name) || 'N/A'}
                            </div>
                        </Tooltip>
                        :
                        <div className='facility-name'>
                            {CommonService.formatTitleCase(item?.facility?.name) || 'N/A'}
                        </div>
                    }
                </div>
            )
        },
    },
    {
        title: "Job Type",
        width: 150,
        render: (item: any) => {
            return (
                <div>
                    {item?.job_details?.job_type?.length > 20 ?
                        <Tooltip title={item?.job_details?.job_type}>
                            <div className={'ellipses-for-table-data'}>
                                {CommonService.formatTitleCase(item?.job_details?.job_type)}
                            </div>
                        </Tooltip>
                        :
                        <div className='facility-name'>
                            {CommonService.formatTitleCase(item?.job_details?.job_type) || 'N/A'}
                        </div>
                    }
                </div>
            )
        },
    },
    {
        title: "Staff Name",
        width: 150,
        render: (item: any) => {
            return (
                <div>
                    {(item?.hcp_details?.first_name?.length + item?.hcp_details?.last_name?.length) > 15 ?
                        <Tooltip title={item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name}>
                            <div className={'ellipses-for-table-data'}>
                                {item?.hcp_details?.first_name + " " +  item?.hcp_details?.last_name}
                            </div>
                        </Tooltip>
                        :
                        <div className='facility-name'>
                            {item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name || 'N/A'}
                        </div>
                    }
                </div>
            )
        },
    },
    {
        title: "Staff Type",
        width: 150,
        render: (item: any) => {
            return (
                <div>
                    {item?.hcp_details?.hcp_type?.length > 25 ?
                        <Tooltip title={item?.hcp_details?.hcp_type}>
                            <div className={'ellipses-for-table-data '}>{item?.hcp_details?.hcp_type}</div>
                        </Tooltip> :
                        <div className={'facility_name'}>
                            {item?.hcp_details?.hcp_type || 'N/A'}
                        </div>
                    }
                </div>
            );
        },
    },
    {
        title: "Job Client",
        width: 150,
        render: (item: any) => {
            return (
                <div>
                    {item?.job_details?.client?.length > 25 ?
                        <Tooltip title={item?.job_details?.client}>
                            <div className={'ellipses-for-table-data '}>{item?.job_details?.client}</div>
                        </Tooltip> :
                        <div className={'facility_name'}>
                            {item?.job_details?.client || 'N/A'}
                        </div>
                    }
                </div>
            );
        },
    },
    {
        title: "Job Source",
        width: 150,
        render: (item: any) => {
            return (
                <div>
                    {item?.job_details?.source?.length > 25 ?
                        <Tooltip title={item?.job_details?.source}>
                            <div className={'ellipses-for-table-data '}>{CommonService.formatTitleCase(item?.job_details?.source)}</div>
                        </Tooltip> :
                        <div className={'facility_name'}>
                            {CommonService.formatTitleCase(item?.job_details?.source) || 'N/A'}
                        </div>
                    }
                </div>
            );
        },
    },
    {
        title: "Staff Source",
        width: 150,
        render: (item: any) => {
            return (
                <div>
                    {item?.hcp_details?.applied_from?.length > 25 ?
                        <Tooltip title={item?.hcp_details?.applied_from}>
                            <div className={'ellipses-for-table-data '}>{item?.hcp_details?.applied_from}</div>
                        </Tooltip> :
                        <div className={'facility_name'}>
                            {item?.hcp_details?.applied_from || 'N/A'}
                        </div>
                    }
                </div>
            );
        },
    },
    {
        title: "Applicant Status",
        width: 150,
        render: (item: any) => {
            return (
                <div>
                    {item?.hcp_details?.applicant_status?.length > 25 ?
                        <Tooltip title={item?.hcp_details?.applicant_status}>
                            <div className={'ellipses-for-table-data '}>{CommonService.formatTitleCase(item?.hcp_details?.applicant_status)}</div>
                        </Tooltip> :
                        <div className={'facility_name'}>
                            {CommonService.formatTitleCase(item?.hcp_details?.applicant_status) || 'N/A'}
                        </div>
                    }
                </div>
            );
        },
    },
    {
        title: "Status",
        width: 150,
        render: (item: any) => {
            return (
                <div>
                    {item?.hcp_details?.status?.length > 25 ?
                        <Tooltip title={item?.hcp_details?.status}>
                            <div className={'ellipses-for-table-data '}>{CommonService.formatTitleCase(item?.hcp_details?.status)}</div>
                        </Tooltip> :
                        <div className={'facility_name'}>
                            {CommonService.formatTitleCase(item?.hcp_details?.status) || 'N/A'}
                        </div>
                    }
                </div>
            );
        },
    },

]