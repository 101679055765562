import "./JobListTableComponent.scss";
import {Button, TableColumnsType} from "antd";
import React, {useCallback, useMemo, useState} from "react";
import {CommonService} from "../../../../../helpers";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";
import {Tooltip} from "antd";
import {CssTextField} from "../../../../../constants/data/styles";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import {ENV} from "../../../../../constants";

interface JobListTableComponentProps{
    selectedJobs:any[],
    setSelectedJobs?:any;
    setSelectedJobsForEmail?:any;
    setOpenJobListModal?:any;
}
const JobListTableComponent=(props:JobListTableComponentProps)=>{
    const {selectedJobs,setSelectedJobs,setSelectedJobsForEmail,setOpenJobListModal}=props;
    const [selectedRowData,setSelectedRowData]=useState<any>([])
    const [allSelectedJobs, setAllSelectedJobs] = useState<any[]>([]);
    const [filterData,setFilterData]=useState<any>({
        search:'',
        limit:10,
        page:1,
        status:['open']
    })

    const formatSelectedJobsToHTML = useCallback((selectedJobs: any[]) => {
        if (!selectedJobs || selectedJobs.length === 0) return "";

        let html = `
        <table border="1" cellpadding="8" cellspacing="0" style="border-collapse: collapse; width: 100%;">
            <thead>
                <tr style="background-color: #f2f2f2;">
                    <th>Job ID</th>
                    <th>Job Title</th>
                    <th>Profession</th>
                    <th>Location</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Apply Link</th>
                </tr>
            </thead>
            <tbody>
    `;
        const addedJobs = new Set();

        selectedJobs.forEach((job: any) => {
            if (!addedJobs.has(job.key)) {
                html += "<tr>";
                html += `<td>${job._id?.substr(-7)}</td>`;
                html += `<td>${job.job_title || "N/A"}</td>`;
                html += `<td>${job.staff_type}</td>`
                const address = [
                    job.address?.street,
                    job.address?.city,
                    job.address?.state,
                    job.address?.country,
                    job.address?.zip_code,
                ]
                    .filter(Boolean)
                    .join(", ");
                html += `<td>${address || "N/A"}</td>`;
                html += `<td>${CommonService.getUtcDate(job.job_start_date) || "N/A"}</td>`;
                html += `<td>${CommonService.getUtcDate(job.job_end_date) || "N/A"}</td>`;
                html += `<td><a href="${ENV.API_JOB_BOARD_URL}job/${job?._id}" target="_blank" rel="noopener noreferrer">Apply Now</a></td>`;
                html += "</tr>";
                addedJobs.add(job.key);
            }
        });

        html += "</tbody></table>";
        return html;
    }, []);


    const handleTagJobsToEmail = useCallback(() => {
        setSelectedJobsForEmail('');
        setOpenJobListModal(false);

        // Get selected jobs data
        const selectedJobsData = selectedJobs.map((key: any) =>
            selectedRowData?.find((item: any) => item.key === key)
        );

        // Update the cumulative list of selected jobs
        setAllSelectedJobs((prev) => [...prev, ...selectedJobsData]);

        let htmlTable = formatSelectedJobsToHTML([...allSelectedJobs, ...selectedJobsData]);
        setSelectedJobsForEmail(htmlTable);
    }, [
        selectedJobs,
        selectedRowData,
        setSelectedJobsForEmail,
        formatSelectedJobsToHTML,
        setOpenJobListModal,
        allSelectedJobs,
    ]);


    const jobColumnList:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'Job ID',
                width:120,
                render:(item:any)=>{
                    return (
                        <div>{item?._id?.substr(-7) || 'N/A'}</div>
                    )
                }
            },
            {
                title:'Job Title',
                width:250,
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.job_title?.length > 35 ?
                            <Tooltip title={item?.job_title}>
                                <div className={'ellipses-for-table-data'}>
                                    {item?.job_title || 'N/A'}
                                </div>
                            </Tooltip>:
                                <div>
                                    {item?.job_title ||  'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Profession',
                width:150,
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.staff_type?.length > 15 ?
                                <Tooltip title={item?.staff_type}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.staff_type || 'N/A'}
                                    </div>
                                </Tooltip>:
                                <div>
                                    {item?.staff_type ||  'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Address',
                width:250,
                render:(item:any)=>{
                    const address=[item?.address?.street,
                        item?.address?.city,
                        item?.address?.state,
                        item?.address?.country,
                        item?.address?.zip_code].filter(Boolean).join(', ')
                    return (
                        <div>
                            {address?.length > 35 ?
                                <Tooltip title={address}>
                                    <div className={'ellipses-for-table-data'}>
                                        {address || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {address || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Job Start Date',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            {item?.job_type === 'per_diem' ?
                                <>
                                    {CommonService.getUtcDate(item?.per_diem_details?.start_date) || "N/A"}
                                </> : item?.job_type === 'contract' ? <>{CommonService.getUtcDate(item?.contract_details?.start_date) || "N/A"}</>
                                    :<>{CommonService.getUtcDate(item?.job_start_date) || "N/A"}</>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Job End Date',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            {item?.job_type === 'per_diem' ?
                                <>
                                    {CommonService.getUtcDate(item?.per_diem_details?.end_date) || "N/A"}
                                </> : item?.job_type === 'contract' ? <>{CommonService.getUtcDate(item?.contract_details?.end_date) || "N/A"}</>
                                    :<>{CommonService.getUtcDate(item?.job_end_date) || "N/A"}</>
                            }
                        </div>
                    )
                }
            }
        ]
    },[])
    return (
        <div className={'job-list-table-component'}>
            <div className={'job-list-table-wrapper'}>
                <div className="d-flex mrg-bottom-20">
                    <div className="d-flex position-relative">
                        {!filterData.search ? (
                            <div className={"search_icon"}>
                                <SearchRounded/>
                            </div>
                        ) : (
                            <div className={"search_icon"}>
                                <ClearIcon
                                    onClick={(event) => {
                                        setFilterData({...filterData, search: '', page: 1})
                                    }}
                                    id="clear_facility_search"
                                />
                            </div>
                        )}
                        <div>
                            <CssTextField
                                defaultValue={""}
                                className="job-search"
                                id="input_search_facility"
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setFilterData({...filterData, search: value, page: 1});
                                }}
                                value={filterData.search}
                                variant={"outlined"}
                                size={"small"}
                                type={"text"}
                                placeholder={"Search Job Title"}
                            />
                        </div>
                    </div>
                </div>
                <AntTableComponent
                    url={ENV.API_URL + 'ats/jobs/listLite'}
                    method={'post'}
                    isRowSelection={true}
                    selectedRowKeys={selectedJobs}
                    setSelectedRowKeys={setSelectedJobs}
                    setSelectedRowData={setSelectedRowData}
                    columns={jobColumnList || []}
                    extraPayload={filterData}
                    noDataText={'No Jobs Found'}
                />
                <div className={'job-list-button-wrapper'} onClick={handleTagJobsToEmail}>
                    <Button
                        disabled={selectedJobs?.length === 0}
                        type={'primary'} className={'job-list-button'}>
                       Add to Email
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default JobListTableComponent