import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Select, Upload, Table, Button, message, DatePicker, Input} from "antd";
import {UploadOutlined, DeleteOutlined} from "@ant-design/icons";
import type {RcFile} from "antd/es/upload";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import DialogComponent from "../../../../components/DialogComponent";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import {TsFileUploadConfig, TsFileUploadWrapperClass} from "../../../../classes/ts-file-upload-wrapper.class";
import {ENV} from "../../../../constants";
import {CommonService} from "../../../../helpers";

const {Option} = Select;

interface Document {
    _id: string;
    key: string;
    title: string;
}

export interface UploadedFile {
    documentId?: string;
    documentTitle?: string;
    file?: RcFile;
    expiry_date?: string;
    setUploadedFiles?: any;
    uploadedFiles?: any// Expiry date in ISO format
}

const HcpAttachmentComponentANTD = (props: UploadedFile) => {
    const {documents} = useSelector((state: StateParams) => state.meta)
    const {uploadedFiles, setUploadedFiles} = props;
    const [availableDocuments, setAvailableDocuments] = useState<Document[]>(documents);
    const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewFile, setPreviewFile] = useState<string | null>(null);
    const [fileUpload,setFileUpload]=useState<{wrapper:any} | null>(null);
    console.log(documents,"DOCUMENTS")
    useEffect(()=>{
        if(documents){
            const updatedDocuments=documents?.map((doc:any)=>{
                if(doc.title === 'License'){
                    return {
                        ...doc,
                        title:'Nursing License'
                    }
                }
                return doc;
            })
            updatedDocuments.push({
                key:'others',
                _id:'others',
                title:'Add Additional Document'
            })
            setAvailableDocuments(updatedDocuments)
        }
    },[documents])

    const handleSelectChange = (value: string) => {
        setSelectedDocumentId(value);
    };

    // const handleUpload = (file: RcFile) => {
    //     if (!selectedDocumentId) {
    //         message.error("Please select a document type first.");
    //         return false;
    //     }
    //
    //     const selectedDocument = availableDocuments.find((doc) => doc._id === selectedDocumentId);
    //
    //     if (selectedDocument) {
    //         const uploadConfig: TsFileUploadConfig = {
    //             file: file,
    //             fileFieldName: 'Data',
    //             uploadUrl: ENV.API_URL + 'hcp/add',
    //             allowed_types: ['jpg', 'png', 'csv', 'pdf','doc','docx'],
    //             extraPayload: {expiry_date: ''}
    //         };
    //
    //         const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state:{
    //             wrapper: TsFileUploadWrapperClass
    //         })=>{
    //
    //         });
    //
    //         const newFile = {
    //             documentId: selectedDocument._id === "others" ? `others-${uuidv4()}` : selectedDocument._id,
    //             documentTitle:  selectedDocument._id === 'others' ? "" : selectedDocument.title,
    //             file,
    //             expiry_date: "",
    //         };
    //
    //         setUploadedFiles((prev: any) => [...prev, newFile]);
    //         if(selectedDocument?._id !== 'others'){
    //             setAvailableDocuments((prev) =>
    //                 prev.filter((doc) => doc._id !== selectedDocumentId)
    //             );
    //         }
    //         setSelectedDocumentId(null);
    //         message.success(`${file.name} uploaded successfully.`);
    //     }
    //
    //     return false; // Prevent automatic upload
    // };

    const handleUpload = (file: RcFile) => {
        if (!selectedDocumentId) {
            message.error("Please select a document type first.");
            return false;
        }

        const selectedDocument = availableDocuments.find((doc) => doc._id === selectedDocumentId);

        if (selectedDocument) {
            // Prepare the file object to be added to the uploaded files state
            const newFile = {
                documentId: selectedDocument._id === "others" ? `others-${uuidv4()}` : selectedDocument._id,
                documentTitle: selectedDocument._id === 'others' ? "" : selectedDocument.title,
                submission_type:'documents_submission',
                file,
            };

            // Add the file to the uploaded files state
            setUploadedFiles((prev: any) => [...prev, newFile]);

            // Remove the selected document from the available documents if it's not 'others'
            if (selectedDocument._id !== 'others') {
                setAvailableDocuments((prev) =>
                    prev.filter((doc) => doc._id !== selectedDocumentId)
                );
            }

            // Reset the selected document ID
            setSelectedDocumentId(null);

            // Now handle the file upload logic (similar to the one in your OnOtherFileSelected function)
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: 'Data',
                uploadUrl: ENV.API_URL + 'facility/add',
                allowed_types: ['jpg', 'png', 'csv', 'pdf'],
                extraPayload: { expiry_date: '', file_type: "others" }
            };

            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: { wrapper: TsFileUploadWrapperClass }) => {
                setFileUpload((prevState: any) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return { wrapper: prevState.wrapper };
                    }
                    return prevState;
                });
            });

            uploadWrapper.onError = (err, heading) => {
                if (heading) {
                    CommonService.showToast(err, 'error');
                }
            };

            uploadWrapper.onSuccess = (resp) => {
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, 'success');
                }
            };

            uploadWrapper.onProgress = (progress) => {
                // You can implement file upload progress handling here if needed
            };

            // Add the upload wrapper to the state to track the upload status
            setFileUpload((prevState: any) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return { wrapper: newState };
            });

            // Show a success message once the file is successfully "prepared" for upload
            message.success(`${file.name} uploaded successfully.`);
        }

        return false; // Prevent automatic upload
    };


    const handleDeleteFile = useCallback((record: UploadedFile) => {
        setUploadedFiles((prev: any) => prev.filter((file: any) => file.documentId !== record.documentId));
        const restoredDocument = documents.find((doc) => doc._id === record.documentId);
        if (restoredDocument) {
            setAvailableDocuments((prev) => [...prev, restoredDocument]);
        }
        message.success(`File for "${record.documentTitle}" deleted successfully.`);
    },[documents,setUploadedFiles])

    const handleDateChange = useCallback((date: any | null, record: UploadedFile) => {
        setUploadedFiles((prev: any) =>
            prev.map((file: any) =>
                file.documentId === record.documentId
                    ? {...file, expiry_date: date ? date.format("YYYY-MM-DD") : undefined}
                    : file
            )
        );
    },[setUploadedFiles])

    const handleOthersTitleChange = useCallback((value: string, record: UploadedFile) => {
        setUploadedFiles((prev: any) =>
            prev.map((file: any) =>
                file.documentId === record.documentId
                    ? { ...file, documentTitle: value }
                    : file
            )
        );
    },[setUploadedFiles])

    const handleFilePreview = useCallback((index: any) => {
        setPreviewVisible(true)
        setPreviewFile(fileUpload?.wrapper[index])
    }, [fileUpload]);

    const closePreview = useCallback(() => {
        setPreviewVisible(false);
        setPreviewFile(null);
    }, []);


    const columns =useMemo(()=>{
        return [
            {
                title: "Document Title",
                dataIndex: "documentTitle",
                key: "documentTitle",
                render: (item: any, record: UploadedFile) => (
                    <div>
                        {record?.documentId?.startsWith("others") ? ( // Check for "others" documents
                            <Input
                                placeholder="Enter Document Title"
                                value={record.documentTitle || ""}
                                onChange={(e) => handleOthersTitleChange(e.target.value, record)} // Update the title
                            />
                        ) : (
                            <div>{item || "N/A"}</div>
                        )}
                    </div>
                ),
            },
            {
                title: "File Name",
                dataIndex: "file",
                key: "file",
                render: (file: RcFile,record:any,rowIndex:number) =>{
                    return (
                        <div
                            onClick={()=>handleFilePreview(rowIndex)}
                            style={{
                                textDecoration:'underline',
                                cursor:'pointer'
                            }}
                        >
                            {file.name || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: "Expiry Date",
                key: "expiry_date",
                render: (_: any, record: UploadedFile) => (
                    <DatePicker
                        style={{width: "100%"}}
                        onChange={(date:any) => handleDateChange(date, record)}
                        value={record.expiry_date ? dayjs(record.expiry_date) : undefined}
                        format="YYYY-MM-DD"
                    />
                ),
            },
            {
                title: "Action",
                key: "action",
                width:100,
                render: (_: any, record: UploadedFile) => (
                    <Button
                        type="primary"
                        danger
                        icon={<DeleteOutlined/>}
                        onClick={() => handleDeleteFile(record)}
                    >
                        Delete
                    </Button>
                ),
            },
        ]
    },[handleDateChange,handleOthersTitleChange,handleDeleteFile,handleFilePreview])


    useEffect(() => {
        console.log('HcpAttachmentComponentANTD', uploadedFiles);
    }, [uploadedFiles]);


    return (
        <div>
            <DialogComponent open={previewVisible} cancel={closePreview} class="preview-content">
                <CustomPreviewFile cancel={closePreview} confirm={closePreview}
                                   previewData={previewFile}/>
            </DialogComponent>
            <div style={{marginBottom: "1rem"}}>
                <Select
                    showSearch
                    placeholder="Select a document type"
                    value={selectedDocumentId || undefined}
                    onChange={handleSelectChange}
                    style={{width: "300px", marginRight: "1rem"}}
                    filterOption={(input, option) =>
                        (option?.label as string).toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {availableDocuments.map((doc) => (
                        <Option key={doc._id} value={doc._id} label={doc.title}>
                            {doc.title}
                        </Option>
                    ))}
                </Select>
                <Upload
                    beforeUpload={handleUpload}
                    showUploadList={false}
                    accept=".pdf,.doc,.docx,.jpg,.png"
                >
                    <Button type="primary" icon={<UploadOutlined/>} disabled={!selectedDocumentId}>
                        Upload File
                    </Button>
                </Upload>
            </div>
            <Table
                dataSource={uploadedFiles.map((file: any) => ({
                    ...file,
                    key: file.documentId,
                }))}
                columns={columns}
                pagination={{pageSize: 10}}
            />
        </div>
    );
};

export default HcpAttachmentComponentANTD;
