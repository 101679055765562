import "./ChangeStatusComponent.scss";
import FormLabelComponent from "../../../../ant-component/form-label/FormLabelComponent";
import SelectDropdownComponent from "../../../../ant-component/select/SelectComponent";
import {useCallback, useState} from "react";
import TextEditorComponent from "../../../../../components/text-editor/TextEditorComponent";
import {Button} from "antd";
import {CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import {applicantStatusList, jobStatusList} from "../../../../../data";

interface ChangeStatusComponentProps{
    selectedJobs:string[];
    setOpenChangeStatusModal?:any;
    setSelectedJobs?:any;
    filterData?:any;
    setFilterData?:any;
    module?:any;
}

const ChangeStatusComponent=(props:ChangeStatusComponentProps)=>{
    const {selectedJobs,setOpenChangeStatusModal,setSelectedJobs,filterData,setFilterData,module}=props;
    const [status,setStatus]=useState<string>("");
    const [reason,setReason]=useState<string>('');
    const [isSubmitting,setIsSubmitting]=useState<boolean>(false)
    const list= module === 'job' ? jobStatusList : applicantStatusList



    const handleChangeStatus=useCallback(()=>{
        setIsSubmitting(true);
        let payload:any
        let apiCall:any;
        if(module === 'job'){
            payload={
                job_status:status,
                reason:reason,
                job_ids:selectedJobs
            }
            apiCall=CommonService._api.put(ENV.API_URL + `ats/editBulkJobStatus`,payload)
        }else{
            payload={
                applicant_status:status,
                reason:reason,
                hcp_ids:selectedJobs
            }
            apiCall=CommonService._api.put(ENV.API_URL + `ats/editBulkApplicantStatus`,payload)
        }
        apiCall.then((response:any)=>{
            setFilterData({...filterData})
            CommonService.showToast(response.message || 'Successfully changed the status','success')
        }).catch((error:any)=>{
                CommonService.showToast(error.error,'error')
        }).finally(()=>{
            setOpenChangeStatusModal(false);
            setSelectedJobs([]);
            setIsSubmitting(false);
        })
    },[setOpenChangeStatusModal,module,selectedJobs,status,reason,setSelectedJobs,filterData,setFilterData])

    const handleCancel=useCallback(()=>{
        setReason('');
        setStatus('');
        setOpenChangeStatusModal(false);
        setSelectedJobs([])
    },[setOpenChangeStatusModal,setSelectedJobs])

    return (
        <div className={'change-status-component'}>
            <div className={'mrg-top-20'}>
                <FormLabelComponent Label={`${module === 'job' ? "Job Status*": "Applicant Status*"}`}/>
                <SelectDropdownComponent
                    placeholder={'Select'}
                    className={'mrg-top-10'}
                    value={status}
                    options={list || []}
                    required={true}
                    size={"middle"}
                    displayWith={(option) => option.title}
                    valueExtractor={(option) => option.code}
                    onChange={(value: string) => {
                        setStatus(value)
                    }}
                />
            </div>
            <div className={'mrg-top-20'}>
                <FormLabelComponent Label={"Reason(Optional)"}/>
                <div className={'change-status-text-editor-wrapper'}>
                    <TextEditorComponent
                        value={reason}
                        onChange={(value: string) => setReason(value)}
                    />
                </div>
            </div>
            <div className={'status-btn-wrapper'}>
                <Button
                    type={'primary'}
                    disabled={!status}
                    className="custom-primary-button"
                    onClick={handleChangeStatus}
                    loading={isSubmitting}
                >Update</Button>
                <Button className="custom-outline-button" onClick={handleCancel}>Cancel</Button>
            </div>
        </div>
    )
}
export default ChangeStatusComponent