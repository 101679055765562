import "./SettingsComponent.scss";
import React, { useEffect} from "react";
import {Communications} from "../../helpers";
import NotificationComponent from "./notifications/NotificationComponent";


const SettingsComponent=()=>{
    // const [activeTab,setActiveTab]=useState<string>('notifications')
    //
    // const settingsTabs: TabsProps['items'] = [
    //     { key: 'notification',label:'Notifications',children:<NotificationComponent/>},
    //     // { key: 'reminder',label:'Reminder',children:<ReminderComponent/>},
    // ];
    //
    // const handleTabChange = useCallback((key: string) => {
    //     setActiveTab(key);
    // },[])

    useEffect(() => {
        Communications.pageTitleSubject.next("Settings");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    return (
        <div>
            <div className={"settings-component screen crud-layout pdd-30 pdd-top-10"}>
                <div className="custom-border pdd-10 pdd-top-20 pdd-bottom-0">
                    <div className="settings-tab-wrapper">
                        {/*<Tabs*/}
                        {/*    defaultActiveKey={activeTab}*/}
                        {/*    items={settingsTabs}*/}
                        {/*    onChange={handleTabChange}*/}
                        {/*    destroyInactiveTabPane={true}*/}
                        {/*/>*/}
                        <NotificationComponent/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsComponent