import {useState} from "react";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {ENV} from "../../../../constants";
import {ContactedTableColumnList} from "../contactedTableColumnList";
import {useLocation} from "react-router-dom";

const ExpiringCredentialsComponent=()=>{
    const location=useLocation();
    const [filterData]=useState<any>({
        recruiter_id:'',
        applicant_status: ['expiring_credentials'],
        search:'',
    })
    return (
        <div>
            <AntTableComponent
                url={ENV.API_URL + "ats/applicantList"}
                method={'post'}
                extraPayload={filterData}
                columns={ContactedTableColumnList(location?.search) || []}
                noDataText={'No Expiring Credentials Data Found.'}
            />
        </div>
    )
}

export default ExpiringCredentialsComponent