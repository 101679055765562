import "./ChatSearchComponent.scss";
import InputComponent from "../../../app/ant-component/input/InputComponent";
import {Button} from "antd";
import {SendOutlined} from "@ant-design/icons";
import {useCallback} from "react";

interface ChatSearchComponentProps{
    setChatMessage?:any;
    chatMessage?:any;
    handleClick?:any;
    chatDetails?:any;
}

const ChatSearchComponent=(props:ChatSearchComponentProps)=>{
    const {chatMessage,setChatMessage,handleClick,chatDetails}=props;

    const handleSendMessage=useCallback(()=>{
            handleClick?.(chatDetails)
            setChatMessage('')
    },[handleClick,chatDetails,setChatMessage])

    return (
        <div className={'chat-search-component'}>
            <div className={'chat-input-wrapper'}>
               <InputComponent
                 placeholder={'Message...'}
                 value={chatMessage}
                 onChange={(value)=>setChatMessage(value)}
                 onPressEnter={handleSendMessage}
               />
            </div>
            <Button className={'chat-send-message-btn'} disabled={!chatMessage} onClick={handleSendMessage}>
                <SendOutlined />
            </Button>
        </div>
    )
}

export default ChatSearchComponent