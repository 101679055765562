import "./EmailDocumentComponent.scss";
import {ENV, ImageConfig} from "../../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import {Button, DatePicker, Divider, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import type {RcFile} from "antd/es/upload";
import {CommonService} from "../../../../helpers";
import {useParams,useRouteMatch} from "react-router-dom";
import LoaderComponent from "../../../../components/loader/LoaderComponent";
import moment from "moment";
import dayjs from "dayjs";


const EmailDocumentComponent=()=> {
    const match = useRouteMatch();
    const baseRoute = match.path.split("/:")[0];
    const {jobId, hcpId} = useParams<{ jobId: string; hcpId: string }>();
    const [documentList, setDocumentList] = useState<any>({})
    const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<any>({})
    const [expiryDates,setExpiryDates]=useState<any>({});

    const getDocumentList = useCallback(() => {
        // if (!jobId || !hcpId) return;
        setIsDocumentLoading(true);
        let apiCall:any;
        if(baseRoute === '/ats_job'){
            apiCall=CommonService._api.get(ENV.API_URL + `job/${jobId}/hcp/${hcpId}`)
        }else{
            let payload:any={
                hcp_id:hcpId
            }
            apiCall=CommonService._api.post(ENV.API_URL + `hcp/hcpDocsList`,payload)
        }
        apiCall.then((response:any) => {
            setDocumentList(response?.data);
        }).catch((error: any) => {
            CommonService.showToast(error.error, 'error')
        }).finally(() => {
            setIsDocumentLoading(false);
        })
    }, [jobId, hcpId,baseRoute])

    useEffect(() => {
        getDocumentList();
    }, [getDocumentList,baseRoute]);

    const handleDateChange = useCallback((date: dayjs.Dayjs | null, item: string) => {
        console.log(date,item)
        setExpiryDates((prev: any) => ({
            ...prev,
            [item]: date ? date.format("YYYY-MM-DD") : null,
        }));
    }, []);

    const handleUpload = useCallback((file: RcFile, submissionType, attachmentType) => {
        const formData = new FormData();
        formData.append('attachment_type', attachmentType);
        formData.append('expiry_date', expiryDates[attachmentType] ? moment(expiryDates[attachmentType]).format('MM-DD-YYYY') : '');
        formData.append('attachment', file);
        formData.append('submission_type', submissionType);
        setIsUploading((prevState: any) => ({
            ...prevState,
            [attachmentType]: true
        }))
        let apiCall:any;
        if(baseRoute === '/ats_job'){
            apiCall=CommonService._api.upload(ENV.API_URL + `job/${jobId}/hcp/${hcpId}`, formData);
        }else {
            apiCall=CommonService._api.upload(ENV.API_URL + "hcp/" + hcpId + "/attachment", formData)
        }
        apiCall.then((response: any) => {
            CommonService.showToast(response.message || 'Document Uploaded Successfully', 'success')
            getDocumentList();
        }).catch((error: any) => {
            CommonService.showToast(error.error, 'error');
        }).finally(() => {
            setIsUploading((prevState: any) => ({
                ...prevState,
                [attachmentType]: false
            }))
        })
    }, [jobId, hcpId, getDocumentList,expiryDates,baseRoute])


    const renderSubmissionSection = (submissionType: string, items: any) => {
        if (!items || items.length === 0) return null;
        const title = submissionType?.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        return (
            <div key={submissionType} className={'mrg-top-20'}>
                <div className={'submission-title'}>{title}</div>
                {items.map((item: any, index: number) => (
                    <div className={'document-card'} key={index}>
                        <div className={'document-attachment-type'}>{item}</div>
                        <div className={'document-attachment-btn'}>
                            <DatePicker
                                placeholder={'Expiry Date'}
                                disabledDate={(current) => current && current < dayjs().startOf('day')}
                                value={expiryDates[item] ? dayjs(expiryDates[item]) : null}
                                onChange={(date) => handleDateChange(date, item)}
                                className={'mrg-right-20'}
                                getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
                            />
                            <Upload
                                beforeUpload={(file) => handleUpload(file, submissionType, item)}
                                showUploadList={false}
                                accept=".pdf,.doc,.docx,.jpg,.png"
                            >
                                <Button type="primary" icon={<UploadOutlined/>} loading={isUploading[item]}
                                        className={'custom-primary-button'}>
                                    Upload
                                </Button>
                            </Upload>
                        </div>
                    </div>
                ))}
                <Divider/>
            </div>
        );
    };

    const allArraysEmpty =
        documentList?.certifications_eboarding?.length === 0 &&
        documentList?.certifications_submission?.length === 0 &&
        documentList?.documents_eboarding?.length === 0 &&
        documentList?.skill_checklist_submission?.length === 0 &&
        documentList?.competency_test?.length === 0;


    return (
        <div className={'email-document-component'}>
            <div className={'email-document-header'}>
                <div className={'vitawerks-logo'}>
                    <img src={ImageConfig.Logo} alt={'vitawerks'}/>
                </div>
            </div>
            <div className={'email-document-content'}>
                <div className={'email-document-holder'}>
                    {isDocumentLoading ?
                        <div className={'document-loader'}>
                            <LoaderComponent type={'spinner'} color={'primary'}/>
                        </div> :
                        <div>
                            {!allArraysEmpty?
                                <>
                                    <div className={'required-documents-title'}>
                                        Required Documents
                                    </div>
                                    <div className={'document-list'}>
                                        {renderSubmissionSection('documents_submission', documentList.documents_submission)}
                                        {renderSubmissionSection('certifications_submission', documentList.certifications_submission)}
                                        {renderSubmissionSection('license_submission', documentList.license_submission)}
                                        {renderSubmissionSection('skill_checklist_submission', documentList.skill_checklist_submission)}
                                        {renderSubmissionSection('competency_test',documentList.competency_test)}
                                    </div>
                                </>
                                :
                                <div className={'no-document-text'}>
                                    Thank you for uploading all the documents.
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EmailDocumentComponent;