import "./MessageSenderCardComponent.scss";
import {CommonService} from "../../../helpers";

interface MessageSenderCardComponentProps{
    message?:any;
    timestamp?:any;
}

const MessageSenderCardComponent=(props:MessageSenderCardComponentProps)=>{
    const {message,timestamp}=props
    return (
        <div className={'message-sender-card-component'}>
            <div className={'message-text'}>{message}</div>
            <div className={'message-timestamp'}>{CommonService.formatTime(timestamp)}</div>
        </div>
    )
}

export default MessageSenderCardComponent