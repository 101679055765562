import './MyPipeLineListComponent.scss';
import React, {useCallback, useEffect, useState} from "react";
import {Tabs} from 'antd';
import type { TabsProps } from 'antd';
import ActiveComponent from "../active/ActiveComponent";
import ExpiringCredentialsComponent from "../expiring-credentials/ExpiringCredentialsComponent";
import NewLeadComponent from "../new-lead/NewLeadComponent";
import OfferAcceptedComponent from "../offer-accepted/OfferAcceptedComponent";
import DocumentInProgressComponent from "../document-in-progress/DocumentInProgressComponent";
import InterviewComponent from "../interview/InterviewComponent";
import OfferExtendedComponent from "../offer-extended/OfferExtendedComponent";
import SubmissionsComponent from "../submissions/SubmissionsComponent";
import ContactedComponent from "../contacted/ContactedComponent";
import {Communications} from "../../../../helpers";
import {useHistory, useLocation} from "react-router-dom";


const MyPipeLineListComponent=()=>{
    const location=useLocation();
    const history=useHistory();
    const defaultTab = 'new_lead';
    const searchParams = new URLSearchParams(location.search);
    const urlTab = searchParams.get('tab');
    const [activeTab,setActiveTab]=useState<string>(urlTab || defaultTab)

    useEffect(() => {
        if (!urlTab) {
            history.replace(`?tab=${defaultTab}`);
        }
    }, [urlTab, history]);



    const pipeLineTabs: TabsProps['items'] = [
        { key: 'new_lead',label:'New Lead',children:<NewLeadComponent/>},
        { key: 'contacted',label:'Contacted',children:<ContactedComponent/>},
        { key: 'active', label: 'Active', children: <ActiveComponent/> },
        { key: 'offer_accepted',label:'Offer Accepted',children:<OfferAcceptedComponent/>},
        { key: 'interview',label:'Interview',children:<InterviewComponent/>},
        { key: 'offer_extended',label:'Offer Extends',children:<OfferExtendedComponent/>},
        { key: 'submissions', label: 'Submissions', children: <SubmissionsComponent/> },
        { key: 'document_in_progress',label:'Document In Progress',children:<DocumentInProgressComponent/>},
        { key: 'expiring-credentials', label: 'Expiring Credentials', children: <ExpiringCredentialsComponent/> },
    ];



    const handleTabChange = useCallback((key: string) => {
        setActiveTab(key);
        history.push(`?tab=${key}`);
    },[history])

    useEffect(() => {
        Communications.pageTitleSubject.next("Pipeline");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    return (
        <>
            <div className={"pipeline-list-component screen crud-layout pdd-30 pdd-top-10"}>
                <div className="custom-border pdd-10 pdd-top-20 pdd-bottom-0">
                    <div className="pipeline-tab-wrapper">
                        <Tabs
                            defaultActiveKey={activeTab}
                            items={pipeLineTabs}
                            onChange={handleTabChange}
                            destroyInactiveTabPane={true}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default MyPipeLineListComponent