import "./ContractRateCardDetailsComponent.scss";
import {Divider, Tooltip} from "antd";
import DataLabelValueComponent from "../../../../../components/data-label-value/DataLabelValueComponent";
import {InfoCircleOutlined} from "@ant-design/icons";

interface ContractRateCardDetailsComponentProps{
    rateDetails?:any;
}

const ContractRateCardDetailsComponent=(props:ContractRateCardDetailsComponentProps)=>{
    const {rateDetails}=props;
    return (
        <div className={'mrg-top-20 contract-rate-card-details'}>
            <div className='contract-rate-left-card'>
                <div className={'rate-type-header'}>Pay Rate Details</div>
                <div className={'d-flex mrg-top-20'}>
                    <div className={'flex-equal'}>
                        <DataLabelValueComponent label={'Type'}>

                            {
                                rateDetails?.pay_rate?.travel_per_diems?.map((item: any, index: any) => {
                                    return (
                                        <div className={'mrg-bottom-20'}>
                                            {item?.type}
                                        </div>
                                    )
                                })
                            }

                        </DataLabelValueComponent>
                    </div>
                    <div className={'flex-equal'}>
                        <DataLabelValueComponent
                            label={'Rate'}
                        >
                            {
                                rateDetails?.pay_rate?.travel_per_diems?.map((item: any, index: any) => {
                                    return (
                                        <div
                                            className={'d-flex ts-justify-content-between mrg-bottom-20'}>
                                            <div>
                                                {item?.rate ? <>
                                                    $ {Number(item?.rate).toFixed(2)} {index === 0 ? "/hr" : "/day"}
                                                </> : "N/A"
                                                }
                                            </div>
                                            {/*<div*/}
                                            {/*    className={'days-rate-text-orientation '}>*/}
                                            {/*    {`(${shiftRequirementDetails?.day_per_week}days  *  $${item?.rate})`}*/}
                                            {/*</div>*/}
                                        </div>
                                    )
                                })
                            }

                        </DataLabelValueComponent>
                    </div>
                    <div className={'flex-equal'}>
                        <DataLabelValueComponent
                            label={'Rate/week'}
                        >
                            {
                                rateDetails?.pay_rate?.travel_per_diems?.map((item: any, index: any) => {
                                    return (
                                        <div
                                            className={'d-flex ts-justify-content-between mrg-bottom-20'}>
                                            <div>
                                                {item?.week_rate ?
                                                    <>
                                                        $ {Number(item?.week_rate)?.toFixed(2)} /week
                                                    </> : "N/A"
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </DataLabelValueComponent>
                    </div>
                </div>
                <Divider className={'divider'}/>
                <div className={'rate-type-header'}>Bill Rate Details</div>
                <div className={'mrg-top-20 mrg-bottom-20'}>
                    <div className={'d-flex mrg-bottom-20'}>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Bill Rate Per Hour'}>
                                {rateDetails?.bill_rate?.bill_rate_per_hour ?
                                    <>
                                        $ {Number(rateDetails?.bill_rate?.bill_rate_per_hour)?.toFixed(2)}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Daily Lodging - Daily (GSA)'}>
                                {rateDetails?.bill_rate?.daily_lodging_gsa ?
                                    <>
                                        {Number(rateDetails?.bill_rate?.daily_lodging_gsa)?.toFixed(2)}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'M&I Daily'}>
                                {rateDetails?.bill_rate?.mi_daily ?
                                    <>
                                        {Number(rateDetails?.bill_rate?.mi_daily)?.toFixed(2)}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                    </div>
                    <div className={'d-flex mrg-bottom-20'}>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Guaranteed Hours-Weekly'}>
                                {rateDetails?.bill_rate?.guaranteed_hours_weekly ?
                                    <>
                                        {Number(rateDetails?.bill_rate?.guaranteed_hours_weekly)?.toFixed(2)}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'VMS Fee of Bill Rate'}>
                                {rateDetails?.bill_rate?.vms_fee_bill_rate ?
                                    <>
                                        $ {Number(rateDetails?.bill_rate?.vms_fee_bill_rate)?.toFixed(2)}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'State Burden-State Taxes'}>
                                {rateDetails?.bill_rate?.state_burden_taxes ?
                                    <>
                                        $ {Number(rateDetails?.bill_rate?.state_burden_taxes)?.toFixed(2)}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                    </div>
                    <div className={'d-flex mrg-bottom-20'}>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Gross Hourly Pay Rate(All Inclusive)'}>
                                {rateDetails?.bill_rate?.gross_hourly_pay_rate ?
                                    <>
                                        $ {Number(rateDetails?.bill_rate?.gross_hourly_pay_rate)?.toFixed(2)}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'contract-right-rate-card'}>
                <div className={'company-gross-card'}>
                    <div className={'title'}>Stipend</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Daily Lodging GSA + M&I Daily'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Daily
                        </div>
                        <div>{Number(rateDetails?.bill_rate?.total_daily_stipend).toFixed(2) || '-'}</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Weekly Stipend / Guaranteed Hours-Weekly'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Hourly
                        </div>
                        <div>{Number(rateDetails?.bill_rate?.hourly_stipend).toFixed(2) || '-'}</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Daily Stipend * No of Days Per Week (From Step 1)'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Weekly
                        </div>
                        <div>{Number(rateDetails?.bill_rate?.weekly_stipend).toFixed(2) || '-'}</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Tax Free + Taxable Hourly Income'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Total Hourly
                        </div>
                        <div>{Number(rateDetails?.bill_rate?.total_hourly).toFixed(2) || '-'}</div>
                    </div>
                </div>
                <div className={'candidate-gross-card'}>
                    <div className={'title'}>Taxable Amount</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Gross Hourly Pay Rate(All Inclusive) - Hourly Stipend'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Hourly
                        </div>
                        <div>{Number(rateDetails?.bill_rate?.taxable_hourly_income).toFixed(2) || '-'}</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Taxable Hourly Income * Guaranteed Hours-Weekly'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Weekly
                        </div>
                        <div>{Number(rateDetails?.bill_rate?.taxable_weekly_income).toFixed(2) || '-'}</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Total Hourly - (Total Hourly * State Burden-State Taxes)'}
                                     placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            W2 Rate Hourly
                        </div>
                        <div>{rateDetails?.bill_rate?.w2_hourly_rate || '-'}</div>
                    </div>
                </div>
                <div className={'gross-rate-card'}>
                    <div className={'title'}>Non Taxable Amount</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Same as Hourly Stipend Rate'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Tax Free
                        </div>
                        <div>{Number(rateDetails?.bill_rate?.tax_free_hourly).toFixed(2) || '-'}</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Tax Free * Guaranteed Hours Weekly'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Weekly
                        </div>
                        <div>{Number(rateDetails?.bill_rate?.tax_free_weekly_income).toFixed(2) || '-'}</div>
                    </div>
                </div>
                <div className={'gross-total-card'}>
                    <div className={'title'}>
                        <Tooltip title={'Taxable Weekly + No Taxable Weekly'} placement={'top'}>
                            <InfoCircleOutlined/>
                        </Tooltip> {" "}
                        Gross Total Weekly
                    </div>
                    <div>{Number(rateDetails?.bill_rate?.gross_total_weekly).toFixed(2) || '-'}</div>
                </div>
                <div className={'guaranteed-week-card'}>
                    <div className={'title'}>
                        <Tooltip title={'Total Hour * 1.5'} placement={'top'}>
                            <InfoCircleOutlined/>
                        </Tooltip> {" "}
                        OT Rate
                    </div>
                    <div>{Number(rateDetails?.bill_rate?.ot_hourly_rate).toFixed(2) || '-'}</div>
                </div>
            </div>
        </div>
    )
}

export default ContractRateCardDetailsComponent;