import {Button, IconButton, MenuItem, Tooltip} from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import { DatePicker } from "formik-material-ui-pickers";
import moment from "moment";
import { nanoid } from "nanoid";
import React, {useMemo, useState,useCallback} from "react";
import { CommonService } from "../../../../../helpers";
import "./VolunteerExperienceAddComponent.scss";
import { volunteerExperienceValidation } from "./VolunteerExperienceValidation";
import {TableColumnsType} from "antd";
import DeleteIcon from "@material-ui/icons/Delete";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";

type VolunteerExperienceAddComponentProps = {
  experiences: any;
  setExperience: any;
};

interface ExperienceItem {
  organisation: string;
  location: string;
  stillWorkingHere: string;
  startDate: any;
  endDate: any;
  speciality: string;
  positionTitle: string;
  skills: any;
}

const experienceInitialState: ExperienceItem = {
  organisation: "",
  location: "",
  stillWorkingHere: "",
  startDate: null,
  endDate: null,
  speciality: "",
  positionTitle: "",
  skills: "",
};

const VolunteerExperienceAddComponent = ({ experiences, setExperience }: VolunteerExperienceAddComponentProps) => {
  const [isExperiences, setIsExperiences] = useState<boolean>(false);
  const [showEndDate, setShowEndDate] = useState<boolean>(true);

  const onAdd = (experience: ExperienceItem, { setSubmitting, setErrors, resetForm }: FormikHelpers<ExperienceItem>) => {
    const newExperience = {
      tempId: nanoid(),
      facility_name: experience.organisation,
      specialisation: experience.speciality,
      unit: experience.speciality,
      still_working_here: experience.stillWorkingHere,
      location: experience.location,
      start_date: experience.startDate ? moment(experience.startDate).format("YYYY-MM") : null,
      end_date: experience.endDate ? moment(experience.endDate).format("YYYY-MM") : null,
      position_title: experience.positionTitle,
      exp_type: "volunteer",
      skills: experience.skills,
    };

    const newExperiences = [...experiences, newExperience];
    setExperience(newExperiences);

    resetForm();
    handleCancelExperience();
    CommonService.showToast("Staff volunteer experience added", "info");
  };

  const handleCancelExperience = () => {
    setIsExperiences(false);
  };

  const handleDeleteClick = useCallback((experienceId: number) => {
    const newExperiences = [...experiences];
    const index = experiences.findIndex((experience: any) => experience.tempId === experienceId);
    newExperiences.splice(index, 1);
    setExperience(newExperiences);
    CommonService.showToast("Staff volunteer experience deleted", "success");
  },[experiences,setExperience])

  const sortedExpData = CommonService.sortDatesByLatest(experiences, "start_date");

  const experienceListColumn:TableColumnsType=useMemo(()=>{
    return [
      {
        title:'Organisation Name',
        dataIndex:'facility_name',
        key:'facility_name',
        width:200,
        render:(item:any)=>{
          return (
              <div>
                {item || 'N/A'}
              </div>
          )
        }
      },
      {
        title:'Location',
        dataIndex:'location',
        key:'location',
        width:160,
        render:(item:any)=>{
          return (
              <div>
                {item || 'N/A'}
              </div>
          )
        }
      },
      {
        title:'Position Title',
        width:200,
        dataIndex:'position_title',
        key:'position_title',
        render:(item:any)=>{
          return (
              <div>
                {item?.length > 20 ?
                    <Tooltip title={item}>
                      <div className={'ellipses-for-table-data'}>
                        {item || 'N/A'}
                      </div>
                    </Tooltip>:
                    <div>
                      {item || 'N/A'}
                    </div>
                }
              </div>
          )
        }
      },
      {
        title:'Speciality',
        width:200,
        dataIndex:'specialisation',
        key:'specialisation',
        render:(item:any)=>{
          return (
              <div>
                {item?.length > 20 ?
                    <Tooltip title={item}>
                      <div className={'ellipses-for-table-data'}>
                        {item || 'N/A'}
                      </div>
                    </Tooltip>:
                    <div>
                      {item || 'N/A'}
                    </div>
                }
              </div>
          )
        }
      },
      {
        title:'Start Date',
        width:120,
        dataIndex:'start_date',
        key:'start_date',
        render:(item:any)=>{
          return (
              <div>
                {item ? moment(item).format('MM-YYYY') : 'N/A'}
              </div>
          )
        }
      },
      {
        title:'End Date',
        width:120,
        dataIndex:'end_date',
        key:'end_date',
        render:(item:any)=>{
          return (
              <div>
                {item ? moment(item).format('MM-YYYY') : 'N/A'}
              </div>
          )
        }
      },
      {
        title: 'Skills',
        width: 200,
        dataIndex: 'skills',
        key: 'skills',
        render: (item: any) => {
          return (
              <div>
                {item?.skills || 'N/A'}
              </div>
          )
        }
      },
      {
        title: 'Delete',
        width: 80,
        dataIndex: 'tempId',
        key: 'tempId',
        render: (item: any) => {
          return (
              <div className={'display-center'}>
                <Tooltip title={`Delete Experience`}>
                  <IconButton onClick={() => handleDeleteClick(item)}>
                    <DeleteIcon className="delete-icon" />
                  </IconButton>
                </Tooltip>
              </div>
          )
        }
      }
    ]
  },[handleDeleteClick])

  return (
    <div className="add-container">
      {/*{experiences.length > 0 && (*/}
      {/*  <Table className="mrg-top-50">*/}
      {/*    <TableHead className={"mat-thead"}>*/}
      {/*       <TableRow className={"mat-tr"}>*/}
      {/*        <th>Organisation Name</th>*/}
      {/*        <th>Location</th>*/}
      {/*        <th>Position Title</th>*/}
      {/*        <th style={{ width: "15%" }}>Speciality</th>*/}
      {/*        <th>Start Date</th>*/}
      {/*        <th>End Date</th>*/}
      {/*        <th>Skills</th>*/}
      {/*      </TableRow>*/}
      {/*    </TableHead>*/}
      {/*   <TableBody className={"mat-tbody"}>*/}
      {/*      {sortedExpData.map((experience: any, index: number) => (*/}
      {/*        <>*/}
      {/*          <ReadOnlyRow key={index} experience={experience} handleDeleteClick={handleDeleteClick} />*/}
      {/*        </>*/}
      {/*      ))}*/}
      {/*    </TableBody>*/}
      {/*  </Table>*/}
      {/*)}*/}

      {isExperiences ? (
        <div className="add-input">
          <Formik initialValues={experienceInitialState} validateOnChange={true} validationSchema={volunteerExperienceValidation} onSubmit={onAdd}>
            {({ isSubmitting, handleSubmit, isValid, resetForm, setFieldValue }) => (
              <Form className={"form-holder"}>
                <div className="input-container">
                  <Field variant="outlined" component={TextField} InputLabelProps={{ shrink: true }} fullWidth name="organisation" label="Organisation*" id="input_hcp_add_Vexperience_name" />

                  <Field variant="outlined" component={TextField} InputLabelProps={{ shrink: true }} fullWidth name="location" label="Location*" id="input_hcp_add_Vexperience_location" />
                </div>

                <div className="input-container">
                  <Field variant="outlined" component={TextField} InputLabelProps={{ shrink: true }} fullWidth name="positionTitle" label="Position Title*" id="input_hcp_add_Vexperience_position" />
                  <Field variant="outlined" component={TextField} InputLabelProps={{ shrink: true }} fullWidth name="speciality" label="Speciality*" id="input_hcp_add_Vexperience_speciality" />
                </div>

                <div className="input-container">
                  <Field variant="outlined" component={TextField}  InputLabelProps={{ shrink: true }} fullWidth name="skills" label="Skills (optional)" id="input_hcp_add_Vexperience_skills" />
                  <Field
                      variant="outlined"
                      fullWidth
                      select
                      component={TextField}
                      name="stillWorkingHere"
                      label="Still Working ?*"
                      id="input_hcp_add_Vexperience_working_here"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e: any) => {
                        const isWorking = e.target.value;
                        if (isWorking === "1") {
                          setFieldValue("stillWorkingHere", isWorking);
                          setFieldValue("endDate", null);
                          setShowEndDate(false);
                        } else {
                          setFieldValue("stillWorkingHere", isWorking);
                          setShowEndDate(true);
                        }
                      }}
                  >
                    {[
                      { value: "1", label: "Yes" },
                      { value: "0", label: "No" },
                    ].map((item: any, index: any) => (
                        <MenuItem value={item.value} id={"menu_hcp_add_Vexperience_" + index}>
                          {item.label}
                        </MenuItem>
                    ))}
                  </Field>
                </div>

                <div className="input-container">

                  <Field
                    openTo="year"
                    views={["year", "month"]}
                    inputVariant="outlined"
                    component={DatePicker}
                    placeholder="MM/YYYY"
                    variant="inline"
                    fullWidth
                    name="startDate"
                    id="input_hcp_add_Vexperience_start_date"
                    label="Start Date"
                    InputLabelProps={{ shrink: true }}
                  />
                  {showEndDate && (
                      <Field
                          openTo="year"
                          views={["year", "month"]}
                          inputVariant="outlined"
                          component={DatePicker}
                          placeholder="MM/YYYY"
                          variant="inline"
                          fullWidth
                          name="endDate"
                          label="End Date"
                          id="input_hcp_add_Vexperience_end_date"
                          InputLabelProps={{ shrink: true }}
                      />
                  )}
                </div>

                <div className="hcp-common-btn-grp">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      resetForm();
                      handleCancelExperience();
                    }}
                    id="icon_hcp_add_Vexperience_cancel"
                  >
                    Delete
                  </Button>
                  <Button variant="contained" color="primary" type="submit" id="icon_hcp_add_Vexperience_submit">
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="exp-add-action">
          <Tooltip title={"Add New Volunteer Experience"}>
            <p id="btn_hcp_add_vol_experience" onClick={() => setIsExperiences(true)} className="generic-add-multiple">
              + Add Volunteer Experience
            </p>
          </Tooltip>
        </div>
      )}
      {experiences?.length > 0 &&
          <div>
            <AntTableComponent
              data={sortedExpData}
              columns={experienceListColumn}
              extraPayload={sortedExpData}
              isRowSelection={false}
            />
          </div>
      }
    </div>
  );
};

export default VolunteerExperienceAddComponent;
