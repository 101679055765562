import "./ResumeParserComponent.scss";
import React, {useState} from "react";
import {Upload, Button, Typography } from "antd";
import { UploadOutlined ,DeleteOutlined} from "@ant-design/icons";
import {CommonService} from "../../../../helpers";
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import animationData from "../../../../assets/animations/resumeParsing.json";
import Lottie from "react-lottie";
import {useHistory} from "react-router-dom";

// Initialize PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;


const { Dragger } = Upload;
const { Text } = Typography;

interface ResumeParserComponentProps{
    setOpenResumeParserModal?:any;
}

interface ExtractedData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

const ResumeParserComponent=(props:ResumeParserComponentProps)=>{
    const {setOpenResumeParserModal}=props;
    const history=useHistory();
    const [fileList, setFileList] = useState<any[]>([]);
    const [loading,setLoading]=useState<boolean>(false);

    const defaultOptions={
        animationData
    }

    const files = {
        name: "file",
        multiple: false,
        accept: ".pdf",
        fileList,
        beforeUpload: (file: any) => {
            const isPdf = file.type === "application/pdf";

            if (!isPdf) {
                CommonService.showToast('Only PDF files are allowed!','error')
                return Upload.LIST_IGNORE;
            }

            if (file.size / 1024 / 1024 > 10) {
                CommonService.showToast("File must be smaller than 10MB!",'error');
                return Upload.LIST_IGNORE;
            }

            setFileList([file]);
            return false;
        },
    };

    const removeFile = (index: number) => {
        setFileList(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    const parsePdf = async (file: File) => {
        setLoading(true);
        try {
            const arrayBuffer:any = await file.arrayBuffer();
            const pdf:any = await pdfjsLib.getDocument(arrayBuffer).promise;
            let fullText = '';

            for (let i = 1; i <= pdf.numPages; i++) {
                const page = await pdf.getPage(i);
                const textContent = await page.getTextContent();
                fullText += textContent.items.map((item:any) => item.str).join(' ') + '\n';
            }
            setLoading(false);
            history.push(`/applicant/add`,{
                data:extractInfo(fullText)
            })
        } catch (err) {
            CommonService.showToast('Failed to parse PDF. Please ensure it contains selectable text.','error')
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const extractInfo = (text: string): ExtractedData => {
        // Improved regex patterns
        const emailMatch = text.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/);
        const phoneMatch = text.match(/(?:\+?\d{1,3}[-.\s]?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}\b/);
        const nameMatch = text.match(/\b([A-Z][a-z'-]+)(?:\s+([A-Z][a-z'-]+))?\b/);

        return {
            firstName: nameMatch?.[1] || '',
            lastName: nameMatch?.[2] || '',
            email: emailMatch?.[0] || '',
            phone: phoneMatch?.[0] || ''
        };
    };

    return(
        <div>
            <div>
                {!loading ?
                    <div className={'bulk-upload-dialog'}>
                        <div className={'form-header-text mrg-bottom-20'}>Resume Parsing</div>
                        {fileList?.length === 0 &&
                            <Dragger {...files} className={'resume-drag-wrapper'}>
                                <UploadOutlined style={{ fontSize: "32px", color: "#999" }} />
                                <div className={'file-drop-zone-wrapper'}>
                                    <p className="ant-upload-text">Click or drag PDF file to this area to upload</p>
                                    <Text type="secondary">Supported file: .pdf</Text>
                                    <Text type="secondary">Max size: 10MB</Text>
                                </div>
                            </Dragger>
                        }

                        <div className={'d-flex mrg-top-10 mrg-bottom-20'}>
                            {fileList && fileList?.map((file: any, index: number) => {
                                return (
                                    <>
                                        <div
                                            className={'resume-file-holder-wrapper'}>
                                            <div>{file?.name}</div>
                                            <div className={'cursor-pointer mrg-top-3'}
                                                 style={{color:'red'}}
                                                 onClick={() => {
                                                     removeFile(index);
                                                 }}>
                                                <DeleteOutlined size={24}/>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                        <div className={'d-flex justify-content-center'}>
                            <Button variant={"outlined"} color="primary"
                                    className={'file-clear-btn'}
                                    onClick={() => {
                                        setOpenResumeParserModal(false)
                                    }}
                            >
                                &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                            </Button>
                            <Button
                                disabled={fileList?.length === 0}
                                onClick={()=>parsePdf(fileList[0])}
                                className={'file-save-btn'}
                                color={"primary"}>
                                Parse
                            </Button>
                        </div>
                    </div> :
                    <div>
                        <div className={'resume-parser-loading'}>Analyzing the Resume, Please wait...</div>
                        <div>
                            <Lottie width={250} height={250} speed={1} options={defaultOptions}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ResumeParserComponent