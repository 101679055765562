import "./ChipInputComponent.scss";
import { Input, Tag,Tooltip} from 'antd';
import {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../helpers";
import DrawerComponent from "../drawer/DrawerComponent";

interface ChipInputComponentProps{
    placeholder?:string;
    selectedEmails?:string[];
    manualEmails?:string[];
    setSelectedEmails?:any;
    setManualEmails?:any;
    title?:string;
    showCC?:any;
    showBCC?:any;
    handleCCToggle?:()=>void;
    handleBCCToggle?:()=>void;
    selectedRoleEmails?:string[];
    setSelectedRoleEmails?:any;
    selectedStaffEmails?:string[];
    setSelectedStaffEmails?:any;
    isEditable?:boolean;
}

const ChipInputComponent=(props:ChipInputComponentProps)=>{
    const {selectedEmails ,
        manualEmails ,
        setSelectedEmails,
        setManualEmails,
        placeholder,
        title,
        showCC,
        showBCC,
        selectedStaffEmails,
        selectedRoleEmails,
        setSelectedRoleEmails,
        setSelectedStaffEmails,
        isEditable=true,
        handleCCToggle,
        handleBCCToggle}=props;
    const [inputValue, setInputValue] = useState('');
    const [allEmails,setAllEmails]=useState<any>([]);
    const [openDrawer, setOpenDrawer]=useState<boolean>(false);

    const handleInputChange = (e:any) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = useCallback((e:any) => {
        if (e.key === 'Enter' && inputValue.trim()) {

            //check the value is in email format or not?
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(inputValue.trim())) {
                CommonService.showToast("Enter a valid email address",'error')
                return;
            }

            // Check for duplicates in manualEmails and selectedEmails
            const emailAlreadyExists =
                manualEmails?.includes(inputValue.trim()) || selectedEmails?.includes(inputValue.trim());

            if (emailAlreadyExists) {
                CommonService.showToast("This email is already added.",'error')
                return;
            }

            // Add the new email to the manuallyEnteredEmails list
            if(manualEmails){
                const newEmails = [inputValue.trim(),...manualEmails];
                setManualEmails(newEmails);
                setInputValue(''); // Clear the input
            }
        }
    },[inputValue,manualEmails,selectedEmails,setManualEmails])

    const handleTagClose = useCallback((removedEmail:any, isManualEmail:any) => {
        if (isManualEmail) {
            // Remove the email from manuallyEnteredEmails
            const newEmails = manualEmails?.filter((email:any) => email !== removedEmail);
            setManualEmails(newEmails);
        } else {
            // Remove the email from selectedEmails
            const newEmails = selectedEmails?.filter((email:any) => email !== removedEmail);
            setSelectedEmails(newEmails);
        }

        //clear the emails from dropdown
        if(selectedRoleEmails?.includes(removedEmail)){
            const updatedRoleEmails=selectedRoleEmails?.filter((email:string)=> email !== removedEmail)
            setSelectedRoleEmails(updatedRoleEmails)
        }else if(selectedStaffEmails?.includes(removedEmail)){
            const updatedStaffEmails=selectedStaffEmails?.filter((email:string)=> email !== removedEmail)
            setSelectedStaffEmails(updatedStaffEmails)
        }
    },[manualEmails,selectedEmails,setManualEmails,setSelectedEmails,selectedRoleEmails,selectedStaffEmails,setSelectedRoleEmails,setSelectedStaffEmails])

    // Combine selectedEmails and manualEmails for rendering
    useEffect(() => {
        const combinedEmails = [ ...(manualEmails || []),...(selectedEmails || [])];
        setAllEmails(combinedEmails);
    }, [selectedEmails, manualEmails]);


    // Limit the number of displayed tags to 5
    const maxDisplayedTags = 3;
    const displayedTags = allEmails?.slice(0, maxDisplayedTags);
    const remainingTagsCount = allEmails?.length - maxDisplayedTags;

    return (
        <div className={'chip-input-component'}>
            <DrawerComponent
                isOpen={openDrawer}
                onClose={()=>setOpenDrawer(false)}
                title={title}
               showClose={true}>
                <div style={{
                    display:'flex',
                    flexDirection:'column',
                    gap:'6px',
                    maxHeight:'100vh',
                    overflowY:'scroll'

                }}>
                    {allEmails?.map((email:string,index:number)=>{
                        const isManualEmail = manualEmails?.includes(email)
                        return (
                            <Tag  key={email}
                                  closable
                                  onClose={() => handleTagClose(email, isManualEmail)}
                                  style={{ margin: '2px' ,width:'fit-content' }}
                            >
                                {email}
                            </Tag>
                        )
                    })}
                </div>
            </DrawerComponent>
        <div className={'chip-input-container'}>
            {displayedTags?.length !== 0 && displayedTags?.map((email:string, index:number) => {
                const isManualEmail = selectedEmails ? index >= selectedEmails.length : true;
                return (
                    <Tag
                        key={email}
                        closable={isEditable}
                        onClose={() => handleTagClose(email, isManualEmail)}
                        style={{ margin: '2px' }}
                    >
                        {email}
                    </Tag>
                );
            })}

            {/* Showing +count if its more than 2 tags */}
            {remainingTagsCount > 0 && (
                <Tag style={{ margin: '2px' ,cursor:'pointer'}} onClick={()=>setOpenDrawer(true)}>
                    +{remainingTagsCount}
                </Tag>
            )}
            {isEditable &&
                <Input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputConfirm}
                    placeholder={placeholder}
                    style={{border: 'none', boxShadow: 'none', width: 'auto'}}
                />
            }
            {
                title === 'Recipients' &&
                <div className={'email-cc-container'}>
                    <Tooltip title={showCC? "Close Cc" : "Open Cc"}>
                        <div className={'email-cc'} onClick={handleCCToggle}>
                            Cc
                        </div>
                    </Tooltip>
                    <Tooltip title={showBCC? "Close Bcc" : "Open Bcc"}>
                    <div className={'email-cc'} onClick={handleBCCToggle}>
                        Bcc
                    </div>
                    </Tooltip>
                </div>
            }
        </div>
        </div>
    )
}

export default ChipInputComponent