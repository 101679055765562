import "./PayRateComponent.scss";
import {Checkbox, Divider} from "antd";
import type { ColumnsType } from "antd/es/table";
import React, {useEffect, useMemo} from "react";
import AntTableComponent from "../../../../../../../components/ant-table-component/AntTableComponent";
import {useFormikContext} from "formik";
// import FormikSelectDropdownComponent
//     from "../../../../../ant-component/formik-select-dropdown/FormikSelectDropdownComponent";
import FormikInputComponent from "../../../../../../ant-component/formik-input-component/FormikInputComponent";
import FormLabelComponent from "../../../../../../ant-component/form-label/FormLabelComponent";
import InputComponent from "../../../../../../ant-component/input/InputComponent";

interface PayRateComponentProps{
    setTotalPayRate:any;
    totalPayRate:any;
}

const PayRateComponent=(props:PayRateComponentProps)=>{
    const {totalPayRate,setTotalPayRate}=props;
    const {values,setFieldValue}=useFormikContext<any>();
    const updatedInitialValues=()=>{
        const { pay_rate } = values;
        return [
            {
                id: "shift_rate",
                rateType: "Shift Rate",
                rateMode: pay_rate?.shift_rate?.rate_mode || '',
                value: pay_rate.shift_rate?.rate || '',
                enabled: !!pay_rate?.is_shift_rate,
            },
            {
                id: "holiday_multiplier_rate",
                rateType: "Holiday Multiplier Rate",
                rateMode: pay_rate?.holiday_multiplier_rate?.rate_mode || '',
                value: pay_rate.holiday_multiplier_rate?.rate || '',
                enabled: !!pay_rate?.is_holiday_multiplier_rate,
            },
            {
                id: "ot_pay_rate",
                rateType: "OT Pay Rate",
                rateMode: pay_rate?.ot_pay_rate?.rate_mode || '',
                value: pay_rate.ot_pay_rate?.rate || '',
                enabled: !!pay_rate?.is_ot_pay_rate,
            },
            {
                id: "dt_pay_rate",
                rateType: "DT Pay Rate",
                rateMode: pay_rate?.dt_pay_rate?.rate_mode || '',
                value: pay_rate?.dt_pay_rate?.rate || '',
                enabled: !!pay_rate?.is_dt_pay_rate,
            },
            {
                id: "pm_diff_rate",
                rateType: "PM Pay Rate",
                rateMode: pay_rate?.pm_diff_rate?.rate_mode || '',
                value: pay_rate?.pm_diff_rate?.rate || '',
                enabled: !!pay_rate?.is_pm_diff_rate,
            },
            {
                id: "noc_diff_rate",
                rateType: "NOC Pay Rate",
                rateMode: pay_rate?.noc_diff_rate?.rate_mode || '',
                value: pay_rate.noc_diff_rate?.rate || '',
                enabled: !!pay_rate?.is_noc_diff_rate,
            },
            {
                id: "weekend_rate",
                rateType: "Weekend Pay Rate",
                rateMode: pay_rate?.weekend_rate?.rate_mode || '',
                value: pay_rate.weekend_rate?.rate || '',
                enabled: !!pay_rate?.is_weekend_rate,
            },
            {
                id: "rush_rate",
                rateType: "Rush Pay Rate",
                rateMode: pay_rate?.rush_rate?.rate_mode || '',
                value: pay_rate?.rush_rate?.rate || '',
                enabled: !!pay_rate?.is_rush_rate,
            },
            {
                id: "hazard_rate",
                rateType: "Hazard Pay Rate",
                rateMode: pay_rate?.hazard_rate?.rate_mode || '',
                value: pay_rate?.hazard_rate?.rate || '',
                enabled: !!pay_rate?.is_hazard_rate,
            },
        ]
    }

    useEffect(() => {
        const { pay_rate } = values;
        let total = 0;

        if (pay_rate) {
            const rates = [
                {id: 'shift_rate', rate: pay_rate.shift_rate?.rate || 0, enabled: pay_rate.is_shift_rate},
                {id:'holiday_multiplier_rate',rate:pay_rate.holiday_multiplier_rate?.rate || 0,enabled:pay_rate.is_holiday_multiplier_rate},
                { id: 'ot_pay_rate', rate: pay_rate.ot_pay_rate?.rate || 0, enabled: pay_rate.is_ot_pay_rate },
                { id: 'dt_pay_rate', rate: pay_rate.dt_pay_rate?.rate || 0, enabled: pay_rate.is_dt_pay_rate },
                { id: 'pm_diff_rate', rate: pay_rate.pm_diff_rate?.rate || 0, enabled: pay_rate.is_pm_diff_rate },
                { id: 'noc_diff_rate', rate: pay_rate.noc_diff_rate?.rate || 0, enabled: pay_rate.is_noc_diff_rate },
                { id: 'weekend_rate', rate: pay_rate.weekend_rate?.rate || 0, enabled: pay_rate.is_weekend_rate },
                { id: 'rush_rate', rate: pay_rate.rush_rate?.rate || 0, enabled: pay_rate.is_rush_rate },
                { id: 'hazard_rate', rate: pay_rate.hazard_rate?.rate || 0, enabled: pay_rate.is_hazard_rate },
            ];

            rates.forEach(({ rate, enabled }) => {
                if (enabled) {
                    total += parseFloat(rate) || 0;
                }
            });
        }

        setTotalPayRate(total);
    }, [values,setTotalPayRate]);


    const payRateColumnList: ColumnsType =useMemo(() =>
     [
        {
            title: "",
            dataIndex: "",
            key: "enabled",
            width:50,
            render: (_, record) =>{
                return (
                    <Checkbox
                        style={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                        }}
                        checked={values?.pay_rate?.[`is_${record?.id}`] || false}
                        onChange={(e:any) => {
                            console.log(record)
                            setFieldValue(`pay_rate.is_${record?.id}`, e.target.checked);
                        }}
                    />
                )
            }
        },
        {
            title: "Rate Type",
            dataIndex: "rateType",
            key: "rateType",
        },
        {
            title: "Value",
            dataIndex: "value",
            key: "value",
            width:150,
            render: (_, record) =>{
                return (
                    <FormikInputComponent
                        type={'number'}
                        prefix={"$"}
                        disabled={!values?.pay_rate?.[`is_${record?.id}`]}
                        name={`pay_rate.${record.id}.rate`}
                        size={'middle'}
                        onUpdate={(value:any)=>{
                            setFieldValue(`pay_rate.${record.id}.final_rate`,value)
                        }}
                    />
                )
            }
        },
         {
             title: "Rate",
             width:150,
             render: (_, record) => {
                 return (
                     <div>
                         $ {Number(values?.pay_rate?.[`${record?.id}`]?.final_rate)?.toFixed(2) || 0.00}/hr
                     </div>
                 )
             }
         },
    ],[setFieldValue,values]);

    return (
        <div className={'pay-rate-component'}>
            {/*<div className={'mrg-bottom-10'}>*/}
            {/*    <div className={'mrg-top-5 flex-equal'}>*/}
            {/*        <FormLabelComponent Label={"Shift Rate"}/>*/}
            {/*    </div>*/}
            {/*    <div className={'flex-double mrg-top-10 mrg-bottom-20'}>*/}
            {/*        <FormikInputComponent name="pay_rate.shift_rate" type={'number'} size={'middle'}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={'pay-rate-table-wrapper'}>
                <AntTableComponent
                    columns={payRateColumnList}
                    data={updatedInitialValues()}
                    isPaginated={false}
                />
            </div>
            <Divider/>
            <div className={'mrg-bottom-10 mrg-top-10'}>
                <div className={'mrg-top-5 flex-equal'}>
                    <FormLabelComponent Label={"Final Pay Rate"}/>
                </div>
                <div className={'flex-double mrg-top-10 mrg-bottom-20'}>
                    <InputComponent
                        prefix={"$"}
                        value={Number(totalPayRate)?.toFixed(2)} type={'number'} disabled={true}/>
                </div>
            </div>
        </div>
    )
}

export default PayRateComponent