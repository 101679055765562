import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import LoaderComponent from "../../../../../components/loader/LoaderComponent";
import {CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import "./JobActivityLogsComponent.scss";
import ActivityLogComponent from "../../../../../components/activity-log/ActivityLogComponent";

const JobActivityLogsComponent = () => {
    const params = useParams<{ id: string }>();
    const { id } = params;
    const [isActivityLogLoading, setIsActivityLogLoading] = useState<boolean>(false);
    const [activityLogData, setActivityLogData] = useState<any>([]);

    const getActivityLog = useCallback(() => {
        setIsActivityLogLoading(true);
        CommonService._api
            .get(ENV.API_URL + `activitylog/${id}`)
            .then((response:any) => {
                setActivityLogData(response?.data || []);
            })
            .catch((error:any) => console.log(error))
            .finally(() => setIsActivityLogLoading(false));
    }, [id]);

    useEffect(() => {
        getActivityLog();
    }, [getActivityLog]);

    return (
        <div className="job-activity-log-component">
            {isActivityLogLoading ? (
                <div className="basic-loading-wrapper">
                    <LoaderComponent color="primary" size="sm" type="spinner" />
                </div>
            ) : (
              <div>
                  {Object.entries(activityLogData)?.length === 0 ? (
                      <div className="basic-loading-wrapper">
                          No Activity Logs Found for this Job
                      </div>)
                      :
                  <ActivityLogComponent logsData={activityLogData} />
                  }
              </div>
            )}
        </div>
    );
};

export default JobActivityLogsComponent;
