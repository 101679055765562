import { Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useCallback, useEffect, useState } from 'react';
import ENV from '../../../constants/ENV';
import LoaderComponent from '../../../components/loader/LoaderComponent';
import { ApiService, CommonService } from '../../../helpers';
import './NotificationComponent.scss';
import { Divider } from 'antd';
import InputComponent from '../../ant-component/input/InputComponent';

const NotificationComponent = () => {
  // const [notificationId,setNotificationId]=useState('')
  const [isLoadingNotification, setIsLoadingNotifications] = useState(false);
  const [selectedDayForJob, setSelectedDayForJob] = useState<string>('');
  const [selectedDayForStaff, setSelectedDayForStaff] = useState<string>('');
  const [selectedEndTimeForJob,setSelectedEndTimeForJob]=useState<string>('');
  const [selectedEndTimeForStaff,setSelectedEndTimeForStaff]=useState<string>('');
  const [selectedAlreadyEndTimeForJob,setSelectedAlreadyEndTimeForJob]=useState<any>('0');
  const [selectedAlreadyEndTimeForStaff,setSelectedAlreadyEndTimeForStaff]=useState<any>('0');
  const [isSubmittingNotifications, setIsSubmittingNotifications] = useState(false);

  const [notifications, setNotifications] = useState({
    sms: false,
    email: false,
    push_notification: false,
    facility_sms: false,
    facility_email: false,
    facility_push_notification: false,
    employee_sms: false,
    employee_email: false,
    // employee_push_notification: false,
  });

  const getNotifications = useCallback(() => {
    setIsLoadingNotifications(true);
    ApiService.get(ENV.API_URL + 'notification/preference')
      .then((res) => {
        const { sms, email, push_notification } = res?.data;
        setNotifications({
          sms,
          email,
          push_notification,
          facility_sms: res?.data?.facility_sms || false,
          facility_email: res?.data?.facility_email || false,
          facility_push_notification: res?.data?.facility_push_notification || false,
          employee_sms: res?.data?.employee_sms || false,
          employee_email: res?.data?.employee_email || false,
          // employee_push_notification: res?.data?.employee_push_notification || false,
        });
        setSelectedDayForJob(res?.data?.cron_job_days || '');
        setSelectedDayForStaff(res?.data?.cron_hcps_days || '');
        setSelectedEndTimeForJob(res?.data?.no_of_triggers_job_days || '');
        setSelectedEndTimeForStaff(res?.data?.no_of_triggers_for_hcps || '');
        setSelectedAlreadyEndTimeForJob(res?.data?.already_triggers_job_days || '0');
        setSelectedAlreadyEndTimeForStaff(res?.data?.already_triggers_for_hcps || '0');

        setIsLoadingNotifications(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingNotifications(false);
      });
  }, []);

  const editNotifications = useCallback(() => {
    setIsSubmittingNotifications(true);
    const changeJobTriggerTime = selectedAlreadyEndTimeForJob !== selectedEndTimeForJob;
    const changeStaffTriggerTime = selectedAlreadyEndTimeForStaff !== selectedEndTimeForStaff;
    const payload = {
      sms: notifications.sms,
      push_notification: notifications.push_notification,
      email: notifications.email,
      facility_sms: notifications.facility_sms,
      facility_email: notifications.facility_email,
      facility_push_notification: notifications.facility_push_notification,
      employee_sms: notifications.employee_sms,
      employee_email: notifications.employee_email,
      cron_job_days: selectedDayForJob,
      cron_hcps_days: selectedDayForStaff,
      no_of_triggers_job_days:selectedEndTimeForJob,
      no_of_triggers_for_hcps:selectedEndTimeForStaff,
      already_triggers_job_days: changeJobTriggerTime ? '0' : selectedAlreadyEndTimeForJob,
      already_triggers_for_hcps: changeStaffTriggerTime ? '0' : selectedAlreadyEndTimeForStaff,
      // employee_push_notification:notifications.employee_push_notification
    };

    ApiService.put(ENV.API_URL + `notification/preference`, payload)
      .then((res) => {
        const {
          sms,
          email,
          push_notification,
          facility_sms,
          facility_email,
          facility_push_notification,
          employee_sms,
          employee_email,
        } = notifications;
        setNotifications({
          sms,
          email,
          push_notification,
          facility_sms,
          facility_email,
          facility_push_notification,
          employee_sms,
          employee_email,
          // employee_push_notification
        });
        setSelectedDayForJob(res?.data?.cron_job_days);
        setSelectedDayForStaff(res?.data?.cron_hcps_days);
        setSelectedEndTimeForJob(res?.data?.no_of_triggers_job_days);
        setSelectedEndTimeForStaff(res?.data?.no_of_triggers_for_hcps);
        setSelectedAlreadyEndTimeForJob(res?.data?.already_triggers_job_days || '0');
        setSelectedAlreadyEndTimeForStaff(res?.data?.already_triggers_for_hcps || '0');

        CommonService.showToast(res?.msg || 'Success', 'success');

        setIsSubmittingNotifications(false);
      })
      .catch((err) => {
        console.log(err);
        CommonService.showToast(err?.msg || 'Error', 'error');
        setIsSubmittingNotifications(false);
      });
  }, [selectedDayForJob, selectedDayForStaff, notifications,selectedEndTimeForStaff,selectedEndTimeForJob,selectedAlreadyEndTimeForJob,selectedAlreadyEndTimeForStaff]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const handleSubmit = () => {
    editNotifications();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotifications({
      ...notifications,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className='notification-component'>
      {isLoadingNotification ? (
        <div className={'basic-loading-wrapper'}>
          <LoaderComponent color={'primary'} type={'spinner'} size={'sm'} />
        </div>
      ) : (
        <>
          <div className='notification-preferences mrg-top-10'>
            <div className='notification-group'>Notification Preferences for Staff</div>
            <div className='notification-preferences-container'>
              <div className='form-control'>
                <Checkbox checked={notifications.sms} onChange={handleChange} name='sms' />
                <span className='form-label'>SMS</span>
              </div>

              <div className='form-control'>
                <Checkbox checked={notifications.email} onChange={handleChange} name='email' />
                <span className='form-label'>Email</span>
              </div>

              <div className='form-control'>
                <Checkbox checked={notifications.push_notification} onChange={handleChange} name='push_notification' />
                <span className='form-label'>Push Notification</span>
              </div>
            </div>
          </div>
          <Divider />
          <div className='notification-preferences mrg-top-10'>
            <div className='notification-group'>Notification Preferences for Facility</div>
            <div className='notification-preferences-container'>
              <div className='form-control'>
                <Checkbox checked={notifications.facility_sms} onChange={handleChange} name='facility_sms' />
                <span className='form-label'>SMS</span>
              </div>

              <div className='form-control'>
                <Checkbox checked={notifications.facility_email} onChange={handleChange} name='facility_email' />
                <span className='form-label'>Email</span>
              </div>

              <div className='form-control'>
                <Checkbox
                  checked={notifications.facility_push_notification}
                  onChange={handleChange}
                  name='facility_push_notification'
                />
                <span className='form-label'>Push Notification</span>
              </div>
            </div>
          </div>
          <Divider />
          <div className='notification-preferences mrg-top-10'>
            <div className='notification-group'>Notification Preferences for Team Members</div>
            <div className='notification-preferences-container'>
              <div className='form-control'>
                <Checkbox checked={notifications.employee_sms} onChange={handleChange} name='employee_sms' />
                <span className='form-label'>SMS</span>
              </div>

              <div className='form-control'>
                <Checkbox checked={notifications.employee_email} onChange={handleChange} name='employee_email' />
                <span className='form-label'>Email</span>
              </div>

              {/*<div className="form-control">*/}
              {/*    <Checkbox*/}
              {/*        checked={notifications.employee_push_notification}*/}
              {/*        onChange={handleChange}*/}
              {/*        name="employee_push_notification"*/}
              {/*    />*/}
              {/*    <span className="form-label">Push Notification</span>*/}
              {/*</div>*/}
            </div>
          </div>
          <Divider />
          <div className='notification-preferences mrg-top-10'>
            <div className={'notification-input-wrapper'}>
              <div className='notification-group'>Email Reminder for Job Documents</div>
                <div className='notification-preferences-container'>
                    <div className={'d-flex align-items-center'}>
                        <div style={{color:'black',marginRight:'10px'}}>Send a reminder every</div>
                        <InputComponent
                            value={selectedDayForJob}
                            maxLength={2}
                            className={'notification-ant-input'}
                            placeholder={'Number of Days'}
                            onChange={(value) => {
                                if (/^\d*$/.test(value)) {
                                    setSelectedDayForJob(value);
                                }
                            }}
                            allowClear={true}
                            type={'number'}
                        />
                        <div style={{color:'black',marginLeft:'10px'}}>days</div>
                    </div>
                    <div className={'d-flex align-items-center'}>
                        <div style={{color: 'black', marginRight: '10px'}}>Stop sending after</div>
                        <InputComponent
                            value={selectedEndTimeForJob}
                            maxLength={2}
                            className={'notification-ant-input'}
                            placeholder={''}
                            onChange={(value) => {
                                if (/^\d*$/.test(value)) {
                                    setSelectedEndTimeForJob(value);
                                }
                            }}
                            allowClear={true}
                            type={'number'}
                        />
                        <div style={{color: 'black', marginLeft: '10px'}}>times</div>
                    </div>
                </div>
            </div>
              <div className={'notification-input-wrapper mrg-top-20'}>
                  <div className='notification-group'>Email Reminder for Staff Documents</div>
                  <div className='notification-preferences-container'>
                      <div className={'d-flex align-items-center'}>
                          <div style={{color: 'black', marginRight: '10px'}}>Send a reminder every</div>
                          <InputComponent
                              value={selectedDayForStaff}
                              maxLength={2}
                              placeholder={'Number of Days'}
                              className={'notification-ant-input'}
                              onChange={(value) => {
                                  if (/^\d*$/.test(value)) {
                                      setSelectedDayForStaff(value);
                                  }
                              }}
                              allowClear={true}
                              type={'number'}
                          />
                          <div style={{color: 'black', marginLeft: '10px'}}>days</div>
                      </div>
                      <div className={'d-flex align-items-center'}>
                          <div style={{color: 'black', marginRight: '10px'}}>Stop sending after</div>
                          <InputComponent
                              value={selectedEndTimeForStaff}
                              maxLength={2}
                              className={'notification-ant-input'}
                              placeholder={''}
                              onChange={(value) => {
                                  if (/^\d*$/.test(value)) {
                                      setSelectedEndTimeForStaff(value);
                                  }
                              }}
                              allowClear={true}
                              type={'number'}
                          />
                          <div style={{color: 'black', marginLeft: '10px'}}>times</div>
                      </div>
                  </div>
              </div>
          </div>
            <div className='flex-1 mrg-top-40' style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                    color={'primary'}
                    disabled={isSubmittingNotifications}
                    variant={'contained'}
                    className={isSubmittingNotifications ? 'mrg-left-30 has-loading-spinner' : 'mrg-left-30'}
                    onClick={handleSubmit}
                >
                    {isSubmittingNotifications ? 'Saving' : 'Save'}
                </Button>
            </div>
        </>
      )}
    </div>
  );
};

export default NotificationComponent;
