import "./OpenMenuListComponent.scss";
import {Link} from "react-router-dom";
import {CommonService} from "../../../../helpers";
import {Tooltip} from "antd";
import {useCallback} from "react";


interface OpenMenuListComponentProps{
    onMenuItemClick?:(action:string)=>void;
    selectedShifts?:string[];
    checkForDisablingHcpButton?:any;
    checkForDisablingShowHcpRateButton?:any;
    checkForDisablingCancelButton?:any;
    filterData?:any
}


const OpenMenuListComponent=(props:OpenMenuListComponentProps)=>{
    const {onMenuItemClick,
        selectedShifts,
        checkForDisablingHcpButton,
        checkForDisablingShowHcpRateButton,
        checkForDisablingCancelButton,
        filterData
    }=props;

    const MenuItems=useCallback(()=>{
        return [
            {
                label:"Bulk Upload",
                id:'bulk_upload',
                link:'/open-shift/BulkUpload',
                action:'navigate',
            },
            {
                label:'Add Staff',
                id:'add_staff',
                action:'add_staff',
                tooltip:checkForDisablingHcpButton() ? "Please select Staff Type and Requirement type filters to Add Staff." : "Add Staff to Shifts.",
                disabled: checkForDisablingHcpButton()
            },
            {
                label:'Cancel Shift',
                id:'cancel_shift',
                action:'cancel_shift',
                tooltip: 'Please select shift to cancel.',
                disabled:checkForDisablingCancelButton()
            },
            {
                label:'Show Shift rate on mobile',
                id:'shift_rate_on_mobile',
                action:'shift_rate_on_mobile',
                tooltip:'Select Requirement type filter and shift to update the shift rate.',
                disabled:checkForDisablingShowHcpRateButton() || filterData?.type !== "facility"
            },
        ]
    },[checkForDisablingHcpButton,checkForDisablingShowHcpRateButton,checkForDisablingCancelButton,filterData])

    return (
        <div className={'applicant-menu-list-component'}>
            {MenuItems()?.map((item:any)=>{
                return (
                    <Tooltip title={item?.tooltip || ''} placement={'left'}>
                        <div
                            key={item.id}
                            className={`applicant-menu-item ${item.disabled ? 'menu-item-disabled' : ''}`}
                            onClick={()=>{
                                if(item?.disabled && item?.action !== 'navigate') {
                                    CommonService.showToast(`${item?.tooltip}`,'info')
                                }else if(selectedShifts?.length === 0 && item?.action !== 'navigate'){
                                    CommonService.showToast(`Select Shift to ${item?.label}`,'info')
                                }else{
                                    onMenuItemClick?.(item?.action)
                                }
                            }}
                        >
                            {item?.action === 'navigate' ?
                                <Link
                                    style={{
                                        color:'black',
                                        width:'100%'
                                    }}
                                    to={{
                                        pathname:item.link,
                                        state: {
                                            from: 'applicant-list'
                                        }
                                    }}>
                                    <span>
                                        {item.label}
                                    </span>
                                </Link>
                                :
                                <div>
                                    <span>
                                        {item.label}
                                    </span>
                                </div>
                            }
                        </div>
                    </Tooltip>
                )
            })}
        </div>
    )
}

export default OpenMenuListComponent