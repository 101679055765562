import React, {useCallback, useEffect, useState} from 'react';
import './ActivityLogComponent.scss';
import { Timeline, Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import {ApiService, CommonService} from '../../helpers';
import DataLabelValueComponent from "../data-label-value/DataLabelValueComponent";
import {ENV} from "../../constants";

const { Panel } = Collapse;

interface ActivityLogComponentProps {
    logsData: any;
}

const ActivityLogComponent = (props: ActivityLogComponentProps) => {
    const { logsData } = props;
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const [isFacilityListLoading,setIsFacilityListLoading]=useState<boolean>(false);
    const [recruiterList, setRecruiterList] = useState<any | null>(null);
    const [isRecruiterListLoading,setIsRecruiterListLoading]=useState<boolean>(false);

    const getFacilityData = useCallback(() => {
        let payload: any = {};
        setIsFacilityListLoading(true)
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                console.log(res?.data);
                //@ts-ignore
                const transformedData = res?.data?.map(({_id, name, ...rest}) => ({
                    code: _id,
                    title: name,
                    ...rest, // Include other properties if needed
                }));
                setFacilityList(transformedData || []);
            })
            .catch((err) => {
                console.log(err);
            }).finally(()=>{
            setIsFacilityListLoading(false)
        })
    }, []);

    useEffect(() => {
        getFacilityData()
    }, [getFacilityData]);

    const getRecruiterList=useCallback(()=>{
        setIsRecruiterListLoading(true);
        CommonService._api.get(ENV.API_URL + `user/lite?is_active=true&roles[]=recruiter`).then((response:any)=>{
            const transformedData = response?.data?.map((item:any) => ({
                code: item?._id,
                title: item?.first_name + " " + item?.last_name,
            }));
            setRecruiterList(transformedData || []);
        }).catch((error:any)=>{
            console.log(error)
        }).finally(()=>{
            setIsRecruiterListLoading(false);
        })
    },[])

    useEffect(() => {
        getRecruiterList()
    }, [getRecruiterList]);


    const getLogsStringWithArrows = (log: any) => {
        let str = log?.module_name && CommonService.formatTitleCase(log?.module_name) + '$';
        if (log?.field_name) {
            const finalFieldName= log?.field_name === 'facility_id' ? 'Facility Name'
                : log?.field_name === 'recruiter_id' ? "Recruiter Name" :  log?.field_name === 'hcp_type' ? "Staff Type" : log?.field_name === 'hcp_uniqueId' ? "Staff Unique Id": log?.field_name;
            const fieldParts = finalFieldName.split('.');
            const formattedFieldParts = fieldParts.map((part: string) => CommonService.formatTitleCase(part));
            str += formattedFieldParts.join(' > ') + '$';
        }

        // Remove the trailing '$' if it exists
        str?.charAt(str?.length - 1) === '$' && (str = str?.slice(0, -1));

        // Replace all '$' with ' > '
        str = str?.replace(/\$+/g, ' > ');

        return str;
    };

    const generateObjectsContent = useCallback((item: any) => {
        if (item === null || item === undefined) {
            return 'N/A';
        }

        if (typeof item === 'string' || typeof item === 'number') {
            return <span className={'activity-log-value'}>{CommonService.formatTitleCase(String(item)) || 'N/A'}</span>;
        }

        if (typeof item === 'boolean') {
            return <span className={'activity-log-value'}>{(item ? 'Yes': 'No') || 'N/A'}</span>;
        }

        if (Array.isArray(item)) {
            return (
                <div className={'activity-log-array-wrapper'}>
                    {item.map((element, index) => (
                        <div key={index} className={'activity-log-array-element'}>
                            {index + 1}. {generateObjectsContent(element) || "N/A"}
                        </div>
                    ))}
                </div>
            );
        }

        if (typeof item === 'object') {
            return (
                <div className={'activity-log-object-wrapper'}>
                    {Object.keys(item).map((key: string, index: number) => (
                        <div key={index} className={'activity-log-key-value-wrapper'}>
                            <span className={'activity-log-key'}>{CommonService.formatTitleCase(key)}: </span>
                            <span className={'activity-log-value'}>{generateObjectsContent(item[key]) || 'N/A'}</span>
                        </div>
                    ))}
                </div>
            );
        }

        return 'N/A';
    }, []);

    const generateAccordionContent=useCallback((log:any)=>{
        const field_name=CommonService.formatTitleCase(log?.field_name);
        console.log(field_name);
        if(field_name === 'Start Time' || field_name === 'End Time' ||
            field_name === 'Contract Details.start Time'
            || field_name === 'Contract Details.end Time'
            || field_name === 'Per Diem Details.start Time'
            || field_name === 'Per Diem Details.end Time'
        ){
            return (
                <div>
                    <DataLabelValueComponent label={"From"}>
                        {log?.old_value ? CommonService.convertMinsToHrsMins(log?.old_value) : 'N/A'}
                    </DataLabelValueComponent>
                    <DataLabelValueComponent label={"To"}>
                        {log?.updated_value ? CommonService.convertMinsToHrsMins(log?.updated_value) : 'N/A'}
                    </DataLabelValueComponent>
                </div>
            )
        }else if(field_name === 'Facility Id'){
            const oldFacility=facilityList?.find((facility:any)=> facility?.code===log?.old_value) || 'N/A';
            const updatedFacility=facilityList?.find((facility:any)=> facility?.code===log?.updated_value) || 'N/A';
            return (
                <div>
                    {isFacilityListLoading ?
                        <>Loading...</>:
                        <>
                            <DataLabelValueComponent label={"From"}>
                                {log?.old_value ? oldFacility?.title : 'N/A'}
                            </DataLabelValueComponent>
                            <DataLabelValueComponent label={"To"}>
                                {log?.updated_value ? updatedFacility?.title : 'N/A'}
                            </DataLabelValueComponent>
                        </>
                    }

                </div>
            )
        }else if(field_name === 'Recruiter Id'){
            const oldRecruiter=recruiterList?.find((recruiter:any)=> recruiter?.code===log?.old_value) || 'N/A';
            const updatedRecruiter=recruiterList?.find((recruiter:any)=> recruiter?.code===log?.updated_value) || 'N/A';
            return (
                <div>
                    {isRecruiterListLoading ?
                        <>Loading...</>:
                        <>
                            <DataLabelValueComponent label={"From"}>
                                {log?.old_value ? oldRecruiter?.title : 'N/A'}
                            </DataLabelValueComponent>
                            <DataLabelValueComponent label={"To"}>
                                {log?.updated_value ? updatedRecruiter?.title : 'N/A'}
                            </DataLabelValueComponent>
                        </>
                    }

                </div>
            )
        }else if(field_name  === 'Job Start Date' ||
            field_name === 'Job End Date' ||
            field_name === 'Contract Details.start Date'
            || field_name === 'Contract Details.end Date'
            || field_name === 'Per Diem Details.start Date'
            || field_name === 'Per Diem Details.end Date'
            || field_name === 'Dob'){
            return (
                <div>
                    <DataLabelValueComponent label={"From"}>
                        {log?.old_value ? CommonService.getUtcDate(log?.old_value) : 'N/A'}
                    </DataLabelValueComponent>
                    <DataLabelValueComponent label={"To"}>
                        {log?.updated_value ? CommonService.getUtcDate(log?.updated_value) : 'N/A'}
                    </DataLabelValueComponent>
                </div>
            )
        }else if(field_name === 'Job Description' || field_name === 'Job Benefits' || field_name === 'Job Requirements'){
            return (
                <div>
                    <DataLabelValueComponent label={"From"}>
                        {log?.old_value ?
                            <div dangerouslySetInnerHTML={{__html:log?.old_value}}></div>
                            : 'N/A'}
                    </DataLabelValueComponent>
                    <DataLabelValueComponent label={"To"}>
                        {log?.updated_value ?
                            <div dangerouslySetInnerHTML={{__html:log?.updated_value}}></div>
                            : 'N/A'}
                    </DataLabelValueComponent>
                </div>
            )
        } else {
            return (
                <div>
                    <DataLabelValueComponent label={"From"}>
                        {log?.old_value !== null && log?.old_value !== undefined
                            ? generateObjectsContent(log?.old_value)
                            : 'N/A'}
                    </DataLabelValueComponent>
                    <DataLabelValueComponent label={"To"}>
                        {log?.updated_value !== null && log?.updated_value !== undefined
                            ? generateObjectsContent(log?.updated_value)
                            : 'N/A'}
                    </DataLabelValueComponent>
                </div>
            )
        }
    },[generateObjectsContent,isFacilityListLoading,facilityList,recruiterList,isRecruiterListLoading])

    return (
        <div className={'mrg-top-25'}>
            <Timeline>
                {logsData && Object.keys(logsData)?.length > 0 ? (
                    Object.keys(logsData)?.map((date, index) => (
                        <Timeline.Item key={index}>
                            <div className="log-item">
                                <div className="log-item-header">
                                    {date && moment(date).format('DD-MMM-YYYY')}
                                </div>
                                <div className="log-item-body">
                                    {logsData[date]?.map((log: any, logIndex: number) => {
                                        return (
                                            <div className="log-item" key={logIndex}>
                                                <Collapse
                                                    bordered={true}
                                                    className={'log-accordion'}
                                                    expandIcon={({isActive}) => (
                                                        <CaretRightOutlined rotate={isActive ? 90 : 0}/>
                                                    )}
                                                >
                                                    <Panel
                                                        header={
                                                            <div className={'activity-header-wrapper'}>
                                                                <div className={'d-flex align-items-center'}>
                                                                    <div className={'log-strings-wrapper'}>
                                                                        {getLogsStringWithArrows(log)}
                                                                    </div>
                                                                    <span
                                                                        className={'log-updated-by d-flex align-items-center'}>
                                                                    was {log?.action?.toLowerCase()} by{' '}
                                                                        <span className={'log-updated-name'}>
                                                                        {log?.updated_by ? log?.updated_by?.name : ''}
                                                                    </span>
                                                                </span>
                                                                </div>
                                                                <div className="log-status-wrapper">
                                                                    <div className="updated-date">
                                                                        {(moment(log?.updated_at).format('hh:mm A'))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        key={logIndex}
                                                    >
                                                        <div>
                                                            {generateAccordionContent(log)}
                                                        </div>
                                                    </Panel>
                                                </Collapse>
                                            </div>
                                        )})}
                                </div>
                            </div>
                        </Timeline.Item>
                    ))
                ) : (
                    <Timeline.Item>No activity logs present</Timeline.Item>
                )}
            </Timeline>
        </div>
    );
};

export default ActivityLogComponent;