import React, { PropsWithChildren } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

export interface LeavePageConfirmationComponentProps {
    confirmationText?: string; // Text to display in the modal
    cancel: () => void;       // Cancel action handler
    confirm: () => void;      // Confirm action handler
    notext?: string;          // Text for the "Leave" button
    yestext?: string;         // Text for the "Cancel" button
    visible?:boolean;
    title?:string;
}

const LeavePageConfirmationComponent = (props: PropsWithChildren<LeavePageConfirmationComponentProps>) => {
    return (
        <Modal
            title={
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <ExclamationCircleOutlined style={{ color: '#faad14', fontSize: '20px' }} />
                    <span style={{ color: '#1e1e1e', display: 'block' }}>{props.title || 'Leave Page?'}</span>
                </div>
            }
            open={props.visible}
            footer={null}
            centered
            onCancel={props.cancel}
            closable={false}
            zIndex={99999}
        >
            {/* Default confirmation text or user-provided */}
            <p
                style={{
                    fontSize:'16px',
                    fontWeight:500,
                }}
            >{props.confirmationText || 'Changes you made might not be saved'}</p>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
                <Button
                    onClick={props.cancel}
                    type="default"
                >
                    {props.notext || 'Leave'}
                </Button>
                <Button
                    onClick={props.confirm}
                    type="primary"
                    style={{background:'#10c4d3'}}
                >
                    {props.yestext || 'Cancel'}
                </Button>
            </div>
        </Modal>
    );
};

export default LeavePageConfirmationComponent;
