import {Button, Tooltip} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import AccessControlComponent from "../../../components/AccessControl";
import LoaderComponent from "../../../components/LoaderComponent";
import {Communications} from "../../../helpers";
import {
    ACCOUNTMANAGER,
    ADMIN,
    FINANCE,
    HUMANRESOURCE,
    NURSECHAMPION,
     TERICADMIN,RECRUITER,HRMANAGER
} from "../../../helpers/common-service";
import HcpDetailsComponent from "./details/HcpDetailsComponent";
import "./HcpManagementViewScreen.scss";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import HcpNoteComponent from "./hcp-note/HcpNoteComponent";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {fetchHcpBasicDetailsRequest} from "../../../store/actions/hcp-management.action";
import HcpAttendanceComponent from "./hcp-attendance-panel/HcpAttendanceComponent";
import RequiredDocumentListComponent from "../../ats/Job/Job-list/required-document/RequiredDocumentListComponent";

// import {ENV} from "../../../constants";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const HcpManagementViewScreen = (props: any) => {
    const dispatch = useDispatch();
    const {hcpBasicDetails, isHcpBasicDetailsLoading} = useSelector((state: StateParams) => state.hcpManagement);
    const param = useParams<any>();
    const {id} = param;
    // const history=useHistory();
    // const [isRejectedHcpMoved, setIsRejectedHcpMoved] = useState<boolean>(false);
    const [isAttachmentsLoading, setIsAttachmentsLoading] = useState<boolean>(true);
    // const {user} = useSelector((state: StateParams) => state.auth);
    const [value, setValue] = useState<any>(0);

    const handleChanges = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(fetchHcpBasicDetailsRequest(id));
    }, [dispatch, id]);


    useEffect(() => {
        let prevLocation: any = "/hcp/user/list";
         if(props?.location?.state?.from === 'job-view-submissions'){
            prevLocation= '/jobs/view/' + props?.location?.state?.job_id + `${props?.location?.state?.currentTab}`;
        } else if(props?.location.state) {
            prevLocation = props?.location.state?.prevPath;
        }
        // init();
        Communications.pageTitleSubject.next("Staff Details");
        Communications.pageBackButtonSubject.next(prevLocation);
    }, [props?.location.state]);

// will integrate when backend updated
//    const moveToPending=useCallback(()=>{
//        setIsRejectedHcpMoved(true);
//        CommonService._api
//            .patch(ENV.API_URL + `hcp/${id}/moveToPending`)
//            .then((resp) => {
//                CommonService.showToast(resp?.message || "Success", "success");
//                setIsRejectedHcpMoved(false);
//                history.push("/hcp/user/list")
//            })
//            .catch((err) => {
//                CommonService.showToast(err?.error || "Error", "error");
//                setIsRejectedHcpMoved(false);
//                history.push("/hcp/user/list")
//            });
//    },[id,history])

    useEffect(() => {
        console.log(isAttachmentsLoading);
    }, [isAttachmentsLoading]);

    if (isHcpBasicDetailsLoading
        //&& isAttachmentsLoading
    ) {
        return <LoaderComponent/>;
    }

    return (
        <Box sx={{width: '100%'}}>
            <Box style={{borderBottom: "1px solid #e4e9ea"}}>
                <Tabs value={value} onChange={(e: any, value: any) => {
                    handleChanges(e, value);
                }
                } aria-label="tab"
                      TabIndicatorProps={{
                          style: {
                              backgroundColor: "#56ebff",
                          }
                      }}
                      style={{
                          color: "black",
                      }}>
                    <Tab label="About" {...a11yProps(0)} />
                    {/*disabled={user?.role === "hr" || user?.role === "operational_manager"}*/}
                    <Tab label="Notes" {...a11yProps(1)} />
                    <Tab label="Attendance" {...a11yProps(2)} />
                    <Tab label="Documents" {...a11yProps(3)}/>
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div className="staff-approved-component">
                    {!isHcpBasicDetailsLoading && (
                        <>
                            <div className="">
                                <div className="d-flex profile-status-wrapper"
                                     style={{display: "flex", justifyContent: "right"}}>
                                    <AccessControlComponent
                                        role={[ADMIN, HUMANRESOURCE, NURSECHAMPION, ACCOUNTMANAGER, FINANCE,TERICADMIN,RECRUITER,HRMANAGER]}>
                                        <div>
                                            <Tooltip title="Edit Staff">
                                                <Button variant={"contained"} color={"primary"} component={Link}
                                                        to={`/hcp/edit/${hcpBasicDetails?._id}`}>
                                                    Edit Staff
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </AccessControlComponent>
                                </div>
                                <div className="mrg-top-20">
                                    <HcpDetailsComponent setIsAttachmentsLoading={setIsAttachmentsLoading}/>
                                </div>
                            </div>
                        </>
                    )}
                    <ScrollToTop smooth color="white"/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <HcpNoteComponent id={id} hcpBasicDetails={hcpBasicDetails}/>
            </TabPanel>
            <TabPanel index={2} value={value}>
                <HcpAttendanceComponent hcpBasicDetails={hcpBasicDetails}/>
            </TabPanel>
            <TabPanel index={3} value={value}>
                <div className={'required-documents-staff-wrapper'}>
                    <RequiredDocumentListComponent module={'hcp'} hcpId={id}/>
                </div>
            </TabPanel>
        </Box>
    );
};

export default HcpManagementViewScreen;
