import { useFormikContext } from 'formik';
import "./BillRateComponent.scss";
import {useEffect, useCallback, useMemo, useState} from 'react';
import {Table, TableColumnsType, Tooltip} from 'antd';
import FormikInputComponent from '../../../../../../ant-component/formik-input-component/FormikInputComponent';
import { InfoCircleOutlined } from "@ant-design/icons";

interface BillRateComponentProps {
    apiDataAfterAdd: any;

}

const tableData = [
    { value: 'bill_rate_per_hour', label: 'Bill Rate Per Hour' },
    { value: 'daily_lodging_gsa', label: 'Daily Lodging - Daily (GSA)' },
    { value: 'mi_daily', label: 'M&I Daily' },
    { value: 'guaranteed_hours_weekly', label: 'Guaranteed Hours-Weekly' },
    { value: 'vms_fee_bill_rate', label: 'VMS Fee of Bill Rate' },
    { value: 'state_burden_taxes', label: 'State Burden-State Taxes' },
    { value: 'gross_hourly_pay_rate', label: 'Gross Hourly Pay Rate(All Inclusive)' },
    { value: 'total_daily_stipend', label: 'Total Daily Stipend' },
    { value: 'weekly_stipend', label: 'Weekly Stipend' },
    { value: 'hourly_stipend', label: 'Hourly Stipend' },
    { value: 'tax_free_hourly', label: 'Tax Free' },
    { value: 'taxable_hourly_income', label: 'Taxable Hourly Income' },
    { value: 'total_hourly', label: 'Total Hourly' },
    { value: 'w2_hourly_rate', label: 'W2 Rate-Hourly' },
    { value: 'taxable_weekly_income', label: 'Taxable Weekly Income' },
    { value: 'tax_free_weekly_income', label: 'Tax Free Weekly Income' },
    { value: 'gross_total_weekly', label: 'Gross Total Weekly' },
    { value: 'ot_hourly_rate', label: 'OT Rate' },
];

const BillRateComponent = (props: BillRateComponentProps) => {
    const { values, setFieldValue } = useFormikContext<any>();
    const { apiDataAfterAdd } = props;
    const [manualEdits, setManualEdits] = useState<Record<string, boolean>>({});

    const handleInputChange = useCallback(
        (value: any, field: string) => {
            setFieldValue(`bill_rate.${field}`, value ? parseFloat(value) : '');
            setManualEdits((prev) => ({ ...prev, [field]: true })); // Mark field as manually edited
        },
        [setFieldValue]
    );

    useEffect(() => {

        const billRate = values?.bill_rate;

        const totalDailyStipend = (Number(billRate?.daily_lodging_gsa) || 0) + (Number(billRate?.mi_daily) || 0);
        if (!manualEdits['total_daily_stipend']) {
            setFieldValue('bill_rate.total_daily_stipend', String(totalDailyStipend));
        }

        const weeklyStipend = (Number(billRate?.total_daily_stipend) || 0) * (Number(apiDataAfterAdd?.contract_details?.days_per_week) || 0);
        if (!manualEdits['weekly_stipend']) {
            setFieldValue('bill_rate.weekly_stipend', String(weeklyStipend));
        }

        if (Number(billRate?.guaranteed_hours_weekly) > 0) {
            const hourlyStipend = (Number(billRate?.weekly_stipend) || 0) / Number(billRate?.guaranteed_hours_weekly);
            if (!manualEdits['hourly_stipend']) {
                setFieldValue('bill_rate.hourly_stipend', String(hourlyStipend));
            }
        }

        if (!manualEdits['tax_free_hourly']) {
            setFieldValue('bill_rate.tax_free_hourly', String(billRate?.hourly_stipend));
        }

        const taxableHourlyIncome = (Number(billRate?.gross_hourly_pay_rate) || 0) - (Number(billRate?.tax_free_hourly) || 0);
        if (!manualEdits['taxable_hourly_income']) {
            setFieldValue('bill_rate.taxable_hourly_income', String(taxableHourlyIncome));
        }

        const totalHourly = (Number(billRate?.taxable_hourly_income) || 0) + (Number(billRate?.tax_free_hourly) || 0);
        if (!manualEdits['total_hourly']) {
            setFieldValue('bill_rate.total_hourly', String(totalHourly));
        }

        const w2HourlyRate = Number(billRate?.total_hourly) - (Number(billRate?.total_hourly) * (Number(billRate?.state_burden_taxes) || 0));
        if (!manualEdits['w2_hourly_rate']) {
            setFieldValue('bill_rate.w2_hourly_rate', String(w2HourlyRate));
        }

        const taxableWeeklyIncome = (Number(billRate?.taxable_hourly_income) || 0) * (Number(billRate?.guaranteed_hours_weekly) || 0);
        if (!manualEdits['taxable_weekly_income']) {
            setFieldValue('bill_rate.taxable_weekly_income', String(taxableWeeklyIncome));
        }

        const taxFreeWeeklyIncome = (Number(billRate?.tax_free_hourly) || 0) * (Number(billRate?.guaranteed_hours_weekly) || 0);
        if (!manualEdits['tax_free_weekly_income']) {
            setFieldValue('bill_rate.tax_free_weekly_income', String(taxFreeWeeklyIncome));
        }

        const grossTotalWeekly = (Number(billRate?.taxable_weekly_income) || 0) + (Number(billRate?.tax_free_weekly_income) || 0);
        if (!manualEdits['gross_total_weekly']) {
            setFieldValue('bill_rate.gross_total_weekly', String(grossTotalWeekly));
        }

        const otHourlyRate = Number(billRate?.total_hourly) * 1.5;
        if (!manualEdits['ot_hourly_rate']) {
            setFieldValue('bill_rate.ot_hourly_rate', String(otHourlyRate));
        }
    }, [
        setFieldValue,
        values?.bill_rate,
        apiDataAfterAdd?.contract_details?.days_per_week,
        manualEdits,
    ]);

    const billRateColumnList: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Rate Type',
                dataIndex: 'label',
                key: 'label',
            },
            {
                title: 'Rate',
                render: (item: any) => {
                    const doNotDisable=item?.value === 'bill_rate_per_hour' ||
                        item?.value === 'daily_lodging_gsa' ||
                        item?.value === 'mi_daily' ||
                        item?.value === 'gross_hourly_pay_rate' ||
                        item?.value === 'vms_fee_bill_rate' ||
                        item?.value === 'state_burden_taxes' || item?.value === 'guaranteed_hours_weekly'
                    return (
                        <div>
                            <FormikInputComponent
                                name={`bill_rate.${item?.value}`}
                                type={'number'}
                                disabled={!doNotDisable}
                                onUpdate={(value: any) => handleInputChange(value, item.value)}
                            />
                        </div>
                    );
                },
            },
            {
                title: 'Final Rate',
                key: 'finalRate',
                width: '33%',
                render: (item: any) => {
                    return <div>{Number(values?.bill_rate?.[`${item?.value}`])?.toFixed(2) || 0}</div>;
                },
            },
        ];
    }, [values, handleInputChange]);

    return (
        <div className={'bill-rate-component'}>
            <div className={'bill-rate-table-wrapper'}>
                <Table dataSource={tableData?.slice(0,7)} columns={billRateColumnList} pagination={false}/>
            </div>
            <div className={'contract-right-rate-card'}>
                <div className={'company-gross-card'}>
                    <div className={'title'}>Stipend</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Daily Lodging GSA + M&I Daily'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Daily
                        </div>
                        <div>{Number(values?.bill_rate?.total_daily_stipend).toFixed(2) || '-'}</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Weekly Stipend / Guaranteed Hours-Weekly'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Hourly
                        </div>
                        <div>{Number(values?.bill_rate?.hourly_stipend).toFixed(2) || '-'}</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Daily Stipend * No of Days Per Week (From Step 1)'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Weekly</div>
                        <div>{Number(values?.bill_rate?.weekly_stipend).toFixed(2) || '-'}</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Tax Free + Taxable Hourly Income'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Total Hourly</div>
                        <div>{Number(values?.bill_rate?.total_hourly).toFixed(2) || '-'}</div>
                    </div>
                </div>
                <div className={'candidate-gross-card'}>
                    <div className={'title'}>Taxable Amount</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Gross Hourly Pay Rate(All Inclusive) - Hourly Stipend'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Hourly</div>
                        <div>{Number(values?.bill_rate?.taxable_hourly_income).toFixed(2) || '-'}</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Taxable Hourly Income * Guaranteed Hours-Weekly'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Weekly</div>
                        <div>{Number(values?.bill_rate?.taxable_weekly_income).toFixed(2) || '-'}</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Total Hourly - (Total Hourly * State Burden-State Taxes)'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            W2 Rate Hourly</div>
                        <div>{Number(values?.bill_rate?.w2_hourly_rate).toFixed(2) || '-'}</div>
                    </div>
                </div>
                <div className={'gross-rate-card'}>
                    <div className={'title'}>Non Taxable Amount</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Same as Hourly Stipend Rate'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Tax Free</div>
                        <div>{Number(values?.bill_rate?.tax_free_hourly).toFixed(2) || '-'}</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>
                            <Tooltip title={'Tax Free * Guaranteed Hours Weekly'} placement={'top'}>
                                <InfoCircleOutlined/>
                            </Tooltip> {" "}
                            Weekly</div>
                        <div>{Number(values?.bill_rate?.tax_free_weekly_income).toFixed(2) || '-'}</div>
                    </div>
                </div>
                <div className={'gross-total-card'}>
                    <div className={'title'}>
                        <Tooltip title={'Taxable Weekly + No Taxable Weekly'} placement={'top'}>
                            <InfoCircleOutlined/>
                        </Tooltip> {" "}
                        Gross Total Weekly</div>
                    <div>{Number(values?.bill_rate?.gross_total_weekly).toFixed(2) || '-'}</div>
                </div>
                <div className={'guaranteed-week-card'}>
                    <div className={'title'}>
                        <Tooltip title={'Total Hour * 1.5'} placement={'top'}>
                            <InfoCircleOutlined/>
                        </Tooltip> {" "}
                        OT Rate</div>
                    <div>{Number(values?.bill_rate?.ot_hourly_rate).toFixed(2) || '-'}</div>
                </div>
            </div>
        </div>
    );
};

export default BillRateComponent;
