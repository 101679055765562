import React, {useCallback, useEffect, useState,useMemo} from "react";
import DialogComponent from "../../../../components/DialogComponent";
import NoDataToShowCardComponent from "../../../../components/NoDataToShowCardComponent";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import {americanTimeZone} from "../../../../constants/data";
import {Avatar, Button, IconButton} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {Link, useParams} from "react-router-dom";
import AccessControlComponent from "../../../../components/AccessControl";
import {ACCOUNTMANAGER, ADMIN, FINANCE, HUMANRESOURCE, NURSECHAMPION,TERICADMIN,RECRUITER,HRMANAGER} from "../../../../helpers/common-service";
import {PdfIcon} from "../../../../constants/ImageConfig";
import {CommonService} from "../../../../helpers";
import {ENV} from "../../../../constants";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {TableColumnsType} from "antd";
import GetAppIcon from "@material-ui/icons/GetApp";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


const FacilityBasicDetailsComponent = (props: any) => {
    const classes = useStyles();
    const facilityDetails = props?.facilityDetails;
    const init = props?.init;
    const [timeZone, setTimeZone] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [openContract, setOpenContract] = useState<boolean>(false);
    const params = useParams<{ id: string }>();
    const {id} = params;
    const [isDownload, setIsDownloading] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [documentFileUpload,setDocumentFileUpload]=useState<any>(null)
    // const [facilityContractData, setFacilityContractData] = useState<any>([]);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const previewFile = useCallback((data: any) => {
        setDocumentFileUpload(data)
        setOpen(true);
    }, []);

    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
        setDocumentFileUpload(null)
    }, []);
    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
        setDocumentFileUpload(null)
    }, []);

    const previewContractFile = useCallback((index: any) => {
        setOpenContract(true);
    }, []);

    const cancelContractPreviewFile = useCallback(() => {
        setOpenContract(false);
    }, []);
    const confirmContractPreviewFile = useCallback(() => {
        setOpenContract(false);
    }, []);

    useEffect(() => {
        for (let i = 0; i < americanTimeZone?.length; i++) {
            if (americanTimeZone[i]?.value === facilityDetails?.timezone) {
                setTimeZone(americanTimeZone[i]?.label);
            }
        }
    }, [facilityDetails]);

    // const getFacilityContract = useCallback(() => {
    //     CommonService._api.get(ENV.API_URL + `facility/${id}/contract?is_preview=true`).then((resp) => {
    //         setFacilityContractData(resp?.data[0]);
    //         //    console.log(resp);
    //     })
    //         .catch((err) => {
    //             //   console.log(err);
    //         });
    // }, [id]);

    // useEffect(() => {
    //     getFacilityContract();
    // }, [getFacilityContract]);
    const handleDownload = useCallback((file_key: any) => {
        setIsDownloading(true);
        let payload = {
            file_key: file_key,
        };
        CommonService._api.post(ENV.API_URL + "downloadAttachment", payload).then((resp) => {
            const link = document.createElement('a');
            link?.setAttribute('href', resp?.data);
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false);
        }).catch((err) => {
            console.log(err);
            setIsDownloading(false);
        });
    }, []);


    useEffect(() => {
        init();
    }, [init]);

    // const StyledLoader = () => {
    //     return (
    //         <div className="pdd-20" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
    //             <LoaderComponent position="block"/>
    //         </div>
    //     );
    // };

    const attachmentListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'Document Name',
                render:(item:any)=>{
                    return (
                        <div
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline'
                            }}
                            onClick={() => previewFile(item)}
                        >{item.file_name || 'N/A'}</div>
                    )
                }
            },
            {
                title: 'Download',
                width: 120,
                render: (item: any) => {
                    return (
                        <div className={'display-center'}>
                            <IconButton onClick={(e) => handleDownload(item?.file_key)}>
                                <GetAppIcon aria-disabled={isDownload} className="download-icon"/>
                            </IconButton>
                        </div>
                    )
                }
            }
        ]
    }, [handleDownload,previewFile,isDownload])


    return (
        <>
            <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile}
                                   previewData={documentFileUpload}/>
            </DialogComponent>
            <DialogComponent open={openContract} cancel={cancelContractPreviewFile} class="preview-content">
                <CustomPreviewFile cancel={cancelContractPreviewFile} confirm={confirmContractPreviewFile}
                                   previewData={facilityDetails?.contract_details}/>
            </DialogComponent>
            <div className="fac-edit-btn">
                <AccessControlComponent
                    role={[ADMIN, NURSECHAMPION, HUMANRESOURCE, ACCOUNTMANAGER, FINANCE, TERICADMIN, RECRUITER,HRMANAGER]}>
                    <Tooltip title={`Edit ${facilityDetails?.name}`}>
                        <Button variant={"contained"} color={"primary"} component={Link} to={`/facility/edit/` + id}>
                            Edit Facility
                        </Button>
                    </Tooltip>
                </AccessControlComponent>
            </div>
            <div className="pdd-0 custom-border">
                <div className={'d-flex pdd-20 hcp-photo-details-wrapper'} style={{display: "flex"}}>
                    <div>
                        <div style={{display: "flex"}}>
                            <Avatar style={{height: "80px", width: "80px"}}>
                                <img style={{width: "80px", height: "80px"}} src={facilityDetails?.profile_url}
                                     alt=""/>
                            </Avatar>
                            <div className="hcp-name-type">
                                <h2 style={{color: "black"}}>
                                    {facilityDetails?.name || 'N/A'}
                                </h2>
                                <p>{facilityDetails?.email || 'N/A'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="rating-container">
                        <p className="rating-title">Average Rating: </p>
                        <p className="rating-content">{facilityDetails?.rating ? `${Math.round(facilityDetails?.rating * 100) / 100}/5` : "N/A"}</p>
                    </div>
                </div>
            </div>
            <div className="custom-border">
                <Accordion expanded={expanded === 'BASIC_DETAILS'} onChange={handleChange('BASIC_DETAILS')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Basic Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="basic-details">
                            <div className="d-flex">
                                {/*<h3>Basic Details</h3>*/}
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Facility Name</h4>
                                    <p>{facilityDetails?.name || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Business Name</h4>
                                    <p>{facilityDetails?.business_name || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Facility Unique Id</h4>
                                    <p>{facilityDetails?.uid || 'N/A'}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Facility Short Name</h4>
                                    <p>{facilityDetails?.short_name || 'N/A'}</p>
                                </div>
                                {/*<div className="flex-1">*/}
                                {/*    <h4>Region Name</h4>*/}
                                {/*    <p>{facilityDetails?.address?.region || 'N/A'}</p>*/}
                                {/*</div>*/}
                                <div className="flex-1">
                                    <h4>Email</h4>
                                    <p>{facilityDetails?.email || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Contact Number</h4>
                                    <p>{CommonService.formatPhoneNumber(facilityDetails?.contact_number?.replace(/^\+1/, '')) || 'N/A'}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Street</h4>
                                    <p>{facilityDetails?.address?.street || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>City</h4>
                                    <p>{facilityDetails?.address?.city || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>State</h4>
                                    <p>{facilityDetails?.address?.state || 'N/A'}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Country</h4>
                                    <p>{facilityDetails?.address?.country || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Zipcode</h4>
                                    <p>{facilityDetails?.address?.zip_code || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>TimeZone</h4>
                                    <p>{timeZone}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Latitude</h4>
                                    <p>{facilityDetails?.location?.coordinates[1] || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Longitude</h4>
                                    <p>{facilityDetails?.location?.coordinates[0] || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>SNF Parent Company</h4>
                                    <p>{facilityDetails?.parent_company || 'N/A'}</p>
                                </div>
                            </div>
                            <div className="d-flex">

                                {/*<div className="flex-1">*/}
                                {/*    <h4>Extension Number</h4>*/}
                                {/*    <p>{facilityDetails?.extension_number ? facilityDetails?.extension_number : "N/A"}</p>*/}
                                {/*</div>*/}
                                <div className="flex-1">
                                    <h4>Website</h4>
                                    <p>{facilityDetails?.website_url || 'N/A'}</p>
                                </div>
                            </div>
                            <div>
                                <h4>About</h4>
                                <p className="summary">{facilityDetails?.about || 'N/A'}</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="basic_details custom-border mrg-top-10">
                <Accordion expanded={expanded === 'Notification_Preferences'}
                           onChange={handleChange('Notification_Preferences')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header"
                    >
                        <Typography className={classes.heading}>Notification Preferences</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.root}>
                            <div className={'hcp-details-wrapper'}>
                                <div className="mrg-top-20" style={{display: "flex"}}>
                                    {facilityDetails?.hasOwnProperty("notification_preferences") ? (
                                        <>
                                            {facilityDetails?.notification_preferences?.sms ||
                                            facilityDetails?.notification_preferences?.email ||
                                            facilityDetails?.notification_preferences?.push_notification ? (
                                                <>
                                                    {facilityDetails?.notification_preferences?.sms && (
                                                        <p className="flex-1">SMS</p>
                                                    )}
                                                    {facilityDetails?.notification_preferences?.email && (
                                                        <p className="flex-1">Email</p>
                                                    )}
                                                    {facilityDetails?.notification_preferences?.push_notification && (
                                                        <p className="flex-1">In-application</p>
                                                    )}
                                                </>
                                            ) : (
                                                <p className="flex-1">NA</p>
                                            )}
                                        </>
                                    ) : (
                                        <p className="flex-1">N/A</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="custom-border mrg-top-10">
                <Accordion expanded={expanded === 'REQUIRED_DOCUMENTS'} onChange={handleChange('REQUIRED_DOCUMENTS')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Required Documents</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {facilityDetails?.document_checklist?.every((doc: any) => !doc.is_required) ?
                            <div style={{width: "100%"}}>
                                <NoDataToShowCardComponent/>
                            </div> :
                            <div className="grid mrg-top-20">
                                {
                                    facilityDetails?.document_checklist?.map((doc: any) => {
                                        return (
                                            <>
                                                {doc?.is_required &&
                                                    <div key={doc.key} className="item">
                                                        <p>• {doc.title === 'License' ? 'Nursing License' : doc.title}</p>
                                                    </div>
                                                }
                                            </>
                                        )
                                    })
                                }
                            </div>
                        }
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="custom-border mrg-top-10">
                <Accordion expanded={expanded === 'REQUIRED_CERTIFICATES'}
                           onChange={handleChange('REQUIRED_CERTIFICATES')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Required Certifications</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {facilityDetails?.required_credentials?.certifications_submission?.length === 0 ?
                            <div style={{width: "100%"}}>
                                <NoDataToShowCardComponent/>
                            </div> :
                            <div className="grid mrg-top-20">
                                {
                                    facilityDetails?.required_credentials?.certifications_submission?.map((doc: any) => {
                                        return (
                                            <>
                                                <div key={doc} className="item">
                                                    <p>• {doc}</p>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        }
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="custom-border mrg-top-10">
                <Accordion expanded={expanded === 'REQUIRED_LICENSE'}
                           onChange={handleChange('REQUIRED_LICENSE')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Required License</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {facilityDetails?.required_credentials?.license_submission?.length === 0 ?
                            <div style={{width: "100%"}}>
                                <NoDataToShowCardComponent/>
                            </div> :
                            <div className="grid mrg-top-20">
                                {
                                    facilityDetails?.required_credentials?.license_submission?.map((doc: any) => {
                                        return (
                                            <>
                                                <div key={doc} className="item">
                                                    <p>• {doc}</p>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        }
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="custom-border mrg-top-10">
                <Accordion expanded={expanded === 'REQUIRED_SKILL_CHECKLIST'}
                           onChange={handleChange('REQUIRED_SKILL_CHECKLIST')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Required Skill Checklist</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {facilityDetails?.required_credentials?.skill_checklist_submission?.length === 0 ?
                            <div style={{width: "100%"}}>
                                <NoDataToShowCardComponent/>
                            </div> :
                            <div className="grid mrg-top-20">
                                {
                                    facilityDetails?.required_credentials?.skill_checklist_submission?.map((doc: any) => {
                                        return (
                                            <>
                                                <div key={doc} className="item">
                                                    <p>• {doc}</p>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        }
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="custom-border mrg-top-10">
                <Accordion expanded={expanded === 'REQUIRED_COMPETENCY_TEST'}
                           onChange={handleChange('REQUIRED_COMPETENCY_TEST')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Required Competency Test</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {facilityDetails?.required_credentials?.competency_test?.length === 0 ?
                            <div style={{width: "100%"}}>
                                <NoDataToShowCardComponent/>
                            </div> :
                            <div className="grid mrg-top-20">
                                {
                                    facilityDetails?.required_credentials?.competency_test?.map((doc: any) => {
                                        return (
                                            <>
                                                <div key={doc} className="item">
                                                    <p>• {doc}</p>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        }
                    </AccordionDetails>
                </Accordion>
            </div>

            {facilityDetails?.attachments ? (
                <div className="custom-border mrg-top-20 pdd-20">
                    <Accordion expanded={expanded === 'ATTACHMENTS'} onChange={handleChange('ATTACHMENTS')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1bh-content"
                            id="panel1bhs-header">
                            <Typography className={classes.heading}>Attachments/Documents</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {facilityDetails?.attachments?.length === 0 && (
                                <p>
                                    <NoDataToShowCardComponent/>
                                </p>
                            )}
                            {facilityDetails?.attachments?.length !== 0 &&
                                <AntTableComponent
                                    columns={attachmentListColumn}
                                    data={facilityDetails?.attachments}
                                    extraPayload={facilityDetails?.attachments}
                                    isRowSelection={false}
                                    noDataText={'No Attachment Found.'}/>}
                        </AccordionDetails>
                    </Accordion>
                </div>
            ) : (
                <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                    <Accordion expanded={expanded === 'ATTACHMENTS'} onChange={handleChange('ATTACHMENTS')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1bh-content"
                            id="panel1bhs-header">
                            <Typography className={classes.heading}>Attachments/Documents</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <NoDataToShowCardComponent/>
                        </AccordionDetails>
                    </Accordion>

                </div>
            )}
            <div className="custom-border mrg-top-10 pdd-top-10">
                <Accordion expanded={expanded === 'FACILITY_IMAGE'} onChange={handleChange('FACILITY_IMAGE')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Facility Image</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="d-flex" style={{gap: "50px"}}>
                            {facilityDetails?.profile_url ? (
                                <div className="attachments" style={{cursor: "pointer"}}>
                                    <Tooltip title="Preview Facility Icon">
                                        <>
                                            <img onClick={() => previewFile(facilityDetails?.profile_url)}
                                                 src={facilityDetails?.profile_url} alt=""
                                                 style={{height: "100px", width: "100px", objectFit: 'contain'}}/>
                                            <p onClick={() => handleDownload(facilityDetails?.profile_key)}
                                               className={"download-link"} style={{
                                                color: "#10C4D3FF",
                                                cursor: 'pointer',
                                                marginTop: "5px",
                                                marginLeft: "5px"
                                            }}>Download</p>
                                        </>
                                    </Tooltip>
                                </div>
                            ) : (
                                <div style={{width: "100%"}}>
                                    <NoDataToShowCardComponent/>
                                </div>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="custom-border mrg-top-10 pdd-top-10">
                <Accordion expanded={expanded === 'FACILITY_CONTRACT'} onChange={handleChange('FACILITY_CONTRACT')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Facility Contract</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="d-flex" style={{gap: "50px"}}>
                            {facilityDetails?.contract_details?.url ? (
                                <div className="attachments" style={{cursor: "pointer"}}>
                                    <Tooltip title="Preview Facility Icon">
                                        {facilityDetails?.contract_details?.ContentType === "image/png" || facilityDetails?.contract_details?.ContentType === "image/jpg" || facilityDetails?.contract_details?.ContentType === "image/jpeg" ? <>
                                            <img src={facilityDetails?.contract_details?.url} alt=""
                                                 onClick={previewContractFile}
                                                 style={{height: "100px", width: "100px", objectFit: "contain"}}
                                            />
                                            <p onClick={() => handleDownload(facilityDetails?.contract_details?.file_key)}
                                               className={"download-link"} style={{
                                                color: "#10C4D3FF",
                                                cursor: 'pointer',
                                                marginTop: "5px",
                                                marginLeft: "5px"
                                            }}>Download</p>

                                        </> : <>
                                            <img src={PdfIcon} alt="" onClick={previewContractFile}
                                                 style={{height: "100px", width: "100px"}}/>
                                            <p onClick={() => handleDownload(facilityDetails?.contract_details?.file_key)}
                                               className={"download-link"} style={{
                                                color: "#10C4D3FF",
                                                cursor: 'pointer',
                                                marginTop: "5px",
                                                marginLeft: "5px"
                                            }}>Download</p>
                                        </>}
                                    </Tooltip>
                                </div>
                            ) : (
                                <div style={{width: "100%"}}>
                                    <NoDataToShowCardComponent/>
                                </div>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="basic_details custom-border mrg-top-10">
                <Accordion expanded={expanded === 'BILL_RATE_DETAILS'} onChange={handleChange('BILL_RATE_DETAILS')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Bill Rate Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={'other-details-wrapper'}>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>SLP Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.slp ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Scrub Tech Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.scrub_tech ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>CNA Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.cna ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Clinical Social Worker Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.clinical_social_worker
                                        ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Marriage Family Therapist Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.marriage_family_therapist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Medical Assistants Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.medical_assistant ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Pharmacist Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.pharmacist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Pharmacy Technician Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.pharmacy_technician ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Psychologist Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.psychologist ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>LVN Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.lvn ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>RN Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.rn ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Care Giver Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.care_giver ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Med Tech Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.med_tech ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>SPD Tech Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.spd_tech ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>DSP Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.dsp ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>PSS Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.pss ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>CLS Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.cls ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>CRMA Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.crma ?? "N/A"}</p>
                                </div>

                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>RN Supervisor Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.rn_supervisor ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Care Giver DSP Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.caregiver_dsp ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Care Giver PSS Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.caregiver_pss ?? "N/A"}</p>
                                </div>

                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>Care Giver CRMA Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.caregiver_crma ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>RN Speciality 1 Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.rn_speciality1 ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>RN Speciality 2 Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.rn_speciality2 ?? "N/A"}</p>
                                </div>

                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>RN Travel Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.rn_travel ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>EMT Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.emt ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Monitor Tech Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.monitor_tech ?? "N/A"}</p>
                                </div>

                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>ORST Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.orst ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Radiology Tech Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.radiology_tech ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>LPN Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.lpn ?? "N/A"}</p>
                                </div>

                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>NOC Diff Rate ($/hr)</h4>
                                    <p>{facilityDetails?.diff_rates?.noc ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>PM Rate ($ / hr)</h4>
                                    <p>{facilityDetails?.diff_rates?.pm ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Weekend Rate ($/hr)</h4>
                                    <p>{facilityDetails?.diff_rates?.weekend ?? "N/A"}</p>
                                </div>

                            </div>


                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>Holiday Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.holiday ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>OT Hours (hr / day)</h4>
                                    <p>{facilityDetails?.conditional_rates?.overtime?.hours ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>OT Rate ($/hr)</h4>
                                    <p>{facilityDetails?.conditional_rates?.overtime?.rate ?? "N/A"}</p>
                                </div>

                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>Hazard Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.hazard ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Rush Hours</h4>
                                    <p>{facilityDetails?.conditional_rates?.rush?.hours ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Rush Rate ($/hr)</h4>
                                    <p>{facilityDetails?.conditional_rates?.rush?.rate ?? "N/A"}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Cancellation Before Hours</h4>
                                    <p>{facilityDetails?.conditional_rates?.cancellation_before?.hours ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Cancellation Before Rate ($/hr)</h4>
                                    <p>{facilityDetails?.conditional_rates?.cancellation_before?.rate ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Shift Early Completion Hours</h4>
                                    <p>{facilityDetails?.conditional_rates?.shift_early_completion?.hours ?? "N/A"}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Shift Early Completion Rate ($/hr)</h4>
                                    <p>{facilityDetails?.conditional_rates?.shift_early_completion?.rate ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Physical Therapist Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.physical_therapist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Occupational Therapist Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.occupational_therapist ?? "N/A"}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>CHHA Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.chha ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>CNA/CHHA Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.cna_chha ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Evaluation (RN/OT/PT/ST) Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.evaluation ?? "N/A"}</p>
                                </div>
                            </div>


                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Start of Care (RN/OT/PT/ST) Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.start_of_care ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Routine visit (RN) Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.rn_routine_vist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Routine visit (LVN) Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.lvn_routine_vist ?? "N/A"}</p>
                                </div>
                            </div>
                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>Routine visit (OT/PT/ST) Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.ot_pt_st_routine_vist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Routine visit (OTA/PTA/STA) Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.ota_pta_sta_routine_vist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Discharge Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.discharge ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>Recertification Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.recertification ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Resumption of Care Rate ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.resumption_of_care ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Routine visit (CNA)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.cna_routine_vist ?? "N/A"}</p>
                                </div>

                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Routine visit (CareGiver/CHHA)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.caregiver_chha_routine_vist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>BSW ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.bsw ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>LNA ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.lna ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>CNA/LNA ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.cna_lna ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>LVN/LPN ($/hr)</h4>
                                    <p>{facilityDetails?.hourly_base_rates?.lpn_lvn ?? "N/A"}</p>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="basic_details custom-border mrg-top-10">
                <Accordion expanded={expanded === 'PAY_RATE_DETAILS'} onChange={handleChange('PAY_RATE_DETAILS')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Pay Rate Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={'other-details-wrapper'}>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>SLP Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.slp ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Scrub Tech Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.scrub_tech ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>CNA Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.cna ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Clinical Social Worker Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.clinical_social_worker
                                        ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Marriage Family Therapist Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.marriage_family_therapist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Medical Assistants Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.medical_assistant ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Pharmacist Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.pharmacist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Pharmacy Technician Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.pharmacy_technician ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Psychologist Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.psychologist ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>LVN Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.lvn ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>RN Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.rn ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Care Giver Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.care_giver ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Med Tech Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.med_tech ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>SPD Tech Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.spd_tech ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>DSP Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.dsp ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>PSS Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.pss ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>CLS Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.cls ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>CRMA Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.crma ?? "N/A"}</p>
                                </div>

                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>RN Supervisor Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.rn_supervisor ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Care Giver DSP Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.caregiver_dsp ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Care Giver PSS Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.caregiver_pss ?? "N/A"}</p>
                                </div>

                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>Care Giver CRMA Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.caregiver_crma ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>RN Speciality 1 Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.rn_speciality1 ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>RN Speciality 2 Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.rn_speciality2 ?? "N/A"}</p>
                                </div>

                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>RN Travel Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.rn_travel ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>EMT Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.emt ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Monitor Tech Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.monitor_tech ?? "N/A"}</p>
                                </div>

                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>ORST Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.orst ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Radiology Tech Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.radiology_tech ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>LPN Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.lpn ?? "N/A"}</p>
                                </div>

                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>NOC Diff Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_diff_rates?.noc ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>PM Rate ($ / hr)</h4>
                                    <p>{facilityDetails?.pay_diff_rates?.pm ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Weekend Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_diff_rates?.weekend ?? "N/A"}</p>
                                </div>

                            </div>


                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>Holiday Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.holiday ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>OT Hours (hr / day)</h4>
                                    <p>{facilityDetails?.pay_conditional_rates?.overtime?.hours ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>OT Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_conditional_rates?.overtime?.rate ?? "N/A"}</p>
                                </div>

                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>Hazard Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.hazard ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Rush Hours</h4>
                                    <p>{facilityDetails?.pay_conditional_rates?.rush?.hours ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Rush Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_conditional_rates?.rush?.rate ?? "N/A"}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Cancellation Before Hours</h4>
                                    <p>{facilityDetails?.pay_conditional_rates?.cancellation_before?.hours ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Cancellation Before Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_conditional_rates?.cancellation_before?.rate ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Shift Early Completion Hours</h4>
                                    <p>{facilityDetails?.pay_conditional_rates?.shift_early_completion?.hours ?? "N/A"}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Shift Early Completion Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_conditional_rates?.shift_early_completion?.rate ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Physical Therapist Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.physical_therapist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Occupational Therapist Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.occupational_therapist ?? "N/A"}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>CHHA Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.chha ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>CNA/CHHA Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.cna_chha ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Evaluation (RN/OT/PT/ST) Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.evaluation ?? "N/A"}</p>
                                </div>
                            </div>


                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Start of Care (RN/OT/PT/ST) Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.start_of_care ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Routine visit (RN) Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.rn_routine_vist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Routine visit (LVN) Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.lvn_routine_vist ?? "N/A"}</p>
                                </div>
                            </div>
                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>Routine visit (OT/PT/ST) Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.ot_pt_st_routine_vist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Routine visit (OTA/PTA/STA) Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.ota_pta_sta_routine_vist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Discharge Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.discharge ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">

                                <div className="flex-1">
                                    <h4>Recertification Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.recertification ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Resumption of Care Rate ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.resumption_of_care ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Routine visit (CNA)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.cna_routine_vist ?? "N/A"}</p>
                                </div>

                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Routine visit (CareGiver/CHHA)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.caregiver_chha_routine_vist ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>BSW ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.bsw ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>LNA ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.lna ?? "N/A"}</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>CNA/LNA ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.cna_lna ?? "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>LVN/LPN ($/hr)</h4>
                                    <p>{facilityDetails?.pay_hourly_base_rates?.lpn_lvn ?? "N/A"}</p>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
};

export default FacilityBasicDetailsComponent;
