import "./HcpFilterDrawerComponent.scss";
import {ENV, ImageConfig} from "../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {DateRangeOutlined} from "@material-ui/icons";
import moment from "moment/moment";
import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {  onboardedListStatus } from "../../../constants/data";
import { makeStyles } from '@material-ui/core/styles';
import {ADMIN, HUMANRESOURCE, TERICADMIN,HRMANAGER} from "../../../helpers/common-service";
import AccessControlComponent from "../../../components/AccessControl";
import {CommonService} from "../../../helpers";

const useStyles = makeStyles((theme) => ({
    option: {
        '&[aria-selected="true"]': {
            backgroundColor: '#41D6C3',
            color: 'white',
        },
    },
}));


interface HcpFilterDrawerComponentProps{
    setOpenFilterDrawer?:any;
    filterData:any;
    setFilterData:any;
    isApprovedList?:boolean;
    hcpTypes:any;
}

const HcpFilterDrawerComponent=(props:HcpFilterDrawerComponentProps)=>{
    const {
        setOpenFilterDrawer,
        filterData,
        setFilterData,
        hcpTypes,
        isApprovedList,
    }=props;
    // const [startDate, endDate] = dateRange;
    const classes=useStyles();
    // const statusList = props?.isApprovedList ? approvedListStatus : onboardedListStatus;
    const statusList=onboardedListStatus;
    const [modifiedNcList,setModifiedNcList]=useState<any>([]);
    const [isNcListLoading,setIsNcListLoading]=useState<boolean>(false);
    const [hrList,setHrList]=useState<any>([]);
    const [isHrListLoading,setIsHrListLoading]=useState<boolean>(false);

    const getNcList=useCallback(()=>{
        setIsNcListLoading(true);
        CommonService._api.get(ENV.API_URL + `user/lite?is_active=true&roles[]=recruiter`).then((response:any)=>{
            const transformedData = response?.data?.map((item:any) => ({
                code: item?._id,
                name: item?.first_name + " " + item?.last_name,
            }));
            setModifiedNcList(transformedData || []);
        }).catch((error:any)=>{
            console.log(error)
        }).finally(()=>{
            setIsNcListLoading(false);
        })
    },[])

    useEffect(() => {
        getNcList()
    }, [getNcList]);

    const getHrList=useCallback(()=>{
        setIsHrListLoading(true);
        CommonService._api.get(ENV.API_URL + `user/lite?is_active=true&roles[]=hr`).then((response:any)=>{
            const transformedData = response?.data?.map((item:any) => ({
                code: item?._id,
                name: item?.first_name + " " + item?.last_name,
            }));
            setHrList(transformedData || []);
        }).catch((error:any)=>{
            console.log(error)
        }).finally(()=>{
            setIsHrListLoading(false);
        })
    },[])

    useEffect(() => {
        getHrList()
    }, [getHrList]);

    const formatFilterMenuListToHandleObj = useCallback(
        (options: any[], filterData: any, key: string): any[] => {
            const allOptions = options.filter(
                (item) => item.code !== "Select All" && item.code !== "Clear All"
            );
            console.log(allOptions,options);
            const selectedValues = filterData[key] || []; // Get selected values for the key
            const allCodes = allOptions.map((item) => item.code);


            let menuList = allOptions;

            // Check if all options are selected
            if (selectedValues.length === allCodes.length) {
                menuList = [{ name: "Clear All", code: "Clear All" }, ...allOptions];
            } else {
                // If not all are selected, show "Select All"
                menuList = [{ name: "Select All", code: "Select All" }, ...allOptions];
            }

            return menuList;
        },
        []
    );

    const handleFilterValueSelectToHandleObj = useCallback(
        (
            newValues: any,
            options: any[],
            key: string, // The key to dynamically update in filterData
        ) => {
            const isSelectAll = newValues.some((f: any) => f.code === "Select All");
            const isClearAll = newValues.some((f: any) => f.code === "Clear All");

            if (newValues.length === 0) {
                // Remove the key from filterData if no values are selected
                setFilterData((prevData: any) => {
                    const {[key]: removedKey, ...rest} = prevData;
                    return rest;
                });
            }

            if (isSelectAll || isClearAll) {
                if (isSelectAll) {
                    const allOptions = options.filter((item) => {
                        return item.code !== "Select All" && item.code !== "Clear All";
                    });

                    // Add all options to filterData under the specified key
                    setFilterData((prevData: any) => ({
                        ...prevData,
                        [key]: allOptions.map((item) => item.code), // Map to codes
                    }));
                }

                if (isClearAll) {

                    // Remove the key from filterData
                    setFilterData((prevData: any) => {
                        const {[key]: removedKey, ...rest} = prevData;
                        return rest;
                    });
                }

                return;
            }

            // For single select

            // Dynamically update filterData with the selected values
            setFilterData((prevData: any) => {
                // If no new values, remove the key from filterData
                if (newValues.length === 0) {
                    const {[key]: removedKey, ...rest} = prevData;
                    return rest;
                }

                // Otherwise, add or update the key in filterData
                return {
                    ...prevData,
                    [key]: newValues.map((item: any) => item.code), // Map to codes
                };
            });
        },
        [setFilterData]
    );

    const updatedFilters=useCallback((updatedFilterData:any)=>{
        // let selectedFilter=0;
        //
        // if (updatedFilterData.start_date) selectedFilter++;
        // if (updatedFilterData.end_date) selectedFilter++;
        //
        // selectedFilter += (updatedFilterData.hcp_types || []).length;
        // if(updatedFilterData?.status){
        //     selectedFilter++;
        // }
        //
        setFilterData(updatedFilterData);
    },[setFilterData])

    const handleDateRangeSelection = useCallback((dateRange: [Date | null, Date | null]) => {
        const [newStartDate, newEndDate] = dateRange || [];

        const startDateFormatted = newStartDate
            ? moment(newStartDate).format("YYYY-MM-DD")
            : null;
        const endDateFormatted = newEndDate
            ? moment(newEndDate || newStartDate).format("YYYY-MM-DD")
            : null;

        const updatedFilterData={...filterData}


        if (startDateFormatted) {
            updatedFilterData.start_date = startDateFormatted;
            // Clear the end_date if the user picks a new start_date
            if (filterData.start_date !== startDateFormatted) {
                delete updatedFilterData.end_date;
            }
        }

        if(endDateFormatted){
            updatedFilterData.end_date=endDateFormatted;
        }


        if (!startDateFormatted && !endDateFormatted) {
            delete updatedFilterData.start_date;
            delete updatedFilterData.end_date;
        }


        updatedFilters(updatedFilterData);
    }, [filterData, updatedFilters]);

    const handleStatusChange = useCallback((value: any) => {
        let updatedFilterData = { ...filterData };

        if (value) {
            if (value.code === "all") {
                delete updatedFilterData.status;
            } else {
                updatedFilterData.status = value.code;
            }
        } else {
            if (!isApprovedList) {
                updatedFilterData.status = "pending";
            } else {
                delete updatedFilterData.status;
            }
        }

        updatedFilters(updatedFilterData);
    }, [isApprovedList, filterData, updatedFilters]);

    const handleHcpTypeChange=useCallback((value:any)=>{
        let updatedFilterData={...filterData};
        updatedFilterData.hcp_types=value?.map((item:any)=>item.code);
        if (updatedFilterData.hcp_types.length === 0) {
            delete updatedFilterData.hcp_types;
        }
        updatedFilters(updatedFilterData);
    },[filterData,updatedFilters])



    const handleClearAll=useCallback(()=>{
        // setStatus({name: "Pending", code: "pending"});
        // setSelectedHcpTypes([]);
        let updatedFilterData={...filterData};
        delete updatedFilterData.start_date;
        delete updatedFilterData.end_date;
        delete updatedFilterData.hcp_types;
        delete updatedFilterData.recruiter_ids;
        delete updatedFilterData.hr_ids;
        updatedFilterData.status='pending';
        setFilterData(updatedFilterData);
    },[filterData,setFilterData])


    return (
        <div className={'facility-filter-component'}>
            <div className={'facility-filter-header-wrapper'}>
                <div className={'facility-filter-header'}>
                    <div className={'facility-filter-title'}>Filters</div>
                    <div className={'facility-filter-clear-all'} onClick={handleClearAll}>Clear All</div>
                </div>
                <div
                    className={'facility-filter-close-btn'}
                    onClick={() => setOpenFilterDrawer(false)}
                >
                    <img src={ImageConfig.CrossIcon} alt={'cross-icon'}/>
                </div>
            </div>
            <div className={'facility-filter-fields-wrapper'}>
                <div className={'facility-date-range'}>
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Select Date"
                        className="facility-custom-input"
                        selectsRange={true}
                        startDate={filterData?.start_date && new Date(`${filterData.start_date}T00:00:00`) }
                        endDate={filterData?.end_date && new Date(`${filterData.end_date}T00:00:00`)}
                        onChange={(update) => {
                            handleDateRangeSelection(update);
                            // setDateRange(update);
                        }}
                        isClearable={true}
                    />
                    {!filterData?.start_date && !filterData?.end_date &&
                        <DateRangeOutlined className="facility-date-icon" fontSize="medium" color="action"/>}
                </div>
                <div>
                    <Autocomplete
                        PaperComponent={({ children }) => <Paper style={{ color: "#1e1e1e" }}>{children}</Paper>}
                        // value={status}
                        value={statusList.find((option: any) => option.code === filterData.status)}
                        options={statusList}
                        getOptionLabel={(option: any) => option.name}
                        placeholder={"Select Status"}
                        id="input_select_status"
                        className="mrg-top-10"
                        classes={{
                            option: classes.option,
                        }}
                        onChange={($event, value) =>{
                            handleStatusChange(value)
                        }}
                        renderInput={(params) =>
                            <TextField {...params} id="select_status"
                                       variant="outlined"
                                       placeholder={"Status"}
                                       fullWidth />}
                    />
                </div>
                <div>
                    <Autocomplete
                        PaperComponent={({ children }) => <Paper style={{ color: "#1e1e1e" }}>{children}</Paper>}
                        multiple
                        // value={selectedHcpTypes}
                        value={hcpTypes?.filter((type: any) => filterData?.hcp_types?.includes(type.code)) || []}
                        options={hcpTypes}
                        getOptionLabel={(option: any) => option?.title}
                        getOptionSelected={(option, value) => option.title === value?.title}
                        placeholder={"Select Staff Type"}
                        id="input_select_hcpType"
                        className="mrg-top-10"
                        classes={{
                            option: classes.option,
                        }}
                        onChange={($event, value) => {
                            handleHcpTypeChange(value);
                        }}
                        // renderTags={() => null}
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({ index: 0 })}>
                                    {selectedCount} {selectedCount === 1 ?  "staff type " : "staff types"} selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.hcp_types?.length === 0 || !filterData?.hcp_types
                                ? "Multiple Staff Types"
                                : "";
                            return <TextField {...params} id="select_hcpType" variant="outlined" placeholder={placeholder} />;
                        }}

                    />
                </div>
                <AccessControlComponent role={[ADMIN, TERICADMIN,HUMANRESOURCE,HRMANAGER]}>
                    <div className="form-field-item">
                        <Autocomplete
                            disableClearable
                            className="mrg-top-10"
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            multiple
                            classes={{
                                option: classes.option,
                            }}
                            getOptionLabel={(option) => option?.name}
                            getOptionSelected={(option, value) => option.name === value?.name}
                            id="input_select_facility"
                            // value={selectedFacilities}
                            value={modifiedNcList?.filter((item: any) => filterData?.recruiter_ids?.includes(item.code))}
                            options={formatFilterMenuListToHandleObj(modifiedNcList, filterData, 'recruiter_ids')}
                            onChange={(e, newValue) =>
                                handleFilterValueSelectToHandleObj(
                                    newValue,
                                    modifiedNcList,
                                    "recruiter_ids"
                                )
                            }
                            renderTags={(value: any, getTagProps: any) => {
                                const selectedCount = value.length;
                                return selectedCount > 0 ? (
                                    <span {...getTagProps({index: 0})}>
                                    {selectedCount} {selectedCount === 1 ? "recruiter":"recruiters"} selected
                                </span>
                                ) : null;
                            }}
                            renderInput={(params: any) => {
                                const placeholder = filterData?.recruiter_ids?.length === 0 || !filterData?.recruiter_ids
                                    ? "Recruiters"
                                    : "";
                                return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                            }}
                            disableCloseOnSelect
                        />
                        {isNcListLoading && (
                            <div className="loading-text-wrapper">
                                <span className="loading-text">loading...</span>
                            </div>
                        )}
                    </div>
                </AccessControlComponent>
                <AccessControlComponent role={[ADMIN, TERICADMIN,HUMANRESOURCE,HRMANAGER]}>
                    <div className="form-field-item">
                        <Autocomplete
                            disableClearable
                            className="mrg-top-10"
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            multiple
                            classes={{
                                option: classes.option,
                            }}
                            getOptionLabel={(option) => option?.name}
                            getOptionSelected={(option, value) => option.name === value?.name}
                            id="input_select_facility"
                            // value={selectedFacilities}
                            value={hrList?.filter((item: any) => filterData?.hr_ids?.includes(item.code))}
                            options={formatFilterMenuListToHandleObj(hrList, filterData, 'hr_ids')}
                            onChange={(e, newValue) =>
                                handleFilterValueSelectToHandleObj(
                                    newValue,
                                    hrList,
                                    "hr_ids"
                                )
                            }
                            renderTags={(value: any, getTagProps: any) => {
                                const selectedCount = value.length;
                                return selectedCount > 0 ? (
                                    <span {...getTagProps({index: 0})}>
                                    {selectedCount} {selectedCount === 1 ? "hr":"hrs"} selected
                                </span>
                                ) : null;
                            }}
                            renderInput={(params: any) => {
                                const placeholder = filterData?.hr_ids?.length === 0 || !filterData?.hr_ids
                                    ? "HR"
                                    : "";
                                return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                            }}
                            disableCloseOnSelect
                        />
                        {isHrListLoading && (
                            <div className="loading-text-wrapper">
                                <span className="loading-text">loading...</span>
                            </div>
                        )}
                    </div>
                </AccessControlComponent>
            </div>
        </div>
    )
}

export default HcpFilterDrawerComponent;