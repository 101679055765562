import {
    FETCH_REGIONS_REQUEST,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_FAILURE,
    FETCH_HCP_TYPES_REQUEST,
    FETCH_HCP_TYPES_SUCCESS,
    FETCH_HCP_TYPES_FAILURE,
    GET_SPECIALITIES_REQUEST,
    GET_SPECIALITIES_SUCCESS, GET_SPECIALITIES_FAILURE, GET_FACILITY_DOCUMENTS, SET_FACILITY_DOCUMENTS,
    GET_NOTIFICATION_REQUEST,GET_NOTIFICATION_FAILURE,GET_NOTIFICATION_SUCCESS,
    GET_REQUIRED_COMPETENCY_TEST,
    GET_REQUIRED_SKILL_CHECKLIST,
    GET_REQUIRED_LICENSE,
    GET_REQUIRED_CERTIFICATIONS,
    SET_REQUIRED_CERTIFICATIONS,
    SET_REQUIRED_COMPETENCY_TEST,
    SET_REQUIRED_SKILL_CHECKLIST,
    SET_REQUIRED_LICENSE
} from "../actions/meta.action";

export interface MetaParams {
    regions: any[];
    documents: any[];
    licenseDocuments:any[];
    skillCheckListDocuments:any[];
    competencyTestDocuments:any[];
    certificationDocuments:any[];
    isLoading: boolean;
    error: any;
    hcpTypes: any[];
    isHcpTypesLoading: boolean;
    hcpTypesError: any;
    specialitiesMasterList: any[];
    specIsLoading: boolean;
    specError: string | null;
    notificationList:any[];
    isNotificationLoading:boolean;
    notificationError:string | null;
}

const initialData: MetaParams = {
    regions: [],
    documents: [],
    licenseDocuments:[],
    skillCheckListDocuments:[],
    competencyTestDocuments:[],
    certificationDocuments:[],
    isLoading: true,
    error: null,
    hcpTypes: [],
    isHcpTypesLoading: true,
    hcpTypesError: null,
    specialitiesMasterList: [],
    specIsLoading: false,
    specError: null,
    notificationList:[],
    isNotificationLoading:false,
    notificationError:null
};

const metaReducer = (state = initialData, action: any): MetaParams => {
    switch (action.type) {
        case FETCH_REGIONS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_REGIONS_SUCCESS:
            return {
                ...state,
                regions: action.payload.regions,
                isLoading: false,
            };
        case GET_FACILITY_DOCUMENTS:
            return {
                ...state,
            };
        case SET_FACILITY_DOCUMENTS:
            return {
                ...state,
                documents: action.payload.documents,
            };
        case GET_REQUIRED_CERTIFICATIONS:
            return {
                ...state,
            };
        case SET_REQUIRED_CERTIFICATIONS:
            return {
                ...state,
                certificationDocuments: action.payload.certificationDocuments,
            };
        case GET_REQUIRED_LICENSE:
            return {
                ...state,
            };
        case SET_REQUIRED_LICENSE:
            return {
                ...state,
                licenseDocuments: action.payload.licenseDocuments,
            };
        case GET_REQUIRED_SKILL_CHECKLIST:
            return {
                ...state,
            };
        case SET_REQUIRED_SKILL_CHECKLIST:
            return {
                ...state,
                skillCheckListDocuments: action.payload.skillCheckListDocuments,
            };
        case GET_REQUIRED_COMPETENCY_TEST:
            return {
                ...state,
            }
        case SET_REQUIRED_COMPETENCY_TEST:
            return {
                ...state,
                competencyTestDocuments:action.payload.competencyTestDocuments
            }
        case FETCH_REGIONS_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false,
            };

        case FETCH_HCP_TYPES_REQUEST:
            return {
                ...state,
                isHcpTypesLoading: true
            }
        case FETCH_HCP_TYPES_SUCCESS:
            return {
                ...state,
                hcpTypes: action.payload.hcpTypes,
                isHcpTypesLoading: false
            }
        case FETCH_HCP_TYPES_FAILURE:
            return {
                ...state,
                hcpTypesError: action.payload.hcpTypesError,
                isHcpTypesLoading: false,
            };
        case GET_SPECIALITIES_REQUEST:
            return {
                ...state,
                specIsLoading: true,
                specError: null,
            };
        case GET_SPECIALITIES_SUCCESS:
            return {
                ...state,
                specialitiesMasterList: action.payload,
                specIsLoading: false,
            };
        case GET_SPECIALITIES_FAILURE:
            return {
                ...state,
                specIsLoading: false,
                specError: action.payload,
            };
        case GET_NOTIFICATION_REQUEST:
            return {
                ...state,
                isNotificationLoading: true,
                notificationError: null,
            };
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationList: action.payload,
                isNotificationLoading: false,
            };
        case GET_NOTIFICATION_FAILURE:
            return {
                ...state,
                isNotificationLoading: false,
                notificationError: action.payload,
            };
        default:
            return state;
    }
};

export default metaReducer;
