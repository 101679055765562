import {Button, Tooltip, IconButton} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import DialogComponent from "../../../../components/DialogComponent";
import LoaderComponent from "../../../../components/LoaderComponent";
import {useHistory, useLocation} from 'react-router-dom';
import {ENV, ImageConfig} from "../../../../constants";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import {StateParams} from "../../../../store/reducers";
import AddShiftsComponent from "../addShiftsComponent/AddShiftsComponent";
import RejectShiftComponent from "../rejectApplication/RejectApplicationComponent";
import PendingShiftsTakeoverComponent from "../takeover/PendingShiftsTakeoverComponent";
import PendingSihftsViewComponent from "../view/PendingShiftsViewComponent";
import SendIcon from "@material-ui/icons/Send";
import "./PendingShiftsListScreen.scss";
import {SearchRounded} from "@material-ui/icons";
import {pendingShiftsStatusList} from "../../../../constants/data";
import {CssTextField} from "../../../../constants/data/styles";
import WarningIcon from "@material-ui/icons/Warning";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import DrawerComponent from "../../../../components/drawer/DrawerComponent";
import ShiftFilterDrawerComponent from "../../shift-filter-drawer/ShiftFilterDrawerComponent";
import {TableColumnsType} from "antd";
import {useSessionStorage} from "../../../../components/useSessionStorage";
import ModalComponent from "../../../../components/modal/ModalComponent";

const PendingShiftsListScreen = () => {
    const history=useHistory();
    const location=useLocation();
    const [hcpId, setHcpId] = useState<string>("");
    const [applicationId, setApplicationId] = useState<string>("");
    const [requirementId, setRequirementId] = useState<string>("");
    const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
    const [isTakeOverOpen, setTakeOverOpen] = useState<boolean>(false);
    const {user} = useSelector((state: StateParams) => state.auth);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta)
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [isApprovalSubmitting, setIsApprovalSubmitting] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isAddShiftsOpen, setIsAddShiftsOpen] = useState<boolean>(false);
    const [rejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [openFilterDrawer,setOpenFilterDrawer]=useState<boolean>(false);
    const [refreshToken,setRefreshToken]=useState<number>(0);
    const [prevLocation,setPrevLocation]=useState<string>("");
    const [filterCount,setFilterCount]=useState<number>(0);
    const [filterData,setFilterData]=useSessionStorage<any>("pendingShiftFilterData",{
        search:'',
        sort:{},
        user_id:user?._id,
        new_shifts: CommonService.changeToUtcDate(new Date()),
        status:['pending'],
        limit:10,
    })
    // const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        let count=0;
        if(filterData?.start_date)count++;
        if(filterData?.end_date)count++;
        if(filterData?.status && filterData?.status?.length > 0) count++;
        if(filterData?.facilities && filterData?.facilities?.length >0) count++;
        if(filterData?.hcp_types && filterData?.hcp_types?.length >0) count++;
        if(filterData?.shift_types && filterData?.shift_types?.length >0) count++;
        if(filterData?.hcp_user_ids && filterData?.hcp_user_ids?.length >0) count++;
        if(filterData?.type) count++;
        setFilterCount(count);
    }, [filterData]);

    useEffect(() => {
        setFilterData((prev: any) => {
            const newUtcDate = CommonService.changeToUtcDate(new Date());
            if (filterData?.new_shifts && (!filterData?.start_date && !filterData?.end_date) && prev.new_shifts !== newUtcDate) {
                return {
                    ...prev,
                    new_shifts: newUtcDate,
                };
            }
            return prev;
        });
    }, [setFilterData,filterData?.new_shifts,filterData?.start_date,filterData?.end_date]);

    useEffect(() => {
        // Detect page change by comparing current location with previous location
        if (location.pathname !== prevLocation) {
            setPrevLocation(location.pathname); // Update the previous location state

            // Only reset the filterData when navigating different page
            if (location.pathname.startsWith('/pendingShifts/') && (!filterData.status)) {
                let updatedFilterData={...filterData};
                updatedFilterData.status=["pending"];
                setFilterData(updatedFilterData);
            }
        }
    }, [location, filterData, setFilterData, prevLocation]);

    // to open modal id shift id present(using this for in app notification)
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const shiftId = searchParams.get("shift_id");

        if (shiftId) {
            setApplicationId(shiftId);
            setIsViewOpen(true);
        }
    }, [location.search]);

    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        let payload: any = {};

        // if (selectedRegion.length > 0) {
        //     payload.regions = selectedRegion;
        // }

        if (filterData?.facilities?.length > 0) {
            payload.facilities = filterData?.facilities;
        }
        if (filterData?.hcp_types?.length > 0) {
            payload.hcp_types = filterData?.hcp_types;
        }

        if (filterData?.hcp_user_ids?.length > 0) {
            payload.hcp_user_ids = filterData?.hcp_user_ids;
        }

        //send all status shifts for download when not selected
        if (filterData?.status?.length === 0) {
            payload.status = pendingShiftsStatusList.map(item => item.code);
        }
        if (filterData?.search !== "") {
            payload.search = filterData?.search
        }

        if (filterData?.type !== '') {
            payload.type = filterData?.type;
        }

        if (filterData?.status?.length > 0) {
            payload.status = filterData?.status;
        }

        if(filterData?.start_date || filterData?.end_date) {
            let startDate = moment(filterData?.start_date).format("YYYY-MM-DD");
            let endDate = moment(filterData?.end_date).format("YYYY-MM-DD");
            if (!filterData?.end_date) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }

        if(filterData?.shift_types?.length > 0) {
            payload.shift_types = filterData?.shift_types;
        }

        ApiService.post(ENV.API_URL + "application/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.msg || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.error || "Error", "error");
            });
    }, [filterData]);

    const cancelView = useCallback(() => {
        if (isViewOpen) {
            setIsViewOpen(false);
            const searchParams = new URLSearchParams(location.search);
            searchParams.delete("shift_id");
            history.replace({ search: searchParams.toString() });
        } else {
            setTakeOverOpen(false);
        }
    }, [isViewOpen,history,location.search]);

    const openViewShift = useCallback(() => {
        setIsViewOpen(true);
        setTakeOverOpen(false);
    }, []);

    const openView = useCallback((id: any, hcp_user_id: any, application_id: any) => {
        setIsViewOpen(true);
        setRequirementId(id);
        setHcpId(hcp_user_id);
        setApplicationId(application_id);
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("shift_id", application_id);
        history.replace({ search: searchParams.toString() });
    }, [history,location.search]);

    const confirmView = useCallback(() => {
        setIsViewOpen(false);
    }, []);

    const cancelTakeOver = useCallback(() => {
        setTakeOverOpen(false);
    }, []);

    const openTakeOver = useCallback((hcp_user_id: any, application_id: any, requirement_id: any, status: any) => {
        setTakeOverOpen(true);
        setRequirementId(requirement_id);
        setHcpId(hcp_user_id);
        setApplicationId(application_id);
        // setShiftStatus(status);
    }, []);

    const confirmTakeOver = useCallback(() => {
        setTakeOverOpen(false);
        setRefreshToken((prev:number)=>prev + 1)
    }, []);


    const clearFilterValues = useCallback(() => {
        setFilterData((prevFilterData:any) => {
            const updatedFilterData = {
                ...prevFilterData,
            };

            // Dynamically remove keys if they exist
            const keysToRemove = [
                "start_date",
                "end_date",
                "facilities",
                "hcp_types",
                "hcp_user_ids",
                "shift_types",
                "type",
            ];

            keysToRemove.forEach((key:any) => delete updatedFilterData[key]);
            updatedFilterData.status=['pending'];
            updatedFilterData.new_shifts=CommonService.changeToUtcDate(new Date());

            return updatedFilterData;
        });
    },[setFilterData])

    const resetFilters = () => {
        clearFilterValues();
    };


    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);

    const handleFacilityApproval = useCallback(
        (requirementId, applicationId) => {
            setIsApprovalSubmitting(true);
            let payload = {
                awaited_by: user?._id,
            };
            CommonService._api
                .patch(ENV.API_URL + `application/${applicationId}/await`, payload)
                .then((resp) => {
                    CommonService.showToast(resp?.msg || "Success", "success");
                    let updatedFilterData={...filterData};
                    setFilterData(updatedFilterData)
                    setIsApprovalSubmitting(false);
                })
                .catch((err) => {
                    console.log(err);
                    CommonService.showToast(err?.msg || "Error", "error");
                    let updatedFilterData={...filterData};
                    setFilterData(updatedFilterData)
                    setIsApprovalSubmitting(false);
                });
        },
        [user?._id, filterData,setFilterData]
    );

    // const openRejectShift = useCallback(() => {
    //     setRejectShiftOpen(true);
    // }, []);

    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        setSelectedShifts([]);
    }, []);

    const cancelOpenAddShifts = useCallback(() => {
        setIsAddShiftsOpen(false);
        setSelectedShifts([]);
    }, []);

    const onAddShiftsConfirm = useCallback(() => {
        setIsAddShiftsOpen(false);
        setSelectedShifts([]);
        setRefreshToken((prev:number)=>prev + 1)
    }, []);

    const onRejectShiftConfirm = useCallback(() => {
        setRejectShiftOpen(false);
        setSelectedShifts([]);
        setRefreshToken((prev:number)=>prev + 1)
    }, []);

    useEffect(() => {
        getFacilityData();
    }, [getFacilityData]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Pending");
        Communications.pageBackButtonSubject.next(null);
    }, []);


    const completedShiftsListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title: '',
                width: 55,
                render: (item: any) => {
                    const missingDocuments = item["document_status"]?.filter((doc: any) => doc.is_required && !doc.is_uploaded);
                    return (
                        <div style={{ display: 'flex', width:'100%', cursor:'pointer' }} className={'display-center'}>
                            {missingDocuments?.length > 0 ?
                                <Tooltip
                                    arrow
                                    title={
                                        <div className="missing-documents-container">
                                            <div className="tooltip">
                                                <h3>Missing staff documents</h3>
                                                <ul>
                                                    {missingDocuments?.map((doc: any) => (
                                                        <li key={doc.key}>{doc.title === 'License' ? "Nursing License" : doc.title}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                >
                                    <div
                                            style={{
                                                color: "red",
                                                cursor: "pointer",
                                            }}
                                        onClick={()=>history.push(`/hcp/edit/${item["hcp_details"]?._id}`)}
                                    >
                                        {(missingDocuments && missingDocuments?.length > 0) &&
                                            <WarningIcon/>}
                                    </div>
                                    {/*<Button style={{*/}
                                    {/*    color: "red",*/}
                                    {/*    cursor: "pointer",*/}
                                    {/*}} to={`/hcp/edit/` + item["hcp_details"]?._id}*/}
                                    {/*        component={Link}></Button>*/}
                                </Tooltip>:
                                <div>

                                </div>
                            }
                        </div>
                    );
                },
            },
            {
                title: 'Facility Name',
                width: 200,
                sorter: (a: any, b: any) => (a?.facility?.name || '').localeCompare(b?.facility?.name || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.facility?.name?.length > 20 ?
                                <Tooltip title={item?.facility?.name || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility?.name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.facility?.name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Staff Name',
                width:200,
                sorter: (a: any, b: any) =>
                    ((a?.hcp_details?.first_name || '') + (a?.hcp_details?.last_name || ''))
                        .localeCompare((b?.hcp_details?.first_name || '') + (b?.hcp_details?.last_name || '')),
                render:(item:any)=>{
                    return (
                        <div>
                            {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)?.length > 20 ?
                                <Tooltip title={(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Applied On',
                width:130,
                sorter: (a: any, b: any) => new Date(a["created_at"]).getTime() - new Date(b["created_at"]).getTime(),
                render:(item:any)=>{
                    return (
                        <div>
                            {moment(item["created_at"]).format("MM-DD-YYYY") || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Staff Type',
                width: 170,
                sorter: (a: any, b: any) => (a?.hcp_data?.hcp_type || '').localeCompare(b?.hcp_data?.hcp_type || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.hcp_data?.hcp_type?.length > 20 ?
                                <Tooltip title={item?.hcp_data?.hcp_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp_data?.hcp_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.hcp_data?.hcp_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Type',
                width: 200,
                sorter: (a: any, b: any) => (a?.shift_type || '').localeCompare(b?.shift_type || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.shift_type?.length > 20 ?
                                <Tooltip title={item?.shift_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.shift_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.shift_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Date',
                width: 120,
                sorter: (a: any, b: any) => new Date(a["shift_date"]).getTime() - new Date(b["shift_date"]).getTime(),
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.getUtcDate(item["shift_date"]) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Status',
                width:120,
                // sorter: (a: any, b: any) => (a["status"] || '').localeCompare(b["status"] || ''),
                render:(item:any)=>{
                    return (
                        <div className={`captalize ${item["status"]}`}>
                            {item["status"]}
                        </div>
                    )
                }
            },
            {
                title:'Awaiting',
                width:100,
                render:(item:any)=>{
                    return (
                        <div>
                            <Tooltip
                                title={`Send ${item["hcp_data"]?.first_name} ${item["hcp_data"]?.last_name} for Facility Approval`}>
                            <span>
                              <IconButton disabled={item["status"] !== "pending"} color="primary"
                                          onClick={() => handleFacilityApproval(item["requirement_id"], item["_id"])}
                                          className={item["status"] !== "pending" ? "btn-disabled" : ""}>
                                <SendIcon fontSize="medium"/>
                              </IconButton>
                            </span>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title:'View Details',
                width:170,
                render: (_: any, item: any, rowIndex: number)=>{
                    return(
                        <div className={'d-flex align-items-center'}>
                            <div className="actions-table">
                                <Button
                                    variant="outlined"
                                    onClick={() => openTakeOver(item["hcp_user_id"], item["_id"], item["requirement_id"], "approve")}
                                    disabled={item["status"] !== "awaiting" || item["can_take_over"] === false}
                                    className={item["status"] !== "awaiting" || item["can_take_over"] === false ? "btn-disabled" : "btn-enabled"}
                                >
                                    TAKEOVER
                                </Button>
                            </div>
                            <div

                                onClick={() => openView(item["requirement_id"], item["hcp_user_id"], item["_id"])}
                                 style={{color: '#1e1e1e',marginLeft:'20px',cursor:'pointer'}}>
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </div>
                        </div>
                    )
                }
            },
        ]
    }, [openView, openTakeOver, handleFacilityApproval,history])


    return (
        <div className="pending-list-screen screen crud-layout pdd-30">
            <DialogComponent open={rejectShiftOpen} cancel={cancelRejectShift}>
                <RejectShiftComponent cancel={cancelRejectShift} confirm={onRejectShiftConfirm}
                                      selectedShifts={selectedShifts}/>
            </DialogComponent>
            <ModalComponent open={isViewOpen} onCancel={cancelView} title={"Pending Shift Details"} width={1000} footer={null} centered={true}>
                <PendingSihftsViewComponent cancel={cancelView} requirementId={requirementId} confirm={confirmView}
                                            hcpId={hcpId} applicationId={applicationId}/>
            </ModalComponent>
            <DialogComponent open={isTakeOverOpen} cancel={cancelTakeOver} maxWidth="xs">
                <PendingShiftsTakeoverComponent cancel={cancelTakeOver} confirm={confirmTakeOver}
                                                openViewShift={openViewShift} applicationId={applicationId}
                                                requirementId={requirementId} hcpId={hcpId}/>
            </DialogComponent>
            <DialogComponent open={isAddShiftsOpen} cancel={cancelOpenAddShifts} maxWidth="md">
                <AddShiftsComponent cancel={cancelOpenAddShifts} selectedShiftIds={selectedShifts}
                                    confirm={onAddShiftsConfirm}/>
            </DialogComponent>

            <DrawerComponent isOpen={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                <ShiftFilterDrawerComponent
                    filterData={filterData}
                    setFilterData={setFilterData}
                    setOpenFilterDrawer={setOpenFilterDrawer}
                    isFacilityListLoading={isFacilityListLoading}
                    isPending={true}
                    noStatus={false}
                    resetFilters={resetFilters}
                    facilityList={facilityList}
                    hcpTypes={hcpTypes}
                />
            </DrawerComponent>

            {isApprovalSubmitting && (
                <div className="table-loading-indicator">
                    <LoaderComponent/>
                </div>
            )}
            <div className="custom-border pdd-20 mrg-bottom-10">
                <div className="pending-shift-header">
                    <div className="d-flex">
                        <div className="d-flex position-relative">
                            {!filterData.search ? (
                                <div className={"search_icon"}>
                                    <SearchRounded/>
                                </div>
                            ) : (
                                <div className={"search_icon"}>
                                    <ClearIcon
                                        onClick={(event) => {
                                            setFilterData({...filterData, search: ''})
                                            setSelectedShifts([]);
                                        }}
                                        id="clear_facility_search"
                                    />
                                </div>
                            )}
                            <div>
                                <CssTextField
                                    defaultValue={""}
                                    className="search-cursor searchField"
                                    id="input_search_facility"
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setFilterData({...filterData, search: value});
                                    }}
                                    value={filterData.search}
                                    variant={"outlined"}
                                    size={"small"}
                                    type={"text"}
                                    placeholder={"Search Shift"}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="actions d-flex mrg-right-5 mrg-bottom-5">
                        <div className={'mrg-left-10'}>
                            <Button
                                variant={"outlined"} id={'add_facility_btn'}
                                color={"primary"}
                                onClick={() => {
                                    setOpenFilterDrawer(true)
                                }}
                            >
                                {filterCount > 0 &&
                                    <span className={'filter-btn-badge'}>{filterCount}</span>
                                }
                                &nbsp;&nbsp;Filters&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div className="actions pdd-right-5">
                            <Tooltip title="Download Shifts List">
                                <Button variant={"outlined"} color="primary" onClick={handleDownload}
                                        className={!isDownloading ? "" : "has-loading-spinner"}
                                        disabled={isDownloading}>
                                    &nbsp;Download
                                </Button>
                            </Tooltip>
                        </div>
                    </div>

                </div>
                <div className={'pending-table-wrapper'}>
                    <AntTableComponent
                        key={refreshToken}
                        url={ENV.API_URL + 'application'}
                        method={'post'}
                        extraPayload={filterData}
                        columns={completedShiftsListColumn}
                        noDataText={'No Pending Shift Found.'}
                        isRowSelection={true}
                        selectedRowKeys={selectedShifts}
                        setSelectedRowKeys={setSelectedShifts}
                    />
                </div>
            </div>
        </div>
    );
};

export default PendingShiftsListScreen;
