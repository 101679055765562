import "./PostingConfigurationComponent.scss";
import {Button, Form as AntdForm, Radio} from "antd";
import React, {useCallback, useState} from "react";
import FormLabelComponent from "../../../../ant-component/form-label/FormLabelComponent";
import SelectDropdownComponent from "../../../../ant-component/select/SelectComponent";
import {CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import {freeJobBoardList, paidJobBoardList} from "../../../../../data";

interface PostingConfigurationComponentProps{
    selectedJobs:string[];
    setSelectedJobs:any;
    setOpenConfigModal:any;
    filterData?:any;
    setFilterData?:any;
}

const PostingConfigurationComponent=(props:PostingConfigurationComponentProps)=>{
    const {selectedJobs,setSelectedJobs,setOpenConfigModal,filterData,setFilterData}=props;
    const [isSubmitting,setIsSubmitting]=useState<boolean>(false);
    const [showOnWebsite,setShowOnWebsite]=useState<string>('');
    const [showOnMobile,setShowOnMobile]=useState<string>("");
    const [showFacility,setShowFacility]=useState<string>('');
    const [isJobBoard,setIsJobBoard]=useState<string>('');
    const [jobBoard,setJobBoard]=useState<string[]>(['google']);
    const [paidJobBoard,setPaidJobBoard]=useState<string[]>([]);

    const handleUpdatePostingConfig=useCallback(()=>{
            setIsSubmitting(true);
            let payload:any={
                posting_status: {
                    website: showOnWebsite === "Yes",
                    mobile_app: showOnMobile === "Yes",
                    is_job_boards: isJobBoard === "Yes",
                    show_facility:showFacility === 'Yes',
                    job_boards: jobBoard || [],
                    paid_job_boards: paidJobBoard || [],
                },
                job_ids:selectedJobs
            }
            CommonService._api.put(ENV.API_URL + 'ats/editBulkJobPostingStatus',payload).then((response:any)=>{
                CommonService.showToast(response.message || 'Successfully updated the configuration','success')
                setFilterData({...filterData})
            }).catch((error:any)=>{
                CommonService.showToast(error.error,'error')
            }).finally(()=>{
                setIsSubmitting(false);
                setOpenConfigModal(false);
                setSelectedJobs([])
            })
    },[setOpenConfigModal,showFacility,showOnMobile,showOnWebsite,isJobBoard,jobBoard,paidJobBoard,selectedJobs,setFilterData,filterData,setSelectedJobs])

    const handleCancel=useCallback(()=>{
        setOpenConfigModal(false);
        setSelectedJobs([])
    },[setOpenConfigModal,setSelectedJobs])

    return (
        <div className={'posting-config-component'}>
            <div className={'posting-config-content-wrapper'}>
                <div>
                    <FormLabelComponent Label={"On Company Website"}/>
                    <div className={'mrg-top-10'}>
                        <AntdForm.Item>
                            <Radio.Group
                                className="custom-radio"
                                value={showOnWebsite}
                                onChange={(e) => setShowOnWebsite(e.target.value)}
                            >
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </AntdForm.Item>
                    </div>
                </div>
                <div>
                    <FormLabelComponent Label={"On Mobile"}/>
                    <div className={'mrg-top-10'}>
                        <AntdForm.Item>
                            <Radio.Group
                                className="custom-radio"
                                value={showOnMobile}
                                onChange={(e) => setShowOnMobile(e.target.value)}
                            >
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </AntdForm.Item>
                    </div>
                </div>
                <div>
                    <FormLabelComponent Label={"Show Facility on Job boards"}/>
                    <div className={'mrg-top-10'}>
                        <AntdForm.Item>
                            <Radio.Group
                                className="custom-radio"
                                value={showFacility}
                                onChange={(e) => setShowFacility(e.target.value)}
                            >
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </AntdForm.Item>
                    </div>
                </div>
                <div>
                    <FormLabelComponent Label={"On Job Boards"}/>
                    <div className={'mrg-top-10'}>
                        <AntdForm.Item>
                            <Radio.Group
                                className="custom-radio"
                                value={isJobBoard}
                                onChange={(e) => setIsJobBoard(e.target.value)}
                            >
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </AntdForm.Item>
                    </div>
                </div>
                {isJobBoard === 'Yes' &&
                    <div className={'d-flex'}>
                        <div className={'mrg-right-20 flex-equal'}>
                            <SelectDropdownComponent
                                className={'job-board-select'}
                                placeholder={'Select Free Job Board'}
                                value={jobBoard}
                                options={freeJobBoardList || []}
                                required={true}
                                size={"middle"}
                                mode={'multiple'}
                                displayWith={(option) => option.title}
                                valueExtractor={(option) => option.code}
                                onChange={(value:any)=>{
                                    setJobBoard(value)
                                }}
                            />
                        </div>
                        <div className={'flex-equal'}>
                            <SelectDropdownComponent
                                className={'job-board-select'}
                                placeholder={'Select Paid Job Board'}
                                value={paidJobBoard}
                                options={paidJobBoardList || []}
                                required={true}
                                size={"middle"}
                                mode={'multiple'}
                                displayWith={(option) => option.title}
                                valueExtractor={(option) => option.code}
                                onChange={(value:any)=>{
                                    setPaidJobBoard(value)
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
            <div className={'status-btn-wrapper'}>
                <Button
                    type={'primary'}
                    className="custom-primary-button"
                    onClick={handleUpdatePostingConfig}
                    loading={isSubmitting}
                >Update</Button>
                <Button className="custom-outline-button" onClick={handleCancel}>Cancel</Button>
            </div>
        </div>
    )
}

export default PostingConfigurationComponent