import "./TagJobComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import { CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import FormLabel from "@material-ui/core/FormLabel";
import {CssTextField} from "../../../../../constants/data/styles";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Lottie from "react-lottie";
import {Button} from "@material-ui/core";
import animationData from "../../../../../assets/animations/NoData.json";
import SelectDropdownComponent from "../../../../ant-component/select/SelectComponent";
import LoaderComponent from "../../../../../components/loader/LoaderComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../../store/reducers";

interface TagJobComponentProps{
    id:string[];
    cancel:()=>void;
    selectedRowData?:any;
    mode?:string;
    filterData?:any;
    setFilterData?:any;
    staff_type?:any;
}

const TagJobComponent=(props:TagJobComponentProps)=>{
    const {id,selectedRowData,mode,filterData,setFilterData,staff_type}=props
    const afterCancel = props?.cancel;
    const {hcpTypes}=useSelector((state:StateParams)=>state.meta)
    const [isJobListLoading,setIsJobListLoading]=useState<boolean>(false);
    const [jobList,setJobList]=useState<any>([])
    const [searchJob, setSearchJob] = useState<string>("");
    const [isSelectedCount, setIsSelectedCount] = useState<any>(-1)
    const [selectedJobs, setSelectedJobs] = useState<any>([]);
    const [selectedStaffType, setSelectedStaffType] = useState<any[]>( staff_type ? [staff_type] : []);
    // const [facilities,setFacilities]=useState<any[]>([]);
    // const [facilityList, setFacilityList] = useState<any | null>(null);
    // const [isFacilityLoading,setIsFacilityLoading]=useState<boolean>(false);
    const [userIds,setUserIds]=useState<any>([]);

    useEffect(() => {
        if(mode === 'list'){
            const updatedUserList=selectedRowData?.map((item:any)=>item?.user_id);
            setUserIds(updatedUserList)
        }
    }, [mode,selectedRowData]);

    // const getFacilityData = useCallback(() => {
    //     let payload: any = {};
    //     setIsFacilityLoading(true)
    //     ApiService.post(ENV.API_URL + "facility/lite", payload)
    //         .then((res) => {
    //             console.log(res?.data);
    //             //@ts-ignore
    //             const transformedData = res?.data?.map(({_id, name, ...rest}) => ({
    //                 code: _id,
    //                 title: name,
    //                 ...rest, // Include other properties if needed
    //             }));
    //             setFacilityList(transformedData || []);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         }).finally(()=>{
    //         setIsFacilityLoading(false)
    //     })
    // }, []);
    //
    // useEffect(() => {
    //     getFacilityData()
    // }, [getFacilityData]);

    const defaultOptions = {
        animationData,
    };

    const cancel = (resetForm: any) => {
        if (afterCancel) {
            afterCancel();
        }
    };

    const getJobList=useCallback(()=>{
            setIsJobListLoading(true);
            let payload:any={
                status:['open','in_progress'],
                limit:50,
            }
            if(searchJob!==""){
                payload.search=searchJob;
            }
            if(selectedStaffType?.length !== 0){
                payload.staff_types=selectedStaffType
            }
            // if(facilities?.length !== 0){
            //     payload.facilities=facilities
            // }
            CommonService._api.post(ENV.API_URL + 'ats/jobsList',payload).then((response:any)=>{
                const updatedJobList=response?.data?.docs?.map((item:any)=>{
                    const title = [
                        item?._id?.substr(-7),
                        item?.job_title,
                        item?.staff_type
                    ].filter(Boolean);

                    return {
                        _id:item?._id,
                        title:title?.join(' | ')
                    }
                });
                setJobList(updatedJobList)
                console.log('response',updatedJobList)
            }).catch((error:any)=>{
                console.log('error',error.error)
            }).finally(()=>{
                setIsJobListLoading(false)
            })
    },[searchJob,selectedStaffType])

    useEffect(() => {
        getJobList()
    }, [getJobList]);

    const isSelected = useCallback((_id: any) => {
        return selectedJobs?.indexOf(_id) !== -1;
    }, [selectedJobs]);

    const handleChange = (event: any) => {
        let index = selectedJobs.indexOf(event.target.value);
        let tempJobs = [];
        if (index > -1) {
            setIsSelectedCount(selectedJobs?.length - 1 === 0 ? -1 : selectedJobs?.length - 1)
            tempJobs = selectedJobs.filter((item: any) => item !== event.target.value);
        } else {
            setIsSelectedCount(selectedJobs?.length + 1)
            tempJobs = [...selectedJobs, event.target.value];
        }
        setSelectedJobs(tempJobs);
    };


    const handleAddJobsToStaff=useCallback(()=>{
        const payload:any={
            job_ids:selectedJobs,
            // user_ids:id
        }
        if(mode === 'view'){
            payload.user_ids=id;
        }else {
            payload.user_ids=userIds
        }
        CommonService._api.post(ENV.API_URL + 'ats/applyJobs',payload).then((response:any)=>{
            CommonService.showToast(response.message,"success")
            if(mode === 'list'){
                setFilterData({...filterData})
            }
            afterCancel();
        }).catch((error:any)=>{
            CommonService.showToast(error.error || 'Error',"error");
        })
    },[afterCancel,id,selectedJobs,mode,userIds,filterData,setFilterData])


    return (
        <div className={'add-staff-to-job-component'}>
            <FormLabel component="legend" className="mrg-left-0">
                List Of Jobs
            </FormLabel>
            <div className="add-staff-to-job-search-wrapper">
                <div className="d-flex position-relative">
                    <CssTextField
                        defaultValue={""}
                        onChange={(event) => {
                            setSearchJob(event?.target?.value);
                        }}
                        className="staff-search-field"
                        variant={"outlined"}
                        size={"small"}
                        type={"text"}
                        placeholder={"Search Job"}
                        value={searchJob}
                    />
                    {searchJob === "" ? (
                        <div className={"search_icon"}>
                            <SearchRounded/>
                        </div>
                    ) : (
                        <div className={"search_icon"}>
                            <ClearIcon onClick={(event) => setSearchJob("")} id="clear_hcp_search"/>
                        </div>
                    )}
                </div>
                <div className={'mrg-left-20 add-staff-filter'}>
                    <SelectDropdownComponent
                        value={selectedStaffType}
                        options={hcpTypes || []}
                        size={"large"}
                        mode={'multiple'}
                        placeholder={'Select Staff Type'}
                        displayWith={(option) => option.title}
                        valueExtractor={(option) => option.code}
                        onChange={(value:any)=>{
                            setSelectedStaffType(value)
                        }}
                    />
                </div>
                {/*<div className={'mrg-left-20 add-staff-filter'}>*/}
                {/*    <SelectDropdownComponent*/}
                {/*        value={facilities}*/}
                {/*        disabled={isFacilityLoading}*/}
                {/*        options={facilityList ||  []}*/}
                {/*        size={"large"}*/}
                {/*        mode={'multiple'}*/}
                {/*        placeholder={'Select Facility'}*/}
                {/*        displayWith={(option) => option.title}*/}
                {/*        valueExtractor={(option) => option.code}*/}
                {/*        onChange={(value: any) => {*/}
                {/*            setFacilities(value)*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <div className={'add-staff-to-job-list'}>
                {isJobListLoading ?
                    <div className={'basic-loading-wrapper'}>
                        <LoaderComponent type={'spinner'} size={'sm'} color={'primary'}/>
                    </div> :
                    <>
                        {jobList?.length === 0 ?
                            <div className={'basic-loading-wrapper'}>
                                <div>
                                    <Lottie width={250} height={250} speed={1} options={defaultOptions}/>
                                    <div style={{textAlign:'center',fontWeight:500,fontSize:'18px'}}>No Job Found.</div>
                                </div>
                            </div>:
                            <>
                                {jobList?.map((item: any) => {
                                    const isItemSelected = isSelected(item?._id)
                                    return (
                                        <div className={'add-staff-list-item'}>
                                            <FormControlLabel value={item?._id} checked={isItemSelected}
                                                              control={<Checkbox/>}
                                                              onChange={(event) => handleChange(event)}
                                                              label={item?.title}/>
                                        </div>
                                    );
                                })}
                            </>
                        }
                    </>
                }
            </div>
            <div className={'add-staff-btn-wrapper'}>
                <Button type={"submit"} size="large" variant={"outlined"}
                        className={"normal"} onClick={cancel}>
                    Cancel
                </Button>
                <Button type={"submit"} color={"primary"} size="large"
                        disabled={isSelectedCount === -1}
                        className={'mrg-left-20'} variant={"contained"}
                        onClick={handleAddJobsToStaff}>
                    Tag Job
                </Button>
            </div>
        </div>
    )
}

export default TagJobComponent