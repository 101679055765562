import "./PayRateTabComponent.scss";
import FormLabelComponent from "../../../../../../ant-component/form-label/FormLabelComponent";
import FormikInputComponent from "../../../../../../ant-component/formik-input-component/FormikInputComponent";
import React from "react";

const PayRateTabComponent=()=>{
    return (
        <div className={'permanent-pay-rate-component'}>
            <div className={'d-flex salary-range-wrapper'}>
                <div className={'d-flex flex-equal'}>
                    <div className={'mrg-top-5 mrg-right-20'}>
                        <FormLabelComponent Label={"Pay Rate / Year"}/>
                    </div>
                    <div className={'flex-equal'}>
                        <FormikInputComponent name="pay_rate.total_pay_rate" type={'number'}  size={'middle'}
                                              prefix={"$"}/>
                    </div>
                </div>
                <div className={'d-flex flex-equal mrg-left-20'}>
                    <div className={'mrg-top-5 mrg-right-10'}>
                        <FormLabelComponent Label={"Salary / Year"}/>
                    </div>
                    <div className={'flex-equal'}>
                        <FormikInputComponent name="pay_rate.salary" type={'number'} size={'middle'}
                                              prefix={"$"}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayRateTabComponent