import "./GeneratedInvoiceComponent.scss";
// import React, {useCallback, useEffect, useState} from "react";
// import {useLocalStorage} from "../../../../components/useLocalStorage";
// import GeneratedFilters from "./generated-filters/GeneratedFilters";
import GeneratedInvoiceListTable from "./generated-invoice-list-table/GeneratedInvoiceListTable";
// import {ApiService} from "../../../../helpers";
// import {ENV} from "../../../../constants";

interface GeneratedProps {
    value?: any;
}

const GeneratedInvoiceComponent = (props: GeneratedProps) => {


    return (
        <>
            <div className={"generate-invoice-component"}>
                <div className={'mrg-top-20 generated-invoice-view'}>
                    <GeneratedInvoiceListTable />
                </div>
            </div>
        </>
    );
};
export default GeneratedInvoiceComponent;