import React, {useCallback, useEffect, useState} from "react";
import "./EmailEditorComponent.scss";
import SelectComponent from "../../../../ant-component/select/SelectComponent";
import {ENV} from "../../../../../constants";
import {CommonService} from "../../../../../helpers";
import InputComponent from "../../../../ant-component/input/InputComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../../store/reducers";
import {Button} from "antd";
import {DeleteOutlined,CloseOutlined} from "@ant-design/icons";
import ChipInputComponent from "../../../../../components/chip-input/ChipInputComponent";
import FilePickerComponent from "../../../../../components/file-picker/FilePickerComponent";
import JoditEditorComponent from "../../../../../components/text-editor-v2/JoditEditorComponent";
import AccessControlComponent from "../../../../../components/AccessControl";
import {ADMIN, TERICADMIN} from "../../../../../helpers/common-service";


export const roles = [
    {code: "account_manager", title: "AM"},
    {code: "nurse_champion", title: "NC"},
    {code: "hr", title: "HR"},
    {code: 'operational_manager', title: "OM"},
    {code: 'finance', title: "Finance"},
    {code: 'super_admin', title: "Admin"},
];

interface EmailEditorComponentProps{
    selectedRole:string[];
    setSelectedRole:any;
    selectedStaffType:string[];
    setSelectedStaffType:any;
    roleEmailList:any;
    setRoleEmailList:any;
    selectedRoleEmails:string[];
    setSelectedRoleEmails:any;
    staffEmailList:any;
    setStaffEmailList:any;
    selectedStaffEmails:string[];
    setSelectedStaffEmails:any;
    manualEmails:string[];
    setManualEmails:any;
    ccEmails:string[];
    setCCEmails:any;
    bccEmails:string[];
    setBCCEmails:any;
    subject:string;
    setSubject:any;
    emailMessage:string;
    setEmailMessage:any;
    attachment:any;
    setAttachment:any;
    openJobListModal?:any;
    selectedJobsForEmail?:any;
    templateList?:any;
    selectedTemplate?:any;
    setSelectedTemplate?:any;
}


const EmailEditorComponent=(props:EmailEditorComponentProps)=>{
    const {
        selectedRole,
        setSelectedRole,
        selectedStaffType,
        setSelectedStaffType,
        roleEmailList,
        setRoleEmailList,
        selectedRoleEmails,
        setSelectedRoleEmails,
        staffEmailList,
        setStaffEmailList,
        selectedStaffEmails,
        setSelectedStaffEmails,
        manualEmails,
        setManualEmails,
        ccEmails,
        setCCEmails,
        bccEmails,
        setBCCEmails,
        subject,
        setSubject,
        emailMessage,
        setEmailMessage,
        attachment,
        setAttachment,
        openJobListModal,selectedJobsForEmail,
        selectedTemplate,
        setSelectedTemplate,
        templateList,
    } = props
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [selectedEmails,setSelectedEmails]=useState<string[]>([])
    const [showCC,setShowCC]=useState<boolean>(false);
    const [showBCC,setShowBCC]=useState<boolean>(false);
    const {user}=useSelector((state:StateParams)=>state.auth);

    useEffect(() => {
        if (selectedJobsForEmail) {
            // setEmailMessage((prevMessage: string) => prevMessage + selectedJobsForEmail);
            setEmailMessage(selectedJobsForEmail)
        }
    }, [selectedJobsForEmail, setEmailMessage]);

    const getEmailsBasedOnRole=useCallback((roles:string[])=>{
        const finalRoles = roles?.map((role: string) => `roles[]=${role}`).join('&');
        const url = `${ENV.API_URL}user/lite?${finalRoles}`;
        CommonService._api.get(url).then((response:any)=>{
            if(response?.data?.length === 0){
                CommonService.showToast(`No Emails for the selected roles`,'error')
                return;
            }
            const updatedData=response?.data?.map((data:any)=>({
                title:(data?.first_name || "") + " " + (data?.last_name || "") + " - [" + data.email +"]",
                code:data.email,
                _id:data._id
            }))
            setRoleEmailList(updatedData || [])
        }).catch((error:any)=>{
            CommonService.showToast(error.error || error.message || 'Error','error')
        })
    },[setRoleEmailList])

    const getEmailsBasedOnStaff=useCallback((staffType:string[])=>{
            let payload:any;
            let apiCall:any;
            if(user?.role === 'recruiter'){
                payload={
                    is_applicant:true,
                    hcp_types:staffType
                }
                apiCall= CommonService._api.post(ENV.API_URL + 'ats/applicantList',payload);
            }else{
                payload = {
                        is_approved:true,
                        hcp_types:staffType,
                    }
                apiCall= CommonService._api.post(ENV.API_URL + 'hcp/lite',payload);
            }
            apiCall
            .then((response:any)=>{
                const data=user?.role === 'recruiter' ? response?.data?.docs : response?.data
            if(data?.length === 0){
                CommonService.showToast(`No Emails for the selected profession`,'error')
                return;
            }
            const updatedData=data?.map((data:any)=>({
                title:(data?.first_name || "") + " " + (data?.last_name || "") + " - [" + data.email +"]",
                code:data.email,
                _id:data._id
            }))
            setStaffEmailList(updatedData || [])
        }).catch((error:any)=>{
            CommonService.showToast(error.error || error.message || 'Error','error')
        })
    },[setStaffEmailList,user?.role])

    useEffect(() => {
        if(selectedRoleEmails || selectedStaffEmails){
            const combinedEmails=[...selectedRoleEmails || [],...selectedStaffEmails|| []];
            setSelectedEmails(combinedEmails)
        }
    }, [selectedStaffEmails,selectedRoleEmails]);

    const handleClear=useCallback(()=>{
        setAttachment(null);
        setEmailMessage("");
        setSelectedRoleEmails([]);
        setSelectedStaffType("");
        setSelectedRole("");
        setSubject("");
        setRoleEmailList([]);
        setStaffEmailList([]);
        setSelectedStaffEmails([]);
        setManualEmails([]);
        setCCEmails([]);
        setBCCEmails([]);
        setSelectedTemplate('');
    },[setBCCEmails,setSelectedTemplate,setCCEmails,setAttachment,setRoleEmailList,setManualEmails,setEmailMessage,setSelectedRoleEmails,setSelectedRole,setSelectedStaffType,setSubject,setStaffEmailList,setSelectedStaffEmails])

    const handleCCOpenClose = () => {
        if (showCC) {
            setCCEmails([]);
        }
        setShowCC(!showCC);
    };

    const handleBCCOpenClose = () => {
        if (showBCC) {
            setBCCEmails([]);
        }
        setShowBCC(!showBCC);
    };

    const handleModalOpen=useCallback(()=>{
        if(openJobListModal){
            openJobListModal()
        }
    },[openJobListModal])


    return (
        <div className={'email-editor-component'}>
            <div className={'email-customize-body-wrapper'}>
                <div className={'email-editor-dropdown-wrapper'}>
                    <AccessControlComponent role={[ADMIN,TERICADMIN]}>
                        <div className={'email-editor-field'}>
                            <p className={'email-option-label'}>Role</p>
                            <SelectComponent
                                options={roles}
                                value={selectedRole}
                                placeholder={"Select"}
                                size={'large'}
                                allowClear={true}
                                mode={'multiple'}
                                onChange={(value: string[]) => {
                                    setSelectedRole(value);
                                    if (value && value?.length > 0) {
                                        getEmailsBasedOnRole(value);
                                    } else {
                                        setRoleEmailList([]);
                                        setSelectedRoleEmails([]);
                                    }
                                }}
                            />
                        </div>
                        <div className={'email-editor-field'}>
                            <p className={'email-option-label'}>Role Email</p>
                            <SelectComponent
                                options={roleEmailList || []}
                                disabled={roleEmailList?.length === 0}
                                value={selectedRoleEmails}
                                mode={'multiple'}
                                placeholder={"Select"}
                                size={'large'}
                                allowClear={true}
                                onChange={(value: string) => {
                                    setSelectedRoleEmails(value);
                                }}
                            />
                        </div>
                    </AccessControlComponent>
                    <div className={'email-editor-field'}>
                        <p className={'email-option-label'}>Profession</p>
                        <SelectComponent
                            options={hcpTypes}
                            value={selectedStaffType}
                            placeholder={"Select"}
                            size={'large'}
                            mode={'multiple'}
                            allowClear={true}
                            onChange={(value: string[]) => {
                                setSelectedStaffType(value);
                                if (value && value?.length > 0) {
                                    getEmailsBasedOnStaff(value);
                                } else {
                                    setStaffEmailList([]);
                                    setSelectedStaffEmails([]);
                                }
                            }}
                        />
                    </div>
                    <div className={'email-editor-field'}>
                        <p className={'email-option-label'}>Profession Email</p>
                        <SelectComponent
                            options={staffEmailList || []}
                            disabled={staffEmailList?.length === 0}
                            value={selectedStaffEmails}
                            mode={'multiple'}
                            placeholder={"Select"}
                            size={'large'}
                            allowClear={true}
                            onChange={(value: string) => {
                                setSelectedStaffEmails(value);
                            }}
                        />
                    </div>
                    <div className={'email-editor-field'}>
                        <p className={'email-option-label'}>Template</p>
                        <SelectComponent
                            options={templateList || []}
                            value={selectedTemplate}
                            placeholder={"Select"}
                            size={'large'}
                            allowClear={true}
                            displayWith={(option:any)=>option.title}
                            valueExtractor={(option:any)=>option.code}
                            onChange={(value: any) => {
                                const template = value
                                    ? templateList?.find((t:any) => t.code === value)
                                    : null;
                                setSelectedTemplate(value);
                                setEmailMessage(template?.message || '');
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={'email-editor-wrapper'}>
                <div className={'email-editor'}>
                    <ChipInputComponent
                        placeholder={'Recipients'}
                        setSelectedEmails={setSelectedEmails}
                        selectedEmails={selectedEmails}
                        manualEmails={manualEmails}
                        setManualEmails={setManualEmails}
                        title={'Recipients'}
                        showCC={showCC}
                        showBCC={showBCC}
                        handleCCToggle={handleCCOpenClose}
                        handleBCCToggle={handleBCCOpenClose}
                        selectedRoleEmails={selectedRoleEmails}
                        setSelectedRoleEmails={setSelectedRoleEmails}
                        selectedStaffEmails={selectedStaffEmails}
                        setSelectedStaffEmails={setSelectedStaffEmails}
                    />
                    {showCC &&
                        <ChipInputComponent
                            placeholder={'Cc'}
                            manualEmails={ccEmails}
                            setManualEmails={setCCEmails}
                            title={"Cc"}
                        />
                    }
                    {showBCC &&
                        <ChipInputComponent
                            placeholder={'Bcc'}
                            manualEmails={bccEmails}
                            setManualEmails={setBCCEmails}
                            title={'Bcc'}
                        />
                    }
                    <div className={'email-subject-input-wrapper'}>
                        <InputComponent
                            placeholder={'Subject'}
                            value={subject}
                            className={'email-subject-input'}
                            onChange={(value: string) => {
                                setSubject(value)
                            }}
                        />
                        <div className={'email-subject-tag-wrapper'} onClick={handleModalOpen}>
                            Tag Job
                        </div>
                    </div>
                    <div className={'email-quill-wrapper'}>
                        {/*<TextEditorComponent*/}
                        {/*    value={emailMessage}*/}
                        {/*    onChange={(value: any) => {*/}
                        {/*        setEmailMessage(value)*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <JoditEditorComponent value={emailMessage}
                                              onChange={(value: any) => {
                                                  setEmailMessage(value)
                                              }}/>
                    </div>
                    <div className={'email-attachment-wrapper'}>
                        <div>
                            {!attachment &&
                            <FilePickerComponent
                                                variant={'outlined'}
                                                 height={'470px'}
                                                 id={" sc_upload_btn"}
                                                 btnText={" Add Attachment"}
                                                 multiple={false}
                                                 showDropZone={false}
                                                 onFilesDrop={(acceptedFiles: any, rejectedFiles: any) => {
                                                     if (acceptedFiles && acceptedFiles.length > 0) {
                                                         const file = acceptedFiles[0];
                                                         //   setTimesheetFile(file);
                                                         setAttachment(file);
                                                     }
                                                 }}
                                                 acceptedFileTypes={["pdf", "jpeg", "jpg", "png", "doc", "docx"]}
                                                 uploadDescription={"(upload only one file)"}
                            />}
                            {attachment &&
                                <div className={'file-preview-wrapper'}>
                                    {attachment?.name}
                                    <div className={'file-preview-close-icon'} onClick={()=>setAttachment(null)}>
                                        <CloseOutlined/>
                                    </div>
                                </div>
                            }
                        </div>
                        <Button onClick={handleClear}
                                icon={<DeleteOutlined/>}
                                iconPosition={'start'}
                                className={'delete-btn'}
                        >
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EmailEditorComponent;