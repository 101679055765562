import React, {useEffect} from "react";
import {BrowserRouter, matchPath, Redirect, Route, Switch, useHistory} from "react-router-dom";
import NotFoundComponent from "../components/NotFoundComponent";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../store/reducers";
import CommonService from "../helpers/common-service";
import {Communications} from "../helpers";
import {logoutUser} from "../store/actions/auth.action";
import LoginScreen from "../app/auth/login/LoginScreen";
import HcpManagementListScreen from "../app/hcpManagement/list/HcpManagementListScreen";
import DashboardScreen from "../app/dashboard/DashboardScreen";
import HcpManagementViewScreen from "../app/hcpManagement/view/HcpManagementViewScreen";
import FacilityManagementListScreen from "../app/facilityManagement/list/FacilityManagementListScreen";
import FacilityManagementAddScreen from "../app/facilityManagement/add/FacilityManagementAddScreen";
import AddHcpComponent from "../app/hcpManagement/add/AddHcpComponent";
import FacilityManagementViewScreen from "../app/facilityManagement/view/FacilityManagementViewScreen";
import AddShiftsScreen from "../app/shiftManagement/add/AddShiftsScreen";
import PendingShiftsListScreen from "../app/shiftManagement/pending/list/PendingShiftsListScreen";
import ApprovedShiftsListScreen from "../app/shiftManagement/approved/list/ApprovedShiftsListScreen";
import CompletedShiftsListScreen from "../app/shiftManagement/completed/list/CompletedShiftsListScreen";
import ClosedShiftsListScreen from "../app/shiftManagement/closed/list/ClosedShiftsListScreen";
import CancelledShiftsListScreen from "../app/shiftManagement/cancelled/list/CancelledShiftsListScreen";
import ShiftsMasterListScreen from "../app/shiftManagement/shiftMaster/list/ShiftsMasterListScreen";
import EditHcpComponent from "../app/hcpManagement/edit/EditHcpComponent";
import FacilityManagementEditScreen from "../app/facilityManagement/edit/FacilityManagementEditScreen";
import GroupListScreen from "../app/communication/group/list/GroupListScreen";
import AddGroupScreen from "../app/communication/group/add/AddGroupScreen";
import GroupViewScreen from "../app/communication/group/view/GroupViewScreen";
import RemoveHcpsScreen from "../app/communication/group/removeHcps/RemoveHcpsScreen";
import AddHcpToExistingGroupScreen from "../app/communication/group/addHcptoExistingGroup/AddHcpToExistingGroupScreen";
import HcpUserViewScreen from "../app/hcpManagement/approvedhcpview/HcpManagementViewScreen";
import ShiftCompletedViewScreen from "../app/shiftManagement/completed/view/ShiftCompletedViewScreen";
import RequirementsShiftsViewScreen from "../app/shiftManagement/open/view/RequirementsShiftsViewScreen";
import ShiftInprogressListScreen from '../app/shiftManagement/inprogress/list/ShiftInprogressListScreen';
import SendSmsBlastScreen from "../app/communication/smsBlast/SendSmsBlastScreen";
import ShiftRequirementListScreen from "../app/shiftManagement/open/list/openShiftsListScreen";
import ApprovedShiftsViewScreen from "../app/shiftManagement/approved/view/ApprovedShiftsViewScreen";
import ShiftInprogressViewScreen from "../app/shiftManagement/inprogress/view/ShiftInprogressViewScreen";
import ShiftMasterViewScreen from "../app/shiftManagement/shiftMaster/view/ShiftMasterViewScreen";
import ClosedShiftsViewScreen from '../app/shiftManagement/closed/view/ClosedShiftsViewScreen';
import CancelledShiftsViewScreen from '../app/shiftManagement/cancelled/view/CancelledShiftsViewScreen';
import HcpApprovedListScreen from "../app/hcpManagement/approvedHcpsList/HcpApprovedListScreen";
import AppLayout from "../app/layout/app-layout/app-layout";
import AuthLayout from "../app/layout/auth-layout/auth-layout";
import ForgotPasswordScreen from "../app/auth/forgotPassword/ForgotPasswordScreen";
import EmployeeListScreen from "../app/employeeManagement/list/EmployeeListScreen";
import EmployeeViewScreen from "../app/employeeManagement/view/EmployeeViewScreen";
import EmployeeAddScreen from "../app/employeeManagement/add/EmployeeAddScreen";
import EmployeeEditScreen from "../app/employeeManagement/edit/EmployeeEditScreen";
import AccountingModuleComponent from "../app/accounting/accounting-module/AccountingModuleComponent";
import GeneratedInvoiceViewDetails
    from "../app/accounting/accounting-module/invoice-generated/view-details-for-generated-invoice/GeneratedInvoiceViewDetails";
import EditGeneratedInvoiceDetails
    from "../app/accounting/accounting-module/invoice-generated/edit-generated-invoice-details/EditGeneratedInvoiceDetails";

import BulkUploadComponent from "../app/shiftManagement/open/bulkUpload/BulkUploadComponent";
import AwaitingShiftsListScreen from "../app/shiftManagement/awaiting-shift/list/PendingShiftsListScreen";
import AddLayout from "../app/layout/add-layout/add-layout";
import JobListComponent from "../app/ats/Job/Job-list/JobListComponent";
import AddJobComponent from "../app/ats/Job/add-job/AddJobComponent";
import JobViewDetailsComponent from "../app/ats/Job/job-view-details/JobViewDetailsComponent";
import ApplicantListComponent from "../app/ats/applicants/applicant-list/ApplicantListComponent";
import SendEmailComponent from "../app/ats/applicants/send-email/SendEmailComponent";
import JobBulkUploadComponent from "../app/ats/Job/job-bulk-upload/JobBulkUploadComponent";
import EmailDocumentComponent from "../app/ats/Job/email-document/EmailDocumentComponent";
import JobEditComponent from "../app/ats/Job/job-edit/JobEditComponent";
import MyPipeLineComponent from "../app/ats/my-pipeline/MyPipeLineComponent";
import ApplicantBulkUploadComponent from "../app/ats/applicants/applicant-bulk-upload/ApplicantBulkUploadComponent";
import AddApplicantComponent from "../app/ats/applicants/applicant-add/AddApplicantComponent";
import EditApplicantComponent from "../app/ats/applicants/applicant-edit/EditApplicantComponent";
import ApplicantViewComponent from "../app/ats/applicants/applicant-view/ApplicantViewComponent";
import DashboardGraphComponent from "../app/analytics/dashboard-graph/DashboardGraphComponent";
import ChatComponent from "../app/communication/chat/ChatComponent";
import SettingsComponent from "../app/settings/SettingsComponent";

// @ts-ignore
const AuthLayoutRoute = ({component: Component, ...rest}) => {
    let doneUrl = "/dashboard";
    let hrUrl = "/hcp/user/list";
    const {token, user} = useSelector((state: StateParams) => state.auth);
    console.log(user);
    if (!!token) {
        if (user?.role === "operational_manager") {
            return <Redirect to={hrUrl}/>;
        } else {
            const query = CommonService.parseQueryString(rest.location.search);
            console.log(query);
            if (query.hasOwnProperty("done")) {
                doneUrl = query.done;
            }
            return <Redirect to={doneUrl}/>;
        }
    }
    return (
        <Route
            {...rest}
            render={(matchProps) => (
                <AuthLayout>
                    <Component {...matchProps} />
                </AuthLayout>
            )}
        />
    );
};

// @ts-ignore
const AppLayoutRoute = ({component: Component, ...rest}) => {
    // const query = new URLSearchParams(rest.location.search);
    // console.log(rest, 'AppLayoutRoute');
    const {token} = useSelector((state: StateParams) => state.auth);
    if (!!token) {
        return (
            <Route
                {...rest}
                render={(matchProps) => (
                    <AppLayout>
                        <Component {...matchProps} />
                    </AppLayout>
                )}
            />
        );
    }
    return (
        <Redirect
            to={"/login?done=" + encodeURIComponent(rest.location.pathname)}
        />
    );
};

//@ts-ignore
const AddLayoutRoute = ({component: Component, ...rest}) => {
    // const query = new URLSearchParams(rest.location.search);
    // console.log(rest, 'AppLayoutRoute');
    const allowedRoutesWithoutAuth = [
        "/ats_job/:jobId/hcp/:hcpId",
        "/hcp/hcp_docs/:hcpId"
    ];

    const isAllowedRoute = allowedRoutesWithoutAuth.some(route =>
        matchPath(rest.path, { path: route, exact: true })
    );
    const {token} = useSelector((state: StateParams) => state.auth);
    if (!!token || isAllowedRoute) {
        return (
            <Route
                {...rest}
                render={(matchProps) => (
                    <AddLayout>
                        <Component {...matchProps} />
                    </AddLayout>
                )}
            />
        );
    }
    return (
        <Redirect
            to={"/login?done=" + encodeURIComponent(rest.location.pathname)}
        />
    );
}

const Navigator = (props: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {user} = useSelector((state: StateParams) => state.auth);
    useEffect(() => {
        const logoutSubjectSub = Communications.logoutSubject.subscribe(() => {
            dispatch(logoutUser());
            history.push("/login");
        });
        const reloadSubjectSub = Communications.ReloadStateSubject.subscribe(() => {
            if (user?.role === "operational_manager") {
                history.push("/hcp/list");
            } else {
                history.push("/dashboard");
            }
        });
        return () => {
            logoutSubjectSub.unsubscribe();
            reloadSubjectSub.unsubscribe();
        };
    }, [dispatch, history, user]);
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to={"/dashboard"}/>
            </Route>

            <AuthLayoutRoute path="/login" component={LoginScreen}/>
            <AuthLayoutRoute path="/forgot-password" component={ForgotPasswordScreen}/>
            <AppLayoutRoute path="/dashboard" component={DashboardScreen}/>
            <AppLayoutRoute path="/hcp/list" component={HcpManagementListScreen}/>
            <AppLayoutRoute path="/hcp/user/list" component={HcpApprovedListScreen}/>
            <AppLayoutRoute path="/hcp/view/:id" component={HcpManagementViewScreen}/>
            <AppLayoutRoute path="/hcp/user/view/:id" component={HcpUserViewScreen}/>
            <AddLayoutRoute path="/hcp/hcp_docs/:hcpId" component={EmailDocumentComponent}/>
            <AppLayoutRoute path="/facility/list" component={FacilityManagementListScreen}/>
            <AppLayoutRoute path="/facility/view/:id" component={FacilityManagementViewScreen}/>
            <AddLayoutRoute path="/shift/add" component={AddShiftsScreen}/>
            <AppLayoutRoute path="/open-shift/BulkUpload" component={BulkUploadComponent}/>
            <AppLayoutRoute path="/approvedShifts/list" component={ApprovedShiftsListScreen}/>
            <AppLayoutRoute path="/approvedShifts/view/:id" component={ApprovedShiftsViewScreen}/>
            <AppLayoutRoute path="/shiftrequirementMaster/list" component={ShiftRequirementListScreen}/>
            <AppLayoutRoute path="/inprogessShifts/list" component={ShiftInprogressListScreen}/>
            <AppLayoutRoute path="/inprogessShifts/view/:id" component={ShiftInprogressViewScreen}/>
            <AppLayoutRoute path="/shiftMaster/view/:id" component={ShiftMasterViewScreen}/>
            <AppLayoutRoute path="/shiftsRequirements/view/:id" component={RequirementsShiftsViewScreen}/>
            <AppLayoutRoute path="/completedShifts/list" component={CompletedShiftsListScreen}/>
            <AppLayoutRoute path="/completedShifts/view/:id" component={ShiftCompletedViewScreen}/>
            <AppLayoutRoute path="/closedShifts/list" component={ClosedShiftsListScreen}/>
            <AppLayoutRoute path="/cancelledShifts/list" component={CancelledShiftsListScreen}/>
            <AppLayoutRoute path="/pendingShifts/list" component={PendingShiftsListScreen}/>
            <AppLayoutRoute path="/awaitingShifts/list" component={AwaitingShiftsListScreen}/>
            <AppLayoutRoute path="/closedShifts/view/:id" component={ClosedShiftsViewScreen}/>
            <AppLayoutRoute path="/cancelledShifts/view/:id" component={CancelledShiftsViewScreen}/>
            <AppLayoutRoute path="/shiftMaster/list" component={ShiftsMasterListScreen}/>
            <AppLayoutRoute path="/group/add" component={AddGroupScreen}/>
            <AppLayoutRoute path="/group/list" component={GroupListScreen}/>
            <AppLayoutRoute path="/group/view/:id" component={GroupViewScreen}/>
            <AppLayoutRoute path="/group/remove/:id" component={RemoveHcpsScreen}/>
            <AppLayoutRoute path="/group/edit/member/:id" component={AddHcpToExistingGroupScreen}/>
            <AppLayoutRoute path="/sendSmsBlast" component={SendSmsBlastScreen}/>
            <AppLayoutRoute path="/employee/list" component={EmployeeListScreen}/>
            <AddLayoutRoute path="/employee/add" component={EmployeeAddScreen}/>
            <AddLayoutRoute path="/employee/edit/:id" component={EmployeeEditScreen}/>
            <AppLayoutRoute path="/employee/view/:id" component={EmployeeViewScreen}/>
            {/*<AppLayoutRoute path="/maintenance/notification" component={NotificationComponent}/>*/}
            <AppLayoutRoute path="/accounting" component={AccountingModuleComponent}/>
            <AppLayoutRoute path="/generatedInvoiceViewDetails/:id" component={GeneratedInvoiceViewDetails}/>
            <AppLayoutRoute path="/EditGeneratedInvoiceViewDetails/:id" component={EditGeneratedInvoiceDetails}/>
            <AddLayoutRoute path="/facility/add" component={FacilityManagementAddScreen}/>
            <AddLayoutRoute path="/job/add" component={AddJobComponent}/>
            <AddLayoutRoute path="/jobs/view/:id" component={JobViewDetailsComponent}/>
            <AddLayoutRoute path="/facility/edit/:id" component={FacilityManagementEditScreen}/>
            <AddLayoutRoute path="/job/edit/:id" component={JobEditComponent}/>
            <AddLayoutRoute path="/hcp/add" component={AddHcpComponent}/>
            <AddLayoutRoute path="/hcp/edit/:id" component={EditHcpComponent}/>
            <AppLayoutRoute path="/open-shift/BulkUpload" component={BulkUploadComponent}/>
            <AppLayoutRoute path="/job" component={JobListComponent}/>
            <AppLayoutRoute path="/ats_job/bulk_upload" component={JobBulkUploadComponent}/>
            <AppLayoutRoute path="/applicants" component={ApplicantListComponent}/>
            <AddLayoutRoute path="/bulk-email" component={SendEmailComponent}/>
            <AddLayoutRoute path="/ats_job/:jobId/hcp/:hcpId" component={EmailDocumentComponent}/>
            <AppLayoutRoute path="/pipeline" component={MyPipeLineComponent}/>
            <AppLayoutRoute path="/applicant/bulk_upload" component={ApplicantBulkUploadComponent}/>
            <AddLayoutRoute path="/applicant/add" component={AddApplicantComponent}/>
            <AddLayoutRoute path="/applicant/edit/:id" component={EditApplicantComponent}/>
            <AppLayoutRoute path="/applicant/view/:id" component={ApplicantViewComponent}/>
            <AppLayoutRoute path="/dashboard-graph" component={DashboardGraphComponent}/>
            <AppLayoutRoute path="/chat" component={ChatComponent}/>
            <AppLayoutRoute path="/settings" component={SettingsComponent}/>

            <Route path="/not-found">
                <NotFoundComponent/>
            </Route>
            <Route path="*">
                <Redirect to={"/not-found"}/>
            </Route>
        </Switch>
    );
};

const MainRouter = () => {
    return (
        <BrowserRouter>
            <Navigator/>
        </BrowserRouter>
    );
};

export default MainRouter;
