import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import {ENV} from "../../../../constants";
import {
    Button,
    Tooltip,
} from "@material-ui/core";
import DialogComponent from "../../../../components/DialogComponent";
import FilePickerComponent from "../../../../components/file-picker/FilePickerComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {TableColumnsType, Typography as Text} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import "./ApplicantBulkUploadComponent.scss";

const ApplicantBulkUploadComponent=()=>{
    const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
    const [isBulkUploadDialogOpen, setIsBulkUploadDialogOpen] = useState<boolean>(false);
    const [shiftUploadFile, setShiftUploadFile] = React.useState<any>([]);
    const [filterData, setFilterData] = useState<any>({
        search: '',
        sort: {},
    })
    const [uploadData, setUploadData] = useState([]);

    useEffect(() => {
        if (uploadData?.length > 0) {
            Communications.pageTitleSubject.next("Staff Bulk Upload / Invalid Entries");
        } else {
            Communications.pageTitleSubject.next("Staff Bulk Upload");
        }
        Communications.pageBackButtonSubject.next("/applicants");
    }, [uploadData]);

    const handleDownload = useCallback(() => {
        let fileUrl = "https://s3.us-east-2.amazonaws.com/bulkupload.template.com/bulkupload/hcp_upload_template.xlsx";
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'applicant_upload_template.xlsx'; // Optional: specify a filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, []);

    const handleParticularDataDownload = useCallback((key: any) => {
        let payload: any = {};
        payload.file_key = key;
        ApiService.post(ENV.API_URL + "downloadAttachment", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                } else {
                    CommonService.showToast(res?.error || "No Data to Download", "info");
                }
            })
            .catch((err) => {
                CommonService.showToast(err?.error || "Error", "error");
            });
    }, [])


    const removeFile = (index: number) => {
        // Create a new array excluding the file at the given index
        const newTimesheetFile = [...shiftUploadFile];
        newTimesheetFile.splice(index, 1);
        // Update the timesheetFile state with the new array
        setShiftUploadFile([]);
    };


    const onAddBulkFileUpload = useCallback(() => {
        setIsFileUploading(true)
        let url: any = "hcp/bulkUpload"
        const formData = new FormData();

        if (shiftUploadFile?.length > 0) {
            shiftUploadFile.forEach((file: File) => {
                // Ensure each file is appended correctly
                formData.append("attachment", file);
            });
        } else {
            console.error("No files to upload");
            return;  // Early return if no files are uploaded
        }

        ApiService.upload(ENV.API_URL + url, formData)
            .then((res) => {
                CommonService.showToast(res?.message, "info");
                setIsBulkUploadDialogOpen(false)
                setFilterData({
                    ...filterData,
                })
                setShiftUploadFile([])
                setUploadData([])
                setIsFileUploading(false)
            })
            .catch((err) => {
                console.log(err?.data)
                setIsBulkUploadDialogOpen(false);
                setUploadData(err?.data || [])
                setShiftUploadFile([])
                setIsFileUploading(false)
                CommonService.showToast(err?.error?.message || "Error", "error");
            });
    }, [shiftUploadFile, filterData])


    const bulkUploadColumns: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Uploaded By',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            {item?.uploaded_by?.first_name + " " + item?.uploaded_by?.last_name}
                        </div>
                    )
                }
            },
            {
                title: 'Uploaded On',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.formatDateTime(item?.updated_at) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Action',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            <Button variant={"outlined"}
                                    color="primary"
                                    onClick={() => {
                                        handleParticularDataDownload(item?.s3_path);
                                    }}
                                //  className={!isDownloading ? "" : "has-loading-spinner"}
                                //   disabled={isDownloading}
                            >
                                &nbsp;Download
                            </Button>
                        </div>
                    )
                }
            },

        ]
    }, [handleParticularDataDownload])


    const jobListColumn: any = [
        {
            title: "Errors",
            dataIndex: "errors",
            key: "errors",
            render: (errors: string[]) =>
                errors && errors?.length > 0 ? (
                    <Tooltip
                        title={errors?.map((error, index) => (
                            <div key={index}>
                                <div color="red">{error}</div>
                            </div>
                        ))}
                    >
                        <ExclamationCircleOutlined style={{color: "red", fontSize: "16px",marginLeft:"20px"}}/>
                    </Tooltip>
                ) : null,
            fixed: "left",
            width: 80,
        },
        {
            title: "First Name",
            dataIndex: ["row", "First Name"],
            key: "first_name",
            width: 200,
            render: (value: string) =>
                value ?
                    <div>
                        {value?.length > 20 ?
                            <Tooltip title={value}>
                                <div className={'ellipses-for-table-data'}>
                                    {value}
                                </div>
                            </Tooltip>:
                            <div>
                                {value}
                            </div>
                        }
                    </div>
                    :
                    <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Last Name",
            dataIndex: ["row", "Last Name"],
            key: "last_name",
            width: 200,
            render: (value: string) =>
                value ? <div>
                    {value?.length > 20 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip> :
                        <div>
                            {value}
                        </div>
                    }
                </div> : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Email",
            dataIndex: ["row", "Email"],
            key: "email",
            width: 150,
            render: (value: string) =>
                value ? <div>
                    {value?.length > 15 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip> :
                        <div>
                            {value}
                        </div>
                    }
                </div> : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Phone Number",
            dataIndex: ["row", "Phone Number"],
            key: "phone_number",
            width: 150,
            render: (value: string) =>
                value ? <div>
                    {value?.length > 20 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip> :
                        <div>
                            {value}
                        </div>
                    }
                </div> : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Staff Type",
            dataIndex: ["row", "Staff Type"],
            key: "staff_type",
            width: 200,
            render: (value: string) =>
                value ? <div>
                    {value?.length > 20 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip> :
                        <div>
                            {value}
                        </div>
                    }
                </div> : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Gender",
            dataIndex: ["row", "Gender"],
            key: "gender",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Street",
            dataIndex: ["row", "Street"],
            key: "street",
            width: 200,
            render: (value: string) =>
                value ? <div>
                    {value?.length > 20 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip> :
                        <div>
                            {value}
                        </div>
                    }
                </div> : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "City",
            dataIndex: ["row", "City"],
            key: "city",
            width: 200,
            render: (value: string) =>
                value ? <div>
                    {value?.length > 20 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip> :
                        <div>
                            {value}
                        </div>
                    }
                </div> : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "State",
            dataIndex: ["row", "State"],
            key: "state",
            width: 120,
            render: (value: string) =>
                value ? <div>
                    {value?.length > 20 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip> :
                        <div>
                            {value}
                        </div>
                    }
                </div> : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "DOB",
            dataIndex: ["row", "DOB"],
            key: "dob",
            width: 120,
            render: (value: string) =>{
                const date = new Date(value);
                const formattedDate = date.toLocaleDateString("en-US");
                return (
                    value ? formattedDate: <Text style={{color: "red"}}>Missing</Text>
                )
            }
        },
        {
            title: "Latitude",
            dataIndex: ["row", "Latitude"],
            key: "latitude",
            width: 150,
            render: (value: string) =>
                value ? <div>
                    {value?.length > 20 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip> :
                        <div>
                            {value}
                        </div>
                    }
                </div> : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Longitude",
            dataIndex: ["row", "Longitude"],
            key: "longitude",
            width: 150,
            render: (value: string) =>
                value ? <div>
                    {value?.length > 20 ?
                        <Tooltip title={value}>
                            <div className={'ellipses-for-table-data'}>
                                {value}
                            </div>
                        </Tooltip> :
                        <div>
                            {value}
                        </div>
                    }
                </div> : <Text style={{color: "red"}}>Missing</Text>,
        },
    ];


    return (
        <div className={'applicant-bulk-upload-component'}>
            <DialogComponent isEditableDialog={true} open={isBulkUploadDialogOpen} cancel={() => {
                setIsBulkUploadDialogOpen(false)
            }}>
                <div className={'mrg-20 bulk-upload-dialog'}>
                    <div className={'form-header-text mrg-bottom-20'}>Bulk Upload</div>
                    {shiftUploadFile?.length === 0 &&
                        <FilePickerComponent
                            height={'470px'}
                            id={" sc_upload_btn"}
                            multiple={false}
                            isBulkUpload={true}
                            showDropZone={false}
                            onFilesDrop={(acceptedFiles: any, rejectedFiles: any) => {
                                if (acceptedFiles && acceptedFiles.length > 0) {
                                    const file = acceptedFiles[0];
                                    setShiftUploadFile([...shiftUploadFile, file]);
                                }
                            }}
                            acceptedFileTypes={["xlsx"]}
                            acceptedFilesText={"Only .xlsx files are supported"}
                        />

                    }

                    <div className={'d-flex mrg-top-10 mrg-bottom-20'}>
                        {shiftUploadFile && shiftUploadFile?.map((file: any, index: number) => {
                            return (
                                <>
                                    <div
                                        className={'d-flex justify-content-space-between file-holder-wrapper mrg-right-10 align-items-center '}>
                                        <div>  {file?.name}</div>
                                        <div className={'cursor-pointer'}
                                             onClick={() => {
                                                 removeFile(index);
                                             }}>
                                            <DeleteIcon/>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                    <div className={'d-flex justify-content-center'}>
                        <div className="actions">
                            <Button variant={"outlined"} color="primary"
                                    onClick={() => {
                                        setIsBulkUploadDialogOpen(false)
                                    }}
                            >
                                &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div className="mrg-left-10">
                            <Button
                                onClick={onAddBulkFileUpload}
                                disabled={shiftUploadFile?.length === 0 || isFileUploading}
                                className={!isFileUploading ? "" : "has-loading-spinner"}
                                // disabled={isDownloading}
                                variant={"contained"}
                                color={"primary"}>
                                &nbsp;&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp;
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogComponent>
            <div className={"facility-list screen crud-layout pdd-30 pdd-top-10"}>
                <div className="custom-border pdd-10 pdd-top-20 pdd-bottom-0">
                    <div className="job-header">
                        <div>
                            <Button variant={"outlined"} color="primary"
                                    onClick={handleDownload}
                            >
                                &nbsp;
                                Download Staff Template
                            </Button>
                        </div>
                        <div className="mrg-left-10">
                            <Button
                                onClick={() => {
                                    setIsBulkUploadDialogOpen(true);
                                }}
                                variant={"contained"}
                                color={"primary"}>
                                &nbsp;&nbsp;{uploadData?.length === 0 ? "Upload File" : "Re-Upload File"}&nbsp;&nbsp;
                            </Button>
                        </div>
                    </div>
                    {uploadData?.length === 0 &&
                        <div className={'facility-table-component mrg-top-20'}>
                            <AntTableComponent
                                isBordered={true}
                                url={ENV.API_URL + "hcp/uploadLogslist"}
                                method={'post'}
                                extraPayload={filterData}
                                columns={bulkUploadColumns}
                                noDataText={'No Data Found.'}
                            />
                        </div>
                    }
                    {uploadData?.length !== 0 &&
                        <div className={'facility-table-component mrg-top-20'}>
                            <AntTableComponent
                                isBordered={true}
                                data={uploadData?.map((item: any, index) => ({...item, key: index}))}
                                columns={jobListColumn}
                                extraPayload={uploadData}
                                noDataText={'No Data Found.'}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ApplicantBulkUploadComponent