import {Button, IconButton, MenuItem, Tooltip} from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import { DatePicker } from "formik-material-ui-pickers";
import moment from "moment";
import { nanoid } from "nanoid";
import React, {useMemo, useState,useCallback} from "react";
import { acknowledgement } from "../../../../../constants/data";
import { CommonService } from "../../../../../helpers";
import "./ExperienceAddComponent.scss";
import { experienceValidation } from "./ExperienceValidation";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";
import {TableColumnsType} from "antd";
import DeleteIcon from "@material-ui/icons/Delete";

type ExperienceAddComponentProps = {
  handleCalcSpecialities: any;
  handleCalcExperience: any;
  hcpTypeSpecialities: any;
  hcpTypes: any;
  experiences: any;
  setExperience: any;
  handleHcpTypeChange?: any;
};

interface ExperienceItem {
  facilityName: string;
  speciality: string;
  hcpType: string;
  location: string;
  startDate: any;
  endDate: any;
  stillWorkingHere: any;
  skills: any;
}

const experienceInitialState: ExperienceItem = {
  facilityName: "",
  speciality: "",
  hcpType: "",
  location: "",
  startDate: null,
  endDate: null,
  stillWorkingHere: "",
  skills: "",
};

const ExperienceAddComponent = ({ handleCalcSpecialities, handleCalcExperience, hcpTypeSpecialities, hcpTypes, experiences, setExperience, handleHcpTypeChange }: ExperienceAddComponentProps) => {
  const [isExperiences, setIsExperiences] = useState<boolean>(false);
  const [showEndDate, setShowEndDate] = useState<boolean>(true);

  const onAdd = (experience: ExperienceItem, { setSubmitting, setErrors, resetForm }: FormikHelpers<ExperienceItem>) => {
    const newExperience = {
      tempId: nanoid(),
      facility_name: experience.facilityName,
      specialisation: experience.speciality,
      unit: experience.speciality,
      location: experience.location,
      start_date: experience.startDate ? moment(experience.startDate).format("YYYY-MM") : "",
      end_date: experience.endDate ? moment(experience.endDate).format("YYYY-MM") : "",
      exp_type: "fulltime",
      position_title: experience.hcpType,
      still_working_here: experience.stillWorkingHere,
      skills: experience.skills,
    };

    const newExperiences = [...experiences, newExperience];
    setExperience(newExperiences);

    resetForm();
    handleCancelShift();
    handleCalcExperience(newExperiences);
    handleCalcSpecialities(newExperiences);
    CommonService.showToast("Staff experience added", "info");
  };

  const handleCancelShift = () => {
    setIsExperiences(false);
  };

  const handleDeleteClick = useCallback((experienceId: string) => {
    const newExperiences = [...experiences];
    const index = experiences.findIndex((experience: any) => experience.tempId === experienceId);
    newExperiences.splice(index, 1);
    setExperience(newExperiences);
    handleCalcExperience(newExperiences);
    handleCalcSpecialities(newExperiences);
    CommonService.showToast("Staff experience deleted", "success");
  },[experiences,setExperience,handleCalcExperience,handleCalcSpecialities])

  const sortedExpData = CommonService.sortDatesByLatest(experiences, "start_date");

  const showDropDownBelowField = {
    MenuProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    },
  };

  const ExperienceListColumn:TableColumnsType=useMemo(()=>{
    return [
      {
        title:'Facility Name',
        width:250,
        dataIndex:'facility_name',
        key:'facility_name',
        render:(item:any)=>{
          return (
              <div>
                {item?.length > 20 ?
                    <Tooltip title={item}>
                      <div className={'ellipses-for-table-data'}>
                        {item || 'N/A'}
                      </div>
                    </Tooltip>:
                    <div>
                      {item || 'N/A'}
                    </div>
                }
              </div>
          )
        }
      },
      {
        title:'Location',
        width:200,
        dataIndex:'location',
        key:'location',
        render:(item:any)=>{
          return (
              <div>
                {item?.length > 20 ?
                    <Tooltip title={item}>
                      <div className={'ellipses-for-table-data'}>
                        {item || 'N/A'}
                      </div>
                    </Tooltip>:
                    <div>
                      {item || 'N/A'}
                    </div>
                }
              </div>
          )
        }
      },
      {
        title:'Position Title',
        width:200,
        dataIndex:'position_title',
        key:'position_title',
        render:(item:any)=>{
          return (
              <div>
                {item?.length > 20 ?
                    <Tooltip title={item}>
                      <div className={'ellipses-for-table-data'}>
                        {item || 'N/A'}
                      </div>
                    </Tooltip>:
                    <div>
                      {item || 'N/A'}
                    </div>
                }
              </div>
          )
        }
      },
      {
        title:'Speciality',
        width:200,
        dataIndex:'specialisation',
        key:'specialisation',
        render:(item:any)=>{
          return (
              <div>
                {item?.length > 20 ?
                    <Tooltip title={item}>
                      <div className={'ellipses-for-table-data'}>
                        {item || 'N/A'}
                      </div>
                    </Tooltip>:
                    <div>
                      {item || 'N/A'}
                    </div>
                }
              </div>
          )
        }
      },
      {
        title:'Start Date',
        width:120,
        dataIndex:'start_date',
        key:'start_date',
        render:(item:any)=>{
          return (
              <div>
                {item ? moment(item).format('MM-YYYY') : 'N/A'}
              </div>
          )
        }
      },
      {
        title:'End Date',
        width:120,
        dataIndex:'end_date',
        key:'end_date',
        render:(item:any)=>{
          return (
              <div>
                {item ? moment(item).format('MM-YYYY') : 'N/A'}
              </div>
          )
        }
      },
      {
        title: 'Skills',
        width: 200,
        dataIndex: 'skills',
        key: 'skills',
        render: (item: any) => {
          return (
              <div>
                {item?.skills || 'N/A'}
              </div>
          )
        }
      },
      {
        title: 'Delete',
        width: 80,
        dataIndex: 'tempId',
        key: 'tempId',
        render: (item: any) => {
          return (
              <div className={'display-center'}>
                <Tooltip title={`Delete Experience`}>
                  <IconButton onClick={() => handleDeleteClick(item)}>
                    <DeleteIcon className="delete-icon" />
                  </IconButton>
                </Tooltip>
              </div>
          )
        }
      }
    ]
  },[handleDeleteClick])

  return (
    <div className="add-container">
      {/*{experiences.length > 0 && (*/}
      {/*  <Table className="mrg-top-50">*/}
      {/*    <TableHead className={"mat-thead"}>*/}
      {/*       <TableRow className={"mat-tr"}>*/}
      {/*        <th>Facility Name</th>*/}
      {/*        <th>Location</th>*/}
      {/*        <th>Position Title</th>*/}
      {/*        <th style={{ width: "15%" }}>Speciality</th>*/}
      {/*        <th>Start Date</th>*/}
      {/*        <th>End Date</th>*/}
      {/*        <th>Skills</th>*/}
      {/*      </TableRow>*/}
      {/*    </TableHead>*/}
      {/*   <TableBody className={"mat-tbody"}>*/}
      {/*      {sortedExpData.map((experience: any, index: number) => (*/}
      {/*        <>*/}
      {/*          <ReadOnlyRow key={index} experience={experience} handleDeleteClick={handleDeleteClick} />*/}
      {/*        </>*/}
      {/*      ))}*/}
      {/*    </TableBody>*/}
      {/*  </Table>*/}
      {/*)}*/}

      {isExperiences ? (
        <div className="add-input">
          <Formik initialValues={experienceInitialState} validateOnChange={true} validationSchema={experienceValidation} onSubmit={onAdd}>
            {({ isSubmitting, handleSubmit, isValid, setFieldValue, resetForm }) => (
              <Form className={"form-holder"}>
                <div className="input-container">
                  <Field variant="outlined" component={TextField}  InputLabelProps={{ shrink: true }} fullWidth name="facilityName" label="Facility Name*" id="input_hcp_add_experience_facility_name" />
                  <Field variant="outlined" component={TextField}   InputLabelProps={{ shrink: true }} fullWidth name="location" label="Location*" id="input_hcp_add_experience_location" />
                </div>

                <div className="input-container">
                  <Field
                    SelectProps={showDropDownBelowField}
                    select
                    onChange={(e: any) => {
                      const hcpType = e.target.value;
                      setFieldValue("hcpType", hcpType);
                      handleHcpTypeChange(hcpType);
                    }}
                    variant="outlined"
                    component={TextField}
                    fullWidth
                    name="hcpType"
                    label="Position Title*"
                    id="input_hcp_add_experience_position_title"
                    InputLabelProps={{ shrink: true }}
                  >
                    {hcpTypes.map((item: any, index: number) => (
                      <MenuItem value={item.code} key={"hcp_type_" + index} id={"menu_hcp_add_experience_hcp_type" + item.title}>
                        {item?.title}
                      </MenuItem>
                    ))}
                  </Field>

                  <Field SelectProps={showDropDownBelowField} select variant="outlined"  InputLabelProps={{ shrink: true }} component={TextField} fullWidth name="speciality" label="Speciality*" id="input_hcp_add_experience_speciality">
                    {hcpTypeSpecialities &&
                      hcpTypeSpecialities.map((item: any, index: any) => (
                        <MenuItem value={item.code} key={"hcp_type_specialities_" + index} id={"input_hcp_add_speciality_" + index}>
                          {item.title}
                        </MenuItem>
                      ))}
                  </Field>
                </div>

                <div className="input-container">
                  <Field variant="outlined" component={TextField}  InputLabelProps={{ shrink: true }} fullWidth name="skills" label="Skills (optional)" id="input_hcp_add_experience_skills" />
                  <Field
                      SelectProps={showDropDownBelowField}
                      variant="outlined"
                      fullWidth
                      select
                      component={TextField}
                      name="stillWorkingHere"
                      label="Still Working ?*"
                      id="input_hcp_add_Vexperience_working_here"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e: any) => {
                        const isWorking = e.target.value;
                        if (isWorking === "1") {
                          setFieldValue("stillWorkingHere", isWorking);
                          setFieldValue("endDate", null);
                          setShowEndDate(false);
                        } else {
                          setFieldValue("stillWorkingHere", isWorking);
                          setShowEndDate(true);
                        }
                      }}
                  >
                    {acknowledgement.map((item: any, index: any) => (
                        <MenuItem value={item.value} id={"menu_hcp_add_experience_" + index}>
                          {item.label}
                        </MenuItem>
                    ))}
                  </Field>
                </div>

                <div className="input-container">
                  <Field
                    fullWidth
                    variant="inline"
                    openTo="year"
                    views={["year", "month"]}
                    inputVariant="outlined"
                    component={DatePicker}
                    placeholder="MM/YYYY"
                    name="startDate"
                    label="Start Date"
                    id="input_hcp_add_experience_start_date"
                    InputLabelProps={{ shrink: true }}
                  />
                  {showEndDate && (
                      <Field
                          openTo="year"
                          views={["year", "month"]}
                          inputVariant="outlined"
                          component={DatePicker}
                          placeholder="MM/YYYY"
                          variant="inline"
                          fullWidth
                          name="endDate"
                          label="End Date"
                          id="input_hcp_add_experience_end_date"
                          InputLabelProps={{ shrink: true }}
                      />
                  )}
                </div>

                {/*<div className="input-container minor">*/}

                {/*</div>*/}

                <div className="hcp-common-btn-grp">
                  <Button
                    variant="outlined"
                    type="reset"
                    onClick={() => {
                      resetForm();
                      handleCancelShift();
                    }}
                    id="icon_hcp_add_experience_close"
                  >
                    Delete
                  </Button>
                  <Button color="primary" variant="contained" type="submit" id="icon_hcp_add_experience_submit">
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="exp-add-action">
          <Tooltip title={"Add New Work Experience"}>
            <p id="btn_hcp_add_experience" onClick={() => setIsExperiences(true)} className="generic-add-multiple">
              + Add Work Experience
            </p>
          </Tooltip>
        </div>
      )}
      {experiences?.length > 0 &&
          <div className={'mrg-top-20'}>
              <AntTableComponent
                  columns={ExperienceListColumn}
                  data={sortedExpData}
                  extraPayload={sortedExpData}
                  noDataText={'No Experience Data Found.'}
              />
          </div>
      }
    </div>
  );
};

export default ExperienceAddComponent;
