import "./AddHcpForMessageComponent.scss";
import {Button, Checkbox, Chip, LinearProgress, Tooltip} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import {CssTextField} from "../../../constants/data/styles";
import React, {useCallback, useEffect, useState} from "react";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import {BackIcon} from "../../../constants/ImageConfig";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {TextField} from "formik-material-ui";
import * as Yup from "yup";
import {ApiService, CommonService} from "../../../helpers";
import {ENV} from "../../../constants";
import {Box} from "@material-ui/core";
import {Slider} from "@material-ui/core";


const marks = [
    {
        value: 0,
        label: '0 miles',
    },
    {
        value: 10,
        label: '10 miles',
    },
    {
        value: 20,
        label: '20 miles',
    },
    {
        value: 30,
        label: '30 miles',
    },
    {
        value: 40,
        label: '40 miles',
    },
    {
        value: 50,
        label: '50 miles',
    },
    {
        value: 60,
        label: '60 miles',
    },
    {
        value: 70,
        label: '70 miles',
    },
    {
        value: 80,
        label: '80 miles',
    },
    {
        value: 90,
        label: '90 miles',
    },
    {
        value: 100,
        label: '100 miles',
    },

];

interface AddHcpForMessageComponentProps {
    selectedFacility: any;
    setOpen: any;
    hcpList: any;
    shiftDetails: any;
    selectedHcpType: any;
    search: any;
    setSearch: any;
    filteredHcp: any;
    isHcpListLoading: any;
    distanceRangeValue: any;
    setDistanceRangeValue: any;
    debounce:any;
    handleSearchHcpData:any;
}

interface smsBlast {
    hcp_ids: any;
    message: string;
}

const smsValidation = Yup.object({
    message: Yup.string().min(10, "minimum 10 characters").trim().typeError("Text and Numbers Only").required("Required"),
});

const AddHcpForMessageComponent = (props: AddHcpForMessageComponentProps) => {
    const {
        setOpen,
        shiftDetails,
        search,
        setSearch,
        isHcpListLoading,
        filteredHcp,
        selectedFacility,
        setDistanceRangeValue,
        selectedHcpType,
        debounce,
        handleSearchHcpData
    } = props;
    const [hcpMessageNavigation, setHcpMessageNavigation] = useState<any>("hcp-selection");
    const [selectedHcpGroups, setSelectedHcpGroups] = useState<any>([]);
    const [isHcpGroupsSelected, setIsHcpGroupsSelected] = useState<any>([]);


    function valuetext(value: number) {
        return `${value} miles`;
    }

    function valueLabelFormat(value: number) {
        // console.log(value);
        return `${value}`;
        //marks.findIndex((mark) => mark.value === value);
    }


    const handleSelectShifts = useCallback(
        (event: any, hcp: any) => {
            if (event.target.checked === true) {
                setSelectedHcpGroups([...selectedHcpGroups, hcp]);
                setIsHcpGroupsSelected([...isHcpGroupsSelected, hcp?._id]);
            } else {
                setSelectedHcpGroups(selectedHcpGroups?.filter((item: any) => item !== hcp));
                setIsHcpGroupsSelected(isHcpGroupsSelected?.filter((value: any) => value !== hcp?._id));
            }

        },

        [selectedHcpGroups, isHcpGroupsSelected]
    );

    useEffect(() => {
        if (isHcpGroupsSelected.length === 0) {
            setSelectedHcpGroups([]);
        }
    }, [isHcpGroupsSelected]);

    const onAdd = (payload: any, {setSubmitting, setErrors, resetForm}: FormikHelpers<smsBlast>) => {
        if (selectedHcpGroups && selectedHcpGroups.length === 0) {
            CommonService.showToast("Please select atleast one group");
            return;
        }

        let createBlast = {
            message: payload.message,
            hcp_ids: selectedHcpGroups.map((hcp: any) => hcp?._id)
        };

        ApiService.post(ENV.API_URL + `requirement/${shiftDetails?._id}/notifyHCP`, createBlast)
            .then((resp: any) => {
                if (resp) {
                    CommonService.showToast(resp?.msg || "Success", "success");
                    setOpen(false);
                    setSearch("");
                } else {
                    setSubmitting(false);
                }
            })
            .catch((err) => {
                console.log(err);
                //  CommonService.handleErrors(setErrors, err?.errors);
                setSubmitting(false);
                CommonService.showToast(err.msg || "Error", "error");
                setOpen(false);
                setSearch("");
            });
    };


    useEffect(() => {
        filteredHcp?.sort((firstDistance: any, secondDistance: any) => firstDistance?.age - secondDistance?.age);
    }, [selectedFacility, selectedHcpType, filteredHcp]);

    useEffect(() => {
        debounce(handleSearchHcpData, 500);
        // eslint-disable-next-line
    }, [search,handleSearchHcpData]);

    return (
        <>
            <div className={'hcp-selection-component-for-message'}>
                {hcpMessageNavigation === "hcp-selection" && <>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div className={'header-wrapper mrg-right-10'}>
                                {shiftDetails?.hcp_type}'s List
                            </div>
                            <div className={"region-name-text"}>
                                {selectedFacility?.name} | {selectedFacility?.address?.region_name}
                            </div>
                        </div>
                        <div className={'search-box'}>
                            <div className="mrg-left-5 filter">
                                <div>
                                    <div className="d-flex">
                                        <div className="d-flex position-relative">
                                            {!search ? (
                                                <div className={"search_icon"}>
                                                    <SearchRounded/>
                                                </div>
                                            ) : (
                                                <div className={"search_icon"}>
                                                    <ClearIcon
                                                        onClick={(event) => {
                                                            setSearch("");
                                                        }}
                                                        id="clear_requirment_search"
                                                    />
                                                </div>
                                            )}
                                            <div>
                                                <CssTextField
                                                    defaultValue={""}
                                                    className="search-cursor searchField"
                                                    id="input_search_requirment"
                                                    onChange={(event) => {
                                                        setSearch(event.target.value);
                                                    }}
                                                    value={search}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    fullWidth
                                                    type={"text"}
                                                    placeholder={"Search Staff Name"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <Box>
                            <Slider
                                aria-label="Default"
                                defaultValue={100}
                                valueLabelFormat={valueLabelFormat}
                                step={null}
                                getAriaValueText={valuetext}
                                valueLabelDisplay="auto"
                                marks={marks}
                                onChange={(event: any, val: any) => {
                                    // console.log(val);
                                    setDistanceRangeValue(val);
                                }
                                }
                            />
                        </Box>
                    </div>

                    <div className={'hcp-list'}>
                        {!isHcpListLoading && <>
                            {filteredHcp?.map((hcp: any) => {
                                return (
                                    <>
                                        <div className={'hcp-name-holder'}>
                                            <div>
                                                <Checkbox id={"cb_"}
                                                          checked={isHcpGroupsSelected?.includes(hcp?._id)}
                                                          onChange={(event) => {
                                                              handleSelectShifts(event, hcp);
                                                          }
                                                          }/>
                                            </div>
                                            <Tooltip title={`${hcp?.first_name} ${hcp?.last_name}`}>
                                                <div style={{display: "flex", width: "350px"}}>
                                                    <div className={'hcp-name'}>{hcp?.first_name}
                                                        &nbsp;{hcp?.last_name}
                                                    </div>
                                                    <div> {`(${hcp?.distance || 0 } miles)`}</div>

                                                </div>
                                            </Tooltip>
                                        </div>

                                    </>
                                );
                            })}
                        </>}
                        {
                            isHcpListLoading && <>
                                <div style={{width: "100%"}}>
                                    <LinearProgress color="primary" {...props} />
                                </div>

                            </>
                        }
                    </div>
                    <div className={'btn-wrapper'} style={{display: "flex", justifyContent: "space-between"}}>
                        <Button className={'mrg-right-20'} variant="outlined" color={'primary'} fullWidth
                                onClick={() => {
                                    setOpen(false);
                                    setSearch("");
                                }}>Cancel</Button>
                        <Button color={'primary'} variant="contained" disabled={selectedHcpGroups.length === 0}
                                endIcon={<SendIcon/>} fullWidth onClick={() => {
                            setHcpMessageNavigation('hcp-message-component');
                        }}>Send Sms Blast</Button>
                    </div>
                </>}
                {
                    hcpMessageNavigation === "hcp-message-component" && <>
                        <div className={'hcp-sms-blast-component'}>
                            <div style={{display: "flex"}}>
                                <div style={{marginRight: "15px", cursor: "pointer"}} onClick={() => {
                                    setHcpMessageNavigation('hcp-selection');
                                }
                                }>
                                    <img src={BackIcon} alt=""/>
                                </div>
                                <div className={'header-wrapper'}>SMS Blast</div>
                            </div>
                            <div className="selected-recipients">
                                {selectedHcpGroups.length > 0 ? (
                                    selectedHcpGroups.map((data: any) => {
                                        return <Chip style={{background: "#E3FFF4"}} key={data?.first_name}
                                                     label={data?.first_name}/>;
                                    })
                                ) : (
                                    <p>No Staff Added</p>
                                )}
                            </div>
                            <div className="sms-blast-message pdd-right-20">
                                <Formik
                                    initialValues={{
                                        hcp_ids: [],
                                        message: "Greetings from Vitawerks, A new shift has opened up.",
                                    }}
                                    validateOnChange={true}
                                    validationSchema={smsValidation}
                                    onSubmit={onAdd}
                                >
                                    {({isSubmitting, isValid}) => (
                                        <Form className="">
                                            <Field autoComplete="off" className="mrg-top-20" component={TextField} fullWidth
                                                   multiline name="message" rows={6} variant="outlined"
                                                   placeholder="Type in your message*"/>
                                            <div className="sms-blast-btn mrg-top-20">
                                                <Tooltip title={isSubmitting ? "Sending Blast" : "Send Blast"}>
                                                    <Button disabled={isSubmitting || !isValid} color={"primary"}
                                                            variant={"contained"} id="sms_blast_button"
                                                            className={isSubmitting ? "has-loading-spinner" : ""}
                                                            type="submit" size={"large"} fullWidth={true}>
                                                        {isSubmitting ? "Sending Blast" : "Send Sms Blast"}
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </>
                }
            </div>

        </>

    );
};
export default AddHcpForMessageComponent;
