import {useState} from "react";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {ENV} from "../../../../constants";
import {PipelineListColumn} from "../pipelineListColumn";
import {useLocation} from "react-router-dom";

const OfferAcceptedComponent=()=>{
    const location=useLocation();
    const [filterData]=useState<any>({
        recruiter_id:'',
        status:['offer_accepted'],
        search:'',
    })
    return (
        <div>
            <AntTableComponent
                url={ENV.API_URL + "ats/jobApplicationPipeline"}
                method={'post'}
                extraPayload={filterData}
                columns={PipelineListColumn(location?.search) || []}
                noDataText={'No Offer Accepted Data Found.'}
            />
        </div>
    )
}

export default OfferAcceptedComponent