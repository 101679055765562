import React, {PropsWithChildren, useCallback, useState} from "react";
import "./HcpContractComponent.scss";
import CustomFile from "../../../../../components/shared/CustomFile";
import {CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import moment from "moment";
import NoDataToShowCardComponent from "../../../../../components/NoDataToShowCardComponent";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import {makeStyles} from "@material-ui/core/styles";

export interface HcpContactComponentProps {
    hcpDetails: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const HcpContractComponent = (props: PropsWithChildren<HcpContactComponentProps>) => {
    const hcpDetails = props?.hcpDetails;
    const classes = useStyles();
    //const [isContractLoading, setIsContractLoading] = useState<boolean>(true);
    const [isDownload, setIsDownloading] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    // const init = useCallback(() => {
    //   CommonService._api.get(ENV.API_URL + "hcp/" + id + "/contract?is_preview=true").then((resp) => {
    //     setContractDetails(resp?.data[0]);
    //     setIsContractLoading(false);
    //   }).catch((err) => {
    //       console.log(err);
    //       setIsContractLoading(false);
    //     });
    // }, [id]);

    const handleDownload = useCallback((file_key: any) => {
        setIsDownloading(true);
        const payload = {
            file_key: file_key
        };
        CommonService._api.post(ENV.API_URL + "downloadAttachment", payload).then((resp) => {
            const link = document.createElement('a');
            link?.setAttribute('href', resp?.data);
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false);
        }).catch((err) => {
            console.log(err);
            setIsDownloading(false);
        });
    }, []);

    // useEffect(() => {
    //   init();
    // }, [init]);

    return (
        <>
            <div className="custom-border pdd-20">
                <Accordion expanded={expanded === 'Contract'} onChange={handleChange('Contract')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Contract</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={'hcp-details-wrapper'}>
                            {hcpDetails?.contract_details?.url !== undefined && (
                                <div>
                                    {/*<h3 className="contract-heading">Contract</h3>*/}
                                    <CustomFile data={hcpDetails?.contract_details} handleDownload={handleDownload}
                                                isDownload={isDownload}/>
                                </div>
                            )}
                            {hcpDetails?.contract_details ? (
                                <div className="d-flex mrg-top-20">
                                    <div className="flex-1">
                                        <h4>Rate/hr</h4>
                                        <p>{hcpDetails?.contract_details?.rate_per_hour ? '$' + hcpDetails?.contract_details?.rate_per_hour : 'N/A'}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Checkrpay ID</h4>
                                        <p>{hcpDetails?.worker_id || 'N/A'}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Signed On</h4>
                                        <p>{hcpDetails?.contract_details?.signed_on ? moment(hcpDetails?.contract_details?.signed_on).format("MMMM Do YYYY") : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Salary Credit Day</h4>
                                        <p>{hcpDetails?.contract_details?.salary_credit || 'N/A'}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Staff Payment Mode</h4>
                                        <p>{hcpDetails?.nc_details?.gusto_type !== "" ? CommonService.nameCoverterToNormal(hcpDetails?.nc_details?.gusto_type) : "N/A"}</p>
                                    </div>
                                    <div className="flex-1"></div>
                                </div>
                            ) : (
                                <>
                                    <NoDataToShowCardComponent/>
                                </>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            </>);
                };

                export default HcpContractComponent;
