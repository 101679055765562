import {CommonService} from "../../../helpers";
import {Tooltip} from "antd";


interface ShiftMenuListComponentProps{
    onMenuItemClick?:(action:string)=>void;
    selectedShifts?:string[];
    menuItems?:any;
}

const ShiftMenuListComponent=(props:ShiftMenuListComponentProps)=>{
    const {onMenuItemClick,
        selectedShifts,
        menuItems}=props;


    return (
        <div className={'applicant-menu-list-component'}>
            {menuItems?.()?.map((item:any)=>{
                return (
                    <Tooltip title={item?.tooltip || ''} key={item.id} placement={'left'}>
                        <div
                            className={`applicant-menu-item ${item.disabled ? 'menu-item-disabled' : ''}`}
                            onClick={() => {
                                if(!item?.disabled && selectedShifts?.length !== 0) {
                                    onMenuItemClick?.(item?.action)
                                }else if(selectedShifts?.length === 0){
                                    CommonService.showToast(`Select Shift to ${item?.label}`, 'info')
                                }
                            }}
                        >
                             <span>
                                {item.label}
                             </span>
                        </div>
                    </Tooltip>
                )
            })}
        </div>
    )
}

export default ShiftMenuListComponent