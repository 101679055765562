import "./ChatUserListComponent.scss";
import InputComponent from "../../../app/ant-component/input/InputComponent";
import {Button, Radio} from "antd";
import {useCallback, useEffect, useState} from "react";
import LoaderComponent from "../../loader/LoaderComponent";
import {CommonService} from "../../../helpers";
import {ENV} from "../../../constants";

interface ChatUserListComponentProps{
    chatList?:any[];
    handleClick?:(user:any)=>void;
    handleClose?:any;
    setChatId?:any;
    getChatDetails?:(chatId:any)=>void;
    setChatDetails?:any;
}

const ChatUserListComponent=(props:ChatUserListComponentProps)=>{
    const {
        chatList,
        handleClick,
        handleClose,
        setChatId,
        getChatDetails,
        setChatDetails
    }=props;
    const [userList,setUserList]=useState<any>([]);
    const [isUserListLoading,setIsUserListLoading]=useState<boolean>(false);
    const [search,setSearch]=useState<string>('');
    const [selectedUser,setSelectedUser]=useState<any>(null);

    const getUserList = useCallback(() => {
        setIsUserListLoading(true)
        CommonService._api.get(ENV.API_URL + `userList?search=${search}&is_active=true`).then((response: any) => {
            const data = response?.data?.docs;
            const updatedList = data?.map((item: any) => ({
                value: item?.first_name + " " + item?.last_name, // Set value as the name, not ID
                label: item?.first_name + " " + item?.last_name, // Display name
                code: item?._id, // Store ID separately if needed
                role: item?.role,
            }))
            setUserList(updatedList);
        }).catch((err: any) => {
            console.log(err.error)
        }).finally(() => {
            setIsUserListLoading(false)
        })
    }, [search])

    useEffect(() => {
        getUserList()
    }, [getUserList]);

    const handleUserSelection=useCallback((e:any)=>{
        setSelectedUser(e.target.value);
    },[])

    const handleStartChat=useCallback(()=>{
        if (selectedUser) {
            setChatId(null);
            const user = userList?.find((u:any) => u.value === selectedUser);
            if (user) {
                let existingChat=chatList?.find((chat:any)=>chat?.user_2 ===  user?.code);
                if(existingChat){
                    handleClose()
                    setChatId(existingChat?._id)
                    getChatDetails?.(existingChat?._id)
                    setChatDetails(existingChat)
                }else{
                    // setChatId(null);
                    // setChatDetails(null);
                    handleClick?.(user);
                    handleClose?.();
                }

            }
        }
    },[handleClick,selectedUser,handleClose,userList,chatList,setChatId,getChatDetails,setChatDetails])
    return (
        <div className={`chat-user-list`}>
            <div className={'mrg-top-10'}>
                <InputComponent
                    value={search}
                    onChange={(value:string)=>setSearch(value)}
                    placeholder={'Search..'}
                    size={'large'}
                />
            </div>
            <div className={'chat-user-list-wrapper'}>
                {isUserListLoading ?
                    <div className={'chat-loading-container'}>
                        <LoaderComponent type={'spinner'} color={'primary'}/>
                    </div>:
                    <>
                    <Radio.Group onChange={handleUserSelection} value={selectedUser} className={'radio-wrapper'}>
                        {userList?.map((user: any) => (
                                <div key={user.value} className={"chat-user"}>
                                    <Radio value={user.value} /> {user.label}
                                </div>
                        ))}
                    </Radio.Group>
                    </>
                }

            </div>
            <Button
                type={'default'}
                className={'custom-primary-button chat-button'}
                disabled={!selectedUser}
                onClick={handleStartChat}
            >
                Chat
            </Button>
        </div>
    )
}

export default ChatUserListComponent;