import "./SendEmailComponent.scss";
import {Button} from "antd";
import {SendOutlined} from '@ant-design/icons'
import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {ENV, ImageConfig} from "../../../../constants";
import LeavePageConfirmationComponent from "../../../../components/shared/LeavePageConfirmationComponent";
import EmailEditorComponent from "./email-editor/EmailEditorComponent";
import {ApiService, CommonService} from "../../../../helpers";
import ModalComponent from "../../../../components/modal/ModalComponent";
import JobListTableComponent from "./job-list-table/JobListTableComponent";
import SaveTemplateComponent from "./save-template/SaveTemplateComponent";

const SendEmailComponent=()=>{
    const history=useHistory();
    const location=useLocation<any>();
    const [isConfirmationOpen,setIsConfirmationOpen]=useState<boolean>(false);
    const [selectedRole,setSelectedRole]=useState<string[]>([]);
    const [selectedStaffType,setSelectedStaffType]=useState<string[]>([]);
    const [roleEmailList,setRoleEmailList]=useState<any>([]);
    const [selectedRoleEmails,setSelectedRoleEmails]=useState<any>([]);
    const [staffEmailList,setStaffEmailList]=useState<any>([]);
    const [selectedStaffEmails,setSelectedStaffEmails]=useState<any>([]);
    const [manualEmails,setManualEmails]=useState<any>([]);
    const [ccEmails,setCCEmails]=useState<any>([]);
    const [bccEmails,setBCCEmails]=useState<any>([]);
    const [subject,setSubject]=useState<string>("");
    const [emailMessage,setEmailMessage]=useState<any>('');
    const [attachment,setAttachment]=useState<any>(null)
    const [isSubmitting,setIsSubmitting]=useState<boolean>(false);
    const [openJobListModal,setOpenJobListModal]=useState<boolean>(false);
    const [selectedJobs,setSelectedJobs]=useState<any>([]);
    const [selectedJobsForEmail,setSelectedJobsForEmail]=useState<any>(null);
    const [openTemplateModal,setOpenTemplateModal]=useState<boolean>(false);
    const [templateName,setTemplateName]=useState<any>('');
    const [templateSubject,setTemplateSubject]=useState<any>('');
    const [templateContent,setTemplateContent]=useState<any>(null);
    const [isTemplateSaving,setIsTemplateSaving]=useState<boolean>(false);
    const [templateList,setTemplateList]=useState<any>([]);
    const [selectedTemplate,setSelectedTemplate]=useState<any>('')
    console.log(location?.state?.from)

    const openAdd = useCallback(() => {
        setIsConfirmationOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsConfirmationOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        if(location?.state?.from === 'job-list'){
            history.push('/job')
        }else{
            history.push("/applicants");
        }
    }, [history,location]);

    const openModal=useCallback(()=>{
        setOpenJobListModal(true);
    },[])

    const closeModal=useCallback(()=>{
        setOpenJobListModal(false);
    },[])

    const handleSubmit=useCallback(()=>{
        setIsSubmitting(true)
        const combinedRecipientEmails = [...(selectedRoleEmails || []),...(selectedStaffEmails || []), ...(manualEmails || [])];
        const ccEmailsList = ccEmails || [];
        const bccEmailsList = bccEmails || [];
        const formData = new FormData();
        if (attachment) {
            formData.append('attachment', attachment);
        }
        combinedRecipientEmails?.forEach((email:any,index:number)=>{
            formData.append(`email[${index}]`,email || "");
        })
        ccEmailsList?.forEach((email:any,index:number)=>{
            formData.append(`cc[${index}]`,email || "");
        })
        bccEmailsList?.forEach((email:any,index:number)=>{
            formData.append(`bcc[${index}]`,email || "");
        })
        formData.append('subject',subject || "");
        formData.append('emailMessage',emailMessage || "");


        ApiService.upload(ENV.API_URL + "ats/bulkEmail",formData)
            .then((response)=>{
                CommonService.showToast(response.message,"success");
                confirmAdd();
            }).catch((error:any)=>{
            CommonService.showToast(error.error || error.message || "Error occurred while sending email",'error')
        }).finally(()=>{
            setIsSubmitting(false)
        })
    },[selectedRoleEmails,selectedStaffEmails,manualEmails,ccEmails,bccEmails,subject,emailMessage,confirmAdd,attachment])

    const getTemplateList=useCallback(()=>{
        CommonService._api.get(ENV.API_URL+ 'ats/listTemplate').then((response:any)=>{
            const updatedList=response?.data?.map((item:any)=>({
                title:item?.template,
                code:item?._id,
                message:item?.template_format
            }))
            setTemplateList(updatedList);
        }).catch((error:any)=>{
            console.log(error)
        })
    },[])

    useEffect(() => {
        getTemplateList()
    }, [getTemplateList]);

    const handleOpenTemplateModal=useCallback(()=>{
        setOpenTemplateModal(true);
        setTemplateContent(emailMessage)
    },[emailMessage])

    const handleAddTemplate=useCallback(()=>{
        let payload:any={
            // ats/addTemplate
            template:templateName,
            template_subject:templateSubject,
            template_format:templateContent
        }
        setIsTemplateSaving(true);
        CommonService._api.post(ENV.API_URL + 'ats/addTemplate',payload).then((response:any)=>{
            console.log(response?.data);
            CommonService.showToast(response.message,'success');
            getTemplateList();
        }).catch((error:any)=>{
            if(error.errors){
                CommonService.showToast(error.errors.template[0],'error');
            }else{
                CommonService.showToast(error.error,'error');
            }
        }).finally(()=>{
            setOpenTemplateModal(false);
            setIsTemplateSaving(false)
            setSelectedTemplate('');
            setEmailMessage('');
            setTemplateContent('');
            setTemplateName('');
            setTemplateSubject('')
        })
    },[templateName,templateSubject,templateContent,getTemplateList])

    return (
        <div className={'add-job-form-container-whole email-container'}>
            <ModalComponent open={openJobListModal} onCancel={closeModal} footer={null} width={900} title={'Tag Jobs'}>
               <JobListTableComponent
                   setSelectedJobs={setSelectedJobs}
                   selectedJobs={selectedJobs}
                   setSelectedJobsForEmail={setSelectedJobsForEmail}
                   setOpenJobListModal={setOpenJobListModal}
               />
            </ModalComponent>
            <ModalComponent
                title={"Save Template"}
                open={openTemplateModal}
                onCancel={() => setOpenTemplateModal(false)}
                 width={900}
                footer={
                    <div className={'template-btn-wrapper'}>
                        <Button
                            type="primary"
                            className="save-template-btn"
                            loading={isTemplateSaving}
                            disabled={templateContent?.length === 0 || templateName?.length === 0}
                            onClick={handleAddTemplate}
                        >
                            Save Template
                        </Button>
                    </div>
                }
            >
                <SaveTemplateComponent
                    templateName={templateName}
                    setTemplateName={setTemplateName}
                    templateSubject={templateSubject}
                    setTemplateSubject={setTemplateSubject}
                    templateContent={templateContent}
                    setTemplateContent={setTemplateContent}
                    isTemplateSaving={isTemplateSaving}
                />
            </ModalComponent>
            <LeavePageConfirmationComponent
                visible={isConfirmationOpen}
                cancel={cancelAdd}
                confirm={confirmAdd}
                confirmationText="Are you sure you want to leave without saving?"
                notext="Cancel"
                yestext="Leave"
            />
            <div className={'add-header-wrapper'}>
                <div className={'add-header-back-wrapper'} onClick={(openAdd)}>
                    <img src={ImageConfig.BackIcon} alt="back"/>
                    <div className={'add-header-back'}>
                        Back
                    </div>
                </div>
                <div className={'add-header-title'}>Bulk Email</div>
                <div className={'add-header-btn-wrapper'}>
                    <Button
                        type="primary"
                        className="email-save-button"
                        // disabled={emailMessage?.length === 0}
                        onClick={handleOpenTemplateModal}
                    >
                        Save as Template
                    </Button>
                </div>
            </div>

            <div className="email-content-wrapper">
                <EmailEditorComponent
                    selectedRole={selectedRole}
                    setSelectedRole={setSelectedRole}
                    selectedStaffType={selectedStaffType}
                    setSelectedStaffType={setSelectedStaffType}
                    roleEmailList={roleEmailList}
                    setRoleEmailList={setRoleEmailList}
                    selectedRoleEmails={selectedRoleEmails}
                    setSelectedRoleEmails={setSelectedRoleEmails}
                    staffEmailList={staffEmailList}
                    setStaffEmailList={setStaffEmailList}
                    selectedStaffEmails={selectedStaffEmails}
                    setSelectedStaffEmails={setSelectedStaffEmails}
                    manualEmails={manualEmails}
                    setManualEmails={setManualEmails}
                    ccEmails={ccEmails}
                    setCCEmails={setCCEmails}
                    bccEmails={bccEmails}
                    setBCCEmails={setBCCEmails}
                    subject={subject}
                    setSubject={setSubject}
                    emailMessage={emailMessage}
                    setEmailMessage={setEmailMessage}
                    setAttachment={setAttachment}
                    attachment={attachment}
                    openJobListModal={openModal}
                    selectedJobsForEmail={selectedJobsForEmail}
                    templateList={templateList}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                />
            </div>

            <div className="email-footer">
                <Button
                    type="primary"
                    className="email-save-button"
                    icon={<SendOutlined/>}
                    iconPosition={'end'}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    disabled={emailMessage?.length === 0 || subject?.length === 0 || (selectedRoleEmails?.length === 0 && selectedStaffEmails?.length === 0 && manualEmails?.length === 0)}
                >
                    Send
                </Button>
            </div>
        </div>
    )
}

export default SendEmailComponent