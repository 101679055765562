import { Avatar } from "antd";
import "./ChatHeaderComponent.scss";
import {CommonService} from "../../../helpers";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";

interface ChatHeaderComponentProps{
    chatDetails?:any;
}

const ChatHeaderComponent=(props:ChatHeaderComponentProps)=>{
    const {chatDetails}=props;
    const {user}=useSelector((state:StateParams)=>state.auth)
    return (
        <div className={'chat-header-component'}>
            <div>
                <Avatar size={'large'} className={'capitalize'}>
                    {chatDetails?.user_2_name?.substr(0,1)}
                </Avatar>
            </div>
            <div>
                <div className={'chat-user-title'}>
                    {(user?.first_name + " " +  user?.last_name) === chatDetails?.user_2_name ?
                        <>
                            You
                        </> :
                        <>
                            {chatDetails?.user_2_name}
                        </>
                    }
                </div>
                <div className={'chat-last-seen'}>{CommonService.formatDateTime(chatDetails?.updated_at)}</div>
            </div>
        </div>
    )
}

export default ChatHeaderComponent