import "./InAppNotificationComponent.scss";
import {useCallback} from "react";
import {useHistory} from "react-router-dom";
import {Skeleton} from 'antd';
import moment from "moment/moment";
import InfiniteScroll from "react-infinite-scroll-component";

interface InAppNotificationComponentProps{
    handlePopoverClose?:any;
    isNotificationLoading?:boolean;
    notificationList?:any;
    hasMore?:any;
    page?:any;
    getNotification?:any
}

const InAppNotificationComponent=(props:InAppNotificationComponentProps)=>{
    const {handlePopoverClose,isNotificationLoading,notificationList,hasMore,getNotification}=props;
    const history=useHistory();

    const handleNavigation=useCallback((notification:any)=>{
            if(notification?.section === 'jobs'){
                if(notification?.notification_action === 'list'){
                        history.push(`/job`)
                }
                if(notification?.notification_action === 'view'){
                    history.push(`/jobs/view/${notification?.linkedTo}?tab=applications`)
                }
            }else if(notification?.section === 'applicants'){
                if(notification?.notification_action === 'list'){
                    history.push('/applicants')
                }
                if(notification?.notification_action === 'view'){
                    history.push(`/applicant/view/${notification?.linkedTo}`)
                }
            }else if(notification?.section === 'pending'){
                if(notification?.notification_action === 'view'){
                    history.push(`/pendingShifts/list?shift_id=${notification?.linkedTo}`)
                }
                if(notification?.notification_action === 'list'){
                    history.push(`/pendingShifts/list`)
                }
            }else if(notification?.section === 'open'){
                if(notification?.notification_action === 'view'){
                    history.push(`/shiftsRequirements/view/${notification?.linkedTo}`)
                }
                if(notification?.notification_action === 'list'){
                    history.push(`/shiftsRequirements/list`)
                }
            }
            else if(notification?.section === 'approved'){
                if(notification?.notification_action === 'view'){
                    history.push(`/approvedShifts/view/${notification?.linkedTo}`)
                }
                if(notification?.notification_action === 'list'){
                    history.push(`/approvedShifts/list`)
                }
            }
            else if(notification?.section === 'completed'){
                if(notification?.notification_action === 'view'){
                    history.push(`/completedShifts/view/${notification?.linkedTo}`)
                }
                if(notification?.notification_action === 'list'){
                    history.push(`/completedShifts/list`)
                }
            }
            else if(notification?.section === 'cancelled'){
                if(notification?.notification_action === 'view'){
                    history.push(`/cancelledShifts/view/${notification?.linkedTo}`)
                }
                if(notification?.notification_action === 'list'){
                    history.push(`/cancelledShifts/list`)
                }
            }
            else if(notification?.section === 'master'){
                if(notification?.notification_action === 'view'){
                    history.push(`/shiftMaster/view/${notification?.linkedTo}`)
                }
                if(notification?.notification_action === 'list'){
                    history.push(`/shiftMaster/list`)
                }
            }else if(notification?.section === 'mobile registrations'){
                if(notification?.notification_action === 'view'){
                    history.push(`/hcp/view/${notification?.linkedTo}`)
                }
                if(notification?.notification_action === 'list'){
                    history.push(`/hcp/list`)
                }
            }else if(notification?.section ===  'approved_user' || notification?.section ===  'hcps'){
                if(notification?.notification_action === 'view'){
                    history.push(`/hcp/user/view/${notification?.linkedTo}`)
                }
                if(notification?.notification_action === 'list'){
                    history.push(`/hcp/user/list`)
                }
            }
            else{
                return;
            }
        handlePopoverClose();
    },[history,handlePopoverClose])


    return (
        <div className={'in-app-notification-component'} id="notificationScrollableDiv">
            {isNotificationLoading && notificationList?.length === 0 ? (
                // Initial loading skeleton using Ant Design
                <div>
                    {[...Array(5)].map((_, index) => (
                        <div key={index} className={'notification-card-loading'}>
                            <Skeleton active paragraph={{ rows: 1, width: '100%' }} title={false} />
                            <Skeleton active paragraph={{ rows: 1, width: '40%' }} title={false} />
                        </div>
                    ))}
                </div>
            ) : (
                <InfiniteScroll
                    dataLength={notificationList.length}
                    next={getNotification}
                    hasMore={hasMore}
                    loader={
                        // Loading skeleton for additional items using Ant Design
                        <div>
                            {[...Array(3)].map((_, index) => (
                                <div key={`skeleton-${index}`} className={'notification-card-loading'}>
                                    <Skeleton active paragraph={{ rows: 1, width: '80%' }} title={false} />
                                    <Skeleton active paragraph={{ rows: 1, width: '60%' }} title={false} />
                                </div>
                            ))}
                        </div>
                    }
                    endMessage={
                        <p style={{ textAlign: 'center', padding: '10px' }}>
                            <b>No more notifications</b>
                        </p>
                    }
                    scrollableTarget="notificationScrollableDiv"
                >
                    {notificationList?.map((item: any) => {
                        const m = moment(item?.created_at);
                        return (
                            <div key={item?._id} className={'notification-card'}>
                                <div>
                                    <div className={'notification-message'} onClick={() => handleNavigation(item)}>
                                        {item?.message}
                                    </div>
                                    <div className={'notification-timestamp'}>
                                        {m.format("dddd")} |{" "}
                                        {m.format("MMMM Do YYYY")} | {m.format("h:mm:ss a")}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </InfiniteScroll>
            )}
        </div>
    )
}

export default InAppNotificationComponent