import React, { useMemo } from 'react';
import JoditEditor from 'jodit-react';
import "./JoditEditorComponent.scss"

interface JoditEditorComponentProps {
    value: string;
    onChange: (newValue: string) => void;
}

const JoditEditorComponent = (props: JoditEditorComponentProps) => {
    const { value, onChange } = props;

    const config:any = useMemo(
        () => ({
            readonly: false,
            showPoweredByJodit: false,
            showStatusBar: false,
            showCharsCounter: false,
            showWordsCounter: false,
            showXPathInStatusbar: false,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            toolbarButtonSize: 'small' as any,
            // Paste handling configuration
            pasteHTMLActionList: ['keep_formatting', 'insert_as_html'],
            processPasteHTML: (html: string): string => {
                // Clean up and maintain formatting
                return html.replace(/<p[^>]*>(<br[^>]*>)<\/p>/g, '') // Remove empty paragraphs
                    .replace(/<p[^>]*>/g, '<p>') // Normalize paragraphs
                    .replace(/style="[^"]*"/g, ''); // Remove inline styles if needed
            },
            cleanHTML: {
                fillEmptyParagraph: false,
                removeEmptyBlocks: true,
                // Additional cleaning options
                replaceOldTags: true,
                allowTags: {
                    p: true,
                    strong: true,
                    em: true,
                    u: true,
                    br: true,
                    ul: true,
                    ol: true,
                    li: true,
                    // Add other allowed tags
                },
                removeEmptyElements: true,
            },
            removeButtons: [
                "image",
                'video',
                "file",
                "spellcheck",
                "preview",
                "full size",
                "print",
                "speechRecognize",
                "fullsize",
                "ai-commands",
                "ai-assistant",
                "hr",
                "about",
                "table",
                "selectall", "classSpan", "subscript", "superscript", "cut", "paste", "copyFormat", "brush", "symbols"
            ],
        }),
        []
    );

    return (
        <div>
            <JoditEditor
                className={'jodit-editor-wrapper'}
                value={value}
                config={config}
                onBlur={(newValue: string) => onChange(newValue.trim() === "<p><br></p>" ? "" : newValue)}
            />
        </div>
    )
}

export default JoditEditorComponent;