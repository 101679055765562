import "./ChatCardComponent.scss";
import {Tooltip} from 'antd';
import {CommonService} from "../../../helpers";
import {useCallback} from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
// import { UserOutlined } from '@ant-design/icons';

interface ChatCardComponentProps{
    chat?:any
    chatId?:any;
    getDetails?:any;
    setChatDetails?:any;
}

const ChartCardComponent=(props:ChatCardComponentProps)=>{
    const {chat,chatId,getDetails,setChatDetails}=props;
    const {user}=useSelector((state:StateParams)=>state.auth);

    const handleCardSelection=useCallback((chatId:any)=>{
        getDetails?.(chatId)
        setChatDetails(chat)
    },[getDetails,chat,setChatDetails])
    return (
        <div onClick={()=>handleCardSelection(chat?._id)} className={`chat-card ${chatId === chat?._id ? 'active' : ''}`}>
            <div className={'chat-middle'}>
                <Tooltip title={chat?.user_1_name?.length > 25 ? chat?.user_1_name : ''} color={'#00000080'}>
                    <div className="truncate-text chat-user-name">
                        {(user?.role === 'super_admin' || user?.role === 'super_super_admin') ?
                            <>{chat?.user_2_name}</>:
                            <>{chat?.user_1_name}</>
                        }
                    </div>
                </Tooltip>
                <div className={`truncate-text chat-last-message ${chat?.unread_count !== 0 ?  'active' : ''}`}>
                    {chat?.message}
                </div>
            </div>
            <div className={'chat-right'}>
                <div className={'chat-timestamp'}>{CommonService.formattedTimestamp(chat?.updated_at)}</div>
                {chat?.unread_count !== 0 &&
                    <div className={'chat-badge'}>
                        {chat?.unread_count}
                    </div>
                }
            </div>
        </div>
    )
}

export default ChartCardComponent