import {call, put, takeLatest} from "redux-saga/effects";
import {
    FETCH_REGIONS_REQUEST,
    fetchRegionsSuccess,
    fetchRegionsFailure,
    fetchHcpTypeSuccess,
    fetchHcpTypeFailure,
    FETCH_HCP_TYPES_REQUEST,
    getSpecialitiesSuccess,
    getSpecialitiesFailure,
    GET_SPECIALITIES_REQUEST,
    setFacilityDocuments,
    GET_FACILITY_DOCUMENTS,
    getNotificationSuccess,
    getNotificationFailure,
    GET_NOTIFICATION_REQUEST
} from "../actions/meta.action";
import CommonService from "../../helpers/common-service";
import {ENV} from "../../constants";


// function fetchFacilityDocuments(facilityId: any) {
//     return CommonService._api.get(ENV.API_URL + `facility/documentsList?is_default=${facilityId}`);
// }
//
// function* getFacilityDocumentsList(action: any) {
//     try {
//         // @ts-ignore
//         const response = yield call(fetchFacilityDocuments(action?.facilityId));
//         console.log(response);
//         yield put(setFacilityDocuments(response.data || []));
//     } catch (error) {
//         // yield put(fetchRegionsFailure(error));
//     }
// }

function* fetchAndSetFacilityDocuments(action: any): any {
    try {
        const facilityId = action?.facilityId;
        // if (!facilityId) {
        //     console.error("Facility ID is missing in the action.");
        //     return;
        // }

        // Construct the API URL with the query parameter
        const apiUrl = `${ENV.API_URL}facility/documentsList?is_default=${facilityId}`;
        console.log("Fetching from API:", apiUrl); // Debugging log

        // Fetch the documents
        // @ts-ignore
        const response = yield call(CommonService._api.get, apiUrl);
        console.log("API Response:", response);

        // Dispatch the action to set the documents in Redux store
        yield put(setFacilityDocuments(response.data || []));
    } catch (error) {
        console.error("Error fetching facility documents:", error);
        // Handle error appropriately, e.g., dispatch an error action if needed
        // yield put(fetchRegionsFailure(error));
    }
}


function fetchRegions(): any {
    return CommonService._api.get(ENV.API_URL + `regions`);
}

function* getRegionsList() {
    try {
        // @ts-ignore
        const response = yield call(fetchRegions);
        yield put(fetchRegionsSuccess(response.data || []));
    } catch (error) {
        yield put(fetchRegionsFailure(error));
    }
}

function fetchHcpTypes(): any {
    return CommonService._api.get(ENV.API_URL + `hcpTypes`);
}

function* getHcpTypeList() {
    try {
        // @ts-ignore
        const response = yield call(fetchHcpTypes);
        yield put(fetchHcpTypeSuccess(response?.data || []));
    } catch (error) {
        yield put(fetchHcpTypeFailure(error));
    }
}

export function* getSpecialitiesSaga() {
    try {
        //@ts-ignore
        const response = yield call(CommonService._api.get, ENV.API_URL + "hcpSpecialities");
        yield put(getSpecialitiesSuccess(response.data || []));
    } catch (error: any) {
        yield put(getSpecialitiesFailure(error.message));
    }
}

export function* getNotificationsSaga() {
    try {
        //@ts-ignore
        const response = yield call(CommonService._api.post, ENV.API_URL + "notification/list");
        yield put(getNotificationSuccess(response?.data?.docs || []));
    } catch (error: any) {
        yield put(getNotificationFailure(error.message));
    }
}


function* metaSaga() {
    yield takeLatest(FETCH_REGIONS_REQUEST, getRegionsList);
    yield takeLatest(FETCH_HCP_TYPES_REQUEST, getHcpTypeList);
    yield takeLatest(GET_SPECIALITIES_REQUEST, getSpecialitiesSaga);
    yield takeLatest(GET_FACILITY_DOCUMENTS, fetchAndSetFacilityDocuments);
    yield takeLatest(GET_NOTIFICATION_REQUEST,getNotificationsSaga);
}

export default metaSaga;
