import React, {useCallback, useEffect, useState,useMemo} from "react";
import {Select, Upload, Table, Button, message, DatePicker, Input} from "antd";
import {UploadOutlined, DeleteOutlined} from "@ant-design/icons";
import type {RcFile} from "antd/es/upload";
// import moment from "moment";
import {CommonService} from "../../../../helpers";
import {ENV} from "../../../../constants";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import DialogComponent from "../../../../components/DialogComponent";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import {TsFileUploadConfig, TsFileUploadWrapperClass} from "../../../../classes/ts-file-upload-wrapper.class";

const {Option} = Select;

interface Document {
    _id: string;
    key: string;
    title: string;
}

export interface UploadedFile {
    documentId?: string;
    documentTitle?: string;
    file?: RcFile;
    expiry_date?: string; // Expiry date in ISO format
    setUploadedFiles?: any;
    uploadedFiles?: any;
    attachmentsDetails?: any
    hcpDetails?: any
    id?: any
    getAttachmentsDetails?: any
    apiUploadedFiles?:any;
    setApiUploadedFiles?:any;
}

// const documents: Document[] = [
//     {_id: "675a952b56ab064d19d6dfc2", key: "driver_license", title: "Driver License"},
//     {_id: "675a952b56ab064d19d6dfc3", key: "resume", title: "Resume"},
//     {_id: "675a952b56ab064d19d6dfc4", key: "physical_test", title: "Physical Test"},
//     {_id: "675a952b56ab064d19d6dfc5", key: "tb_test", title: "TB Test"},
//     {_id: "675a952b56ab064d19d6dfc6", key: "chest_xray", title: "Chest X-ray"},
//     {_id: "675a952b56ab064d19d6dfc7", key: "cpr_bls_card", title: "CPR/BLS Card"},
//     {_id: "675a952b56ab064d19d6dfc8", key: "ssn_card", title: "SSN Card"},
//     {_id: "675a952b56ab064d19d6dfc9", key: "covid_certificate", title: "Covid Certificate"},
//     {_id: "675a952b56ab064d19d6dfca", key: "covid_vaccine_card", title: "Covid Vaccine Card"},
//     {_id: "675a952b56ab064d19d6dfcb", key: "covid_test_result", title: "Covid Test Result"},
//     {_id: "675a952b56ab064d19d6dfcc", key: "live_scan", title: "Live Scan"},
//     {_id: "675a952b56ab064d19d6dfcd", key: "vaccine_exemption_letter", title: "Vaccine Exemption Letter"},
// ];

const HcpEditAttachmentComponentANTD = (props: UploadedFile) => {
    const {attachmentsDetails, id, getAttachmentsDetails} = props
    const {documents} = useSelector((state: any) => state.meta);
    // const { uploadedFiles = [], setUploadedFiles } = props; // Ensure uploadedFiles is an array
    const [availableDocuments, setAvailableDocuments] = useState<Document[]>(documents);
    const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
    // const [apiUploadedFiles, setApiUploadedFiles] = useState<UploadedFile[]>([]);
    const {uploadedFiles, setUploadedFiles,apiUploadedFiles,setApiUploadedFiles} = props;
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewFile, setPreviewFile] = useState<string | null>(null);
    const [fileUpload,setFileUpload]=useState<{wrapper:any} | null>(null);

    useEffect(()=>{
        if(documents){
            const updatedDocuments=documents?.map((doc:any)=>{
                if(doc.title === 'License'){
                    return {
                        ...doc,
                        title:'Nursing License'
                    }
                }
                return doc;
            })
            updatedDocuments.push({
                key:'others',
                _id:'others',
                title:'Add Additional Document'
            })

            const remainingDocs = updatedDocuments.filter(
                (doc: any) => ![...uploadedFiles, ...apiUploadedFiles].some(
                    (file: any) => file?.documentTitle === doc?.title
                )
            );

            setAvailableDocuments(remainingDocs);
        }
    },[documents,uploadedFiles, apiUploadedFiles])


    // Simulate fetching documents from API
    useEffect(() => {
        const mappedApiDocuments: any = attachmentsDetails.map((doc: any) => ({
            documentId: doc.file_key,
            documentTitle: doc.attachment_type === 'License' ? 'Nursing License' : doc?.attachment_type,
            file: {
                name: doc.file_name,
                type: doc.ContentType,
            },
            expiry_date: doc.expiry_date,
            url: doc.url,
        }));
        setApiUploadedFiles(mappedApiDocuments);
    }, [attachmentsDetails,setApiUploadedFiles]);


    const handleSelectChange = (value: string) => {
        setSelectedDocumentId(value);
    };


    const handleUpload = (file: RcFile) => {
        if (!selectedDocumentId) {
            message.error("Please select a document type first.");
            return false;
        }

        const selectedDocument = availableDocuments.find((doc) => doc._id === selectedDocumentId);

        if (selectedDocument) {
            // Prepare the file object to be added to the uploaded files state
            const newFile = {
                documentId: selectedDocument._id === "others" ? `others-${uuidv4()}` : selectedDocument._id,
                documentTitle: selectedDocument._id === 'others' ? "" : selectedDocument.title,
                submission_type:'documents_submission',
                file,
            };

            // Add the file to the uploaded files state
            setUploadedFiles((prev: any) => [...prev, newFile]);

            // Remove the selected document from the available documents if it's not 'others'
            if (selectedDocument._id !== 'others') {
                setAvailableDocuments((prev) =>
                    prev.filter((doc) => doc._id !== selectedDocumentId)
                );
            }

            // Reset the selected document ID
            setSelectedDocumentId(null);

            // Now handle the file upload logic (similar to the one in your OnOtherFileSelected function)
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: 'Data',
                uploadUrl: ENV.API_URL + 'facility/add',
                allowed_types: ['jpg', 'png', 'csv', 'pdf'],
                extraPayload: { expiry_date: '', file_type: "others" }
            };

            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: { wrapper: TsFileUploadWrapperClass }) => {
                setFileUpload((prevState: any) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return { wrapper: prevState.wrapper };
                    }
                    return prevState;
                });
            });

            uploadWrapper.onError = (err, heading) => {
                if (heading) {
                    CommonService.showToast(err, 'error');
                }
            };

            uploadWrapper.onSuccess = (resp) => {
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, 'success');
                }
            };

            uploadWrapper.onProgress = (progress) => {
                // You can implement file upload progress handling here if needed
            };

            // Add the upload wrapper to the state to track the upload status
            setFileUpload((prevState: any) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return { wrapper: newState };
            });

            // Show a success message once the file is successfully "prepared" for upload
            message.success(`${file.name} uploaded successfully.`);
        }

        return false; // Prevent automatic upload
    };

    const handleDeleteFile = useCallback((record: any) => {
        if (apiUploadedFiles.some((apiFile:any) => apiFile.documentId === record.documentId)) {
            // console.log(record?.key)
            setApiUploadedFiles((prev:any) =>
                prev.filter((file:any) => file.documentId !== record.documentId)
            );
            let payload = {
                file_key: record?.key,
                is_license_removal: record?.documentTitle === "Nursing License",
                is_tos_removal: record?.documentTitle === "Terms of Service",
                is_ica_removal: record?.documentTitle ==="ICA Terms",
                hcp_id: id
            };

            console.log(payload)

            CommonService._api
                .delete(ENV.API_URL + "deleteAttachment", payload)
                .then((resp) => {
                    getAttachmentsDetails();
                    CommonService.showToast(resp?.msg || "Staff Attachment Deleted", "info");
                })
                .catch((err) => {
                    CommonService.showToast(err?.msg || err?.error || "Error Deleting Staff Attachment", "error");
                    console.log(err);
                });
        } else {
            setUploadedFiles((prev: any) => prev.filter((file: any) => file.documentId !== record.documentId));
            const restoredDocument = documents.find((doc: any) => doc._id === record.documentId);
            if (restoredDocument) {
                setAvailableDocuments((prev) => [...prev, restoredDocument]);
            }
            message.success(`File for "${record.documentTitle}" deleted successfully.`);
        }

    }, [apiUploadedFiles, getAttachmentsDetails, id, setUploadedFiles, documents,setApiUploadedFiles]);

    const handleDateChange = useCallback((date: any | null, record: UploadedFile) => {
        const formattedDate = date ? date.format("YYYY-MM-DD") : null;

        if (apiUploadedFiles.some((apiFile:any) => apiFile.documentId === record.documentId)) {
            // Update in apiUploadedFiles if the file is from the API
            setApiUploadedFiles((prev:any) =>
                prev.map((file:any) =>
                    file.documentId === record.documentId ? { ...file, expiry_date: formattedDate } : file
                )
            );
        } else {
            // Update in uploadedFiles if the file is newly uploaded
            setUploadedFiles((prev: any) =>
                prev.map((file: any) =>
                    file.documentId === record.documentId ? { ...file, expiry_date: formattedDate } : file
                )
            );
        }
    }, [apiUploadedFiles, setUploadedFiles,setApiUploadedFiles]);

    const handleOthersTitleChange =useCallback((value: string, record: UploadedFile) => {
        setUploadedFiles((prev: any) =>
            prev.map((file: any) =>
                file.documentId === record.documentId
                    ? { ...file, documentTitle: value }
                    : file
            )
        );
    },[setUploadedFiles])


    const handleFilePreview = useCallback((record:any,index: any) => {
        let url:any;
        console.log(record,"RECORD")
        if(record?.url){
            url = record;
        }else{
            console.log("coming here")
            url= fileUpload?.wrapper[index]
            console.log(url,"URL")
        }
        console.log(url)
        setPreviewVisible(true)
        setPreviewFile(url)
    }, [fileUpload]);

    const closePreview = useCallback(() => {
        setPreviewVisible(false);
        setPreviewFile(null);
    }, []);

    const columns =useMemo(()=>{
        return [
            {
                title: "Document Title",
                dataIndex: "documentTitle",
                key: "documentTitle",
                render: (item: any, record: UploadedFile) => (
                    <div>
                        {record?.documentId?.startsWith("others") ? ( // Check for "others" documents
                            <Input
                                placeholder="Enter Document Title"
                                value={record.documentTitle || ""}
                                onChange={(e) => handleOthersTitleChange(e.target.value, record)} // Update the title
                            />
                        ) : (
                            <div>{item || "N/A"}</div>
                        )}
                    </div>
                ),
            },
            {
                title: "File Name",
                dataIndex: "file",
                key: "file",
                render: (file: RcFile,record:any,rowIndex:number) =>{
                    return (
                        <div
                            onClick={()=>handleFilePreview(record,rowIndex)}
                            style={{
                                textDecoration:'underline',
                                cursor:'pointer'
                            }}
                        >
                            {file.name || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: "Expiry Date",
                key: "expiry_date",
                render: (_: any, record: UploadedFile) => (
                    <DatePicker
                        // disabled={apiUploadedFiles.some((apiFile) => apiFile.documentId === record.documentId)}
                        style={{width: "100%"}}
                        onChange={(date:any) => handleDateChange(date, record)}
                        // onClick={() => {
                        //     setUploadedFiles((prev: any) =>
                        //         prev.map((file: any) =>
                        //             file.documentId === record.documentId
                        //                 ? {...file, expiry_date: null}
                        //                 : file
                        //         )
                        //     );
                        // }}
                        value={record.expiry_date ? dayjs(record.expiry_date) : null}
                        format="YYYY-MM-DD"
                    />
                ),
            },
            {
                title: "Action",
                key: "action",
                width:100,
                render: (_: any, record: UploadedFile) => (
                    <Button
                        type="primary"
                        danger
                        icon={<DeleteOutlined/>}
                        onClick={() => handleDeleteFile(record)}
                    >
                        Delete
                    </Button>
                ),
            },
        ]
    },[handleDeleteFile,handleOthersTitleChange,handleDateChange,handleFilePreview])


    return (
        <div>
            <DialogComponent open={previewVisible} cancel={closePreview} class="preview-content">
                <CustomPreviewFile cancel={closePreview} confirm={closePreview}
                                   previewData={previewFile}/>
            </DialogComponent>
            <div className={'mrg-bottom-20 d-flex justify-content-space-between'}>
                <div>
                    <Select
                        showSearch
                        placeholder="Select a document type"
                        value={selectedDocumentId || undefined}
                        onChange={handleSelectChange}
                        className={'mrg-right-15'}
                        filterOption={(input, option) =>
                            (option?.label as string).toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {availableDocuments.map((doc) => (
                            <Option key={doc._id} value={doc._id} label={doc.title}>
                                {doc.title}
                            </Option>
                        ))}
                    </Select>
                    <Upload
                        beforeUpload={handleUpload}
                        showUploadList={false}
                        accept=".pdf,.doc,.docx,.jpg,.png"
                    >
                        <Button type="primary" icon={<UploadOutlined/>} disabled={!selectedDocumentId}>
                            Upload File
                        </Button>
                    </Upload>
                </div>
            </div>
            <Table
                dataSource={[...uploadedFiles, ...apiUploadedFiles].map((file: any) => ({
                    ...file,
                    key: file.documentId,
                }))}
                columns={columns}
                pagination={{pageSize: 10}}
            />
        </div>
    );
};

export default HcpEditAttachmentComponentANTD;
