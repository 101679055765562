import {Button, Tooltip} from "@material-ui/core";
import {AddRounded, SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import DialogComponent from "../../../../components/DialogComponent";
import {ENV, ImageConfig} from "../../../../constants";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import CancelShiftRequirement from "../cancelShiftRequirement/CancelShiftRequirementComponent";
import "./openShiftsListScreen.scss";
import AddHcpToShifts from "../addHcpToShiftsDialog/AddHcpToShiftsDialog";
import ShowShiftRateOnMobileComponent from "../showShiftRateOnMobile/ShowShiftRateOnMobileComponent";
import {CssTextField} from "../../../../constants/data/styles";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
import ShiftFilterDrawerComponent from "../../shift-filter-drawer/ShiftFilterDrawerComponent";
import DrawerComponent from "../../../../components/drawer/DrawerComponent";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {Popover, TableColumnsType} from "antd";
import {useSessionStorage} from "../../../../components/useSessionStorage";
import OpenMenuListComponent from "../open-menu-list/OpenMenuListComponent";

// export const defaultSelectedStatusType = 'open';

const OpenShiftsListScreen = () => {
    const location=useLocation();
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [isRejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);
    const [isAddHcpToShiftsOpen, setIsAddHcpToShiftsOpen] = useState<boolean>(false);
    const [isHCPRateOnMobileOpen, setIsHCPRateOnMobileOpen] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);
    const [refreshToken,setRefreshToken]=useState<number>(0);
    const [prevLocation,setPrevLocation]=useState<string>("");
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [filterCount, setFilterCount] = useState<number>(0);
    const [filterData, setFilterData] = useSessionStorage<any>("vitaOpenShiftFilterData", {
        search: '',
        sort: {},
        status: ["open","PartiallyOpen"],
        limit: 10,
        new_shifts: CommonService.changeToUtcDate(new Date()),
        type:'facility'
    })

    useEffect(() => {
        let count=0;
        if(filterData?.status && filterData?.status?.length > 0) count++;
        if(filterData?.type) count++;
        if(filterData?.hcp_types && filterData?.hcp_types?.length > 0) count++;
        if(filterData?.start_date) count++;
        if(filterData?.end_date) count++;
        if(filterData?.facilities && filterData?.facilities?.length >0) count++;
        if(filterData?.shift_types && filterData?.shift_types?.length > 0) count++;
        if(filterData?.is_shift_rate !== null && filterData?.is_shift_rate !== undefined) count++;
        if(filterData?.requested_by) count++;
        setFilterCount(count)
    }, [filterData]);

    useEffect(() => {
        setFilterData((prev: any) => {
            const newUtcDate = CommonService.changeToUtcDate(new Date());
            if (filterData?.new_shifts && (!filterData?.start_date && !filterData?.end_date) && prev.new_shifts !== newUtcDate) {
                return {
                    ...prev,
                    new_shifts: newUtcDate,
                };
            }
            return prev;
        });
    }, [setFilterData,filterData?.new_shifts,filterData?.start_date,filterData?.end_date]);

    useEffect(() => {
        // Detect page change by comparing current location with previous location
        if (location.pathname !== prevLocation) {
            setPrevLocation(location.pathname); // Update the previous location state

            // Only reset the filterData when navigating different page
            if (location.pathname.startsWith('/shiftrequirementMaster') && (!filterData.status || !filterData.type)) {
                let updatedFilterData={...filterData};
                updatedFilterData.status=["open","PartiallyOpen"];
                updatedFilterData.type = "facility"
                setFilterData(updatedFilterData);
            }
        }
    }, [location, filterData, setFilterData, prevLocation]);


    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        // if (selectedRegion.length > 0) {
        //     const selectedRegions = selectedRegion;
        //     payload.regions = selectedRegions;
        // }
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);


    const checkForDisablingHcpButton = useCallback(() => {
        const statusArray = Array.isArray(filterData?.status) ? filterData.status : [];
        const hcpTypesArray = Array.isArray(filterData?.hcp_types) ? filterData.hcp_types : [];

        const isCancelledSelected = statusArray.includes('cancelled');
        const isUnfilledSelected = statusArray.includes('unfilled');
        const isStatusSelected = filterData?.type !== "facility";

        return !!(selectedShifts.length === 0 ||
            isCancelledSelected ||
            isStatusSelected ||
            isUnfilledSelected ||
            hcpTypesArray.length > 1 ||
            hcpTypesArray.length === 0);

    }, [filterData?.status, filterData?.hcp_types, filterData?.type, selectedShifts]);


    const checkForDisablingShowHcpRateButton = useCallback(() => {
        return selectedShifts.length === 0;
    }, [selectedShifts]);


    const checkForDisablingCancelButton = useCallback(() => {
        const statusArray = Array.isArray(filterData?.status) ? filterData.status : [];

        const isCancelledSelected = statusArray.includes('cancelled');
        const isUnfilledSelected = statusArray.includes('unfilled');

        return !!(selectedShifts.length === 0 || isCancelledSelected || isUnfilledSelected);

    }, [filterData?.status, selectedShifts]);


    useEffect(() => getFacilityData(), [getFacilityData]);

    const handleDownload = useCallback(() => {
        let payload: any = {};

        console.log(filterData,"FILTERDATA")

        // if (selectedRegion.length > 0) {
        //     payload.regions = selectedRegion;
        // }

        if (filterData?.facilities?.length > 0) {
            payload.facilities = filterData?.facilities;
        }

        if (filterData?.hcp_user_ids?.length > 0) {
            payload.hcp_user_ids = filterData?.hcp_user_ids;
        }


        if (filterData?.hcp_types?.length > 0) {
            //const hcpTitles = selectedHcps.map((item: any) => item.title);
            const combinedTitleIndex = filterData?.hcp_types?.indexOf("CNA/CHHA");
            if (combinedTitleIndex !== -1) {
                const combinedTitle = filterData?.hcp_types[combinedTitleIndex];
                payload.hcp_types = [
                    ...filterData?.hcp_types?.filter((title: any) => title !== "CNA/CHHA"), // Remove "CNA/CCHA"
                    "CNA", // Add "CNA"
                    "CHHA", // Add "CCHA"
                    combinedTitle, // Add "CNA/CCHA" separately
                ];
            } else {
                payload.hcp_types = filterData?.hcp_types;
            }
        }
        if (filterData?.status?.length > 0) {
            payload.status = filterData?.status;
        }
        if (filterData.is_shift_rate !== '') {
            payload.is_shift_rate = filterData.is_shift_rate;
        }

        if (filterData?.requested_by !== '') {
            payload.requested_by = filterData?.requested_by;
        }

        if (filterData?.type !== '') {
            payload.type = filterData?.type;
        }


        if (filterData?.start_date || filterData?.end_date) {
            let startDate = moment(filterData?.start_date).format("YYYY-MM-DD");
            let endDate = moment(filterData?.end_date).format("YYYY-MM-DD");
            if (!filterData?.end_date) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }

        if (filterData?.shift_types?.length > 0) {
            payload.shift_types = filterData?.shift_types;
        }
        if (filterData?.search !== "") {
            // payload.search = shiftSearch;
            payload.search = filterData?.search;
        }
        setIsDownloading(true);
        ApiService.post(ENV.API_URL + "requirement/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.error || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.error || "Error", "error");
            });
    }, [filterData]);

    const clearFilterValues = useCallback(() => {
        setFilterData((prevFilterData: any) => {
            const updatedFilterData = {
                ...prevFilterData,
            }
            // Dynamically remove keys if they exist
            const keysToRemove = [
                "start_date",
                "end_date",
                "facilities",
                "hcp_types",
                "shift_types",
                "requested_by",
                "is_shift_rate",
            ];

            keysToRemove.forEach((key: any) => delete updatedFilterData[key]);
            updatedFilterData.new_shifts = CommonService.changeToUtcDate(new Date());
            updatedFilterData.status=['open',"PartiallyOpen"];
            updatedFilterData.type='facility'

            return updatedFilterData
        });
    }, [setFilterData]);

    const resetFilters = () => {
        clearFilterValues();
    };

    const resetSelectedCount = () => {
        // setSelectedCount(-1);
        setSelectedShifts([]);
    };

    useEffect(() => {
        Communications.pageTitleSubject.next("Open");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    const cancelOpenShowHCPRateOnMobile = useCallback(() => {
        setIsHCPRateOnMobileOpen(false);
        setSelectedShifts([]);
        // setSelectedCount(-1);
    }, []);

    const cancelOpenAddHcpToShifts = useCallback(() => {
        setIsAddHcpToShiftsOpen(false);
        setSelectedShifts([]);
        // setSelectedCount(-1);
    }, []);

    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        setSelectedShifts([]);
        // setSelectedCount(-1);
    }, []);

    const confirmRejectShift = useCallback(() => {
        // setSelectedCount(-1);
        setSelectedShifts([]);
        setRefreshToken((prev:number)=>prev + 1)
        setRejectShiftOpen(false);
    }, [setSelectedShifts]);

    const confirmAddHcpToShifts = useCallback(() => {
        setSelectedShifts([]);
        // setSelectedCount(-1);
        setRefreshToken((prev:number)=>prev + 1)
        setIsAddHcpToShiftsOpen(false);
    }, []);

    const confirmOpenShowHCPRateOnMobile = useCallback(() => {
        setSelectedShifts([]);
        // setSelectedCount(-1);
        setRefreshToken((prev:number)=>prev + 1);
        setIsHCPRateOnMobileOpen(false);
    }, []);

    const handleMenuItemClick = useCallback((action: string) => {
        setPopoverVisible(false);
        switch(action){
            case 'shift_rate_on_mobile':
                setIsHCPRateOnMobileOpen(true);
                break;
            case 'cancel_shift':
                setRejectShiftOpen(true);
                break;
            case 'add_staff':
                setIsAddHcpToShiftsOpen(true);
                break;
            default:
                return ;
        }
    },[])


    const openShiftsListColumn: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Facility Name',
                width: 250,
                sorter: (a: any, b: any) =>
                    (a?.facility?.name || '').localeCompare(b?.facility?.name || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.facility?.name?.length > 20 ?
                                <Tooltip title={item?.facility?.name || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility?.name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.facility?.name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Date',
                width: 120,
                sorter: (a: any, b: any) =>
                    new Date(a?.shift_date || '').getTime() - new Date(b?.shift_date || '').getTime(),
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.getUtcDate(item["shift_date"]) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Staff Type',
                width: 160,
                sorter: (a: any, b: any) =>
                    (typeof a?.hcp_type === 'string' ? a.hcp_type : '').localeCompare(
                        typeof b?.hcp_type === 'string' ? b.hcp_type : ''
                    ),
                render: (item: any) => {
                    const hcpType = typeof item?.hcp_type === 'string' ? item.hcp_type : 'N/A';
                    return (
                        <div>
                            {hcpType.length > 15 ? (
                                <Tooltip title={hcpType}>
                                    <div className={'ellipses-for-table-data'}>
                                        {hcpType}
                                    </div>
                                </Tooltip>
                            ) : (
                                <div>{hcpType}</div>
                            )}
                        </div>
                    );
                },
            },
            {
                title: 'No of Staff',
                width: 140,
                sorter: (a: any, b: any) =>
                    (a?.hcp_count || 0) - (b?.hcp_count || 0),
                render: (item: any) => {
                    return (
                        <div>
                            {item["hcp_count"] || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Shift Hours',
                width: 170,
                sorter: (a: any, b: any) => {
                    const aStartTime = new Date(a?.shift_timings?.start_time).getTime();
                    const bStartTime = new Date(b?.shift_timings?.start_time).getTime();
                    return aStartTime - bStartTime;
                },
                render: (item: any) => {
                    const {
                        start_time,
                        end_time
                    } = CommonService.getUtcTimeInAMPM(item["shift_timings"]?.start_time, item["shift_timings"]?.end_time);

                    return (
                        <div>
                            {(start_time + " - " + end_time) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Shift Type',
                width: 150,
                sorter: (a: any, b: any) =>
                    (a?.shift_type || '').localeCompare(b?.shift_type || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.shift_type?.length > 15 ?
                                <Tooltip title={item?.shift_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.shift_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.shift_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Staff Filled',
                width: 120,
                // sorter: (a: any, b: any) =>
                //     (a?.approved_hcps || 0) - (b?.approved_hcps || 0),
                render: (item: any) => {
                    return (
                        <div>
                            {item["approved_hcps"] >= 0 ? item["approved_hcps"] + "/" + item["hcp_count"] : "N/A"}
                        </div>
                    )
                }
            },
            {
                title: 'Status',
                width: 120,
                // sorter: (a: any, b: any) =>
                //     (a?.status || '').localeCompare(b?.status || ''),
                render: (item: any) => {
                    return (
                        <div className={`${item["status"]}`}>
                            {item?.status}
                        </div>
                    )
                }
            },
            {
                title: 'Show Shift Rate',
                width: 150,
                // sorter: (a: any, b: any) =>
                //     (a?.is_shift_rate === b?.is_shift_rate ? 0 : a?.is_shift_rate ? -1 : 1),
                render: (item: any) => {
                    const isShowHcpRate = item["is_shift_rate"];
                    return (
                        <div className={`${isShowHcpRate === true ? "success" : "danger"}`}>
                            {isShowHcpRate ? "YES" : "NO"}
                        </div>
                    )
                }
            },
            {
                title: 'View Details',
                width: 140,
                render: (_: any, item: any, rowIndex: number) => {
                    return (
                        <div className={'display-center'}>
                            <Link to={"/shiftsRequirements/view/" + item["_id"]}
                                  id={"link_hospital_details" + rowIndex}
                                  style={{color: '#1e1e1e'}}
                            >
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </Link>
                        </div>
                    )
                }
            },
        ]
    }, [])


    return (
        <>
            <DialogComponent open={isRejectShiftOpen} cancel={cancelRejectShift} headerLabel={selectedShifts && selectedShifts?.length > 1 ? "Cancel Shifts" : "Cancel Shift"}>
                <CancelShiftRequirement cancel={cancelRejectShift} confirm={confirmRejectShift}
                                        selectedShifts={selectedShifts}/>
            </DialogComponent>

            <DialogComponent open={isAddHcpToShiftsOpen} cancel={cancelOpenAddHcpToShifts} maxWidth="md" headerLabel={`${selectedShifts?.length > 1 ? 'Add an Staff to Shifts':'Add an Staff to Shift'}`}>
                <AddHcpToShifts requirementType={filterData?.type} cancel={cancelOpenAddHcpToShifts}
                                confirm={confirmAddHcpToShifts}
                                selectedHcp={filterData?.hcp_types} selectedHcps={filterData?.hcp_types}
                                selectedShifts={selectedShifts}/>
            </DialogComponent>

            <DialogComponent open={isHCPRateOnMobileOpen} cancel={cancelOpenShowHCPRateOnMobile} maxWidth="sm" headerLabel={"Shift Details"}>
                <ShowShiftRateOnMobileComponent
                    cancel={cancelOpenShowHCPRateOnMobile}
                    confirm={confirmOpenShowHCPRateOnMobile}
                    selectedShifts={selectedShifts}
                    type={'multiple'}
                />
            </DialogComponent>
            <DrawerComponent isOpen={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                <ShiftFilterDrawerComponent
                    setOpenFilterDrawer={setOpenFilterDrawer}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    resetSelectedCount={resetSelectedCount}
                    isFacilityListLoading={isFacilityListLoading}
                    noStatus={false}
                    isOpenShift={true}
                    facilityList={facilityList}
                    hcpTypes={hcpTypes}
                    resetFilters={resetFilters}
                />
            </DrawerComponent>

            <div className={"open-shifts-list screen crud-layout pdd-30"}>
                <div className="custom-border pdd-20  pdd-bottom-0">
                    <div className="open-header">
                        <div className={'open-header-section'}>
                            <div className="mrg-left-5 filter">
                                <div>
                                    <div className="d-flex">
                                        <div className="d-flex position-relative">
                                            {!filterData.search ? (
                                                <div className={"search_icon"}>
                                                    <SearchRounded/>
                                                </div>
                                            ) : (
                                                <div className={"search_icon"}>
                                                    <ClearIcon
                                                        onClick={(event) => {
                                                            setFilterData({...filterData, search: '', page: 1})
                                                            setSelectedShifts([]);
                                                        }}
                                                        id="clear_facility_search"
                                                    />
                                                </div>
                                            )}
                                            <div>
                                                <CssTextField
                                                    defaultValue={""}
                                                    className="search-cursor searchField"
                                                    id="input_search_facility"
                                                    onChange={(event) => {
                                                        const value = event.target.value;
                                                        setFilterData({...filterData, search: value, page: 1});
                                                    }}
                                                    value={filterData.search}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    type={"text"}
                                                    placeholder={"Search Shift"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="shift-req-actions d-flex">
                                <div className={'mrg-left-10'}>
                                    <Button
                                        variant={"outlined"} id={'add_facility_btn'}
                                        color={"primary"}
                                        onClick={() => {
                                            setOpenFilterDrawer(true)
                                        }}
                                    >
                                        {filterCount > 0 &&
                                            <span className={'filter-btn-badge'}>{filterCount}</span>
                                        }
                                        &nbsp;&nbsp;Filters&nbsp;&nbsp;
                                    </Button>
                                </div>
                                <div className="actions pdd-right-5 mrg-left-10">
                                    <Tooltip title="Download Shifts List">
                                        <Button variant={"outlined"} color="primary" onClick={handleDownload}
                                                className={!isDownloading ? "" : "has-loading-spinner"}
                                                disabled={isDownloading}>
                                            &nbsp;Download
                                        </Button>
                                    </Tooltip>
                                </div>
                                <div className="mrg-left-10">
                                    <Tooltip title={"Add New Shift Requirement"}>
                                        <Button component={Link} to={"/shift/add"} variant={"contained"}
                                                color={"primary"}>
                                            <AddRounded/>
                                            &nbsp;&nbsp;Add New&nbsp;&nbsp;
                                        </Button>
                                    </Tooltip>
                                </div>
                                <div className={'mrg-left-10'}>
                                    <Popover
                                        trigger={'click'}
                                        placement={'bottomRight'}
                                        destroyTooltipOnHide={true}
                                        open={popoverVisible}
                                        onOpenChange={(visible) => setPopoverVisible(visible)}
                                        content={<OpenMenuListComponent onMenuItemClick={handleMenuItemClick}
                                                                        selectedShifts={selectedShifts}
                                                                        checkForDisablingHcpButton={checkForDisablingHcpButton}
                                                                        checkForDisablingShowHcpRateButton={checkForDisablingShowHcpRateButton}
                                                                        checkForDisablingCancelButton={checkForDisablingCancelButton}
                                                                        filterData={filterData}
                                        />}
                                    >
                                        <div className={'applicant-box'}
                                             onClick={() => setPopoverVisible(!popoverVisible)}>
                                            <ImageConfig.KebabIcon className={'applicant-edit-icon'}/>
                                        </div>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'open-table-wrapper mrg-top-20'}>
                        <AntTableComponent
                            key={refreshToken}
                            url={ENV.API_URL + "requirement/list"}
                            method={'post'}
                            extraPayload={filterData}
                            columns={openShiftsListColumn}
                            noDataText={'No Open Shift Found.'}
                            isRowSelection={true}
                            selectedRowKeys={selectedShifts}
                            setSelectedRowKeys={setSelectedShifts}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default OpenShiftsListScreen;
