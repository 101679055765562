import "./ApplicantFilterDrawerComponent.scss";
import {makeStyles} from "@material-ui/core/styles";
import {ENV,ImageConfig} from "../../../../../constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, {useCallback,useState,useEffect} from "react";
import {CommonService} from "../../../../../helpers";
import {ADMIN, HUMANRESOURCE, TERICADMIN,HRMANAGER} from "../../../../../helpers/common-service";
import AccessControlComponent from "../../../../../components/AccessControl";
import DatePicker from "react-datepicker";
import CancelIcon from "@material-ui/icons/Cancel";
import {DateRangeOutlined} from "@material-ui/icons";


interface ApplicantFilterDrawerComponentProps{
    filterData:any;
    setFilterData:any;
    setOpenFilterDrawer:any;
    hcpTypes:any[];
    resetFilters:any;
    stateList:any;
    isApprovedList?:boolean;
}

const useStyles = makeStyles((theme) => ({
    option: {
        '&[aria-selected="true"]': {
            backgroundColor: '#41D6C3',
            color: 'white',
        },
    },
}));

const statusList=[
    {name: 'Pending', code: 'pending'},
    {name: 'Approved', code: 'approved'},
    {name:'Rejected',code:'rejected'},
]

const jobStatusList=[
    {label: 'None', value: 'none'},
    {label: 'Assigned', value: 'assigned'},
    {label:'All',value:'all'},
]

const applicantStatusList=[
    {name:'New Lead',code:'new_lead'},
    {name:'Contacted',code:'contacted'},
    {name:'Pending Documents',code:'pending documents'},
    {name:'Unresponsive',code:'unresponsive'},
    {name:'Incorrect Information',code:'incorrect_information'},
    {name:'Not Qualified',code:'not_qualified'},
    {name:'Do Not Contact',code:'do_not_contact'},
    {name:'Expiring Credentials',code:'expiring_credentials'}
]

const sourceList=[
    {name:'Company Portal',code:'company_portal'},
    {name:'Bulk Upload',code:'bulk_upload'},
    {name:'Monster',code:'monster_jobs'}
]
const ApplicantFilterDrawerComponent=(props:ApplicantFilterDrawerComponentProps)=>{
    const {filterData,setFilterData,setOpenFilterDrawer,hcpTypes,resetFilters,stateList,isApprovedList}=props;
    const classes=useStyles();

    const [modifiedNcList,setModifiedNcList]=useState<any>([]);
    const [isNcListLoading,setIsNcListLoading]=useState<boolean>(false);
    const [hrList,setHrList]=useState<any>([]);
    const [isHrListLoading,setIsHrListLoading]=useState<boolean>(false);

    const getNcList=useCallback(()=>{
        setIsNcListLoading(true);
        CommonService._api.get(ENV.API_URL + `user/lite?is_active=true&roles[]=recruiter`).then((response:any)=>{
            const transformedData = response?.data?.map((item:any) => ({
                code: item?._id,
                name: item?.first_name + " " + item?.last_name,
            }));
            setModifiedNcList(transformedData || []);
        }).catch((error:any)=>{
            console.log(error)
        }).finally(()=>{
            setIsNcListLoading(false);
        })
    },[])

    useEffect(() => {
        getNcList()
    }, [getNcList]);

    const getHrList=useCallback(()=>{
        setIsHrListLoading(true);
        CommonService._api.get(ENV.API_URL + `user/lite?is_active=true&roles[]=hr`).then((response:any)=>{
            const transformedData = response?.data?.map((item:any) => ({
                code: item?._id,
                name: item?.first_name + " " + item?.last_name,
            }));
            setHrList(transformedData || []);
        }).catch((error:any)=>{
            console.log(error)
        }).finally(()=>{
            setIsHrListLoading(false);
        })
    },[])

    useEffect(() => {
        getHrList()
    }, [getHrList]);

    const formatFilterMenuList = useCallback((options: any[],filterData:any,key:string): any[] => {
        const selectedValues = filterData[key] || []; // Get the selected values for the key
        const allOptions = options?.map((option: any) => option?.code);

        let menuList = [...allOptions];

        if (selectedValues.length === allOptions.length) {
            // All options are selected, show "Clear All"
            menuList = ["Clear All", ...menuList];
        } else {
            // Not all options are selected, show "Select All"
            menuList = ["Select All", ...menuList];
        }

        return menuList;
    }, []);

    const formatFilterMenuListToHandleObj = useCallback(
        (options: any[], filterData: any, key: string): any[] => {
            const allOptions = options.filter(
                (item) => item.code !== "Select All" && item.code !== "Clear All"
            );
            console.log(allOptions,options);
            const selectedValues = filterData[key] || []; // Get selected values for the key
            const allCodes = allOptions.map((item) => item.code);


            let menuList = allOptions;

            // Check if all options are selected
            if (selectedValues.length === allCodes.length) {
                menuList = [{ name: "Clear All", code: "Clear All" }, ...allOptions];
            } else {
                // If not all are selected, show "Select All"
                menuList = [{ name: "Select All", code: "Select All" }, ...allOptions];
            }

            return menuList;
        },
        []
    );


    const handleFilterValueSelect = useCallback(
        (
            newValues: any,
            options: any[],
            event: any,
            key: string
        ) => {

            // Extract the 'code' from each selected object in newValues
            const selectedValues = newValues.map((value: any) =>
                typeof value === "string" ? value : value.code
            );

            if (selectedValues.includes("Select All") || selectedValues.includes("Clear All")) {
                if (selectedValues.includes("Select All")) {
                    const allOptions = options.map((item: any) => item.code);

                    // Update filterData for Select All
                    setFilterData((prevData: any) => ({
                        ...prevData,
                        [key]: allOptions,
                    }));
                }

                if (selectedValues.includes("Clear All")) {

                    // Clear filterData for Clear All
                    setFilterData((prevData: any) => {
                        const { [key]: removedKey, ...rest } = prevData; // Remove the key from the object
                        return rest;
                    });
                }

                return;
            }

            // Dynamically add the key to filterData only when values are selected
            setFilterData((prevData: any) => {
                if (selectedValues.length === 0) {
                    const { [key]: removedKey, ...rest } = prevData;
                    return rest;
                }

                // Special case for "hcp_types"
                if (key === "hcp_types") {
                    const combinedTitleIndex = selectedValues.indexOf("CNA/CHHA");

                    // If "CNA/CHHA" is selected, split it into "CNA" and "CHHA"
                    if (combinedTitleIndex !== -1) {
                        const processedValues = [
                            ...selectedValues.filter((title: any) => title !== "CNA/CHHA"), // Exclude "CNA/CHHA"
                            "CNA", // Add "CNA"
                            "CHHA", // Add "CHHA"
                            "CNA/CHHA", // Keep "CNA/CHHA" as is
                        ];
                        return {
                            ...prevData,
                            [key]: processedValues,
                        };
                    }
                }

                // Default case for other keys
                return {
                    ...prevData,
                    [key]: selectedValues,
                };
            });
        },
        [setFilterData]
    );



    const handleFilterValueSelectToHandleObj = useCallback(
        (
            newValues: any,
            options: any[],
            key: string, // The key to dynamically update in filterData
        ) => {
            const isSelectAll = newValues.some((f: any) => f.code === "Select All");
            const isClearAll = newValues.some((f: any) => f.code === "Clear All");

            if (newValues.length === 0) {
                // Remove the key from filterData if no values are selected
                setFilterData((prevData: any) => {
                    const {[key]: removedKey, ...rest} = prevData;
                    return rest;
                });
            }

            if (isSelectAll || isClearAll) {
                if (isSelectAll) {
                    const allOptions = options.filter((item) => {
                        return item.code !== "Select All" && item.code !== "Clear All";
                    });

                    // Add all options to filterData under the specified key
                    setFilterData((prevData: any) => ({
                        ...prevData,
                        [key]: allOptions.map((item) => item.code), // Map to codes
                    }));
                }

                if (isClearAll) {

                    // Remove the key from filterData
                    setFilterData((prevData: any) => {
                        const {[key]: removedKey, ...rest} = prevData;
                        return rest;
                    });
                }

                return;
            }

            // For single select

            // Dynamically update filterData with the selected values
            setFilterData((prevData: any) => {
                // If no new values, remove the key from filterData
                if (newValues.length === 0) {
                    const {[key]: removedKey, ...rest} = prevData;
                    return rest;
                }

                // Otherwise, add or update the key in filterData
                return {
                    ...prevData,
                    [key]: newValues.map((item: any) => item.code), // Map to codes
                };
            });
        },
        [setFilterData]
    );

    const handleStatusChange = useCallback((value: any) => {
        let updatedFilterData = { ...filterData };

        if (value) {
            if (value.code === "all") {
                delete updatedFilterData.status;
            } else {
                updatedFilterData.status = value.code;
            }
        } else {
            if (!isApprovedList) {
                delete updatedFilterData.status;
            } else {
                delete updatedFilterData.status;
            }
        }

        setFilterData(updatedFilterData);
    }, [isApprovedList, filterData,setFilterData]);


    const handleJobsFilterSelection = useCallback((selectedOption: any) => {
        let updatedFilterData = {...filterData};
        if (selectedOption.value === '') {
            delete updatedFilterData.jobs
            setFilterData(updatedFilterData);
        } else {
            updatedFilterData.jobs = selectedOption.value;
            setFilterData(updatedFilterData);
        }
    }, [filterData, setFilterData])

    const handleDateChange = useCallback(
        (date: any, key: string) => {
            setFilterData((prevData: any) => ({
                ...prevData,
                [key]: date ? CommonService.convertToDateFromTimestamp(date) : null,
            }));
        },
        [setFilterData])

    const selectedHcpCodes = filterData?.hcp_types || [];
    const updatedSelectedHcp = hcpTypes?.filter(({ code }) => selectedHcpCodes.includes(code));

    const selectedStateCodes=filterData?.states || [];
    const updatedSelectedStates=stateList?.filter((item:any)=>selectedStateCodes.includes(item?.code))

    return (
        <div className={'facility-filter-component'}>
            <div className={'facility-filter-header-wrapper'}>
                <div className={'facility-filter-header'}>
                    <div className={'facility-filter-title'}>Filters</div>
                    <div className={'facility-filter-clear-all'}
                         onClick={() => {
                             resetFilters();
                             setOpenFilterDrawer(false);
                         }}
                    >Clear All</div>
                </div>
                <div
                    className={'facility-filter-close-btn'}
                    onClick={() => setOpenFilterDrawer(false)}
                >
                    <img src={ImageConfig.CrossIcon} alt={'cross-icon'}/>
                </div>
            </div>
            <div className={'facility-filter-fields-wrapper'}>
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_hcps"
                        value={updatedSelectedHcp?.map((staff: any) => staff.code)}
                        classes={{
                            option: classes.option,
                        }}
                        options={formatFilterMenuList(hcpTypes, filterData, "hcp_types")}
                        onChange={(e, newValue) =>
                            handleFilterValueSelect(
                                newValue,
                                hcpTypes,
                                e,
                                "hcp_types"
                            )
                        }
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                    {selectedCount} {selectedCount === 1 ? 'staff type' : 'staff types'} selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.hcp_types?.length === 0 || !filterData?.hcp_types
                                ? "Staff Type"
                                : "";
                            return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                        }}
                        disableCloseOnSelect
                    />
                </div>
                <div>
                    <Autocomplete
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        // value={status}
                        value={statusList?.find((option: any) => option.code === filterData.status)}
                        options={statusList}
                        getOptionLabel={(option: any) => option.name}
                        placeholder={"Select Status"}
                        id="input_select_status"
                        className="mrg-top-10"
                        classes={{
                            option: classes.option,
                        }}
                        onChange={($event, value) => {
                            handleStatusChange(value)
                        }}
                        renderInput={(params) =>
                            <TextField {...params} id="select_status"
                                       variant="outlined"
                                       placeholder={"Status"}
                                       fullWidth/>}
                    />
                </div>
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_status"
                        classes={{
                            option: classes.option,
                        }}
                        getOptionLabel={(option) => option?.name}
                        getOptionSelected={(option, value) => option.name === value?.name}
                        // value={updatedApplicantSelectedStatus?.map((status: any) => status.code)}
                        value={applicantStatusList?.filter((status: any) => filterData?.applicant_status?.includes(status.code))}
                        options={formatFilterMenuListToHandleObj(applicantStatusList, filterData, "applicant_status")}
                        onChange={(e, newValue) =>
                            handleFilterValueSelectToHandleObj(
                                newValue,
                                applicantStatusList,
                                "applicant_status"
                            )
                        }
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                    {selectedCount} applicant status selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.applicant_status?.length === 0 || !filterData?.applicant_status
                                ? "Applicant Status"
                                : "";
                            return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                        }}
                        disableCloseOnSelect
                    />
                </div>
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_status"
                        classes={{
                            option: classes.option,
                        }}
                        getOptionLabel={(option) => option?.name}
                        getOptionSelected={(option, value) => option.name === value?.name}
                        // value={updatedApplicantSelectedStatus?.map((status: any) => status.code)}
                        value={sourceList?.filter((status: any) => filterData?.source?.includes(status.code))}
                        options={formatFilterMenuListToHandleObj(sourceList, filterData, "source")}
                        onChange={(e, newValue) =>
                            handleFilterValueSelectToHandleObj(
                                newValue,
                                sourceList,
                                "source"
                            )
                        }
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                    {selectedCount} source selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.source?.length === 0 || !filterData?.source
                                ? "Source"
                                : "";
                            return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                        }}
                        disableCloseOnSelect
                    />
                </div>
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        PaperComponent={({children}) => <Paper
                            style={{color: "#1e1e1e"}}>{children}</Paper>}
                        // value={isTimeSheetUploaded}
                        value={jobStatusList?.find((item: any) => item.value === filterData.jobs)}
                        options={jobStatusList}
                        getOptionLabel={(option: any) => option.label}
                        placeholder={"Jobs"}
                        id="input_select_status"
                        className="mrg-top-10"
                        onChange={($event, value) => {
                            handleJobsFilterSelection(value);
                        }}
                        classes={{
                            option: classes.option,
                        }}
                        renderInput={(params) => (
                            <TextField {...params} id="select_status" variant="outlined"
                                       placeholder={"Jobs"}/>
                        )}
                        disableCloseOnSelect
                    />
                </div>
                <AccessControlComponent role={[ADMIN, TERICADMIN, HUMANRESOURCE, HRMANAGER]}>
                    <div className="form-field-item">
                        <Autocomplete
                            disableClearable
                            className="mrg-top-10"
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            multiple
                            classes={{
                                option: classes.option,
                            }}
                            getOptionLabel={(option) => option?.name}
                            getOptionSelected={(option, value) => option.name === value?.name}
                            id="input_select_facility"
                            // value={selectedFacilities}
                            value={modifiedNcList?.filter((item: any) => filterData?.recruiter_ids?.includes(item.code))}
                            options={formatFilterMenuListToHandleObj(modifiedNcList, filterData, 'recruiter_ids')}
                            onChange={(e, newValue) =>
                                handleFilterValueSelectToHandleObj(
                                    newValue,
                                    modifiedNcList,
                                    "recruiter_ids"
                                )
                            }
                            renderTags={(value: any, getTagProps: any) => {
                                const selectedCount = value.length;
                                return selectedCount > 0 ? (
                                    <span {...getTagProps({index: 0})}>
                                    {selectedCount} {selectedCount === 1 ? 'recruiter' : 'recruiters'} selected
                                </span>
                                ) : null;
                            }}
                            renderInput={(params: any) => {
                                const placeholder = filterData?.recruiter_ids?.length === 0 || !filterData?.recruiter_ids
                                    ? "Recruiters"
                                    : "";
                                return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                            }}
                            disableCloseOnSelect
                        />
                        {isNcListLoading && (
                            <div className="loading-text-wrapper">
                                <span className="loading-text">loading...</span>
                            </div>
                        )}
                    </div>
                </AccessControlComponent>
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_hcps"
                        value={updatedSelectedStates?.map((state: any) => state.code)}
                        classes={{
                            option: classes.option,
                        }}
                        options={formatFilterMenuList(stateList, filterData, "states")}
                        onChange={(e, newValue) =>
                            handleFilterValueSelect(
                                newValue,
                                stateList,
                                e,
                                "states"
                            )
                        }
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                    {selectedCount} {selectedCount === 1 ? 'state' : 'states'} selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.states?.length === 0 || !filterData?.states
                                ? "States"
                                : "";
                            return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                        }}
                        disableCloseOnSelect
                    />
                </div>
                <AccessControlComponent role={[ADMIN, TERICADMIN, HUMANRESOURCE, HRMANAGER]}>
                    <div className="form-field-item">
                        <Autocomplete
                            disableClearable
                            className="mrg-top-10"
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            multiple
                            classes={{
                                option: classes.option,
                            }}
                            getOptionLabel={(option) => option?.name}
                            getOptionSelected={(option, value) => option.name === value?.name}
                            id="input_select_facility"
                            // value={selectedFacilities}
                            value={hrList?.filter((item: any) => filterData?.hr_ids?.includes(item.code))}
                            options={formatFilterMenuListToHandleObj(hrList, filterData, 'hr_ids')}
                            onChange={(e, newValue) =>
                                handleFilterValueSelectToHandleObj(
                                    newValue,
                                    hrList,
                                    "hr_ids"
                                )
                            }
                            renderTags={(value: any, getTagProps: any) => {
                                const selectedCount = value.length;
                                return selectedCount > 0 ? (
                                    <span {...getTagProps({index: 0})}>
                                    {selectedCount} {selectedCount === 1 ? "hr" : "hrs"} selected
                                </span>
                                ) : null;
                            }}
                            renderInput={(params: any) => {
                                const placeholder = filterData?.hr_ids?.length === 0 || !filterData?.hr_ids
                                    ? "HR"
                                    : "";
                                return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                            }}
                            disableCloseOnSelect
                        />
                        {isHrListLoading && (
                            <div className="loading-text-wrapper">
                                <span className="loading-text">loading...</span>
                            </div>
                        )}
                    </div>
                </AccessControlComponent>
                <div className="form-field-item">
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Created On"
                        className="custom-date-input"
                        selected={filterData?.created_at && new Date(filterData?.created_at)}
                        onChange={(date) => handleDateChange(date, "created_at")}
                    />
                    {filterData?.created_at &&
                        <CancelIcon style={{cursor: "pointer"}} className={"custom-date-icon"}
                                    onClick={() => {
                                        let updatedFilterData = {...filterData};
                                        delete updatedFilterData?.created_at;
                                        setFilterData(updatedFilterData)
                                    }}
                        />}
                    {!filterData?.created_at &&
                        <DateRangeOutlined className="custom-date-icon" fontSize="small" color="action"/>}
                </div>
                <div className="form-field-item">
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Last Touch Point"
                        className="custom-date-input"
                        selected={filterData?.last_note_updated_at && new Date(filterData?.last_note_updated_at)}
                        onChange={(date) => handleDateChange(date, "last_note_updated_at")}
                    />

                    {filterData?.last_note_updated_at &&
                        <CancelIcon style={{cursor: "pointer"}} className={"custom-date-icon"}
                                    onClick={() => {
                                        let updatedFilterData = {...filterData};
                                        delete updatedFilterData?.last_note_updated_at;
                                        setFilterData(updatedFilterData)
                                    }}
                        />}
                    {!filterData?.last_note_updated_at &&
                        <DateRangeOutlined className="custom-date-icon" fontSize="small" color="action"/>}
                </div>
            </div>
        </div>
    )
}

export default ApplicantFilterDrawerComponent