import {Button, Tooltip} from "@material-ui/core";
import {FormikHelpers} from "formik";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import "react-phone-number-input/style.css";
import {useHistory} from "react-router";
import ScrollToTop from "react-scroll-to-top";
import {TsFileUploadConfig, TsFileUploadWrapperClass} from "../../../../classes/ts-file-upload-wrapper.class";
import DialogComponent from "../../../../components/DialogComponent";
import LoaderComponent from "../../../../components/LoaderComponent";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import LeavePageConfirmationComponent from "../../../../components/shared/LeavePageConfirmationComponent";
import {ENV, ImageConfig} from "../../../../constants";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import "./AddApplicantComponent.scss";
import {HcpItemAddType} from "./AddApplicantInitialValues";
import AddHcpBasicDetailsComponent from "./BasicDetails/AddHcpBasicDetailsComponent";
import EducationAddComponent from "./EducationAddComponent/EducationAddComponent";
import ExperienceAddComponent from "./ExperienceAddComponent/ExperienceAddComponent";
import ReferenceAddComponent from "./ReferenceAddComponent/ReferenceAddComponent";
import VolunteerExperienceAddComponent from "./VolunteerExperienceAddComponent/VolunteerExperienceAddComponent";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
import {UploadedFile} from "./hcp-attachment-component/HcpAttachmentComponentANTD";
import {getFacilityDocuments} from "../../../../store/actions/meta.action";

const AddApplicantComponent = () => {
    const dispatch = useDispatch();
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
    const history = useHistory();
    const [educations, setEducations] = useState<any>([]);
    const [experiences, setExperiences] = useState<any>([]);
    const [specialities, setSpecialities] = useState<any>([]);
    const [volunteerExperiences, setVolunteerExperiences] = useState<any>([]);
    const [references, setReferences] = useState<any>([]);
    const [required_attachments, setRequiredAttachments] = useState<any>([
        {name: "Resume", index: -1},
        {name: "Physical Test", index: -1},
        {name: "TB Test", index: -1},
        {name: "Chest X-ray", index: -1},
        {name: "CPR/BLS Card", index: -1},
        {name: "Driver License", index: -1},
        {name: "SSN Card", index: -1},
        {name: "Nursing License", index: -1},
        {name: "License", index: -1},
        {name: "Covid Certificate", index: -1},
        {name: "Covid Vaccine Card", index: -1},
        {name: "Covid Test Result", index: -1},
        {name: "Live Scan", index: -1},
        {name: "Vaccine Exemption Letter", index: -1},
        {name: "Others", index: -1, isAdditionalAttachment: true},

    ]);
    const {specialitiesMasterList, specIsLoading} = useSelector((state: StateParams) => state.meta);
    const [hcpTypeSpecialities, setHcpTypeSpecialities] = useState<any>([]);
    const [fileUpload, setFileUpload] = useState<{ wrapper: any } | null>(null);
    const [contractFile, setContractFile] = useState<{ wrapper: any } | null>(null);
    const [isHcpSubmitting, setIsHcpSubmitting] = useState<boolean>(false);
    const [expInYears, setExpInYears] = useState<any>(0);
    // const [hcpTypes, setHcpTypes] = useState<any>([]);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [state, setState] = useState<any>([]);
    const {regions, isLoading} = useSelector((state: StateParams) => state.meta);
    const [open, setOpen] = useState<boolean>(false);
    const [previewFileData, setPreviewFile] = useState<any | null>(null);
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const [scrollToError, setScrollToError] = useState<boolean>(false);
    const [otherDocuments, setOtherDocuments] = useState<any>([]);


    // const getSpecialities = useCallback(() => {
    //     CommonService._api
    //         .get(ENV.API_URL + "meta/hcp-specialities")
    //         .then((resp) => {
    //             setSpecialitiesMaster(resp.data || []);
    //             setSpecIsLoading(false);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);

    const getState = useCallback(() => {
        CommonService._api
            .get(ENV.API_URL + "states")
            .then((resp) => {
                console.log(resp);
                setState(resp.data || []);
                // setRegIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        dispatch(getFacilityDocuments(false));
    }, [dispatch]);

    useEffect(() => {
        getState();
    }, [getState]);

    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);
    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const onAddEducation = useCallback((education: any, hcpId: string) => {
        return new Promise((resolve, reject) => {
            ApiService.post(ENV.API_URL + "hcp/" + hcpId + "/education", education)
                .then((resp: any) => {
                    console.log(resp);
                    if (resp && resp.success) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }, []);

    const addEducations = useCallback(
        (hcpId: string) => {
            return (educations || []).map((value: any) => onAddEducation(value, hcpId));
        },
        [educations, onAddEducation]
    );

    const onAddExperience = useCallback((experience: any, hcpId: string) => {
        return new Promise((resolve, reject) => {
            ApiService.post(ENV.API_URL + "hcp/" + hcpId + "/experience", experience)
                .then((resp: any) => {
                    if (resp && resp.success) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }, []);

    const addExperiences = useCallback(
        (hcpId: string) => {
            return (experiences || []).map((value: any) => {
                return onAddExperience(value, hcpId);
            });
        },
        [experiences, onAddExperience]
    );

    const onAddVolunteerExperience = useCallback((experience: any, hcpId: string) => {
        return new Promise((resolve, reject) => {
            ApiService.post(ENV.API_URL + "hcp/" + hcpId + "/experience", experience)
                .then((resp: any) => {
                    if (resp && resp.success) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }, []);

    const addVolunteerExperiences = useCallback(
        (hcpId: string) => {
            return (volunteerExperiences || []).map((value: any) => {
                return onAddVolunteerExperience(value, hcpId);
            });
        },
        [volunteerExperiences, onAddVolunteerExperience]
    );

    const onAddReference = useCallback((reference: any, hcpId: string) => {
        return new Promise((resolve, reject) => {
            ApiService.post(ENV.API_URL + "hcp/" + hcpId + "/reference", reference)
                .then((resp: any) => {
                    console.log(resp);
                    if (resp && resp.success) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }, []);

    const addReferences = useCallback((hcpId: string) => {
            return (references || []).map((value: any) => {
                return onAddReference(value, hcpId);
            });
        },
        [references, onAddReference]
    );

    const onAdd = (hcp: HcpItemAddType, {setSubmitting, setErrors, resetForm, setFieldValue}: FormikHelpers<any>) => {
        setIsHcpSubmitting(true);
        const AddHcp = () => {
            hcp.contract_details.signed_on = hcp?.contract_details?.signed_on ? moment(hcp?.contract_details?.signed_on).format("YYYY-MM-DD") : null;
            let payload: any = {};
            payload = hcp;
            payload = {
                ...payload,
                contact_number:payload?.contact_number ? ("+1" + payload?.contact_number) : null,
                dob:payload?.dob ? moment(payload?.dob).format("YYYY-MM-DD") : null,
                email: payload.email.toLowerCase(),
                professional_details: {
                    ...payload.professional_details,
                    experience: expInYears,
                    speciality: specialities.join(","),
                },
                coordinates:(hcp?.longitude && hcp?.latitude) ? [Number(hcp?.longitude), Number(hcp?.latitude)] : [],
            };

            ApiService.post(ENV.API_URL + "ats/addApplicant", payload)
                .then((resp: any) => {
                    if (resp && resp.success) {
                        const applicantId = resp?.data?._id;
                        let eduPromises = addEducations(applicantId);
                        let expPromises = addExperiences(applicantId);
                        let expVolPromises = addVolunteerExperiences(applicantId);
                        let refPromises = addReferences(applicantId);
                        let attachmentsPromises = handleAttachmentsUpload(applicantId, resp);
                        let contractPromises = handleContractUpload(applicantId);
                        let otherAttachmentsPromises = handleOtherAttachmentsUpload(applicantId, resp);

                        const promArray = [eduPromises, expPromises, expVolPromises, refPromises, attachmentsPromises, otherAttachmentsPromises, contractPromises];

                        Promise.all(promArray)
                            .then((res) => {
                                console.log(res);
                                CommonService.showToast("Success" || "Success", "success");
                                setTimeout(() => history.push("/applicant/view/" + applicantId), 600);
                            })
                            .catch((err) => {
                                CommonService.showToast("Error" || "Error", "error");
                            });
                    } else {
                        setSubmitting(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setSubmitting(false);
                    CommonService.handleErrors(setErrors, err);
                    setIsHcpSubmitting(false);
                    // CommonService.showToast(err?.msg || "Error", "error");
                    setScrollToError(prevState => !prevState);
                });
        };
        AddHcp();
    };

    const handleHcpTypeChange = (hcp_type: any) => {
        const selectedSpeciality = specialitiesMasterList[hcp_type];
        setHcpTypeSpecialities(selectedSpeciality);
    };

    // const handleContractFileUpload = useCallback((link: any) => {
    //     return new Promise((resolve, reject) => {
    //         const file = contractFile?.wrapper[0].file;
    //         delete file.base64;
    //         CommonService._api.upload(link, file, {"Content-Type": file?.type}).then((resp) => {
    //             console.log(resp);
    //             resolve(resp);
    //         })
    //             .catch((err) => {
    //                 // console.log(err);
    //                 reject(err);
    //             });
    //     });
    // }, [contractFile]);

    const onHandleAttachmentUpload = useCallback((value: any, hcpId: any, requiredAttachment: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const file = value?.file;
                const formData = new FormData();
                if (file) {
                    formData.append("attachment", file);
                    formData.append("attachment_type", value?.documentTitle === 'Nursing License' ? 'License' : value?.documentTitle);
                    if (value?.expiry_date) {
                        formData.append("expiry_date", value?.expiry_date);
                    }
                }
                // let payload = {
                //     file_name: value?.file?.name,
                //     file_type: value?.file?.type,
                //     attachment_type: value?.extraPayload?.file_type,
                //     expiry_date: requiredAttachment?.isAdditionalAttachment === true ? "" : value?.extraPayload?.expiry_date,
                // };
                CommonService._api.upload(ENV.API_URL + "hcp/" + hcpId + "/attachment", formData).then((resp) => {
                    resolve(resp);
                })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const onHandleOtherAttachmentUpload = useCallback((value: any, index: any, hcpId: any, requiredAttachment: any) => {
        // return new Promise(async (resolve, reject) => {
        //     try {
        //         let payload = {
        //             file_name: value?.file?.name,
        //             file_type: value?.file?.type,
        //             attachment_type: value?.extraPayload?.file_type,
        //             expiry_date: "",
        //         };
        //         CommonService._api.post(ENV.API_URL + "hcp/" + hcpId + "/attachment", payload).then((resp) => {
        //             if (value) {
        //                 const file = value?.file;
        //                 delete file.base64;
        //                 CommonService._api.upload(resp.data, file, {"Content-Type": value?.file?.type}).then((resp) => {
        //                     resolve(resp);
        //                 })
        //                     .catch((err) => {
        //                         console.log(err);
        //                     });
        //             }
        //         })
        //             .catch((err) => {
        //                 console.log(err);
        //             });
        //     } catch (error) {
        //         reject(error);
        //     }
        // });
        return new Promise(async (resolve, reject) => {
            try {
                const file = value?.file;
                const formData = new FormData();
                if (file) {
                    formData.append("attachment", file);
                    formData.append("attachment_type", value?.extraPayload?.doc_name);
                    formData.append("expiry_date", value?.extraPayload?.expiry_date);
                    //  formData.append("expiry_date",requiredAttachment?.isAdditionalAttachment === true ? "" : value?.extraPayload?.expiry_date)
                }
                // let payload = {
                //     file_name: value?.file?.name,
                //     file_type: value?.file?.type,
                //     attachment_type: value?.extraPayload?.file_type,
                //     expiry_date: requiredAttachment?.isAdditionalAttachment === true ? "" : value?.extraPayload?.expiry_date,
                // };
                CommonService._api.upload(ENV.API_URL + "hcp/" + hcpId + "/attachment", formData).then((resp) => {
                    resolve(resp);
                })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const handleAttachmentsUpload = useCallback((hcpId: any, hcpResp: any) => {
        let promArray: any = [];
        uploadedFiles?.forEach((value: any, index: any) => {
            promArray.push(onHandleAttachmentUpload(value, hcpId, value));
        });

        return promArray;
    }, [onHandleAttachmentUpload, uploadedFiles]);

    const handleOtherAttachmentsUpload = useCallback((hcpId: any, hcpResp: any) => {
        let promArray: any = [];
        otherDocuments?.forEach((value: any, index: any) => {
            if (value?.index !== -1) {
                promArray.push(onHandleOtherAttachmentUpload(fileUpload?.wrapper[value?.index], index, hcpId, value));
            }
        });

        return promArray;
    }, [fileUpload?.wrapper, onHandleOtherAttachmentUpload, otherDocuments]);

    const handleContractUpload = useCallback((hcpId: any) => {
        return new Promise((resolve, reject) => {
            const file = contractFile?.wrapper[0]?.file;
            // console.log(contractFileUpload?.wrapper);
            const formData = new FormData();
            if (file) {
                formData.append("contract", file);
            }
            if (contractFile?.wrapper.length > 0) {
                CommonService._api
                    .upload(ENV.API_URL + "hcp/" + hcpId + "/contract", formData)
                    .then((resp) => {
                        resolve(resp);
                    })
                    .catch((err) => {
                        CommonService.showToast(err || "Error", "error");
                        reject(err);
                    });
            } else {
                resolve(null);
            }

        });
    }, [contractFile?.wrapper]);

    const OnContractFileUpload = (files: File[]) => {
        for (let file of files) {
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: "Data",
                uploadUrl: ENV.API_URL + "facility/add",
                allowed_types: ["jpg", "png", "csv", "pdf"],
                extraPayload: {expiry_date: ""},
            };
            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: {
                wrapper: TsFileUploadWrapperClass
            }) => {
                setContractFile((prevState) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return {wrapper: prevState.wrapper};
                    }
                    return prevState;
                });
            });
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, "error");
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                console.log(resp, "contract");
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, "success");
                }
            };
            uploadWrapper.onProgress = (progress) => {
                // console.log('progress', progress);
            };
            setContractFile((prevState) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
            // uploadWrapper.startUpload();
        }
    };

    const deleteContractFile = (temp: any) => {
        let data = contractFile?.wrapper.filter((_: any, index: any) => index !== temp);
        setContractFile((prevState) => {
            return {wrapper: [...data]};
        });
    };

    const calculateExperience = (experiences: any[]) => {
        let totalExp = 0;
        let showExp = '';
        for (let experience of experiences) {
            if (experience.still_working_here === "0" && experience.exp_type === "fulltime") {
                let start_date = new Date(experience.start_date);
                let end_date = new Date(experience.end_date);
                let y1 = start_date.getFullYear();
                let y2 = end_date.getFullYear();
                let m1 = start_date.getMonth();
                let m2 = end_date.getMonth();
                let diff = ((y2 - y1) * 12) + ((m2 - m1));
                let diffYear = Math.floor(diff / 12);
                let diffMonth = diff % 12;
                totalExp = totalExp + diffYear * 12 + diffMonth;
                const yearOfExp = Math.floor(totalExp / 12);
                const montOfExp = totalExp % 12;
                let exp = `${yearOfExp}.${montOfExp}`;
                showExp = `${exp}`;
            }
        }
        return showExp;
    };

    const handleCalcExperience = (exp: any) => {
        const res = calculateExperience(exp);
        setExpInYears(res);
        console.log(res);
    };

    const handleCalcSpecialities = (exp: any) => {
        let specialities = exp.map((item: any) => item?.specialisation);
        let filteredSpecs = specialities.filter((spec: any) => spec !== "None");
        setSpecialities(filteredSpecs);
    };

    // const getHcpTypes = useCallback(() => {
    //     CommonService._api
    //         .get(ENV.API_URL + "meta/hcp-types")
    //         .then((resp) => {
    //             setHcpTypes(resp.data || []);
    //             setHcpTypesLoading(false);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);

    const openAdd = useCallback(() => {
        setIsAddOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        history.push("/applicants");
    }, [history]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Add Applicant");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [scrollToError]);

    if (specIsLoading || isLoading) {
        return <LoaderComponent/>;
    }

    return (
        !specIsLoading &&
        !isLoading && (
            <div className={'add-hcp-component'}>
                <div className={'add-header-wrapper'}>
                    <div className={'add-header-back-wrapper'} onClick={openAdd}>
                        <img src={ImageConfig.BackIcon} alt="back"/>
                        <div className={'add-header-back'}>
                            Back
                        </div>
                    </div>
                    <div className={'add-header-title'}>Add Applicant</div>
                    <div className={'add-header-btn-wrapper mrg-right-30'}>
                        <Tooltip title={"Cancel"}>
                            <Button size="large" onClick={openAdd} variant={"outlined"} color="primary"
                                    id="btn_hcp_add_cancel">
                                {"Cancel"}
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Save Changes"}>
                            <Button
                                disabled={isHcpSubmitting}
                                form="add-hcp-form"
                                type="submit"
                                id="btn_hcp_add_save"
                                size="large"
                                variant={"contained"}
                                color={"primary"}
                                className={isHcpSubmitting ? "has-loading-spinner" : ""}
                            >
                                {isHcpSubmitting ? "Saving" : "Save"}
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                    <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile}
                                       previewData={previewFileData}/>
                </DialogComponent>
                {/*<DialogComponent open={isAddOpen} cancel={cancelAdd}>*/}
                {/*    <LeavePageConfirmationComponent cancel={cancelAdd} confirm={confirmAdd} confirmationText={""}*/}
                {/*                                    notext={"Cancel"} yestext={"Leave"}/>*/}
                {/*</DialogComponent>*/}
                <LeavePageConfirmationComponent
                    visible={isAddOpen}
                    cancel={cancelAdd}
                    confirm={confirmAdd}
                    confirmationText="Are you sure you want to leave without saving?"
                    notext="Cancel"
                    yestext="Leave"
                />
                <div className="add-hcp screen">
                    <AddHcpBasicDetailsComponent
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                        contractFile={contractFile}
                        fileUpload={fileUpload}
                        setPreviewFile={setPreviewFile}
                        setOpen={setOpen}
                        onAdd={onAdd}
                        hcpTypes={hcpTypes}
                        regions={regions}
                        state={state}
                        specialities={specialities}
                        expInYears={expInYears}
                        required_attachments={required_attachments}
                        setRequiredAttachments={setRequiredAttachments}
                        OnContractFileUpload={OnContractFileUpload}
                        deleteContractFile={deleteContractFile}
                        setFileUpload={setFileUpload}
                        otherDocuments={otherDocuments}
                        setOtherDocuments={setOtherDocuments}
                    />
                    <div className="mrg-top-0 custom-border">
                        <p className="card-header">Education</p>
                        <EducationAddComponent educations={educations} setEducation={setEducations}/>
                    </div>
                    <div className="mrg-top-0 custom-border">
                        <p className="card-header">Work Experience</p>
                        <ExperienceAddComponent
                            handleCalcSpecialities={handleCalcSpecialities}
                            handleCalcExperience={handleCalcExperience}
                            hcpTypeSpecialities={hcpTypeSpecialities}
                            hcpTypes={hcpTypes}
                            handleHcpTypeChange={handleHcpTypeChange}
                            experiences={experiences}
                            setExperience={setExperiences}
                        />
                    </div>
                    <div className="mrg-top-0 custom-border">
                        <p className="card-header">Volunteer Experience</p>
                        <VolunteerExperienceAddComponent experiences={volunteerExperiences}
                                                         setExperience={setVolunteerExperiences}/>
                    </div>
                    <div className="mrg-top-0 custom-border">
                        <p className="card-header">References</p>
                        <ReferenceAddComponent references={references} setReference={setReferences}/>
                    </div>
                    {/*<div className="add-hcp-actions mrg-top-80">*/}
                    {/*    <Tooltip title={"Cancel"}>*/}
                    {/*        <Button size="large" onClick={openAdd} variant={"outlined"} color="primary"*/}
                    {/*                id="btn_hcp_add_cancel">*/}
                    {/*            {"Cancel"}*/}
                    {/*        </Button>*/}
                    {/*    </Tooltip>*/}
                    {/*    <Tooltip title={"Save Changes"}>*/}
                    {/*        <Button*/}
                    {/*            disabled={isHcpSubmitting}*/}
                    {/*            form="add-hcp-form"*/}
                    {/*            type="submit"*/}
                    {/*            id="btn_hcp_add_save"*/}
                    {/*            size="large"*/}
                    {/*            variant={"contained"}*/}
                    {/*            color={"primary"}*/}
                    {/*            className={isHcpSubmitting ? "has-loading-spinner" : ""}*/}
                    {/*        >*/}
                    {/*            {isHcpSubmitting ? "Saving" : "Save"}*/}
                    {/*        </Button>*/}
                    {/*    </Tooltip>*/}
                    {/*</div>*/}
                    <ScrollToTop smooth color="#10c4d3"/>
                </div>
            </div>)
    )
}

export default AddApplicantComponent;
