import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {ENV, ImageConfig} from "../../../../constants";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./JobListComponent.scss";
import {Drawer, Modal, Popover, TableColumnsType} from "antd";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import {Tooltip} from "@material-ui/core";
import SelectDropdownComponent from "../../../ant-component/select/SelectComponent";
import {useSessionStorage} from "../../../../components/useSessionStorage";
import JobFilterDrawerComponent from "./job-filter-drawer/JobFilterDrawerComponent";
import DrawerComponent from "../../../../components/drawer/DrawerComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
import {AddRounded, SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import {CssTextField} from "../../../../constants/data/styles";
import {MailOutlined, SendOutlined} from '@ant-design/icons'
import JobNotesComponent from "../job-view-details/job-notes/JobNotesComponent";
import ModalComponent from "../../../../components/modal/ModalComponent";
import TextEditorComponent from "../../../../components/text-editor/TextEditorComponent";
import JobMenuListComponent from "./job-menu-list/JobMenuListComponent";
import ChangeStatusComponent from "./change-status/ChangeStatusComponent";
import PostingConfigurationComponent from "./posting-configuration/PostingConfigurationComponent";
import ChangeRecruiterComponent from "./change-recruiter/ChangeRecruiterComponent";
import UpdatePayRateComponent from "./update-pay-rate/UpdatePayRateComponent";
import SingleEmailEditorComponent from "../job-view-details/email-editor-single/SingleEmailEditorComponent";
import PipelineComponent from "../../../../components/ats/pipeline/PipelineComponent";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import RequiredDocumentListComponent from "./required-document/RequiredDocumentListComponent";
import SelectComponent from "../../../ant-component/select/SelectComponent";
import moment from "moment";



const JobListComponent = () => {
    // const history=useHistory();
    const [selectedJobs, setSelectedJobs] = useState<any>([]);
    const [jobFilterData, setJobFilterData] = useSessionStorage<any>("vitaJobsFilterData",{
        search: '',
        sort: {},
        limit: 10,
        page:1
    });
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [rowStatus, setRowStatus] = useState<{ [key: string]: string }>({});
    const [openFilterDrawer,setOpenFilterDrawer]=useState<boolean>(false);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const [openApplicantListDrawer,setOpenApplicantListDrawer]=useState<boolean>(false);
    const [openNotesListDrawer,setOpenNotesListDrawer]=useState<boolean>(false);
    const [applicantFilterData,setApplicantFilterData]=useState<any>({
        search:'',
        limit:10,
        page:1,
    })
    const [selectedJobId,setSelectedJobId]=useState<string>('');
    const [openAddNotesModal,setOpenAddNotesModal]=useState<boolean>(false);
    const [notes, setNotes] = useState<string>("");
    const [openChangeRecruiterModal,setOpenChangeRecruiterModal]=useState<boolean>(false);
    const [openChangeStatusModal,setOpenChangeStatusModal]=useState<boolean>(false);
    const [openConfigModal,setOpenConfigModal]=useState<boolean>(false);
    const [openPayRateModal,setOpenPayRateModal]=useState<boolean>(false);
    const [openEmailDrawer,setOpenEmailDrawer]=useState<boolean>(false);
    const [selectedJobForEmail,setSelectedJobForEmail]=useState<any>(null);
    const [emailMessage,setEmailMessage]=useState<any>('');
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [applicantRowStatus, setApplicantRowStatus] = useState<{ [key: string]: string }>({});
    const [isEmailSending,setIsEmailSending]=useState<any>({});
    const [selectedFile, setSelectedFile] = useState<any>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [openDocumentDrawer,setOpenDocumentDrawer]=useState<boolean>(false)
    const [selectedHcpId,setSelectedHcpId]=useState<string>('');
    const [refreshToken,setRefreshToken]=useState<number>(0);
    const [filterCount,setFilterCount]=useState<number>(0);
    const [openApplicantEmailDrawer,setOpenApplicantEmailDrawer]=useState<boolean>(false);
    const [emailDetails,setEmailDetails]=useState<any>(null);
    const [templateList,setTemplateList]=useState<any>([]);
    const [selectedTemplate,setSelectedTemplate]=useState<any>('')

    useEffect(() => {
        let count = 0;

        if (jobFilterData.facilities && jobFilterData.facilities.length > 0) count++;
        if (jobFilterData.job_types && jobFilterData.job_types.length > 0) count++;
        if (jobFilterData.status && jobFilterData.status.length > 0) count++;
        if (jobFilterData.staff_types && jobFilterData.staff_types.length > 0) count++;
        if (jobFilterData.recruiter_ids && jobFilterData.recruiter_ids.length > 0) count++;

        if (jobFilterData.start_date) count++;
        if (jobFilterData.end_date) count++;
        if (jobFilterData.submissions) count++;
        if (jobFilterData.notes) count++;
        if(jobFilterData.created_at) count++;
        if(jobFilterData.last_note_updated_at) count++;

        setFilterCount(count);
    }, [jobFilterData]);

    const handlePreview = useCallback((fileUrl: any) => {
        setSelectedFile(fileUrl);
        setIsModalVisible(true);
    }, []);

    const handleDocumentDrawerOpen=useCallback((jobId:string,hcpId:string)=>{
        console.log(jobId,hcpId)
        setSelectedJobId(jobId);
        setSelectedHcpId(hcpId);
        setOpenApplicantListDrawer(false)
        setOpenDocumentDrawer(true);
    },[])

    const handleDocumentDrawerClose=useCallback(()=>{
        setSelectedJobId('');
        setSelectedHcpId('');
        setOpenDocumentDrawer(false);
    },[])

    const handleMenuItemClick = useCallback((action: string) => {
        setPopoverVisible(false);
        switch(action){
            case 'add_notes':
                setOpenAddNotesModal(true);
                break;
            case "change_recruiter":
                setOpenChangeRecruiterModal(true);
                break;
            case "post_multiple_places":
                setOpenConfigModal(true);
                break;
            case "change_status":
                setOpenChangeStatusModal(true);
                break;
            case "update_pay_rate":
                setOpenPayRateModal(true);
                break;
            default:
                return ;
        }
    },[])

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);

    useEffect(() => getFacilityData(), [ getFacilityData]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Job");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    const getTemplateList=useCallback(()=>{
        CommonService._api.get(ENV.API_URL+ 'ats/listTemplate').then((response:any)=>{
            const updatedList=response?.data?.map((item:any)=>({
                title:item?.template,
                code:item?._id,
                message:item?.template_format
            }))
            setTemplateList(updatedList);
        }).catch((error:any)=>{
            console.log(error)
        })
    },[])

    useEffect(() => {
        getTemplateList()
    }, [getTemplateList]);

    const handleStatusChange = useCallback((key: string, newStatus: string) => {
        setRowStatus((prev) => ({
            ...prev,
            [key]: newStatus, // Update only the selected row
        }));
        let payload: any = {};
        payload.job_status = newStatus;
        ApiService.put(ENV.API_URL + `ats/${key}/job`, payload)
            .then((resp: any) => {
                CommonService.showToast('Status updated successfully','success');
                setJobFilterData({...jobFilterData});
            })
            .catch((err: any) => {
                CommonService.showToast(err.error,'error');
            });
    }, [jobFilterData,setJobFilterData]);

    const handleOpenApplicantList=useCallback((jobId:any)=>{
        const updatedFilterData={...applicantFilterData};
        updatedFilterData.job_id=jobId
        setApplicantFilterData(updatedFilterData)
        setOpenApplicantListDrawer(true)
    },[applicantFilterData])

    const handleReadUnRead=useCallback((jobId:any)=>{
        try{
            let payload:any={
                is_job_view:true
            }
            ApiService.put(ENV.API_URL + `ats/${jobId}/job`, payload)
                .then((resp: any) => {
                    setJobFilterData({...jobFilterData})
                })
                .catch((err: any) => {
                    CommonService.showToast(err.error,'error')
                });
        }catch(err){
            console.log(err)
        }
    },[jobFilterData,setJobFilterData])

    const handleNotesDrawer=useCallback((jobId:string)=>{
        setOpenNotesListDrawer(true)
        setSelectedJobId(jobId)
    },[])

    const handleOpenMailDrawer=useCallback((job:any)=>{
        setOpenEmailDrawer(true);
        setSelectedJobForEmail(job)
    },[])

    const handleApplicantStatusChange = useCallback((key: string, newStatus: string) => {
        setApplicantRowStatus((prev) => ({
            ...prev,
            [key]: newStatus, // Update only the selected row
        }));
        let payload: any = {};
        payload.status = newStatus;
        ApiService.put(ENV.API_URL + `ats/${key}/jobSubmissons`, payload)
            .then((resp: any) => {
                setApplicantFilterData({...applicantFilterData})
                CommonService.showToast('Status updated successfully','success')
            })
            .catch((err: any) => {
                CommonService.showToast(err.error,'error')
            });
    }, [applicantFilterData]);

    const handleEmailTrigger=useCallback((id:any)=>{
        let payload={
            application_id:id
        }
        setIsEmailSending((prev:any)=>({
            ...prev,
            [id]:true
        }))
        CommonService._api.post(ENV.API_URL + 'ats/sentJobApplicationDoc',payload).then((response:any)=>{
            CommonService.showToast(response?.message,"success");
            setApplicantFilterData({...applicantFilterData})
        }).catch((error:any)=>{
            CommonService.showToast(error.error || error.message || 'Error','error')
        }).finally(()=>{
            setIsEmailSending((prev:any)=>({
                ...prev,
                [id]:false
            }))
        })
    },[applicantFilterData])

    const handleEmailDrawer=useCallback((details:any)=>{
        setOpenApplicantEmailDrawer(true)
        setEmailDetails(details);
        setOpenApplicantListDrawer(false)
    },[])

    const JobListTableColumns: TableColumnsType = useMemo(()=>{
        return [
            {
                title:'Job ID',
                width:120,
                sorter: (a: any, b: any) => {
                    const idA = a?._id?.substr(-7) || "";
                    const idB = b?._id?.substr(-7) || "";
                    return idA.localeCompare(idB);
                },
                render:(item:any)=>{
                    return (
                        <div>{item?._id?.substr(-7) || 'N/A'}</div>
                    )
                }
            },
            {
                title: "Facility",
                width: 250,
                sorter: (a: any, b: any) => (a?.facility?.name || "").localeCompare(b?.facility?.name || ""),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.facility?.name?.length > 25 ?
                                <Tooltip title={item?.facility?.name}>
                                    <div className={'ellipses-for-table-data '}>{item?.facility?.name}</div>
                                </Tooltip> :
                                <div className={'facility_name'}>
                                    {item?.facility?.name || 'N/A'}
                                </div>
                            }
                        </div>
                    );
                },
            },
            {
                title: "Job Title",
                width: 250,
                sorter: (a: any, b: any) => (a?.job_title || "").localeCompare(b?.job_title || ""),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.job_title?.length > 25 ?
                                <Tooltip title={item?.job_title}>
                                    <div className={'ellipses-for-table-data '}>{item?.job_title}</div>
                                </Tooltip> :
                                <div className={'facility_name'}>
                                    {item?.job_title || 'N/A'}
                                </div>
                            }
                        </div>
                    );
                },
            },
            {
                title:'Created On',
                width:130,
                sorter: (a: any, b: any) => {
                    return moment(a.created_at).unix() - moment(b.created_at).unix();
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {moment(item.created_at).utc().format("MM-DD-YYYY") || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: "Job Type",
                width: 120,
                sorter: (a: any, b: any) => (a?.job_type || "").localeCompare(b?.job_type || ""),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.job_type?.length > 25 ?
                                <Tooltip title={CommonService.formatTitleCase(item?.job_type)}>
                                    <div className={'ellipses-for-table-data '}>{CommonService.formatTitleCase(item?.job_type)}</div>
                                </Tooltip> :
                                <div className={'facility_name'}>
                                    {CommonService.formatTitleCase(item?.job_type) || 'N/A'}
                                </div>
                            }
                        </div>
                    );
                },
            },
            {
                title: "Staff Type",
                width: 170,
                sorter: (a: any, b: any) => (a?.staff_type || "").localeCompare(b?.staff_type || ""),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.staff_type?.length > 25 ?
                                <Tooltip title={item?.staff_type}>
                                    <div className={'ellipses-for-table-data '}>{item?.staff_type}</div>
                                </Tooltip> :
                                <div className={'facility_name'}>
                                    {item?.staff_type || 'N/A'}
                                </div>
                            }
                        </div>
                    );
                },
            },
            {
                title:'Recruiter',
                width:180,
                dataIndex:'recruiter_details',
                sorter: (a: any, b: any) => {
                    const nameA = `${a?.first_name ?? ''} ${a?.last_name ?? ''}`.toLowerCase();
                    const nameB = `${b?.first_name ?? ''} ${b?.last_name ?? ''}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                },
                render:(item:any)=>{
                    return(
                        <>
                            {item ?
                                <div>
                                    {(item?.first_name + item?.last_name)?.length > 20 ?
                                        <Tooltip title={item?.first_name + " " + item?.last_name}>
                                            <div className={'ellipses-for-table-data'}>
                                                {item?.first_name + " " + item?.last_name || 'N/A'}
                                            </div>
                                        </Tooltip>
                                        :
                                        <div>
                                            {item?.first_name + " " + item?.last_name || 'N/A'}
                                        </div>
                                    }
                                </div>
                                :
                                <div>N/A</div>}
                        </>
                    )
                }
            },
            {
                title: "Speciality",
                width: 200,
                sorter: (a: any, b: any) => (a?.speciality || "").localeCompare(b?.speciality || ""),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.speciality?.length > 25 ?
                                <Tooltip title={item?.speciality}>
                                    <div className={'ellipses-for-table-data'}>{item?.speciality}</div>
                                </Tooltip> :
                                <div className={'facility_name'}>
                                    {item?.speciality || 'N/A'}
                                </div>
                            }
                        </div>
                    );
                },
            },
            {
                title: "Start Date",
                width: 130,
                sorter: (a: any, b: any) => {
                    const getStartDate = (item: any) => {
                        if (item?.job_type === 'per_diem') return item?.per_diem_details?.start_date;
                        if (item?.job_type === 'contract') return item?.contract_details?.start_date;
                        return item?.job_start_date;
                    };
                    const dateA = getStartDate(a) ? moment(getStartDate(a)).unix() : 0;
                    const dateB = getStartDate(b) ? moment(getStartDate(b)).unix() : 0;
                    return dateA - dateB;
                },
                render: (item: any) => {
                    return <div>
                        {item?.job_type === 'per_diem' ?
                            <>
                                {CommonService.getUtcDate(item?.per_diem_details?.start_date) || "N/A"}
                            </> : item?.job_type === 'contract' ? <>{CommonService.getUtcDate(item?.contract_details?.start_date) || "N/A"}</>
                                :<>{CommonService.getUtcDate(item?.job_start_date) || "N/A"}</>
                        }
                    </div>;
                },
            },
            {
                title: "End Date",
                width: 130,
                sorter: (a: any, b: any) => {
                    const getEndDate = (item: any) => {
                        if (item?.job_type === 'per_diem') return item?.per_diem_details?.end_date;
                        if (item?.job_type === 'contract') return item?.contract_details?.end_date;
                        return item?.job_end_date;
                    };
                    const dateA = getEndDate(a) ? moment(getEndDate(a)).unix() : 0;
                    const dateB = getEndDate(b) ? moment(getEndDate(b)).unix() : 0;
                    return dateA - dateB;
                },
                render: (item: any) => {
                    return <div>
                        {item?.job_type === 'per_diem' ?
                            <>
                                {CommonService.getUtcDate(item?.per_diem_details?.end_date) || "N/A"}
                            </> : item?.job_type === 'contract' ? <>{CommonService.getUtcDate(item?.contract_details?.end_date) || "N/A"}</>
                                :<>{CommonService.getUtcDate(item?.job_end_date) || "N/A"}</>
                        }
                    </div>
                },
            },
            {
                title: "Remote Job",
                width: 120,
                sorter: (a: any, b: any) => (a?.remote_job || "").localeCompare(b?.remote_job || ""),
                render: (item: any) => {
                    return <div>{CommonService.formatTitleCase(item?.remote_job)  || 'N/A'}</div>;
                },
            },
            {
                title: "No. of Positions",
                width: 150,
                sorter: (a: any, b: any) => (a?.no_of_positions || 0) - (b?.no_of_positions || 0),
                render: (item: any) => {
                    return <div>{item?.no_of_positions || "N/A"}</div>;
                },
            },
            {
                title:'Client',
                width:120,
                sorter: (a: any, b: any) => (a?.client || "").localeCompare(b?.client || ""),
                render:(item:any)=>{
                    return <div>{item?.client || "N/A"}</div>
                }
            },
            {
                title:'Source',
                width:120,
                sorter: (a: any, b: any) => (a?.added_source || "").localeCompare(b?.added_source || ""),
                render:(item:any)=>{
                    return <div>{CommonService.formatTitleCase(item?.added_source) || 'N/A'}</div>
                }
            },
            {
                title:'Last Touch Point',
                width:150,
                sorter: (a: any, b: any) => moment(a?.last_note?.updated_at || 0).unix() - moment(b?.last_note?.updated_at || 0).unix(),
                render:(item:any)=>{
                    return (
                        <div>{CommonService.getUtcDate(item?.last_note?.updated_at) || 'N/A'}</div>
                    )
                }
            },
            {
                title: "Status",
                width: 150,
                render: (item: any) => {
                    return <div className={'table-under-select-box'}>
                        <SelectDropdownComponent
                            placeholder={'Select job status'}
                            value={rowStatus[item._id] || item.job_status}
                            options={[
                                {title: 'Open', code: 'open'},
                                {title: 'Closed', code: 'closed'},
                                {title: 'Filled', code: 'filled'},
                                {title: 'Hold', code: 'hold'},
                                {title: 'In Progress', code: 'in_progress'},
                            ]}
                            required={true}
                            size={"middle"}
                            displayWith={(option) => option.title}
                            valueExtractor={(option) => option.code}
                            onChange={(value) => handleStatusChange(item._id, value)}
                        />
                    </div>;
                },
            },
            {
                title: "View Details",
                width: 140,
                fixed: "right",
                render: (_: any, item: any, rowIndex: number) => {
                    return (
                        // <div className={"display-center"}>
                        //     <Link
                        //         to={`/jobs/view/${item?._id}`}
                        //         id={`link_job_details_${rowIndex}`}
                        //         style={{color: "#1e1e1e"}}
                        //     >
                        //         <ImageConfig.ArrowRightOutlinedIcon/>
                        //     </Link>
                        // </div>
                        <div className={'applicant-list-btn-wrapper'}>
                            <div className={'icon-wrapper'} onClick={() => handleOpenMailDrawer(item)}>
                                <Tooltip title={'Send Mail'}>
                                    <MailOutlined size={16}/>
                                </Tooltip>
                            </div>
                            {/*<div className={'icon-wrapper'}>*/}
                            {/*    <Tooltip title={item?.is_job_view ? 'Read' : 'Unread'}>*/}

                            {/*        {item?.is_job_view ? <FolderOpenOutlined size={18}/> :*/}
                            {/*            <FolderViewOutlined size={18}/>}*/}
                            {/*    </Tooltip>*/}
                            {/*</div>*/}
                            <Tooltip title={'Applicants List'}>
                                <div onClick={() => handleOpenApplicantList(item?._id)} className={'job-list-count'}>
                                    {item?.job_application_count}
                                </div>
                            </Tooltip>
                            <Tooltip title={'Notes'}>
                                <div onClick={() => handleNotesDrawer(item?._id)} className={'icon-wrapper'}>
                                    <ImageConfig.NoteIcon/>
                                </div>
                            </Tooltip>
                            <Link
                                to={`/jobs/view/${item?._id}`}
                                onClick={() => {
                                    if (!item?.is_job_view) {
                                        handleReadUnRead(item?._id)
                                    }
                                }}
                                id={`link_job_details_${rowIndex}`}
                                style={{
                                    color: "#1e1e1e",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </Link>
                        </div>
                    );
                },
            },
        ]
    }, [handleOpenApplicantList, handleStatusChange, rowStatus, handleReadUnRead, handleNotesDrawer,handleOpenMailDrawer])

    const JobSubmissionTableColumns: TableColumnsType = useMemo(() => {
        return [
            {
                title: "Name",
                width: 250,
                sorter: (a: any, b: any) => {
                    const nameA = `${a?.hcp_details?.first_name || ''} ${a?.hcp_details?.last_name || ''}`.toLowerCase();
                    const nameB = `${b?.hcp_details?.first_name || ''} ${b?.hcp_details?.last_name || ''}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                },
                render: (item: any) => {
                    return (
                        <div>
                            {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name)?.length > 25 ?
                                <Tooltip title={item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name}>
                                    <Link to={{
                                            pathname: `/applicant/view/${item?.hcp_details?._id}`,
                                            state: {
                                                from: 'job-list'
                                            }
                                       }} className={'ellipses-for-table-data '}
                                         style={{textDecoration:'underline',color:'#10c4d3',cursor:'pointer'}}>
                                        {item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name}
                                    </Link>
                                </Tooltip> :
                                <Link to={{
                                    pathname: `/applicant/view/${item?.hcp_details?._id}`,
                                    state: {
                                        from: 'job-list'
                                    }
                                }}
                                    className={'facility_name'}
                                     style={{textDecoration:'underline',color:'#10c4d3',cursor:'pointer'}}
                                >
                                    {item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name || 'N/A'}
                                </Link>
                            }
                        </div>
                    );
                },
            },
            {
                title: "Source",
                width: 200,
                sorter: (a: any, b: any) => (a?.applied_source || '').localeCompare(b?.applied_source || ''),
                render: (item: any) => {
                    return (
                        <div>
                            <div>{CommonService.formatTitleCase(item?.applied_source) || "N/A"}</div>
                        </div>
                    );
                },
            },
            {
                title: "Email",
                width: 300,
                sorter: (a: any, b: any) => (a?.hcp_details?.email || '').localeCompare(b?.hcp_details?.email || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.hcp_details?.email >20 ?
                                <Tooltip title={item?.hcp_details?.email}>
                                    <div className={'ellipses-for-email'}>
                                        {item?.hcp_details?.email}
                                    </div>
                                </Tooltip>:
                                <div>
                                    {item?.hcp_details?.email}
                                </div>
                            }
                        </div>
                    );
                },
            },
            {
                title: "Pay Rate",
                width: 200,
                render: (item: any) => {
                    const contractTotalHourlyRate=item?.job_details?.job_type === 'contract' &&
                        (item?.pay_rate?.travel_per_diems && item?.pay_rate?.travel_per_diems.reduce((sum:any, item:any) => sum + Number(item.rate), 0));
                    const contractTotalWeeklyRate=item?.job_details?.job_type === 'contract' &&
                        ( item?.pay_rate?.travel_per_diems && item?.pay_rate?.travel_per_diems.reduce((sum:any, item:any) => sum + Number(item.week_rate), 0));
                    return (
                        <div>
                            {item?.job_details?.job_type === 'per_diem' ?
                                <div>
                                    {item?.pay_rate?.total_pay_rate || 'N/A'}
                                </div> :
                                item?.job_details?.job_type === 'permanent' ?
                                    <div>
                                        {item?.pay_rate?.salary || 'N/A'}
                                    </div>:
                                    <div>
                                        {item?.pay_rate?.travel_per_diems ?
                                            <>
                                                ${contractTotalHourlyRate}/Hr - ${contractTotalWeeklyRate}/Week
                                            </>: 'N/A'}
                                    </div>
                            }
                        </div>
                    );
                },
            },
            {title:'Document Received',
                width:160,
                render:(item:any)=> {
                    return <div>
                        {item?.required_credentials_count !== 0 ?
                            <div
                                style={{textDecoration:"underline",cursor:'pointer',color:'#10c4d3'}}
                                onClick={()=>handleDocumentDrawerOpen(item?.job_id,item?.hcp_details?._id)}>
                                {item?.received_credentials_count}/{item?.required_credentials_count}
                            </div>: 'N/A'
                        }
                    </div>
                }
            },
            {
                title: "Application Status",
                width: 180,
                render: (item: any) => {
                    return <div className={'table-under-select-box'}>
                        {/*{CommonService.formatTitleCase(item?.status) || 'N/A'}*/}
                        <SelectDropdownComponent
                            placeholder={'Select job status'}
                            value={applicantRowStatus[item._id] || item.status}
                            options={[
                                {title: 'Open', code: 'open'},
                                {title: 'Closed', code: 'closed'},
                                {title: 'Filled', code: 'filled'},
                                {title: 'Hold', code: 'hold'},
                                {title: 'In Progress', code: 'in_progress'},
                                {title:'Submitted',code:'submitted'},
                                {title:'Offer Accepted',code:'offer_accepted'},
                                {title:'Offer Extended',code:'offer_extended'}
                            ]}
                            required={true}
                            size={"middle"}
                            displayWith={(option) => option.title}
                            valueExtractor={(option) => option.code}
                            onChange={(value) => handleApplicantStatusChange(item._id, value)}
                        />
                    </div>;
                },
            },
            {
                title:'Send Mail',
                width:100,
                render:(item:any)=>{
                    return (
                        <div style={{marginLeft:'10px'}}>
                            <Button onClick={()=>handleEmailDrawer(item)}>
                                <SendOutlined/>
                            </Button>
                        </div>
                    )
                }
            },
            {
                title:'Document Request',
                width:150,
                render:(item:any)=>{
                    return (
                        <div className={'display-center'}>
                            <Tooltip placement={'top'} title={`Send an email requesting ${item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name} to upload their documents.`}>
                                <Button variant={"outlined"}
                                        color={'primary'}
                                        className={!isEmailSending[item?._id] ? "" : "has-loading-spinner"}
                                        disabled={isEmailSending[item?._id]}
                                        onClick={()=>handleEmailTrigger(item?._id)}>
                                    <MailOutlined size={16}/>
                                </Button>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: "Action",
                width: 150,
                render: (item: any) => {
                    return(
                        <div className={'d-flex align-items-center'}>

                            <div className={'cursor-pointer underline-text color-underline-text mrg-left-25'}
                                 onClick={() => handlePreview(item)}>View Resume
                            </div>
                        </div>
                    );
                },
            },
            {
                expandableComponent : PipelineComponent
            }
        ]
    },[handleApplicantStatusChange,isEmailSending,applicantRowStatus,handleEmailTrigger,handlePreview,handleDocumentDrawerOpen,handleEmailDrawer]) ;

    const clearFilterValues = useCallback(() => {
        setJobFilterData((prevFilterData: any) => {
            const updatedFilterData = {
                ...prevFilterData,
            }
            const keysToRemove = [
                "facilities",
                "job_types",
                "status",
                "staff_types",
                "start_date",
                "end_date",
                "submissions",
                "notes",
                "recruiter_ids",
                "created_at",
                "last_note_updated_at"
            ]
            keysToRemove.forEach((key: any) => delete updatedFilterData[key])

            return updatedFilterData
        })
    }, [setJobFilterData])

    const resetFilters = () => {
        clearFilterValues();
    };

    const handleNotesClose = useCallback(() => {
        setNotes("");
        setOpenAddNotesModal(false)
        setSelectedJobs([])
    }, []);

    const handleNotesAdd = useCallback(() => {
        const payload = {
            notes: notes || "",
            job_ids:selectedJobs,
        };
        CommonService._api.post(ENV.API_URL+ `job/bulkNote`,payload)
            .then((response: any) => {
                CommonService.showToast(response.message, "success");
                setOpenAddNotesModal(false);
                setJobFilterData({...jobFilterData})
            })
            .catch((error: any) => {
                CommonService.showToast(error.error, "error");
            })
            .finally(() => {
                setNotes("");
                setSelectedJobs([])
            });
    }, [selectedJobs, notes,jobFilterData,setJobFilterData]);
    return (
        <>
            <Modal
                title="Resume Preview"
                open={isModalVisible}
                footer={null}
                onCancel={() => {
                    setIsModalVisible(false)
                    setSelectedFile(undefined)
                }}
                width={800}
                zIndex={99999999999}
            >
                <CustomPreviewFile
                    previewData={{
                        url: selectedFile?.resume_url,
                        file_type: selectedFile?.attachments[0]?.file_type,
                    }}/>
            </Modal>
            <DrawerComponent isOpen={openDocumentDrawer} title={'Documents'} showClose={true} onClose={handleDocumentDrawerClose} width={700}>
                <RequiredDocumentListComponent
                    hcpId={selectedHcpId}
                    jobId={selectedJobId}
                    setRefreshToken={setRefreshToken}
                />
            </DrawerComponent>
            <DrawerComponent  isOpen={openApplicantEmailDrawer} title={'Email'} showClose={false} onClose={()=> {
                setOpenApplicantEmailDrawer(false);
                setSelectedTemplate('');
                setEmailMessage('');
            }} width={650}
                              headerActions={
                                  <div className={'d-flex align-items-center template-select-wrapper'}>
                                      <SelectComponent
                                          options={templateList || []}
                                          value={selectedTemplate}
                                          placeholder={"Select Template"}
                                          allowClear={true}
                                          valueExtractor={(option:any)=>option.code}
                                          onChange={(value: any) => {
                                              const template = value
                                                  ? templateList?.find((t:any) => t.code === value)
                                                  : null;
                                              setSelectedTemplate(value ? value : '');
                                              setEmailMessage(value ? template?.message : '');
                                          }}
                                      />
                                      <div className={'mrg-left-20 cursor-pointer'} onClick={()=> {
                                          setOpenApplicantEmailDrawer(false)
                                          setSelectedTemplate('');
                                          setEmailMessage('');
                                      }}>
                                          <img src={ImageConfig.CrossIcon} alt="close"/>
                                      </div>
                                  </div>
                              }
            >
                <SingleEmailEditorComponent
                    recipientEmail={emailDetails?.hcp_details?.email || ''}
                    setOpenEmailDrawer={setOpenApplicantEmailDrawer}
                    emailMessage={emailMessage}
                    setEmailMessage={setEmailMessage}
                />
            </DrawerComponent>
            <DrawerComponent isOpen={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                <JobFilterDrawerComponent
                    filterData={jobFilterData}
                    setFilterData={setJobFilterData}
                    setOpenFilterDrawer={setOpenFilterDrawer}
                    hcpTypes={hcpTypes}
                    isFacilityListLoading={isFacilityListLoading}
                    facilityList={facilityList}
                    resetFilters={resetFilters}
                />
            </DrawerComponent>
            <DrawerComponent
                isOpen={openEmailDrawer}
                title={'Email'}
                showClose={true}
                onClose={()=> {
                    setOpenEmailDrawer(false)
                    setEmailMessage(null)
                }}
                width={650}
            >
                <SingleEmailEditorComponent
                    selectedJobForEmail={selectedJobForEmail}
                    setOpenEmailDrawer={setOpenEmailDrawer}
                    setEmailMessage={setEmailMessage}
                    emailMessage={emailMessage}
                    isEditable={true}/>
            </DrawerComponent>
            <Drawer title="List of Applicants" onClose={()=>setOpenApplicantListDrawer(false)} open={openApplicantListDrawer} destroyOnClose width={900} zIndex={999999999}>
                <div>
                    <div className="d-flex">
                        <div className="d-flex position-relative">
                            {!applicantFilterData.search ? (
                                <div className={"search_icon"}>
                                    <SearchRounded/>
                                </div>
                            ) : (
                                <div className={"search_icon"}>
                                    <ClearIcon
                                        onClick={(event) => {
                                            setApplicantFilterData({...applicantFilterData, search: '', page: 1})
                                        }}
                                        id="clear_facility_search"
                                    />
                                </div>
                            )}
                            <div>
                                <CssTextField
                                    defaultValue={""}
                                    className="search-cursor searchField"
                                    id="input_search_facility"
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setApplicantFilterData({...applicantFilterData, search: value, page: 1});
                                    }}
                                    value={applicantFilterData.search}
                                    variant={"outlined"}
                                    size={"small"}
                                    type={"text"}
                                    placeholder={"Search Applicants"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'mrg-top-20'}>
                        <AntTableComponent
                            key={refreshToken}
                            url={ENV.API_URL + "ats/jobSubmissons"}
                            method={'post'}
                            columns={JobSubmissionTableColumns || []}
                            extraPayload={applicantFilterData}
                            expandable={true}
                            noDataText={'No Applicants linked to this Job'}
                        />
                    </div>
                </div>
            </Drawer>
            <Drawer title={'Notes'} onClose={()=>setOpenNotesListDrawer(false)} open={openNotesListDrawer} destroyOnClose width={500} zIndex={99999}>
                <JobNotesComponent
                    id={selectedJobId}
                    showTitle={false}
                    filterData={jobFilterData}
                    setFilterData={setJobFilterData}/>
            </Drawer>
            <ModalComponent open={openAddNotesModal} onCancel={()=>setOpenAddNotesModal(false)} width={600} footer={null} title={'Add Notes'}>
                <div className={'bulk-notes-wrapper'}>
                    <TextEditorComponent
                        className={"notes-text-editor"}
                        value={notes}
                        onChange={(value: any) => setNotes(value)}
                    />
                    <div className={"job-notes-btn-wrapper"}>
                        <Button
                            onClick={handleNotesClose}
                            variant={"outlined"} id={'add_facility_btn'}
                            color={"primary"}
                        >
                            &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                        </Button>
                        <Button
                            onClick={handleNotesAdd}
                            variant={"contained"} id={'add_facility_btn'}
                            color={"primary"}
                        >
                            &nbsp;&nbsp;Save&nbsp;&nbsp;
                        </Button>
                    </div>
                </div>
            </ModalComponent>
            <ModalComponent open={openChangeStatusModal} onCancel={()=>setOpenChangeStatusModal(false)} width={600} footer={null} title={'Change Status'}>
               <ChangeStatusComponent
                   selectedJobs={selectedJobs}
                   setOpenChangeStatusModal={setOpenChangeStatusModal}
                   setSelectedJobs={setSelectedJobs}
                   filterData={jobFilterData}
                   setFilterData={setJobFilterData}
                   module={'job'}
               />
            </ModalComponent>
            <ModalComponent open={openChangeRecruiterModal} onCancel={()=>setOpenChangeRecruiterModal(false)} width={600} footer={null} title={'Change Recruiter'}>
                <ChangeRecruiterComponent
                    selectedJobs={selectedJobs}
                    setSelectedJobs={setSelectedJobs}
                    setOpenChangeRecruiterModal={setOpenChangeRecruiterModal}
                    filterData={jobFilterData}
                    setFilterData={setJobFilterData}
                    module={'job'}
                />
            </ModalComponent>
            <ModalComponent open={openConfigModal} onCancel={()=>setOpenConfigModal(false)} width={600} footer={null} title={'Change Job Posting Configuration'}>
               <PostingConfigurationComponent selectedJobs={selectedJobs} setOpenConfigModal={setOpenConfigModal} setSelectedJobs={setSelectedJobs}/>
            </ModalComponent>
            <ModalComponent open={openPayRateModal} onCancel={()=>setOpenPayRateModal(false)} width={600} footer={null} title={'Update Pay Rate'}>
                <UpdatePayRateComponent selectedJobs={selectedJobs} setSelectedJobs={setSelectedJobs} setOpenPayRateModal={setOpenPayRateModal}/>
            </ModalComponent>
            <div className={"facility-list screen crud-layout pdd-30 pdd-top-10"}>
                <div className="custom-border pdd-10 pdd-top-20 pdd-bottom-0">
                    <div className="header">
                        <div className="mrg-left-5 filter">
                            <div>
                                <div className="d-flex">
                                    <div className="d-flex position-relative">
                                        {!jobFilterData.search ? (
                                            <div className={"search_icon"}>
                                                <SearchRounded/>
                                            </div>
                                        ) : (
                                            <div className={"search_icon"}>
                                                <ClearIcon
                                                    onClick={(event) => {
                                                        setJobFilterData({...jobFilterData, search: '', page: 1})
                                                        setSelectedJobs([]);
                                                    }}
                                                    id="clear_facility_search"
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <CssTextField
                                                defaultValue={""}
                                                className="search-cursor searchField"
                                                id="input_search_facility"
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    setJobFilterData({...jobFilterData, search: value, page: 1});
                                                }}
                                                value={jobFilterData.search}
                                                variant={"outlined"}
                                                size={"small"}
                                                type={"text"}
                                                placeholder={"Search Job"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'mrg-left-20'}>
                            <Button
                                variant={"outlined"} id={'add_facility_btn'}
                                color={"primary"}
                                onClick={() => {
                                    setOpenFilterDrawer(true)
                                }}
                            >
                                {filterCount > 0 &&
                                    <span className={'filter-btn-badge'}>{filterCount}</span>
                                }
                                &nbsp;&nbsp;Filters&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div className="action pdd-right-5 d-flex">
                            <div className="mrg-left-20">
                                <Button component={Link} to={"/ats_job/bulk_upload"} variant={"contained"}
                                        color={"primary"}>
                                    &nbsp;&nbsp;Bulk Upload&nbsp;&nbsp;
                                </Button>
                            </div>
                        </div>
                        <div className="mrg-left-20 mrg-right-20">
                            <Button variant={"contained"} id={'add_facility_btn'} color={"primary"}
                                    component={Link} to="/job/add">
                                <AddRounded/>
                                &nbsp;&nbsp;Add Job&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div>
                            <Popover trigger={'click'} placement={'bottomRight'}
                                     open={popoverVisible}
                                     onOpenChange={(visible) => setPopoverVisible(visible)}
                                     content={<JobMenuListComponent onMenuItemClick={handleMenuItemClick}
                                                                    selectedJobs={selectedJobs}/>}
                            >
                                <div className={'applicant-box'} onClick={() => setPopoverVisible(!popoverVisible)}>
                                    <ImageConfig.KebabIcon className={'applicant-edit-icon'}/>
                                </div>
                            </Popover>
                        </div>
                    </div>
                    <div className={'facility-table-component mrg-top-20'}>
                        <AntTableComponent
                            isBordered={true}
                            url={ENV.API_URL + "ats/jobsList"}
                            method={'post'}
                            extraPayload={jobFilterData}
                            columns={JobListTableColumns}
                            noDataText={'No Job Found.'}
                            isRowSelection={true}
                            selectedRowKeys={selectedJobs}
                            isRowColorChange={true}
                            setSelectedRowKeys={setSelectedJobs}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobListComponent
