import "./SaveTemplateComponent.scss";
import FormLabelComponent from "../../../../ant-component/form-label/FormLabelComponent";
import InputComponent from "../../../../ant-component/input/InputComponent";
import React from "react";
import JoditEditorComponent from "../../../../../components/text-editor-v2/JoditEditorComponent";

interface SaveTemplateComponentProps{
     templateName?:any;
     setTemplateName?:any;
     templateContent?:any;
     setTemplateContent?:any;
     templateSubject?:any;
     setTemplateSubject?:any;
     isTemplateSaving?:boolean;
}

const SaveTemplateComponent=(props:SaveTemplateComponentProps)=>{
    const {templateName,
        templateSubject,
        templateContent,
        setTemplateSubject,
        setTemplateName,
        setTemplateContent,
        }=props;


    return (
        <div className={'save-template-component'}>
            <div>
                <div>
                    <FormLabelComponent Label={'Template Name*'}/>
                </div>
                <div className={'mrg-top-10'}>
                    <InputComponent
                        value={templateName}
                        onChange={(value: any) => {
                            setTemplateName(value)
                        }}
                    />
                </div>
            </div>
            <div>
                <div>
                    <FormLabelComponent Label={'Email Subject'}/>
                </div>
                <div  className={'mrg-top-10'}>
                    <InputComponent
                        value={templateSubject}
                        onChange={(value: any) => {
                            setTemplateSubject(value)
                        }}
                    />
                </div>
            </div>
            <div>
                <div>
                    <FormLabelComponent Label={'Email Body'}/>
                </div>
                <div className={'template-text-editor mrg-top-10'}>
                   <JoditEditorComponent
                       value={templateContent}
                       onChange={(value)=>{
                       setTemplateContent(value)}
                       }
                   />
                </div>
            </div>
        </div>
    )
}

export default SaveTemplateComponent