import React from 'react';
import {DatePicker} from 'antd';
import type {DatePickerProps} from 'antd';
import type {Dayjs} from 'dayjs';
import './DatePickerComponent.scss';
import FormItemComponent, {CommonFormInputProps, extractCommonPropsFromInput} from "../formItem/FormItemComponent";
import dayjs from "dayjs";

export interface DatePickerComponentProps extends CommonFormInputProps {
    onChange?: DatePickerProps['onChange'];
    // defaultValue?: Dayjs | Dayjs[];
    format?: string;
    placeholder?: string;
    size?: 'small' | 'middle' | 'large';
    multiple?: boolean; // Custom prop for multi-date selection (workaround needed)
    onBlur?: DatePickerProps['onBlur'];
    value?: string | string[];
    //@ts-ignore
    onCancel?: DatePickerProps['onCancel'];
    onSelectionClose?: () => void;
    picker?: 'month' | 'quarter' | 'year';
    disabledDates?:any;
}

export const formatValueProps = (value: string[] | string | undefined | null, format: string) => {
    //@ts-ignore
    const multipleValues = [];

    if (Array.isArray(value)) {
        value.forEach(dateString => {
            const date = dayjs(dateString, format);
            if (date.isValid()) {
                multipleValues.push(date);
            }
        })
        //@ts-ignore
        return multipleValues;
    } else {
        const date = dayjs(value, format);
        if (date.isValid()) {
            return date
        } else {
            return null;
        }
    }
}

const DatePickerComponent: React.FC<DatePickerComponentProps> = (props) => {
    const {
        onChange,
        // defaultValue,
        placeholder = 'Select date',
        size = 'middle' as DatePickerComponentProps["size"],
        onBlur,
        multiple = false,
        value,
        format = "MM/DD/YYYY",
        onCancel,
        picker,
        onSelectionClose,
        disabledDates
    } = props;


    // Handle multi-date selection with work-around
    const handleChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (multiple) {
            // Logic to handle multiple dates
            console.log('Selected multiple dates:', date, dateString);
        } else {
            console.log('Selected date:', date, dateString);
        }
        if (onChange) {
            onChange(date, dateString);
        }
    };

    return (
        <FormItemComponent {...extractCommonPropsFromInput(props)}>
            <DatePicker
                multiple={multiple}
                maxTagCount="responsive"
                className={'custom-date-picker'}
                onChange={handleChange}
                placeholder={placeholder}
                format={format}
                picker={picker}
                size={size}
                disabledDate={disabledDates}
                onBlur={onBlur}
                value={formatValueProps(value, format) as Dayjs}//getting TS error'
                //@ts-ignore
                onCancel={onCancel}
                onOpenChange={(value: boolean) => {
                    if (!value && onSelectionClose) {
                        onSelectionClose();
                    }
                }}
            />
        </FormItemComponent>
    );
};

export default DatePickerComponent;
