import {Button, Tooltip} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import DialogComponent from "../../../../components/DialogComponent";
import {ENV, ImageConfig} from "../../../../constants";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import {StateParams} from "../../../../store/reducers";
import AddShiftsComponent from "../addShiftsComponent/AddShiftsComponent";
import RejectShiftComponent from "../rejectApplication/RejectApplicationComponent";
import PendingShiftsTakeoverComponent from "../takeover/PendingShiftsTakeoverComponent";
import PendingSihftsViewComponent from "../view/PendingShiftsViewComponent";
import "./PendingShiftsListScreen.scss";
import {SearchRounded} from "@material-ui/icons";
import {pendingShiftsStatusList} from "../../../../constants/data";
import {CssTextField} from "../../../../constants/data/styles";
import { useLocation} from "react-router-dom";
import {Popover, TableColumnsType} from "antd";
import ShiftFilterDrawerComponent from "../../shift-filter-drawer/ShiftFilterDrawerComponent";
import DrawerComponent from "../../../../components/drawer/DrawerComponent";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {useSessionStorage} from "../../../../components/useSessionStorage";
import ModalComponent from "../../../../components/modal/ModalComponent";
import ShiftMenuListComponent from "../../shift-menu-list/ShiftMenuListComponent";

const AwaitingShiftsListScreen = () => {
    const location=useLocation();
    const [hcpId, setHcpId] = useState<string>("");
    const [applicationId, setApplicationId] = useState<string>("");
    const [requirementId, setRequirementId] = useState<string>("");
    const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
    const [isTakeOverOpen, setTakeOverOpen] = useState<boolean>(false);
    const {user} = useSelector((state: StateParams) => state.auth);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta)
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isAddShiftsOpen, setIsAddShiftsOpen] = useState<boolean>(false);
    const [rejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);
    const [selectedRowData,setSelectedRowData]=useState<any>([]);
    const [prevLocation,setPrevLocation]=useState<string>("")
    const [filterData, setFilterData] = useSessionStorage<any>("vitaAwaitingShiftFilterData",{
        search: '',
        sort: {},
        user_id: user?._id,
        new_shifts: CommonService.changeToUtcDate(new Date()),
        status: ['awaiting'],
        limit: 10,
    })
    const [refreshToken,setRefreshToken]=useState<number>(0);
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [filterCount,setFilterCount]=useState<number>(0);

    useEffect(() => {
        let count=0;
        if(filterData?.start_date)count++;
        if(filterData?.end_date)count++;
        if(filterData?.status && filterData?.status?.length > 0) count++;
        if(filterData?.facilities && filterData?.facilities?.length >0) count++;
        if(filterData?.hcp_types && filterData?.hcp_types?.length >0) count++;
        if(filterData?.shift_types && filterData?.shift_types?.length >0) count++;
        if(filterData?.hcp_user_ids && filterData?.hcp_user_ids?.length >0) count++;
        if(filterData?.type) count++;
        setFilterCount(count);
    }, [filterData]);

    useEffect(() => {
        setFilterData((prev: any) => {
            const newUtcDate = CommonService.changeToUtcDate(new Date());
            if (filterData?.new_shifts && (!filterData?.start_date && !filterData?.end_date) && prev.new_shifts !== newUtcDate) {
                return {
                    ...prev,
                    new_shifts: newUtcDate,
                };
            }
            return prev;
        });
    }, [setFilterData,filterData?.new_shifts,filterData?.start_date,filterData?.end_date]);

    useEffect(() => {
        // Detect page change by comparing current location with previous location
        if (location.pathname !== prevLocation) {
            setPrevLocation(location.pathname); // Update the previous location state

            // Only reset the filterData when navigating different page
            if (location.pathname.startsWith('/awaitingShifts') && (!filterData.status)) {
                let updatedFilterData={...filterData};
                updatedFilterData.status=["awaiting"];
                setFilterData(updatedFilterData);
            }
        }
    }, [location, filterData, setFilterData, prevLocation]);

    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        let payload: any = {};

        // if (selectedRegion.length > 0) {
        //     payload.regions = selectedRegion;
        // }

        if (filterData?.facilities?.length > 0) {
            payload.facilities = filterData?.facilities;
        }
        if (filterData?.hcp_types?.length > 0) {
            payload.hcp_types = filterData?.hcp_types;
        }

        if (filterData?.hcp_user_ids?.length > 0) {
            payload.hcp_user_ids = filterData?.hcp_user_ids;
        }


        //send all status shifts for download when not selected
        if (filterData?.status?.length === 0) {
            payload.status = pendingShiftsStatusList.map(item => item.code);
        }
        if (filterData?.search !== "") {
            payload.search = filterData?.search
        }
        if (filterData?.type !== '') {
            payload.type = filterData?.type;
        }
        if (filterData?.status?.length > 0) {
            payload.status = filterData?.status;
        }

        if(filterData?.start_date || filterData?.end_date) {
            let startDate = moment(filterData?.start_date).format("YYYY-MM-DD");
            let endDate = moment(filterData?.end_date).format("YYYY-MM-DD");
            if (!filterData?.end_date) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }

        if(filterData?.shift_types?.length > 0) {
            payload.shift_types = filterData?.shift_types;
        }

        ApiService.post(ENV.API_URL + "application/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.msg || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.error || "Error", "error");
            });
    }, [filterData]);

    const cancelView = useCallback(() => {
        if (isViewOpen === true) {
            setIsViewOpen(false);
        } else {
            setTakeOverOpen(false);
        }
    }, [isViewOpen]);

    const openViewShift = useCallback(() => {
        setIsViewOpen(true);
        setTakeOverOpen(false);
    }, []);

    const openView = useCallback((id: any, hcp_user_id: any, application_id: any) => {
        setIsViewOpen(true);
        setRequirementId(id);
        setHcpId(hcp_user_id);
        setApplicationId(application_id);
    }, []);

    const confirmView = useCallback(() => {
        setIsViewOpen(false);
    }, []);

    const cancelTakeOver = useCallback(() => {
        setTakeOverOpen(false);
    }, []);

    const openTakeOver = useCallback((hcp_user_id: any, application_id: any, requirement_id: any, status: any) => {
        setTakeOverOpen(true);
        setRequirementId(requirement_id);
        setHcpId(hcp_user_id);
        setApplicationId(application_id);
        // setShiftStatus(status);
    }, []);

    const confirmTakeOver = useCallback(() => {
        setTakeOverOpen(false);
        setRefreshToken((prev:number)=>prev + 1)
    }, []);


    const clearFilterValues = useCallback(() => {
        setFilterData((prevFilterData: any) => {
            const updatedFilterData = {
                ...prevFilterData,
            };

            // Dynamically remove keys if they exist
            const keysToRemove = [
                "start_date",
                "end_date",
                "facilities",
                "hcp_types",
                "hcp_user_ids",
                "shift_types",
                "type"
            ];
            keysToRemove.forEach((key: any) => delete updatedFilterData[key]);
            updatedFilterData.status=['awaiting'];
            updatedFilterData.new_shifts=CommonService.changeToUtcDate(new Date())

            return updatedFilterData;
        });
    }, [setFilterData])


    const resetFilters = () => {
        clearFilterValues();
    };


    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);

    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        setSelectedShifts([]);
    }, []);

    const cancelOpenAddShifts = useCallback(() => {
        setIsAddShiftsOpen(false);
        setSelectedShifts([]);
    }, []);

    const onAddShiftsConfirm = useCallback(() => {
        setIsAddShiftsOpen(false);
        setSelectedShifts([]);
        setRefreshToken((prev:number)=>prev + 1)
    }, []);

    const onRejectShiftConfirm = useCallback(() => {
        setRejectShiftOpen(false);
        setSelectedShifts([]);
        setRefreshToken((prev:number)=>prev + 1)
    }, []);

    const handleApproveButtonDisable = useCallback(() => {
        if (selectedShifts?.length > 0) {
        // filterData?.status?.includes("pending")
            if(filterData?.status?.length === 0  || !selectedRowData?.every((row:any)=>row?.status === 'awaiting')) {
                return true;
            }
            return false;
        } else {
            return true;
        }
    },[filterData?.status,selectedShifts,selectedRowData])

    const handleRejectButtonDisable = useCallback(() => {
        // filterData?.status?.includes("cancelled") || filterData?.status?.includes("rejected")
        if (selectedShifts?.length === 0 || filterData?.status?.length === 0 ||
            !selectedRowData?.every((row:any)=> row.status === 'awaiting')
        ) {
            return true;
        } else {
        }
    },[filterData?.status,selectedShifts,selectedRowData])

    useEffect(() => {
        getFacilityData();
    }, [getFacilityData]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Awaiting");
        Communications.pageBackButtonSubject.next(null);
    }, []);


    const awaitShiftsListColumn: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Facility Name',
                width: 250,
                sorter: (a: any, b: any) => (a?.facility?.name || '').localeCompare(b?.facility?.name || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.facility?.name?.length > 20 ?
                                <Tooltip title={item?.facility?.name || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility?.name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.facility?.name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Staff Name',
                width: 250,
                sorter: (a: any, b: any) =>
                    ((a?.hcp_details?.first_name || '') + (a?.hcp_details?.last_name || ''))
                        .localeCompare((b?.hcp_details?.first_name || '') + (b?.hcp_details?.last_name || '')),
                render: (item: any) => {
                    return (
                        <div>
                            {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)?.length > 20 ?
                                <Tooltip
                                    title={(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Applied On',
                width: 130,
                sorter: (a: any, b: any) => new Date(a["created_at"]).getTime() - new Date(b["created_at"]).getTime(),
                render: (item: any) => {
                    return (
                        <div>
                            {moment(item["created_at"]).format("MM-DD-YYYY") || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Staff Type',
                width: 170,
                sorter: (a: any, b: any) => (a?.hcp_data?.hcp_type || '').localeCompare(b?.hcp_data?.hcp_type || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.hcp_data?.hcp_type?.length > 20 ?
                                <Tooltip title={item?.hcp_data?.hcp_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp_data?.hcp_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.hcp_data?.hcp_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Type',
                width: 200,
                sorter: (a: any, b: any) => (a?.shift_type || '').localeCompare(b?.shift_type || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.shift_type?.length > 20 ?
                                <Tooltip title={item?.shift_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.shift_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.shift_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Date',
                width: 120,
                sorter: (a: any, b: any) => new Date(a["shift_date"]).getTime() - new Date(b["shift_date"]).getTime(),
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.getUtcDate(item["shift_date"]) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Status',
                width: 100,
                // sorter: (a: any, b: any) => (a["status"] || '').localeCompare(b["status"] || ''),
                render: (item: any) => {
                    return (
                        <div className={`captalize ${item["status"]}`}>
                            {item["status"]}
                        </div>
                    )
                }
            },
            {
                title: 'View Details',
                width: 200,
                render: (_: any, item: any, rowIndex: number) => {
                    console.log(item?.status)
                    return (
                        <div className={'d-flex align-items-center'}>
                            <div className="display-center">
                                <div>
                                    <Button
                                        // style={{borderRadius: "2rem",color:'black'}}
                                        variant="outlined"
                                        color={'primary'}
                                        onClick={() => openTakeOver(item["hcp_details"]?._id, item["_id"], item["requirement_id"], "approve")}
                                        disabled={item?.status !== "awaiting" || item["can_take_over"] === false}
                                        className={item["status"] !== "awaiting" || item["can_take_over"] === false  ? "btn-disabled" : "btn-enabled"}
                                    >
                                        TAKEOVER
                                    </Button>
                                </div>
                                <div onClick={() => openView(item["requirement_id"], item["hcp_details"]?._id, item["_id"])}
                                     style={{color: '#1e1e1e', marginLeft: '20px',cursor:'pointer'}}>
                                    <ImageConfig.ArrowRightOutlinedIcon/>
                                </div>
                            </div>
                        </div>
                    )
                }
            },
        ]
    }, [openView, openTakeOver])

    const menuItems=useCallback(()=>{
        return [
            {
                label:'Approve',
                id:'approve',
                action:'approve',
                tooltip:"Select Awaiting status and shifts to Approve.",
                disabled: handleApproveButtonDisable()
            },
            {
                label:'Reject',
                id:'reject',
                action:'reject',
                tooltip: 'Select Awaiting status and shifts to Reject.',
                disabled:handleRejectButtonDisable()
            },
        ]
    },[handleRejectButtonDisable,handleApproveButtonDisable])

    const handleMenuItemClick = useCallback((action: string) => {
        setPopoverVisible(false);
        switch(action){
            case "approve":
                setIsAddShiftsOpen(true);
                break;
            case "reject":
                setRejectShiftOpen(true);
                break;
            default:
                return ;
        }
    },[])

    return (
        <div className="pending-list-screen screen crud-layout pdd-30">
            <DialogComponent open={rejectShiftOpen} cancel={cancelRejectShift} headerLabel={selectedShifts && selectedShifts?.length > 1 ? "Reject Shifts" : "Reject Shift"}>
                <RejectShiftComponent cancel={cancelRejectShift} confirm={onRejectShiftConfirm}
                                      selectedShifts={selectedShifts}/>
            </DialogComponent>
            <ModalComponent centered={true} width={1000} open={isViewOpen} onCancel={cancelView} footer={null} title={"Awaiting Shift Details"}>
                <PendingSihftsViewComponent cancel={cancelView} requirementId={requirementId} confirm={confirmView}
                                            hcpId={hcpId} applicationId={applicationId}/>
            </ModalComponent>
            {/*<DialogComponent open={isViewOpen} cancel={cancelView} maxWidth="lg">*/}
            {/*    <PendingSihftsViewComponent cancel={cancelView} requirementId={requirementId} confirm={confirmView}*/}
            {/*                                hcpId={hcpId} applicationId={applicationId}/>*/}
            {/*</DialogComponent>*/}
            <DialogComponent open={isTakeOverOpen} cancel={cancelTakeOver} maxWidth="xs">
                <PendingShiftsTakeoverComponent cancel={cancelTakeOver} confirm={confirmTakeOver}
                                                openViewShift={openViewShift} applicationId={applicationId}
                                                requirementId={requirementId} hcpId={hcpId}/>
            </DialogComponent>
            <DialogComponent open={isAddShiftsOpen} cancel={cancelOpenAddShifts} maxWidth="md">
                <AddShiftsComponent cancel={cancelOpenAddShifts} selectedShiftIds={selectedShifts}
                                    confirm={onAddShiftsConfirm}/>
            </DialogComponent>

            <DrawerComponent isOpen={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                <ShiftFilterDrawerComponent
                    filterData={filterData}
                    setFilterData={setFilterData}
                    setOpenFilterDrawer={setOpenFilterDrawer}
                    isFacilityListLoading={isFacilityListLoading}
                    isPending={true}
                    isAwaiting={true}
                    noStatus={false}
                    resetFilters={resetFilters}
                    facilityList={facilityList}
                    hcpTypes={hcpTypes}
                />
            </DrawerComponent>
            <div className="custom-border pdd-20">

                <div className="pending-shift-header">
                    <div className="d-flex">
                        <div className="d-flex position-relative">
                            {!filterData.search ? (
                                <div className={"search_icon"}>
                                    <SearchRounded/>
                                </div>
                            ) : (
                                <div className={"search_icon"}>
                                    <ClearIcon
                                        onClick={(event) => {
                                            setFilterData({...filterData, search: ''})
                                            setSelectedShifts([]);
                                            setSelectedRowData([]);
                                        }}
                                        id="clear_facility_search"
                                    />
                                </div>
                            )}
                            <div>
                                <CssTextField
                                    defaultValue={""}
                                    className="search-cursor searchField"
                                    id="input_search_facility"
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setFilterData({...filterData, search: value});
                                    }}
                                    value={filterData.search}
                                    variant={"outlined"}
                                    size={"small"}
                                    type={"text"}
                                    placeholder={"Search Shift"}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex mrg-right-5 mrg-bottom-5">
                        <div className="actions pdd-right-5">
                            <Tooltip title="Download Shifts List">
                                <Button variant={"outlined"} color="primary" onClick={handleDownload}
                                        className={!isDownloading ? "" : "has-loading-spinner"}
                                        disabled={isDownloading}>
                                    &nbsp;Download
                                </Button>
                            </Tooltip>
                        </div>
                        <div className={'mrg-left-10'}>
                            <Button
                                variant={"outlined"} id={'add_facility_btn'}
                                color={"primary"}
                                onClick={() => {
                                    setOpenFilterDrawer(true)
                                }}
                            >
                                {filterCount > 0 &&
                                    <span className={'filter-btn-badge'}>{filterCount}</span>
                                }
                                &nbsp;&nbsp;Filters&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div className={'mrg-left-15'}>
                            <Popover
                                trigger={'click'}
                                placement={'bottomRight'}
                                destroyTooltipOnHide={true}
                                open={popoverVisible}
                                onOpenChange={(visible) => setPopoverVisible(visible)}
                                content={<ShiftMenuListComponent
                                        selectedShifts={selectedShifts}
                                        onMenuItemClick={handleMenuItemClick}
                                        menuItems={menuItems}
                                />}
                            >
                                <div className={'applicant-box'} onClick={() => setPopoverVisible(!popoverVisible)}>
                                    <ImageConfig.KebabIcon className={'applicant-edit-icon'}/>
                                </div>
                            </Popover>
                        </div>
                    </div>

                </div>
                <div className={'pending-table-wrapper'}>
                    <AntTableComponent
                        key={refreshToken}
                        url={ENV.API_URL + "application"}
                        method={'post'}
                        extraPayload={filterData}
                        columns={awaitShiftsListColumn}
                        noDataText={'No Awaiting Shift Found.'}
                        isRowSelection={true}
                        selectedRowKeys={selectedShifts}
                        setSelectedRowKeys={setSelectedShifts}
                        setSelectedRowData={setSelectedRowData}
                    />
                </div>
            </div>
        </div>
    );
};

export default AwaitingShiftsListScreen;
