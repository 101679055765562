import React, {useState, useEffect, useCallback} from "react";
import {Drawer, Checkbox, Button, Input} from "antd";
import "./AddDocumentDrawer.scss";

interface AddDocumentDrawerProps {
    visible: boolean;
    onClose: () => void;
    onUpdate: (selectedItems: string[]) => void;
    selectedItems: string[];
    availableOptions: string[];
}

const AddDocumentDrawer: React.FC<AddDocumentDrawerProps> = ({
                                                                 visible,
                                                                 onClose,
                                                                 onUpdate,
                                                                 selectedItems,
                                                                 availableOptions,
                                                             }) => {
    const [checkedItems, setCheckedItems] = useState<string[]>(selectedItems);
    const [searchTerm, setSearchTerm] = useState('');

    // Update the local state when the parent state changes
    useEffect(() => {
        setCheckedItems(selectedItems);
    }, [selectedItems]);

    const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    }, []);

    // Filtered documents based on search term
    const filteredDocuments = useCallback(() => {
        return availableOptions?.filter(doc =>
            doc?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
    }, [availableOptions, searchTerm]);


    // This function will update checkedItems only for the visible (filtered) documents
    const handleCheckboxChange = useCallback((checkedValues: string[]) => {
        const newCheckedItems = [...checkedItems];
        // Update the checked state only for the filtered documents
        filteredDocuments().forEach(doc => {
            if (checkedValues.includes(doc)) {
                if (!newCheckedItems.includes(doc)) {
                    newCheckedItems.push(doc);
                }
            } else {
                const index = newCheckedItems.indexOf(doc);
                if (index !== -1) {
                    newCheckedItems.splice(index, 1);
                }
            }
        });

        setCheckedItems(newCheckedItems); // Set the updated checkedItems state
    }, [checkedItems, filteredDocuments]);

    const handleApply = useCallback(() => {
        setSearchTerm('');
        onUpdate(checkedItems); // Pass the updated checkedItems state
    }, [onUpdate, checkedItems]);

    const handleClose=useCallback(()=>{
        setSearchTerm('');
        if(selectedItems?.length !== checkedItems?.length){
            onUpdate(selectedItems);
        }
        if(onClose){
            onClose()
        }
    },[onClose,selectedItems,checkedItems,onUpdate])


    return (
        <Drawer
            title="Select Documents"
            placement="right"
            onClose={handleClose}
            open={visible}
            maskClosable={false}
            destroyOnClose={false}
            footer={
                <div style={{textAlign: "right"}} className="add-document-drawer__footer">
                    <Button
                        className={"drawer-deselect-all-button"}
                        onClick={() => {
                            setCheckedItems([]);// Deselect all checked items
                        }}
                    >
                        Deselect All
                    </Button>
                    <Button type="primary" className={"drawer-apply-button"} onClick={handleApply}>
                        Apply
                    </Button>
                </div>
            }
        >
            <div className="add-document-drawer">
                <Input
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(value)=>handleSearch(value)}
                    className="add-document-drawer__search-input"
                />
                <div className="add-document-drawer__list-container">
                    <Checkbox.Group
                        value={checkedItems} // Bind to checkedItems state
                        onChange={handleCheckboxChange}
                    >
                        {filteredDocuments()?.map((option) => (
                            <div key={option} style={{marginBottom: 8}}>
                                <Checkbox value={option}>
                                    {option}
                                </Checkbox>
                            </div>
                        ))}
                    </Checkbox.Group>
                </div>
            </div>
        </Drawer>
    );
};

export default AddDocumentDrawer;
