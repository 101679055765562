import {Button, Checkbox, Tooltip} from "@material-ui/core";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import DialogComponent from "../../../../components/DialogComponent";
import RejectShiftComponent from "../../rejectShift/RejectShiftComponent";
import VitawerksConfirmComponent from "../../../../components/VitawerksConfirmComponent";
import {ENV, ImageConfig} from "../../../../constants";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import "./CompletedShiftsListScreen.scss";
import {CssTextField} from "../../../../constants/data/styles";
import PendingConcernConfirmationBox from "./pending-concern-confirm/PendingConcernConfirmationBox";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
import {Popover, TableColumnsType} from "antd";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import ShiftFilterDrawerComponent from "../../shift-filter-drawer/ShiftFilterDrawerComponent";
import DrawerComponent from "../../../../components/drawer/DrawerComponent";
import {useSessionStorage} from "../../../../components/useSessionStorage";
import ShiftMenuListComponent from "../../shift-menu-list/ShiftMenuListComponent";

const [firstDayOfMonth, previousDate] = CommonService.getFirstDayAndFortyFiveDayUsingDate();
const CompletedShiftsListScreen = () => {
    const location=useLocation();
    const {hcpTypes} = useSelector((state: StateParams) => state.meta)
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    const [shiftId, setShiftId] = useState<string>("");
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [isRejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);
    const [isPendingConcernOpen, setIsPendingConcernOpen] = useState<boolean>(false);
    const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);
    const [refreshToken,setRefreshToken]=useState<number>(0);
    const [prevLocation,setPrevLocation]=useState<string>("");
    const [selectedRowData,setSelectedRowData]=useState<any>([]);
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [filterCount,setFilterCount]=useState<number>(0);
    const [filterData, setFilterData] = useSessionStorage<any>("vitaCompletedShiftFilterData",{
        search: '',
        sort: {},
        status: 'complete',
        limit: 10,
        start_date: CommonService.changeToUtcDate(previousDate),
        end_date: CommonService.changeToUtcDate(firstDayOfMonth)
    })

    useEffect(() => {
        let count=0;

        if(filterData?.start_date)count++;
        if(filterData?.end_date)count++;
        if(filterData?.facilities && filterData?.facilities?.length >0) count++;
        if(filterData?.hcp_types && filterData?.hcp_types?.length >0) count++;
        if(filterData?.shift_types && filterData?.shift_types?.length >0) count++;
        if(filterData?.hcp_user_ids && filterData?.hcp_user_ids?.length >0) count++;
        if(filterData?.type) count++;
        if(filterData?.salary_credit && filterData?.salary_credit?.length > 0) count++;
        if(filterData?.gusto_type) count++;
        if(filterData?.is_timesheet_uploaded !== null && filterData?.is_timesheet_uploaded !== undefined) count++;
        if(filterData?.is_facility_approved !== null && filterData?.is_facility_approved !== undefined) count++;
        if(filterData?.is_pending_concern !== null && filterData?.is_pending_concern !== undefined) count++;
        if(filterData?.is_sent_home !== null && filterData?.is_sent_home !== undefined) count++;
        setFilterCount(count);
    }, [filterData]);

    useEffect(() => {
        // Detect page change by comparing current location with previous location
        if (location.pathname !== prevLocation) {
            setPrevLocation(location.pathname); // Update the previous location state

            // Only reset the filterData when navigating different page
            if (location.pathname.startsWith('/completedShifts') && (!filterData.start_date && !filterData.end_date)) {
                let updatedFilterData={...filterData};
                updatedFilterData.start_date=CommonService.changeToUtcDate(previousDate);
                updatedFilterData.end_date=CommonService.changeToUtcDate(firstDayOfMonth);
                delete updatedFilterData.new_shifts;
                setFilterData(updatedFilterData);
            }
        }
    }, [location, filterData, setFilterData, prevLocation]);



    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        let payload: any = {};
        // if (selectedRegion.length > 0) {
        //     payload.regions = selectedRegion;
        // }
        if (filterData?.facilities?.length > 0) {
            payload.facilities = filterData?.facilities;
        }
        if (filterData?.hcp_types?.length > 0) {
            payload.hcp_types = filterData?.hcp_types;
        }

        if (filterData?.hcp_user_ids?.length > 0) {
            payload.hcp_user_ids = filterData?.hcp_user_ids;
        }

        payload.status = ['complete'];

        if (filterData?.is_timesheet_uploaded !== "") {
            payload.is_timesheet_uploaded = filterData?.is_timesheet_uploaded;
        }

        if (filterData?.is_facility_approved !== "") {
            payload.is_facility_approved = filterData?.is_facility_approved;
        }

        if (filterData?.is_pending_concern !== "") {
            payload.is_pending_concern = filterData?.is_pending_concern;
        }

        if (filterData?.gusto_type !== "") {
            payload.gusto_type = filterData?.gusto_type;
        }

        if (filterData?.type !== '') {
            payload.type = filterData?.type;
        }

        if (filterData?.salary_credit?.length > 0) {
            payload.salary_credit = filterData?.salary_credit;
        }

        if (filterData?.search !== "") {
            payload.search = filterData?.search;
        }

        if(filterData?.start_date || filterData?.end_date) {
            let startDate = moment(filterData?.start_date).format("YYYY-MM-DD");
            let endDate = moment(filterData?.end_date).format("YYYY-MM-DD");
            if (!filterData?.end_date) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        } else {
            let today = moment(new Date()).format("YYYY-MM-DD");
            payload.new_shifts = today;
        }

        if (filterData?.shift_types?.length > 0) {
            payload.shift_types = filterData?.shift_types;
        }


        if (filterData?.is_sent_home !== "") {
            payload.is_sent_home = filterData?.is_sent_home;
        }


        ApiService.post(ENV.API_URL + "shift/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.msg || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.msg || "Error", "error");
            });
    }, [filterData]);

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);

    useEffect(() => getFacilityData(), [getFacilityData]);

    const handleCloseShift = useCallback(
        (shiftId: string) => {
            setIsConfirm(true);
            ApiService.patch(ENV.API_URL + "shift/" + shiftId + "/close")
                .then((resp: any) => {
                    CommonService.showToast(resp?.msg || "Shift has been moved from complete to closed", "success");
                    setIsConfirm(false);
                    setIsAddOpen(false);
                    let updatedFilterData = {...filterData}
                    setFilterData(updatedFilterData)
                })
                .catch((err) => {
                    console.log(err);
                    CommonService.showToast(err.error || "Someting went wrong", "error");
                    setIsConfirm(false);
                    setIsAddOpen(false);
                });
        },
        [filterData,setFilterData]
    );


    const handleCloseShiftTimelineAndFacilityApproved = useCallback(
        () => {
            setIsConfirm(true);
            const payloadFacilityAndTimelineApproved = {
                shift_ids: selectedShifts
            };

            // Resetting error and success states before making the API call
            setIsAddOpen(false);
            //  setSelectedCompletedShiftWithFacilityAndTimeline([]);

            // Make the API call
            ApiService.patch(ENV.API_URL + "shift/close", payloadFacilityAndTimelineApproved)
                .then((resp: any) => {
                    // Handle success
                    CommonService.showToast(resp?.message || "Shift has been moved from complete to closed", "success");
                    setSelectedShifts([])
                    // Reset state after successful operation
                    setIsConfirm(false);

                    // Optionally update filter data or refetch the table data
                    setRefreshToken((prev:number)=>prev + 1)

                    // You may want to trigger a table data refetch here if needed
                    // For example: refetchTableData();
                })
                .catch((err) => {
                    // Handle error
                    setIsConfirm(false);
                    CommonService.showToast(err?.error || "Something went wrong", "error");

                    // Optionally show specific error details from the response
                    console.error("API Error: ", err);
                });
        },
        [ selectedShifts]
    );

    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        // setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const cancelMarkingPendingConcern = useCallback(() => {
        setIsPendingConcernOpen(false);
        // setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const confirmRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        let updatedFilterData = {...filterData}
        setFilterData(updatedFilterData)
        // setSelectedCount(-1);
        setSelectedShifts([]);
    }, [filterData, setSelectedShifts,setFilterData]);

    const confirmMarkingPendingConcern = useCallback(() => {
        setIsPendingConcernOpen(false);
        // setSelectedCount(-1);
        return new Promise((resolve, reject) => {
            ApiService.patch(ENV.API_URL + "shift/markConcern", {
                shift_ids: selectedShifts
            })
                .then((res: any) => {
                    resolve(res);
                    let updatedFilterData = {...filterData}
                    setFilterData(updatedFilterData)
                    CommonService.showToast(res?.message  || "success", "success");
                    setSelectedShifts([]);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }, [selectedShifts, filterData,setFilterData]);

    const clearFilterValues = useCallback(() => {
        setFilterData((prevFilterData: any) => {
            const updatedFilterData = {
                ...prevFilterData,
            };
            // Dynamically remove keys if they exist
            const keysToRemove = [
                "facilities",
                "hcp_types",
                "is_timesheet_uploaded",
                "is_facility_approved",
                "is_pending_concern",
                "gusto_type",
                "is_sent_home",
                "shift_types",
                "salary_credit",
                "type",
                "hcp_user_ids",
            ];
            keysToRemove.forEach((key: any) => delete updatedFilterData[key]);
            updatedFilterData.status='complete';
            updatedFilterData.start_date=CommonService.changeToUtcDate(previousDate);
            updatedFilterData.end_date=CommonService.changeToUtcDate(firstDayOfMonth)
            return updatedFilterData;
        })
    }, [setFilterData])

    const resetFilters = () => {
        clearFilterValues();
    };

    useEffect(() => {
        Communications.pageTitleSubject.next("Completed");
        Communications.pageBackButtonSubject.next(null);
    }, []);


    const openAdd = useCallback((id: any) => {
        setShiftId(id);
        setIsAddOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        handleCloseShift(shiftId);
    }, [handleCloseShift, shiftId]);


    const completedShiftsListColumn: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Facility Name',
                width: 250,
                sorter: (a: any, b: any) => (a?.facility?.name || '').localeCompare(b?.facility?.name || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.facility?.name?.length > 20 ?
                                <Tooltip title={item?.facility?.name || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility?.name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.facility?.name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Staff Name',
                width: 250,
                sorter: (a: any, b: any) => {
                    const nameA = (a?.hcp_details?.first_name + " " + a?.hcp_details?.last_name || '').trim();
                    const nameB = (b?.hcp_details?.first_name + " " + b?.hcp_details?.last_name || '').trim();
                    return nameA.localeCompare(nameB);
                },
                render: (item: any) => {
                    return (
                        <div>
                            {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)?.length > 20 ?
                                <Tooltip
                                    title={(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Clock In',
                width: 100,
                // sorter: (a: any, b: any) => {
                //     const timeA = a["time_breakup"]?.check_in_time || '';
                //     const timeB = b["time_breakup"]?.check_in_time || '';
                //     return timeA.localeCompare(timeB);
                // },
                render: (item: any) => {
                    const checkinTime: any = item["time_breakup"]?.check_in_time ? CommonService.startTime(item["time_breakup"]?.check_in_time) : "";
                    const result = CommonService.compareCheckIn(item["expected"], item["time_breakup"]);
                    return (
                        <div>
                            <Tooltip
                                title={`${result.isBefore ? `Staff has clocked in earlier than shift start time by ${result.hours} hour(s) and ${result.minutes} minute(s).` : "Staff has clocked in on time or after the shift start"}`}>
                                <p style={{color: `${result.isBefore ? "red" : ""}`}}>
                                    {checkinTime}
                                </p>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Break In',
                width: 100,
                // sorter: (a: any, b: any) => {
                //     const timeA = a["time_breakup"]?.break_timings?.[0]?.break_in_time || '';
                //     const timeB = b["time_breakup"]?.break_timings?.[0]?.break_in_time || '';
                //     return timeA.localeCompare(timeB);
                // },
                render: (item: any) => {
                    const breakInTime: any = item["time_breakup"]?.break_timings?.length > 0 ? CommonService.startTime(item["time_breakup"]?.break_timings[0]?.break_in_time) : "N/A";
                    return (
                        <div>
                            {breakInTime}
                        </div>
                    )
                }
            },
            {
                title: 'Break Out',
                width: 120,
                // sorter: (a: any, b: any) => {
                //     const timeA = a["time_breakup"]?.break_timings?.[0]?.break_out_time || '';
                //     const timeB = b["time_breakup"]?.break_timings?.[0]?.break_out_time || '';
                //     return timeA.localeCompare(timeB);
                // },
                render: (item: any) => {
                    const breakOutTime: any = item["time_breakup"]?.break_timings?.length > 0 ? (item["time_breakup"]?.break_timings[0]?.break_out_time ?  CommonService.startTime(item["time_breakup"]?.break_timings[0]?.break_out_time):'N/A') : "N/A";
                    return (
                        <div>
                            {breakOutTime}
                        </div>
                    )
                }
            },
            {
                title: 'Clock Out',
                width: 130,
                // sorter: (a: any, b: any) => {
                //     const timeA = a["time_breakup"]?.check_out_time || '';
                //     const timeB = b["time_breakup"]?.check_out_time || '';
                //     return timeA.localeCompare(timeB);
                // },
                render: (item: any) => {
                    const checkOutTime: any = item["time_breakup"]?.check_out_time ? CommonService.startTime(item["time_breakup"]?.check_out_time) : "N/A";
                    const checkOutResult = CommonService.compareCheckOut(item["expected"], item["time_breakup"])
                    return (
                        <div>
                            <Tooltip
                                title={`${checkOutResult.isAfter ? `Staff has clocked out later than shift end time by ${checkOutResult.hours} hour(s) and ${checkOutResult.minutes} minute(s).` : "Staff has clocked out  on time or before the shift end"}`}>
                                <p style={{color: `${checkOutResult.isAfter ? "red" : ""}`}}>
                                    {checkOutTime}
                                </p>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Staff Type',
                width: 170,
                sorter: (a: any, b: any) => (a?.hcp_type || '').localeCompare(b?.hcp_type || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.hcp_type?.length > 20 ?
                                <Tooltip title={item?.hcp_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.hcp_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Type',
                width: 200,
                sorter: (a: any, b: any) => (a?.shift_type || '').localeCompare(b?.shift_type || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.shift_type?.length > 20 ?
                                <Tooltip title={item?.shift_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.shift_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.shift_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Date',
                width: 120,
                sorter: (a: any, b: any) => {
                    const dateA = new Date(a["shift_date"]).getTime();
                    const dateB = new Date(b["shift_date"]).getTime();
                    return dateA - dateB;
                },
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.getUtcDate(item["shift_date"]) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Is Pending Concern',
                width: 170,
                render: (item: any) => {
                    const isPendingConcern = item?.hasOwnProperty('is_pending_concern') && item["is_pending_concern"];
                    return (
                        <div>
                            {isPendingConcern ? "Yes" : "No"}
                            {/*    <div className={'mrg-right-10'}>*/}
                            {/*        <WarningIcon className={'mrg-top-5 mrg-right-5'}*/}
                            {/*                     color={'error'}/>*/}
                            {/*    </div>:*/}
                            {/*    <div>No</div>*/}
                            {/*}*/}
                        </div>
                    )
                }
            },
            {
                title: "Staff Sent Home",
                width: 160,
                render: (item: any) => {
                    const isHcpSentHome = item?.hasOwnProperty('is_sent_home') ? (item["is_sent_home"] === true ? "Yes" : "No") : "No";
                    return (
                        <div className={`${isHcpSentHome === 'Yes' ? 'success' : 'danger'}`}>
                            {isHcpSentHome}
                        </div>
                    )
                }
            },
            {
                title: 'Timesheet',
                width: 120,
                render: (item: any) => {
                    const isTimeSheetUploaded =
                        item?.hasOwnProperty("is_timesheet_uploaded") && item["is_timesheet_uploaded"];
                    return (
                        <div className={'display-center'}>
                            {isTimeSheetUploaded ? (
                                <Checkbox checked={isTimeSheetUploaded}/>
                            ) : (
                                <Checkbox checked={Boolean(isTimeSheetUploaded)}/>
                            )}
                        </div>
                    )
                }
            },
            {
                title: 'Facility Confirmation',
                width: 180,
                render: (item: any) => {
                    const isFacilityApproved =
                        item?.hasOwnProperty("is_facility_approved") && item["is_facility_approved"];
                    return (
                        <div className={'display-center'}>
                            {isFacilityApproved ? (
                                <Checkbox checked={isFacilityApproved}/>
                            ) : (
                                <Checkbox checked={Boolean(isFacilityApproved)}/>
                            )}
                        </div>
                    )
                }
            },
            {
                title: 'Close Shift',
                width: 120,
                render: (item: any) => {
                    // const isFacilityApproved = item?.hasOwnProperty('is_facility_approved') && item["is_facility_approved"];
                    // const isTimeSheetUploaded = item?.hasOwnProperty('is_timesheet_uploaded') && item["is_timesheet_uploaded"];
                    // const isPendingConcern = item?.hasOwnProperty('is_pending_concern') && item["is_pending_concern"];
                    return (
                        <div>
                            <Tooltip title={` close shift`}>
                                <Button variant="outlined" style={{borderRadius: "2rem"}}
                                    // disabled={!(isTimeSheetUploaded && isFacilityApproved && !isPendingConcern)}
                                        color="primary" onClick={() => openAdd(item["_id"])}
                                        className={`close-shift-btn`}>


                                    Close
                                </Button>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'View Details',
                width: 140,
                render: (_: any, item: any, rowIndex: number) => {
                    return (
                        <div className={'display-center'}>
                            <Link to={"/completedShifts/view/" + item["_id"]}
                                  id={"link_hospital_details" + rowIndex}
                                  style={{color: '#1e1e1e'}}
                            >
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </Link>
                        </div>
                    )
                }
            },
        ]
    }, [openAdd])

    const menuItems=useCallback(()=>{
        return [
            {
                label:'Pending Concern',
                id:'pending_concern',
                action:'pending_concern',
                tooltip:"Pending Concern",
                disabled:filterData?.is_pending_concern ||
                    (selectedShifts?.length === 0) ||
                    selectedRowData?.some((row: any) => row?.is_pending_concern === true)
            },
            {
                label:'Cancel Shift',
                id:'cancel_shift',
                action:'cancel_shift',
                tooltip:"Cancel Completed Shift",
            },
            {
                label:'Close',
                id:'close',
                action:'close',
                tooltip: 'Close Completed Shift',
            },
        ]
    },[filterData,selectedShifts,selectedRowData])

    const handleMenuItemClick = useCallback((action: string) => {
        setPopoverVisible(false);
        switch(action){
            case "pending_concern":
                setIsPendingConcernOpen(true);
                break;
            case "cancel_shift":
                setRejectShiftOpen(true);
                break;
            case "close":
                handleCloseShiftTimelineAndFacilityApproved()
                break;
            default:
                return ;
        }
    },[handleCloseShiftTimelineAndFacilityApproved])

    return (
        <div className="completed-shifts screen crud-layout pdd-30">
            <DialogComponent open={isRejectShiftOpen} cancel={cancelRejectShift} headerLabel={selectedShifts && selectedShifts?.length > 1 ? "Cancel Shifts" : "Cancel Shift"}>
                <RejectShiftComponent isMultipleReasons={true} cancel={cancelRejectShift} confirm={confirmRejectShift}
                                      selectedShifts={selectedShifts}/>
            </DialogComponent>
            <DialogComponent open={isPendingConcernOpen} cancel={cancelMarkingPendingConcern}>
                <PendingConcernConfirmationBox selectedShifts={selectedShifts}
                                               cancelMarkingPendingConcern={cancelMarkingPendingConcern}
                                               confirmMarkingPendingConcern={confirmMarkingPendingConcern}/>
            </DialogComponent>
            <DrawerComponent isOpen={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                <ShiftFilterDrawerComponent
                    filterData={filterData}
                    setFilterData={setFilterData}
                    setOpenFilterDrawer={setOpenFilterDrawer}
                    isFacilityListLoading={isFacilityListLoading}
                    noStatus={true}
                    resetFilters={resetFilters}
                    facilityList={facilityList}
                    hcpTypes={hcpTypes}
                    isCompleted={true}
                />
            </DrawerComponent>
            <VitawerksConfirmComponent
                open={isAddOpen}
                isShiftType={true}
                isConfirm={isConfirm}
                cancel={cancelAdd}
                confirm={confirmAdd}
                text1="Do you want to move the shift from completed to closed?"
                hcpname={""}
                groupname={""}
                confirmationText={""}
                notext={"No"}
                yestext={"Yes"}
            />
            <div className="custom-border pdd-20">
                <div className="header">
                    <div className="mrg-left-5 filter">
                        <div>
                            <div className="d-flex">
                                <div className="d-flex position-relative">
                                    {!filterData.search ? (
                                        <div className={"search_icon"}>
                                            <SearchRounded/>
                                        </div>
                                    ) : (
                                        <div className={"search_icon"}>
                                            <ClearIcon
                                                onClick={(event) => {
                                                    setFilterData({...filterData, search: ''})
                                                    setSelectedRowData([]);
                                                    setSelectedShifts([]);
                                                }}
                                                id="clear_facility_search"
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <CssTextField
                                            defaultValue={""}
                                            className="search-cursor searchField"
                                            id="input_search_facility"
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                setFilterData({...filterData, search: value});
                                            }}
                                            value={filterData.search}
                                            variant={"outlined"}
                                            size={"small"}
                                            type={"text"}
                                            placeholder={"Search Shift"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actions d-flex">
                        <div className={'mrg-left-10'}>
                            <Button
                                variant={"outlined"} id={'add_facility_btn'}
                                color={"primary"}
                                onClick={() => {
                                    setOpenFilterDrawer(true)
                                }}
                            >
                                {filterCount > 0 &&
                                    <span className={'filter-btn-badge'}>{filterCount}</span>
                                }
                                &nbsp;&nbsp;Filters&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div>
                            <Tooltip title="Download Shifts List">
                                <Button variant={"outlined"} color="primary" onClick={handleDownload}
                                        className={!isDownloading ? "" : "has-loading-spinner"}
                                        disabled={isDownloading}>
                                    &nbsp;Download
                                </Button>
                            </Tooltip>
                        </div>
                        <div className={'mrg-left-15'}>
                            <Popover
                                trigger={'click'}
                                placement={'bottomRight'}
                                destroyTooltipOnHide={true}
                                open={popoverVisible}
                                onOpenChange={(visible) => setPopoverVisible(visible)}
                                content={<ShiftMenuListComponent
                                    selectedShifts={selectedShifts}
                                    onMenuItemClick={handleMenuItemClick}
                                    menuItems={menuItems}
                                />}
                            >
                                <div className={'applicant-box'} onClick={() => setPopoverVisible(!popoverVisible)}>
                                    <ImageConfig.KebabIcon className={'applicant-edit-icon'}/>
                                </div>
                            </Popover>
                        </div>
                    </div>
                </div>
                <div className={'completed-table-wrapper'}>
                    <AntTableComponent
                        key={refreshToken}
                        url={ENV.API_URL + "shift/complete"}
                        method={'post'}
                        extraPayload={filterData}
                        columns={completedShiftsListColumn}
                        noDataText={'No Completed Shift Found.'}
                        isRowSelection={true}
                        selectedRowKeys={selectedShifts}
                        setSelectedRowKeys={setSelectedShifts}
                        setSelectedRowData={setSelectedRowData}
                    />
                </div>
            </div>
        </div>
    );
};

export default CompletedShiftsListScreen;
