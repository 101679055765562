import "./PipelineComponent.scss";
import {CheckOutlined} from "@ant-design/icons"
import {CommonService} from "../../../helpers";

interface PipelineComponentProps{
    pipeline?:any;
}


const PipelineComponent=(props:PipelineComponentProps)=>{
    const {pipeline}=props;

    return (
        <div className="pipeline-container">
            {pipeline.map((step:any, index:number) => {
                const isCompleted = step.status_applied;
                return (
                    <div key={step.status_name} className="pipeline-step">
                        {/* Line before the circle, except for the first item */}

                        <div className={`label`}>
                            {CommonService.formatTitleCase(step.status_name)}
                        </div>
                        {index !== 0 && <div className="line"/>}
                        <div className={`circle ${isCompleted ? "completed" : ""}`}>
                            {isCompleted && <CheckOutlined/>}
                        </div>

                        {/* Label */}
                    </div>
                );
            })}
        </div>
    )
}
export default PipelineComponent